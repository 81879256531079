import {
  AuctionDetailDto,
  MessageChatDto,
} from "../../../../../redux/autions/type";

export const formatChatMessageReceive = (
  m: TenderChatMessageReceiveObject
): MessageChatDto => {
  return {
    opportunity__c: m.opportunity__c,
    Message: m.message,
    CreateTime: m.createTime,
    ProfileImage: m.profileImage,
    UserId: m.userId,
    UserName: m.userName,
    Read: m.read,
  };
};

export interface ChatSendObject {
  Message: string;
  opportunity__c?: string;
  user__c?: string;
  contact__c?: string;
}

export interface TenderChatMessageReceiveObject {
  id: string;
  message: string;
  userId: string;
  createTime: string;
  profileImage: string;
  opportunity_destination__c: string;
  read: boolean;
  userName: string;
  opportunity__c: string;
}

export type ChatDrawerProps = {
  show: boolean;
  setShow: (show: boolean) => void;
  auction: AuctionDetailDto;
  direction: any;
};
