import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link, useLocation } from "react-router-dom";
import { Drawer, Dropdown, Navbar, Nav, Sidenav, NavbarProps } from "rsuite";
import "../style.scss";
import "./style.scss";
import {
  getLocalizedMenuStrings,
  getNavbarMenuSelector,
  getUserState,
  languageSelector,
  languagesSelector,
} from "../../../redux/selectors";
import { AccountTypeParams, CommunicationLanguageObject } from "../../../types";
import RegistrationFormModal from "./../registration-form-modal";
import { accountApproved } from "../../../utils/userTypes";

import { icons } from "../../../assets";
import Icon from "../../Icon";
import {
  getAccountTypes,
  getCommunicationLanguages,
  logoutAction,
  setAuthenticate,
  setLanguage,
} from "../../../redux/actions";
import { color } from "../../../utils/Properties";
import Button, { ButtonType } from "../../Button";
import Flag from "react-flagkit";

const MobileNavBar = ({ activeKey, onSelect }: NavbarProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const strings = useSelector(getLocalizedMenuStrings);
  const menuList = useSelector(getNavbarMenuSelector);
  const language = useSelector(languageSelector);
  const languages = useSelector(languagesSelector);
  const [show, setShow] = useState<boolean>(false);
  const [accountType, setAccountType] = useState<AccountTypeParams[]>();
  const [comLang, setComLang] = useState<CommunicationLanguageObject[]>();
  const [showDrawer, setShowDrawer] = useState(false);
  const user = useSelector(getUserState);
  const location = useLocation();
  useEffect(() => {
    setShowDrawer(false);
  }, [location]);
  return (
    <div className="mobile-navbar-drawer-div" style={{ width: "100%" }}>
      <Navbar
        appearance={"subtle"}
        style={{ display: "flex", overflow: "visible", width: "100%" }}
      >
        <Navbar.Header
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            paddingBlock: 8,
            paddingInline: 0,
          }}
        >
          {window.location.hostname.includes("rapid") ? (
            <img src={icons.rapid} alt="" width={200} />
          ) : (
            <img
              src={icons.rapid}
              alt=""
              width={220}
              style={{ marginInlineStart: -22 }}
            />
          )}
        </Navbar.Header>
        <Navbar.Body
          style={{
            width: `calc(100% - 106px)`,
            height: "100%",
            overflow: "visible",
          }}
        >
          <Nav
            className="customNavbar"
            style={{
              overflow: "auto",
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            onSelect={onSelect}
            activeKey={activeKey}
            pullRight
          >
            <li
              className="rs-nav-item login"
              style={{
                marginBlockStart: 10,
                cursor: "pointer",
                paddingTop: 7,
              }}
              onClick={() => {
                setShowDrawer(true);
              }}
            >
              <Icon icon={"list"} size={36} />
            </li>
          </Nav>
        </Navbar.Body>
      </Navbar>
      <Drawer
        show={showDrawer}
        onHide={() => setShowDrawer(false)}
        className={"mobile-navbar-drawer"}
      >
        <Drawer.Body>
          <>
            <RegistrationFormModal
              show={show}
              onHide={() => setShow(false)}
              types={accountType || []}
              comLang={comLang}
            />
            <Sidenav activeKey={activeKey} onSelect={onSelect}>
              <Sidenav.Body>
                <Nav>
                  {menuList &&
                    menuList.map((item) => {
                      if (item.subItems) {
                        return (
                          <Dropdown
                            key={item.id}
                            eventKey={item.id}
                            title={item.name}
                            className={
                              "navbarDropdown mobileNavbarDropdown" +
                              (item.auth && user
                                ? item.auth?.some(
                                  (x) => x === user.AccountType.Code
                                )
                                  ? ""
                                  : "display-none"
                                : "")
                            }
                            trigger={["click", "hover"]}
                          >
                            {item.subItems.map((subItem) => {
                              return (
                                <Dropdown.Item
                                  key={subItem.id}
                                  eventKey={subItem.id}
                                  componentClass={Link}
                                  to={
                                    accountApproved
                                      ? subItem.link
                                      : "my-profile"
                                  }
                                >
                                  {subItem.name}
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown>
                        );
                      } else {
                        return (
                          <Nav.Item
                            className={
                              item.auth && user
                                ? item.auth?.some(
                                  (x) => x === user.AccountType.Code
                                )
                                  ? ""
                                  : "display-none"
                                : ""
                            }
                            componentClass={Link}
                            to={accountApproved ? item.link : "my-profile"}
                            style={{
                              marginBlockStart: activeKey === item.id ? -3 : 0,
                              backgroundColor:
                                activeKey === item.id
                                  ? "#f7f7fa"
                                  : "transparent",
                              borderTop:
                                activeKey === item.id
                                  ? "3px solid #00A3E0"
                                  : "none",
                            }}
                            eventKey={item.id}
                            key={item.id}
                          >
                            <span
                              style={{
                                fontWeight: 500,
                                color: "#215CA0",
                              }}
                              className="regularText"
                            >
                              {item.name}
                            </span>
                          </Nav.Item>
                        );
                      }
                    })}
                  {user ? (
                    <>
                      <Dropdown
                        title={`${user?.FirstName} ${user.LastName}`}
                        className="navbarDropdown mobileNavbarDropdown"
                        trigger={["click", "hover"]}
                      >
                        <Dropdown.Item componentClass={Link} to={"/my-profile"}>
                          {strings.getString("myprofile")}
                        </Dropdown.Item>
                        <Dropdown.Item
                          componentClass={Link}
                          to={"/"}
                          onSelect={() => {
                            dispatch(setAuthenticate(false));
                            dispatch(logoutAction());
                          }}
                        >
                          {strings.getString("logout")}
                        </Dropdown.Item>
                      </Dropdown>
                    </>
                  ) : (
                    <>
                      <Nav.Item
                        componentClass={Link}
                        to={"login"}
                        style={{
                          marginBlockStart: activeKey === "login" ? -3 : 0,
                          backgroundColor:
                            activeKey === "login" ? "#f7f7fa" : "transparent",
                          borderTop:
                            activeKey === "login"
                              ? "3px solid #00A3E0"
                              : "none",
                        }}
                        eventKey={"login"}
                      >
                        <span
                          style={{
                            fontWeight: 500,
                            color: "#215CA0",
                          }}
                          className="regularText"
                        >
                          {strings.getString("login")}{" "}
                        </span>
                      </Nav.Item>


                      <Nav.Item>
                        <a style={{
                          fontWeight: 500,
                          color: "#215CA0",
                          fontSize: 14
                        }} download={"rapidloadboard.apk"} href="../../assets/rapidloadboard.apk" target="download">{strings.getString("download_app")}</a>
                      </Nav.Item>
                      <li
                        className="rs-nav-item"
                        style={{
                          marginInline: "5px 10px",
                          marginBlockStart: 4,
                          width: 110,
                        }}
                      >
                        <Button
                          title={strings.getString("register").toUpperCase()}
                          buttonType={ButtonType.inverted}
                          style={{
                            borderColor: "#37A75B",
                            backgroundColor: "#37A75B",
                            color: color.textAccent,
                          }}
                          onClick={() => {
                            dispatch(
                              getAccountTypes({
                                payload: {
                                  onSuccess: (message, payload) => {
                                    setAccountType(payload);
                                  },
                                  onError: (message) => { },
                                },
                                url: "System/GetAccountTypes",
                              })
                            );
                            dispatch(
                              getCommunicationLanguages({
                                payload: {
                                  onSuccess: (message, payload) => {
                                    setComLang(payload);
                                  },
                                  onError: (message) => { },
                                },
                                url: "System/GetCommunicationLanguage",
                              })
                            );
                            setShow(true);
                          }}
                        />

                      </li>

                    </>
                  )}

                  <Dropdown
                    title={""}
                    key={language}
                    eventKey={language}
                    icon={
                      <Flag
                        country={
                          language === "en" ? "US" : language?.toUpperCase()
                        }
                      />
                    }
                    className="navbarDropdown mobileNavbarDropdown"
                    trigger={["click", "hover"]}
                  >
                    {languages.map((x) => (
                      <>
                        <Dropdown.Item
                          eventKey={x.flag}
                          onSelect={async () => {
                            await dispatch(
                              setLanguage(x.flag === "us" ? "en" : x.flag)
                            );
                            history.go(0);
                          }}
                        >
                          {x.language}
                        </Dropdown.Item>
                      </>
                    ))}
                  </Dropdown>

                </Nav>
              </Sidenav.Body>
            </Sidenav>
          </>
        </Drawer.Body>
      </Drawer>
    </div>
  );
};

export default MobileNavBar;
