import { Formik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Modal } from "rsuite";
import ModalBody from "rsuite/lib/Modal/ModalBody";
import ModalHeader from "rsuite/lib/Modal/ModalHeader";
import { Button, Input, ProfileLoadCard } from "../../../../../components";
import ProfileLoadCardMobil from "../../../../../components/ProfileLoadCardMobil";
import { setCustomerOfferStatus } from "../../../../../redux/profile/profileSaga";
import {
  DisapprovalReasonDto,
  QuoteDestionation,
} from "../../../../../redux/profile/type";
import { getLocalizedMyProfile } from "../../../../../redux/selectors";
import { LoadOpportunities } from "../../../../../types";
import { getCurrency } from "../../../../../utils/Helpers";
import { color } from "../../../../../utils/Properties";

const ApprovalOfferCardCustomer = ({
  data,
  reasons,
  getData,
  activeTab,
  destination,
}: {
  activeTab: string;
  getData: () => void;
  destination: QuoteDestionation;
  data: LoadOpportunities;
  reasons?: DisapprovalReasonDto[];
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [showAccept, setShowAccept] = useState<boolean>(false);
  const strings = useSelector(getLocalizedMyProfile);
  const dispatch = useDispatch();

  return (
    <>
      <div className="mobile-profile-offer-list">
        <div className="mobile-profile-offer-load-card">
          {data && (
            <ProfileLoadCardMobil
              key={data.id}
              load={data}
              component={
                <>
                  {activeTab === "open" && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: 10,
                        alignItems: "center",
                        marginInlineEnd: 10,
                        marginBlockEnd: 10,
                      }}
                    >
                      <Button
                        style={{
                          borderColor: color.error,
                          backgroundColor: color.error,
                          width: 100,
                          height: 30,
                        }}
                        title={"Reddet"}
                        onClick={() => {
                          setShow(true);
                        }}
                      />
                      <Button
                        style={{
                          borderColor: color.success,
                          backgroundColor: color.success,
                          width: 100,
                          height: 30,
                        }}
                        title={"Kabul Et"}
                        onClick={() => {
                          setShowAccept(true);
                        }}
                      />
                    </div>
                  )}
                </>
              }
            />
          )}
        </div>

        <div className="profile-offer-load-card">
          {data && (
            <ProfileLoadCard
              key={data.id}
              load={data}
              width={"calc(100% - 100px)"}
              component={
                <>
                  {" "}
                  {activeTab === "open" && (
                    <>
                      {" "}
                      <div style={{ textAlign: "right", marginInlineEnd: 50 }}>
                        <span className="headingStyle"> Price: </span>
                        <span className="headingStyle">
                          {data.currencyisocode && destination.price__c
                            ? getCurrency(
                                destination.price__c,
                                data.currencyisocode
                              )
                            : destination.price__c
                            ? destination.price__c
                            : "-"}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          gap: 10,
                          alignItems: "center",
                          marginInlineEnd: 10,
                          marginBlockEnd: 10,
                        }}
                      >
                        <Button
                          style={{
                            borderColor: color.error,
                            backgroundColor: color.error,
                            width: 100,
                            height: 30,
                          }}
                          title={strings.getString("Cancel")}
                          onClick={() => {
                            setShow(true);
                          }}
                        />
                        <Button
                          style={{
                            borderColor: color.success,
                            backgroundColor: color.success,
                            width: 100,
                            height: 30,
                          }}
                          title={strings.getString("Accept")}
                          onClick={() => {
                            setShowAccept(true);
                          }}
                        />
                      </div>
                    </>
                  )}
                </>
              }
            />
          )}
        </div>
      </div>
      <Modal
        show={showAccept}
        onHide={() => {
          setShowAccept(false);
        }}
        size={"xs"}
      >
        <ModalHeader
          onHide={() => setShowAccept(false)}
          style={{ minHeight: 7 }}
        ></ModalHeader>
        <ModalBody>
          <div style={{ padding: 20 }}>
            <Formik
              initialValues={{
                quotedestination__c: destination.sfid,
                accepted: true,
                reasonfordecline__c: "",
              }}
              onSubmit={(values) => {
                dispatch(
                  setCustomerOfferStatus({
                    payload: {
                      onSuccess: () => {
                        Alert.success(
                          strings.getString(
                            "The Offer is accepted successfully"
                          )
                        );
                        setShowAccept(false);
                        getData();
                      },
                      onError: () => {},
                    },
                    body: values,
                  })
                );
              }}
            >
              {({ handleSubmit }) => (
                <div>
                  <p
                    className="biggerHeadingStyle"
                    style={{ textAlign: "center" }}
                  >
                    {strings.getString("Are you sure accept this offer")}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      marginBlockStart: 20,
                      justifyContent: "space-between",
                      gap: 10,
                    }}
                  >
                    <Button
                      style={{
                        borderColor: color.success,
                        backgroundColor: color.success,
                      }}
                      title={strings.getString("Yes")}
                      onClick={handleSubmit}
                    />
                  </div>
                </div>
              )}
            </Formik>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        size={"xs"}
      >
        <ModalBody>
          <div style={{ padding: 20 }}>
            <Formik
              initialValues={{
                quotedestination__c: destination.sfid,
                accepted: false,
                reasonfordecline__c: "",
              }}
              onSubmit={(values) => {
                dispatch(
                  setCustomerOfferStatus({
                    payload: {
                      onSuccess: () => {
                        Alert.success(
                          strings.getString("The Offer is canceled")
                        );
                        setShow(false);
                        getData();
                      },
                      onError: () => {},
                    },
                    body: values,
                  })
                );
              }}
            >
              {({ handleSubmit, handleChange, values }) => (
                <div>
                  <Input
                    name={"reasonfordecline__c"}
                    label={strings.getString("Reason to Disapproval")}
                    placeholder={strings.getString("Reason to Disapproval")}
                    value={values.reasonfordecline__c}
                    onChange={handleChange("reasonfordecline__c")}
                    style={{ paddingBlock: 13 }}
                    autoFocus
                    onPressEnter={() => handleSubmit()}
                  />

                  <div
                    style={{
                      display: "flex",
                      marginBlockStart: 20,
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      style={{
                        borderColor: color.error,
                        backgroundColor: color.error,
                      }}
                      title={strings.getString("Cancel")}
                      onClick={handleSubmit}
                    />
                  </div>
                </div>
              )}
            </Formik>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
export default ApprovalOfferCardCustomer;
