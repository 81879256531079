import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Alert, Col, Icon, Rate, Row } from "rsuite";
import {
  addTenderFavourite,
  deleteTenderFavourite,
} from "../../../redux/autions/saga";
import {
  AuctionDto,
  AuctionListCounterDto,
  TenderCheckModel,
} from "../../../redux/autions/type";
import { getLocalizedAuction } from "../../../redux/selectors";
import { color } from "../../../utils/Properties";

const AuctionCard = ({
  auction,
  listCounter,
  isOpen,
  refreshList,
}: {
  refreshList?: () => void;
  isOpen: boolean;
  auction: AuctionDto;
  listCounter?: AuctionListCounterDto;
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const t = useSelector(getLocalizedAuction);
  const [counter, setCounter] = useState<TenderCheckModel>();
  useEffect(() => {
    if (listCounter) {
      const data = listCounter?.tenderCheckModels.find(
        (x) => x.opportunity__c === auction.sfid
      );
      setCounter(data);
    }
  }, [listCounter, auction.sfid]);
  return (
    <div className="auctionCard ">
      <Row>
        <Col md={2}>
          <Rate
            disabled={!isOpen}
            className="product-favorite-icon"
            readOnly={false}
            value={auction.AddedFavorite ? 1 : 0}
            max={1}
            character={<Icon icon="star" />}
            size="xs"
            color="yellow"
            onChange={(e) => {
              if (e) {
                dispatch(
                  addTenderFavourite({
                    payload: {
                      onSuccess: (message, payload) => {
                        Alert.success(t.getString("Added Favourites"), 5000);
                        refreshList && refreshList();
                      },
                      onError: () => {},
                    },
                    body: {
                      opportunityy__c: auction.sfid,
                    },
                  })
                );
              } else {
                dispatch(
                  deleteTenderFavourite({
                    payload: {
                      onSuccess: (message, payload) => {
                        Alert.warning(t.getString("Deleted Favourites"), 5000);
                        refreshList && refreshList();
                      },
                      onError: () => {},
                    },
                    id: auction.sfid,
                  })
                );
              }
            }}
            style={{}}
          />
        </Col>
        <Col
          md={22}
          onClick={() => history.push("/auction-details/" + auction.sfid)}
        >
          <Row>
            <Col md={isOpen ? 3 : 5}>
              <div className="auctionCardColumn">
                <p className="auctionCardColumnTitle">
                  {t.getString("Status")}
                </p>
                <p>
                  {auction.HasParticipated ? (
                    <span style={{ color: color.success }}>
                      {t.getString("Participated")}
                    </span>
                  ) : (
                    <span style={{ color: color.error }}>
                      {t.getString("Not Participated")}
                    </span>
                  )}
                </p>
              </div>
            </Col>
            <Col md={isOpen ? 3 : 5}>
              <div className="auctionCardColumn">
                <p className="auctionCardColumnTitle">
                  {t.getString("Tender No")}
                </p>
                <p>{auction.tender_no__c}</p>
              </div>
            </Col>
            <Col md={isOpen ? 3 : 5}>
              <div className="auctionCardColumn">
                <p className="auctionCardColumnTitle">
                  {t.getString("Tender Type")}
                </p>
                <p>{t.getString(auction.Opportunity_Tender_Type__c)}</p>
              </div>
            </Col>
            <Col md={5}>
              <div className="auctionCardColumn">
                <p className="auctionCardColumnTitle">
                  {t.getString("Tender Contract Start Date")}
                </p>
                <p>
                  {moment(auction.Tender_Start_Date__c + "+00:00").format(
                    "DD.MM.YYYY HH:mm"
                  )}
                </p>
              </div>
            </Col>
            <Col md={4}>
              <div className="auctionCardColumn">
                <p className="auctionCardColumnTitle">
                  {t.getString("Tender Contract End Date")}
                </p>
                <p>
                  {moment(auction.Tender_End_Date__c + "+00:00").format(
                    "DD.MM.YYYY HH:mm"
                  )}
                </p>
              </div>
            </Col>
            {isOpen && (
              <Col md={6}>
                <div className="auctionCardColumn">
                  <p className="auctionCardColumnTitle">
                    {auction.StartingTender
                      ? t.getString("End Remaining Time")
                      : t.getString("Start Remaining Time")}
                  </p>
                  <p>
                    {counter &&
                      (auction.StartingTender
                        ? counter.endingRemainingTime
                        : counter.startingRemainingTime)}
                  </p>
                </div>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default AuctionCard;
