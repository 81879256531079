import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Col, Form, Row } from "rsuite";
import * as Yup from "yup";
import { Icon, SelectPicker } from "../../../../../../components";
import { editDestinations } from "../../../../../../redux/profile/profileSaga";
import {
  DestinationDto,
  PicklistCountryDto,
  TransportWaysDto,
  TruckTypeDto,
  VehicleLengthDto,
} from "../../../../../../redux/profile/type";
import {
  getLocalizedErrors,
  getLocalizedMyProfile,
} from "../../../../../../redux/selectors";
const EditAccountDestinationForm = ({
  countries,
  selected,
  onSuccess,
  types,
  transports,
  widthdata,
  lengthdata,
  heightdata,
}: {
  widthdata?: VehicleLengthDto[];
  lengthdata?: VehicleLengthDto[];
  heightdata?: VehicleLengthDto[];
  onSuccess: () => void;
  selected: DestinationDto;
  types: TruckTypeDto[];
  transports: TransportWaysDto[];
  countries: PicklistCountryDto[];
}) => {
  const errorStrings = useSelector(getLocalizedErrors);
  const strings = useSelector(getLocalizedMyProfile);
  const dispatch = useDispatch();
  console.log(selected);
  return (
    <Formik
      enableReinitialize
      initialValues={{
        id: selected.id,
        from__c: selected.from__c,
        to__c: selected.to__c,
        transportways__c: selected.transportways__c ?? "",
        vehicletype__c: selected.vehicletype__c ?? "",
        vehiclewidth__c: selected.vehiclewidth__c ?? "",
        vehicleheight__c: selected.vehicleheight__c ?? "",
        vehiclelength__c: selected.vehiclelength__c ?? "",
      }}
      validationSchema={Yup.object().shape({
        from__c: Yup.string().required(errorStrings.getString("required")),
        to__c: Yup.string().required(errorStrings.getString("required")),

        vehicletype__c: Yup.string().required(
          errorStrings.getString("required")
        ),
        transportways__c: Yup.string().required(
          errorStrings.getString("required")
        ),
      })}
      onSubmit={(values) => {
        console.log("values", values);
        dispatch(
          editDestinations({
            payload: {
              onSuccess: onSuccess,
              onError: (message) => {
                toast.error(message, {
                  theme: "colored",
                });
              },
            },
            url: "/Profile/UpdateAccountDestination",
            body: values,
          })
        );
      }}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        values,
      }) => (
        <Form>
          <Row>
            <Col md={12}>
              <SelectPicker
                placement="bottomStart"
                cleanable={false}
                searchable={true}
                labelKey={"description"}
                valueKey={"id"}
                style={{ marginBlockEnd: 10 }}
                label={strings.getString("vehicletype")}
                placeholder={strings.getString("vehicletype")}
                data={types}
                name="from__c"
                value={values.vehicletype__c}
                onSelect={handleChange("vehicletype__c")}
                onBlur={handleBlur("vehicletype__c")}
                error={errors.vehicletype__c}
                touched={touched.vehicletype__c}
              />
            </Col>
            <Col md={12}>
              <SelectPicker
                cleanable={false}
                placement="bottomStart"
                searchable={true}
                style={{ marginBlockEnd: 10 }}
                label={strings.getString("transportways")}
                placeholder={strings.getString("transportways")}
                data={transports}
                name="to__c"
                labelKey={"value__c"}
                valueKey={"api_name__c"}
                value={values.transportways__c}
                onSelect={handleChange("transportways__c")}
                onBlur={handleBlur("transportways__c")}
                error={errors.transportways__c}
                touched={touched.transportways__c}
              />
            </Col>

            <Col md={11}>
              <SelectPicker
                cleanable={false}
                labelKey={"description"}
                placement="bottomStart"
                valueKey={"id"}
                searchable={true}
                style={{ marginBlockEnd: 10 }}
                label={strings.getString("startingpoint")}
                placeholder={strings.getString("startingpoint")}
                data={countries}
                name="from__c"
                value={values.from__c}
                onSelect={handleChange("from__c")}
                onBlur={handleBlur("from__c")}
                error={errors.from__c}
                touched={touched.from__c}
              />
            </Col>
            <Col md={11}>
              <SelectPicker
                cleanable={false}
                placement="bottomStart"
                searchable={true}
                style={{ marginBlockEnd: 10 }}
                label={strings.getString("destinationpoint")}
                placeholder={strings.getString("destinationpoint")}
                data={countries}
                name="to__c"
                labelKey={"description"}
                valueKey={"id"}
                value={values.to__c}
                onSelect={handleChange("to__c")}
                onBlur={handleBlur("to__c")}
                error={errors.to__c}
                touched={touched.to__c}
              />
            </Col>

            <Col md={2}>
              <div className="smResponsiveButtonDiv gridPosition">
                <button
                  type="submit"
                  className="gridIcon "
                  onClick={() => handleSubmit()}
                >
                  <Icon icon="plus" size={20} />
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
export default EditAccountDestinationForm;
