import ScrollBar from "react-perfect-scrollbar";
import "./style.css";

import {
  AboutUs,
  BlogNews,
  Offices,
  Opener,
  Stats,
} from "./homepage-inner-screens";
import { Footer } from "../../../components";
import { Helmet } from "react-helmet";
import { getLocalizedHelmet } from "../../../redux/selectors";
import { useSelector } from "react-redux";
const Homepage = () => {
  const helmetStrings = useSelector(getLocalizedHelmet);
  return (
    <div className="homepage">
      <Helmet>
        <title>{helmetStrings.getString("HomeHelmet")}</title>
      </Helmet>
      <ScrollBar id="homepageContent">
        <Opener />
        <AboutUs />
        <Stats />
        <BlogNews />
        <Offices />
        <Footer />
      </ScrollBar>
    </div>
  );
};

export default Homepage;
