import { useDispatch, useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { getLocalizedTruckRegistration } from "../../../../redux/selectors";
import { StepProps } from ".";
import {
  CheckPicker,
  DatePicker,
  Input,
  SelectPicker,
  TruckSelector,
} from "../../../../components";
import {
  EquipmentDto,
  TruckTransitDeclarationTypeDto,
} from "../../../../redux/opportunity/type";
import { useEffect, useState } from "react";
import isBefore from "date-fns/isBefore";
import {
  GetEquipments,
  GetTruckTransitDeclarationTypes,
} from "../../../../redux/opportunity/saga";
import moment from "moment";
import {
  getCountryList,
  getCurrencies,
  getTruckTypes,
} from "../../../../redux/profile/profileSaga";
import {
  CountryDto,
  CurrencyDto,
  TruckTypeDto,
} from "../../../../redux/profile/type";
import { getLoadingTypes } from "../../../../redux/actions";
import { LoadingTypes } from "../../../../types";
import { Divider, Radio, RadioGroup } from "rsuite";
import { color } from "../../../../utils/Properties";

interface Step0Props extends StepProps {
  quickBooking?: boolean;
}

const Step0 = ({ values, errors, touched, quickBooking }: Step0Props) => {
  const [showMoreDetail, setShowMoreDetails] = useState(false);
  const dispatch = useDispatch();
  const { handleChange, handleBlur, setFieldValue } = useFormikContext();
  // eslint-disable-next-line
  const strings = useSelector(getLocalizedTruckRegistration);
  const [gumrukbeyannameList, setGumrukbeyannameList] =
    useState<TruckTransitDeclarationTypeDto[]>();
  const [equipments, setEquipments] = useState<EquipmentDto[]>();
  const [countries, setCountries] = useState<CountryDto[]>();
  const [vehicletypes, setVehicleTypes] = useState<TruckTypeDto[]>();
  const [loadingTypes, setLoadingTypes] = useState<LoadingTypes[]>();
  const [currencies, setCurrencies] = useState<CurrencyDto[]>();
  useEffect(() => {
    dispatch(
      getCurrencies({
        payload: {
          onSuccess: (message, payload) => setCurrencies(payload),
          onError: (message) => {},
        },
        url: "System/GetCurrencyList",
      })
    );
    dispatch(
      getLoadingTypes({
        payload: {
          onSuccess: (message, payload) => setLoadingTypes(payload),
          onError: (message) => {},
        },
        url: "System/GetLoadingTypes",
      })
    );
    dispatch(
      getTruckTypes({
        payload: {
          onSuccess: (message, payload) => {
            setVehicleTypes(payload);
          },
          onError: (message) => {},
        },
        url: "System/GetTruckType",
      })
    );
    dispatch(
      getCountryList({
        payload: {
          onSuccess: (message, payload) => {
            setCountries(payload);
          },
          onError: (message) => {},
        },
        url: "System/GetCountryList",
      })
    );
    dispatch(
      GetEquipments({
        payload: {
          onSuccess: (message, payload) => setEquipments(payload),
          onError: (message) => {},
        },
      })
    );
    dispatch(
      GetTruckTransitDeclarationTypes({
        payload: {
          onSuccess: (message, payload) => setGumrukbeyannameList(payload),
          onError: (message) => {},
        },
        url: "System/GetTruckTransitDeclarationTypes",
      })
    );
  }, [dispatch]);
  return (
    <>
      <p
        className="newBoldHeadingStyle"
        style={{ marginBlockEnd: 18, textAlign: "center" }}
      >
        {strings.getString("1 Vehicle Information")}
      </p>
      <div>
        <TruckSelector
          vehicletypes={vehicletypes}
          selectedTrucks={[values.vehicle_Type__c]}
          setSelectedTrucks={(selectedTrucks) => {
            console.log(selectedTrucks);
            selectedTrucks.length > 0
              ? setFieldValue("vehicle_Type__c", selectedTrucks[1])
              : setFieldValue("vehicle_Type__c", "");
          }}
        />
        {errors.vehicle_Type__c && (
          <p className="inputErrorStyles">{errors.vehicle_Type__c}</p>
        )}
      </div>

      <div className="dividedRow mt-1">
        <div className="threeCol">
          <DatePicker
            oneTap
            disabledDate={(date) => {
              let today = new Date();
              today.setDate(today.getDate() - 1);
              if (date) {
                return isBefore(date, today);
              }
              return false;
            }}
            format="DD/MM/YY"
            label={strings.getString("Vehicle First Availability Date")}
            name="start"
            value={new Date(values.vehicle_First_Availability_Date__c)}
            onChange={(e) => {
              setFieldValue(
                "vehicle_First_Availability_Date__c",
                moment(e).format()
              );
            }}
            onBlur={handleBlur("vehicle_First_Availability_Date__c")}
          />
        </div>
        <div className="threeCol">
          <DatePicker
            oneTap
            format="DD/MM/YY"
            disabledDate={(date) => {
              if (date) {
                return isBefore(
                  date,
                  new Date(values.vehicle_First_Availability_Date__c)
                );
              }
              return false;
            }}
            label={strings.getString("Vehicle Last Availability Date")}
            name="vehicle_Last_Availability_Date__c"
            value={new Date(values.vehicle_Last_Availability_Date__c)}
            onChange={(e) => {
              setFieldValue(
                "vehicle_Last_Availability_Date__c",
                moment(e).format()
              );
            }}
            onBlur={handleBlur("vehicle_Last_Availability_Date__c")}
          />
        </div>
        <div className="threeCol">
          <SelectPicker
            cleanable={false}
            searchable={true}
            valueKey={"isocode"}
            labelKey={"isocode"}
            value={values.currency__}
            label={strings.getString("Currency")}
            data={currencies ? currencies : []}
            onSelect={handleChange("currency__")}
            onBlur={handleBlur("currency__")}
            error={errors.currency__}
            touched={touched.currency__}
          />
        </div>
      </div>
      <div
        style={{
          marginBlock: 20,
        }}
      >
        <p
          className="formLabel"
          style={{
            fontSize: 13,
            marginBlockEnd: 2,
            color: color.textPrimary,
          }}
        >
          {strings.getString("notes")}
        </p>
        <div style={{ width: "100%" }}>
          <Input
            componentClass="textarea"
            rows={3}
            name="description__c"
            value={values.description__c}
            onChange={handleChange("description__c")}
            onBlur={handleBlur("description__c")}
          />
        </div>
      </div>
      {showMoreDetail ? (
        <>
          <div className="dividedRow mt-1">
            <div className="halfWidth">
              <p className="mediumHeadingStyle" style={{ marginBlockEnd: 10 }}>
                {strings.getString("third_country_certificate")}
              </p>
              <RadioGroup
                value={values.thirdCountryCertificate__c}
                onChange={(e) => setFieldValue("thirdCountryCertificate__c", e)}
                onBlur={handleBlur("thirdCountryCertificate__c")}
              >
                <Radio value={true} style={{ marginInlineEnd: 30 }}>
                  {strings.getString("yes")}
                </Radio>
                <Radio value={false}>{strings.getString("no")}</Radio>
              </RadioGroup>
            </div>
            <div className="halfWidth">
              <p className="mediumHeadingStyle" style={{ marginBlockEnd: 10 }}>
                {strings.getString("driver_required")}
              </p>
              <RadioGroup
                value={values.secondDriverCheckbox__c}
                onChange={(e) => setFieldValue("secondDriverCheckbox__c", e)}
                onBlur={handleBlur("secondDriverCheckbox__c")}
              >
                <Radio value={true} style={{ marginInlineEnd: 30 }}>
                  {strings.getString("yes")}
                </Radio>
                <Radio value={false}>{strings.getString("no")}</Radio>
              </RadioGroup>
            </div>
          </div>
          <Divider />
          <div className="dividedRow ">
            <div className="halfWidth">
              <SelectPicker
                cleanable={false}
                value={values.thirdCountryName__c}
                labelKey={"countryname__c"}
                valueKey={"counryrpicklistref__c"}
                searchable={true}
                label={strings.getString("countryname")}
                data={countries ? countries : []}
                onSelect={handleChange("thirdCountryName__c")}
                onBlur={handleBlur("thirdCountryName__c")}
                error={errors.thirdCountryName__c}
                touched={touched.thirdCountryName__c}
              />
            </div>
            <div className="halfWidth">
              <SelectPicker
                style={{ maxHeight: 50 }}
                cleanable={false}
                value={values.truckTransitDeclarationType__c}
                labelKey={"description"}
                valueKey={"id"}
                searchable={true}
                label={strings.getString("trucktransitdeclarationtype")}
                data={gumrukbeyannameList ? gumrukbeyannameList : []}
                onSelect={(value) =>
                  setFieldValue("truckTransitDeclarationType__c", value)
                }
                onBlur={handleBlur("truckTransitDeclarationType__c ")}
                error={errors.truckTransitDeclarationType__c}
                touched={touched.truckTransitDeclarationType__c}
              />
            </div>
          </div>
          <div className="dividedRow mt-1">
            <div className="halfWidth">
              <SelectPicker
                cleanable={false}
                value={values.loading_Type__c}
                labelKey={"description"}
                valueKey={"id"}
                searchable={true}
                label={strings.getString("Loading Type")}
                data={loadingTypes ? loadingTypes : []}
                onSelect={handleChange("loading_Type__c")}
                onBlur={handleBlur("loading_Type__c")}
                error={errors.loading_Type__c}
                touched={touched.loading_Type__c}
              />
            </div>
            <div className="halfWidth">
              <SelectPicker
                cleanable={false}
                value={values.unloading_Type_c__c}
                labelKey={"description"}
                valueKey={"id"}
                searchable={true}
                label={strings.getString("Unloading Type")}
                data={loadingTypes ? loadingTypes : []}
                onSelect={handleChange("unloading_Type_c__c")}
                onBlur={handleBlur("unloading_Type_c__c")}
                error={errors.unloading_Type_c__c}
                touched={touched.unloading_Type_c__c}
              />
            </div>
          </div>
          <div className="dividedRow mt-1">
            <div style={{ width: "100%" }}>
              <CheckPicker
                label={strings.getString("equipments")}
                placement="bottomEnd"
                placeholder={strings.getString("equipmentss")}
                data={equipments ? equipments : []}
                value={values.equipmentsValue}
                onChange={(e) => setFieldValue("equipmentsValue", e)}
                valueKey="id"
                labelKey="description"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
          <div
            style={{
              marginBlockStart: 20,
              textAlign: "end",
              color: color.primary,
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => setShowMoreDetails(false)}
          >
            {strings.getString("Hide Details Info")}
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              textAlign: "end",
              color: color.primary,
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => setShowMoreDetails(true)}
          >
            {strings.getString("Show Details Info")}
          </div>
        </>
      )}
    </>
  );
};

export default Step0;
