import "./style.scss";
import { Nav, NavProps } from "rsuite";
import withDirection, {
  DIRECTIONS,
  //@ts-ignore
} from "react-with-direction";
import NavItem from "rsuite/lib/Nav/NavItem";
import { Icon } from "..";

interface CustomNavProps extends NavProps {
  center?: boolean;
  content: { name: string; icon: string }[];
}
const NavRS = ({
  content,
  centerClass = false,
  direction,
  ...rest
}: CustomNavProps) => {
  return (
    <Nav className="customNav" {...rest}>
      {content.map((c: { name: string; icon: string }, i: number) => {
        return (
          <NavItem
            className={centerClass ? "navItemCenter" : rest.className}
            key={i}
            eventKey={i + 1}
            active={i === 0}
            style={{
              width: `calc(100% / ${content.length})`,
              fontSize: 18,
              height: 100,
              alignItems: "center",
            }}
          >
            <div style={{ marginBlockStart: 10 }}>
              <Icon
                icon={c.icon}
                size={c.icon === "cargo-truck" ? 35 : 30}
                style={{
                  marginBlockStart: c.icon === "cargo-truck" ? -3 : 0,
                  transform:
                    direction === DIRECTIONS.LTR
                      ? "rotateY(0)"
                      : "rotateY(180deg)",
                }}
              />
              <span
                style={{
                  whiteSpace: "normal",
                  marginInlineStart: 2,
                  textAlign: "center",
                }}
              >
                {c.name}
              </span>
            </div>
          </NavItem>
        );
      })}
    </Nav>
  );
};

export default withDirection(NavRS);
