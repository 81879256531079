import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { SagaActionParams, SagaGenericParams } from "../../types";
import { deleteSaga } from "../sagas/deleteSaga";
import { getListSaga } from "../sagas/getSaga";
import { postSaga, PostSagaGenericParams } from "../sagas/postsaga";
import { IncDescAuctionDto } from "./inc-desc-type";
import * as T from "./type";

export const gettenderexcelfiletojson = createAction(
  "EXCEL_FILE_JSON",
  ({ payload, body }: SagaActionParams<T.TenderExcelJsonDto[]>) => ({
    payload,
    body: body,
    url: "/Tenders/GetTenderExcelFileToJson",
  })
);
export const getAuctionList = createAction(
  "GET_AUCTION_LIST",
  ({ payload, params }: SagaActionParams<T.AuctionDto[]>) => ({
    payload,
    url: "Tenders/GetTenders" + (params ? params : ""),
  })
);
export const getIncDescAuctionList = createAction(
  "GET_INC_DESC_AUCTION_LIST",
  ({ payload, params }: SagaActionParams<IncDescAuctionDto[]>) => ({
    payload,
    url: "Tenders/GetTenderRaiseReductions" + (params ? params : ""),
  })
);
export const getClosedIncDescAuctionList = createAction(
  "GET_CLOSED_INC_DESC_AUCTION_LIST",
  ({ payload, params }: SagaActionParams<IncDescAuctionDto[]>) => ({
    payload,
    url: "Tenders/GetClosingTenderRaiseReductions" + (params ? params : ""),
  })
);
export const addTenderFavourite = createAction(
  "ADD_TENDER_FAVOURITE",
  ({ payload, body }: SagaActionParams<T.AuctionDto>) => ({
    payload,
    body: body,
    url: "Tenders/AddFavorite",
  })
);
export const deleteTenderFavourite = createAction(
  "DELETE_TENDER_FAVOURITE",
  ({ payload, id }: SagaActionParams<T.AuctionDto>) => ({
    payload,
    url: "/Tenders/DeleteFavorite/" + id,
  })
);
export const getClosedAuctionList = createAction(
  "GET_CLOSED_AUCTION_LIST",
  ({ payload, params }: SagaActionParams<T.AuctionDto[]>) => ({
    payload,
    url: "Tenders/GetClosingTenders" + (params ? params : ""),
  })
);
export const getIncDescAuctionDetails = createAction(
  "GET_INC_DESC_AUCTION_DETAILS",
  ({ payload, id }: SagaActionParams<IncDescAuctionDto[]>) => ({
    payload,
    url: "Tenders/GetTenderDetailById?sfid=" + id,
  })
);
export const getAuctionDetails = createAction(
  "GET_AUCTION_DETAILS",
  ({ payload, id }: SagaActionParams<T.AuctionDetailDto[]>) => ({
    payload,
    url: "Tenders/GetByTender?sfid=" + id,
  })
);
export const getAuctionDetailsPriceList = createAction(
  "GET_AUCTION_DETAILS_PRICE_LIST",
  ({ payload, id }: SagaActionParams<T.AuctionPriceDto[]>) => ({
    payload,
    url: "Tenders/GetTenderDetails?sfid=" + id,
  })
);
export const getAuctionQuestion = createAction(
  "GET_QUESTION_AUCTION_LIST",
  ({ payload, id }: SagaActionParams<T.AuctionQuestionDto[]>) => ({
    payload,
    url: "Tenders/GetTenderQuestions?sfid=" + id,
  })
);
export const getIncDescAuctionQuestion = createAction(
  "GET_INC_DESC_QUESTION_AUCTION_LIST",
  ({ payload, id }: SagaActionParams<T.AuctionQuestionDto[]>) => ({
    payload,
    url: "Tenders/GetTenderRaiseReductionQuestionList?sfid=" + id,
  })
);
export const postAuctionPriceTable = createAction(
  "POST_AUCTION_PRICE_TABLE",
  ({ payload, body }: SagaActionParams<T.AuctionPriceDto[]>) => ({
    payload,
    body,
    url: "Tenders/AddTargetTenderOffer",
  })
);
export const getAuctionAgreement = createAction(
  "GET_AUCTION_AGREEMENT",
  ({ payload, id }: SagaActionParams<string>) => ({
    payload,
    url: "system/GetTenderAggrements",
  })
);
export const getAuctionChat = createAction(
  "GET_AUCTION_CHAT",
  ({ payload, id }: SagaActionParams<T.AuctionChatDto[]>) => ({
    payload,
    url: "Profile/GetTenderChats/" + id,
  })
);
export const getAuctionChatINC = createAction(
  "GET_AUCTION_CHAT_INC_DEC",
  ({ payload, id }: SagaActionParams<T.AuctionChatIncDto[]>) => ({
    payload,
    url: "Profile/GetRaiseReductionChats/" + id,
  })
);
export const getTenderExcel = createAction(
  "GET_TENDER_EXCEL",
  ({ payload, id }: SagaActionParams<T.TenderExcelDto[]>) => ({
    payload,
    url: "Tenders/GetTenderExcelFile/" + id,
  })
);

export const getMyAuctionList = createAction(
  "GET_MY_AUCTION_LIST",
  ({ payload, params }: SagaActionParams<T.AuctionDto[]>) => ({
    payload,
    url: "Tenders/GetTenders" + (params ? params : ""),
  })
);

export const getMyIncDescAuctionList = createAction(
  "GET_MY_INC_DESC_AUCTION_LIST",
  ({ payload, params }: SagaActionParams<IncDescAuctionDto[]>) => ({
    payload,
    url: "Tenders/GetTheTendersIWon" + (params ? params : ""),
  })
);

const auctionSagas = [
  takeLatest(getMyAuctionList.toString(), (payload: SagaGenericParams<any[]>) =>
    getListSaga<any[]>(payload)
  ),
  takeLatest(
    getMyIncDescAuctionList.toString(),
    (payload: SagaGenericParams<any[]>) => getListSaga<any[]>(payload)
  ),
  takeLatest(
    getIncDescAuctionDetails.toString(),
    (payload: PostSagaGenericParams<T.TenderExcelDto>) =>
      getListSaga<T.TenderExcelDto>(payload)
  ),
  takeLatest(
    getTenderExcel.toString(),
    (payload: PostSagaGenericParams<T.TenderExcelDto>) =>
      getListSaga<T.TenderExcelDto>(payload)
  ),
  takeLatest(
    deleteTenderFavourite.toString(),
    (payload: PostSagaGenericParams<T.AuctionDto>) =>
      deleteSaga<T.AuctionDto>(payload)
  ),
  takeLatest(
    addTenderFavourite.toString(),
    (payload: PostSagaGenericParams<T.AuctionDto>) =>
      postSaga<T.AuctionDto>(payload)
  ),
  takeLatest(
    getAuctionAgreement.toString(),
    (payload: SagaGenericParams<string>) => getListSaga<string>(payload)
  ),
  takeLatest(
    getAuctionChatINC.toString(),
    (payload: SagaGenericParams<T.AuctionChatDto[]>) =>
      getListSaga<T.AuctionChatDto[]>(payload)
  ),
  takeLatest(
    getAuctionChat.toString(),
    (payload: SagaGenericParams<T.AuctionChatDto[]>) =>
      getListSaga<T.AuctionChatDto[]>(payload)
  ),
  takeLatest(
    getAuctionDetailsPriceList.toString(),
    (payload: SagaGenericParams<T.AuctionPriceDto[]>) =>
      getListSaga<T.AuctionPriceDto[]>(payload)
  ),
  takeLatest(getAuctionList.toString(), (payload: SagaGenericParams<any[]>) =>
    getListSaga<any[]>(payload)
  ),
  takeLatest(
    getIncDescAuctionList.toString(),
    (payload: SagaGenericParams<any[]>) => getListSaga<any[]>(payload)
  ),
  takeLatest(
    getClosedIncDescAuctionList.toString(),
    (payload: SagaGenericParams<any[]>) => getListSaga<any[]>(payload)
  ),
  takeLatest(
    getClosedAuctionList.toString(),
    (payload: SagaGenericParams<any[]>) => getListSaga<any[]>(payload)
  ),
  takeLatest(getAuctionDetails.toString(), (payload: SagaGenericParams<any>) =>
    getListSaga<any>(payload)
  ),
  takeLatest(
    getIncDescAuctionQuestion.toString(),
    (payload: SagaGenericParams<any[]>) => getListSaga<any[]>(payload)
  ),
  takeLatest(
    getAuctionQuestion.toString(),
    (payload: SagaGenericParams<any[]>) => getListSaga<any[]>(payload)
  ),
  takeLatest(
    postAuctionPriceTable.toString(),
    (payload: PostSagaGenericParams<any[]>) => postSaga<any[]>(payload)
  ),
  takeLatest(
    gettenderexcelfiletojson.toString(),
    (payload: PostSagaGenericParams<T.TenderExcelJsonDto[]>) =>
      postSaga<T.TenderExcelJsonDto[]>(payload)
  ),
];
export default auctionSagas;
