import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AutoComplete, AutoCompleteProps } from "rsuite";
import { color } from "../../utils/Properties";
import "./style.scss";
interface props extends AutoCompleteProps {
  action: any;
  onselectitem: (value: any) => void;
  selectedItem?: any;
  url: string;
  onselectitemforlabel?: (value: any) => void;
  touch?: any;
  error?: any;
  label?: any;
  onselectcountrycode?: (value: any, id?: any) => void;
}

const AutoCompleteRs = ({
  action,
  selectedItem,
  label,
  onselectitemforlabel,
  onselectcountrycode,
  touch,
  error,
  onselectitem,
  url,
  ...rest
}: props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState<any[]>([]);
  const [departureValue, setDepartureValue] = useState<string | undefined>(
    selectedItem ? selectedItem : ""
  );
  useEffect(() => {
    selectedItem === "" && setDepartureValue("");
  }, [selectedItem]);
  return (
    <>
      {label && (
        <span
          style={{
            fontSize: 13,
            marginBlockEnd: 2,
            color: color.textPrimary,
          }}
        >
          {label}
        </span>
      )}
      {departureValue ? (
        <div
          className="responsiveAutoCompleteValue"
          style={{
            padding: 8,
            marginBlock: label ? "0px 5px" : 5,
            maxWidth: "",
            fontWeight: 400,
            fontSize: "1em",
            width: "100%",
            backgroundColor: "white",
            border: "1px solid #ddd",
            borderRadius: "4px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "calc(100% - 30px)",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {departureValue}
          </div>
          <div
            onClick={() => {
              setDepartureValue("");
              onselectitem("");
              onselectitemforlabel && onselectitemforlabel("");
              onselectcountrycode && onselectcountrycode("", "");
            }}
            style={{
              width: "20px",
              paddingRight: 10,
              cursor: "pointer",
            }}
          >
            x
          </div>
        </div>
      ) : (
        <AutoComplete
          {...rest}
          placement="bottomStart"
          data={
            data
              ? onselectcountrycode
                ? data.map((x) => {
                    return {
                      value: x.sfid,
                      label: x.name,
                      countryid: x.countryid,
                      countrycode: x.countrycode,
                    };
                  })
                : data.map((x) => {
                    return {
                      value: x.sfid,
                      label: x.name,
                    };
                  })
              : []
          }
          onChange={(value) => {
            dispatch(
              action({
                payload: {
                  //@ts-ignore
                  onSuccess: (message, payload) => {
                    setData(payload);
                  },
                  //@ts-ignore
                  onError: (message) => {},
                },
                url: url + `?SearchParam=${value}`,
              })
            );
          }}
          menuClassName={"renderedMneu"}
          renderItem={(item) => {
            return item.label;
          }}
          onSelect={(item, e: any) => {
            onselectitemforlabel && onselectitemforlabel(item.label);
            setDepartureValue(item.label);
            onselectitem(item.value);
            onselectcountrycode &&
              //@ts-ignore
              onselectcountrycode(item.countrycode, item.countryid);
          }}
        />
      )}

      {error && <p className="inputErrorStyles">{error}</p>}
    </>
  );
};
export default AutoCompleteRs;
