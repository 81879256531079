import LocalizedStrings, {
  GlobalStrings,
  LocalizedStringsMethods,
} from "react-localization";
import { createSelector } from "reselect";
import { RootState } from "../../types";

const loadingState = (state: RootState) => state.app.loading;
export const isLoadingState = createSelector(
  [loadingState],
  (loading) => loading
);
const scrollState = (state: RootState) => state.app.scroll_to;
export const scrollPositionSelector = createSelector(
  [scrollState],
  (scroll_to) => scroll_to
);
const userTokenState = (state: RootState) => state.user.user?.Token;
export const getUserToken = createSelector([userTokenState], (token) => token);
const userState = (state: RootState) => state.user.user;
export const getUserState = createSelector([userState], (user) => user);

const languageState = (state: RootState) => state.system.language;
export const languageSelector = createSelector(
  [languageState],
  (locale) => locale
);

export const getApiParams = createSelector(
  [userTokenState, languageState],
  (token, lang) => {
    return {
      lang,
      token,
    };
  }
);

/*  APP SELECTORS  */
const homepageState = (state: RootState) => state.app.homepage;
export const homepageSelector = createSelector(
  [homepageState],
  (homepage) => homepage
);

const authenticateState = (state: RootState) => state.app.authenticate;
export const authenticateSelector = createSelector(
  [authenticateState],
  (authenticate) => authenticate
);

/*  SYSTEM SELECTORS  */

const getHeaderbarMenuState = (state: RootState) => state.system.headerbar;
export const getHeaderbarMenuSelector = createSelector(
  [getHeaderbarMenuState],
  (menu) => menu || []
);

const getNavbarMenuState = (state: RootState) => state.system.navbar;
export const getNavbarMenuSelector = createSelector(
  [getNavbarMenuState],
  (menu) => menu || []
);

const contactRolesState = (state: RootState) => state.system.contactRoles;
export const contactRolesSelector = createSelector(
  [contactRolesState],
  (contactRoles) => contactRoles || []
);

const languagesState = (state: RootState) => state.system.languages;
export const languagesSelector = createSelector(
  [languagesState],
  (languages) => languages || []
);

const communicationlanguagesState = (state: RootState) =>
  state.system.communicationLanguages;
export const communicationlanguagesSelector = createSelector(
  [communicationlanguagesState],
  (communicationlanguages) => communicationlanguages || []
);

/*  LOCALE SELECTORS  */

export const userLanguageState = createSelector(
  [languageState],
  (lang) => lang
);

const returnLocolaziedString = (
  auth: GlobalStrings<string>,
  lang?: string
): LocalizedStringsMethods => {
  const l = new LocalizedStrings(auth);
  if (lang) l.setLanguage(lang);
  return l;
};

const getLocaleAuctions = (state: RootState) => state.locale.auctions;
export const getLocalizedAuction = createSelector(
  [getLocaleAuctions, languageState],
  (auctions, lang) => returnLocolaziedString(auctions, lang)
);

const getLocaleAuth = (state: RootState) => state.locale.auth;
export const getLocalizedAuth = createSelector(
  [getLocaleAuth, languageState],
  (auth, lang) => returnLocolaziedString(auth, lang)
);

const getLocaleChat = (state: RootState) => state.locale.chat;
export const getLocalizedChat = createSelector(
  [getLocaleChat, languageState],
  (chat, lang) => returnLocolaziedString(chat, lang)
);

const getLocaleDate = (state: RootState) => state.locale.date;
export const getLocalizedDates = createSelector(
  [getLocaleDate, languageState],
  (date, lang) => returnLocolaziedString(date, lang)
);
const getLocaleMyProfile = (state: RootState) => state.locale.profile;
export const getLocalizedMyProfile = createSelector(
  [getLocaleMyProfile, languageState],
  (profile, lang) => returnLocolaziedString(profile, lang)
);

const getLocaleErrors = (state: RootState) => state.locale.errors;
export const getLocalizedErrors = createSelector(
  [getLocaleErrors, languageState],
  (errors, lang) => returnLocolaziedString(errors, lang)
);

const getLocaleHomepage = (state: RootState) => state.locale.homepage;
export const getLocalizedHomepage = createSelector(
  [getLocaleHomepage, languageState],
  (homepage, lang) => returnLocolaziedString(homepage, lang)
);

const getLocaleLoadCard = (state: RootState) => state.locale.load_card;
export const getLocalizedLoadCard = createSelector(
  [getLocaleLoadCard, languageState],
  (load, lang) => returnLocolaziedString(load, lang)
);

const getLocaleMenu = (state: RootState) => state.locale.menu;
export const getLocalizedMenuStrings = createSelector(
  [getLocaleMenu, languageState],
  (menu, lang) => returnLocolaziedString(menu, lang)
);

const getLocaleLoadRegistration = (state: RootState) =>
  state.locale.load_registration;
export const getLocalizedLoadRegistration = createSelector(
  [getLocaleLoadRegistration, languageState],
  (load, lang) => returnLocolaziedString(load, lang)
);

const getLocaleSettings = (state: RootState) => state.locale.settings;
export const getLocalizedSettings = createSelector(
  [getLocaleSettings, languageState],
  (settings, lang) => returnLocolaziedString(settings, lang)
);

const getLocaleSearchTrucks = (state: RootState) => state.locale.search_trucks;
export const getLocalizedSearchTrucks = createSelector(
  [getLocaleSearchTrucks, languageState],
  (trucks, lang) => returnLocolaziedString(trucks, lang)
);

const getLocaleSidebarFilter = (state: RootState) =>
  state.locale.sidebar_filter;
export const getLocalizedSidebarFilter = createSelector(
  [getLocaleSidebarFilter, languageState],
  (filter, lang) => returnLocolaziedString(filter, lang)
);

const getLocaleSidebarNavigation = (state: RootState) =>
  state.locale.sidebar_navigation;
export const getLocalizedSidebarNavigation = createSelector(
  [getLocaleSidebarNavigation, languageState],
  (navigation, lang) => returnLocolaziedString(navigation, lang)
);

const getLocaleTruckCard = (state: RootState) => state.locale.truck_card;
export const getLocalizedTruckCard = createSelector(
  [getLocaleTruckCard, languageState],
  (truck, lang) => returnLocolaziedString(truck, lang)
);

const getLocaleTruckRegistration = (state: RootState) =>
  state.locale.truck_registration;
export const getLocalizedTruckRegistration = createSelector(
  [getLocaleTruckRegistration, languageState],
  (truck, lang) => returnLocolaziedString(truck, lang)
);

const getLocaleTruckTypes = (state: RootState) => state.locale.truck_types;
export const getLocalizedTruckTypes = createSelector(
  [getLocaleTruckTypes, languageState],
  (truck, lang) => returnLocolaziedString(truck, lang)
);
const getLocaleHelmet = (state: RootState) => state.locale.helmet;
export const getLocalizedHelmet = createSelector(
  [getLocaleHelmet, languageState],
  (navigation, lang) => returnLocolaziedString(navigation, lang)
);

const getLocaleCustomerContract = (state: RootState) =>
  state.locale.customercontracts;
export const getCustomerContractLocalization = createSelector(
  [getLocaleCustomerContract, languageState],
  (navigation, lang) => returnLocolaziedString(navigation, lang)
);
