import { useSelector } from "react-redux";
import {
  getLocalizedMyProfile,
  getUserState,
} from "../../../../../redux/selectors";
import { color } from "../../../../../utils/Properties";
import { truckUserAuth } from "../../../../../utils/userTypes";
import CustomerActiveTransportDetail from "./customer";
import TransporterActiveTransporterDetail from "./transporter";

const ActiveTransport = () => {
  const user = useSelector(getUserState);

  const strings = useSelector(getLocalizedMyProfile);
  return (
    <div
      className="my-profile-card"
      style={{ backgroundColor: color.accent, position: "fixed" }}
    >
      <p
        className="newBoldHeadingStyle"
        style={{ textAlign: "center", color: "#215CA0" }}
      >
        {strings.getString("comingsoon")}
      </p>
    </div>
  );
};
export default ActiveTransport;
