import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getLocalizedErrors,
  getLocalizedLoadRegistration,
} from "../../../redux/selectors";
import "./style.scss";
import { Button, Footer } from "../../../components";
import { color, isEmpty } from "../../../utils/Properties";
import { Formik } from "formik";
import * as Yup from "yup";
import { Alert } from "rsuite";

import { Step0, Step1, Step2, Step3 } from "./steps";
import moment from "moment";
import { LoadType } from "./type";
import { createLoadOpportunity } from "../../../redux/opportunity/saga";
const AlwaysScrollToBottom = () => {
  const elementRef = useRef(null);
  useEffect(() =>
    //@ts-ignore
    elementRef.current.scrollIntoView()
  );
  return <div ref={elementRef} />;
};
const CreateLoad = () => {
  const strings = useSelector(getLocalizedLoadRegistration);
  const errorStrings = useSelector(getLocalizedErrors);
  const [step, setStep] = useState<number>(0);
  const [firstError, setFirstError] = useState<string>("");
  const [showMoreDetail, setShowMoreDetails] = useState(false);

  const QuickBookingSchema = Yup.object().shape({
    Currencyisocode: Yup.string().required(errorStrings.getString("required")),
    FirstLoadingLocation__c: Yup.string().required(
      errorStrings.getString("required")
    ),
    FirstUnloadingLocation__c: Yup.string().required(
      errorStrings.getString("required")
    ),
    LoadingTimeStarted__c: Yup.string().required(
      errorStrings.getString("required")
    ),
    LoadingTimeEnded__c: Yup.string().required(
      errorStrings.getString("required")
    ),
    CustomersRequestedArrivalDate__c: Yup.string().required(
      errorStrings.getString("required")
    ),
    Main_Load_Type__c: Yup.string().required(
      errorStrings.getString("required")
    ),
    LoadType__c: Yup.string().required(errorStrings.getString("required")),

    VehicleNumber__c: Yup.number()
      .min(1, errorStrings.getString("required"))
      .required(errorStrings.getString("required")),
    Tonnage__c: Yup.number()
      .min(1, errorStrings.getString("required"))
      .required(errorStrings.getString("required")),
    vehicleList__c: Yup.array().min(
      1,
      strings.getString("You have to add at least one vehicle")
    ),
  });
  useEffect(() => {}, [step]);
  useEffect(() => {
    if (firstError && firstError.length > 0) {
      setStep(parseInt(firstError.charAt(firstError.length - 1)));
      setFirstError("");
    }
  }, [firstError]);

  const dispatch = useDispatch();

  const onSubmitPress = (
    values: LoadType,
    { resetForm }: { resetForm: any }
  ) => {
    dispatch(
      createLoadOpportunity({
        payload: {
          onSuccess: () => {
            Alert.success(
              strings.getString("Your load registered successfully"),
              5000
            );
            resetForm();
            setStep(0);
          },
          onError: (message) => {
            Alert.error(message, 5000);
          },
        },
        body: {
          OpportunityDestination: {
            customerswantsprice__c: values.CustomersWantsPrice__c,
            firstloadinglocation__c: values.FirstLoadingLocation__c,
            firstunloadinglocation__c: values.FirstUnloadingLocation__c,
            loadingtimestarted__c: values.LoadingTimeStarted__c,
            loadingtimeended__c: values.LoadingTimeEnded__c,
            fromcustoms__c: values.FromCustoms__c,
            tocustoms__c: values.ToCustoms__c,
            customersrequestedarrivaldate__c:
              values.CustomersRequestedArrivalDate__c,
            productprice__c: values.ProductPrice__c,
            main_load_type__c: values.Main_Load_Type__c,
            loadtype__c: values.LoadType__c,
            packagetype__c: values.PackageType__c,
            piece__c: values.Piece__c,
            gumrukyukbeyannamesilistesi__c:
              values.GumrukYukBeyannamesiListesi__c,
            adr__c: values.ADR__c,
            frigocelsius__c: values.FrigoCelsius__c,
            tonnage__c: values.Tonnage__c,
            hsc_code__c: values.HSC_Code__c,
            load_length__c: values.Load_Length__c,
            load_width__c: values.Load_Width__c,
            load_height__c: values.Load_Height__c,
            description__c: values.Description__c,
            doctor__c: values.Doctor__c,
            loadingtype__c: values.LoadingType__c.join(";"),
            unloadingtype__c: values.UnloadingType__c.join(";"),
          },
          VehicleTypes: values.vehicleList__c.map((x) => {
            return {
              transportways__c: "",
              vehicletype__c: x,
              vehiclenumber__c: values.VehicleNumber__c,
              seconddrivercheckbox__c: values.SecondDriverCheckbox__c,
              thirdcountrycertificate__c: values.ThirdCountryCertificate__c,
              thirdcountryname__c: values.ThirdCountryName__c,
              trucktransitdeclarationtype__c:
                values.TruckTransitDeclarationType__c,
              equipments__c: values.Equipments__c,
            };
          }),
          Routes: values.guzergahList__c.map((x) => {
            return {
              guzergah__c: x.Guzergah__c,
              customerswantsprice__c: x.CustomersWantsPrice__c,
            };
          }),
        },
      })
    );
  };
  return (
    <div className="innerScreen">
      <AlwaysScrollToBottom />
      <div
        className="innerScreenContent"
        style={{ backgroundColor: "#BFE9FE4D" }}
      >
        <div
          className="mainWidth"
          style={{
            marginBlock: 20,
            backgroundColor: color.accent,
            paddingInline: 40,
            paddingBlock: 40,
          }}
        >
          <Formik
            initialValues={{
              main_load_type__c_VALUE: "",
              FirstLoadingLocation__c: "",
              FirstUnloadingLocation__c: "",
              FirstLoadingLocation__c_Name: "",
              FirstUnloadingLocation__c_Name: "",
              LoadingTimeStarted__c: moment(new Date()).format(),
              LoadingTimeEnded__c: moment(new Date()).format(),
              CustomersRequestedArrivalDate__c: moment(new Date()).format(),
              Main_Load_Type__c: "",
              ADR__c: "",
              FrigoCelsius__c: "",
              LoadType__c: "",
              Tonnage__c: 0,
              CustomersWantsPrice__c: "",
              Currencyisocode: "",
              VehicleNumber__c: 1,
              vehicleList__c: [],
              SecondDriverCheckbox__c: false,
              ThirdCountryCertificate__c: false,
              TruckTransitDeclarationType__c: "",
              ThirdCountryName__c: "",
              Equipments__c: "",
              equipmentsValue: [],
              guzergahList__c: [],
              FromCustoms__c: "",
              FromCustoms__c_Name: "",
              ToCustoms__c: "",
              ToCustoms__c_Name: "",
              fromcountrycode: "",
              tocountrycode: "",
              PackageType__c: "",
              Piece__c: "",
              LoadingType__c: [],
              UnloadingType__c: [],
              HSC_Code__c: "",
              Load_Width__c: "",
              Load_Length__c: "",
              Load_Height__c: "",
              ProductPrice__c: "",
              Doctor__c: false,
              GumrukYukBeyannamesiListesi__c: "",
              Description__c: "",
            }}
            validationSchema={QuickBookingSchema}
            onSubmit={onSubmitPress}
          >
            {({ handleSubmit, values, errors, touched, validateForm }) => {
              return (
                <div style={{ width: "100%" }}>
                  <Step0
                    values={values}
                    errors={errors}
                    touched={touched}
                    quickBooking={false}
                  />
                  <Step1 values={values} errors={errors} touched={touched} />
                  <Step2 values={values} errors={errors} touched={touched} />

                  {showMoreDetail ? (
                    <>
                      <Step3
                        values={values}
                        errors={errors}
                        touched={touched}
                      />
                      <div
                        style={{
                          marginBlock: "5px 40px",
                          textAlign: "end",
                          color: color.primary,
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        onClick={() => setShowMoreDetails(false)}
                      >
                        {strings.getString("Hide Details Info")}
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          textAlign: "end",
                          marginBlock: "20px 40px",
                          color: color.primary,
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        onClick={() => setShowMoreDetails(true)}
                      >
                        {strings.getString("Show Details Info")}
                      </div>
                    </>
                  )}
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      style={{ width: "20%" }}
                      type="submit"
                      title={strings.getString("submit")}
                      onClick={() => {
                        validateForm().then((error) => {
                          if (isEmpty(error)) {
                            handleSubmit();
                          }
                        });
                      }}
                    />
                  </div>
                </div>
              );
            }}
          </Formik>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CreateLoad;
