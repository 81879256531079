import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Nav } from "rsuite";
import { getLocalizedAuction } from "../../../redux/selectors";
import Auctions from "../auctions";
import IncDescAuctions from "../inc-desc-auctions";

const BaseAuctions = () => {
  const { type }: { type: string } = useParams();
  const [active, setActive] = useState(type);
  const strings = useSelector(getLocalizedAuction);

  useEffect(() => {
    setActive(type);
  }, [type]);
  return (
    <React.Fragment>
      <Nav
        justified
        appearance={"default"}
        className={"tabbedNav "}
        activeKey={active}
        onSelect={(e) => {
          setActive(e);
          window.location.replace("/auctions/open/" + e);
        }}
      >
        <Nav.Item eventKey={"purpose"}>
          {strings.getString("Purpose Auctions")}
        </Nav.Item>
        <Nav.Item eventKey={"incdesc"}>
          {strings.getString("Inc/Desc Auctions")}
        </Nav.Item>
      </Nav>
      {type === "purpose" && <Auctions />}
      {type === "incdesc" && <IncDescAuctions />}
    </React.Fragment>
  );
};

export default BaseAuctions;
