import { call, put, takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { ApiResponse, SagaActionParams, SagaGenericParams } from "../../types";
import { resultStatus } from "../../utils/Properties";
import { createRequestSaga, hideLoader, showLoader } from "../actions";
import { getListSaga, getListWithoutLoaderSaga } from "../sagas/getSaga";
import { postSaga } from "../sagas/postsaga";

import {
  AdrListDto,
  CustomListDto,
  EquipmentDto,
  FrigoTypeDto,
  GumrukBeyannameListDto,
  LoadTypeDto,
  LocationOpportunityDto,
  PackageTypeDto,
  RouteDto,
  TruckTransitDeclarationTypeDto,
  VehicleOpportunitiesResponse,
  VehicleOpportunityDto,
} from "./type";
export const createVehicleOpportunity = createAction(
  "CREATE_VEHICLE_OPPORTUNİTY",
  ({ payload, body }: SagaActionParams<any>) => ({
    payload,
    body: body,
    url: "LoadOpportunity/CreateTruck",
  })
);
export const createLoadOpportunity = createAction(
  "CREATE_LOAD_OPPORTUNİTY",
  ({ payload, body }: SagaActionParams<any>) => ({
    payload,
    body: body,
    url: "VehicleOpportunity/CreateLoad",
  })
);
export const GetVehicleOpportunites = createAction(
  "GET_VEHICLE_OPPORTUNITY",
  ({ payload, url }: SagaActionParams<VehicleOpportunitiesResponse>) => ({
    payload,
    url: url,
  })
);
export const GetTruckTransitDeclarationTypes = createAction(
  "GET_TRUCK_TRANSIT_DECLARATION_TYPES",
  ({ payload, url }: SagaActionParams<TruckTransitDeclarationTypeDto[]>) => ({
    payload,
    url: url,
  })
);
export const GetRoutes = createAction(
  "GET_ROUTES",
  ({ payload, url }: SagaActionParams<RouteDto[]>) => ({
    payload,
    url: url,
  })
);
export const getGumrukbeyannamelist = createAction(
  "GET_GUMRUKBEYANNAME",
  ({ payload }: SagaActionParams<GumrukBeyannameListDto[]>) => ({
    payload,
    url: "System/GetGumrukBeyannameList",
  })
);
export const getAdrList = createAction(
  "GET_ADR_LIST",
  ({ payload }: SagaActionParams<AdrListDto[]>) => ({
    payload,
    url: "System/GetAdrList",
  })
);
export const getLocationForOpp = createAction(
  "GET_LOCATION_OPP",
  ({ payload, url }: SagaActionParams<LocationOpportunityDto[]>) => ({
    payload,
    url: url,
  })
);
export const getCustoms = createAction(
  "GET_CUSTOMS",
  ({ payload, url }: SagaActionParams<CustomListDto[]>) => ({
    payload,
    url: url,
  })
);
export const GetMainLoadTypes = createAction(
  "GET_MAIN_LOAD_TYPE",
  ({ payload }: SagaActionParams<LoadTypeDto[]>) => ({
    payload,
    url: "System/GetMainLoadTypes",
  })
);
export const GetEquipments = createAction(
  "GET_EQUIPMENTS",
  ({ payload }: SagaActionParams<EquipmentDto[]>) => ({
    payload,
    url: "System/GetEquipments",
  })
);
export const GetLoadTypes = createAction(
  "GET_LOAD_TYPE",
  ({ payload }: SagaActionParams<LoadTypeDto[]>) => ({
    payload,
    url: "System/GetLoadTypes",
  })
);
export const GetPackageTypes = createAction(
  "GET_PACKAGE_TYPE",
  ({ payload }: SagaActionParams<PackageTypeDto[]>) => ({
    payload,
    url: "System/GetPackageTypes",
  })
);
export const GetFrigoTypes = createAction(
  "GET_FRIGO_TYPE",
  ({ payload }: SagaActionParams<FrigoTypeDto[]>) => ({
    payload,
    url: "System/GetFrigolovs",
  })
);
export const createVehicleOffer = createAction(
  "CREATE_VEHICLE_OFFER",
  ({ payload, body }: SagaActionParams<any>) => ({
    payload,
    url: "VehicleOpportunity/CreateTruckOffer",
    body: body,
  })
);
export const createVehicleOfferWithLoad = createAction(
  "CREATE_VEHICLE_OFFER_WITH_LOAD",
  ({ payload, body }: SagaActionParams<any>) => ({
    payload,
    url: "VehicleOpportunity/CreateTruckOfferLoad",
    body: body,
  })
);
function* getVehicleOpportunitiesSaga({
  payload,
  url,
}: SagaGenericParams<VehicleOpportunitiesResponse>) {
  yield put(showLoader());
  try {
    const response: ApiResponse<VehicleOpportunityDto[]> = yield call(
      createRequestSaga,
      {
        method: "GET",
        url: url || "",
      }
    );
    if (resultStatus(response.ResultStatus)) {
      //@ts-ignore
      const responseData: VehicleOpportunitiesResponse = {
        list: response.Result,
        //@ts-ignore
        total: response.TotalCount,
      };
      payload.onSuccess(response.Message, responseData, response);
    } else payload.onError(response.Message);
  } catch (e) {
    console.log("ERROR getListSaga", e);
  } finally {
    yield put(hideLoader());
  }
}
const opportunitySagas = [
  takeLatest(createVehicleOpportunity.toString(), (payload) =>
    postSaga(payload as any)
  ),
  takeLatest(createLoadOpportunity.toString(), (payload) =>
    postSaga(payload as any)
  ),
  takeLatest(createVehicleOfferWithLoad.toString(), (payload) =>
    postSaga(payload as any)
  ),
  takeLatest(createVehicleOffer.toString(), (payload) =>
    postSaga(payload as any)
  ),
  takeLatest(
    GetTruckTransitDeclarationTypes.toString(),
    (payload: SagaGenericParams<TruckTransitDeclarationTypeDto[]>) =>
      getListWithoutLoaderSaga<TruckTransitDeclarationTypeDto[]>(payload)
  ),
  takeLatest(GetRoutes.toString(), (payload: SagaGenericParams<RouteDto[]>) =>
    getListWithoutLoaderSaga<RouteDto[]>(payload)
  ),
  takeLatest(GetVehicleOpportunites.toString(), (payload) =>
    getVehicleOpportunitiesSaga(payload as any)
  ),
  takeLatest(
    getLocationForOpp.toString(),
    (payload: SagaGenericParams<LocationOpportunityDto[]>) =>
      getListWithoutLoaderSaga<LocationOpportunityDto[]>(payload)
  ),
  takeLatest(
    getCustoms.toString(),
    (payload: SagaGenericParams<CustomListDto[]>) =>
      getListWithoutLoaderSaga<CustomListDto[]>(payload)
  ),
  takeLatest(
    getAdrList.toString(),
    (payload: SagaGenericParams<AdrListDto[]>) =>
      getListSaga<AdrListDto[]>(payload)
  ),
  takeLatest(
    getGumrukbeyannamelist.toString(),
    (payload: SagaGenericParams<GumrukBeyannameListDto[]>) =>
      getListSaga<GumrukBeyannameListDto[]>(payload)
  ),
  takeLatest(
    GetLoadTypes.toString(),
    (payload: SagaGenericParams<LoadTypeDto[]>) =>
      getListSaga<LoadTypeDto[]>(payload)
  ),
  takeLatest(
    GetMainLoadTypes.toString(),
    (payload: SagaGenericParams<LoadTypeDto[]>) =>
      getListSaga<LoadTypeDto[]>(payload)
  ),
  takeLatest(
    GetEquipments.toString(),
    (payload: SagaGenericParams<EquipmentDto[]>) =>
      getListSaga<EquipmentDto[]>(payload)
  ),
  takeLatest(
    GetPackageTypes.toString(),
    (payload: SagaGenericParams<PackageTypeDto[]>) =>
      getListSaga<PackageTypeDto[]>(payload)
  ),
  takeLatest(
    GetFrigoTypes.toString(),
    (payload: SagaGenericParams<FrigoTypeDto[]>) =>
      getListSaga<FrigoTypeDto[]>(payload)
  ),
];
export default opportunitySagas;
