import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ScrollBar from "react-perfect-scrollbar";
import {
  color,
  CUSTOM_MAP,
  GOOGLE_MAPS_API_KEY,
  mapContainerStyles,
} from "../../../../../utils/Properties";
import {
  getLocalizedHomepage,
  languageSelector,
} from "../../../../../redux/selectors";
import Flag from "react-flagkit";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import "./style.scss";
import { OfficeObject } from "../../../../../types";
import { getHomepageOffices } from "../../../../../redux/actions";

const Offices = () => {
  const language = useSelector(languageSelector);
  const dispatch = useDispatch();
  const strings = useSelector(getLocalizedHomepage);
  const [offices, setOffices] = useState<OfficeObject[]>();

  const [activeOffice, setActiveOffice] = useState<OfficeObject>();
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    dispatch(
      getHomepageOffices({
        payload: {
          onSuccess: (message, payload) => setOffices(payload),
          onError: (message) => {},
        },
        url: "HomePage/GetOffices",
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, dispatch]);

  useEffect(() => {
    showAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, offices]);

  const loadHandler = (map: google.maps.Map<Element>) => {
    setMap(map);
  };

  const showAll = () => {
    if (map) fitBounds(map, offices);
  };

  console.log(map);

  const fitBounds = (
    map: google.maps.Map<Element>,
    offices?: OfficeObject[]
  ) => {
    const bounds = new window.google.maps.LatLngBounds();
    offices?.map((office) => {
      bounds.extend({
        lat: parseFloat(office.latitude.toString()),
        lng: parseFloat(office.longitude.toString()),
      });
      return office.id;
    });
    map.fitBounds(bounds);
  };

  return (
    <div className="contactUs " id="contact">
      <div
        className="contactUsContent"
        style={{
          borderLeft: "1px solid #e8e8e8",
          borderTop: "1px solid #e8e8e8",
          paddingInlineStart: 20,
        }}
      >
        <div className="officesDiv">
          <h2
            className="h4"
            style={{ paddingBlock: "87px 26px", color: color.black }}
          >
            {strings.getString("offices")}
          </h2>
          <div className="offices">
            <ScrollBar>
              {offices?.map((office) => {
                return (
                  <>
                    <div
                      style={{
                        backgroundColor: "#e8e8e8",
                        height: 1,
                        width: "90%",
                        marginBlock: 10,
                      }}
                    />
                    <div
                      key={office.id}
                      className={
                        activeOffice?.id === office.id
                          ? "office activeOffice"
                          : "office"
                      }
                      onClick={() => {
                        setActiveOffice(office);
                        map?.panTo({
                          lat: parseFloat(office.latitude.replace(",", ".")),
                          lng: parseFloat(office.longitude.replace(",", ".")),
                        });
                        map?.setZoom(10);
                      }}
                    >
                      <p
                        className="headingStyle"
                        style={{ marginBlock: "8px 16px" }}
                      >
                        <Flag
                          country={office.flag === "EN" ? "US" : office.flag}
                          style={{
                            width: 18,
                            marginBlockStart: -5,
                            marginInlineEnd: 3,
                          }}
                        />
                        {office.officename}
                      </p>
                      <span
                        className="headingStyle"
                        style={{
                          color: color.primary,
                          textDecoration: "underline",
                        }}
                      >
                        {office.address}
                      </span>
                      <p
                        className="standardText"
                        style={{
                          marginBlock: 8,
                          color: color.textPrimary,
                        }}
                      >
                        {office.phone}
                      </p>
                      <span
                        className="standardText"
                        style={{
                          color: color.primary,
                          textDecorationLine: "underline",
                        }}
                      >
                        {office.email}
                      </span>
                    </div>
                  </>
                );
              })}
            </ScrollBar>
          </div>
        </div>
        <div className="officeLocation">
          <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
            <GoogleMap
              id="rapidMap"
              options={{
                gestureHandling: "cooperative",
                //@ts-ignore
                styles: CUSTOM_MAP,
                disableDefaultUI: true,
                zoomControl: true,
              }}
              mapContainerStyle={mapContainerStyles}
              zoom={16}
              onLoad={(map) => loadHandler(map)}
            >
              {offices?.map((office) => (
                <Marker
                  position={{
                    lat: parseFloat(office.latitude.replace(",", ".")),
                    lng: parseFloat(office.longitude.replace(",", ".")),
                  }}
                  onClick={() => {
                    setActiveOffice(office);
                    map?.panTo({
                      lat: parseFloat(office.latitude.replace(",", ".")),
                      lng: parseFloat(office.longitude.replace(",", ".")),
                    });
                    map?.setZoom(10);
                  }}
                />
              ))}
            </GoogleMap>
          </LoadScript>
        </div>
      </div>
    </div>
  );
};

export default Offices;
