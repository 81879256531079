import { forwardRef, MutableRefObject, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GoogleMap, DirectionsRenderer } from "@react-google-maps/api";
import { color, CUSTOM_MAP, mapContainerStyles } from "../../utils/Properties";
import moment from "moment";
import Flag from "react-flagkit";
import { getLocalizedLoadCard } from "../../redux/selectors";
import { LoadOpportunities, RouteObject } from "../../types";
import { Icon, Nav, Whisper } from "..";
import "./style.scss";
import { getCurrency } from "../../utils/Helpers";
import ScrollBar from "react-perfect-scrollbar";
import { getLoadOpportunityDetailWithoutLoader } from "../../redux/actions";
import { Loader } from "rsuite";

type LoadCardProps = {
  load: LoadOpportunities;
  selected: boolean;
  setSelected: (loadId: string) => void;
  details: string;
  setDetails: (loadId: string) => void;
  favList: string[];
  setFavList: (favList: string[]) => void;
  openChat: () => void;
  openBooking: (
    load: LoadOpportunities,
    fistOne: boolean,
    acceptNow: boolean
  ) => void;
  handlePrint: (ref: MutableRefObject<null>) => void;
};

const LoadCard = forwardRef<HTMLDivElement, LoadCardProps>(
  (
    {
      load,
      selected,
      setSelected,
      details,
      setDetails,
      favList,
      setFavList,
      openChat,
      openBooking,
      handlePrint,
    },
    ref
  ) => {
    const [selectedLoad, setSelectedLoad] = useState(load);
    const [loader, setLoader] = useState(false);
    const strings = useSelector(getLocalizedLoadCard);
    const cardRef = useRef(null);
    const [route_directions, setRouteDirections] = useState<
      google.maps.DirectionsResult[]
    >([]);
    const dispatch = useDispatch();
    const [path_distance, setPathDistance] = useState<string>("");
    const [path_distance_index, setPathDistanceIndex] = useState<number>(0);

    const navContent = [
      {
        id: 0,
        icon: "list",
        activeColor: color.primary,
        label: strings.getString("details"),
      },
      {
        id: 1,
        icon: "indicator",
        activeColor: color.primary,
        label: strings.getString("extra_infos"),
      },
      {
        id: 2,
        icon: "shorter-list",
        activeColor: color.primary,
        label: strings.getString("other_details"),
      },
      {
        id: 3,
        icon: "route",
        activeColor: color.primary,
        label: strings.getString("routes"),
      },
      {
        id: 4,
        icon: "map-pin",
        activeColor: color.primary,
        label: strings.getString("map"),
      },
    ];

    const postEndCalculater =
      new Date(
        selectedLoad.postendsat ? selectedLoad.postendsat : new Date()
      ).getTime() - new Date().getTime();

    const disabledCheck = (selectedLoad: LoadOpportunities) => {
      if (selectedLoad.RouteTab == null && selectedLoad.shipperofferprice > 0) {
        return true;
      } else if (selectedLoad.RouteTab) {
        if (
          selectedLoad.RouteTab.find((x) => x.shipperofferprice == null) == null
        ) {
          return true;
        }
      }

      return false;
    };

    function decideColor(i: number) {
      switch (i) {
        case 0:
          return "blue";
        case 1:
          return "orange";
        case 2:
          return "yellow";
        case 3:
          return "black";
        case 4:
          return "pink";
        case 5:
          return "gray";

        default:
          return "red";
      }
    }

    function clickableDirectionServiceResult(data: RouteObject) {
      let waypoints: google.maps.DirectionsWaypoint[] = [];
      if (data.koordinat) {
        JSON.parse(data.koordinat)?.forEach((data: any) => {
          waypoints.push({
            location: new google.maps.LatLng(data.Latitude, data.Longtitude),
            stopover: true,
          });
        });
      }
      setRouteDirections([]);
      getDirectionsServiceResult(waypoints);
    }

    const getDirectionsServiceResult = (
      waypoints?: google.maps.DirectionsWaypoint[]
    ) => {
      if (
        selectedLoad.DetailTab &&
        selectedLoad.DetailTab.ll1name &&
        selectedLoad.DetailTab.ul1name
      ) {
        const DirectionsService = new google.maps.DirectionsService();
        console.log("WAYPOINTS", waypoints);
        DirectionsService.route(
          {
            origin: selectedLoad.DetailTab.ll1name,
            destination: selectedLoad.DetailTab.ul1name,
            waypoints,
            travelMode: google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            console.log("ROUTE RESULT", result);
            if (status === google.maps.DirectionsStatus.OK) {
              setRouteDirections((rds) => [...rds, result]);
              console.log("LEGS", result.routes);
              let total_distance = 0;
              if ((result?.routes[0]?.legs?.length ?? 0) > 0) {
                result.routes[0].legs.forEach((l) => {
                  total_distance += l.distance.value;
                });
              }
              if (total_distance > 0) {
                setPathDistance(Math.round(total_distance / 1000) + "km");
              } else setPathDistance("");
            } else {
              console.error(`error fetching directions ${result}`);
            }
          }
        );
      }
    };

    return (
      <div key={selectedLoad.id} ref={cardRef}>
        <div
          ref={ref}
          className="truckResultCard"
          style={{
            fontSize: 10,
            color: color.textPrimary + color.thirtyOpacity,
            borderColor: selected ? color.side3 : "#e6e6e6",
            backgroundColor: color.pageBgColor,
          }}
        >
          <div style={{ width: "100%", display: "flex" }}>
            <div
              style={{
                width: `calc(100% - 36px)`,
                paddingBlock: "12px 2px",
                paddingInline: 10,
                display: "flex",
                marginInlineStart: 10,
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                className="vehicleLogo"
                style={{
                  backgroundColor: color.accent,
                  borderRadius: 10,
                }}
              >
                <img
                  className="imageStyles"
                  style={{ borderRadius: 10 }}
                  src={
                    selectedLoad.Owner
                      ? selectedLoad.Owner.profileimage
                      : selectedLoad.profileimage
                  }
                  alt=""
                />
              </div>
              <div className="truckResultCardMainDetails smallRegularText">
                <div>
                  <p
                    className="boldText card-object"
                    style={{ color: color.blackFifty }}
                  >
                    {strings.getString("file_number")}
                  </p>
                  <p className="card-object-value">
                    {"#" + selectedLoad.opdfilenumber}
                  </p>
                </div>
                <div>
                  <p
                    className="boldText card-object"
                    style={{ color: color.blackFifty }}
                  >
                    {strings.getString("load_type")}
                  </p>
                  <p className="card-object-value">
                    {(selectedLoad.main_load_type__c ?? "-") +
                      "/" +
                      (selectedLoad.loadtype ?? "-")}
                  </p>
                </div>
                <div>
                  <p
                    className="boldText card-object"
                    style={{ color: color.blackFifty }}
                  >
                    {strings.getString("route")}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingBlockStart: 8,
                    }}
                  >
                    <p className="card-object-value">
                      {selectedLoad.fromcountry
                        ? selectedLoad.fromcountry
                        : "-"}
                    </p>
                    <Icon
                      icon="right-bold"
                      size={13}
                      style={{ marginInline: 3 }}
                    />
                    <p className="card-object-value">
                      {selectedLoad.tocountry ? selectedLoad.tocountry : "-"}
                    </p>
                  </div>
                </div>
                <div>
                  <p
                    className="boldText  card-object"
                    style={{ color: color.blackFifty }}
                  >
                    {strings.getString("date")}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingBlockStart: 8,
                    }}
                  >
                    <p className="card-object-value">
                      {selectedLoad.loadbegindate
                        ? moment(selectedLoad.loadbegindate).format("l")
                        : "-"}
                    </p>
                    <Icon
                      icon="right-bold"
                      size={13}
                      style={{ marginInline: 3 }}
                    />
                    <p className="card-object-value">
                      {selectedLoad.loadenddate
                        ? moment(selectedLoad.loadenddate).format("l")
                        : "-"}
                    </p>
                  </div>
                </div>
                <div className="">
                  <p
                    className="boldText  card-object"
                    style={{ color: color.blackFifty }}
                  >
                    {strings.getString("estimated_amount")}
                  </p>
                  <p className="card-object-value">
                    {selectedLoad.currencyisocode
                      ? getCurrency(
                          selectedLoad.estimatedamount,
                          selectedLoad.currencyisocode
                        )
                      : selectedLoad.estimatedamount}
                  </p>
                  {!disabledCheck(selectedLoad) &&
                    selectedLoad.estimatedamount &&
                    selectedLoad.firstcomegets && (
                      <div
                        className="offerButton smallText"
                        style={{ marginBlock: "5px -30px" }}
                        onClick={() => {
                          if (!disabledCheck(selectedLoad)) {
                            openBooking(selectedLoad, true, false); //true selectedLoad.first.buy now
                          }
                        }}
                      >
                        {strings.getString("make_offer")}
                      </div>
                    )}
                </div>
              </div>
              <div
                className={
                  disabledCheck(selectedLoad)
                    ? "actionButtonDisabled card-offer-button"
                    : "actionButton card-offer-button"
                }
                onClick={() => {
                  if (!disabledCheck(selectedLoad)) {
                    openBooking(
                      selectedLoad,
                      selectedLoad.firstcomegets
                        ? selectedLoad.firstcomegets
                        : false, //true selectedLoad.first.buy now
                      selectedLoad.firstcomegets ? true : false
                    );
                  }
                }}
              >
                {disabledCheck(selectedLoad) ? (
                  <p style={{ textAlign: "center" }}>
                    {strings.getString("offer_has_been")}
                  </p>
                ) : selectedLoad.firstcomegets ? (
                  <p style={{ textAlign: "center" }}>
                    {strings.getString("accept_now")}
                  </p>
                ) : (
                  <p style={{ textAlign: "center" }}>
                    {strings.getString("make_offer")}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              marginInlineStart: 8,
              marginBlockEnd: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ marginInlineStart: 10 }}>
              <p
                className="boldText"
                style={{ fontSize: 14, color: color.textPrimary }}
              >
                {selectedLoad.rapidadvisorname}
              </p>
              <span
                className="smallText"
                style={{ color: color.side }}
                title={moment(selectedLoad.postedat).format("lll")}
              >
                {selectedLoad.postedat
                  ? moment(selectedLoad.postedat).format("l")
                  : "-"}
              </span>
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{ width: 160, textAlign: "center", marginInlineEnd: 30 }}
              >
                <p
                  className="smallBoldText"
                  style={{ color: color.blackFifty }}
                >
                  {strings.getString("post_end_date")}
                </p>
                <span
                  className="thinText"
                  style={
                    postEndCalculater < 432000000 && postEndCalculater > 0
                      ? { color: color.error }
                      : {}
                  }
                >
                  {selectedLoad.postendsat
                    ? moment(selectedLoad.postendsat).format("l")
                    : "-"}
                </span>
              </div>
            </div>
          </div>
          <div
            className="extraInfos"
            style={
              details === selectedLoad.id
                ? {
                    color: color.textPrimary + color.fiftyOpacity,
                    borderColor: color.black + "16",
                    borderBlockEnd: "1px solid #18181816",
                    backgroundColor: "#f5f5f5",
                  }
                : {
                    color: color.textPrimary + color.fiftyOpacity,
                    borderColor: color.black + "16",
                    borderEndEndRadius: 5,
                    borderEndStartRadius: 5,
                  }
            }
          >
            <div
              className="standardText"
              style={{
                display: "flex",
                alignItems: "center",
                marginInlineEnd: 50,
              }}
            >
              <Whisper speaker={strings.getString("weight")}>
                <div style={{ cursor: "pointer", marginInlineEnd: 20 }}>
                  <Icon icon="tonne" size={14} style={{ marginInlineEnd: 5 }} />
                  <span>{selectedLoad.weight + " t"}</span>
                </div>
              </Whisper>
              <Whisper speaker={strings.getString("volume")}>
                <div style={{ cursor: "pointer", marginInlineEnd: 20 }}>
                  <Icon icon="ruler" size={12} style={{ marginInlineEnd: 5 }} />
                  <span>
                    {selectedLoad.cubicmeters
                      ? new Intl.NumberFormat("tr-TR", {
                          maximumSignificantDigits: 20,
                        }).format(selectedLoad.cubicmeters) + " m³"
                      : "-"}
                  </span>
                </div>
              </Whisper>
              <Whisper
                speaker={
                  <p>
                    <p style={{ fontWeight: 700 }}>
                      {strings.getString("desired_truck_number") + ": "}
                    </p>
                    {selectedLoad.loadTrucks?.map((type, i) => (
                      <p key={i}>{`${type.vehicletype} / ${parseInt(
                        type.vehiclenumber__c.toString()
                      )}`}</p>
                    ))}
                  </p>
                }
              >
                <div
                  style={{
                    display: "flex",
                    marginInlineEnd: 20,
                    cursor: "pointer",
                  }}
                >
                  <Icon icon="truck" size={20} style={{ marginInlineEnd: 5 }} />
                  <p style={{ marginInlineEnd: 3 }}>
                    {selectedLoad.trucktype}

                    {selectedLoad.loadTrucks &&
                      selectedLoad.loadTrucks?.length > 1 &&
                      strings.formatString(
                        strings.getString("more"),
                        selectedLoad.loadTrucks.length - 1
                      )}
                  </p>
                  {selectedLoad.vehiclenumber && (
                    <span
                      style={{
                        textAlign: "center",
                        paddingBlockStart: 2,
                        width: 18,
                        height: 18,
                        borderRadius: 40,
                        color: color.accent,
                        backgroundColor: color.side3,
                        fontSize: 11,
                      }}
                    ></span>
                  )}
                </div>
              </Whisper>
              <Whisper speaker={strings.getString("distance")}>
                <div style={{ cursor: "pointer", marginInlineEnd: 30 }}>
                  <Icon
                    icon="distance"
                    size={17}
                    style={{ marginInlineEnd: 5 }}
                  />
                  <span>
                    {(selectedLoad.mindistance?.toFixed(0) ?? 0) + " km"}
                  </span>
                </div>
              </Whisper>
              <Whisper
                speaker={
                  strings.getString("pack_type") +
                  " / " +
                  strings.getString("number_of_packs")
                }
              >
                <div
                  style={{
                    display: "flex",
                    marginInlineEnd: 20,
                    cursor: "pointer",
                  }}
                >
                  <Icon
                    icon="box-stacked"
                    size={17}
                    style={{ marginInlineEnd: 5 }}
                  />
                  <p style={{ marginInlineEnd: 3 }}>
                    {selectedLoad.packagetype ? selectedLoad.packagetype : "-"}
                  </p>
                  {selectedLoad.packagequantity > 0 && (
                    <span
                      style={{
                        textAlign: "center",
                        paddingBlockStart: 2,
                        width: 18,
                        height: 18,
                        borderRadius: 40,
                        color: color.accent,
                        backgroundColor: color.side3,
                        fontSize: 11,
                      }}
                    >
                      {selectedLoad.packagequantity}
                    </span>
                  )}
                </div>
              </Whisper>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: 44,
                width: "max-content",
                border: "1px solid",
                borderColor: color.borderSecondary,
                borderRadius: 5,
                cursor: "pointer",
                backgroundColor: color.accent,
              }}
            >
              <Whisper speaker={strings.getString("details")}>
                <div
                  className="extraInfosButton"
                  style={{
                    borderColor: color.borderSecondary,
                    backgroundColor:
                      details === selectedLoad.id ? "#74747416" : "transparent",
                    borderStartStartRadius: 5,
                    borderEndStartRadius: 5,
                  }}
                  onClick={() => {
                    if (details === selectedLoad.id) {
                      setDetails("");
                    } else {
                      setLoader(true);
                      setDetails(selectedLoad.id);
                      dispatch(
                        getLoadOpportunityDetailWithoutLoader({
                          payload: {
                            onSuccess: (message, payload) => {
                              payload && setSelectedLoad(payload);
                              return setLoader(false);
                            },
                            onError: (message) => {
                              setLoader(false);
                            },
                          },
                          url: `LoadOpportunity/GetByLoadOpportunities?sfid=${selectedLoad.id}`,
                        })
                      );
                    }
                  }}
                >
                  <Icon icon="more" />
                </div>
              </Whisper>
              <Whisper speaker={strings.getString("print")}>
                <div
                  className="extraInfosButton"
                  style={{
                    borderColor: color.borderSecondary,
                  }}
                  onClick={async () => {
                    await setDetails(selectedLoad.id);
                    handlePrint(cardRef);
                  }}
                >
                  <Icon icon="printer" color={color.blackFifty} />
                </div>
              </Whisper>

              <Whisper speaker={strings.getString("chat")}>
                <div
                  className="extraInfosButton"
                  style={{ border: "none" }}
                  onClick={openChat}
                >
                  <Icon icon="chat" />
                </div>
              </Whisper>
            </div>
          </div>
          <div
            className="extraDetails"
            style={{ height: details === selectedLoad.id ? 293 : 0 }}
          >
            <div
              className="w-100"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {loader && <Loader />}
            </div>
            {!loader && (
              <Nav content={navContent}>
                {(activeTab) => {
                  return (
                    <div
                      className="extraDetailsContent"
                      style={{
                        paddingInline:
                          activeTab !== navContent.length - 1 ? 32 : 0,
                      }}
                    >
                      <ScrollBar>
                        {activeTab !== navContent.length - 1 && (
                          <div className="extraDetailsFirstTab">
                            <div className="extraDetailsFirstTabFirstCol">
                              {(activeTab === 0 || activeTab === undefined) && (
                                <>
                                  <div
                                    className="dividedRow"
                                    style={{ marginBlockEnd: 10 }}
                                  >
                                    <div style={{ width: "50%" }}>
                                      <p
                                        className="boldText"
                                        style={{ color: color.textSecondary }}
                                      >
                                        {strings.getString("loading_area")}
                                      </p>
                                      <ul className="areasList smallRegularText">
                                        {selectedLoad.DetailTab &&
                                          selectedLoad.DetailTab.ll1name && (
                                            <li>
                                              {selectedLoad.DetailTab.ll1name}
                                            </li>
                                          )}
                                        {selectedLoad.DetailTab &&
                                          selectedLoad.DetailTab.ll2name && (
                                            <li>
                                              {selectedLoad.DetailTab.ll2name}
                                            </li>
                                          )}
                                        {selectedLoad.DetailTab &&
                                          selectedLoad.DetailTab.ll3name && (
                                            <li>
                                              {selectedLoad.DetailTab.ll3name}
                                            </li>
                                          )}
                                        {selectedLoad.DetailTab &&
                                          selectedLoad.DetailTab.ll4name && (
                                            <li>
                                              {selectedLoad.DetailTab.ll4name}
                                            </li>
                                          )}
                                      </ul>
                                    </div>
                                    <div style={{ width: "50%" }}>
                                      <p
                                        className="boldText"
                                        style={{ color: color.textSecondary }}
                                      >
                                        {strings.getString("unloading_area")}
                                      </p>
                                      <ul className="areasList smallRegularText">
                                        {selectedLoad.DetailTab &&
                                          selectedLoad.DetailTab.ul1name && (
                                            <li>
                                              {selectedLoad.DetailTab.ul1name}
                                            </li>
                                          )}
                                        {selectedLoad.DetailTab &&
                                          selectedLoad.DetailTab.ul2name && (
                                            <li>
                                              {selectedLoad.DetailTab.ul2name}
                                            </li>
                                          )}
                                        {selectedLoad.DetailTab &&
                                          selectedLoad.DetailTab.ul3name && (
                                            <li>
                                              {selectedLoad.DetailTab.ul3name}
                                            </li>
                                          )}
                                        {selectedLoad.DetailTab &&
                                          selectedLoad.DetailTab.ul4name && (
                                            <li>
                                              {selectedLoad.DetailTab.ul4name}
                                            </li>
                                          )}
                                      </ul>
                                    </div>
                                  </div>
                                  <div
                                    className="dividedRow"
                                    style={{ marginBlockEnd: 10 }}
                                  >
                                    <div style={{ width: "50%" }}>
                                      <p
                                        className="boldText"
                                        style={{ color: color.textSecondary }}
                                      >
                                        {strings.getString("customs")}
                                      </p>
                                      {selectedLoad.DetailTab &&
                                      selectedLoad.DetailTab.fromcustoms ? (
                                        <span className="smallRegularText">
                                          {selectedLoad.DetailTab &&
                                            selectedLoad.DetailTab.fromcustoms}
                                        </span>
                                      ) : (
                                        <span className="smallRegularText">
                                          -
                                        </span>
                                      )}
                                    </div>
                                    <div style={{ width: "50%" }}>
                                      <p
                                        className="boldText"
                                        style={{ color: color.textSecondary }}
                                      >
                                        {strings.getString("customs")}
                                      </p>
                                      {selectedLoad.DetailTab &&
                                      selectedLoad.DetailTab.tocustoms ? (
                                        <span className="smallRegularText">
                                          {selectedLoad.DetailTab &&
                                            selectedLoad.DetailTab.tocustoms}
                                        </span>
                                      ) : (
                                        <span className="smallRegularText">
                                          -
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    className="dividedRow"
                                    style={{ marginBlockEnd: 10 }}
                                  >
                                    <div style={{ width: "50%" }}>
                                      <p
                                        className="boldText"
                                        style={{ color: color.textSecondary }}
                                      >
                                        {strings.getString("loading_type")}
                                      </p>
                                      <span className="smallRegularText">
                                        {selectedLoad.DetailTab &&
                                        selectedLoad.DetailTab.loadintypes
                                          ? selectedLoad.DetailTab.loadintypes.replaceAll(
                                              ";",
                                              ","
                                            )
                                          : "-"}
                                      </span>
                                    </div>
                                    <div style={{ width: "50%" }}>
                                      <p
                                        className="boldText"
                                        style={{ color: color.textSecondary }}
                                      >
                                        {strings.getString("unloading_type")}
                                      </p>
                                      <span className="smallRegularText">
                                        {selectedLoad.DetailTab &&
                                        selectedLoad.DetailTab.unloadingtypes
                                          ? selectedLoad.DetailTab.unloadingtypes.replaceAll(
                                              ";",
                                              ","
                                            )
                                          : "-"}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              )}
                              {activeTab === 1 && (
                                <>
                                  <div
                                    style={{
                                      width: "100%",
                                      marginBlockEnd: 10,
                                    }}
                                  >
                                    <p
                                      className="boldText"
                                      style={{ color: color.textSecondary }}
                                    >
                                      {strings.getString(
                                        "desired_arrival_date"
                                      )}
                                    </p>
                                    <span className="smallRegularText">
                                      {selectedLoad.loadenddate
                                        ? moment(
                                            selectedLoad.loadenddate
                                          ).format("L")
                                        : ""}
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      width: "100%",
                                      marginBlockEnd: 10,
                                    }}
                                  >
                                    <p
                                      className="boldText"
                                      style={{ color: color.textSecondary }}
                                    >
                                      {strings.getString("payment_conditions")}
                                    </p>
                                    <span className="smallRegularText">
                                      {selectedLoad.ExtraTab &&
                                      selectedLoad.ExtraTab
                                        .advancepaymentafterloading__c ? (
                                        <>
                                          <span>
                                            {strings.formatString(
                                              strings.getString(
                                                "paymentconditionfirstsentence"
                                              ),
                                              selectedLoad.ExtraTab.percent__c,
                                              100 -
                                                selectedLoad.ExtraTab.percent__c
                                            )}
                                          </span>
                                          {selectedLoad.ExtraTab
                                            .dayafterloading__c && (
                                            <p>
                                              {strings.formatString(
                                                strings.getString(
                                                  "afterloadingday"
                                                ),
                                                selectedLoad.ExtraTab.day__c
                                              )}
                                            </p>
                                          )}

                                          {selectedLoad.ExtraTab
                                            .dayafterunloading__c && (
                                            <p>
                                              {strings.formatString(
                                                strings.getString(
                                                  "afterunloadingday"
                                                ),
                                                selectedLoad.ExtraTab
                                                  .unloadingday__c
                                              )}
                                            </p>
                                          )}

                                          {selectedLoad.ExtraTab
                                            .afterinvoicedate__c && (
                                            <p>
                                              {strings.formatString(
                                                strings.getString(
                                                  "afterinvoice"
                                                ),
                                                selectedLoad.ExtraTab
                                                  .afterinvoicedateday__c
                                              )}
                                            </p>
                                          )}

                                          {selectedLoad.ExtraTab
                                            .aftercopycmrinvoice__c && (
                                            <p>
                                              {strings.formatString(
                                                strings.getString(
                                                  "aftercopycmr"
                                                ),
                                                selectedLoad.ExtraTab
                                                  .aftercopycmrinvoiceday__c
                                              )}
                                            </p>
                                          )}
                                          {selectedLoad.ExtraTab
                                            .afteroriginalcmr__c && (
                                            <p>
                                              {strings.formatString(
                                                strings.getString(
                                                  "afteroriginalcmr"
                                                ),
                                                selectedLoad.ExtraTab.cmrday__c
                                              )}
                                            </p>
                                          )}
                                        </>
                                      ) : (
                                        <span>{strings.getString("Cash")}</span>
                                      )}
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      width: "100%",
                                      marginBlockEnd: 10,
                                    }}
                                  >
                                    <p
                                      className="boldText"
                                      style={{ color: color.textSecondary }}
                                    >
                                      {strings.getString("hsc") +
                                        " / " +
                                        strings.getString("commodity_price")}
                                    </p>
                                    {selectedLoad?.ExtraTab?.hsc_code__c ? (
                                      <span className="smallRegularText">
                                        {selectedLoad.ExtraTab.hsc_code__c}
                                      </span>
                                    ) : (
                                      <span className="smallRegularText">
                                        -
                                      </span>
                                    )}
                                    {selectedLoad?.ExtraTab?.productprice__c ? (
                                      <span className="smallRegularText">
                                        /
                                        {getCurrency(
                                          selectedLoad.ExtraTab.productprice__c,
                                          selectedLoad.currencyisocode
                                        )}
                                      </span>
                                    ) : (
                                      <span className="smallRegularText">
                                        /-
                                      </span>
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      width: "100%",
                                      marginBlockEnd: 10,
                                    }}
                                  >
                                    <p
                                      className="boldText"
                                      style={{ color: color.textSecondary }}
                                    >
                                      {strings.getString("desired_equipment")}
                                    </p>
                                    <span className="smallRegularText">
                                      {selectedLoad.loadTrucks?.map(
                                        (type, i) => (
                                          <p key={i}>{`${
                                            type.equipments ?? ""
                                          }`}</p>
                                        )
                                      )}
                                    </span>
                                  </div>
                                </>
                              )}
                              {activeTab === 2 && (
                                <div style={{ width: "100%" }}>
                                  <p
                                    className="boldText"
                                    style={{ color: color.textSecondary }}
                                  >
                                    {strings.getString("other_details")}
                                  </p>
                                  <div
                                    className="smallRegularText"
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <p
                                      style={{
                                        marginInlineEnd: 10,
                                        color: color.textSecondary,
                                      }}
                                    >
                                      {strings.getString("adr") + ": "}
                                    </p>
                                    <span>
                                      {selectedLoad?.OtherTab?.adr__c ? (
                                        <>
                                          {strings.getString("yes") +
                                            " - " +
                                            selectedLoad.OtherTab.adr__c}
                                        </>
                                      ) : (
                                        strings.getString("no")
                                      )}
                                    </span>
                                  </div>
                                  <div
                                    className="smallRegularText"
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <p
                                      style={{
                                        marginInlineEnd: 10,
                                        color: color.textSecondary,
                                      }}
                                    >
                                      {strings.getString("frigo") + ": "}
                                    </p>
                                    <span>
                                      {selectedLoad?.OtherTab?.frigocelsius_ ? (
                                        <>
                                          {strings.getString("yes") +
                                            `- (${selectedLoad.OtherTab.frigocelsius_})`}
                                        </>
                                      ) : (
                                        strings.getString("no")
                                      )}
                                    </span>
                                  </div>
                                  <div
                                    className="smallRegularText"
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <p
                                      style={{
                                        marginInlineEnd: 10,
                                        color: color.textSecondary,
                                      }}
                                    >
                                      {strings.getString("doctor_required") +
                                        ": "}
                                    </p>
                                    <span>
                                      {selectedLoad?.OtherTab?.isdoctorrequired
                                        ? strings.getString("yes")
                                        : strings.getString("no")}
                                    </span>
                                  </div>
                                  <div
                                    className="smallRegularText"
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <p
                                      style={{
                                        marginInlineEnd: 10,
                                        color: color.textSecondary,
                                      }}
                                    >
                                      {strings.getString("countrycertificate") +
                                        ": "}
                                    </p>
                                    <span>
                                      {selectedLoad?.thirdcountrycertificate !==
                                      undefined
                                        ? selectedLoad.thirdcountryname
                                          ? strings.getString("yes")
                                          : strings.getString("no")
                                        : "-"}
                                    </span>
                                  </div>
                                  <div
                                    className="smallRegularText"
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <p
                                      style={{
                                        marginInlineEnd: 10,
                                        color: color.textSecondary,
                                      }}
                                    >
                                      {strings.getString("countryname") + ": "}
                                    </p>
                                    <span>
                                      {selectedLoad?.thirdcountryname
                                        ? selectedLoad?.thirdcountryname
                                        : "-"}
                                    </span>
                                  </div>
                                  <div
                                    className="smallRegularText"
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <p
                                      style={{
                                        marginInlineEnd: 10,
                                        color: color.textSecondary,
                                      }}
                                    >
                                      {strings.getString(
                                        "trucktransitdeclarationtype"
                                      ) + ": "}
                                    </p>
                                    <span>
                                      {selectedLoad?.trucktransitdeclarationtype
                                        ? selectedLoad?.trucktransitdeclarationtype
                                        : "-"}
                                    </span>
                                  </div>
                                  <div
                                    className="smallRegularText"
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <p
                                      style={{
                                        marginInlineEnd: 10,
                                        color: color.textSecondary,
                                      }}
                                    >
                                      {strings.getString(
                                        "seconddrivercheckbox"
                                      ) + ": "}
                                    </p>
                                    <span>
                                      {selectedLoad?.seconddrivercheckbox !==
                                      undefined
                                        ? selectedLoad?.seconddrivercheckbox
                                          ? strings.getString("yes")
                                          : strings.getString("no")
                                        : "-"}
                                    </span>
                                  </div>
                                </div>
                              )}
                              {activeTab === 3 && (
                                <>
                                  <p
                                    className="boldText"
                                    style={{ color: color.textSecondary }}
                                  >
                                    {strings.getString("available_routes")}
                                  </p>
                                  {selectedLoad.RouteTab?.map((route, i) => (
                                    <div
                                      key={i}
                                      className="smallRegularText"
                                      style={{
                                        display: "flex",
                                      }}
                                    >
                                      <p>{route.route}&nbsp; </p>
                                      <span>
                                        {getCurrency(
                                          route.targetprice,
                                          route.currencyisocode
                                        )}
                                      </span>
                                      <span>
                                        &nbsp;{route.distance?.toFixed(0) ?? 0}{" "}
                                        km
                                      </span>
                                    </div>
                                  ))}
                                </>
                              )}
                            </div>
                            <div
                              style={{
                                width: "5%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  width: 1,
                                  backgroundColor: color.borderColor,
                                }}
                              />
                            </div>
                            <div className="extraDetailsFirstTabSecondCol">
                              <p
                                className="boldText"
                                style={{ color: color.textSecondary }}
                              >
                                {strings.getString("language")}
                              </p>
                              <div
                                style={{
                                  width: 100,
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  flexWrap: "wrap",
                                  gap: "4",
                                }}
                              >
                                {selectedLoad?.Owner?.flags
                                  ?.split(";")
                                  .map((x) => {
                                    return (
                                      <Flag
                                        style={{ marginRight: 3 }}
                                        country={x === "EN" ? "US" : x}
                                        title={x}
                                        className="flag"
                                      />
                                    );
                                  })}
                              </div>
                              <p
                                className="boldText"
                                style={{
                                  color: color.textSecondary,
                                  marginBlockEnd: 0,
                                  marginBlockStart: 5,
                                }}
                              >
                                {strings.getString("contact_details")}
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Icon
                                  icon="mail"
                                  size={20}
                                  style={{ marginInlineEnd: 5 }}
                                />
                                <span
                                  className="boldText"
                                  style={{
                                    textDecoration: "underline",
                                    fontWeight: 600,
                                  }}
                                >
                                  {selectedLoad.Owner?.email}
                                </span>
                              </div>
                              <div style={{ display: "flex" }}>
                                <Icon
                                  icon="mobile"
                                  size={20}
                                  style={{ marginInlineEnd: 5 }}
                                />
                                <span
                                  className="boldText"
                                  style={{ fontWeight: 600 }}
                                >
                                  {selectedLoad.Owner?.mobilephone}
                                </span>
                              </div>
                              <div style={{ display: "flex" }}>
                                <Icon
                                  icon="phone"
                                  color={color.black}
                                  size={15}
                                  style={{
                                    marginInlineEnd: 7,
                                    marginInlineStart: 3,
                                  }}
                                />
                                <span
                                  className="boldText"
                                  style={{ fontWeight: 600 }}
                                >
                                  {selectedLoad.Owner?.phone}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                        {activeTab === 4 && (
                          <GoogleMap
                            id={`rapidMap-${selectedLoad.id}`}
                            options={{
                              //@ts-ignore
                              styles: CUSTOM_MAP,
                              disableDefaultUI: true,
                              zoomControl: true,
                              scrollwheel: false,
                            }}
                            onLoad={(map: google.maps.Map<Element>) => {
                              setRouteDirections([]);
                              setPathDistance("");

                              if (selectedLoad.RouteTab) {
                                clickableDirectionServiceResult(
                                  selectedLoad.RouteTab[0]
                                );
                              } else {
                                getDirectionsServiceResult();
                              }
                            }}
                            mapContainerStyle={mapContainerStyles}
                          >
                            {route_directions &&
                              route_directions.map((rd, i) => (
                                <DirectionsRenderer
                                  key={`route-map-${i}`}
                                  directions={rd}
                                  onLoad={(
                                    directionsRenderer: google.maps.DirectionsRenderer
                                  ) => {
                                    console.log(
                                      "directionsRenderer",
                                      directionsRenderer
                                    );
                                  }}
                                  options={{
                                    polylineOptions: {
                                      strokeColor:
                                        decideColor(path_distance_index),
                                    },
                                  }}
                                />
                              ))}
                            {selectedLoad.RouteTab && (
                              <div
                                style={{
                                  position: "absolute",
                                  left: 10,
                                  top: 5,
                                }}
                              >
                                {selectedLoad.RouteTab.map((data, i) => {
                                  return (
                                    <div
                                      style={{
                                        position: "relative",
                                        backgroundColor: decideColor(i),
                                        padding: 5,
                                        marginTop: 5,
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        clickableDirectionServiceResult(data);
                                        setPathDistanceIndex(i);
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "black",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {data.route}
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                            )}

                            {path_distance !== "" && (
                              <div
                                style={{
                                  position: "absolute",
                                  right: 10,
                                  top: 5,
                                  padding: 5,
                                  backgroundColor: "#ededed",
                                  borderRadius: 10,
                                }}
                              >
                                <span
                                  style={{ color: "black", fontWeight: "bold" }}
                                >
                                  {path_distance}
                                </span>
                              </div>
                            )}
                          </GoogleMap>
                        )}
                      </ScrollBar>
                    </div>
                  );
                }}
              </Nav>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default LoadCard;
