import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, Row } from "rsuite";
import * as Yup from "yup";
import { Input, SelectPicker } from "../../../../../components";
import { editVehicleType } from "../../../../../redux/profile/profileSaga";
import {
  TransportWaysDto,
  TruckTypeDto,
  VehicleLengthDto,
  VehicleTypeDto,
} from "../../../../../redux/profile/type";
import {
  getLocalizedErrors,
  getLocalizedMyProfile,
} from "../../../../../redux/selectors";
const EditVehicleTypeForm = ({
  onSuccess,
  types,
  transports,
  selected,
  width,
  length,
  height,
  listData,
}: {
  listData: VehicleTypeDto[];
  width?: VehicleLengthDto[];
  length?: VehicleLengthDto[];
  height?: VehicleLengthDto[];
  onSuccess: () => void;
  types: TruckTypeDto[];
  transports: TransportWaysDto[];
  selected: VehicleTypeDto;
}) => {
  const errorStrings = useSelector(getLocalizedErrors);
  const strings = useSelector(getLocalizedMyProfile);
  const dispatch = useDispatch();
  console.log(selected);
  return (
    <Formik
      enableReinitialize
      initialValues={{
        id: selected.id,
        vehicletype__c: selected.vehicletype__c,
        transportways__c: selected.transportways__c,
        numberofvehicles__c: selected.numberofvehicles__c,
        vehiclewidth__c:
          selected.vehiclewidth__c != null ? selected.vehiclewidth__c : "",
        vehicleheight__c:
          selected.vehicleheight__c != null ? selected.vehicleheight__c : "",
        vehiclelength__c:
          selected.vehiclelength__c != null ? selected.vehiclelength__c : "",
      }}
      validationSchema={Yup.object().shape({
        transportways__c: Yup.string().required(
          errorStrings.getString("required")
        ),
        vehicletype__c: Yup.string().required(
          errorStrings.getString("required")
        ),
        numberofvehicles__c: Yup.number()
          .min(1, errorStrings.getString("required"))
          .typeError(errorStrings.getString("required"))
          .required(errorStrings.getString("required")),
      })}
      onSubmit={(values) => {
        values.id = selected.id;
        dispatch(
          editVehicleType({
            payload: { onSuccess: onSuccess, onError: () => {} },
            url: "Profile/UpdateVehicleType",
            body: values,
          })
        );
      }}
    >
      {({
        setFieldError,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        values,
      }) => (
        <Form>
          <Row>
            <Col md={8}>
              <SelectPicker
                cleanable={false}
                placement="bottomStart"
                searchable={true}
                style={{ marginBlockEnd: 10 }}
                label={strings.getString("transportways")}
                placeholder={strings.getString("transportways")}
                data={transports}
                name="to__c"
                labelKey={"value__c"}
                valueKey={"api_name__c"}
                value={values.transportways__c}
                onSelect={handleChange("transportways__c")}
                onBlur={handleBlur("transportways__c")}
                error={errors.transportways__c}
                touched={touched.transportways__c}
              />
            </Col>
            <Col md={8}>
              <SelectPicker
                placement="bottomStart"
                cleanable={false}
                searchable={true}
                labelKey={"description"}
                valueKey={"id"}
                style={{ marginBlockEnd: 10 }}
                label={strings.getString("vehicletype")}
                placeholder={strings.getString("vehicletype")}
                data={types}
                name="from__c"
                value={values.vehicletype__c}
                onSelect={(e) => {
                  if (
                    listData
                      .filter((x) => x.sfid !== selected.sfid)
                      .some((x) => x.vehicletype__c === e)
                  ) {
                    setFieldError(
                      "vehicletype__c",
                      strings.getString("samevehicletypeerror")
                    );
                    setFieldValue("vehicletype__c", "");
                  } else {
                    setFieldValue("vehicletype__c", e);
                  }
                }}
                onBlur={handleBlur("vehicletype__c")}
                error={errors.vehicletype__c}
                touched={touched.vehicletype__c}
              />
            </Col>
            <Col md={6}>
              <Input
                label={strings.getString("numberofvehicles")}
                name="numberofvehicles__c"
                value={values.numberofvehicles__c}
                onChange={handleChange(`numberofvehicles__c`)}
                onBlur={handleBlur("numberofvehicles__c")}
                error={errors.numberofvehicles__c}
                touched={touched.numberofvehicles__c}
              />
            </Col>
            {values.vehicletype__c === "Mega" && (
              <>
                <Col md={8}>
                  <SelectPicker
                    placement="bottomStart"
                    cleanable={false}
                    searchable={true}
                    labelKey={"description"}
                    valueKey={"id"}
                    style={{ marginBlockEnd: 10 }}
                    label={strings.getString("width")}
                    placeholder={strings.getString("width")}
                    data={width ? width : []}
                    name="vehiclewidth__c"
                    value={values.vehiclewidth__c}
                    onSelect={handleChange("vehiclewidth__c")}
                    onBlur={handleBlur("vehiclewidth__c")}
                    error={errors.vehiclewidth__c}
                    touched={touched.vehiclewidth__c}
                  />
                </Col>
                <Col md={7}>
                  <SelectPicker
                    placement="bottomStart"
                    cleanable={false}
                    searchable={true}
                    labelKey={"description"}
                    valueKey={"id"}
                    style={{ marginBlockEnd: 10 }}
                    label={strings.getString("height")}
                    placeholder={strings.getString("height")}
                    data={height ? height : []}
                    name="vehicleheight__c"
                    value={values.vehicleheight__c}
                    onSelect={handleChange("vehicleheight__c")}
                    onBlur={handleBlur("vehicleheight__c")}
                    error={errors.vehicleheight__c}
                    touched={touched.vehicleheight__c}
                  />
                </Col>
                <Col md={7}>
                  <SelectPicker
                    placement="bottomStart"
                    cleanable={false}
                    searchable={true}
                    labelKey={"description"}
                    valueKey={"id"}
                    style={{ marginBlockEnd: 10 }}
                    label={strings.getString("length")}
                    placeholder={strings.getString("length")}
                    data={length ? length : []}
                    name="vehiclelength__c"
                    value={values.vehiclelength__c}
                    onSelect={handleChange("vehiclelength__c")}
                    onBlur={handleBlur("vehiclelength__c")}
                    error={errors.vehiclelength__c}
                    touched={touched.vehiclelength__c}
                  />
                </Col>
              </>
            )}
            <Col md={2}>
              <div className="smResponsiveButtonDiv gridPosition">
                <button
                  type="submit"
                  className="gridIcon "
                  onClick={() => handleSubmit()}
                >
                  <i className="dx-icon-save" style={{ color: "white" }} />
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
export default EditVehicleTypeForm;
