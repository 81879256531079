import { Formik } from "formik";
import { useSelector } from "react-redux";
import { CheckPicker, Icon } from "../../../../../components";
import { Input, SelectPicker } from "../../../../../components";
import * as Yup from "yup";
import {
  getLocalizedErrors,
  getLocalizedTruckRegistration,
} from "../../../../../redux/selectors";
import { InputType } from "../../../../../components/Input";
import { RouteDto } from "../../../../../redux/opportunity/type";
import { generateUUID } from "../../../../../utils/Properties";
import { Col, Row } from "rsuite";

const CreateLocation = ({
  show,
  onHide,
  types,
  baseValues,
  errors,
  touched,
  onSubmit,
  onSelectDestination,
}: {
  onSelectDestination: (fromcode: string, tocode: string) => void;
  onSubmit: (
    values: {
      destinationId: string;
      destinationName: string;
      no: string;
      Guzergah__c: string[];
      CustomersWantsPrice__c: any;
    },
    routes: RouteDto[]
  ) => void;
  types: RouteDto[];
  onHide: () => void;
  show: boolean;
  baseValues: any;
  errors: any;
  touched: any;
}) => {
  // eslint-disable-next-line
  const strings = useSelector(getLocalizedTruckRegistration);
  const errorStrings = useSelector(getLocalizedErrors);
  return (
    <Formik
      initialValues={{
        destinationId: "",
        destinationName: "",
        no: generateUUID(),
        Guzergah__c: [],
        CustomersWantsPrice__c: "",
      }}
      validationSchema={Yup.object().shape({
        Guzergah__c: Yup.array()
          .min(1, errorStrings.getString("required"))
          .required(errorStrings.getString("required")),
      })}
      onSubmit={(values, { resetForm }) => {
        onSubmit(values, types);
        resetForm({
          values: {
            destinationId: "",
            destinationName: "",
            no: generateUUID(),
            Guzergah__c: [],
            CustomersWantsPrice__c: "",
          },
        });
      }}
    >
      {({
        setFieldValue,
        handleChange,
        handleSubmit,
        handleBlur,
        errors,
        touched,
        values,
      }) => (
        <>
          <Row>
            <Col md={8}>
              <SelectPicker
                cleanable={false}
                searchable={true}
                valueKey={"no"}
                labelKey={"location_To_Load__c"}
                value={values.destinationId}
                label={strings.getString("Destination")}
                data={baseValues.destinationList___c}
                onSelect={(value, item: any) => {
                  console.log(item, value);
                  setFieldValue("destinationId", value);
                  setFieldValue(
                    "destinationName",
                    item.location_To_Load__c_Name +
                      " > " +
                      item.location_To_Go__c_Name
                        .map((x: any) => x.label)
                        .toString()
                  );
                  item &&
                    onSelectDestination(
                      item.fromcountrycode,
                      item.tocountrycode
                    );
                }}
                onBlur={handleBlur("destinationId")}
                error={errors.destinationId}
                touched={touched.destinationId}
                renderValue={(label, item: any) => {
                  return item ? (
                    <>
                      {item.location_To_Load__c_Name} {" > "}
                      {item.location_To_Go__c_Name
                        .map((x: any) => x.label)
                        .toString()}
                    </>
                  ) : (
                    <></>
                  );
                }}
                renderMenuItem={(label, item: any) => {
                  return item ? (
                    <>
                      {item.location_To_Load__c_Name}
                      {" > "}
                      {item.location_To_Go__c_Name
                        .map((x: any) => x.label)
                        .toString()}
                    </>
                  ) : (
                    <></>
                  );
                }}
              />
            </Col>

            <Col md={7}>
              <CheckPicker
                disabled={
                  (baseValues.guzergahList__c.length > 0
                    ? types.filter(
                        (x) =>
                          !baseValues.guzergahList__c.some(
                            (y: any) => y.Guzergah__c === x.sfid
                          )
                      )
                    : types
                  ).length > 0
                    ? false
                    : true
                }
                placement="bottomEnd"
                label={strings.getString("Route")}
                placeholder={strings.getString("Route")}
                data={
                  baseValues.guzergahList__c.length > 0
                    ? types.filter(
                        (x) =>
                          !baseValues.guzergahList__c.some(
                            (y: any) => y.Guzergah__c === x.sfid
                          )
                      )
                    : types
                }
                value={values.Guzergah__c}
                onChange={(e: any) => setFieldValue("Guzergah__c", e)}
                style={{ width: "100%", height: "auto" }}
                labelKey={"name"}
                valueKey={"sfid"}
              />
            </Col>
            <Col md={7}>
              <Input
                disabled={
                  (baseValues.guzergahList__c.length > 0
                    ? types.filter(
                        (x) =>
                          !baseValues.guzergahList__c.some(
                            (y: any) => y.Guzergah__c === x.sfid
                          )
                      )
                    : types
                  ).length > 0
                    ? false
                    : true
                }
                inputType={InputType.number}
                value={values.CustomersWantsPrice__c}
                label={strings.getString("Requested Price")}
                name="CustomersWantsPrice__c"
                onChange={handleChange("CustomersWantsPrice__c")}
                onBlur={handleBlur("CustomersWantsPrice__c")}
                //@ts-ignore
                error={errors.CustomersWantsPrice__c}
                //@ts-ignore
                touched={touched.CustomersWantsPrice__c}
              />
            </Col>
            <Col md={1}>
              <div className="smResponsiveButtonDiv gridPosition">
                <button
                  disabled={
                    (baseValues.guzergahList__c.length > 0
                      ? types.filter(
                          (x) =>
                            !baseValues.guzergahList__c.some(
                              (y: any) => y.Guzergah__c === x.sfid
                            )
                        )
                      : types
                    ).length > 0
                      ? false
                      : true
                  }
                  type="submit"
                  className="gridIcon "
                  onClick={() => handleSubmit()}
                >
                  <Icon icon="plus" size={20} />
                </button>
              </div>
            </Col>
          </Row>
        </>
      )}
    </Formik>
  );
};
export default CreateLocation;
