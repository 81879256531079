import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import {
  Button,
  Footer,
  Icon,
  Modal,
  Pagination,
  SidebarFilter,
  Slider,
  TruckCard,
  TruckCardList,
  TruckCardVertical,
  SelectPicker,
  Input,
  DatePicker,
  Loader,
  CheckPicker,
} from "../../../components";
import {
  getLocalizedErrors,
  getLocalizedSearchTrucks,
} from "../../../redux/selectors";
import {
  GoogleMap,
  LoadScript,
  MarkerClusterer,
  Marker,
} from "@react-google-maps/api";
import {
  color,
  CUSTOM_MAP,
  GOOGLE_MAPS_API_KEY,
} from "../../../utils/Properties";
import "./style.scss";
import { LoadingTypes, LoadOpportunities, TruckObject } from "../../../types";
import { icons } from "../../../assets";
import { useReactToPrint } from "react-to-print";
import {
  Alert,
  Checkbox,
  Divider,
  Drawer,
  Form,
  Nav,
  Radio,
  RadioGroup,
} from "rsuite";
import * as Yup from "yup";
import { truckResults } from "./data";
import {
  AdrListDto,
  EquipmentDto,
  FrigoTypeDto,
  GumrukBeyannameListDto,
  LoadTypeDto,
  PackageTypeDto,
  VehicleOpportunityDto,
} from "../../../redux/opportunity/type";
import {
  createVehicleOffer,
  createVehicleOfferWithLoad,
  getAdrList,
  getCustoms,
  GetEquipments,
  GetFrigoTypes,
  getGumrukbeyannamelist,
  GetLoadTypes,
  getLocationForOpp,
  GetMainLoadTypes,
  GetPackageTypes,
  GetVehicleOpportunites,
} from "../../../redux/opportunity/saga";
import {
  getLoadingTypes,
  getLoadOpportunities,
  getVehicleOpportunityDetail,
  scrollToPosition,
} from "../../../redux/actions";
import { InputType } from "../../../components/Input";
import {
  getCountryList,
  getCurrencies,
} from "../../../redux/profile/profileSaga";
import { CountryDto, CurrencyDto } from "../../../redux/profile/type";
import { getCurrency } from "../../../utils/Helpers";
import ModalLoadCard from "./modal-load-card";
import moment from "moment";
import VehicleChatDrawer from "../../../components/VehicleChatDrawer";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { getLocalizedHelmet } from "../../../redux/selectors";
import AutoCompleteRs from "../../../components/AutoCompleteRs";

const mapOptions = {
  center: { lat: 40.930438, lng: 29.150688 },
  zoom: 10,
};

const SearchTrucks = () => {
  const helmetStrings = useSelector(getLocalizedHelmet);
  const [destinationValue, setDestinationValue] = useState<string | undefined>(
    undefined
  );
  const [departureValue, setDepartureValue] = useState<string | undefined>(
    undefined
  );
  const screen = useRef(null);
  let printerRef = useRef(null);
  let modalPrinterRef = useRef(null);
  const [opportunityMessage, setOpportunityMessage] = useState<any>();
  const dispatch = useDispatch();
  const strings = useSelector(getLocalizedSearchTrucks); // eslint-disable-next-line
  const errorStrings = useSelector(getLocalizedErrors);
  const [chatLoad, setChatLoad] = useState<VehicleOpportunityDto>();
  const [map, setMap] = useState<google.maps.Map>(); // eslint-disable-next-line
  const [selectedTruck, setSelectedTruck] = useState<VehicleOpportunityDto>();
  const [selectedTrucks, setSelectedTrucks] = useState<string[]>([]);
  const [truckDetails, setTruckDetails] = useState<string>("");
  const [favList, setFavList] = useState<string[]>([]); // eslint-disable-next-line
  const [activePage, setActivePage] = useState<number>(1);
  const [modalTruck, setModalTruck] = useState<VehicleOpportunityDto>();
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);
  const [bookingModal, setBookingModal] = useState<boolean>(false);
  const [bookingData, setBookingData] = useState<VehicleOpportunityDto>(); // eslint-disable-next-line
  const [step, setStep] = useState<number>(0);
  const [firstError, setFirstError] = useState<string>("");
  const [displayingTrucks, setDisplayingTrucks] =
    useState<VehicleOpportunityDto[]>();
  const [showCards, setShowCards] = useState<boolean>(false); // eslint-disable-next-line
  const [loading, setLoading] = useState<boolean>(false);
  const [loadData, setLoadData] = useState<VehicleOpportunityDto[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [take, setTake] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [filterQuery, setFilterQuery] = useState<string>("");
  const [verticalTruckCards, setVerticalTruckCards] = useState<
    (VehicleOpportunityDto | undefined)[]
  >([]);

  const [showChat, setShowChat] = useState<boolean>(false);
  const [currentLoads, setCurrentLoads] = useState<LoadOpportunities[]>();
  const [equipments, setEquipments] = useState<EquipmentDto[]>();
  const [currencies, setCurrencies] = useState<CurrencyDto[]>();
  const [mainLoadTypes, setMainLoadTypes] = useState<LoadTypeDto[]>();
  const [loadingTypes, setLoadingTypes] = useState<LoadingTypes[]>(); // eslint-disable-next-line
  const [loadTypes, setLoadTypes] = useState<LoadTypeDto[]>();
  const [packageTypes, setPackageTypes] = useState<PackageTypeDto[]>();
  const [frigoTypes, setFrigoTypes] = useState<FrigoTypeDto[]>();
  const [countries, setCountries] = useState<CountryDto[]>();
  const [adrList, setAdrList] = useState<AdrListDto[]>();
  const [accepted, setAccepted] = useState<boolean>();
  const [activeTab, setActiveTab] = useState("1");
  const location = useLocation();
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);
  const [gumrukbeyannameList, setGumrukbeyannameList] =
    useState<GumrukBeyannameListDto[]>();

  var elem1 = document.getElementById("innerscreen");
  console.log(elem1);
  elem1 && elem1.addEventListener("scroll", () => alert("hi"));
  const getPageData = () => {
    const array = new URLSearchParams(location.search);
    setLoading(true);
    setLoadData([]);
    setDisplayingTrucks([]);
    location.search.includes("opportunity")
      ? dispatch(
          GetVehicleOpportunites({
            payload: {
              onSuccess: (message, payload, response) => {
                setLoadData(payload?.list ? payload.list : []);
                setTotal(payload?.total ?? 0);
                setDisplayingTrucks(payload?.list ?? []);
                setLoading(false);
                response &&
                  response.SubResult &&
                  setOpportunityMessage(
                    strings.formatString(
                      strings.getString("loadopportunitymessage"),
                      response.SubResult
                    )
                  );
              },
              onError: (message) => {
                setLoading(false);
              },
            },
            url: `VehicleOpportunity/GetMatchedTruckOpportunities?_skip=${
              (page - 1) * take
            }&_take=${take}&opportunity=${array.get(
              "opportunity"
            )}&${filterQuery}`,
          })
        )
      : dispatch(
          GetVehicleOpportunites({
            payload: {
              onSuccess: (message, payload) => {
                setLoadData(payload?.list ? payload.list : []);

                setTotal(payload?.total ?? 0);
                setDisplayingTrucks(payload?.list ?? []);
                setLoading(false);
              },
              onError: (message) => {
                setLoading(false);
              },
            },
            url: `VehicleOpportunity/GetVehicleOpportunitiesNew?_skip=${
              (page - 1) * take
            }&_take=${take}&${filterQuery}`,
          })
        );
  };
  const getMapData = () => {
    setLoading(true);
    setShowCards(false);
    setLoadData([]);
    setVerticalTruckCards([]);
    dispatch(
      GetVehicleOpportunites({
        payload: {
          onSuccess: (message, payload) => {
            setLoadData(payload?.list ? payload.list : []);
            setTotal(payload?.total ?? 0);
            setDisplayingTrucks(payload?.list ?? []);
            setLoading(false);
          },
          onError: (message) => {
            setLoading(false);
          },
        },
        url: location.search.includes("opportunity")
          ? `VehicleOpportunity/GetMapMatchedTruckOpportunities?${filterQuery}`
          : `VehicleOpportunity/GetMapVehicleOpportunities?${filterQuery}`,
      })
    );
  };
  const onBookingModalOpen = (
    truck: VehicleOpportunityDto,
    accepted: boolean
  ) => {
    setBookingData(truck);
    setBookingModal(true);
    setAccepted(accepted);
    dispatch(
      getLoadOpportunities({
        payload: {
          onSuccess: (message, payload) => {
            setCurrentLoads(payload?.list);
          },
          onError: (message) => {
            setLoading(false);
          },
        },
        url: `VehicleOpportunity/GetLoadOpportunities`,
      })
    );
    dispatch(
      getCurrencies({
        payload: {
          onSuccess: (message, payload) => setCurrencies(payload),
          onError: (message) => {},
        },
        url: "System/GetCurrencyList",
      })
    );

    dispatch(
      getLoadingTypes({
        payload: {
          onSuccess: (message, payload) => setLoadingTypes(payload),
          onError: (message) => {},
        },
        url: "System/GetLoadingTypes",
      })
    );
    dispatch(
      GetFrigoTypes({
        payload: {
          onSuccess: (message, payload) => setFrigoTypes(payload),
          onError: (message) => {},
        },
      })
    );
    dispatch(
      GetEquipments({
        payload: {
          onSuccess: (message, payload) => setEquipments(payload),
          onError: (message) => {},
        },
      })
    );
    dispatch(
      getAdrList({
        payload: {
          onSuccess: (message, payload) => setAdrList(payload),
          onError: (message) => {},
        },
      })
    );
    dispatch(
      getGumrukbeyannamelist({
        payload: {
          onSuccess: (message, payload) => setGumrukbeyannameList(payload),
          onError: (message) => {},
        },
      })
    );
    dispatch(
      GetMainLoadTypes({
        payload: {
          onSuccess: (message, payload) => setMainLoadTypes(payload),
          onError: (message) => {},
        },
      })
    );
    dispatch(
      getCountryList({
        payload: {
          onSuccess: (message, payload) => {
            setCountries(payload);
          },
          onError: (message) => {},
        },
        url: "System/GetCountryList",
      })
    ); // eslint-disable-next-line
    dispatch(
      GetLoadTypes({
        payload: {
          onSuccess: (message, payload) => setLoadTypes(payload),
          onError: (message) => {},
        },
      })
    );
    dispatch(
      GetPackageTypes({
        payload: {
          onSuccess: (message, payload) => setPackageTypes(payload),
          onError: (message) => {},
        },
      })
    );
  };

  const selectedCheck = (id: string) => {
    let newArr: string[] = [...selectedTrucks];
    let findIndex = newArr.findIndex((truckId) => truckId === id);
    if (findIndex >= 0) {
      newArr.splice(findIndex, 1);
    } else {
      newArr.push(id);
    }
    setSelectedTrucks(newArr);
  };

  const isSelected = (id: string) => {
    let selected = selectedTrucks.some((truckId) => truckId === id);
    return selected;
  };

  const onModalOpen = (id: string, print: boolean = false) => {
    dispatch(
      getVehicleOpportunityDetail({
        payload: {
          onSuccess: (message, payload) => {
            setLoading(false);
            setModalTruck(payload);
            setTruckDetails(id);
            setModalVisibility(true);
          },
          onError: (message) => {
            setLoading(false);
          },
        },
        url: `VehicleOpportunity/GetByVehicleOpportunities?sfid=${id}`,
      })
    );
    // Open Print
  };

  const onModalClose = () => {
    setModalVisibility(false);
  };

  const openChat = (truck: VehicleOpportunityDto) => {
    setShowChat(true);
    history.push(
      location.search + "&chatId=" + truck.destination_vehicletype_vehicleopp__c
    );
    setChatLoad(truck);
  };

  const onBookingModalClose = () => {
    setActiveTab("1");
    setDestinationValue(undefined);
    setDepartureValue(undefined);
    setBookingModal(false);
    setAccepted(undefined);
    setCurrentLoads([]);
    setStep(0);
    if (activePage === 2) {
      getMapData();
    } else {
      getPageData();
    }
  };

  const handlePrint = useReactToPrint({
    content: () => printerRef.current,
  });
  const handleModalPrint = useReactToPrint({
    content: () => modalPrinterRef.current,
  });

  const loadHandler = (map: google.maps.Map<Element>) => {
    setMap(map);
  };

  const fitBounds = (map: google.maps.Map<Element>, trucks?: TruckObject[]) => {
    const bounds = new window.google.maps.LatLngBounds();
    trucks?.map((truck) => {
      bounds.extend(truck.location);
      return truck.id;
    });
    map.fitBounds(bounds);
  };

  function updateQueryStringParameter(uri: string, key: string, value: string) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf("?") !== -1 ? "&" : "?";
    if (uri.match(re)) {
      return uri.replace(re, "$1" + key + "=" + value + "$2");
    } else {
      return uri + separator + key + "=" + value;
    }
  }
  useEffect(() => {
    setLoadData([]);
    dispatch(scrollToPosition(0));

    if (activePage === 2) {
      getMapData();
    } else {
      getPageData();
    }
    window.history.pushState(
      "",
      document.title,
      updateQueryStringParameter(window.location.href, "page", page.toString())
    );
    window.history.pushState(
      "",
      document.title,
      updateQueryStringParameter(window.location.href, "take", take.toString())
    );
    console.log("FilterQuery", filterQuery);
    // eslint-disable-next-line
  }, [take, page, filterQuery]);

  useEffect(() => {
    if (map) {
      fitBounds(map, truckResults);
    }
  }, [map]);

  useEffect(() => {
    if (firstError && firstError.length > 0) {
      setStep(parseInt(firstError.charAt(firstError.length - 1)));
      setFirstError("");
    }
  }, [firstError]);

  const onSubmitPress = (values: any) => {
    // console.log("aasdfa", {
    //   ...values,
    //   equipments: values.equipmentsValue.join(";"),
    //   unloading_type__c: values.unloading_type__c.join(";"),
    //   loadingtype__c: values.loadingtype__c.join(";"),
    // });
    dispatch(
      createVehicleOffer({
        payload: {
          onSuccess: (message) => {
            Alert.success(strings.getString("notify_by_rapid"), 7000);
            onBookingModalClose();
          },
          onError: (message) => {
            Alert.error(message);
          },
        },
        body: {
          ...values,
          equipments: values.equipmentsValue.join(";"),
          unloading_type__c: values.unloading_type__c.join(";"),
          loadingtype__c: values.loadingtype__c.join(";"),
        },
      })
    );
  };

  return (
    <div
      className="innerScreen listScreen"
      id="innerscreen"
      ref={screen}
      style={{ overflowY: activePage === 2 ? "hidden" : "visible" }}
    >
      <Helmet>
        <title>{helmetStrings.getString("TruckHelmet")}</title>
      </Helmet>
      <VehicleChatDrawer
        setTruckUndefined={() => setChatLoad(undefined)}
        show={showChat}
        setShow={(show: boolean) => setShowChat(show)}
        truck={chatLoad}
      />
      <Modal visible={modalVisibility} close={onModalClose}>
        {modalTruck && (
          <TruckCard
            ref={modalPrinterRef}
            truck={modalTruck}
            selected={false}
            setSelected={(truckId) => selectedCheck(truckId)}
            details={truckDetails}
            setDetails={(truckId) => setTruckDetails(truckId)}
            favList={favList}
            setFavList={setFavList}
            //@ts-ignore
            openChat={() => openChat(selectedTruck)}
            openBooking={(truckId) => setBookingModal(true)}
            handlePrint={() => {
              //@ts-ignore
              handleModalPrint();
            }}
          />
        )}
      </Modal>
      <Modal
        title={strings.getString("quick_booking")}
        visible={bookingModal}
        close={onBookingModalClose}
      >
        <div style={{ width: "100%", height: "100%", paddingBlockEnd: 10 }}>
          {bookingData && (
            <>
              <Nav
                activeKey={activeTab}
                style={{ marginBlockEnd: 30 }}
                onSelect={(eventKey) => setActiveTab(eventKey)}
              >
                <Nav.Item eventKey="1">
                  {strings.getString("New Offer")}
                </Nav.Item>
              </Nav>
              {activeTab === "2" && (
                <Formik
                  initialValues={{
                    accepted: accepted,
                    vehicle_type__c: bookingData.id,
                    vehicle_opportunity__c: bookingData.parentsfid,
                    destination_vehicletype_vehicleopp__c:
                      bookingData.destination_vehicletype_vehicleopp__c,
                    route_vehicletype_vehicleopp__c: "",
                    opportunitydestination__c: "",
                    opportunityy__c: "", //parentsfi,
                    firsat_destinasyon_rota__c: "",
                    customer_requested_price__c: accepted
                      ? bookingData.estimatedamount
                      : "",
                  }}
                  validationSchema={Yup.object().shape({
                    customer_requested_price__c: Yup.string().when("accepted", {
                      is: () => {
                        return bookingData.estimatedamount ? true : false;
                      }, //just an e.g. you can return a function
                      then: Yup.string().required(
                        errorStrings.getString("required")
                      ),
                      otherwise: Yup.string(),
                    }),
                  })}
                  onSubmit={(values) => {
                    dispatch(
                      createVehicleOfferWithLoad({
                        payload: {
                          onSuccess: (message) => {
                            Alert.success(
                              strings.getString("notify_by_rapid"),
                              7000
                            );
                            onBookingModalClose();
                          },
                          onError: (message) => {
                            Alert.error(message);
                          },
                        },
                        body: values,
                      })
                    );
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    setFieldValue,
                    touched,
                  }) => {
                    return (
                      <Form style={{ width: "100%" }}>
                        <div>
                          {bookingData?.RouteTab ? (
                            <RadioGroup
                              name="route"
                              value={values.route_vehicletype_vehicleopp__c}
                              onChange={(v, e) => {
                                handleChange("route_vehicletype_vehicleopp__c")(
                                  v
                                );
                              }}
                            >
                              {bookingData?.RouteTab.map((route, i) => {
                                return (
                                  <div key={i}>
                                    <Radio
                                      value={route.sfid}
                                      disabled={route.shipperofferprice > 0}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div
                                          style={{
                                            marginInlineEnd: 10,
                                            width: `calc(100% - 120px)`,
                                          }}
                                        >
                                          {route.route +
                                            " " +
                                            (route.distance != null
                                              ? " - " +
                                                (route.distance?.toFixed(0) ??
                                                  0) +
                                                " km"
                                              : "")}
                                        </div>
                                        <div>
                                          {route.targetprice != null
                                            ? getCurrency(
                                                route.targetprice,
                                                route.currencyisocode
                                              )
                                            : ""}
                                        </div>
                                      </div>
                                    </Radio>
                                    {i + 1 !== bookingData?.RouteTab.length ? (
                                      <Divider style={{ margin: "10px 0" }} />
                                    ) : (
                                      <div style={{ height: 15 }} />
                                    )}
                                  </div>
                                );
                              })}
                            </RadioGroup>
                          ) : null}
                        </div>
                        <Divider />
                        <div>
                          {currentLoads ? (
                            <>
                              <RadioGroup
                                value={values.opportunitydestination__c}
                                onChange={(v, e) => {
                                  handleChange("opportunitydestination__c")(v);
                                  const value = currentLoads.find(
                                    (x) => x.id === v
                                  );
                                  value &&
                                    setFieldValue(
                                      "opportunityy__c",
                                      value.parentsfid
                                    );
                                }}
                              >
                                {currentLoads.map((x) => {
                                  return (
                                    <Radio value={x.id}>
                                      <ModalLoadCard load={x} />
                                    </Radio>
                                  );
                                })}
                              </RadioGroup>
                            </>
                          ) : (
                            <Loader
                              style={{
                                backgroundColor: "white" + color.transparent,
                              }}
                            />
                          )}
                        </div>
                        <div className="dividedRow">
                          <div style={{ width: "48%" }}></div>
                          <div style={{ width: "48%" }}>
                            <Input
                              inputType={InputType.number}
                              value={values.customer_requested_price__c}
                              label={strings.getString("Offer Price")}
                              name="customer_requested_price__c"
                              onChange={handleChange(
                                "customer_requested_price__c"
                              )}
                              disabled={accepted ? true : false}
                              onBlur={handleBlur("customer_requested_price__c")}
                              error={errors.customer_requested_price__c}
                              touched={touched.customer_requested_price__c}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            height: 48,
                            marginBlock: 15,
                            display: "flex",
                            paddingBlockEnd: 30,
                            justifyContent: "flex-end",
                          }}
                        >
                          <div style={{ width: 150 }}>
                            <Button
                              type="submit"
                              title={strings.getString("submit")}
                              onClick={() => handleSubmit()}
                            />
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              )}
              {activeTab === "1" && (
                <Formik
                  initialValues={{
                    route_vehicletype_vehicleopp__c: "",
                    currencyisocode: bookingData.currencyisocode,
                    value_of_goods__c: "",
                    yukbilgisigenislik__c: 0,
                    yukbilgisiuzunluk__c: 0,
                    vehicle_type__c: bookingData.id,
                    destination_vehicletype_vehicleopp__c:
                      bookingData.destination_vehicletype_vehicleopp__c,
                    yukbilgisiyukseklik__c: 0,
                    yukbilgisiambalajadedi__c: 0,
                    loadinglocation__c: "",
                    unloadinglocation__c: "",
                    main_load_type__c: "",
                    vehicle_opportunity__c: bookingData.parentsfid,
                    load_type__c: "",
                    loadingtype__c: [],
                    unloading_type__c: [],
                    yukbilgisiambalajsekli__c: "",
                    customer_requested_price__c: accepted
                      ? bookingData.estimatedamount
                      : "",
                    yukbilgisimusterireferansno__c: "",
                    frigo_celsius__c: "",
                    tonnage__c: 0,
                    load_date_start__c: moment(new Date()).format(),
                    load_date_end__c: moment(new Date()).format(),
                    customersrequestedarrivaldate__c: moment(
                      new Date()
                    ).format(),
                    adr__c: "",
                    equipments__c: "",
                    equipmentsValue: [],
                    is_doctor_necessary__c: false,
                    is_second_driver_necessary__c: false,
                    thirdcountrycertificate__c: false,
                    thirdcountryname__c: "",
                    gumrukyukbeyannamesi__c: false,
                    yukbilgisigumrukyukbeyannamesilistesi__c: "",
                    gtip_code__c: "",
                    fromcustoms__c: "",
                    tocustoms__c: "",
                    fromcustoms__c_Name: "",
                    tocustoms__c_Name: "",
                    main_load_type__c_VALUE: "",
                    accepted: accepted,
                  }}
                  validationSchema={Yup.object().shape({
                    load_date_start__c: Yup.string().required(
                      errorStrings.getString("required")
                    ),
                    load_date_end__c: Yup.string().required(
                      errorStrings.getString("required")
                    ),

                    unloadinglocation__c: Yup.string().required(
                      errorStrings.getString("required")
                    ),
                    loadinglocation__c: Yup.string().required(
                      errorStrings.getString("required")
                    ),
                    main_load_type__c: Yup.string().required(
                      errorStrings.getString("required")
                    ),
                    load_type__c: Yup.string().required(
                      errorStrings.getString("required")
                    ),
                    customer_requested_price__c: Yup.string().when("accepted", {
                      is: () => {
                        return bookingData.estimatedamount ? true : false;
                      }, //just an e.g. you can return a function
                      then: Yup.string().required(
                        errorStrings.getString("required")
                      ),
                      otherwise: Yup.string(),
                    }),
                    tonnage__c: Yup.number()
                      .min(1, errorStrings.getString("required"))
                      .required(errorStrings.getString("required")),
                  })}
                  onSubmit={onSubmitPress}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    setFieldValue,
                    touched,
                  }) => {
                    return (
                      <Form
                        className="truck-offer-modal-form-div"
                        style={{ width: "100%" }}
                      >
                        {bookingData?.RouteTab ? (
                          <RadioGroup
                            name="route"
                            value={values.route_vehicletype_vehicleopp__c}
                            onChange={(v, e) => {
                              handleChange("route_vehicletype_vehicleopp__c")(
                                v
                              );
                              const selectedRoute = bookingData.RouteTab.find(
                                (x) => x.sfid === v
                              );
                              selectedRoute &&
                                setFieldValue(
                                  "customer_requested_price__c",
                                  selectedRoute.targetprice
                                );
                            }}
                          >
                            {bookingData?.RouteTab.map((route, i) => {
                              return (
                                <div key={i}>
                                  <Radio
                                    value={route.sfid}
                                    disabled={route.shipperofferprice > 0}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginInlineEnd: 10,
                                          width: `calc(100% - 120px)`,
                                        }}
                                      >
                                        {route.route +
                                          " " +
                                          (route.distance != null
                                            ? " - " +
                                              (route.distance?.toFixed(0) ??
                                                0) +
                                              " km"
                                            : "")}
                                      </div>
                                      <div>
                                        {route.targetprice != null
                                          ? getCurrency(
                                              route.targetprice,
                                              route.currencyisocode
                                            )
                                          : ""}
                                      </div>
                                    </div>
                                  </Radio>
                                  {i + 1 !== bookingData?.RouteTab.length ? (
                                    <Divider style={{ margin: "10px 0" }} />
                                  ) : (
                                    <div style={{ height: 15 }} />
                                  )}
                                </div>
                              );
                            })}
                          </RadioGroup>
                        ) : null}
                        <Divider style={{ marginBlock: "0px 30px" }} />
                        <div className="dividedRow mblockend-10">
                          <div style={{ width: "48%" }}>
                            <span
                              style={{
                                fontSize: 13,
                                marginBlockEnd: 2,
                                color: color.textPrimary,
                              }}
                            >
                              {strings.getString("Loading Location")}
                            </span>

                            <AutoCompleteRs
                              selectedItem={departureValue}
                              error={errors.loadinglocation__c}
                              action={getLocationForOpp}
                              touch={touched.loadinglocation__c}
                              url="VehicleOpportunity/GetLocations"
                              onselectitem={(value) => {
                                setFieldValue("loadinglocation__c", value);
                              }}
                              onselectitemforlabel={(value) => {
                                setDepartureValue(value);
                              }}
                            />
                          </div>
                          <div style={{ width: "48%" }}>
                            <span
                              style={{
                                fontSize: 13,
                                marginBlockEnd: 2,
                                color: color.textPrimary,
                              }}
                            >
                              {strings.getString("Unloading Location")}
                            </span>
                            <AutoCompleteRs
                              selectedItem={destinationValue}
                              error={errors.unloadinglocation__c}
                              action={getLocationForOpp}
                              touch={touched.unloadinglocation__c}
                              url="VehicleOpportunity/GetLocations"
                              onselectitem={(value) => {
                                setFieldValue("unloadinglocation__c", value);
                              }}
                              onselectitemforlabel={(value) => {
                                setDestinationValue(value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="dividedRow mblockend-10">
                          <div style={{ width: "48%" }}>
                            <span
                              style={{
                                fontSize: 13,
                                marginBlockEnd: 2,
                                color: color.textPrimary,
                              }}
                            >
                              {strings.getString("From Customs")}
                            </span>

                            <AutoCompleteRs
                              selectedItem={values.fromcustoms__c_Name}
                              error={errors.fromcustoms__c}
                              action={getCustoms}
                              touch={touched.fromcustoms__c}
                              url="VehicleOpportunity/GetCustomsss"
                              onselectitem={(value) => {
                                setFieldValue("fromcustoms__c_Name", value);
                              }}
                              onselectitemforlabel={(value) => {
                                setFieldValue("fromcustoms__c", value);
                              }}
                            />
                          </div>
                          <div style={{ width: "48%" }}>
                            <span
                              style={{
                                fontSize: 13,
                                marginBlockEnd: 2,
                                color: color.textPrimary,
                              }}
                            >
                              {strings.getString("To Customs")}
                            </span>
                            <AutoCompleteRs
                              selectedItem={values.tocustoms__c_Name}
                              error={errors.tocustoms__c}
                              action={getCustoms}
                              touch={touched.tocustoms__c}
                              url="VehicleOpportunity/GetCustomsss"
                              onselectitem={(value) => {
                                setFieldValue("tocustoms__c", value);
                              }}
                              onselectitemforlabel={(value) => {
                                setFieldValue("tocustoms__c_Name", value);
                              }}
                            />
                          </div>
                        </div>

                        <div className="dividedRow mblockend-10">
                          <div style={{ width: "32%" }}>
                            <DatePicker
                              label={strings.getString("Load Start Date")}
                              name="start"
                              value={new Date(values.load_date_start__c)}
                              onChange={(e) => {
                                setFieldValue(
                                  "load_date_start__c",
                                  moment(e).format()
                                );
                              }}
                              onBlur={handleBlur("load_date_start__c")}
                            />
                          </div>
                          <div style={{ width: "32%" }}>
                            <DatePicker
                              label={strings.getString("Load End Date")}
                              name="start"
                              value={new Date(values.load_date_end__c)}
                              onChange={(e) => {
                                setFieldValue(
                                  "load_date_end__c",
                                  moment(e).format()
                                );
                              }}
                              onBlur={handleBlur("load_date_end__c")}
                            />
                          </div>
                          <div style={{ width: "32%" }}>
                            <DatePicker
                              label={strings.getString(
                                "Customers Requested Arrival Date"
                              )}
                              name="start"
                              value={
                                new Date(
                                  values.customersrequestedarrivaldate__c
                                )
                              }
                              onChange={(e) => {
                                setFieldValue(
                                  "customersrequestedarrivaldate__c",
                                  moment(e).format()
                                );
                              }}
                              onBlur={handleBlur(
                                "customersrequestedarrivaldate__c"
                              )}
                            />
                          </div>
                        </div>
                        <div className="dividedRow mblockend-10">
                          <div style={{ width: "48%" }}>
                            <SelectPicker
                              cleanable={false}
                              searchable={true}
                              valueKey={"api_name__c"}
                              labelKey={"value__c"}
                              value={values.main_load_type__c}
                              label={strings.getString("Main Load Type")}
                              data={mainLoadTypes ? mainLoadTypes : []}
                              onSelect={(value, item) => {
                                setFieldValue("main_load_type__c", value);
                                //@ts-ignore
                                item.api_name__c !== "FRİGO" &&
                                  setFieldValue("frigo_celsius__c", "");
                                //@ts-ignore
                                item.api_name__c !== "ADR" &&
                                  setFieldValue("adr__c", "");
                                //@ts-ignore
                                setFieldValue(
                                  "main_load_type__c_VALUE",
                                  //@ts-ignore
                                  item.api_name__c
                                );
                              }}
                              onBlur={handleBlur("main_load_type__c")}
                              error={errors.main_load_type__c}
                              touched={touched.main_load_type__c}
                            />
                          </div>
                          <div style={{ width: "48%" }}>
                            <Input
                              label={strings.getString("Load Type")}
                              name="load_type__c"
                              onChange={handleChange("load_type__c")}
                              onBlur={handleBlur("load_type__c")}
                              error={errors.load_type__c}
                              touched={touched.load_type__c}
                            />
                          </div>
                        </div>
                        <div
                          className={
                            values.main_load_type__c_VALUE === "ADR" ||
                            values.main_load_type__c_VALUE === "FRİGO"
                              ? "dividedRow mblockend-10"
                              : "dividedRow"
                          }
                        >
                          {values.main_load_type__c_VALUE === "ADR" && (
                            <div style={{ width: "48%" }}>
                              <SelectPicker
                                cleanable={false}
                                value={values.adr__c}
                                valueKey={"sfid"}
                                labelKey={"value__c"}
                                searchable={true}
                                label={strings.getString("ADR")}
                                data={adrList ? adrList : []}
                                onSelect={handleChange("adr__c")}
                                onBlur={handleBlur("adr__c")}
                                error={errors.adr__c}
                                touched={touched.adr__c}
                              />
                            </div>
                          )}
                          {values.main_load_type__c_VALUE === "FRİGO" && (
                            <div style={{ width: "48%" }}>
                              <SelectPicker
                                cleanable={false}
                                value={values.frigo_celsius__c}
                                valueKey={"sfid"}
                                labelKey={"value__c"}
                                searchable={true}
                                label={strings.getString("Frigo Celsius")}
                                data={frigoTypes ? frigoTypes : []}
                                onSelect={handleChange("frigo_celsius__c")}
                                onBlur={handleBlur("frigo_celsius__c")}
                                error={errors.frigo_celsius__c}
                                touched={touched.frigo_celsius__c}
                              />
                            </div>
                          )}
                        </div>
                        <div className="dividedRow">
                          <div style={{ width: "48%" }}>
                            <Input
                              label={strings.getString(
                                "Load Package Count(Type)"
                              )}
                              placeHolder="Değeri"
                              inputType={InputType.number}
                              selectPicker
                              selectPickerLabelKey={"value__c"}
                              selectPickerValueKey={"value__c"}
                              doubleinputType={true}
                              selectPickerItems={
                                packageTypes ? packageTypes : []
                              }
                              onChange={handleChange(
                                "yukbilgisiambalajadedi__c"
                              )}
                              selectPickerSelectedId={
                                values.yukbilgisiambalajsekli__c
                              }
                              selectPickerSelect={(id, item) => {
                                setFieldValue("yukbilgisiambalajsekli__c", id);
                              }}
                            />
                          </div>
                          <div style={{ width: "48%" }}>
                            <Input
                              label={strings.getString("Product HS Code")}
                              name="gtip_code__c"
                              onChange={handleChange("gtip_code__c")}
                              onBlur={handleBlur("gtip_code__c")}
                              error={errors.gtip_code__c}
                              touched={touched.gtip_code__c}
                            />
                          </div>
                        </div>
                        <div className="dividedRow  ">
                          <div style={{ width: "48%" }}>
                            <Input
                              inputType={InputType.number}
                              label={strings.getString("Tonnage")}
                              name="tonnage__c"
                              onChange={handleChange("tonnage__c")}
                              onBlur={handleBlur("tonnage__c")}
                              error={errors.tonnage__c}
                              touched={touched.tonnage__c}
                            />
                          </div>
                          <div style={{ width: "48%" }}>
                            <Input
                              inputType={InputType.number}
                              label={strings.getString("Load Width")}
                              name="yukbilgisigenislik__c"
                              onChange={handleChange("yukbilgisigenislik__c")}
                              onBlur={handleBlur("yukbilgisigenislik__c")}
                              error={errors.yukbilgisigenislik__c}
                              touched={touched.yukbilgisigenislik__c}
                            />
                          </div>
                        </div>
                        <div className="dividedRow ">
                          <div style={{ width: "48%" }}>
                            <Input
                              inputType={InputType.number}
                              label={strings.getString("Load Length")}
                              name="yukbilgisiuzunluk__c"
                              onChange={handleChange("yukbilgisiuzunluk__c")}
                              onBlur={handleBlur("yukbilgisiuzunluk__c")}
                              error={errors.yukbilgisiuzunluk__c}
                              touched={touched.yukbilgisiuzunluk__c}
                            />
                          </div>
                          <div style={{ width: "48%" }}>
                            <Input
                              inputType={InputType.number}
                              label={strings.getString("Load Height")}
                              name="yukbilgisiyukseklik__c"
                              onChange={handleChange("yukbilgisiyukseklik__c")}
                              onBlur={handleBlur("yukbilgisiyukseklik__c")}
                              error={errors.yukbilgisiyukseklik__c}
                              touched={touched.yukbilgisiyukseklik__c}
                            />
                          </div>
                        </div>
                        <div className="dividedRow mblockend-10">
                          <div style={{ width: "48%" }}>
                            <CheckPicker
                              placement="bottomEnd"
                              label={strings.getString("Loading Type")}
                              placeholder={strings.getString("Loading Type")}
                              data={loadingTypes ? loadingTypes : []}
                              value={values.loadingtype__c}
                              onChange={(e) =>
                                setFieldValue("loadingtype__c", e)
                              }
                              style={{ width: "100%", height: "auto" }}
                              labelKey={"description"}
                              valueKey={"id"}
                              error={errors.loadingtype__c}
                              touched={touched.loadingtype__c}
                            />
                          </div>
                          <div style={{ width: "48%" }}>
                            <CheckPicker
                              placement="bottomEnd"
                              label={strings.getString("Unloading Type")}
                              placeholder={strings.getString("Unloading Type")}
                              data={loadingTypes ? loadingTypes : []}
                              value={values.unloading_type__c}
                              onChange={(e) =>
                                setFieldValue("unloading_type__c", e)
                              }
                              style={{ width: "100%", height: "auto" }}
                              labelKey={"description"}
                              valueKey={"id"}
                              error={errors.unloading_type__c}
                              touched={touched.unloading_type__c}
                            />
                          </div>
                        </div>
                        <div className="dividedRow">
                          <div style={{ width: "48%" }}>
                            <Input
                              label={strings.getString("Reference No")}
                              name="yukbilgisimusterireferansno__c"
                              onChange={handleChange(
                                "yukbilgisimusterireferansno__c"
                              )}
                              onBlur={handleBlur(
                                "yukbilgisimusterireferansno__c"
                              )}
                              error={errors.yukbilgisimusterireferansno__c}
                              touched={touched.yukbilgisimusterireferansno__c}
                            />
                          </div>
                          <div style={{ width: "48%" }}>
                            <CheckPicker
                              label={strings.getString("Equipments")}
                              placement="topStart"
                              placeholder={strings.getString("Equipments")}
                              data={equipments ? equipments : []}
                              value={values.equipmentsValue}
                              onChange={(e) =>
                                setFieldValue("equipmentsValue", e)
                              }
                              valueKey="id"
                              style={{ width: "100%", height: "auto" }}
                              labelKey="description"
                            />
                          </div>
                        </div>
                        <div className="dividedRow ">
                          <div style={{ width: "48%" }}>
                            <Input
                              inputType={InputType.number}
                              value={values.customer_requested_price__c}
                              label={strings.getString("Offer Price")}
                              name="customer_requested_price__c"
                              onChange={handleChange(
                                "customer_requested_price__c"
                              )}
                              disabled={accepted ? true : false}
                              onBlur={handleBlur("customer_requested_price__c")}
                              error={errors.customer_requested_price__c}
                              touched={touched.customer_requested_price__c}
                            />
                          </div>
                          <div style={{ width: "48%" }}>
                            <Input
                              label={strings.getString("Value of Goods")}
                              inputType={InputType.number}
                              selectPicker
                              selectPickerLabelKey={"isocode"}
                              selectPickerValueKey={"isocode"}
                              doubleinputType={true}
                              selectPickerItems={currencies ? currencies : []}
                              onChange={handleChange("value_of_goods__c")}
                              selectPickerSelectedId={values.currencyisocode}
                              selectPickerdisabled={true}
                              selectPickerSelect={(id, item) => {
                                setFieldValue("currencyisocode", id);
                              }}
                            />
                          </div>
                        </div>

                        <div className="dividedRow">
                          <div
                            className="checkbox-input"
                            style={{ width: "17%" }}
                          >
                            <Checkbox
                              name="partial"
                              checked={values.is_doctor_necessary__c}
                              onChange={(value, checked) =>
                                setFieldValue("is_doctor_necessary__c", checked)
                              }
                            >
                              {strings.getString("Is Doctor Necessary")}
                            </Checkbox>
                          </div>
                          <div
                            className="checkbox-input"
                            style={{ width: "20%" }}
                          >
                            <Checkbox
                              name="partial"
                              checked={values.is_second_driver_necessary__c}
                              onChange={(value, checked) =>
                                setFieldValue(
                                  "is_second_driver_necessary__c",
                                  checked
                                )
                              }
                            >
                              {strings.getString("Is Second Driver Necessary")}
                            </Checkbox>
                          </div>
                          <div
                            className="checkbox-input"
                            style={{ width: "27%" }}
                          >
                            <Checkbox
                              name="partial"
                              checked={values.thirdcountrycertificate__c}
                              onChange={(value, checked) =>
                                setFieldValue(
                                  "thirdcountrycertificate__c",
                                  checked
                                )
                              }
                            >
                              {strings.getString("Third Country Certificate")}
                            </Checkbox>
                          </div>
                          <div
                            className="checkbox-input"
                            style={{ width: "25%" }}
                          >
                            <Checkbox
                              name="partial"
                              checked={values.gumrukyukbeyannamesi__c}
                              onChange={(value, checked) =>
                                setFieldValue(
                                  "gumrukyukbeyannamesi__c",
                                  checked
                                )
                              }
                            >
                              {strings.getString("Customs Load Declaration")}
                            </Checkbox>
                          </div>
                        </div>
                        <div className="dividedRow  mblockend-10">
                          {values.thirdcountrycertificate__c && (
                            <div style={{ width: "48%" }}>
                              <SelectPicker
                                cleanable={false}
                                value={values.thirdcountryname__c}
                                labelKey={"countryname__c"}
                                valueKey={"name"}
                                searchable={true}
                                label={strings.getString("Third Country")}
                                data={countries ? countries : []}
                                onSelect={handleChange("thirdcountryname__c")}
                                onBlur={handleBlur("thirdcountryname__c")}
                                error={errors.thirdcountryname__c}
                                touched={touched.thirdcountryname__c}
                              />
                            </div>
                          )}
                          {values.gumrukyukbeyannamesi__c && (
                            <div style={{ width: "48%" }}>
                              <SelectPicker
                                className="defaultInput customs-select-picker"
                                style={{ maxHeight: 50 }}
                                cleanable={false}
                                value={
                                  values.yukbilgisigumrukyukbeyannamesilistesi__c
                                }
                                labelKey={"value__c"}
                                valueKey={"sfid"}
                                searchable={true}
                                label={strings.getString(
                                  "Customs Load Declaration"
                                )}
                                data={
                                  gumrukbeyannameList ? gumrukbeyannameList : []
                                }
                                onSelect={(value) =>
                                  setFieldValue(
                                    "yukbilgisigumrukyukbeyannamesilistesi__c",
                                    value
                                  )
                                }
                                onBlur={handleBlur(
                                  "yukbilgisigumrukyukbeyannamesilistesi__c "
                                )}
                                error={
                                  errors.yukbilgisigumrukyukbeyannamesilistesi__c
                                }
                                touched={
                                  touched.yukbilgisigumrukyukbeyannamesilistesi__c
                                }
                              />
                            </div>
                          )}
                        </div>
                        <div
                          style={{
                            width: "100%",
                            height: 48,
                            marginBlock: 15,
                            display: "flex",
                            paddingBlockEnd: 30,
                            justifyContent: "flex-end",
                          }}
                        >
                          <div style={{ width: 150 }}>
                            <Button
                              type="submit"
                              title={strings.getString("submit")}
                              onClick={() => handleSubmit()}
                            />
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              )}
            </>
          )}
        </div>
      </Modal>{" "}
      <div className="mobile-search-sidebarfilter">
        <Drawer
          className="mobile-search-sidebarfilter-drawer"
          placement={"left"}
          show={showMenu}
          onHide={() => setShowMenu(false)}
        >
          <Drawer.Body>
            <SidebarFilter
              isTruck={true}
              setFilterQuery={(f) => {
                setFilterQuery(f);
              }}
              setPage={(p) => {
                setPage(p);
              }}
            />
          </Drawer.Body>
        </Drawer>
      </div>
      <div className="innerScreenContent search-innerScreenContent">
        {activePage === 1 && (
          <div
            className="sidebarFilter search-sidebarfilter-main-div"
            style={{ backgroundColor: color.pageBgColor }}
          >
            <div className="sidebarFilterInnerContent">
              <SidebarFilter
                isTruck={true}
                setFilterQuery={(f) => {
                  setFilterQuery(f);
                }}
                setPage={(p) => {
                  setPage(p);
                }}
              />
            </div>
          </div>
        )}
        <div
          className={
            "searchTrucksPageContent  searchtruckpagecontent-main" +
            (activePage === 2 ? " width-100" : "")
          }
          style={{
            width: activePage === 2 ? "100%" : "unset",
          }}
        >
          <div
            className={
              "searchTrucksPageInnerContent " +
              (activePage === 2 ? " width-100" : "")
            }
            style={{
              width: activePage === 2 ? "100%" : 934,
              position: activePage === 2 ? "absolute" : "unset",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                paddingBlock: 15,
                paddingInline: "30px 0px",
              }}
            >
              <div
                className={
                  "resultsResponsive" + (activePage === 2 ? " width-95" : "")
                }
                style={{
                  width: "100%",
                  height: "max-content",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBlockEnd: 26,
                }}
              >
                <div
                  className="headingStyle search-truck-result"
                  style={{ marginBlockEnd: 0, fontWeight: 400 }}
                >
                  {strings.getString("results")}
                  <p
                    style={{
                      fontSize: 12,
                      color: color.textPrimary,
                      marginBlockStart: 5,
                    }}
                  >
                    {strings.formatString(
                      strings.getString("results_found"),
                      String(total)
                    )}
                  </p>
                </div>

                <div className="responsivefilterButtonsOuterDiv">
                  <div
                    className="filterButtons"
                    style={{ backgroundColor: color.side2, color: "white" }}
                    onClick={() => {
                      setShowMenu(true);
                    }}
                  >
                    <Icon icon="preferrences" size={20} />
                  </div>
                </div>
                <div className="filterButtonsOuterDiv">
                  <div
                    className="filterButtons"
                    style={{
                      backgroundColor: color.accent,
                      border: "1px solid",
                      borderColor: color.textPrimary,
                    }}
                    onClick={() => {
                      setLoadData([]);
                      scrollToPosition(0);
                      const activePageSet = activePage === 1 ? 2 : 1;
                      if (activePageSet === 2) {
                        getMapData();
                      } else getPageData();
                      setActivePage(activePageSet);
                    }}
                  >
                    <Icon icon={activePage === 1 ? "map" : "list"} size={20} />
                    <span style={{ marginInlineEnd: 4 }}>
                      {activePage === 1
                        ? strings.getString("map_view")
                        : strings.getString("list_view")}
                    </span>
                  </div>
                  {activePage === 2 && (
                    <div
                      className="filterButtons"
                      style={{ backgroundColor: color.side2, color: "white" }}
                      onClick={() => {
                        setShowMenu(true);
                      }}
                    >
                      <Icon icon="preferrences" size={20} /> Filter
                    </div>
                  )}
                </div>
              </div>
              {activePage === 1 && (
                <div className="searchResults">
                  {opportunityMessage && (
                    <p
                      style={{
                        fontWeight: 700,
                        fontSize: 24,
                        textAlign: "center",
                        border: "1px solid " + color.bgSecondary,
                        padding: 10,
                      }}
                    >
                      {opportunityMessage}
                    </p>
                  )}
                  <TruckCardList
                    openModal={(id) => onModalOpen(id)}
                    trucks={displayingTrucks || []}
                    favList={favList}
                    setFavList={(favs) => setFavList(favs)}
                    isSelected={(id) => isSelected(id)}
                    setSelected={(id) => selectedCheck(id)}
                    truckDetails={truckDetails}
                    setTruckDetails={(id) => setTruckDetails(id)}
                    openChat={(truck) => openChat(truck)}
                    openBooking={(truck, accepted) => {
                      onBookingModalOpen(truck, accepted);
                    }}
                    handlePrint={(ref) => {
                      printerRef = ref;
                      //@ts-ignore
                      handlePrint();
                    }}
                  />
                  <Pagination
                    data={loadData}
                    totalCount={total}
                    take={take}
                    page={page}
                    itemListArray={[
                      { value: 10, label: 10 },
                      { value: 20, label: 20 },
                      { value: 30, label: 30 },
                    ]}
                    setTake={(t) => {
                      setTake(t);
                    }}
                    setPage={(p) => {
                      setPage(p);
                    }}
                    returnedData={(dataArr) => setDisplayingTrucks(dataArr)}
                  />
                </div>
              )}
            </div>
          </div>
          {activePage === 2 && (
            <>
              <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
                <GoogleMap
                  id="rapidMap"
                  mapContainerClassName="rapid-mapview"
                  options={{
                    gestureHandling: "cooperative",
                    styles: CUSTOM_MAP,
                    disableDefaultUI: true,
                    zoomControl: true,
                  }}
                  mapContainerStyle={{
                    width: "100%",
                    //@ts-ignore
                    height: "calc(100vh - 195px)",
                    position: "relative",
                    top: 0,
                  }}
                  center={mapOptions.center}
                  zoom={mapOptions.zoom}
                  onLoad={(map) => loadHandler(map)}
                >
                  <MarkerClusterer
                    onClick={(cluster) => {
                      verticalTruckCards.length > 0 &&
                        setVerticalTruckCards([]);
                      let clusterMarkerIDs: string[] = [];
                      cluster.markers?.forEach((marker) => {
                        const id = marker.getTitle();
                        if (id) clusterMarkerIDs.push(id);
                      });
                      let filteredTrucks: VehicleOpportunityDto[] =
                        loadData.filter((tr) =>
                          clusterMarkerIDs.some((cmi) => cmi === tr.id)
                        );
                      setVerticalTruckCards(filteredTrucks);
                      setShowCards(true);
                    }}
                  >
                    {(clusterer) =>
                      loadData.map((truck) => (
                        <Marker
                          key={truck.id}
                          //options={{ clickable: true }}
                          position={{
                            lat: truck.startLat,
                            lng: truck.startLong,
                          }}
                          clusterer={clusterer}
                          icon={icons.m1}
                          title={truck.id}
                          label="1"
                          onClick={() => {
                            const foundTruck = loadData.find(
                              (tr) => tr.id === truck.id
                            );
                            setVerticalTruckCards([foundTruck]);
                            setShowCards(true);
                          }}
                        />
                      ))
                    }
                  </MarkerClusterer>
                </GoogleMap>
              </LoadScript>
              <div
                style={{
                  width: "90%",
                  height: showCards ? 500 : 25,
                  overflow: "hidden",
                  marginInlineStart: "5%",
                  position: "relative",
                  zIndex: 1,
                  top: showCards ? -552 : -177,
                  transition: "0.3s ease-in-out",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    width: 25,
                    height: 25,
                    margin: "auto",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowCards(!showCards)}
                >
                  <Icon
                    icon={showCards ? "down" : "up"}
                    color={color.primary}
                  />
                </div>
                {verticalTruckCards.length > 0 && (
                  <Slider
                    noArrows={true}
                    noInfinite={true}
                    responsive={[
                      {
                        breakpoint: 3841,
                        settings: {
                          slidesToShow: 10,
                          slidesToScroll: 10,
                        },
                      },
                      {
                        breakpoint: 2561,
                        settings: {
                          slidesToShow: 5,
                          slidesToScroll: 5,
                        },
                      },
                      {
                        breakpoint: 1920,
                        settings: {
                          slidesToShow: 3,
                          slidesToScroll: 3,
                        },
                      },
                      {
                        breakpoint: 1280,
                        settings: {
                          slidesToShow: 2,
                          slidesToScroll: 2,
                        },
                      },
                      {
                        breakpoint: 928,
                        settings: {
                          slidesToShow: 1,
                          slidesToScroll: 1,
                          centerMode: false,
                        },
                      },
                    ]}
                  >
                    {verticalTruckCards.map((truck, i) => {
                      if (truck) {
                        return (
                          <TruckCardVertical
                            openModal={(id) => onModalOpen(id)}
                            truck={truck}
                            favList={favList}
                            setFavList={(favs) => setFavList(favs)}
                            isSelected={(id) => isSelected(id)}
                            setSelected={(id) => selectedCheck(id)}
                            truckDetails={truckDetails}
                            setTruckDetails={(id) => setTruckDetails(id)}
                            openChat={(truck) => openChat(truck)}
                            openBooking={(truck, accepted) => {
                              onBookingModalOpen(truck, accepted);
                            }}
                            handlePrint={(ref) => {
                              printerRef = ref;
                              //@ts-ignore
                              handlePrint();
                            }}
                          />
                        );
                      } else return <></>;
                    })}
                  </Slider>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SearchTrucks;
