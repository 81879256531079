import { forwardRef, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { GoogleMap, DirectionsRenderer } from "@react-google-maps/api";
import moment from "moment";
import Flag from "react-flagkit";
import "./style.scss";
import { LoadOpportunities, RouteObject } from "../../../types";
import { getLocalizedLoadCard } from "../../../redux/selectors";
import {
  color,
  CUSTOM_MAP,
  mapContainerStyles,
} from "../../../utils/Properties";
import { Icon, Nav, Whisper } from "../../../components";
import { getCurrency } from "../../../utils/Helpers";
import { Divider, Radio, RadioGroup } from "rsuite";
import { useFormikContext } from "formik";

type LoadCardProps = {
  load: LoadOpportunities;
};

const LoadCard = forwardRef<HTMLDivElement, LoadCardProps>(({ load }, ref) => {
  const { handleChange, values } = useFormikContext();
  const [details, setDetails] = useState<string>();
  const strings = useSelector(getLocalizedLoadCard);
  const cardRef = useRef(null);
  const [route_directions, setRouteDirections] = useState<
    google.maps.DirectionsResult[]
  >([]);

  const [path_distance, setPathDistance] = useState<string>("");
  const [path_distance_index, setPathDistanceIndex] = useState<number>(0);

  const navContent = [
    {
      id: 0,
      icon: "list",
      activeColor: color.primary,
      label: strings.getString("details"),
    },
    {
      id: 1,
      icon: "indicator",
      activeColor: color.primary,
      label: strings.getString("extra_infos"),
    },
    {
      id: 2,
      icon: "shorter-list",
      activeColor: color.primary,
      label: strings.getString("other_details"),
    },
    {
      id: 3,
      icon: "route",
      activeColor: color.primary,
      label: strings.getString("routes"),
    },
    {
      id: 4,
      icon: "map-pin",
      activeColor: color.primary,
      label: strings.getString("map"),
    },
  ];

  const postEndCalculater =
    new Date(load.postedat ? load.postedat : new Date()).getTime() -
    new Date().getTime();

  function decideColor(i: number) {
    switch (i) {
      case 0:
        return "blue";
      case 1:
        return "orange";
      case 2:
        return "yellow";
      case 3:
        return "black";
      case 4:
        return "pink";
      case 5:
        return "gray";

      default:
        return "red";
    }
  }

  function clickableDirectionServiceResult(data: RouteObject) {
    let waypoints: google.maps.DirectionsWaypoint[] = [];
    if (data.koordinat) {
      JSON.parse(data.koordinat)?.forEach((data: any) => {
        waypoints.push({
          location: new google.maps.LatLng(data.Latitude, data.Longtitude),
          stopover: true,
        });
      });
    }
    setRouteDirections([]);
    getDirectionsServiceResult(waypoints);
  }

  const getDirectionsServiceResult = (
    waypoints?: google.maps.DirectionsWaypoint[]
  ) => {
    if (load.DetailTab && load.DetailTab.ll1name && load.DetailTab.ul1name) {
      const DirectionsService = new google.maps.DirectionsService();
      console.log("WAYPOINTS", waypoints);
      DirectionsService.route(
        {
          origin: load.DetailTab.ll1name,
          destination: load.DetailTab.ul1name,
          waypoints,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          console.log("ROUTE RESULT", result);
          if (status === google.maps.DirectionsStatus.OK) {
            setRouteDirections((rds) => [...rds, result]);
            console.log("LEGS", result.routes);
            let total_distance = 0;
            if ((result?.routes[0]?.legs?.length ?? 0) > 0) {
              result.routes[0].legs.forEach((l) => {
                total_distance += l.distance.value;
              });
            }
            if (total_distance > 0) {
              setPathDistance(Math.round(total_distance / 1000) + "km");
            } else setPathDistance("");
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    }
  };

  if (!load.DetailTab) return null;
  //eslint-disable-next-line
  const calculateDiff = (startDate: string, endDate: string) => {
    const start = moment(startDate);
    const end = moment(endDate);

    const totalDay = start.diff(end, "day");

    return totalDay;
  };

  return (
    <div key={load.id} ref={cardRef}>
      <div
        ref={ref}
        className="truckResultCard"
        style={{
          fontSize: 10,
          color: color.textPrimary + color.thirtyOpacity,
          borderColor: "#e6e6e6",
          backgroundColor: color.pageBgColor,
        }}
      >
        <div style={{ width: "100%", display: "flex" }}>
          <div
            style={{
              width: `calc(100% - 36px)`,
              paddingBlock: "12px 2px",
              paddingInline: 10,
              display: "flex",
              marginInlineStart: 10,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              className="vehicleLogo"
              style={{
                backgroundColor: color.accent,
                borderRadius: 10,
              }}
            >
              <img
                className="imageStyles"
                style={{ borderRadius: 10 }}
                src={load.Owner?.profileimage}
                alt=""
              />
            </div>
            <div
              className="truckResultCardMainDetails smallRegularText"
              style={{
                width: "85%",
              }}
            >
              <div>
                <p className="boldText" style={{ color: color.blackFifty }}>
                  {strings.getString("file_number")}
                </p>
                <p>{"#" + load.opdfilenumber}</p>
              </div>
              <div>
                <p className="boldText" style={{ color: color.blackFifty }}>
                  {strings.getString("load_type")}
                </p>
                <p>{load.loadtype}</p>
              </div>
              <div>
                <p className="boldText" style={{ color: color.blackFifty }}>
                  {strings.getString("route")}
                </p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingBlockStart: 8,
                  }}
                >
                  <p>{load.fromcountryid ? load.fromcountryid : "-"}</p>
                  <Icon
                    icon="right-bold"
                    size={13}
                    style={{ marginInline: 3 }}
                  />
                  <p>{load.tocountryid ? load.tocountryid : "-"}</p>
                </div>
              </div>
              <div>
                <p className="boldText" style={{ color: color.blackFifty }}>
                  {strings.getString("date")}
                </p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingBlockStart: 8,
                  }}
                >
                  <p>
                    {load.loadbegindate
                      ? moment(load.loadbegindate).format("l")
                      : "-"}
                  </p>
                  <Icon
                    icon="right-bold"
                    size={13}
                    style={{ marginInline: 3 }}
                  />
                  <p>
                    {load.loadenddate
                      ? moment(load.loadenddate).format("l")
                      : "-"}
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: 44,
                  width: "max-content",
                  border: "1px solid",
                  borderColor: color.borderSecondary,
                  borderRadius: 5,
                  cursor: "pointer",
                  backgroundColor: color.accent,
                }}
              >
                <Whisper speaker={strings.getString("details")}>
                  <div
                    className="extraInfosButton"
                    style={{
                      borderColor: color.borderSecondary,
                      backgroundColor:
                        details === load.id ? "#74747416" : "transparent",
                      borderStartStartRadius: 5,
                      borderEndStartRadius: 5,
                    }}
                    onClick={() => {
                      details === load.id
                        ? setDetails("")
                        : setDetails(load.id);
                    }}
                  >
                    <Icon icon="more" />
                  </div>
                </Whisper>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            marginInlineStart: 8,
            marginBlockEnd: 5,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ marginInlineStart: 10 }}>
            <p
              className="boldText"
              style={{ fontSize: 14, color: color.textPrimary }}
            >
              {load.rapidadvisorname}
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{ width: 160, textAlign: "center", marginInlineEnd: -18 }}
            >
              <p className="smallBoldText" style={{ color: color.blackFifty }}>
                {strings.getString("post_end_date")}
              </p>
              <span
                className="thinText"
                style={
                  postEndCalculater < 432000000 && postEndCalculater > 0
                    ? { color: color.error }
                    : {}
                }
              >
                {load.postendsat ? moment(load.postendsat).format("l") : "-"}
              </span>
            </div>
          </div>
        </div>
        <div
          className="extraInfos"
          style={
            details === load.id
              ? {
                  color: color.textPrimary + color.fiftyOpacity,
                  borderColor: color.black + "16",
                  borderBlockEnd: "1px solid #18181816",
                  backgroundColor: "#f5f5f5",
                }
              : {
                  color: color.textPrimary + color.fiftyOpacity,
                  borderColor: color.black + "16",
                  borderEndEndRadius: 5,
                  borderEndStartRadius: 5,
                }
          }
        >
          <div
            className="standardText"
            style={{
              display: "flex",
              alignItems: "center",
              marginInlineEnd: 50,
            }}
          >
            <Whisper speaker={strings.getString("weight")}>
              <div style={{ cursor: "pointer", marginInlineEnd: 20 }}>
                <Icon icon="tonne" size={14} style={{ marginInlineEnd: 5 }} />
                <span>{load.weight + " t"}</span>
              </div>
            </Whisper>
            <Whisper speaker={strings.getString("volume")}>
              <div style={{ cursor: "pointer", marginInlineEnd: 20 }}>
                <Icon icon="ruler" size={12} style={{ marginInlineEnd: 5 }} />
                <span>
                  {load.cubicmeters
                    ? new Intl.NumberFormat("tr-TR", {
                        maximumSignificantDigits: 20,
                      }).format(load.cubicmeters) + " m³"
                    : "-"}
                </span>
              </div>
            </Whisper>
            <Whisper
              speaker={
                <p>
                  <p style={{ fontWeight: 700 }}>
                    {strings.getString("desired_truck_number") + ": "}
                  </p>
                  {load.loadTrucks?.map((type, i) => (
                    <p key={i}>{`${type.vehicletype} / ${parseInt(
                      type.vehiclenumber__c.toString()
                    )}`}</p>
                  ))}
                </p>
              }
            >
              <div
                style={{
                  display: "flex",
                  marginInlineEnd: 20,
                  cursor: "pointer",
                }}
              >
                <Icon icon="truck" size={20} style={{ marginInlineEnd: 5 }} />
                <p style={{ marginInlineEnd: 3 }}>
                  {load.trucktype}
                  {load.loadTrucks &&
                    load.loadTrucks?.length > 1 &&
                    strings.formatString(
                      strings.getString("more"),
                      load.loadTrucks.length - 1
                    )}
                </p>
                {load?.loadTrucks && load?.loadTrucks?.length > 0 && (
                  <span
                    style={{
                      textAlign: "center",
                      paddingBlockStart: 2,
                      width: 18,
                      height: 18,
                      borderRadius: 40,
                      color: color.accent,
                      backgroundColor: color.side3,
                      fontSize: 11,
                    }}
                  >
                    {parseInt(
                      load?.loadTrucks[0]?.vehiclenumber__c.toString() ??
                        "0".toString()
                    )}
                  </span>
                )}
              </div>
            </Whisper>
            <Whisper speaker={strings.getString("distance")}>
              <div style={{ cursor: "pointer", marginInlineEnd: 30 }}>
                <Icon
                  icon="distance"
                  size={17}
                  style={{ marginInlineEnd: 5 }}
                />
                <span>{(load.mindistance?.toFixed(0) ?? 0) + " km"}</span>
              </div>
            </Whisper>
            <Whisper
              speaker={
                strings.getString("pack_type") +
                " / " +
                strings.getString("number_of_packs")
              }
            >
              <div
                style={{
                  display: "flex",
                  marginInlineEnd: 20,
                  cursor: "pointer",
                }}
              >
                <Icon
                  icon="box-stacked"
                  size={17}
                  style={{ marginInlineEnd: 5 }}
                />
                <p style={{ marginInlineEnd: 3 }}>
                  {load.packagetype ? load.packagetype : "-"}
                </p>
                {load.packagequantity > 0 && (
                  <span
                    style={{
                      textAlign: "center",
                      paddingBlockStart: 2,
                      width: 18,
                      height: 18,
                      borderRadius: 40,
                      color: color.accent,
                      backgroundColor: color.side3,
                      fontSize: 11,
                    }}
                  >
                    {load.packagequantity}
                  </span>
                )}
              </div>
            </Whisper>
          </div>
        </div>
        <div
          className="extraInfos"
          style={
            details === load.id
              ? {
                  color: color.textPrimary + color.fiftyOpacity,
                  borderColor: color.black + "16",
                  borderBlockEnd: "1px solid #18181816",
                  backgroundColor: "#f5f5f5",
                }
              : {
                  color: color.textPrimary + color.fiftyOpacity,
                  borderColor: color.black + "16",
                  borderEndEndRadius: 5,
                  borderEndStartRadius: 5,
                }
          }
        >
          {load?.RouteTab ? (
            <RadioGroup
              style={{ width: "100%" }}
              name="route"
              //@ts-ignore
              value={values["firsat_destinasyon_rota__c"]}
              onChange={(v, e) => {
                handleChange("opportunitydestination__c")(load.id);
                handleChange("opportunityy__c")(load.parentsfid);
                handleChange("firsat_destinasyon_rota__c")(v);
              }}
            >
              {load?.RouteTab.map((route, i) => {
                return (
                  <div key={i}>
                    <Radio
                      value={route.sfid}
                      disabled={route.shipperofferprice > 0}
                    >
                      <div
                        style={{
                          fontSize: 12,
                          color: color.black,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            marginInlineEnd: 10,
                            width: `calc(100% - 120px)`,
                          }}
                        >
                          {route.route +
                            " " +
                            (route.distance != null
                              ? " - " +
                                (route.distance?.toFixed(0) ?? 0) +
                                " km"
                              : "")}
                        </div>
                        <div>
                          {route.targetprice != null
                            ? getCurrency(
                                route.targetprice,
                                route.currencyisocode
                              )
                            : ""}
                        </div>
                      </div>
                    </Radio>
                    {i + 1 !== load?.RouteTab?.length ? (
                      <Divider style={{ margin: "10px 0" }} />
                    ) : (
                      <div style={{ height: 15 }} />
                    )}
                  </div>
                );
              })}
            </RadioGroup>
          ) : null}
        </div>
        <div
          className="extraDetails"
          style={{ height: details === load.id ? 293 : 0 }}
        >
          <Nav content={navContent}>
            {(activeTab) => {
              return (
                <div
                  className="extraDetailsContent"
                  style={{
                    paddingInline: activeTab !== navContent.length - 1 ? 32 : 0,
                  }}
                >
                  {activeTab !== navContent.length - 1 && (
                    <div className="extraDetailsFirstTab">
                      <div className="extraDetailsFirstTabFirstCol">
                        {(activeTab === 0 || activeTab === undefined) && (
                          <>
                            <div
                              className="dividedRow"
                              style={{ marginBlockEnd: 10 }}
                            >
                              <div style={{ width: "50%" }}>
                                <p
                                  className="boldText"
                                  style={{ color: color.textSecondary }}
                                >
                                  {strings.getString("loading_area")}
                                </p>
                                <ul className="areasList smallRegularText">
                                  {load.DetailTab && load.DetailTab.ll1name && (
                                    <li>{load.DetailTab.ll1name}</li>
                                  )}
                                  {load.DetailTab && load.DetailTab.ll2name && (
                                    <li>{load.DetailTab.ll2name}</li>
                                  )}
                                  {load.DetailTab && load.DetailTab.ll3name && (
                                    <li>{load.DetailTab.ll3name}</li>
                                  )}
                                  {load.DetailTab && load.DetailTab.ll4name && (
                                    <li>{load.DetailTab.ll4name}</li>
                                  )}
                                </ul>
                              </div>
                              <div style={{ width: "50%" }}>
                                <p
                                  className="boldText"
                                  style={{ color: color.textSecondary }}
                                >
                                  {strings.getString("unloading_area")}
                                </p>
                                <ul className="areasList smallRegularText">
                                  {load.DetailTab && load.DetailTab.ul1name && (
                                    <li>{load.DetailTab.ul1name}</li>
                                  )}
                                  {load.DetailTab && load.DetailTab.ul2name && (
                                    <li>{load.DetailTab.ul2name}</li>
                                  )}
                                  {load.DetailTab && load.DetailTab.ul3name && (
                                    <li>{load.DetailTab.ul3name}</li>
                                  )}
                                  {load.DetailTab && load.DetailTab.ul4name && (
                                    <li>{load.DetailTab.ul4name}</li>
                                  )}
                                </ul>
                              </div>
                            </div>
                            <div
                              className="dividedRow"
                              style={{ marginBlockEnd: 10 }}
                            >
                              <div style={{ width: "50%" }}>
                                <p
                                  className="boldText"
                                  style={{ color: color.textSecondary }}
                                >
                                  {strings.getString("customs")}
                                </p>
                                {load.DetailTab &&
                                load.DetailTab.fromcustoms ? (
                                  <span className="smallRegularText">
                                    {load.DetailTab.fromcustoms}
                                  </span>
                                ) : (
                                  <span className="smallRegularText">-</span>
                                )}
                              </div>
                              <div style={{ width: "50%" }}>
                                <p
                                  className="boldText"
                                  style={{ color: color.textSecondary }}
                                >
                                  {strings.getString("customs")}
                                </p>
                                {load.DetailTab && load.DetailTab.tocustoms ? (
                                  <span className="smallRegularText">
                                    {load.DetailTab.tocustoms}
                                  </span>
                                ) : (
                                  <span className="smallRegularText">-</span>
                                )}
                              </div>
                            </div>
                            <div
                              className="dividedRow"
                              style={{ marginBlockEnd: 10 }}
                            >
                              <div style={{ width: "50%" }}>
                                <p
                                  className="boldText"
                                  style={{ color: color.textSecondary }}
                                >
                                  {strings.getString("loading_type")}
                                </p>
                                <span className="smallRegularText">
                                  {load.DetailTab && load.DetailTab.loadintypes
                                    ? load.DetailTab.loadintypes.replaceAll(
                                        ";",
                                        ","
                                      )
                                    : "-"}
                                </span>
                              </div>
                              <div style={{ width: "50%" }}>
                                <p
                                  className="boldText"
                                  style={{ color: color.textSecondary }}
                                >
                                  {strings.getString("unloading_type")}
                                </p>
                                <span className="smallRegularText">
                                  {load.DetailTab &&
                                  load.DetailTab.unloadingtypes
                                    ? load.DetailTab.unloadingtypes.replaceAll(
                                        ";",
                                        ","
                                      )
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                        {activeTab === 1 && (
                          <>
                            <div style={{ width: "100%", marginBlockEnd: 10 }}>
                              <p
                                className="boldText"
                                style={{ color: color.textSecondary }}
                              >
                                {strings.getString("desired_arrival_date")}
                              </p>
                              <span className="smallRegularText">
                                {load.loadenddate
                                  ? moment(load.loadenddate).format("L")
                                  : ""}
                              </span>
                            </div>
                            <div style={{ width: "100%", marginBlockEnd: 10 }}>
                              <p
                                className="boldText"
                                style={{ color: color.textSecondary }}
                              >
                                {strings.getString("payment_conditions")}
                              </p>
                              <span className="smallRegularText">
                                {load.ExtraTab &&
                                load.ExtraTab.advancepaymentafterloading__c ? (
                                  <>
                                    <span>
                                      {strings.formatString(
                                        strings.getString(
                                          "paymentconditionfirstsentence"
                                        ),
                                        load.ExtraTab.percent__c,
                                        100 - load.ExtraTab.percent__c
                                      )}
                                    </span>
                                    {load.ExtraTab.dayafterloading__c && (
                                      <p>
                                        {strings.formatString(
                                          strings.getString("afterloadingday"),
                                          load.ExtraTab.day__c
                                        )}
                                      </p>
                                    )}

                                    {load.ExtraTab.dayafterunloading__c && (
                                      <p>
                                        {strings.formatString(
                                          strings.getString(
                                            "afterunloadingday"
                                          ),
                                          load.ExtraTab.unloadingday__c
                                        )}
                                      </p>
                                    )}

                                    {load.ExtraTab.afterinvoicedate__c && (
                                      <p>
                                        {strings.formatString(
                                          strings.getString("afterinvoice"),
                                          load.ExtraTab.afterinvoicedateday__c
                                        )}
                                      </p>
                                    )}

                                    {load.ExtraTab.aftercopycmrinvoice__c && (
                                      <p>
                                        {strings.formatString(
                                          strings.getString("aftercopycmr"),
                                          load.ExtraTab
                                            .aftercopycmrinvoiceday__c
                                        )}
                                      </p>
                                    )}
                                    {load.ExtraTab.afteroriginalcmr__c && (
                                      <p>
                                        {strings.formatString(
                                          strings.getString("afteroriginalcmr"),
                                          load.ExtraTab.cmrday__c
                                        )}
                                      </p>
                                    )}
                                  </>
                                ) : (
                                  <span>{strings.getString("Cash")}</span>
                                )}
                              </span>
                            </div>
                            <div style={{ width: "100%", marginBlockEnd: 10 }}>
                              <p
                                className="boldText"
                                style={{ color: color.textSecondary }}
                              >
                                {strings.getString("hsc") +
                                  " / " +
                                  strings.getString("commodity_price")}
                              </p>
                              {load?.ExtraTab?.hsc_code__c ? (
                                <span className="smallRegularText">
                                  {load.ExtraTab.hsc_code__c}
                                </span>
                              ) : (
                                <span className="smallRegularText">-</span>
                              )}
                              {load?.ExtraTab?.productprice__c ? (
                                <span className="smallRegularText">
                                  /{" "}
                                  {getCurrency(
                                    load.ExtraTab.productprice__c,
                                    load.currencyisocode
                                  )}
                                </span>
                              ) : (
                                <span className="smallRegularText">/-</span>
                              )}
                            </div>
                            <div style={{ width: "100%", marginBlockEnd: 10 }}>
                              <p
                                className="boldText"
                                style={{ color: color.textSecondary }}
                              >
                                {strings.getString("desired_equipment")}
                              </p>
                              <span className="smallRegularText">
                                {load.loadTrucks?.map((type, i) => (
                                  <p key={i}>{`${type.equipments ?? ""}`}</p>
                                ))}
                              </span>
                            </div>
                          </>
                        )}
                        {activeTab === 2 && (
                          <div style={{ width: "100%" }}>
                            <p
                              className="boldText"
                              style={{ color: color.textSecondary }}
                            >
                              {strings.getString("other_details")}
                            </p>
                            <div
                              className="smallRegularText"
                              style={{
                                display: "flex",
                              }}
                            >
                              <p
                                style={{
                                  marginInlineEnd: 10,
                                  color: color.textSecondary,
                                }}
                              >
                                {strings.getString("adr") + ": "}
                              </p>
                              <span>
                                {load?.OtherTab?.adr__c ? (
                                  <>
                                    {strings.getString("yes") +
                                      " - " +
                                      load.OtherTab.adr__c}
                                  </>
                                ) : (
                                  strings.getString("no")
                                )}
                              </span>
                            </div>
                            <div
                              className="smallRegularText"
                              style={{
                                display: "flex",
                              }}
                            >
                              <p
                                style={{
                                  marginInlineEnd: 10,
                                  color: color.textSecondary,
                                }}
                              >
                                {strings.getString("frigo") + ": "}
                              </p>
                              <span>
                                {load?.OtherTab?.frigocelsius_ ? (
                                  <>
                                    {strings.getString("yes") +
                                      `- (${load.OtherTab.frigocelsius_})`}
                                  </>
                                ) : (
                                  strings.getString("no")
                                )}
                              </span>
                            </div>
                            <div
                              className="smallRegularText"
                              style={{
                                display: "flex",
                              }}
                            >
                              <p
                                style={{
                                  marginInlineEnd: 10,
                                  color: color.textSecondary,
                                }}
                              >
                                {strings.getString("doctor_required") + ": "}
                              </p>
                              <span>
                                {load?.OtherTab?.isdoctorrequired
                                  ? strings.getString("yes")
                                  : strings.getString("no")}
                              </span>
                            </div>
                            <div
                              className="smallRegularText"
                              style={{
                                display: "flex",
                              }}
                            >
                              <p
                                style={{
                                  marginInlineEnd: 10,
                                  color: color.textSecondary,
                                }}
                              >
                                {strings.getString("countrycertificate") + ": "}
                              </p>
                              <span>
                                {load?.thirdcountrycertificate !== undefined
                                  ? load.thirdcountryname
                                    ? strings.getString("yes")
                                    : strings.getString("no")
                                  : "-"}
                              </span>
                            </div>
                            <div
                              className="smallRegularText"
                              style={{
                                display: "flex",
                              }}
                            >
                              <p
                                style={{
                                  marginInlineEnd: 10,
                                  color: color.textSecondary,
                                }}
                              >
                                {strings.getString("countryname") + ": "}
                              </p>
                              <span>
                                {load?.thirdcountryname
                                  ? load?.thirdcountryname
                                  : "-"}
                              </span>
                            </div>
                            <div
                              className="smallRegularText"
                              style={{
                                display: "flex",
                              }}
                            >
                              <p
                                style={{
                                  marginInlineEnd: 10,
                                  color: color.textSecondary,
                                }}
                              >
                                {strings.getString(
                                  "trucktransitdeclarationtype"
                                ) + ": "}
                              </p>
                              <span>
                                {load?.trucktransitdeclarationtype
                                  ? load?.trucktransitdeclarationtype
                                  : "-"}
                              </span>
                            </div>
                            <div
                              className="smallRegularText"
                              style={{
                                display: "flex",
                              }}
                            >
                              <p
                                style={{
                                  marginInlineEnd: 10,
                                  color: color.textSecondary,
                                }}
                              >
                                {strings.getString("seconddrivercheckbox") +
                                  ": "}
                              </p>
                              <span>
                                {load?.seconddrivercheckbox !== undefined
                                  ? load?.seconddrivercheckbox
                                    ? strings.getString("yes")
                                    : strings.getString("no")
                                  : "-"}
                              </span>
                            </div>
                          </div>
                        )}
                        {activeTab === 3 && (
                          <>
                            <p
                              className="boldText"
                              style={{ color: color.textSecondary }}
                            >
                              {strings.getString("available_routes")}
                            </p>
                            {load.RouteTab?.map((route, i) => (
                              <div
                                key={i}
                                className="smallRegularText"
                                style={{
                                  display: "flex",
                                }}
                              >
                                <p>{route.route}&nbsp; </p>
                                <span>
                                  {getCurrency(
                                    route.targetprice,
                                    route.currencyisocode
                                  )}
                                </span>
                                <span>
                                  &nbsp;{route.distance?.toFixed(0) ?? 0} km
                                </span>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                      <div
                        style={{
                          width: "5%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            width: 1,
                            backgroundColor: color.borderColor,
                          }}
                        />
                      </div>
                      <div className="extraDetailsFirstTabSecondCol">
                        <p
                          className="boldText"
                          style={{ color: color.textSecondary }}
                        >
                          {strings.getString("language")}
                        </p>
                        <div
                          style={{
                            width: 100,
                            display: "flex",
                            justifyContent: "flex-start",
                            flexWrap: "wrap",
                            gap: "4",
                          }}
                        >
                          {load?.Owner?.flags?.split(";").map((x) => {
                            return (
                              <Flag
                                style={{ marginRight: 3 }}
                                country={x === "EN" ? "US" : x}
                                title={x}
                                className="flag"
                              />
                            );
                          })}
                        </div>
                        <p
                          className="boldText"
                          style={{
                            color: color.textSecondary,
                            marginBlockEnd: 0,
                            marginBlockStart: 5,
                          }}
                        >
                          {strings.getString("contact_details")}
                        </p>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Icon
                            icon="mail"
                            size={20}
                            style={{ marginInlineEnd: 5 }}
                          />
                          <span
                            className="boldText"
                            style={{
                              textDecoration: "underline",
                              fontWeight: 600,
                            }}
                          >
                            {load.Owner?.email}
                          </span>
                        </div>
                        <div style={{ display: "flex" }}>
                          <Icon
                            icon="mobile"
                            size={20}
                            style={{ marginInlineEnd: 5 }}
                          />
                          <span
                            className="boldText"
                            style={{ fontWeight: 600 }}
                          >
                            {load.Owner?.mobilephone}
                          </span>
                        </div>
                        <div style={{ display: "flex" }}>
                          <Icon
                            icon="phone"
                            color={color.black}
                            size={15}
                            style={{
                              marginInlineEnd: 7,
                              marginInlineStart: 3,
                            }}
                          />
                          <span
                            className="boldText"
                            style={{ fontWeight: 600 }}
                          >
                            {load.Owner?.phone}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  {activeTab === 4 && (
                    <GoogleMap
                      id={`rapidMap-${load.id}`}
                      options={{
                        //@ts-ignore
                        styles: CUSTOM_MAP,
                        disableDefaultUI: true,
                        zoomControl: true,
                        scrollwheel: false,
                      }}
                      onLoad={(map: google.maps.Map<Element>) => {
                        setRouteDirections([]);
                        setPathDistance("");

                        if (load.RouteTab) {
                          clickableDirectionServiceResult(load.RouteTab[0]);
                        } else {
                          getDirectionsServiceResult();
                        }
                      }}
                      mapContainerStyle={mapContainerStyles}
                    >
                      {route_directions &&
                        route_directions.map((rd, i) => (
                          <DirectionsRenderer
                            key={`route-map-${i}`}
                            directions={rd}
                            onLoad={(
                              directionsRenderer: google.maps.DirectionsRenderer
                            ) => {
                              console.log(
                                "directionsRenderer",
                                directionsRenderer
                              );
                            }}
                            options={{
                              polylineOptions: {
                                strokeColor: decideColor(path_distance_index),
                              },
                            }}
                          />
                        ))}
                      {load.RouteTab && (
                        <div
                          style={{
                            position: "absolute",
                            left: 10,
                            top: 5,
                          }}
                        >
                          {load.RouteTab.map((data, i) => {
                            return (
                              <div
                                style={{
                                  position: "relative",
                                  backgroundColor: decideColor(i),
                                  padding: 5,
                                  marginTop: 5,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  clickableDirectionServiceResult(data);
                                  setPathDistanceIndex(i);
                                }}
                              >
                                <span
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {data.route}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      )}

                      {path_distance !== "" && (
                        <div
                          style={{
                            position: "absolute",
                            right: 10,
                            top: 5,
                            padding: 5,
                            backgroundColor: "#ededed",
                            borderRadius: 10,
                          }}
                        >
                          <span style={{ color: "black", fontWeight: "bold" }}>
                            {path_distance}
                          </span>
                        </div>
                      )}
                    </GoogleMap>
                  )}
                </div>
              );
            }}
          </Nav>
        </div>
      </div>
    </div>
  );
});

export default LoadCard;
