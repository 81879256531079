import { MutableRefObject } from "react";
import { TruckCard } from "..";
import { VehicleOpportunityDto } from "../../redux/opportunity/type";
import TruckCardMobil from "../TruckCardMobil";

type TruckCardListProps = {
  trucks: VehicleOpportunityDto[];
  favList: string[];
  setFavList: (favList: string[]) => void;
  isSelected: (id: string) => boolean;
  setSelected: (id: string) => void;
  truckDetails: string;
  openModal: (id: string) => void;
  setTruckDetails: (id: string) => void;
  openChat: (truck: VehicleOpportunityDto) => void;
  openBooking: (truck: VehicleOpportunityDto, accepted: boolean) => void;
  handlePrint: (ref: MutableRefObject<null>) => void;
};

const TruckCardList = ({
  trucks,
  favList,
  setFavList,
  isSelected,
  setSelected,
  truckDetails,
  setTruckDetails,
  openChat,
  openBooking,
  openModal,
  handlePrint,
}: TruckCardListProps) => {
  return (
    <>
      <div
        className="search-truck-card-list"
        style={{ width: "100%", height: "100%" }}
      >
        {trucks &&
          trucks.length > 0 &&
          trucks.map((truck, i) => {
            return (
              <>
                <TruckCard
                  key={i}
                  truck={truck}
                  selected={isSelected(truck.id)}
                  setSelected={(truckId) => setSelected(truckId)}
                  details={truckDetails}
                  setDetails={(truckId) => setTruckDetails(truckId)}
                  favList={favList}
                  setFavList={setFavList}
                  openChat={() => openChat(truck)}
                  openBooking={(truck, accepted) =>
                    openBooking(truck, accepted)
                  }
                  handlePrint={(ref) => handlePrint(ref)}
                />
              </>
            );
          })}
      </div>
      <div
        className="search-truck-card-list-mobil"
        style={{ width: "100%", height: "100%" }}
      >
        {trucks &&
          trucks.length > 0 &&
          trucks.map((truck, i) => {
            return (
              <>
                <TruckCardMobil
                  key={i}
                  truck={truck}
                  selected={isSelected(truck.id)}
                  setSelected={(truckId) => setSelected(truckId)}
                  details={truckDetails}
                  setDetails={(truckId) => setTruckDetails(truckId)}
                  favList={favList}
                  setFavList={setFavList}
                  openChat={() => openChat(truck)}
                  openBooking={(truck, accepted) =>
                    openBooking(truck, accepted)
                  }
                  openModal={openModal}
                  handlePrint={(ref) => handlePrint(ref)}
                />
              </>
            );
          })}
      </div>
    </>
  );
};

export default TruckCardList;
