import { useDispatch, useSelector } from "react-redux";
import { Icon, Loader } from "../../../components";
import { getLocalizedAuth, isLoadingState } from "../../../redux/selectors";
import { Loader as LoaderRs } from "rsuite";
import { color } from "../../../utils/Properties";
import { emailconfirm } from "../../../redux/actions";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

type ForgotPasswordProps = {
  onClick: () => void;
};

const EmailConfirm = ({ onClick }: ForgotPasswordProps) => {
  const dispatch = useDispatch();
  const strings = useSelector(getLocalizedAuth);
  const location = useLocation();
  const [message, setmessage] = useState<string>();
  const [errormessage, seterrormessage] = useState<string>();
  const loader = useSelector(isLoadingState);
  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const token = search.get("token");
    dispatch(
      emailconfirm({
        params: { Token: token != null ? token : "" },
        onSuccess: (message: string) => {
          setmessage(message);
          setTimeout(() => {
            window.location.replace("login");
          }, 5000);
        },
        onError: (message: string) => {
          seterrormessage(message);
        },
      })
    );
  }, [dispatch, location.search]);

  return (
    <div className="forgotPasswordContent">
      <div
        className="standardText"
        style={{
          display: "flex",
          cursor: "pointer",
          lineHeight: "18px",
          fontSize: 12,
          marginBlockEnd: "35%",
          width: "max-content",
          color: color.textHeader,
        }}
        onClick={onClick}
      >
        <Icon
          icon="left"
          size={12}
          color={color.black}
          style={{ marginInlineEnd: 10, marginBlockStart: 3 }}
        />
        {strings.getString("back_to_login")}
      </div>
      <div>
        {loader && <Loader />}

        {message && (
          <div
            style={{
              backgroundColor: color.successSoft,
              color: color.success,
              border: "1px solid " + color.success,
              padding: 20,
              borderRadius: 6,
              textAlign: "center",
            }}
          >
            {strings.getString(
              "Your email confirmed successfully You are redirecting for login"
            )}{" "}
            <LoaderRs />
          </div>
        )}
        {errormessage && (
          <div
            style={{
              backgroundColor: color.errorSoft,
              color: color.error,
              border: "1px solid " + color.error,
              padding: 20,
              borderRadius: 6,
              textAlign: "center",
            }}
          >
            {errormessage}
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailConfirm;
