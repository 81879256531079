import { forwardRef, MutableRefObject, useRef } from "react";
import { useSelector } from "react-redux";
import { Popover, Whisper } from "rsuite";
import { Divider, Icon } from "..";
import { getLocalizedLoadCard } from "../../redux/selectors";
import { color } from "../../utils/Properties";
import moment from "moment";
import "./style.css";
import { LoadOpportunities } from "../../types";
import { getCurrency } from "../../utils/Helpers";

type LoadCardProps = {
  load: LoadOpportunities;
  selected: boolean;
  setSelected: (loadId: string) => void;
  favList: string[];
  setFavList: (favList: string[]) => void;
  openModal: (id: string) => void;
  openChat: (load: LoadOpportunities) => void;
  openBooking: (
    load: LoadOpportunities,
    fistOne: boolean,
    acceptNow: boolean
  ) => void;
  handlePrint: (ref: MutableRefObject<null>) => void;
};

const dividerPadding: number = 6;

const LoadCardVertical = forwardRef<HTMLDivElement, LoadCardProps>(
  (
    {
      load,
      selected,
      setSelected,
      favList,
      setFavList,
      openModal,
      openChat,
      openBooking,
      handlePrint,
    }: LoadCardProps,
    ref
  ) => {
    const printerRef = useRef(null);
    const strings = useSelector(getLocalizedLoadCard);

    const disabledCheck = (load: LoadOpportunities) => {
      if (load.RouteTab == null && load.shipperofferprice > 0) {
        return true;
      } else if (load.RouteTab) {
        if (load.RouteTab.find((x) => x.shipperofferprice == null) == null) {
          return true;
        }
      }

      return false;
    };

    return (
      <div
        ref={ref}
        className="verticalLoadCard"
        style={{
          border: "1px solid",
          paddingBlock: 20,
          borderColor: selected ? color.primary : "#e6e6e6",
          height: "520px",
          position: "relative",
        }}
      >
        <div
          className="smallRegularText"
          style={{
            width: "100%",
            paddingInline: 15,
          }}
        >
          <div
            style={{
              width: "100%",
              height: "max-content",
              display: "flex",
            }}
          >
            <div
              className="vehicleLogo"
              style={{
                backgroundColor: color.accent,
                borderRadius: 10,
                marginInlineStart: -5,
                marginBlockStart: -5,
              }}
            >
              <img
                className="imageStyles"
                style={{ padding: 5, borderRadius: 12 }}
                src={load.Owner ? load.Owner.profileimage : load.profileimage}
                alt=""
              />
            </div>
            <div
              style={{
                width: `calc(100% - 65px)`,
                height: 65,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                paddingInlineStart: 5,
              }}
            >
              <span
                className="boldText"
                style={{
                  color: color.textHeader + color.fiftyOpacity,
                  lineHeight: "15px",
                }}
              >
                {strings.getString("name")}
              </span>
              <p className="mediumHeadingStyle" style={{ lineHeight: "23px" }}>
                {load.rapidadvisorname}
              </p>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p className="smallText">
                  {load.postedat ? moment(load.postedat).format("l") : "-"}
                </p>
              </div>
            </div>
          </div>{" "}
          <Divider paddingBlock={dividerPadding} />
          <div style={{ display: "flex", paddingInline: 3 }}>
            <div
              style={{
                width: "50%",
              }}
            >
              <span className="smallBoldText" style={{ opacity: 0.5 }}>
                {strings.getString("file_number")}
              </span>
              <p>{"#" + load.opdfilenumber}</p>
            </div>
            <div
              style={{
                width: "50%",
              }}
            >
              <span className="smallBoldText" style={{ opacity: 0.5 }}>
                {strings.getString("load_type")}
              </span>
              <p>
                {" "}
                {(load.main_load_type__c ?? "-") + "/" + (load.loadtype ?? "-")}
              </p>
            </div>
          </div>
          <Divider paddingBlock={dividerPadding} />
          <div
            style={{
              paddingInline: 3,
            }}
          >
            <p className="smallBoldText" style={{ opacity: 0.5 }}>
              {strings.getString("route")}
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <p className="biggerText">
                {load.fromcountry ? load.fromcountry : "-"}
              </p>
              <Icon
                icon="right-bold"
                color={color.textSecondary}
                size={13}
                style={{ marginInline: 5 }}
              />
              <p className="biggerText">
                {load.tocountry ? load.tocountry : "-"}
              </p>
            </div>
          </div>
          <Divider paddingBlock={dividerPadding} />
          <div
            style={{
              paddingInline: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div>
              <span className="smallBoldText" style={{ opacity: 0.5 }}>
                {strings.getString("estimated_amount")}
              </span>
              <p>
                {load.currencyisocode
                  ? getCurrency(load.estimatedamount, load.currencyisocode)
                  : load.estimatedamount}
              </p>
            </div>
            {!disabledCheck(load) &&
              load.estimatedamount &&
              load.firstcomegets && (
                <div
                  className="offerButton smallText"
                  style={{
                    marginInlineStart: 20,
                  }}
                  onClick={() => {
                    if (!disabledCheck(load)) {
                      openBooking(load, true, false);
                    }
                  }}
                >
                  {strings.getString("make_offer")}
                </div>
              )}
          </div>
          <Divider paddingBlock={dividerPadding} />
          <div style={{ display: "flex", paddingInline: 3 }}>
            <div>
              <Icon
                icon="truck"
                size={14}
                style={{ marginInlineEnd: 5, opacity: 0.5 }}
              />
              <p style={{ marginInlineEnd: 3 }}>
                {load.trucktype} (
                {load?.loadTrucks && load?.loadTrucks?.length > 0
                  ? parseInt(
                      load?.loadTrucks[0]?.vehiclenumber__c.toString() ??
                        "0".toString()
                    )
                  : 0}
                )
                {load.loadTrucks &&
                  load.loadTrucks?.length > 1 &&
                  strings.formatString(
                    strings.getString("more"),
                    load.loadTrucks.length - 1
                  )}
              </p>
            </div>
          </div>
          <Divider paddingBlock={dividerPadding} />
          <div className="iconGroup">
            <div className="iconGroupItem">
              <Icon icon="tonne" size={12} />
              <div style={{ textAlign: "center" }}>{load.weight + " t"}</div>
            </div>
            <div className="iconGroupItem">
              <Icon icon="ruler" size={14} />
              {load.cubicmeters ? load.cubicmeters + " m³" : "-"}
            </div>
            <div className="iconGroupItem">
              <Icon icon="distance" size={17} />
              <span>{(load.mindistance?.toFixed(0) ?? 0) + " km"}</span>
            </div>
            <div className="iconGroupItem">
              <Icon icon="box-stacked" size={15} />
              <span>
                {true ? strings.getString("pallets") : strings.getString("box")}
              </span>
            </div>
          </div>
          <div
            style={{ marginBlock: 12, width: "100%" }}
            className={
              disabledCheck(load)
                ? "actionButtonDisabled card-offer-button"
                : "actionButton card-offer-button"
            }
            onClick={() => {
              if (!disabledCheck(load)) {
                openBooking(
                  load,
                  load.firstcomegets ? load.firstcomegets : false,
                  load.firstcomegets ? true : false
                );
              }
            }}
          >
            {disabledCheck(load) ? (
              <p style={{ textAlign: "center" }}>
                {strings.getString("offer_has_been")}
              </p>
            ) : load.firstcomegets ? (
              <p style={{ textAlign: "center" }}>
                {strings.getString("accept_now")}
              </p>
            ) : (
              <p style={{ textAlign: "center" }}>
                {strings.getString("make_offer")}
              </p>
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: 44,
              width: "100%",
              border: "1px solid",
              borderColor: color.borderSecondary,
              borderRadius: 5,
              cursor: "pointer",
              backgroundColor: color.accent,
            }}
          >
            <Whisper
              delay={500}
              placement="top"
              trigger="hover"
              speaker={
                <Popover>
                  <p>{strings.getString("details")}</p>
                </Popover>
              }
              enterable
            >
              <div
                className="extraInfosButton"
                style={{
                  borderColor: color.borderSecondary,
                  borderStartStartRadius: 5,
                  borderEndStartRadius: 5,
                  width: "33%",
                }}
                onClick={() => openModal(load.id)}
              >
                <Icon icon="more" />
              </div>
            </Whisper>
            <Whisper
              delay={500}
              placement="top"
              trigger="hover"
              speaker={
                <Popover>
                  <p>{strings.getString("print")}</p>
                </Popover>
              }
              enterable
            >
              <div
                className="extraInfosButton"
                style={{
                  borderColor: color.borderSecondary,
                  width: "33%",
                }}
                onClick={async () => {
                  handlePrint(printerRef);
                }}
              >
                <Icon icon="printer" color={color.blackFifty} />
              </div>
            </Whisper>

            <Whisper
              delay={500}
              placement="top"
              trigger="hover"
              speaker={
                <Popover>
                  <p>{strings.getString("chat")}</p>
                </Popover>
              }
              enterable
            >
              <div
                className="extraInfosButton"
                style={{ border: "none", width: "33%" }}
                onClick={() => openChat(load)}
              >
                <Icon icon="chat" />
              </div>
            </Whisper>
          </div>
        </div>
      </div>
    );
  }
);

export default LoadCardVertical;
