import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { Col, DateRangePicker, Row } from "rsuite";
import IncDescSearchTenderCheckPicker from "../../../components/SearchIcon/incseacrhtender";
import { IncDescAuctionDto } from "../../../redux/autions/inc-desc-type";
import { FilteredDataParams } from "../../../redux/autions/type";
import {
  getLocalizedAuction,
  getLocalizedDates,
} from "../../../redux/selectors";
import { color } from "../../../utils/Properties";

const Filter = ({
  open,
  onHide,
  setTenders,
  tenders,
  searchTenders,
  setFilteredData,
  filteredData,
}: {
  filteredData: FilteredDataParams;
  setFilteredData: (filteredData: FilteredDataParams) => void;
  searchTenders: IncDescAuctionDto[];
  onHide: () => void;
  open: boolean;
  tenders: IncDescAuctionDto[];
  setTenders: (value: any) => void;
}) => {
  const t = useSelector(getLocalizedAuction);
  const datestrings = useSelector(getLocalizedDates);
  return (
    <React.Fragment>
      {open && (
        <div
          className="mainTenderFilterOuterDiv"
          style={{
            border: "1px solid " + color.borderSecondary,
            padding: "0px 20px 20px 20px",
            marginTop: 10,
          }}
        >
          <Row>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                color: color.textSecondary,
                padding: 10,
                cursor: "pointer",
              }}
              onClick={() => onHide()}
            >
              X
            </div>
          </Row>
          <Row>
            <Col md={12}>
              <IncDescSearchTenderCheckPicker
                filteredData={filteredData}
                searchTenders={searchTenders}
                setFilteredData={setFilteredData}
                label={t.getString("Tender Type")}
                field="transportertendertype__c"
                tenders={tenders}
                setTenders={setTenders}
              />
            </Col>
            <Col md={12}>
              <DateRangePicker
                ranges={[]}
                onClean={() => {
                  let data = filteredData;
                  data.StartDate = "";
                  data.EndDate = "";
                  setFilteredData(data);
                }}
                placement="bottomStart"
                showWeekNumbers={false}
                style={{
                  width: "100%",
                  border: "1px solid " + color.bgSecondary,
                }}
                onChange={(value) => {
                  let data = filteredData;
                  data.StartDate = moment(value[0]).format();
                  data.EndDate = moment(value[1]).format();
                  setFilteredData(data);
                }}
                locale={{
                  sunday: datestrings.getString("sunday"),
                  monday: datestrings.getString("monday"),
                  tuesday: datestrings.getString("tuesday"),
                  wednesday: datestrings.getString("wednesday"),
                  thursday: datestrings.getString("thursday"),
                  friday: datestrings.getString("friday"),
                  saturday: datestrings.getString("saturday"),
                  ok: datestrings.getString("ok"),
                  today: datestrings.getString("today"),
                  yesterday: datestrings.getString("yesterday"),
                  hours: datestrings.getString("hours"),
                  minutes: datestrings.getString("minutes"),
                  seconds: datestrings.getString("seconds"),
                }}
              />
            </Col>
          </Row>
        </div>
      )}
    </React.Fragment>
  );
};

export default Filter;
