import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { Col, DateRangePicker, Row } from "rsuite";
import SearchTenderCheckPicker from "../../../components/SearchIcon/searchTender";
import { AuctionDto, FilteredDataParams } from "../../../redux/autions/type";
import {
  getLocalizedAuction,
  getLocalizedDates,
} from "../../../redux/selectors";
import { color } from "../../../utils/Properties";

const Filter = ({
  open,
  onHide,
  setTenders,
  tenders,
  searchTenders,
  setFilteredData,
  filteredData,
}: {
  filteredData: FilteredDataParams;
  setFilteredData: (filteredData: FilteredDataParams) => void;
  searchTenders: AuctionDto[];
  onHide: () => void;
  open: boolean;
  tenders: AuctionDto[];
  setTenders: (value: any) => void;
}) => {
  const datestrings = useSelector(getLocalizedDates);
  const t = useSelector(getLocalizedAuction);
  return (
    <React.Fragment>
      {open && (
        <div
          className="mainTenderFilterOuterDiv"
          style={{
            border: "1px solid " + color.borderSecondary,
            padding: "0px 20px 20px 20px",
            marginTop: 10,
          }}
        >
          <Row>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                color: color.textSecondary,
                padding: 10,
                cursor: "pointer",
              }}
              onClick={() => onHide()}
            >
              X
            </div>
          </Row>
          <Row>
            <Col md={6}>
              <SearchTenderCheckPicker
                defaultData={[
                  { Id: true, Label: t.getString("Favourite") },
                  { Id: false, Label: t.getString("Not Favourite") },
                ]}
                filteredData={filteredData}
                label={t.getString("Favourite Status")}
                setFilteredData={setFilteredData}
                searchTenders={searchTenders}
                field="AddedFavorite"
                tenders={tenders}
                setTenders={setTenders}
                renderData={(e) =>
                  e ? t.getString("Favourite") : t.getString("Not Favourite")
                }
              />
            </Col>
            <Col md={6}>
              <DateRangePicker
                ranges={[]}
                onClean={() => {
                  let data = filteredData;
                  data.StartDate = "";
                  data.EndDate = "";
                  setFilteredData(data);
                }}
                placement="bottomStart"
                showWeekNumbers={false}
                style={{
                  width: "100%",
                  border: "1px solid " + color.bgSecondary,
                }}
                onChange={(value) => {
                  let data = filteredData;
                  data.StartDate = moment(value[0]).format();
                  data.EndDate = moment(value[1]).format();
                  setFilteredData(data);
                }}
                locale={{
                  sunday: datestrings.getString("sunday"),
                  monday: datestrings.getString("monday"),
                  tuesday: datestrings.getString("tuesday"),
                  wednesday: datestrings.getString("wednesday"),
                  thursday: datestrings.getString("thursday"),
                  friday: datestrings.getString("friday"),
                  saturday: datestrings.getString("saturday"),
                  ok: datestrings.getString("ok"),
                  today: datestrings.getString("today"),
                  yesterday: datestrings.getString("yesterday"),
                  hours: datestrings.getString("hours"),
                  minutes: datestrings.getString("minutes"),
                  seconds: datestrings.getString("seconds"),
                }}
              />
            </Col>
            <Col md={6}>
              <SearchTenderCheckPicker
                filteredData={filteredData}
                label={t.getString("Status")}
                setFilteredData={setFilteredData}
                searchTenders={searchTenders}
                field="HasParticipated"
                tenders={tenders}
                setTenders={setTenders}
                renderData={(e) =>
                  e
                    ? t.getString("Participated")
                    : t.getString("Not Participated")
                }
              />
            </Col>
            <Col md={6}>
              <SearchTenderCheckPicker
                filteredData={filteredData}
                searchTenders={searchTenders}
                setFilteredData={setFilteredData}
                label={t.getString("Tender Type")}
                field="Opportunity_Tender_Type__c"
                tenders={tenders}
                setTenders={setTenders}
              />
            </Col>
          </Row>
        </div>
      )}
    </React.Fragment>
  );
};

export default Filter;
