import { Formik } from "formik";
import { useSelector } from "react-redux";
import { CheckPicker, Icon } from "../../../../../components";
import { Input } from "../../../../../components";
import * as Yup from "yup";
import {
  getLocalizedErrors,
  getLocalizedLoadRegistration,
} from "../../../../../redux/selectors";
import { InputType } from "../../../../../components/Input";
import { RouteDto } from "../../../../../redux/opportunity/type";
import { generateUUID } from "../../../../../utils/Properties";
import { Col, Row } from "rsuite";

const CreateLocation = ({
  show,
  onHide,
  types,
  baseValues,
  errors,
  touched,
  onSubmit,
}: {
  onSubmit: (values: {
    no: string;
    Guzergah__c: string[];
    CustomersWantsPrice__c: any;
  }) => void;
  types: RouteDto[];
  onHide: () => void;
  show: boolean;
  baseValues: any;
  errors: any;
  touched: any;
}) => {
  // eslint-disable-next-line
  const strings = useSelector(getLocalizedLoadRegistration);
  const errorStrings = useSelector(getLocalizedErrors);

  return (
    <Formik
      initialValues={{
        no: generateUUID(),
        Guzergah__c: [],
        Guzergah__c__Name: "",
        CustomersWantsPrice__c: "",
      }}
      validationSchema={Yup.object().shape({
        Guzergah__c: Yup.array().required(errorStrings.getString("required")),
      })}
      onSubmit={(values, { resetForm }) => {
        onSubmit(values);
        return resetForm({
          values: {
            no: generateUUID(),
            Guzergah__c: [],
            Guzergah__c__Name: "",
            CustomersWantsPrice__c: "",
          },
        });
      }}
    >
      {({
        handleChange,
        handleSubmit,
        handleBlur,
        errors,
        touched,
        setFieldValue,
        values,
      }) => (
        <>
          <Row>
            <Col md={12}>
              <CheckPicker
                placement="bottomEnd"
                label={strings.getString("Route")}
                placeholder={strings.getString("Route")}
                data={
                  baseValues.guzergahList__c.length > 0
                    ? types.filter(
                        (x) =>
                          !baseValues.guzergahList__c.some(
                            (y: any) => y.Guzergah__c === x.sfid
                          )
                      )
                    : types
                }
                value={values.Guzergah__c}
                onChange={(e) => setFieldValue("Guzergah__c", e)}
                valueKey="sfid"
                style={{ width: "100%", height: "auto" }}
                labelKey="name"
              />
            </Col>
            <Col md={11}>
              <Input
                inputType={InputType.number}
                value={values.CustomersWantsPrice__c}
                label={strings.getString("Requested Price")}
                name="CustomersWantsPrice__c"
                onChange={handleChange("CustomersWantsPrice__c")}
                onBlur={handleBlur("CustomersWantsPrice__c")}
                //@ts-ignore
                error={errors.CustomersWantsPrice__c}
                //@ts-ignore
                touched={touched.CustomersWantsPrice__c}
              />
            </Col>
            <Col md={1}>
              <div className="smResponsiveButtonDiv gridPosition">
                <button
                  type="submit"
                  className="gridIcon "
                  onClick={() => handleSubmit()}
                >
                  <Icon icon="plus" size={20} />
                </button>
              </div>
            </Col>
          </Row>
        </>
      )}
    </Formik>
  );
};
export default CreateLocation;
