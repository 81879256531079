import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Col, Form, Row } from "rsuite";
import * as Yup from "yup";
import { CheckPicker, Icon, SelectPicker } from "../../../../../../components";
import { addDestinations } from "../../../../../../redux/profile/profileSaga";
import { PicklistCountryDto } from "../../../../../../redux/profile/type";
import {
  getLocalizedErrors,
  getLocalizedMyProfile,
} from "../../../../../../redux/selectors";
const CreateDestinationForm = ({
  countries,
  onSuccess,
}: {
  onSuccess: () => void;
  countries: PicklistCountryDto[];
}) => {
  const errorStrings = useSelector(getLocalizedErrors);
  const strings = useSelector(getLocalizedMyProfile);
  const dispatch = useDispatch();
  return (
    <Formik
      initialValues={{
        from__c: "",
        to__c: [],
      }}
      validationSchema={Yup.object().shape({
        from__c: Yup.string().required(errorStrings.getString("required")),
        to__c: Yup.array()
          .min(1, errorStrings.getString("required"))
          .required(errorStrings.getString("required")),
      })}
      onSubmit={(values, { resetForm }) => {
        dispatch(
          addDestinations({
            payload: {
              onSuccess: () => {
                onSuccess();
                return resetForm();
              },
              onError: (message) => {
                toast.error(message, {
                  theme: "colored",
                });
              },
            },
            url: "/Profile/AddDestinationMulti",
            body: values,
          })
        );
      }}
    >
      {({
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        values,
      }) => (
        <Form>
          <Row>
            <Col md={11}>
              <SelectPicker
                placement={"bottomStart"}
                cleanable={false}
                searchable={true}
                labelKey={"description"}
                valueKey={"id"}
                style={{ marginBlockEnd: 10 }}
                label={strings.getString("startingpoint")}
                placeholder={strings.getString("startingpoint")}
                data={countries}
                name="from__c"
                value={values.from__c}
                onSelect={handleChange("from__c")}
                onBlur={handleBlur("from__c")}
                error={errors.from__c}
                touched={touched.from__c}
              />
            </Col>
            <Col md={11}>
              <CheckPicker
                label={strings.getString("destinationpoint")}
                searchable={true}
                placement="bottomStart"
                placeholder={strings.getString("truck_type")}
                data={countries ? countries : []}
                value={values.to__c}
                onChange={(e) => setFieldValue("to__c", e)}
                valueKey="id"
                style={{ width: "100%", height: "auto" }}
                labelKey="description"
              />
            </Col>

            <Col md={2}>
              <div className="smResponsiveButtonDiv gridPosition">
                <button
                  type="submit"
                  className="gridIcon "
                  onClick={() => handleSubmit()}
                >
                  <Icon icon="plus" size={20} />
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
export default CreateDestinationForm;
