import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Popover, Radio, RadioGroup, Whisper } from "rsuite";
import { StepProps } from ".";
import { CheckPicker, Input, SelectPicker } from "../../../../components";
import AutoCompleteRs from "../../../../components/AutoCompleteRs";
import { InputType } from "../../../../components/Input";
import { getLoadingTypes } from "../../../../redux/actions";
import {
  getCustoms,
  GetEquipments,
  getGumrukbeyannamelist,
  GetPackageTypes,
  GetTruckTransitDeclarationTypes,
} from "../../../../redux/opportunity/saga";
import {
  EquipmentDto,
  GumrukBeyannameListDto,
  PackageTypeDto,
  TruckTransitDeclarationTypeDto,
} from "../../../../redux/opportunity/type";
import { getCountryList } from "../../../../redux/profile/profileSaga";
import { CountryDto } from "../../../../redux/profile/type";
import { getLocalizedLoadRegistration } from "../../../../redux/selectors";
import { LoadingTypes } from "../../../../types";

const Step3 = ({ values, errors, touched }: StepProps) => {
  const strings = useSelector(getLocalizedLoadRegistration);
  const { handleChange, handleBlur, setFieldValue } = useFormikContext();
  const dispatch = useDispatch();
  const [packageTypes, setPackageTypes] = useState<PackageTypeDto[]>();

  const [loadingTypes, setLoadingTypes] = useState<LoadingTypes[]>();
  const [transitDeclarationTypes, settransitDeclarationTypes] =
    useState<TruckTransitDeclarationTypeDto[]>();
  const [equipments, setEquipments] = useState<EquipmentDto[]>();
  const [countries, setCountries] = useState<CountryDto[]>();
  const [gumrukbeyannameList, setGumrukbeyannameList] =
    useState<GumrukBeyannameListDto[]>();

  useEffect(() => {
    dispatch(
      GetTruckTransitDeclarationTypes({
        payload: {
          onSuccess: (message, payload) => settransitDeclarationTypes(payload),
          onError: (message) => {},
        },
        url: "System/GetTruckTransitDeclarationTypes",
      })
    );

    dispatch(
      getCountryList({
        payload: {
          onSuccess: (message, payload) => {
            setCountries(payload);
          },
          onError: (message) => {},
        },
        url: "System/GetCountryList",
      })
    );
    dispatch(
      GetEquipments({
        payload: {
          onSuccess: (message, payload) => setEquipments(payload),
          onError: (message) => {},
        },
      })
    );
  }, [dispatch]);
  useEffect(() => {
    dispatch(
      GetPackageTypes({
        payload: {
          onSuccess: (message, payload) => setPackageTypes(payload),
          onError: (message) => {},
        },
      })
    );
    dispatch(
      getLoadingTypes({
        payload: {
          onSuccess: (message, payload) => setLoadingTypes(payload),
          onError: (message) => {},
        },
        url: "System/GetLoadingTypes",
      })
    );
    dispatch(
      getGumrukbeyannamelist({
        payload: {
          onSuccess: (message, payload) => setGumrukbeyannameList(payload),
          onError: (message) => {},
        },
      })
    );
  }, [dispatch]);
  return (
    <>
      <Divider />
      <p
        className="newBoldHeadingStyle"
        style={{ marginBlock: 30, textAlign: "center" }}
      ></p>
      <div className="dividedRow">
        <div className="threeCol">
          <p className="mediumHeadingStyle" style={{ marginBlockEnd: 10 }}>
            {strings.getString("driver_required")}
          </p>
          <RadioGroup
            value={values.SecondDriverCheckbox__c}
            onChange={(e) => setFieldValue("SecondDriverCheckbox__c", e)}
            onBlur={handleBlur("SecondDriverCheckbox__c")}
          >
            <Radio value={true} style={{ marginInlineEnd: 30 }}>
              {strings.getString("yes")}
            </Radio>
            <Radio value={false}>{strings.getString("no")}</Radio>
          </RadioGroup>
        </div>
        <div className="threeCol">
          <p className="mediumHeadingStyle" style={{ marginBlockEnd: 10 }}>
            {strings.getString("doctor_required")}
          </p>
          <RadioGroup
            value={values.SecondDriverCheckbox__c}
            onChange={(e) => setFieldValue("Doctor__c", e)}
            onBlur={handleBlur("Doctor__c")}
          >
            <Radio value={true} style={{ marginInlineEnd: 30 }}>
              {strings.getString("yes")}
            </Radio>
            <Radio value={false}>{strings.getString("no")}</Radio>
          </RadioGroup>
        </div>
        <div className="threeCol">
          <Whisper
            delay={500}
            placement="top"
            trigger="hover"
            speaker={
              <Popover>
                <p>{strings.getString("ecmt")}</p>
              </Popover>
            }
            enterable
          >
            <p
              className="mediumHeadingStyle"
              style={{ marginBlockEnd: 15, width: "max-content" }}
            >
              {strings.getString("third_country_certificate")}
            </p>
          </Whisper>
          <RadioGroup
            value={values.ThirdCountryCertificate__c}
            onChange={(e) => setFieldValue("ThirdCountryCertificate__c", e)}
            onBlur={handleBlur("ThirdCountryCertificate__c")}
          >
            <Radio value={true} style={{ marginInlineEnd: 30 }}>
              {strings.getString("yes")}
            </Radio>
            <Radio value={false}>{strings.getString("no")}</Radio>
          </RadioGroup>
        </div>
      </div>
      <Divider />
      <div className="dividedRow">
        {values.FirstLoadingLocation__c_Name.includes("Turkey") && (
          <div className="halfWidth">
            <SelectPicker
              style={{ maxHeight: 50 }}
              cleanable={false}
              value={values.GumrukYukBeyannamesiListesi__c}
              labelKey={"description"}
              valueKey={"id"}
              searchable={true}
              label={strings.getString("trucktransitdeclarationtype")}
              data={transitDeclarationTypes ? transitDeclarationTypes : []}
              onSelect={(value) =>
                setFieldValue("GumrukYukBeyannamesiListesi__c", value)
              }
              onBlur={handleBlur("GumrukYukBeyannamesiListesi__c ")}
              error={errors.GumrukYukBeyannamesiListesi__c}
              touched={touched.GumrukYukBeyannamesiListesi__c}
            />
          </div>
        )}
        <div className="halfWidth">
          <SelectPicker
            cleanable={false}
            value={values.thirdcountryname__c}
            labelKey={"countryname__c"}
            valueKey={"name"}
            searchable={true}
            label={strings.getString("countryname")}
            data={countries ? countries : []}
            onSelect={handleChange("thirdcountryname__c")}
            onBlur={handleBlur("thirdcountryname__c")}
            error={errors.thirdcountryname__c}
            touched={touched.thirdcountryname__c}
          />
        </div>
      </div>

      <div className="dividedRow mt-1">
        <div className="halfWidth">
          <AutoCompleteRs
            selectedItem={values.ToCustoms__c_Name}
            error={errors.FromCustoms__c}
            label={strings.getString("From Customs")}
            action={getCustoms}
            touch={touched.FromCustoms__c}
            url="VehicleOpportunity/GetCustomsss"
            onselectitem={(value) => {
              setFieldValue("FromCustoms__c", value);
            }}
            onselectitemforlabel={(value) => {
              setFieldValue("FromCustoms__c_Name", value);
            }}
          />
        </div>
        <div className="halfWidth">
          <AutoCompleteRs
            selectedItem={values.ToCustoms__c_Name}
            error={errors.ToCustoms__c}
            label={strings.getString("To Customs")}
            action={getCustoms}
            url="VehicleOpportunity/GetCustomsss"
            onselectitem={(value) => {
              setFieldValue("ToCustoms__c", value);
            }}
            onselectitemforlabel={(value) => {
              setFieldValue("ToCustoms__c_Name", value);
            }}
          />
        </div>
      </div>
      <div className="dividedRow mt-1">
        <div className="halfWidth">
          <SelectPicker
            cleanable={false}
            value={values.PackageType__c}
            labelKey={"value__c"}
            valueKey={"value__c"}
            searchable={true}
            label={strings.getString("Package Type")}
            data={packageTypes ? packageTypes : []}
            onSelect={handleChange("PackageType__c")}
            onBlur={handleBlur("PackageType__c")}
            error={errors.PackageType__c}
            touched={touched.PackageType__c}
          />
        </div>
        <div className="halfWidth">
          <Input
            inputType={InputType.number}
            label={strings.getString("Package Count")}
            name="Piece__c"
            onChange={handleChange("Piece__c")}
            onBlur={handleBlur("Piece__c")}
            error={errors.Piece__c}
            touched={touched.Piece__c}
          />
        </div>
      </div>
      <div className="dividedRow ">
        <div className="halfWidth">
          <CheckPicker
            placement="bottomEnd"
            label={strings.getString("Loading Type")}
            placeholder={strings.getString("Loading Type")}
            data={loadingTypes ? loadingTypes : []}
            value={values.LoadingType__c}
            onChange={(e) => setFieldValue("LoadingType__c", e)}
            style={{ width: "100%", height: "auto" }}
            labelKey={"description"}
            valueKey={"id"}
            error={errors.LoadingType__c}
            touched={touched.LoadingType__c}
          />
        </div>
        <div className="halfWidth">
          <CheckPicker
            placement="bottomEnd"
            label={strings.getString("Unloading Type")}
            placeholder={strings.getString("Unloading Type")}
            data={loadingTypes ? loadingTypes : []}
            value={values.UnloadingType__c}
            onChange={(e) => setFieldValue("UnloadingType__c", e)}
            style={{ width: "100%", height: "auto" }}
            labelKey={"description"}
            valueKey={"id"}
            error={errors.UnloadingType__c}
            touched={touched.UnloadingType__c}
          />
        </div>
      </div>
      <div className="dividedRow mt-1">
        <div className="halfWidth">
          <Input
            label={strings.getString("Product HS Code")}
            name="HSC_Code__c"
            onChange={handleChange("HSC_Code__c")}
            onBlur={handleBlur("HSC_Code__c")}
            error={errors.HSC_Code__c}
            touched={touched.HSC_Code__c}
          />
        </div>
        <div className="halfWidth">
          <Input
            inputType={InputType.number}
            label={strings.getString("Load Width")}
            name="Load_Width__c"
            onChange={handleChange("Load_Width__c")}
            onBlur={handleBlur("Load_Width__c")}
            error={errors.Load_Width__c}
            touched={touched.Load_Width__c}
          />
        </div>
      </div>
      <div className="dividedRow">
        <div className="halfWidth">
          <Input
            inputType={InputType.number}
            label={strings.getString("Load Length")}
            name="Load_Length__c"
            onChange={handleChange("Load_Length__c")}
            onBlur={handleBlur("Load_Length__c")}
            error={errors.Load_Length__c}
            touched={touched.Load_Length__c}
          />
        </div>
        <div className="halfWidth">
          <Input
            inputType={InputType.number}
            label={strings.getString("Load Height")}
            name="Load_Height__c"
            onChange={handleChange("Load_Height__c")}
            onBlur={handleBlur("Load_Height__c")}
            error={errors.Load_Height__c}
            touched={touched.Load_Height__c}
          />
        </div>
      </div>
      <div className="dividedRow">
        <div className="halfWidth">
          <Input
            inputType={InputType.number}
            value={values.ProductPrice__c}
            label={strings.getString("Value of Goods")}
            name="ProductPrice__c"
            onChange={handleChange("ProductPrice__c")}
            onBlur={handleBlur("ProductPrice__c")}
            error={errors.ProductPrice__c}
            touched={touched.ProductPrice__c}
          />
        </div>
        <div className="halfWidth">
          <SelectPicker
            className="defaultInput customs-select-picker"
            style={{ maxHeight: 50 }}
            cleanable={false}
            value={values.GumrukYukBeyannamesiListesi__c}
            labelKey={"value__c"}
            valueKey={"sfid"}
            searchable={true}
            label={strings.getString("Customs Load Declaration")}
            data={gumrukbeyannameList ? gumrukbeyannameList : []}
            onSelect={(value) =>
              setFieldValue("GumrukYukBeyannamesiListesi__c", value)
            }
            onBlur={handleBlur("GumrukYukBeyannamesiListesi__c ")}
            error={errors.GumrukYukBeyannamesiListesi__c}
            touched={touched.GumrukYukBeyannamesiListesi__c}
          />
        </div>
      </div>
      <div
        className="dividedRow s"
        style={{ maxHeight: 50, marginBlockEnd: 40 }}
      >
        <div style={{ width: "100%" }}>
          <CheckPicker
            placement="bottomEnd"
            label={strings.getString("equipments")}
            placeholder={strings.getString("equipments")}
            data={equipments ? equipments : []}
            value={values.Guzergah__c}
            onChange={(e) => setFieldValue("Guzergah__c", e)}
            style={{ width: "100%", height: "auto" }}
            valueKey="id"
            labelKey="description"
          />
        </div>
      </div>
    </>
  );
};

export default Step3;
