import { images } from "../../../assets";
import { TruckObject } from "../../../types";

export const truckResults: TruckObject[] = [
  {
    id: "1",
    name: "Faruk Güngör",
    departure: "Turkey",
    destination: "Romania",
    distance: "1523 km",
    type: "Mega",
    tonne: 24,
    volume: 100,
    start: "01/01/21",
    end: "02/01/21",
    arrival: "2021-04-02T11:47:20.573",
    amount: "$4000",
    posted: "2021-03-16T11:47:20.573",
    post_end: "2022-05-02T11:47:20.573",
    gps: true,
    available: true,
    image: images.profile1,
    location: { lat: 40.930438, lng: 29.150688 },
    loadingType: "Front",
    unloadingType: "Front",
    width: 1.5,
    height: 4.5,
    length: 10,
  },
  {
    id: "2",
    name: "Mehmet Polat Koçak",
    departure: null,
    destination: null,
    distance: "1523 km",
    type: "Mega",
    tonne: 24,
    volume: 100,
    start: null,
    end: null,
    arrival: "2021-04-14T11:47:20.573",
    amount: null,
    posted: "2021-03-16T11:47:20.573",
    post_end: "2022-04-16T11:47:20.573",
    gps: false,
    available: false,
    image: images.profile2,
    location: { lat: 41.030438, lng: 33.150688 },
    loadingType: "Front",
    unloadingType: "Front",
    width: 1.5,
    height: 4.5,
    length: 10,
  },
  {
    id: "3",
    name: "Emin Sözüer",
    departure: "Turkey",
    destination: "Romania",
    distance: "1523 km",
    type: "Mega",
    tonne: 24,
    volume: 100,
    start: "01/01/21",
    end: "02/01/21",
    arrival: "2021-04-10T11:47:20.573",
    amount: "$4000",
    posted: "2021-03-15T11:47:20.573",
    post_end: "2021-10-19T11:47:20.573",
    gps: true,
    available: undefined,
    image: images.profile3,
    location: { lat: 38.930438, lng: 44.150688 },
    loadingType: "Front",
    unloadingType: "Front",
    width: 1.5,
    height: 4.5,
    length: 10,
  },
  {
    id: "4",
    name: "Bayram Özge",
    departure: "Turkey",
    destination: "Romania",
    distance: "1523 km",
    type: "Mega",
    tonne: 24,
    volume: 100,
    start: "01/01/21",
    end: "02/01/21",
    arrival: "2021-04-08T11:47:20.573",
    amount: "$4000",
    posted: "2021-03-15T11:47:20.573",
    post_end: "2021-10-24T11:47:20.573",
    gps: true,
    available: true,
    image: images.profile4,
    location: { lat: 41.230438, lng: 27.150688 },
    loadingType: "Front",
    unloadingType: "Front",
    width: 1.5,
    height: 4.5,
    length: 10,
  },
  {
    id: "5",
    name: "Can Kılıç",
    departure: null,
    destination: null,
    distance: "1523 km",
    type: "Mega",
    tonne: 24,
    volume: 100,
    start: null,
    end: null,
    arrival: "2021-04-06T11:47:20.573",
    amount: null,
    posted: "2021-03-14T11:47:20.573",
    post_end: "2021-10-20T11:47:20.573",
    gps: false,
    available: false,
    image: images.profile5,
    location: { lat: 40.930438, lng: 29.150688 },
    loadingType: "Front",
    unloadingType: "Front",
    width: 1.5,
    height: 4.5,
    length: 10,
  },
  {
    id: "6",
    name: "Elenar Pena",
    departure: "Turkey",
    destination: "Romania",
    distance: "1523 km",
    type: "Mega",
    tonne: 24,
    volume: 100,
    start: "01/01/21",
    end: "02/01/21",
    arrival: "2021-04-21T11:47:20.573",
    amount: "$4000",
    posted: "2021-03-14T11:47:20.573",
    post_end: "2021-04-16T11:47:20.573",
    gps: true,
    available: undefined,
    image: images.profile6,
    location: { lat: 41.025944, lng: 28.978391 },
    loadingType: "Front",
    unloadingType: "Front",
    width: 1.5,
    height: 4.5,
    length: 10,
  },
  {
    id: "7",
    name: "Jack Daniels",
    departure: "Turkey",
    destination: "Romania",
    distance: "1523 km",
    type: "Mega",
    tonne: 24,
    volume: 100,
    start: "01/01/21",
    end: "02/01/21",
    arrival: "2021-05-13T11:47:20.573",
    amount: "$4000",
    posted: "2021-03-09T11:47:20.573",
    post_end: "2021-03-21T11:47:20.573",
    gps: true,
    available: true,
    image: images.profile7,
    location: { lat: 41.025944, lng: 27.978391 },
    loadingType: "Front",
    unloadingType: "Front",
    width: 1.5,
    height: 4.5,
    length: 10,
  },
  {
    id: "8",
    name: "Legolas Greenleaf",
    departure: null,
    destination: null,
    distance: "1523 km",
    type: "Mega",
    tonne: 24,
    volume: 100,
    start: null,
    end: null,
    arrival: "2021-04-17T11:47:20.573",
    amount: null,
    posted: "2021-03-02T11:47:20.573",
    post_end: "2021-03-16T11:47:20.573",
    gps: false,
    available: false,
    image: images.profile8,
    location: { lat: 41.005944, lng: 36.978391 },
    loadingType: "Front",
    unloadingType: "Front",
    width: 1.5,
    height: 4.5,
    length: 10,
  },
  {
    id: "9",
    name: "Jorji Costava",
    departure: "Turkey",
    destination: "Romania",
    distance: "1523 km",
    type: "Mega",
    tonne: 24,
    volume: 100,
    start: "01/01/21",
    end: "02/01/21",
    arrival: "2021-04-05T11:47:20.573",
    amount: "$4000",
    posted: "2021-02-16T11:47:20.573",
    post_end: "2021-03-16T11:47:20.573",
    gps: true,
    available: undefined,
    image: images.profile9,
    location: { lat: 37.025944, lng: 37.978391 },
    loadingType: "Front",
    unloadingType: "Front",
    width: 1.5,
    height: 4.5,
    length: 10,
  },
];
