import { MutableRefObject } from "react";
import { LoadCard } from "..";
import { LoadOpportunities } from "../../types";
import LoadCardMobil from "../LoadCardMobil";

type LoadCardListProps = {
  loads: LoadOpportunities[];
  favList: string[];
  setFavList: (favList: string[]) => void;
  isSelected: (id: string) => boolean;
  setSelected: (id: string) => void;
  loadDetails: string;
  setLoadDetails: (id: string) => void;
  openChat: (load: LoadOpportunities) => void;
  openBooking: (
    load: LoadOpportunities,
    fistOne: boolean,
    acceptNow: boolean
  ) => void;
  handlePrint: (ref: MutableRefObject<null>) => void;
};

const LoadCardList = ({
  loads,
  favList,
  setFavList,
  isSelected,
  setSelected,
  loadDetails,
  setLoadDetails,
  openChat,
  openBooking,
  handlePrint,
}: LoadCardListProps) => {
  return (
    <>
      <div
        className="search-truck-card-list"
        style={{ width: "100%", height: "100%" }}
      >
        {loads.map((load) => {
          return (
            <LoadCard
              key={load.id}
              load={load}
              selected={isSelected(load.id)}
              setSelected={(loadId) => setSelected(loadId)}
              details={loadDetails}
              setDetails={(loadId) => setLoadDetails(loadId)}
              favList={favList}
              setFavList={setFavList}
              openChat={() => openChat(load)}
              openBooking={(load, firstOne, acceptNow) =>
                openBooking(load, firstOne, acceptNow)
              }
              handlePrint={(ref) => handlePrint(ref)}
            />
          );
        })}
      </div>
      <div
        className="search-truck-card-list-mobil"
        style={{ width: "100%", height: "100%" }}
      >
        {loads.map((load) => {
          return (
            <>
              <LoadCardMobil
                key={load.id}
                load={load}
                selected={isSelected(load.id)}
                setSelected={(loadId) => setSelected(loadId)}
                details={loadDetails}
                setDetails={(loadId) => setLoadDetails(loadId)}
                favList={favList}
                setFavList={setFavList}
                openChat={() => openChat(load)}
                openBooking={(load, firstOne, acceptNow) =>
                  openBooking(load, firstOne, acceptNow)
                }
                handlePrint={(ref) => handlePrint(ref)}
              />
            </>
          );
        })}
      </div>
    </>
  );
};

export default LoadCardList;
