import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Footer } from "../../../components";
import { color } from "../../../utils/Properties";
import {
  getLocalizedMyProfile,
  getLocalizedSidebarNavigation,
} from "../../../redux/selectors";
import { Drawer, Nav } from "rsuite";
import ContactUs from "./contact-us";
import FaqsPage from "./faqs";
import "./style.scss";
import { ButtonType } from "../../../components/Button";

const AboutPage = () => {
  const location = useLocation();
  const [active, setActive] = useState(location.pathname);
  const history = useHistory();
  const strings = useSelector(getLocalizedSidebarNavigation);
  const profileStrings = useSelector(getLocalizedMyProfile);
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <div className="innerScreen">
        <div className="mobile-contact-us-menu">
          <Button
            title={profileStrings.getString("Menu")}
            buttonType={ButtonType.inverted}
            style={{
              zIndex: 1035,
              borderColor: "#E6F7FE",
              backgroundColor: "#E6F7FE",
              color: color.primary,
              borderRadius: 0,
              boxShadow: "0 4px 2px -2px #80808026",
              position: "fixed",
            }}
            onClick={() => {
              setShowMenu(true);
            }}
          />
          <Drawer
            onHide={() => setShowMenu(false)}
            show={showMenu}
            placement={"left"}
            className={"mobile-contact-us-drawer"}
          >
            <Drawer.Body>
              <Nav
                className="aboutus-nav responsive-nav"
                appearance="tabs"
                activeKey={active}
                onSelect={setActive}
                vertical
              >
                <Nav.Item
                  eventKey="/sss"
                  className="aboutus-menu-item responsive-navitem"
                  onClick={() => history.push("/sss")}
                >
                  {strings.getString("FREQUENTLY ASKED QUESTIONS")}
                </Nav.Item>
                <Nav.Item
                  eventKey="/contact-us"
                  className="aboutus-menu-item responsive-navitem"
                  onClick={() => history.push("/contact-us")}
                >
                  {strings.getString("CONTACT US")}
                </Nav.Item>
              </Nav>
            </Drawer.Body>
          </Drawer>
        </div>
        <div
          className="innerScreenContent"
          style={{ backgroundColor: "#BFE9FE4D" }}
        >
          <div
            className="sidebarNavigationDiv contactus-div"
            style={{ backgroundColor: color.pageBgColor, height: "100vh" }}
          >
            <div className="sidebarNavigation">
              <div className="sidebarNavigation">
                <div className="sidebarNavigationComponent">
                  <Nav
                    className="aboutus-nav responsive-nav contactusMenu"
                    appearance="tabs"
                    activeKey={active}
                    onSelect={setActive}
                    vertical
                  >
                    <Nav.Item
                      eventKey="/sss"
                      className="aboutus-menu-item responsive-navitem"
                      onClick={() => history.push("/sss")}
                    >
                      {strings.getString("FREQUENTLY ASKED QUESTIONS")}
                    </Nav.Item>
                    <Nav.Item
                      eventKey="/contact-us"
                      className="aboutus-menu-item responsive-navitem"
                      onClick={() => history.push("/contact-us")}
                    >
                      {strings.getString("CONTACT US")}
                    </Nav.Item>
                  </Nav>
                </div>
              </div>
            </div>
          </div>
          <div className="truckRegistrationDiv contactus-outer-div">
            <div
              className="truckRegistration contactus-content-div"
              style={{ backgroundColor: color.accent }}
            >
              {active === "/sss" && <FaqsPage />}
              {active === "/contact-us" && <ContactUs />}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AboutPage;
