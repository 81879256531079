import { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import Login from "./login";
import ForgotPassword from "./forgot-password";
import "./style.css";
import { getLocalizedAuth } from "../../redux/selectors";
import { color } from "../../utils/Properties";
import { useHistory, useLocation } from "react-router-dom";
import EmailConfirm from "./email-confirm";

const Authenticate = (props: any) => {
  const location = useLocation();
  const [activePage, setActivePage] = useState<number>(
    location.pathname.includes("email") ? 3 : 1
  );
  const strings = useSelector(getLocalizedAuth);
  useLayoutEffect(() => {
    if (location.pathname.includes("forgot-password")) {
      setActivePage(2);
    }
  }, [location]);
  const history = useHistory();
  return (
    <div className="authPage">
      <div className="authPageImage">
        <div style={{ marginInlineStart: "16%", marginBlockEnd: 50 }}>
          <h1
            style={{
              fontSize: 52,
              fontWeight: 700,
              lineHeight: "60px",
              maxWidth: 400,
              color: color.primarySofter,
              marginBlockEnd: 25,
            }}
          >
            {strings.getString("header")}
          </h1>
          <h4
            className="newHeadingStyle"
            style={{
              fontSize: 24,
              color: color.primarySofter,
              maxWidth: 350,
            }}
          >
            {strings.getString("subheader")}
          </h4>
          <p
            style={{
              fontSize: 40,
              fontWeight: 700,
              maxWidth: 400,
              color: color.primarySofter,
            }}
          >
            ...
          </p>
        </div>
      </div>
      <div className="authContent">
        {activePage === 1 && (
          <Login
            callbackUrl={
              props.location.state
                ? props.location.state.from.pathname +
                  props.location.state.from.search
                : undefined
            }
            activePage={activePage}
            onClick={(active) => setActivePage(active)}
          />
        )}
        {activePage === 2 && (
          <ForgotPassword onClick={() => history.push("login")} />
        )}
        {activePage === 3 && (
          <EmailConfirm onClick={() => history.push("login")} />
        )}
      </div>
    </div>
  );
};

export default Authenticate;
