import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SelectPicker } from "rsuite";
import { Icon } from "..";
import {
  languagesSelector,
  getLocalizedHomepage,
  languageSelector,
  getUserState,
} from "../../redux/selectors";
import { color } from "../../utils/Properties";
import Flag from "react-flagkit";
import "./style.css";
import { SocialMediaLinksObject } from "../../types/components";
import { getSocialMediaLinks, setLanguage } from "../../redux/actions";
import { generateLanguagesPickerArray } from "../../utils/Helpers";
import { loadUserAuth, truckUserAuth } from "../../utils/userTypes";

const Footer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const strings = useSelector(getLocalizedHomepage);
  const languages = useSelector(languagesSelector);
  const language = useSelector(languageSelector);
  const [socialLinks, setSocialLinks] = useState<SocialMediaLinksObject>();
  const user = useSelector(getUserState);
  useEffect(() => {
    dispatch(
      getSocialMediaLinks({
        payload: {
          onSuccess: (message, payload) => setSocialLinks(payload),
          onError: (message) => {},
        },
        url: "HomePage/GetSocialMediaLinks",
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const socialIcons = [
    { icon: "facebook", link: socialLinks?.Facebook },
    { icon: "twitter", link: socialLinks?.Twitter },
    { icon: "linkedin", link: socialLinks?.Linkedin },
    { icon: "youtube", link: socialLinks?.Youtube },
    { icon: "instagram", link: socialLinks?.Instagram },
  ];

  return (
    <div className="footer">
      <div className="footerContent">
        <div
          className="emailSubscriptionOuterDiv"
          style={{
            height: 1,
            backgroundColor: color.secondary,
            borderColor: color.borderColor,
          }}
        ></div>
        <div className="footerNavigationOuterDiv">
          <div className="footerNavigation">
            <div className="footerNavigationCol">
              <p
                style={{
                  fontWeight: 400,
                  fontSize: 20,
                  lineHeight: "27px",
                  maxWidth: 250,
                  color: color.textPrimary,
                  marginBlockEnd: 41,
                  minWidth: 240,
                }}
              >
                {strings.getString("footercontent")}
              </p>
              <p className="smallHeadingStyle" style={{ color: color.primary }}>
                <span
                  style={{ textDecorationLine: "underline", cursor: "pointer" }}
                  onClick={() => history.push("/contact-us")}
                >
                  {strings.getString("contactusfooter")}
                </span>
              </p>
              <div style={{ display: "flex", marginBlockStart: 20 }}>
                {socialIcons.map((icon, i) => (
                  <a
                    key={i}
                    href={icon.link || ""}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginInlineEnd: 10 }}
                  >
                    <Icon icon={icon.icon} />
                  </a>
                ))}
              </div>
            </div>
            <div className="footerNavigationCol">
              <p
                className="boldText"
                style={{
                  lineHeight: "30px",
                  marginBlockEnd: 10,
                }}
              >
                {strings.getString("trucks")?.toLocaleUpperCase()}
              </p>
              <div className="standardText" style={{ color: color.primary }}>
                {user ? (
                  <>
                    {loadUserAuth?.some(
                      (x) => x === user?.AccountType.Code
                    ) && (
                      <p
                        style={{ lineHeight: "30px", cursor: "pointer" }}
                        onClick={() => history.push("/truck-registration")}
                      >
                        {strings.getString("have_truck")}
                      </p>
                    )}
                    {truckUserAuth?.some(
                      (x) => x === user?.AccountType.Code
                    ) && (
                      <p
                        style={{ lineHeight: "30px", cursor: "pointer" }}
                        onClick={() =>
                          history.push("/search-trucks?page=1&take=10")
                        }
                      >
                        {strings.getString("search_trucks")}
                      </p>
                    )}
                  </>
                ) : (
                  <>
                    <p
                      style={{ lineHeight: "30px", cursor: "pointer" }}
                      onClick={() => history.push("/truck-registration")}
                    >
                      {strings.getString("have_truck")}
                    </p>{" "}
                    <p
                      style={{ lineHeight: "30px", cursor: "pointer" }}
                      onClick={() =>
                        history.push("/search-trucks?page=1&take=10")
                      }
                    >
                      {strings.getString("search_trucks")}
                    </p>
                  </>
                )}
              </div>
            </div>
            <div className="footerNavigationCol">
              <p
                className="boldText"
                style={{
                  lineHeight: "30px",
                  marginBlockEnd: 10,
                }}
              >
                {strings.getString("loads")?.toLocaleUpperCase()}
              </p>
              <div className="standardText" style={{ color: color.primary }}>
                {user ? (
                  <>
                    {" "}
                    {truckUserAuth?.some(
                      (x) => x === user?.AccountType.Code
                    ) && (
                      <p
                        style={{ lineHeight: "30px", cursor: "pointer" }}
                        onClick={() => history.push("/load-registration")}
                      >
                        {strings.getString("have_load")}
                      </p>
                    )}
                    {loadUserAuth?.some(
                      (x) => x === user?.AccountType.Code
                    ) && (
                      <p
                        style={{ lineHeight: "30px", cursor: "pointer" }}
                        onClick={() =>
                          history.push("/search-loads?page=1&take=10")
                        }
                      >
                        {strings.getString("search_loads")}
                      </p>
                    )}
                  </>
                ) : (
                  <>
                    <p
                      style={{ lineHeight: "30px", cursor: "pointer" }}
                      onClick={() => history.push("/load-registration")}
                    >
                      {strings.getString("have_load")}
                    </p>{" "}
                    <p
                      style={{ lineHeight: "30px", cursor: "pointer" }}
                      onClick={() =>
                        history.push("/search-loads?page=1&take=10")
                      }
                    >
                      {strings.getString("search_loads")}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="footerEndOuterDiv"
          style={{ backgroundColor: color.textHeader }}
        >
          <div
            className="footerEnd"
            style={{ color: color.side, justifyContent: "flex-end" }}
          >
            <div
              className="footerEndContent"
              style={{ textAlign: "center", justifyContent: "center" }}
            >
              <p style={{ cursor: "pointer" }}>2023 RAPID</p>
            </div>
            <div
              className="footerEndContent"
              style={{ textAlign: "end", justifyContent: "flex-end" }}
            >
              <SelectPicker
                searchable={false}
                className="footerSelectPicker"
                style={{ marginBlockStart: 9 }}
                data={generateLanguagesPickerArray(languages)}
                placement="topEnd"
                cleanable={false}
                value={language === "en" ? "US" : language?.toUpperCase()}
                onSelect={async (language) => {
                  await dispatch(
                    setLanguage(language === "us" ? "en" : language)
                  );
                  history.go(0);
                }}
                renderMenuItem={(label, item) => {
                  return (
                    <>
                      <span
                        style={{ marginInlineEnd: 10 }}
                        className="navbarLanguageDropdownIcon"
                      >
                        <Flag country={item.value.toUpperCase()} />
                      </span>
                      <span className="navbarLanguageDropdownItem">
                        {label}
                      </span>
                    </>
                  );
                }}
                renderValue={(value, item) => {
                  return <Flag country={value} />;
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
