import { produce } from "immer";
import { AppReducer } from "../../types";
import {
  showLoader,
  hideLoader,
  setAuthenticate,
  setScrollPosition,
} from "../actions";

const initialState: AppReducer = {
  loading: false,
  authenticate: false,
  homepage: true,
  scroll_to: undefined,
};
const appReducer = produce((draft: AppReducer, action: any) => {
  switch (action.type) {
    case showLoader.toString():
      draft.loading = true;
      break;
    case hideLoader.toString():
      draft.loading = false;
      break;
    case setAuthenticate.toString():
      draft.authenticate = action.payload;
      break;
    case setScrollPosition.toString():
      draft.scroll_to = action.payload;
      break;
  }
}, initialState);

export default appReducer;
