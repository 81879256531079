import React from "react";
import IcomoonReact from "icomoon-react";
import iconSet from "../../assets/selection.json";

const Icon: React.FC<{
  color?: string;
  size?: string | number;
  icon: string;
  className?: string;
  style?: React.CSSProperties;
  onclicked?: () => void;
}> = (props) => {
  const { color, size = 25, icon, className = "", style, onclicked } = props;
  return (
    <IcomoonReact
      className={className}
      iconSet={iconSet}
      color={color}
      size={size}
      icon={icon}
      style={style}
      onClick={onclicked}
    />
  );
};

export default Icon;
