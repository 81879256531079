import { MutableRefObject, useRef } from "react";
import { useSelector } from "react-redux";
import { Popover, Whisper } from "rsuite";
import { Divider, Icon } from "..";
import { getLocalizedTruckCard } from "../../redux/selectors";
import { color } from "../../utils/Properties";
import moment from "moment";
import "./style.css";
import { VehicleOpportunityDto } from "../../redux/opportunity/type";
import { getCurrency } from "../../utils/Helpers";

type TruckCardProps = {
  truck: VehicleOpportunityDto;
  favList: string[];
  setFavList: (favList: string[]) => void;
  isSelected: (id: string) => boolean;
  setSelected: (id: string) => void;
  truckDetails: string;
  openModal: (id: string) => void;
  setTruckDetails: (id: string) => void;
  openChat: (truck: VehicleOpportunityDto) => void;
  openBooking: (truck: VehicleOpportunityDto, accepted: boolean) => void;
  handlePrint: (ref: MutableRefObject<null>) => void;
};

const dividerPadding: number = 6;

const TruckCardVertical = ({
  truck,
  favList,
  setFavList,
  isSelected,
  setSelected,
  truckDetails,
  setTruckDetails,
  openChat,
  openBooking,
  openModal,
  handlePrint,
}: TruckCardProps) => {
  const printerRef = useRef(null);
  const strings = useSelector(getLocalizedTruckCard);

  return (
    <div
      ref={printerRef}
      className="verticalTruckCard"
      style={{
        height: 425,
        paddingTop: 20,
        border: "1px solid",
        borderColor: "#e6e6e6",
      }}
    >
      <div
        className="smallRegularText"
        style={{
          width: "100%",
          height: `calc(100% - 6px)`,
          paddingInline: 15,
        }}
      >
        <div
          style={{
            width: "100%",
            height: "max-content",
            display: "flex",
            marginBlockEnd: 8,
          }}
        >
          <div
            className="vehicleLogo"
            style={{
              backgroundColor: color.accent,
              borderRadius: 10,
              marginInlineStart: -5,
              marginBlockStart: -5,
            }}
          >
            <img
              className="imageStyles"
              style={{ padding: 5, borderRadius: 12 }}
              src={truck.profileimage}
              alt=""
            />
          </div>
          <div
            style={{
              width: `calc(100% - 65px)`,
              height: 65,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              paddingInlineStart: 5,
            }}
          >
            <span
              className="boldText"
              style={{
                color: color.textHeader + color.fiftyOpacity,
                lineHeight: "15px",
              }}
            >
              {strings.getString("name")}
            </span>
            <p className="mediumHeadingStyle" style={{ lineHeight: "23px" }}>
              {truck.rapidadvisorname}
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p className="smallText">
                {truck.lastofferdate
                  ? moment(truck.lastofferdate).format("l")
                  : "-"}
              </p>
            </div>
          </div>
        </div>
        <Divider paddingBlock={dividerPadding} />
        <div style={{ display: "flex", paddingInline: 3 }}>
          <div
            style={{
              width: "50%",
            }}
          >
            <span className="smallBoldText" style={{ opacity: 0.5 }}>
              {strings.getString("file_number")}
            </span>
            <p>{"#" + truck.filenumber}</p>
          </div>
          <div
            style={{
              width: "50%",
            }}
          >
            <span className="smallBoldText" style={{ opacity: 0.5 }}>
              {strings.getString("truck_type")}
            </span>
            <p>{truck.trucktypename}</p>
          </div>
        </div>

        <Divider paddingBlock={dividerPadding} />
        <div
          style={{
            paddingInline: 3,
          }}
        >
          <p className="smallBoldText" style={{ opacity: 0.5 }}>
            {strings.getString("route")}
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p className="biggerText">
              {truck.loadcountry ? truck.loadcountry : "-"}
            </p>
            <Icon
              icon="right-bold"
              color={color.textSecondary}
              size={13}
              style={{ marginInline: 5 }}
            />
            <p className="biggerText">
              {truck.tocountry ? truck.tocountry : "-"}
            </p>
          </div>
        </div>
        <Divider paddingBlock={dividerPadding} />
        <div
          style={{
            paddingInline: 3,
            display: "flex",
            alignItems: "center",
          }}
        >
          <div>
            <span className="smallBoldText" style={{ opacity: 0.5 }}>
              {strings.getString("estimated_amount")}
            </span>
            <p>
              {truck.currencyisocode
                ? getCurrency(truck.estimatedamount, truck.currencyisocode)
                : truck.estimatedamount}
            </p>
          </div>
          {truck.estimatedamount && (
            <div
              className={
                truck.offerHasBeen
                  ? "offerButtonDisabled smallText"
                  : "offerButton smallText"
              }
              style={{ marginBlock: "5px -30px" }}
              onClick={() => {
                !truck.offerHasBeen && openBooking(truck, false);
              }}
            >
              {strings.getString("make_offer")}
            </div>
          )}
        </div>
        <div
          className={
            truck.offerHasBeen
              ? "actionButtonDisabled card-offer-button"
              : "actionButton card-offer-button"
          }
          onClick={() => {
            if (!truck.offerHasBeen) {
              !(truck.shipperofferprice && truck.shipperofferprice > 0) &&
              truck.estimatedamount
                ? openBooking(truck, true)
                : openBooking(truck, false);
            }
          }}
          style={{
            marginBlock: 20,
            width: "100%",
            cursor: !(truck.shipperofferprice && truck.shipperofferprice > 0)
              ? "pointer"
              : "no-drop",
          }}
        >
          {!truck.offerHasBeen ? (
            truck.estimatedamount ? (
              <p style={{ textAlign: "center" }}>
                {strings.getString("accept_now")}
              </p>
            ) : (
              <p style={{ textAlign: "center" }}>
                {strings.getString("make_offer")}
              </p>
            )
          ) : (
            <p style={{ textAlign: "center" }}>
              {strings.getString("offersending")}
            </p>
          )}
        </div>
        <div
          className="boldText"
          style={{ display: "flex", marginBlockEnd: 7 }}
        >
          <Icon icon="clock" size={17} style={{ marginInlineEnd: 5 }} />
          <p style={{ marginInlineEnd: 5, fontWeight: 600 }}>
            {strings.getString("estimates")}
          </p>
          <span style={{ marginInlineEnd: 30 }}>
            {truck.estimatedarrivaltime
              ? Math.round(truck.estimatedarrivaltime)
              : "-"}
          </span>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: 44,
            width: "100%",
            border: "1px solid",
            borderColor: color.borderSecondary,
            borderRadius: 5,
            cursor: "pointer",
            backgroundColor: color.accent,
          }}
        >
          <Whisper
            delay={500}
            placement="top"
            trigger="hover"
            speaker={
              <Popover>
                <p>{strings.getString("details")}</p>
              </Popover>
            }
            enterable
          >
            <div
              className="extraInfosButton"
              style={{
                borderColor: color.borderSecondary,
                borderStartStartRadius: 5,
                borderEndStartRadius: 5,
                width: "33%",
              }}
              onClick={() =>
                openModal(truck.destination_vehicletype_vehicleopp__c)
              }
            >
              <Icon icon="more" />
            </div>
          </Whisper>
          <Whisper
            delay={500}
            placement="top"
            trigger="hover"
            speaker={
              <Popover>
                <p>{strings.getString("print")}</p>
              </Popover>
            }
            enterable
          >
            <div
              className="extraInfosButton"
              style={{
                borderColor: color.borderSecondary,
                width: "33%",
              }}
              onClick={() => handlePrint(printerRef)}
            >
              <Icon icon="printer" color={color.blackFifty} />
            </div>
          </Whisper>

          <Whisper
            delay={500}
            placement="top"
            trigger="hover"
            speaker={
              <Popover>
                <p>{strings.getString("chat")}</p>
              </Popover>
            }
            enterable
          >
            <div
              className="extraInfosButton"
              style={{ border: "none", width: "33%" }}
              onClick={() => openChat(truck)}
            >
              <Icon icon="chat" />
            </div>
          </Whisper>
        </div>
      </div>
    </div>
  );
};

export default TruckCardVertical;
