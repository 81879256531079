import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
//@ts-ignore
import { useReactToPrint } from "react-to-print";
//@ts-ignore
import { Formik } from "formik";
//@ts-ignore
import * as Yup from "yup";
import "./style.scss";
import {
  GoogleMap,
  LoadScript,
  Marker,
  MarkerClusterer,
} from "@react-google-maps/api";
import {
  Button,
  ChatDrawer,
  FocusError,
  Footer,
  Icon,
  Input,
  LoadCard,
  LoadCardList,
  LoadCardVertical,
  Loader,
  Modal,
  Pagination,
  SelectPicker,
  SidebarFilter,
  Slider,
} from "../../../components";
import {
  getLocalizedErrors,
  getLocalizedSearchTrucks,
  isLoadingState,
} from "../../../redux/selectors";
import { LoadObject, LoadOpportunities, TruckTypes } from "../../../types";
import {
  color,
  CUSTOM_MAP,
  GOOGLE_MAPS_API_KEY,
} from "../../../utils/Properties";
import { icons } from "../../../assets";
import { Alert, Divider, Drawer, Form, Radio, RadioGroup } from "rsuite";
import { ButtonType } from "../../../components/Button";
import { generatePickerArray, getCurrency } from "../../../utils/Helpers";
import { InputType } from "../../../components/Input";
import {
  getLoadOpportunities,
  getLoadOpportunityDetail,
  getTruckTypes,
  scrollToPosition,
  shipperOffer,
} from "../../../redux/actions";
import { routes } from "./data";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { LatLng } from "../../../types/maps";
import { Helmet } from "react-helmet";
import { getLocalizedHelmet } from "../../../redux/selectors";

const mapOptions = {
  center: { lat: 40.930438, lng: 29.150688 },
  zoom: 10,
};

const SearchLoads = () => {
  const helmetStrings = useSelector(getLocalizedHelmet);
  const location = useLocation();
  console.log("location", location);
  const dispatch = useDispatch();
  const screen = useRef<HTMLDivElement>(null);
  let printerRef = useRef(null);
  let modalPrinterRef = useRef(null);
  const formikRef = useRef(null);
  const strings = useSelector(getLocalizedSearchTrucks); // eslint-disable-next-line
  const [truckTypes, setTruckTypes] = useState<TruckTypes[]>();
  const errorStrings = useSelector(getLocalizedErrors);
  const isAppLoading = useSelector(isLoadingState);
  const history = useHistory();
  // eslint-disable-next-line
  const [map, setMap] = useState<google.maps.Map>();
  const [opportunityMessage, setOpportunityMessage] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadData, setLoadData] = useState<LoadOpportunities[]>([]);
  const [displayingLoads, setDisplayingLoads] = useState<any[]>();
  const [selectedLoads, setSelectedLoads] = useState<string[]>([]);
  const [loadDetails, setLoadDetails] = useState<string>("");
  const [favList, setFavList] = useState<string[]>([]);
  const [modalLoad, setModalLoad] = useState<string>("");
  const [chatLoad, setChatLoad] = useState<LoadOpportunities>();
  const [verticalLoadCards, setVerticalLoadCards] = useState<
    (LoadOpportunities | undefined)[]
  >([]);

  const stepsList: string[] = ["1"];
  // eslint-disable-next-line
  const [activePage, setActivePage] = useState<number>(1);
  const [step, setStep] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [take, setTake] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [filterQuery, setFilterQuery] = useState<string>("");
  const [firstError, setFirstError] = useState<string>(""); // eslint-disable-next-line
  const [itemListArray, setItemListArray] = useState([
    { value: 10, label: 10 },
    { value: 20, label: 20 },
    { value: 30, label: 30 },
  ]);

  const [showChat, setShowChat] = useState<boolean>(false);
  const [showCards, setShowCards] = useState<boolean>(false);
  const [modalVisibility, setModalVisibility] = useState<LoadOpportunities>();

  const [showMenu, setShowMenu] = useState(false);
  const [bookingModal, setBookingModal] = useState<boolean>(false);
  const [formInfos, setFormInfos] = useState<{
    firstOne: boolean;
    acceptNow: boolean;
  }>({ firstOne: false, acceptNow: false });
  // eslint-disable-next-line
  const [selectedLoad, setSelectedLoad] = useState<LoadOpportunities>();

  const QuickBookingSchema = Yup.object().shape({
    route: Yup.string(),
    truckType: Yup.string().required(errorStrings.getString("required")),
    truckPrice: Yup.number()
      .moreThan(0)
      .required(errorStrings.getString("required")),
    truckNumber: Yup.number()
      .required(errorStrings.getString("required"))
      .typeError(errorStrings.getString("number")),
    description: Yup.string(),
  });

  const loadHandler = (map: google.maps.Map<Element>) => {
    setMap(map);
  };
  // eslint-disable-next-line
  const fitBounds = (map: google.maps.Map<Element>, trucks?: LoadObject[]) => {
    const bounds = new window.google.maps.LatLngBounds();
    trucks?.map((truck) => {
      bounds.extend(truck.location);
      return truck.id;
    });
    // eslint-disable-next-line array-callback-return
    map.fitBounds(bounds);
  };

  function updateQueryStringParameter(uri: string, key: string, value: string) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf("?") !== -1 ? "&" : "?";
    if (uri.match(re)) {
      return uri.replace(re, "$1" + key + "=" + value + "$2");
    } else {
      return uri + separator + key + "=" + value;
    }
  }

  useEffect(() => {
    dispatch(
      getTruckTypes({
        payload: {
          onSuccess: (message, payload) => {
            setTruckTypes(payload);
          },
          onError: (message) => {},
        },
        url: "System/GetTruckType",
      })
    );

    const array = new URLSearchParams(location.search);

    const loadPageNumber = array.get("page");
    const loadTake = array.get("take");

    if (loadPageNumber) {
      if (typeof loadPageNumber === "string") {
        const pageNumber = parseInt(loadPageNumber);

        setPage(pageNumber < 1 ? 1 : pageNumber);
      }
    }
    if (loadTake) {
      if (typeof loadTake === "string") {
        const takeNumber = parseInt(loadTake);

        if (itemListArray.filter((x) => x.value === takeNumber).length > 0) {
          setTake(takeNumber);
        } else {
          setTake(itemListArray[0].value);
        }
      }
    }
    return () => {}; //Component kaldırılırken çalışacak fonksiyon
    // eslint-disable-next-line
  }, []);
  // eslint-disable-next-line
  const getLocationFromAddress = async (address: string): Promise<LatLng> =>
    await new Promise((resolve, reject) => {
      console.log("PROMISE", address);
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        {
          address,
        },
        (results, status) => {
          console.log("GEO CALLBACK", address, status);
          if (status === "OK") {
            var latLng = {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            };
            console.log("LAT LONG", address, latLng, new Date().getTime());
            setTimeout(resolve, 500, latLng);
          } else reject(status);
        }
      );
    });

  const getPageData = () => {
    setLoading(true);
    setLoadData([]);
    setDisplayingLoads([]);
    const array = new URLSearchParams(location.search);
    location.search.includes("opportunity")
      ? dispatch(
          getLoadOpportunities({
            payload: {
              onSuccess: (message, payload, response) => {
                setLoadData(payload?.list ? payload.list : []);
                setTotal(payload?.total ?? 0);
                setDisplayingLoads(payload?.list ?? []);
                setLoading(false);
                response &&
                  response.SubResult &&
                  setOpportunityMessage(
                    strings.formatString(
                      strings.getString("loadopportunitymessage"),
                      response.SubResult
                    )
                  );
              },
              onError: (message) => {
                setLoading(false);
              },
            },
            url: `LoadOpportunity/GetMatchedLoadOpportunities?_skip=${
              (page - 1) * take
            }&_take=${take}&opportunity=${array.get(
              "opportunity"
            )}&${filterQuery}`,
          })
        )
      : dispatch(
          getLoadOpportunities({
            payload: {
              onSuccess: (message, payload) => {
                setLoadData(payload?.list ? payload.list : []);
                setTotal(payload?.total ?? 0);
                setDisplayingLoads(payload?.list ?? []);
                setLoading(false);
              },
              onError: (message) => {
                setLoading(false);
              },
            },
            url: `LoadOpportunity/GetLoadOpportunitiesNew?_skip=${
              (page - 1) * take
            }&_take=${take}&${filterQuery}`,
          })
        );
  };

  const getMapData = () => {
    setLoading(true);
    setShowCards(false);
    setLoadData([]);
    setVerticalLoadCards([]);
    location.search.includes("opportunity")
      ? dispatch(
          getLoadOpportunities({
            payload: {
              onSuccess: (message, payload) => {
                setLoadData(payload?.list ? payload.list : []);

                setTotal(payload?.total ?? 0);
                setDisplayingLoads(payload?.list ?? []);
                setLoading(false);
              },
              onError: (message) => {
                setLoading(false);
              },
            },
            url: `LoadOpportunity/GetMapMatchedLoadOpportunities?${filterQuery}`,
          })
        )
      : dispatch(
          getLoadOpportunities({
            payload: {
              onSuccess: (message, payload) => {
                setLoadData(payload?.list ? payload.list : []);
                setTotal(payload?.total ?? 0);
                setDisplayingLoads(payload?.list ?? []);
                setLoading(false);
              },
              onError: (message) => {
                setLoading(false);
              },
            },
            url: `LoadOpportunity/GetMapLoadOpportunities?${filterQuery}`,
          })
        );
  };
  useEffect(() => {
    setLoadData([]);
    dispatch(scrollToPosition(0));

    if (activePage === 2) {
      getMapData();
    } else {
      getPageData();
    }
    window.history.pushState(
      "",
      document.title,
      updateQueryStringParameter(window.location.href, "page", page.toString())
    );
    window.history.pushState(
      "",
      document.title,
      updateQueryStringParameter(window.location.href, "take", take.toString())
    );
    console.log("FilterQuery", filterQuery);
    // eslint-disable-next-line
  }, [take, page, filterQuery]);

  useEffect(() => {
    if (firstError && firstError.length > 0) {
      setStep(parseInt(firstError.charAt(firstError.length - 1)));
      setFirstError("");
    }
    // eslint-disable-next-line
  }, [firstError]);

  const isSelected = (id: string) => {
    let selected = selectedLoads.some((loadId) => loadId === id);
    return selected;
  };

  const selectedCheck = (id: string) => {
    let newArr: string[] = [...selectedLoads];
    let findIndex = newArr.findIndex((truckId) => truckId === id);
    if (findIndex >= 0) {
      newArr.splice(findIndex, 1);
    } else {
      newArr.push(id);
    }
    setSelectedLoads(newArr);
  };

  const handlePrint = useReactToPrint({
    content: () => printerRef.current,
  });
  const handleModalPrint = useReactToPrint({
    content: () => modalPrinterRef.current,
  });

  const onModalOpen = (id: string, print: boolean = false) => {
    setModalLoad(id);
    setLoadDetails(id);
    dispatch(
      getLoadOpportunities({
        payload: {
          onSuccess: (message, payload) => {
            setLoading(false);
            setSelectedLoad(payload?.list[0]);
            setModalVisibility(payload?.list[0]);
          },
          onError: (message) => {
            setLoading(false);
          },
        },
        url: `LoadOpportunity/GetByLoadOpportunities?sfid=${id}`,
      })
    );
  };

  const fillModalData = (id: string) => {
    dispatch(
      getLoadOpportunities({
        payload: {
          onSuccess: (message, payload) => {
            setLoading(false);
            setSelectedLoad(payload?.list[0]);
          },
          onError: (message) => {
            setLoading(false);
          },
        },
        url: `LoadOpportunity/GetByLoadOpportunities?sfid=${id}`,
      })
    );
  };

  const onModalClose = () => {
    setModalVisibility(undefined);
    setSelectedLoad(undefined);
  };

  const openChat = (load: LoadOpportunities) => {
    setShowChat(true);
    history.push(location.search + "&chatId=" + load.id);
    setChatLoad(load);
  };

  const onBookingModalOpen = (
    load: LoadOpportunities,
    firstOne: boolean,
    acceptNow: boolean
  ) => {
    setFormInfos({ firstOne, acceptNow });
    dispatch(
      getTruckTypes({
        payload: {
          onSuccess: (message, payload) => {
            setTruckTypes(payload);
          },
          onError: (message) => {},
        },
        url: "System/GetTruckType",
      })
    );
    fillModalData(load.id);
    setBookingModal(true);
  };

  const onBookingModalClose = () => {
    setBookingModal(false);
    setSelectedLoad(undefined);
    setStep(0);
  };
  const onSubmitPress = ({
    route,
    truckType,
    truckNumber,
    description,
    truckPrice,
  }: {
    truckPrice: number;
    route: string;
    truckType: string;
    truckNumber: number;
    description: string;
  }) => {
    if (selectedLoad) {
      const selectedRoute = selectedLoad.RouteTab?.find(
        (x) => x.sfid === route
      );
      const currencyisocode = selectedRoute?.currencyisocode ?? "EUR";

      dispatch(
        shipperOffer({
          params: {
            currencyisocode: currencyisocode,
            accepted_vehicle_type__c: truckType,
            dateoftransporteroffer__c: moment().format(), //("01/01/YYYY"),
            firsat_destinasyon_rota__c: route,
            numberofvehiclesoftheshipper__c: truckNumber,
            opportunitydestination__c: selectedLoad.id,
            opportunityy__c: selectedLoad.parentsfid,
            pricefromshipper__c: truckPrice,
            shipperofferdescription__c: description,
            firstcomegets: formInfos.acceptNow,
            accepted: formInfos.acceptNow,
          },
          onSuccess: () => {
            Alert.success(
              formInfos.firstOne
                ? strings.getString("notify_by_rapid")
                : strings.getString("notify_in_shortest"),
              7000
            );
            setDisplayingLoads([]);
            onBookingModalClose();
            if (activePage === 2) {
              getMapData();
            } else {
              getPageData();
            }
          },
          onError: (message) => {
            Alert.error(message, 7000);
          },
        })
      );
    }
  };
  const setMaxTrucks = (e: string, l: LoadOpportunities) => {
    const val = Number(e);
    const max_number =
      l?.loadTrucks && l?.loadTrucks?.length > 0
        ? parseInt(l?.loadTrucks[0]?.vehiclenumber__c.toString() ?? "1")
        : 1;
    return val > max_number ? max_number : val;
  };
  return (
    <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
      <Helmet>
        <title>{helmetStrings.getString("LoadHelmet")}</title>
      </Helmet>
      <div
        className="innerScreen listScreen"
        ref={screen}
        style={{ overflowY: activePage === 2 ? "hidden" : "visible" }}
      >
        <ChatDrawer
          setTruckUndefined={() => setChatLoad(undefined)}
          show={showChat}
          setShow={(show: boolean) => setShowChat(show)}
          truck={chatLoad}
        />
        <Modal
          title=" "
          newMinHeight={500}
          //className="big"
          visible={modalVisibility !== undefined}
          close={onModalClose}
        >
          {modalVisibility && (
            <LoadCard
              ref={modalPrinterRef}
              load={modalVisibility}
              selected={isSelected(modalLoad)}
              setSelected={(truckId) => selectedCheck(truckId)}
              details={loadDetails}
              setDetails={(truckId) => setLoadDetails(truckId)}
              favList={favList}
              setFavList={setFavList}
              //@ts-ignore
              openChat={() => openChat(selectedLoad)}
              openBooking={(load, firstOne, acceptNow) =>
                onBookingModalOpen(load, firstOne, acceptNow)
              }
              handlePrint={() => {
                //@ts-ignore
                handleModalPrint();
              }}
            />
          )}
        </Modal>
        <Modal
          title={
            strings.getString("quick_booking") +
            " | " +
            (selectedLoad?.filenumber ?? "")
          }
          visible={bookingModal}
          close={onBookingModalClose}
        >
          <div style={{ width: "100%", height: "100%" }}>
            {selectedLoad && (
              <Formik
                ref={formikRef}
                initialValues={{
                  accepted: formInfos.acceptNow,
                  route:
                    selectedLoad?.RouteTab && selectedLoad?.RouteTab?.length > 0
                      ? selectedLoad?.RouteTab.find(
                          (x) => x.shipperofferprice === null
                        )?.sfid ?? ""
                      : "",
                  truckType:
                    selectedLoad?.loadTrucks &&
                    selectedLoad?.loadTrucks?.length > 0
                      ? selectedLoad?.loadTrucks[0]?.vehicletypeid ?? ""
                      : "",
                  truckNumber:
                    selectedLoad?.loadTrucks &&
                    selectedLoad?.loadTrucks?.length > 0
                      ? parseInt(
                          selectedLoad?.loadTrucks[0]?.vehiclenumber__c.toString() ??
                            "0".toString()
                        )
                      : 0,
                  truckPrice:
                    selectedLoad?.RouteTab && selectedLoad?.RouteTab?.length > 0
                      ? selectedLoad?.RouteTab.find(
                          (x) => x.shipperofferprice === null
                        )?.targetprice ?? 0
                      : selectedLoad.estimatedamount ?? 0,
                  description: "",
                }}
                validationSchema={QuickBookingSchema}
                onSubmit={onSubmitPress}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  values,
                  errors,
                  touched,
                }) => {
                  return (
                    <Form style={{ width: "100%" }}>
                      <>
                        {selectedLoad?.RouteTab ? (
                          <RadioGroup
                            name="route"
                            value={values.route}
                            onChange={(v, e) => {
                              handleChange("route")(v);
                              if (v) {
                                const currentPrice =
                                  selectedLoad?.RouteTab &&
                                  selectedLoad?.RouteTab.find(
                                    (x) => x.sfid === v
                                  )?.targetprice;
                                setFieldValue("truckPrice", currentPrice ?? 0);
                              } else setFieldValue("truckPrice", 0);
                            }}
                          >
                            {selectedLoad?.RouteTab.map((route, i) => {
                              return (
                                <div key={i}>
                                  <Radio
                                    value={route.sfid}
                                    disabled={route.shipperofferprice > 0}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginInlineEnd: 10,
                                          width: `calc(100% - 120px)`,
                                        }}
                                      >
                                        {route.route +
                                          " " +
                                          (route.distance != null
                                            ? " - " +
                                              (route.distance?.toFixed(0) ??
                                                0) +
                                              " km"
                                            : "")}
                                      </div>
                                      <div>
                                        {route.targetprice != null
                                          ? getCurrency(
                                              route.targetprice,
                                              route.currencyisocode
                                            )
                                          : ""}
                                      </div>
                                    </div>
                                  </Radio>
                                  {i + 1 !== routes.length ? (
                                    <Divider style={{ margin: "10px 0" }} />
                                  ) : (
                                    <div style={{ height: 15 }} />
                                  )}
                                </div>
                              );
                            })}
                          </RadioGroup>
                        ) : null}
                        <SelectPicker
                          searchable={true}
                          style={{ marginBlockEnd: 15 }}
                          cleanable={false}
                          label={strings.getString("truck_type")}
                          data={
                            selectedLoad.loadTrucks
                              ? generatePickerArray(
                                  selectedLoad.loadTrucks.map((truck) => {
                                    return {
                                      description: truck.vehicletype,
                                      id: truck.vehicletypeid,
                                    };
                                  })
                                )
                              : []
                          }
                          name="truckType"
                          value={values.truckType}
                          onSelect={handleChange("truckType")}
                          onBlur={handleBlur("truckType")}
                          error={errors.truckType}
                          touched={touched.truckType}
                        />
                        <Input
                          inputType={InputType.number}
                          label={strings.getString("number_of_trucks")}
                          value={parseInt(
                            values.truckNumber.toString()
                          ).toString()}
                          name="selectedCountries"
                          onChange={(e) =>
                            setFieldValue(
                              "truckNumber",
                              setMaxTrucks(e, selectedLoad)
                            )
                          }
                          onBlur={handleBlur("truckNumber")}
                          error={errors.truckNumber}
                          touched={touched.truckNumber}
                        />

                        <Input
                          inputType={InputType.number}
                          disabled={
                            ((formInfos.acceptNow &&
                              selectedLoad.estimatedamount &&
                              selectedLoad.firstcomegets) ??
                              0) > 0
                          }
                          label={
                            strings.getString("price") +
                            (!selectedLoad.RouteTab
                              ? getCurrency("", selectedLoad.currencyisocode)
                              : "")
                          }
                          value={values.truckPrice.toString()}
                          name="selectedPrice"
                          onChange={(e) =>
                            setFieldValue("truckPrice", Number(e))
                          }
                          onBlur={handleBlur("truckPrice")}
                          error={errors.truckPrice as string}
                          //@ts-ignore
                          touched={touched.truckPrice}
                        />
                        <Input
                          label={strings.getString("description")}
                          value={values.description}
                          name="description"
                          onChange={handleChange("description")}
                          onBlur={handleBlur("description")}
                          error={errors.description}
                          touched={touched.description}
                        />
                      </>

                      <div
                        style={{
                          width: "100%",
                          height: 48,
                          marginBlockStart: 30,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ width: 150 }}>
                          {step !== 0 && (
                            <Button
                              buttonType={ButtonType.basic}
                              title={strings.getString("previous")}
                              style={{ paddingBlock: 14 }}
                              onClick={() => {
                                setStep(step - 1);
                              }}
                            />
                          )}
                        </div>
                        <div style={{ width: 150 }}>
                          {step <= stepsList.length - 1 && (
                            <Button
                              type="submit"
                              title={
                                step === stepsList.length - 1
                                  ? strings.getString("submit")
                                  : strings.getString("next")
                              }
                              onClick={handleSubmit}
                            />
                          )}
                        </div>
                      </div>
                      <FocusError />
                    </Form>
                  );
                }}
              </Formik>
            )}
          </div>
        </Modal>
        <div className="mobile-search-sidebarfilter">
          <Drawer
            className="mobile-search-sidebarfilter-drawer"
            placement={"left"}
            show={showMenu}
            onHide={() => setShowMenu(false)}
          >
            <Drawer.Body>
              <SidebarFilter
                filterQuery={filterQuery}
                setFilterQuery={(f) => {
                  setFilterQuery(f);
                }}
                setPage={(p) => {
                  setPage(p);
                }}
              />
            </Drawer.Body>
          </Drawer>
        </div>
        <div
          className="innerScreenContent search-innerScreenContent"
          style={{ height: "auto" }}
        >
          {activePage === 1 && (
            <div
              className="sidebarFilter search-sidebarfilter-main-div"
              style={{
                backgroundColor: color.pageBgColor,
                height: "auto",
                overflow: "auto",
              }}
            >
              <div className="sidebarFilterInnerContent">
                <SidebarFilter
                  setFilterQuery={(f) => {
                    setFilterQuery(f);
                  }}
                  setPage={(p) => {
                    setPage(p);
                  }}
                />
              </div>
            </div>
          )}
          <div
            className={
              "searchTrucksPageContent  searchtruckpagecontent-main" +
              (activePage === 2 ? " width-100" : "")
            }
            style={{
              width: activePage === 2 ? "100%" : "unset",
            }}
          >
            <div style={{ width: "100%" }}>
              <div
                className={
                  "searchTrucksPageInnerContent " +
                  (activePage === 2 ? " width-100" : "")
                }
                style={{
                  width: activePage === 2 ? "100%" : 934,
                  position: activePage === 2 ? "absolute" : "unset",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    paddingBlock: 15,
                    paddingInline: "30px 0px",
                  }}
                >
                  <div
                    className={
                      "resultsResponsive" +
                      (activePage === 2 ? " width-95" : "")
                    }
                    style={{
                      width: "100%",
                      height: "max-content",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBlockEnd: 26,
                    }}
                  >
                    <div
                      className="headingStyle  search-truck-result"
                      style={{ marginBlockEnd: 0, fontWeight: 400 }}
                    >
                      {strings.getString("results")}
                      <p
                        style={{
                          fontSize: 12,
                          color: color.textPrimary,
                          marginBlockStart: 5,
                        }}
                      >
                        {strings.formatString(
                          strings.getString("results_found"),
                          String(total)
                        )}
                      </p>
                    </div>

                    <div className="responsivefilterButtonsOuterDiv">
                      <div
                        className="filterButtons"
                        style={{ backgroundColor: color.side2, color: "white" }}
                        onClick={() => {
                          setShowMenu(true);
                        }}
                      >
                        <Icon icon="preferrences" size={20} />
                      </div>
                    </div>
                    <div className="filterButtonsOuterDiv">
                      <div
                        className="filterButtons"
                        style={{
                          backgroundColor: color.accent,
                          border: "1px solid",
                          borderColor: color.textPrimary,
                        }}
                        onClick={() => {
                          console.log("aaaaaaaaaaa");
                          setLoadData([]);
                          scrollToPosition(0);
                          const activePageSet = activePage === 1 ? 2 : 1;
                          if (activePageSet === 2) {
                            getMapData();
                          } else getPageData();
                          setActivePage(activePageSet);
                        }}
                      >
                        <Icon
                          icon={activePage === 1 ? "map" : "list"}
                          size={20}
                        />
                        <span style={{ marginInlineEnd: 5 }}>
                          {activePage === 1
                            ? strings.getString("map_view")
                            : strings.getString("list_view")}
                        </span>
                      </div>
                      {activePage === 2 && (
                        <div
                          className="filterButtons"
                          style={{
                            backgroundColor: color.side2,
                            color: "white",
                          }}
                          onClick={() => {
                            setShowMenu(true);
                          }}
                        >
                          <Icon icon="preferrences" size={20} /> Filter
                        </div>
                      )}
                    </div>
                  </div>
                  {activePage === 1 && (
                    <div
                      className="searchResults"
                      style={{ position: "relative", minHeight: "250px" }}
                    >
                      {!isAppLoading && loading && (
                        <Loader
                          style={{
                            position: "absolute",
                            backgroundColor: "#fff",
                            maxHeight: "250px",
                          }}
                        />
                      )}
                      {opportunityMessage && (
                        <p
                          style={{
                            fontWeight: 700,
                            fontSize: 24,
                            textAlign: "center",
                            border: "1px solid " + color.bgSecondary,
                            padding: 10,
                          }}
                        >
                          {opportunityMessage}
                        </p>
                      )}
                      <LoadCardList
                        loads={displayingLoads || []}
                        favList={favList}
                        setFavList={(favs) => setFavList(favs)}
                        isSelected={(id) => isSelected(id)}
                        setSelected={(id) => selectedCheck(id)}
                        loadDetails={loadDetails}
                        setLoadDetails={(id) => setLoadDetails(id)}
                        openChat={(load) => {
                          dispatch(
                            getLoadOpportunityDetail({
                              payload: {
                                onSuccess: (message, payload) => {
                                  payload && openChat(payload);
                                },
                                onError: (message) => {},
                              },
                              url: `LoadOpportunity/GetByLoadOpportunities?sfid=${load.id}`,
                            })
                          );
                        }}
                        openBooking={(loadId, firstOne, acceptNow) =>
                          onBookingModalOpen(loadId, firstOne, acceptNow)
                        }
                        handlePrint={(ref) => {
                          printerRef = ref;
                          //@ts-ignore
                          handlePrint();
                        }}
                      />
                      <Pagination
                        data={loadData}
                        totalCount={total}
                        take={take}
                        page={page}
                        itemListArray={itemListArray}
                        setTake={(t) => {
                          setTake(t);
                        }}
                        setPage={(p) => {
                          setPage(p);
                        }}
                        returnedData={(dataArr) => setDisplayingLoads(dataArr)}
                      />
                    </div>
                  )}
                </div>
              </div>
              {activePage === 2 && (
                <>
                  <GoogleMap
                    mapContainerClassName="rapid-mapview"
                    id="rapidMap"
                    options={{
                      gestureHandling: "cooperative",
                      styles: CUSTOM_MAP,
                      disableDefaultUI: true,
                      maxZoom: 15,
                      zoomControl: true,
                    }}
                    mapContainerStyle={{
                      width: "100%",
                      //@ts-ignore
                      height: "calc(100vh - 195px)",
                      position: "relative",
                      top: 0,
                    }}
                    center={mapOptions.center}
                    zoom={mapOptions.zoom}
                    onLoad={(map) => loadHandler(map)}
                  >
                    <MarkerClusterer
                      options={{ minimumClusterSize: 0 }}
                      onClick={(cluster) => {
                        verticalLoadCards.length > 0 &&
                          setVerticalLoadCards([]);
                        let clusterMarkerIDs: string[] = [];
                        cluster.markers?.forEach((marker) => {
                          const id = marker.getTitle();
                          if (id) clusterMarkerIDs.push(id);
                        });
                        let filteredTrucks: LoadOpportunities[] =
                          loadData.filter((tr) =>
                            clusterMarkerIDs.some((cmi) => cmi === tr.id)
                          );
                        setVerticalLoadCards(filteredTrucks);
                        setShowCards(true);
                      }}
                    >
                      {(clusterer) =>
                        loadData.map((load) => {
                          if (!load.startLat || !load.startLong) return null;
                          return (
                            <Marker
                              key={load.id}
                              //options={{ clickable: true }}
                              position={{
                                lat: load.startLat,
                                lng: load.startLong,
                              }}
                              clusterer={clusterer}
                              label={"1"}
                              icon={icons.m1}
                              title={load.id}
                              onClick={() => {
                                const foundLoad = loadData.find(
                                  (ld) => ld.id === load.id
                                );
                                setVerticalLoadCards([foundLoad]);
                                setShowCards(true);
                              }}
                            />
                          );
                        })
                      }
                    </MarkerClusterer>
                  </GoogleMap>
                  <div
                    style={{
                      width: "90%",
                      height: showCards ? 550 : 25,
                      overflow: "hidden",
                      marginInlineStart: "5%",
                      position: "relative",
                      zIndex: 1,
                      top: showCards ? -602 : -35,
                      transition: "0.3s ease-in-out",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        width: 25,
                        height: 25,
                        margin: "auto",
                        cursor: "pointer",
                      }}
                      onClick={() => setShowCards(!showCards)}
                    >
                      <Icon
                        icon={showCards ? "down" : "up"}
                        color={color.primary}
                      />
                    </div>
                    {verticalLoadCards.length > 0 && (
                      <Slider
                        noArrows={true}
                        noInfinite={true}
                        responsive={[
                          {
                            breakpoint: 3841,
                            settings: {
                              slidesToShow: 10,
                              slidesToScroll: 10,
                            },
                          },
                          {
                            breakpoint: 2561,
                            settings: {
                              slidesToShow: 5,
                              slidesToScroll: 5,
                            },
                          },
                          {
                            breakpoint: 1920,
                            settings: {
                              slidesToShow: 3,
                              slidesToScroll: 3,
                            },
                          },
                          {
                            breakpoint: 1280,
                            settings: {
                              slidesToShow: 2,
                              slidesToScroll: 2,
                            },
                          },
                          {
                            breakpoint: 928,
                            settings: {
                              slidesToShow: 1,
                              slidesToScroll: 1,
                              centerMode: false,
                            },
                          },
                        ]}
                      >
                        {verticalLoadCards.map((load, i) => {
                          if (load) {
                            return (
                              <LoadCardVertical
                                key={load.id}
                                ref={modalPrinterRef}
                                load={load}
                                favList={favList}
                                setFavList={(favs) => setFavList(favs)}
                                selected={isSelected(load.id)}
                                setSelected={(id) => selectedCheck(id)}
                                openModal={(id) => onModalOpen(id)}
                                openChat={(load) => {
                                  dispatch(
                                    getLoadOpportunityDetail({
                                      payload: {
                                        onSuccess: (message, payload) => {
                                          payload && openChat(payload);
                                        },
                                        onError: (message) => {},
                                      },
                                      url: `LoadOpportunity/GetByLoadOpportunities?sfid=${load.id}`,
                                    })
                                  );
                                }}
                                openBooking={(loadId, firstOne, acceptNow) =>
                                  onBookingModalOpen(
                                    loadId,
                                    firstOne,
                                    acceptNow
                                  )
                                }
                                handlePrint={() => {
                                  //@ts-ignore
                                  handleModalPrint();
                                }}
                              />
                            );
                          } else return <></>;
                        })}
                      </Slider>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </LoadScript>
  );
};

export default SearchLoads;
