import React from "react";
import { Uploader, UploaderProps as UploaderRSProps } from "rsuite";
import { Icon } from "..";
import { color } from "../../utils/Properties";
import "./style.css";

const styles = {
  width: "100%",
  height: "100%",
};

export enum UploaderType {
  default,
}

const UploaderRsuite = ({
  label,
  error,
  touched,
  uploaderType,
  ...rest
}: UploaderProps) => {
  const uploaderStyles = () => {
    const defStyle = {
      display: "flex",
      overflow: "auto",
    };
    switch (uploaderType) {
      case UploaderType.default:
      default:
        return {
          ...defStyle,
          width: "100%",
          flexDirection: "column",
        } as React.CSSProperties;
    }
  };

  return (
    <>
      <Uploader
        autoUpload={false}
        style={{
          ...uploaderStyles(),
        }}
        {...rest}
      >
        <button
          style={{
            ...styles,
            borderColor: error && touched ? color.error : color.borderSecondary,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon
              icon="upload"
              size={20}
              style={{ width: 24, marginTop: 10 }}
            />
            <div className="smallText" style={{ padding: 10, fontWeight: 400 }}>
              {label}
            </div>
          </div>
        </button>
      </Uploader>
      {error && touched && <p className="inputErrorStyles">{error}</p>}
    </>
  );
};

interface UploaderProps extends UploaderRSProps {
  label: string;
  error?: string | null;
  touched?: boolean | null;
}

export default UploaderRsuite;
