import { MutableRefObject, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader, Popover, Whisper } from "rsuite";
import { Divider, Icon, Nav } from "..";
import { getLocalizedLoadCard } from "../../redux/selectors";
import moment from "moment";
import "./style.scss";
import { LoadOpportunities, RouteObject } from "../../types";
import { getCurrency } from "../../utils/Helpers";
import ScrollBar from "react-perfect-scrollbar";

import { GoogleMap, DirectionsRenderer } from "@react-google-maps/api";
import { color, CUSTOM_MAP, mapContainerStyles } from "../../utils/Properties";
import Flag from "react-flagkit";
import { getLoadOpportunityDetailWithoutLoader } from "../../redux/actions";
type LoadCardProps = {
  load: LoadOpportunities;
  selected: boolean;
  setSelected: (loadId: string) => void;
  details: string;
  setDetails: (loadId: string) => void;
  favList: string[];
  setFavList: (favList: string[]) => void;
  openChat: () => void;
  openBooking: (
    load: LoadOpportunities,
    fistOne: boolean,
    acceptNow: boolean
  ) => void;
  handlePrint: (ref: MutableRefObject<null>) => void;
};

const dividerPadding: number = 6;

const LoadCardMobil = ({
  load,
  selected,
  setSelected,
  details,
  setDetails,
  favList,
  setFavList,
  openChat,
  openBooking,
  handlePrint,
}: LoadCardProps) => {
  const dispatch = useDispatch();
  const printerRef = useRef(null);
  const strings = useSelector(getLocalizedLoadCard);
  const [route_directions, setRouteDirections] = useState<
    google.maps.DirectionsResult[]
  >([]);
  const [selectedLoad, setSelectedLoad] = useState(load);
  const [loader, setLoader] = useState(false);
  const [path_distance, setPathDistance] = useState<string>("");
  const [path_distance_index, setPathDistanceIndex] = useState<number>(0);
  function clickableDirectionServiceResult(data: RouteObject) {
    let waypoints: google.maps.DirectionsWaypoint[] = [];
    if (data.koordinat) {
      JSON.parse(data.koordinat)?.forEach((data: any) => {
        waypoints.push({
          location: new google.maps.LatLng(data.Latitude, data.Longtitude),
          stopover: true,
        });
      });
    }
    setRouteDirections([]);
    getDirectionsServiceResult(waypoints);
  }

  const getDirectionsServiceResult = (
    waypoints?: google.maps.DirectionsWaypoint[]
  ) => {
    if (
      selectedLoad.DetailTab &&
      selectedLoad.DetailTab.ll1name &&
      selectedLoad.DetailTab.ul1name
    ) {
      const DirectionsService = new google.maps.DirectionsService();
      console.log("WAYPOINTS", waypoints);
      DirectionsService.route(
        {
          origin: selectedLoad.DetailTab.ll1name,
          destination: selectedLoad.DetailTab.ul1name,
          waypoints,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          console.log("ROUTE RESULT", result);
          if (status === google.maps.DirectionsStatus.OK) {
            setRouteDirections((rds) => [...rds, result]);
            console.log("LEGS", result.routes);
            let total_distance = 0;
            if ((result?.routes[0]?.legs?.length ?? 0) > 0) {
              result.routes[0].legs.forEach((l) => {
                total_distance += l.distance.value;
              });
            }
            if (total_distance > 0) {
              setPathDistance(Math.round(total_distance / 1000) + "km");
            } else setPathDistance("");
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    }
  };
  function decideColor(i: number) {
    switch (i) {
      case 0:
        return "blue";
      case 1:
        return "orange";
      case 2:
        return "yellow";
      case 3:
        return "black";
      case 4:
        return "pink";
      case 5:
        return "gray";

      default:
        return "red";
    }
  }

  const navContent = [
    {
      id: 0,
      icon: "list",
      activeColor: color.primary,
      label: "",
    },
    {
      id: 1,
      icon: "indicator",
      activeColor: color.primary,
      label: "",
    },
    {
      id: 2,
      icon: "shorter-list",
      activeColor: color.primary,
      label: "",
    },
    {
      id: 3,
      icon: "route",
      activeColor: color.primary,
      label: "",
    },
    {
      id: 4,
      icon: "map-pin",
      activeColor: color.primary,
      label: "",
    },
    {
      id: 5,
      icon: "more",
      activeColor: color.primary,
      label: "",
    },
  ];

  const disabledCheck = (selectedLoad: LoadOpportunities) => {
    if (selectedLoad.RouteTab == null && selectedLoad.shipperofferprice > 0) {
      return true;
    } else if (selectedLoad.RouteTab) {
      if (
        selectedLoad.RouteTab.find((x) => x.shipperofferprice == null) == null
      ) {
        return true;
      }
    }

    return false;
  };

  return (
    <div
      ref={printerRef}
      className="verticalLoadCard"
      style={{
        boxShadow: "unset",
        border: "1px solid",
        paddingBottom: 10,
        borderColor: selected ? color.primary : "#e6e6e6",
        height: details === selectedLoad.id ? "auto" : "550px",
        position: "relative",
      }}
    >
      <div
        className="smallRegularText"
        style={{
          width: "100%",
          paddingInline: 15,
          marginBlock: 10,
        }}
      >
        <div
          style={{
            width: "100%",
            height: "max-content",
            display: "flex",
          }}
        >
          <div
            className="vehicleLogo"
            style={{
              backgroundColor: color.accent,
              borderRadius: 10,
              marginInlineStart: -5,
              marginBlockStart: -5,
            }}
          >
            <img
              className="imageStyles"
              style={{ padding: 5, borderRadius: 12 }}
              src={load.Owner ? load.Owner.profileimage : load.profileimage}
              alt=""
            />
          </div>
          <div
            style={{
              width: `calc(100% - 65px)`,
              height: 65,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              paddingInlineStart: 5,
              gap: 5,
            }}
          >
            <p className="mediumHeadingStyle" style={{ lineHeight: "23px" }}>
              {selectedLoad.rapidadvisorname}
            </p>
            <div
              className="smallText"
              style={{ display: "flex", alignItems: "center" }}
            >
              {strings.getString("post_end_date")}:{" "}
              <span className="smallText">
                {selectedLoad.postedat
                  ? moment(selectedLoad.postedat).format("l")
                  : ""}
              </span>
            </div>
          </div>
        </div>
        <Divider paddingBlock={dividerPadding} />
        <div style={{ display: "flex", paddingInline: 3 }}>
          <div
            style={{
              width: "50%",
            }}
          >
            <span className="smallBoldText" style={{ opacity: 0.5 }}>
              {strings.getString("file_number")}
            </span>
            <p>{"#" + selectedLoad.opdfilenumber}</p>
          </div>
          <div style={{ width: "50%" }}>
            <span className="smallBoldText" style={{ opacity: 0.5 }}>
              {strings.getString("load_type")}
            </span>
            <p>
              {(selectedLoad.main_load_type__c ?? "-") +
                "/" +
                (selectedLoad.loadtype ?? "-")}
            </p>
          </div>
        </div>

        <Divider paddingBlock={dividerPadding} />
        <div
          style={{
            paddingInline: 3,
          }}
        >
          <p className="smallBoldText" style={{ opacity: 0.5 }}>
            {strings.getString("route")}
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p className="biggerText">
              {selectedLoad.fromcountry ? selectedLoad.fromcountry : "-"}
            </p>
            <Icon
              icon="right-bold"
              color={color.textSecondary}
              size={13}
              style={{ marginInline: 5 }}
            />
            <p className="biggerText">
              {selectedLoad.tocountry ? selectedLoad.tocountry : "-"}
            </p>
          </div>
        </div>
        <Divider paddingBlock={dividerPadding} />
        <div style={{ display: "flex", paddingInline: 3 }}>
          <div>
            <p className="boldText" style={{ color: color.blackFifty }}>
              {strings.getString("date")}
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingBlockStart: 8,
              }}
            >
              <p>
                {selectedLoad.loadbegindate
                  ? moment(selectedLoad.loadbegindate).format("l")
                  : "-"}
              </p>
              <Icon icon="right-bold" size={13} style={{ marginInline: 3 }} />
              <p>
                {selectedLoad.loadenddate
                  ? moment(selectedLoad.loadenddate).format("l")
                  : "-"}
              </p>
            </div>
          </div>
        </div>
        <Divider paddingBlock={dividerPadding} />
        <div
          style={{
            paddingInline: 3,
            display: "flex",
            alignItems: "center",
          }}
        >
          <div>
            <span className="smallBoldText" style={{ opacity: 0.5 }}>
              {strings.getString("estimated_amount")}
            </span>
            <p>
              {selectedLoad.currencyisocode
                ? getCurrency(
                    selectedLoad.estimatedamount,
                    selectedLoad.currencyisocode
                  )
                : selectedLoad.estimatedamount}
            </p>
          </div>
          {!disabledCheck(selectedLoad) &&
            selectedLoad.estimatedamount &&
            selectedLoad.firstcomegets && (
              <div
                className="offerButton smallText"
                style={{ margin: "5px 10px" }}
                onClick={() => {
                  if (!disabledCheck(selectedLoad)) {
                    openBooking(selectedLoad, true, false);
                  }
                }}
              >
                {strings.getString("make_offer")}
              </div>
            )}
        </div>

        <Divider paddingBlock={dividerPadding} />
        <div style={{ display: "flex", paddingInline: 3 }}>
          <div>
            <Icon
              icon="truck"
              size={14}
              style={{ marginInlineEnd: 5, opacity: 0.5 }}
            />
            <p style={{ marginInlineEnd: 3 }}>
              {selectedLoad.trucktype} (
              {selectedLoad?.vehiclenumber && selectedLoad?.vehiclenumber})
              {selectedLoad.loadTrucks &&
                selectedLoad.loadTrucks?.length > 1 &&
                strings.formatString(
                  strings.getString("more"),
                  selectedLoad.loadTrucks.length - 1
                )}
            </p>
          </div>
        </div>
        <Divider paddingBlock={dividerPadding} />
        <div className="iconGroup">
          <div className="iconGroupItem">
            <Icon icon="tonne" size={12} />
            <div style={{ textAlign: "center" }}>
              {selectedLoad.weight + " t"}
            </div>
          </div>
          <div className="iconGroupItem">
            <Icon icon="ruler" size={14} />
            {selectedLoad.cubicmeters ? selectedLoad.cubicmeters + " m³" : "-"}
          </div>
          <div className="iconGroupItem">
            <Icon icon="distance" size={17} />
            <span>{(selectedLoad.mindistance?.toFixed(0) ?? 0) + " km"}</span>
          </div>
          <div className="iconGroupItem">
            <Icon icon="box-stacked" size={15} />
            <span>
              {true ? strings.getString("pallets") : strings.getString("box")}
            </span>
          </div>
        </div>

        <Divider paddingBlock={dividerPadding} />
        <div
          style={{ marginBlockEnd: 10, width: "100%" }}
          className={
            disabledCheck(selectedLoad)
              ? "mobilCardButton actionButtonDisabled"
              : "mobilCardButton actionButton "
          }
          onClick={() => {
            if (!disabledCheck(selectedLoad)) {
              openBooking(
                selectedLoad,
                selectedLoad.firstcomegets ? selectedLoad.firstcomegets : false, //true selectedLoad.first.buy now
                selectedLoad.firstcomegets ? true : false
              );
            }
          }}
        >
          {disabledCheck(selectedLoad) ? (
            <p style={{ textAlign: "center" }}>
              {strings.getString("offer_has_been")}
            </p>
          ) : selectedLoad.firstcomegets ? (
            <p style={{ textAlign: "center" }}>
              {strings.getString("accept_now")}
            </p>
          ) : (
            <p style={{ textAlign: "center" }}>
              {strings.getString("make_offer")}
            </p>
          )}
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: 44,
            width: "100%",
            border: "1px solid",
            borderColor: color.borderSecondary,
            borderRadius: 5,
            cursor: "pointer",
            backgroundColor: color.accent,
          }}
        >
          <Whisper
            delay={500}
            placement="top"
            trigger="hover"
            speaker={
              <Popover>
                <p>{strings.getString("details")}</p>
              </Popover>
            }
            enterable
          >
            <div
              className="extraInfosButton"
              style={{
                borderColor: color.borderSecondary,
                borderStartStartRadius: 5,
                borderEndStartRadius: 5,
                width: "33%",
              }}
              onClick={() => {
                if (details === selectedLoad.id) {
                  setDetails("");
                } else {
                  setLoader(true);
                  setDetails(selectedLoad.id);
                  dispatch(
                    getLoadOpportunityDetailWithoutLoader({
                      payload: {
                        onSuccess: (message, payload) => {
                          payload && setSelectedLoad(payload);
                          return setLoader(false);
                        },
                        onError: (message) => {
                          setLoader(false);
                        },
                      },
                      url: `LoadOpportunity/GetByLoadOpportunities?sfid=${selectedLoad.id}`,
                    })
                  );
                }
              }}
            >
              <Icon icon="more" />
            </div>
          </Whisper>
          <Whisper
            delay={500}
            placement="top"
            trigger="hover"
            speaker={
              <Popover>
                <p>{strings.getString("print")}</p>
              </Popover>
            }
            enterable
          >
            <div
              className="extraInfosButton"
              style={{
                borderColor: color.borderSecondary,
                width: "33%",
              }}
              onClick={() => handlePrint(printerRef)}
            >
              <Icon icon="printer" color={color.blackFifty} />
            </div>
          </Whisper>

          <Whisper
            delay={500}
            placement="top"
            trigger="hover"
            speaker={
              <Popover>
                <p>{strings.getString("chat")}</p>
              </Popover>
            }
            enterable
          >
            <div
              className="extraInfosButton"
              style={{ border: "none", width: "33%" }}
              onClick={() => openChat()}
            >
              <Icon icon="chat" />
            </div>
          </Whisper>
        </div>
      </div>
      {details === selectedLoad.id && <Divider paddingBlock={"6px 0px"} />}

      <div
        className="extraDetails  mobil-extraDetailsContent"
        style={{
          width: 223,
          paddingInline: 0,
          display: details === selectedLoad.id ? "block" : "none",
          height: details === selectedLoad.id ? 320 : 0,
        }}
      >
        <div
          className="w-100"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {loader && <Loader />}
        </div>
        {!loader && (
          <Nav
            justified
            content={navContent}
            className="mobilenavbarContentMenu"
            appearance={"default"}
          >
            {(activeTab) => {
              return (
                <div
                  className="extraDetailsContent"
                  style={{
                    border: 0,
                    height: "100%",

                    maxHeight: 300,
                    paddingInlineStart: activeTab !== 4 ? 12 : 0,
                  }}
                >
                  <ScrollBar>
                    {activeTab !== navContent.length - 1 && activeTab !== 4 && (
                      <div className="extraDetailsFirstTab">
                        <div
                          className="extraDetailsFirstTabFirstCol"
                          style={{ width: "100%" }}
                        >
                          {(activeTab === 0 || activeTab === undefined) && (
                            <>
                              <div className="" style={{ marginBlockEnd: 10 }}>
                                <div>
                                  <p
                                    className="boldText"
                                    style={{ color: color.textSecondary }}
                                  >
                                    {strings.getString("loading_area")}
                                  </p>
                                  <ul className="areasList smallRegularText">
                                    {selectedLoad.DetailTab &&
                                      selectedLoad.DetailTab.ll1name && (
                                        <li>
                                          {selectedLoad.DetailTab.ll1name}
                                        </li>
                                      )}
                                    {selectedLoad.DetailTab &&
                                      selectedLoad.DetailTab.ll2name && (
                                        <li>
                                          {selectedLoad.DetailTab.ll2name}
                                        </li>
                                      )}
                                    {selectedLoad.DetailTab &&
                                      selectedLoad.DetailTab.ll3name && (
                                        <li>
                                          {selectedLoad.DetailTab.ll3name}
                                        </li>
                                      )}
                                    {selectedLoad.DetailTab &&
                                      selectedLoad.DetailTab.ll4name && (
                                        <li>
                                          {selectedLoad.DetailTab.ll4name}
                                        </li>
                                      )}
                                  </ul>
                                </div>
                                <div>
                                  <p
                                    className="boldText"
                                    style={{ color: color.textSecondary }}
                                  >
                                    {strings.getString("unloading_area")}
                                  </p>
                                  <ul className="areasList smallRegularText">
                                    {selectedLoad.DetailTab &&
                                      selectedLoad.DetailTab.ul1name && (
                                        <li>
                                          {selectedLoad.DetailTab.ul1name}
                                        </li>
                                      )}
                                    {selectedLoad.DetailTab &&
                                      selectedLoad.DetailTab.ul2name && (
                                        <li>
                                          {selectedLoad.DetailTab.ul2name}
                                        </li>
                                      )}
                                    {selectedLoad.DetailTab &&
                                      selectedLoad.DetailTab.ul3name && (
                                        <li>
                                          {selectedLoad.DetailTab.ul3name}
                                        </li>
                                      )}
                                    {selectedLoad.DetailTab &&
                                      selectedLoad.DetailTab.ul4name && (
                                        <li>
                                          {selectedLoad.DetailTab.ul4name}
                                        </li>
                                      )}
                                  </ul>
                                </div>
                              </div>
                              <div style={{ marginBlockEnd: 10 }}>
                                <div>
                                  <p
                                    className="boldText"
                                    style={{ color: color.textSecondary }}
                                  >
                                    {strings.getString("customs")}
                                  </p>
                                  {selectedLoad.DetailTab &&
                                  selectedLoad.DetailTab.fromcustoms ? (
                                    <span className="smallRegularText">
                                      {selectedLoad.DetailTab &&
                                        selectedLoad.DetailTab.fromcustoms}
                                    </span>
                                  ) : (
                                    <span className="smallRegularText">-</span>
                                  )}
                                </div>
                                <div>
                                  <p
                                    className="boldText"
                                    style={{ color: color.textSecondary }}
                                  >
                                    {strings.getString("customs")}
                                  </p>
                                  {selectedLoad.DetailTab &&
                                  selectedLoad.DetailTab.tocustoms ? (
                                    <span className="smallRegularText">
                                      {selectedLoad.DetailTab &&
                                        selectedLoad.DetailTab.tocustoms}
                                    </span>
                                  ) : (
                                    <span className="smallRegularText">-</span>
                                  )}
                                </div>
                              </div>
                              <div style={{ marginBlockEnd: 10 }}>
                                <div>
                                  <p
                                    className="boldText"
                                    style={{ color: color.textSecondary }}
                                  >
                                    {strings.getString("loading_type")}
                                  </p>
                                  <span className="smallRegularText">
                                    {selectedLoad.DetailTab &&
                                    selectedLoad.DetailTab.loadintypes
                                      ? selectedLoad.DetailTab.loadintypes.replaceAll(
                                          ";",
                                          ","
                                        )
                                      : "-"}
                                  </span>
                                </div>
                                <div>
                                  <p
                                    className="boldText"
                                    style={{ color: color.textSecondary }}
                                  >
                                    {strings.getString("unloading_type")}
                                  </p>
                                  <span className="smallRegularText">
                                    {selectedLoad.DetailTab &&
                                    selectedLoad.DetailTab.unloadingtypes
                                      ? selectedLoad.DetailTab.unloadingtypes.replaceAll(
                                          ";",
                                          ","
                                        )
                                      : "-"}
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                          {activeTab === 1 && (
                            <>
                              <div
                                style={{ width: "100%", marginBlockEnd: 10 }}
                              >
                                <p
                                  className="boldText"
                                  style={{ color: color.textSecondary }}
                                >
                                  {strings.getString("desired_arrival_date")}
                                </p>
                                <span className="smallRegularText">
                                  {selectedLoad.loadenddate
                                    ? moment(selectedLoad.loadenddate).format(
                                        "L"
                                      )
                                    : ""}
                                </span>
                              </div>
                              <div
                                style={{ width: "100%", marginBlockEnd: 10 }}
                              >
                                <p
                                  className="boldText"
                                  style={{ color: color.textSecondary }}
                                >
                                  {strings.getString("payment_conditions")}
                                </p>
                                <span className="smallRegularText">
                                  {selectedLoad.ExtraTab &&
                                  selectedLoad.ExtraTab
                                    .advancepaymentafterloading__c ? (
                                    <>
                                      <span>
                                        {strings.formatString(
                                          strings.getString(
                                            "paymentconditionfirstsentence"
                                          ),
                                          selectedLoad.ExtraTab.percent__c,
                                          100 - selectedLoad.ExtraTab.percent__c
                                        )}
                                      </span>
                                      {selectedLoad.ExtraTab
                                        .dayafterloading__c && (
                                        <p>
                                          {strings.formatString(
                                            strings.getString(
                                              "afterloadingday"
                                            ),
                                            selectedLoad.ExtraTab.day__c
                                          )}
                                        </p>
                                      )}

                                      {selectedLoad.ExtraTab
                                        .dayafterunloading__c && (
                                        <p>
                                          {strings.formatString(
                                            strings.getString(
                                              "afterunloadingday"
                                            ),
                                            selectedLoad.ExtraTab
                                              .unloadingday__c
                                          )}
                                        </p>
                                      )}

                                      {selectedLoad.ExtraTab
                                        .afterinvoicedate__c && (
                                        <p>
                                          {strings.formatString(
                                            strings.getString("afterinvoice"),
                                            selectedLoad.ExtraTab
                                              .afterinvoicedateday__c
                                          )}
                                        </p>
                                      )}

                                      {selectedLoad.ExtraTab
                                        .aftercopycmrinvoice__c && (
                                        <p>
                                          {strings.formatString(
                                            strings.getString("aftercopycmr"),
                                            selectedLoad.ExtraTab
                                              .aftercopycmrinvoiceday__c
                                          )}
                                        </p>
                                      )}
                                      {selectedLoad.ExtraTab
                                        .afteroriginalcmr__c && (
                                        <p>
                                          {strings.formatString(
                                            strings.getString(
                                              "afteroriginalcmr"
                                            ),
                                            selectedLoad.ExtraTab.cmrday__c
                                          )}
                                        </p>
                                      )}
                                    </>
                                  ) : (
                                    <span>{strings.getString("Cash")}</span>
                                  )}
                                </span>
                              </div>
                              <div
                                style={{ width: "100%", marginBlockEnd: 10 }}
                              >
                                <p
                                  className="boldText"
                                  style={{ color: color.textSecondary }}
                                >
                                  {strings.getString("hsc") +
                                    " / " +
                                    strings.getString("commodity_price")}
                                </p>
                                {selectedLoad?.ExtraTab?.hsc_code__c ? (
                                  <span className="smallRegularText">
                                    {selectedLoad.ExtraTab.hsc_code__c}
                                  </span>
                                ) : (
                                  <span className="smallRegularText">-</span>
                                )}
                                {selectedLoad?.ExtraTab?.productprice__c ? (
                                  <span className="smallRegularText">
                                    /
                                    {getCurrency(
                                      selectedLoad.ExtraTab.productprice__c,
                                      selectedLoad.currencyisocode
                                    )}
                                  </span>
                                ) : (
                                  <span className="smallRegularText">/-</span>
                                )}
                              </div>
                              <div
                                style={{ width: "100%", marginBlockEnd: 10 }}
                              >
                                <p
                                  className="boldText"
                                  style={{ color: color.textSecondary }}
                                >
                                  {strings.getString("desired_equipment")}
                                </p>
                                <span className="smallRegularText">
                                  {selectedLoad.loadTrucks?.map((type, i) => (
                                    <p key={i}>{`${type.equipments ?? ""}`}</p>
                                  ))}
                                </span>
                              </div>
                            </>
                          )}
                          {activeTab === 2 && (
                            <div style={{ width: "100%" }}>
                              <p
                                className="boldText"
                                style={{ color: color.textSecondary }}
                              >
                                {strings.getString("other_details")}
                              </p>
                              <div
                                className="smallRegularText"
                                style={{
                                  display: "flex",
                                }}
                              >
                                <p
                                  style={{
                                    marginInlineEnd: 10,
                                    color: color.textSecondary,
                                  }}
                                >
                                  {strings.getString("adr") + ": "}
                                </p>
                                <span>
                                  {selectedLoad?.OtherTab?.adr__c ? (
                                    <>
                                      {strings.getString("yes") +
                                        " - " +
                                        selectedLoad.OtherTab.adr__c}
                                    </>
                                  ) : (
                                    strings.getString("no")
                                  )}
                                </span>
                              </div>
                              <div
                                className="smallRegularText"
                                style={{
                                  display: "flex",
                                }}
                              >
                                <p
                                  style={{
                                    marginInlineEnd: 10,
                                    color: color.textSecondary,
                                  }}
                                >
                                  {strings.getString("frigo") + ": "}
                                </p>
                                <span>
                                  {selectedLoad?.OtherTab?.frigocelsius_ ? (
                                    <>
                                      {strings.getString("yes") +
                                        `- (${selectedLoad.OtherTab.frigocelsius_})`}
                                    </>
                                  ) : (
                                    strings.getString("no")
                                  )}
                                </span>
                              </div>
                              <div
                                className="smallRegularText"
                                style={{
                                  display: "flex",
                                }}
                              >
                                <p
                                  style={{
                                    marginInlineEnd: 10,
                                    color: color.textSecondary,
                                  }}
                                >
                                  {strings.getString("doctor_required") + ": "}
                                </p>
                                <span>
                                  {selectedLoad?.OtherTab?.isdoctorrequired
                                    ? strings.getString("yes")
                                    : strings.getString("no")}
                                </span>
                              </div>
                              <div
                                className="smallRegularText"
                                style={{
                                  display: "flex",
                                }}
                              >
                                <p
                                  style={{
                                    marginInlineEnd: 10,
                                    color: color.textSecondary,
                                  }}
                                >
                                  {strings.getString("countrycertificate") +
                                    ": "}
                                </p>
                                <span>
                                  {selectedLoad?.thirdcountrycertificate !==
                                  undefined
                                    ? selectedLoad.thirdcountryname
                                      ? strings.getString("yes")
                                      : strings.getString("no")
                                    : "-"}
                                </span>
                              </div>
                              <div
                                className="smallRegularText"
                                style={{
                                  display: "flex",
                                }}
                              >
                                <p
                                  style={{
                                    marginInlineEnd: 10,
                                    color: color.textSecondary,
                                  }}
                                >
                                  {strings.getString("countryname") + ": "}
                                </p>
                                <span>
                                  {selectedLoad?.thirdcountryname
                                    ? selectedLoad?.thirdcountryname
                                    : "-"}
                                </span>
                              </div>
                              <div
                                className="smallRegularText"
                                style={{
                                  display: "flex",
                                }}
                              >
                                <p
                                  style={{
                                    marginInlineEnd: 10,
                                    color: color.textSecondary,
                                  }}
                                >
                                  {strings.getString(
                                    "trucktransitdeclarationtype"
                                  ) + ": "}
                                </p>
                                <span>
                                  {selectedLoad?.trucktransitdeclarationtype
                                    ? selectedLoad?.trucktransitdeclarationtype
                                    : "-"}
                                </span>
                              </div>
                              <div
                                className="smallRegularText"
                                style={{
                                  display: "flex",
                                }}
                              >
                                <p
                                  style={{
                                    marginInlineEnd: 10,
                                    color: color.textSecondary,
                                  }}
                                >
                                  {strings.getString("seconddrivercheckbox") +
                                    ": "}
                                </p>
                                <span>
                                  {selectedLoad?.seconddrivercheckbox !==
                                  undefined
                                    ? selectedLoad?.seconddrivercheckbox
                                      ? strings.getString("yes")
                                      : strings.getString("no")
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          )}
                          {activeTab === 3 && (
                            <>
                              <p
                                className="boldText"
                                style={{ color: color.textSecondary }}
                              >
                                {strings.getString("available_routes")}
                              </p>
                              {selectedLoad.RouteTab?.map((route, i) => (
                                <div
                                  key={i}
                                  className="smallRegularText"
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  <p>{route.route}&nbsp; </p>
                                  <span>
                                    {getCurrency(
                                      route.targetprice,
                                      route.currencyisocode
                                    )}
                                  </span>
                                  <span>
                                    &nbsp;{route.distance?.toFixed(0) ?? 0} km
                                  </span>
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                      </div>
                    )}

                    {activeTab === 5 && (
                      <>
                        <div style={{ width: "100%", padding: "5px 0px" }}>
                          <p
                            className="boldText"
                            style={{ color: color.textSecondary }}
                          >
                            {strings.getString("language")}
                          </p>
                          <div
                            style={{
                              width: 100,
                              display: "flex",
                              justifyContent: "flex-start",
                              flexWrap: "wrap",
                              gap: "4",
                            }}
                          >
                            {selectedLoad?.Owner?.flags?.split(";").map((x) => {
                              return (
                                <Flag
                                  style={{ marginRight: 3 }}
                                  country={x === "EN" ? "US" : x}
                                  title={x}
                                  className="flag"
                                />
                              );
                            })}
                          </div>
                          <p
                            className="boldText"
                            style={{
                              color: color.textSecondary,
                              marginBlockEnd: 0,
                              marginBlockStart: 5,
                            }}
                          >
                            {strings.getString("contact_details")}
                          </p>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Icon
                              icon="mail"
                              size={20}
                              style={{ marginInlineEnd: 5 }}
                            />
                            <span
                              className="boldText"
                              style={{
                                textDecoration: "underline",
                                fontWeight: 600,
                              }}
                            >
                              {selectedLoad.Owner?.email}
                            </span>
                          </div>
                          <div style={{ display: "flex" }}>
                            <Icon
                              icon="mobile"
                              size={20}
                              style={{ marginInlineEnd: 5 }}
                            />
                            <span
                              className="boldText"
                              style={{ fontWeight: 600 }}
                            >
                              {selectedLoad.Owner?.mobilephone}
                            </span>
                          </div>
                          <div style={{ display: "flex" }}>
                            <Icon
                              icon="phone"
                              color={color.black}
                              size={15}
                              style={{
                                marginInlineEnd: 7,
                                marginInlineStart: 3,
                              }}
                            />
                            <span
                              className="boldText"
                              style={{ fontWeight: 600 }}
                            >
                              {selectedLoad.Owner?.phone}
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                    {activeTab === 4 && (
                      <GoogleMap
                        id={`rapidMap-${selectedLoad.id}`}
                        options={{
                          //@ts-ignore
                          styles: CUSTOM_MAP,
                          disableDefaultUI: true,
                          zoomControl: true,
                          scrollwheel: false,
                        }}
                        onLoad={(map: google.maps.Map<Element>) => {
                          setRouteDirections([]);
                          setPathDistance("");

                          if (selectedLoad.RouteTab) {
                            clickableDirectionServiceResult(
                              selectedLoad.RouteTab[0]
                            );
                          } else {
                            getDirectionsServiceResult();
                          }
                        }}
                        mapContainerStyle={mapContainerStyles}
                      >
                        {route_directions &&
                          route_directions.map((rd, i) => (
                            <DirectionsRenderer
                              key={`route-map-${i}`}
                              directions={rd}
                              onLoad={(
                                directionsRenderer: google.maps.DirectionsRenderer
                              ) => {
                                console.log(
                                  "directionsRenderer",
                                  directionsRenderer
                                );
                              }}
                              options={{
                                polylineOptions: {
                                  strokeColor: decideColor(path_distance_index),
                                },
                              }}
                            />
                          ))}
                        {selectedLoad.RouteTab && (
                          <div
                            style={{
                              position: "absolute",
                              left: 10,
                              top: 5,
                            }}
                          >
                            {selectedLoad.RouteTab.map((data, i) => {
                              return (
                                <div
                                  style={{
                                    position: "relative",
                                    backgroundColor: decideColor(i),
                                    padding: 5,
                                    marginTop: 5,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    clickableDirectionServiceResult(data);
                                    setPathDistanceIndex(i);
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {data.route}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        )}

                        {path_distance !== "" && (
                          <div
                            style={{
                              position: "absolute",
                              right: 10,
                              top: 5,
                              padding: 5,
                              backgroundColor: "#ededed",
                              borderRadius: 10,
                            }}
                          >
                            <span
                              style={{ color: "black", fontWeight: "bold" }}
                            >
                              {path_distance}
                            </span>
                          </div>
                        )}
                      </GoogleMap>
                    )}
                  </ScrollBar>
                </div>
              );
            }}
          </Nav>
        )}
      </div>
    </div>
  );
};

export default LoadCardMobil;
