import { createAction } from "redux-smart-actions";

export const showLoader = createAction("SHOW_LOADER");
export const hideLoader = createAction("HIDE_LOADER");
export const setAuthenticate = createAction(
  "SET_AUTHENTICATE",
  (payload: "truck" | "client" | boolean) => ({ payload })
);
export const scrollToPosition = createAction(
  "SCROLL_TO_POSITION",
  (payload?: number) => ({ payload })
);
export const setScrollPosition = createAction(
  "SET_SCROLL_POSITION",
  (payload?: number) => ({ payload })
);
