import { forwardRef, MutableRefObject, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader, Popover, Whisper } from "rsuite";
import { Icon, Nav } from "..";
import { getLocalizedTruckCard } from "../../redux/selectors";
import { DirectionsRenderer, GoogleMap } from "@react-google-maps/api";
import { color, CUSTOM_MAP, mapContainerStyles } from "../../utils/Properties";
import moment from "moment";
import "./style.scss";
import { VehicleOpportunityDto } from "../../redux/opportunity/type";
import Flag from "react-flagkit";
import { getCurrency } from "../../utils/Helpers";
import ScrollBar from "react-perfect-scrollbar";
import { getVehicleDetailWithoutLoader } from "../../redux/actions";
import { RouteObject } from "../../types";

type TruckCardProps = {
  truck: VehicleOpportunityDto;
  selected: boolean;
  setSelected: (truckId: string) => void;
  details: string;
  setDetails: (truckId: string) => void;
  favList: string[];
  setFavList: (favList: string[]) => void;
  openChat: () => void;
  openBooking: (truck: VehicleOpportunityDto, accept: boolean) => void;
  handlePrint: (ref: MutableRefObject<null>) => void;
};

const TruckCard = forwardRef<HTMLDivElement, TruckCardProps>(
  (
    {
      truck,
      selected,
      setSelected,
      details,
      setDetails,
      favList,
      setFavList,
      openChat,
      openBooking,
      handlePrint,
    },
    ref
  ) => {
    const [route_directions, setRouteDirections] = useState<
      google.maps.DirectionsResult[]
    >([]);
    const [selectedTruck, setSelectedTruck] = useState(truck);
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const strings = useSelector(getLocalizedTruckCard);
    const cardRef = useRef(null);
    function decideColor(i: number) {
      switch (i) {
        case 0:
          return "blue";
        case 1:
          return "orange";
        case 2:
          return "yellow";
        case 3:
          return "black";
        case 4:
          return "pink";
        case 5:
          return "gray";

        default:
          return "red";
      }
    }
    const navContent = [
      {
        id: 0,
        icon: "list",
        activeColor: color.primary,
        label: strings.getString("details"),
      },
      {
        id: 1,
        icon: "indicator",
        activeColor: color.primary,
        label: strings.getString("extra_infos"),
      },
      {
        id: 2,
        icon: "shorter-list",
        activeColor: color.primary,
        label: strings.getString("other_details"),
      },
      {
        id: 3,
        icon: "route",
        activeColor: color.primary,
        label: strings.getString("routes"),
      },
      {
        id: 4,
        icon: "map-pin",
        activeColor: color.primary,
        label: strings.getString("map"),
      },
    ];
    function clickableDirectionServiceResult(data: RouteObject) {
      let waypoints: google.maps.DirectionsWaypoint[] = [];
      if (data.koordinat) {
        JSON.parse(data.koordinat)?.forEach((data: any) => {
          waypoints.push({
            location: new google.maps.LatLng(data.Latitude, data.Longtitude),
            stopover: true,
          });
        });
      }
      setRouteDirections([]);
      getDirectionsServiceResult(waypoints);
    }

    const [path_distance, setPathDistance] = useState<string>("");
    const [path_distance_index, setPathDistanceIndex] = useState<number>(0);
    const getDirectionsServiceResult = (
      waypoints?: google.maps.DirectionsWaypoint[]
    ) => {
      if (selectedTruck.loadpoint && selectedTruck.unloadpoint) {
        const DirectionsService = new google.maps.DirectionsService();
        console.log("WAYPOINTS", waypoints);
        DirectionsService.route(
          {
            origin: selectedTruck.loadpoint,
            destination: selectedTruck.unloadpoint,
            waypoints,
            travelMode: google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            console.log("ROUTE RESULT", result);
            if (status === google.maps.DirectionsStatus.OK) {
              setRouteDirections((rds) => [...rds, result]);
              console.log("LEGS", result.routes);
              let total_distance = 0;
              if ((result?.routes[0]?.legs?.length ?? 0) > 0) {
                result.routes[0].legs.forEach((l) => {
                  total_distance += l.distance.value;
                });
              }
              if (total_distance > 0) {
                setPathDistance(Math.round(total_distance / 1000) + "km");
              } else setPathDistance("");
            } else {
              console.error(`error fetching directions ${result}`);
            }
          }
        );
      }
    };
    const postEndCalculater = selectedTruck.lastofferdate
      ? new Date(selectedTruck.lastofferdate).getTime() - new Date().getTime()
      : new Date().getTime() - new Date().getTime();

    return (
      <div key={selectedTruck.id} ref={cardRef}>
        <div
          ref={ref}
          className="truckResultCard"
          style={{
            fontSize: 10,
            color: color.textPrimary + color.thirtyOpacity,
            borderColor: selected ? color.side3 : "#e6e6e6",
            backgroundColor: color.pageBgColor,
          }}
        >
          <div style={{ width: "100%", display: "flex" }}>
            <div
              style={{
                marginInlineStart: 10,
                width: `calc(100% - 6px)`,
                paddingBlock: "12px 2px",
                paddingInline: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                className="vehicleLogo"
                style={{
                  backgroundColor: color.accent,
                  borderRadius: 10,
                }}
              >
                <img
                  className="imageStyles card-object-img"
                  style={{ borderRadius: 10 }}
                  src={selectedTruck.profileimage}
                  alt=""
                />
              </div>
              <div className="truckResultCardMainDetails smallRegularText">
                <div>
                  <p
                    className="boldText card-object"
                    style={{ color: color.blackFifty }}
                  >
                    {strings.getString("file_number")}
                  </p>
                  <p className="card-object-value">
                    {"#" + selectedTruck.filenumber}
                  </p>
                </div>
                <div>
                  <p
                    className="boldText card-object"
                    style={{ color: color.blackFifty }}
                  >
                    {strings.getString("truck_type")}
                  </p>
                  <p className="card-object-value">
                    {selectedTruck.trucktypename}
                  </p>
                </div>
                <div>
                  <p
                    className="boldText card-object"
                    style={{ color: color.blackFifty }}
                  >
                    {strings.getString("destination")}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingBlockStart: 8,
                    }}
                  >
                    <p className="card-object-value">
                      {selectedTruck.loadcountry
                        ? selectedTruck.loadcountry
                        : "-"}
                    </p>
                    <Icon
                      icon="right-bold"
                      size={13}
                      style={{ marginInline: 3 }}
                    />
                    <p className="card-object-value">
                      {selectedTruck.tocountry ? selectedTruck.tocountry : "-"}
                    </p>
                  </div>
                </div>
                <div>
                  <p
                    className="boldText card-object"
                    style={{ color: color.blackFifty }}
                  >
                    {strings.getString("date")}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingBlockStart: 8,
                    }}
                  >
                    <p className="card-object-value">
                      {selectedTruck.firstavailabilitydate
                        ? moment(selectedTruck.firstavailabilitydate).format(
                            "l"
                          )
                        : "-"}
                    </p>
                    <Icon
                      icon="right-bold"
                      size={13}
                      style={{ marginInline: 3 }}
                    />
                    <br />
                    <p className="card-object-value">
                      {selectedTruck.lastavailabilitydate
                        ? moment(selectedTruck.lastavailabilitydate).format("l")
                        : "-"}
                    </p>
                  </div>
                </div>
                <div>
                  <p
                    className="boldText card-object"
                    style={{ color: color.blackFifty }}
                  >
                    {strings.getString("estimated_amount")}
                  </p>
                  <p className="card-object-value">
                    {selectedTruck.currencyisocode
                      ? getCurrency(
                          selectedTruck.estimatedamount,
                          selectedTruck.currencyisocode
                        )
                      : selectedTruck.estimatedamount}
                  </p>
                  {selectedTruck.estimatedamount && (
                    <div
                      className={
                        selectedTruck.offerHasBeen
                          ? "offerButtonDisabled smallText"
                          : "offerButton smallText"
                      }
                      style={{ marginBlock: "5px -30px" }}
                      onClick={() => {
                        !selectedTruck.offerHasBeen &&
                          openBooking(selectedTruck, false);
                      }}
                    >
                      {strings.getString("make_offer")}
                    </div>
                  )}
                </div>
              </div>
              <div
                className={
                  selectedTruck.offerHasBeen
                    ? "actionButtonDisabled card-offer-button"
                    : "actionButton card-offer-button"
                }
                onClick={() => {
                  if (!selectedTruck.offerHasBeen) {
                    !(
                      selectedTruck.shipperofferprice &&
                      selectedTruck.shipperofferprice > 0
                    ) && selectedTruck.estimatedamount
                      ? openBooking(selectedTruck, true)
                      : openBooking(selectedTruck, false);
                  }
                }}
                style={{
                  cursor: !(
                    selectedTruck.shipperofferprice &&
                    selectedTruck.shipperofferprice > 0
                  )
                    ? "pointer"
                    : "no-drop",
                }}
              >
                {!selectedTruck.offerHasBeen ? (
                  selectedTruck.estimatedamount ? (
                    <p style={{ textAlign: "center" }}>
                      {strings.getString("accept_now")}
                    </p>
                  ) : (
                    <p style={{ textAlign: "center" }}>
                      {strings.getString("make_offer")}
                    </p>
                  )
                ) : (
                  <p style={{ textAlign: "center" }}>
                    {strings.getString("offersending")}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              marginInlineStart: 8,
              marginBlockEnd: 5,
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* <div
              style={{
                width: 18,
                height: 18,
                borderRadius: 18,
                backgroundColor:
                  selectedTruck.available === true
                    ? color.available
                    : selectedTruck.available === false
                    ? color.notAvailable
                    : color.lessAvailable,
                marginInlineEnd: 20,
              }}
            /> */}
            <div
              style={{
                marginInlineStart: 10,
                width: `calc(100% - 38px)`,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <p
                  className="boldText"
                  style={{ fontSize: 14, color: color.textPrimary }}
                >
                  {selectedTruck.rapidadvisorname}
                </p>
                <span className="smallText" style={{ color: color.side }}>
                  {selectedTruck.publishDate
                    ? moment(selectedTruck.publishDate).format("l")
                    : ""}
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    textAlign: "center",
                    marginInlineEnd: 30,
                  }}
                >
                  <p
                    className="smallBoldText"
                    style={{ color: color.blackFifty }}
                  >
                    {strings.getString("post_end_date")}
                  </p>
                  <span
                    className="thinText"
                    style={
                      postEndCalculater < 432000000 && postEndCalculater > 0
                        ? { color: color.error }
                        : {}
                    }
                  >
                    {selectedTruck.lastofferdate
                      ? moment(selectedTruck.lastofferdate).format("l")
                      : ""}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className="extraInfos"
            style={
              details === selectedTruck.destination_vehicletype_vehicleopp__c
                ? {
                    color: color.textPrimary + color.fiftyOpacity,
                    borderColor: color.black + "16",
                    borderBlockEnd: "1px solid #18181816",
                    backgroundColor: "#f5f5f5",
                  }
                : {
                    color: color.textPrimary + color.fiftyOpacity,
                    borderColor: color.black + "16",
                    borderEndEndRadius: 5,
                    borderEndStartRadius: 5,
                  }
            }
          >
            <div
              className="standardText"
              style={{
                display: "flex",
                alignItems: "center",
                marginInlineEnd: 50,
              }}
            >
              <Icon icon="clock" size={17} style={{ marginInlineEnd: 5 }} />
              <p style={{ marginInlineEnd: 5, fontWeight: 700 }}>
                {strings.getString("estimated_arrival")}
              </p>
              <span style={{ marginInlineEnd: 30 }}>
                {selectedTruck.estimatedarrivaltime
                  ? Math.round(selectedTruck.estimatedarrivaltime)
                  : "-"}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: 44,
                width: "max-content",
                border: "1px solid",
                borderColor: color.borderSecondary,
                borderRadius: 5,
                cursor: "pointer",
                backgroundColor: color.accent,
              }}
            >
              <Whisper
                delay={500}
                placement="top"
                trigger="hover"
                speaker={
                  <Popover>
                    <p>{strings.getString("details")}</p>
                  </Popover>
                }
                enterable
              >
                <div
                  className="extraInfosButton"
                  style={{
                    borderColor: color.borderSecondary,
                    backgroundColor:
                      details ===
                      selectedTruck.destination_vehicletype_vehicleopp__c
                        ? "#74747416"
                        : "transparent",
                    borderStartStartRadius: 5,
                    borderEndStartRadius: 5,
                  }}
                  onClick={() => {
                    if (
                      details ===
                      selectedTruck.destination_vehicletype_vehicleopp__c
                    ) {
                      setDetails("");
                    } else {
                      setLoader(true);
                      setDetails(
                        selectedTruck.destination_vehicletype_vehicleopp__c
                      );
                      dispatch(
                        getVehicleDetailWithoutLoader({
                          payload: {
                            onSuccess: (message, payload) => {
                              payload && setSelectedTruck(payload);
                              return setLoader(false);
                            },
                            onError: (message) => {
                              setLoader(false);
                            },
                          },
                          url: `VehicleOpportunity/GetByVehicleOpportunities?sfid=${selectedTruck.destination_vehicletype_vehicleopp__c}`,
                        })
                      );
                    }
                  }}
                >
                  <Icon icon="more" />
                </div>
              </Whisper>
              <Whisper
                delay={500}
                placement="top"
                trigger="hover"
                speaker={
                  <Popover>
                    <p>{strings.getString("print")}</p>
                  </Popover>
                }
                enterable
              >
                <div
                  className="extraInfosButton"
                  style={{
                    borderColor: color.borderSecondary,
                  }}
                  onClick={async () => {
                    setDetails(
                      selectedTruck.destination_vehicletype_vehicleopp__c
                    );
                    handlePrint(cardRef);
                  }}
                >
                  <Icon icon="printer" color={color.blackFifty} />
                </div>
              </Whisper>

              <Whisper
                delay={500}
                placement="top"
                trigger="hover"
                speaker={
                  <Popover>
                    <p>{strings.getString("chat")}</p>
                  </Popover>
                }
                enterable
              >
                <div
                  className="extraInfosButton"
                  style={{ border: "none" }}
                  onClick={openChat}
                >
                  <Icon icon="chat" />
                </div>
              </Whisper>
            </div>
          </div>
          <div
            className="extraDetails"
            style={{
              height:
                details === selectedTruck.destination_vehicletype_vehicleopp__c
                  ? 293
                  : 0,
            }}
          >
            <div
              className="w-100"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {loader && <Loader />}
            </div>
            {!loader && (
              <Nav content={navContent}>
                {(activeTab) => {
                  return (
                    <div
                      className="extraDetailsContent"
                      style={{
                        paddingInline: activeTab !== 4 ? 32 : 0,
                      }}
                    >
                      <ScrollBar>
                        {activeTab !== 4 && (
                          <div className="extraDetailsFirstTab">
                            <div className="extraDetailsFirstTabFirstCol">
                              {(activeTab === 0 || activeTab === undefined) && (
                                <>
                                  <div
                                    className="dividedRow"
                                    style={{ marginBlockEnd: 10 }}
                                  >
                                    <div style={{ width: "50%" }}>
                                      <p
                                        className="boldText"
                                        style={{ color: color.textSecondary }}
                                      >
                                        {strings.getString("loading_area")}
                                      </p>
                                      <ul className="areasList smallRegularText">
                                        {selectedTruck.loadpoint}
                                      </ul>
                                    </div>
                                    <div style={{ width: "50%" }}>
                                      <p
                                        className="boldText"
                                        style={{ color: color.textSecondary }}
                                      >
                                        {strings.getString("unloading_area")}
                                      </p>
                                      <ul className="areasList smallRegularText">
                                        {selectedTruck.unloadpoint}
                                      </ul>
                                    </div>
                                  </div>

                                  <div
                                    className="dividedRow"
                                    style={{ marginBlockEnd: 10 }}
                                  >
                                    <div style={{ width: "50%" }}>
                                      <p
                                        className="boldText"
                                        style={{ color: color.textSecondary }}
                                      >
                                        {strings.getString("loading_type")}
                                      </p>
                                      <span className="smallRegularText">
                                        {selectedTruck.loadingtype
                                          ? selectedTruck.loadingtype
                                          : "-"}
                                      </span>
                                    </div>
                                    <div style={{ width: "50%" }}>
                                      <p
                                        className="boldText"
                                        style={{ color: color.textSecondary }}
                                      >
                                        {strings.getString("unloading_type")}
                                      </p>
                                      <span className="smallRegularText">
                                        {selectedTruck.unloadingtype
                                          ? selectedTruck.unloadingtype
                                          : "-"}
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    className="dividedRow"
                                    style={{ marginBlockEnd: 10 }}
                                  >
                                    <div style={{ width: "50%" }}>
                                      <p
                                        className="boldText"
                                        style={{ color: color.textSecondary }}
                                      >
                                        {strings.getString("maxWeight")}
                                      </p>
                                      <span className="smallRegularText">
                                        {selectedTruck.weight
                                          ? selectedTruck.weight
                                          : "-"}
                                      </span>
                                    </div>
                                    <div style={{ width: "50%" }}>
                                      <p
                                        className="boldText"
                                        style={{ color: color.textSecondary }}
                                      >
                                        {strings.getString("volumemt")}
                                      </p>
                                      <span className="smallRegularText">
                                        {selectedTruck.volume
                                          ? selectedTruck.volume
                                          : "-"}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              )}
                              {activeTab === 1 && (
                                <>
                                  <div style={{ width: "100%" }}>
                                    <p
                                      className="boldText"
                                      style={{ color: color.textSecondary }}
                                    >
                                      {strings.getString("measurements")}
                                    </p>
                                    <div
                                      className="smallRegularText"
                                      style={{ display: "flex" }}
                                    >
                                      <div style={{ marginInlineEnd: 10 }}>
                                        <span className="smallRegularText">
                                          {strings.getString("width") + ": "}
                                        </span>
                                        <span>
                                          {selectedTruck.vehiclewidth
                                            ? selectedTruck.vehiclewidth + "m"
                                            : "-"}
                                        </span>
                                      </div>
                                      <div style={{ marginInlineEnd: 10 }}>
                                        <span className="smallRegularText">
                                          {strings.getString("height") + ": "}
                                        </span>
                                        <span>
                                          {selectedTruck.vehicleheight
                                            ? selectedTruck.vehicleheight + "m"
                                            : "-"}
                                        </span>
                                      </div>
                                      <div>
                                        <span className="smallRegularText">
                                          {strings.getString("length") + ": "}
                                        </span>
                                        <span>
                                          {selectedTruck.vehiclelength
                                            ? selectedTruck.vehiclelength + "m"
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "100%",
                                      marginBlockStart: 10,
                                    }}
                                  >
                                    <p
                                      className="boldText"
                                      style={{ color: color.textSecondary }}
                                    >
                                      {strings.getString("equipments")}:
                                    </p>{" "}
                                    <span className="smallRegularText">
                                      {selectedTruck.equipments}
                                    </span>
                                  </div>
                                </>
                              )}
                              {activeTab === 2 && (
                                <>
                                  <div
                                    style={{
                                      width: "100%",
                                      marginBlockStart: 10,
                                    }}
                                  >
                                    <p
                                      className="boldText"
                                      style={{ color: color.textSecondary }}
                                    >
                                      {strings.getString(
                                        "Does it Have Third Country Permission or Cemt"
                                      )}
                                    </p>{" "}
                                    <span className="smallRegularText">
                                      {selectedTruck.thirdcountrycertificate
                                        ? selectedTruck.thirdcountrycertificate ===
                                          true
                                          ? strings.getString("yes")
                                          : strings.getString("no")
                                        : "-"}
                                    </span>
                                  </div>
                                  <div style={{ width: "100%" }}>
                                    <p
                                      className="boldText"
                                      style={{ color: color.textSecondary }}
                                    >
                                      {strings.getString("Countries")}:
                                    </p>{" "}
                                    <span className="smallRegularText">
                                      {selectedTruck.thirdcountryname
                                        ? selectedTruck.thirdcountryname
                                        : "-"}
                                    </span>
                                  </div>
                                  <div style={{ width: "100%" }}>
                                    <p
                                      className="boldText"
                                      style={{ color: color.textSecondary }}
                                    >
                                      {strings.getString(
                                        "Transport Declaration Document Type"
                                      )}
                                      :{" "}
                                    </p>
                                    <span className="smallRegularText">
                                      {selectedTruck.trucktransitdeclarationtype
                                        ? selectedTruck.trucktransitdeclarationtype
                                        : "-"}
                                    </span>
                                  </div>{" "}
                                  <div style={{ width: "100%" }}>
                                    <p
                                      className="boldText"
                                      style={{ color: color.textSecondary }}
                                    >
                                      {strings.getString(
                                        "Can Provide Second Driver"
                                      )}{" "}
                                    </p>
                                    <span className="smallRegularText">
                                      {selectedTruck.seconddrivercheckbox
                                        ? selectedTruck.seconddrivercheckbox ===
                                          true
                                          ? strings.getString("yes")
                                          : strings.getString("no")
                                        : "-"}
                                    </span>
                                  </div>
                                </>
                              )}
                              {activeTab === 3 && (
                                <>
                                  <p
                                    className="boldText"
                                    style={{ color: color.textSecondary }}
                                  >
                                    {strings.getString("destination")}
                                  </p>
                                  {selectedTruck.RouteTab?.map((route, i) => (
                                    <div
                                      key={i}
                                      className="smallRegularText"
                                      style={{
                                        display: "flex",
                                      }}
                                    >
                                      <p>{route.route} - &nbsp; </p>
                                      <span>
                                        {Math.round(route.transittime) ?? 0}{" "}
                                        Days - &nbsp;
                                      </span>
                                      <span>
                                        {getCurrency(
                                          route.targetprice,
                                          route.currencyisocode
                                        )}
                                      </span>
                                    </div>
                                  ))}
                                </>
                              )}
                            </div>
                            <div
                              style={{
                                width: "5%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  width: 1,
                                  backgroundColor: color.borderColor,
                                }}
                              />
                            </div>
                            <div className="extraDetailsFirstTabSecondCol">
                              <p
                                className="boldText"
                                style={{ color: color.textSecondary }}
                              >
                                {strings.getString("language")}
                              </p>
                              <div
                                style={{
                                  width: 70,
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  flexWrap: "wrap",
                                  gap: "4",
                                }}
                              >
                                {selectedTruck?.Owner?.flags
                                  ?.split(";")
                                  .map((x) => {
                                    return (
                                      <Flag
                                        style={{ marginRight: 3 }}
                                        country={x === "EN" ? "US" : x}
                                        title={x}
                                        className="flag"
                                      />
                                    );
                                  })}
                              </div>
                              <p
                                className="boldText"
                                style={{
                                  color: color.textSecondary,
                                  marginBlockEnd: 0,
                                  marginBlockStart: 5,
                                }}
                              >
                                {strings.getString("contact_details")}
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Icon
                                  icon="mail"
                                  size={20}
                                  style={{ marginInlineEnd: 5 }}
                                />
                                <span
                                  className="boldText"
                                  style={{
                                    textDecoration: "underline",
                                    fontWeight: 600,
                                  }}
                                >
                                  {selectedTruck.Owner?.email}
                                </span>
                              </div>
                              <div style={{ display: "flex" }}>
                                <Icon
                                  icon="mobile"
                                  size={20}
                                  style={{ marginInlineEnd: 5 }}
                                />
                                <span
                                  className="boldText"
                                  style={{ fontWeight: 600 }}
                                >
                                  {selectedTruck.Owner?.mobilephone
                                    ? selectedTruck.Owner?.mobilephone
                                    : "-"}
                                </span>
                              </div>
                              <div style={{ display: "flex" }}>
                                <Icon
                                  icon="phone"
                                  color={color.black}
                                  size={15}
                                  style={{
                                    marginInlineEnd: 7,
                                    marginInlineStart: 3,
                                  }}
                                />
                                <span
                                  className="boldText"
                                  style={{ fontWeight: 600 }}
                                >
                                  {selectedTruck.Owner?.phone
                                    ? selectedTruck.Owner.phone
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                        {activeTab === 4 && selectedTruck.RouteTab && (
                          <GoogleMap
                            id={`rapidMap-${selectedTruck.id}`}
                            options={{
                              //@ts-ignore
                              styles: CUSTOM_MAP,
                              disableDefaultUI: true,
                              zoomControl: true,
                              scrollwheel: false,
                            }}
                            onLoad={(map: google.maps.Map<Element>) => {
                              setRouteDirections([]);
                              setPathDistance("");

                              if (
                                selectedTruck.RouteTab &&
                                selectedTruck.RouteTab[0].koordinat
                              ) {
                                clickableDirectionServiceResult(
                                  selectedTruck.RouteTab[0]
                                );
                              } else {
                                getDirectionsServiceResult();
                              }
                            }}
                            mapContainerStyle={mapContainerStyles}
                          >
                            {route_directions &&
                              route_directions.map((rd, i) => (
                                <DirectionsRenderer
                                  key={`route-map-${i}`}
                                  directions={rd}
                                  onLoad={(
                                    directionsRenderer: google.maps.DirectionsRenderer
                                  ) => {
                                    console.log(
                                      "directionsRenderer",
                                      directionsRenderer
                                    );
                                  }}
                                  options={{
                                    polylineOptions: {
                                      strokeColor:
                                        decideColor(path_distance_index),
                                    },
                                  }}
                                />
                              ))}
                            {selectedTruck.RouteTab && (
                              <div
                                style={{
                                  position: "absolute",
                                  left: 10,
                                  top: 5,
                                }}
                              >
                                {selectedTruck.RouteTab.map((data, i) => {
                                  return (
                                    <div
                                      style={{
                                        position: "relative",
                                        backgroundColor: decideColor(i),
                                        padding: 5,
                                        marginTop: 5,
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        clickableDirectionServiceResult(data);
                                        setPathDistanceIndex(i);
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "black",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {data.route}
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                            )}

                            {path_distance !== "" && (
                              <div
                                style={{
                                  position: "absolute",
                                  right: 10,
                                  top: 5,
                                  padding: 5,
                                  backgroundColor: "#ededed",
                                  borderRadius: 10,
                                }}
                              >
                                <span
                                  style={{ color: "black", fontWeight: "bold" }}
                                >
                                  {path_distance}
                                </span>
                              </div>
                            )}
                          </GoogleMap>
                        )}
                      </ScrollBar>
                    </div>
                  );
                }}
              </Nav>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default TruckCard;
