import TablePagination from "rsuite/lib/Table/TablePagination";
import "./style.css";

type PaginationProps = {
  data: any[];
  returnedData: (data?: any[]) => void;
  totalCount?: number;
  take?: number;
  page?: number;
  setTake?: (t: number) => void;
  setPage?: (t: number) => void;
  itemListArray?: any;
};
const Pagination = ({
  data,
  setTake,
  totalCount,
  take = 10,
  page = 1,
  setPage,
  itemListArray = [{ value: 10, label: 10 }],
}: PaginationProps) => {
  return (
    <TablePagination
      className="customPagination"
      activePage={page}
      lengthMenu={itemListArray}
      displayLength={take}
      total={totalCount ?? data.length}
      onChangePage={(e) => {
        typeof setPage === "function" && setPage(e);
      }}
      onChangeLength={(e) => {
        typeof setPage === "function" && setPage(1);

        typeof setTake === "function" && setTake(e);
      }}
    />
  );
};

export default Pagination;
