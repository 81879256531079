import { call, put, takeLatest, takeEvery } from "@redux-saga/core/effects";
import {
  AccountTypeParams,
  ApiResponse,
  CommunicationLanguageObject,
  LoadingTypes,
  LoadOpportunities,
  LoadOpportunitiesResponse,
  SagaGenericParams,
  SliderTimeObject,
  TransportTypes,
  TruckTypes,
} from "../../types";
import { resultStatus } from "../../utils/Properties";
import { createRequestSaga } from "../actions";
import * as A from "../actions";
import * as O from "../../types/objects";
import { hideLoader, showLoader } from "../actions/appActions";
import { VehicleOpportunityDto } from "../opportunity/type";

export function* getListSaga<Type>({ payload, url }: SagaGenericParams<Type>) {
  yield put(showLoader());
  try {
    const response: ApiResponse<Type, null> = yield call(createRequestSaga, {
      method: "GET",
      url: url || "",
    });
    if (resultStatus(response.ResultStatus)) {
      payload.onSuccess(response.Message, response.Result, response);
    } else payload.onError(response.Message);
  } catch (e) {
    console.log("ERROR getListSaga", e);
  } finally {
    yield put(hideLoader());
  }
}
export function* getListWithoutLoaderSaga<Type>({
  payload,
  url,
}: SagaGenericParams<Type>) {
  try {
    const response: ApiResponse<Type> = yield call(createRequestSaga, {
      method: "GET",
      url: url || "",
    });
    if (resultStatus(response.ResultStatus)) {
      payload.onSuccess(response.Message, response.Result);
    } else payload.onError(response.Message);
  } catch (e) {
    console.log("ERROR getListSaga", e);
  }
}
function* getLoadOpportunitiesSaga({
  payload,
  url,
}: SagaGenericParams<LoadOpportunitiesResponse>) {
  yield put(showLoader());
  try {
    const response: ApiResponse<LoadOpportunities[]> = yield call(
      createRequestSaga,
      {
        method: "GET",
        url: url || "",
      }
    );
    if (resultStatus(response.ResultStatus)) {
      //@ts-ignore
      const responseData: LoadOpportunitiesResponse = {
        list: response.Result,
        //@ts-ignore
        total: response.TotalCount,
      };
      payload.onSuccess(response.Message, responseData, response);
    } else payload.onError(response.Message);
  } catch (e) {
    console.log("ERROR getListSaga", e);
  } finally {
    yield put(hideLoader());
  }
}
function* getLoadOpportunityDetailSaga({
  payload,
  url,
}: SagaGenericParams<LoadOpportunities>) {
  yield put(showLoader());
  try {
    const response: ApiResponse<LoadOpportunities[]> = yield call(
      createRequestSaga,
      {
        method: "GET",
        url: url || "",
      }
    );
    if (resultStatus(response.ResultStatus)) {
      payload.onSuccess(response.Message, response.Result[0]);
    } else payload.onError(response.Message);
  } catch (e) {
    console.log("ERROR getListSaga", e);
  } finally {
    yield put(hideLoader());
  }
}
function* getVehicleDetailSagaWithoutloader({
  payload,
  url,
}: SagaGenericParams<VehicleOpportunityDto>) {
  try {
    const response: ApiResponse<VehicleOpportunityDto[]> = yield call(
      createRequestSaga,
      {
        method: "GET",
        url: url || "",
      }
    );
    if (resultStatus(response.ResultStatus)) {
      payload.onSuccess(response.Message, response.Result[0]);
    } else payload.onError(response.Message);
  } catch (e) {
    console.log("ERROR getListSaga", e);
  } finally {
    yield put(hideLoader());
  }
}
function* getLoadOpportunityDetailSagaWithoutloader({
  payload,
  url,
}: SagaGenericParams<LoadOpportunities>) {
  try {
    const response: ApiResponse<LoadOpportunities[]> = yield call(
      createRequestSaga,
      {
        method: "GET",
        url: url || "",
      }
    );
    if (resultStatus(response.ResultStatus)) {
      payload.onSuccess(response.Message, response.Result[0]);
    } else payload.onError(response.Message);
  } catch (e) {
    console.log("ERROR getListSaga", e);
  } finally {
    yield put(hideLoader());
  }
}
function* getVehicleOpportunityDetailSaga({
  payload,
  url,
}: SagaGenericParams<VehicleOpportunityDto>) {
  yield put(showLoader());
  try {
    const response: ApiResponse<VehicleOpportunityDto[]> = yield call(
      createRequestSaga,
      {
        method: "GET",
        url: url || "",
      }
    );
    if (resultStatus(response.ResultStatus)) {
      payload.onSuccess(response.Message, response.Result[0]);
    } else payload.onError(response.Message);
  } catch (e) {
    console.log("ERROR getListSaga", e);
  } finally {
    yield put(hideLoader());
  }
}
const getSaga = [
  takeLatest(A.getSSS.toString(), (payload: SagaGenericParams<O.SSSDto[]>) =>
    getListSaga<O.SSSDto[]>(payload)
  ),
  /* Homepage */
  takeLatest(
    A.getSocialMediaLinks.toString(),
    (payload: SagaGenericParams<O.BlogObject[]>) =>
      getListSaga<O.BlogObject[]>(payload)
  ),
  takeLatest(
    A.getHomepageOffices.toString(),
    (payload: SagaGenericParams<O.OfficeObject[]>) =>
      getListSaga<O.OfficeObject[]>(payload)
  ),
  takeLatest(
    A.getHomepageSliders.toString(),
    (payload: SagaGenericParams<O.SliderObject[]>) =>
      getListSaga<O.SliderObject[]>(payload)
  ),
  takeLatest(
    A.getHomepageStats.toString(),
    (payload: SagaGenericParams<O.StatObject>) =>
      getListSaga<O.StatObject>(payload)
  ),
  /* Components */
  takeLatest(
    A.getSocialMediaLinks.toString(),
    (payload: SagaGenericParams<O.SocialMediaLinksObject>) =>
      getListSaga<O.SocialMediaLinksObject>(payload)
  ),
  /* Enums */
  takeLatest(
    A.getAccountTypes.toString(),
    (payload: SagaGenericParams<AccountTypeParams[]>) =>
      getListSaga<AccountTypeParams[]>(payload)
  ),
  takeLatest(
    A.getHomepageBlogs.toString(),
    (payload: SagaGenericParams<O.BlogObject[]>) =>
      getListSaga<O.BlogObject[]>(payload)
  ),
  takeLatest(
    A.getCommunicationLanguages.toString(),
    (payload: SagaGenericParams<CommunicationLanguageObject[]>) =>
      getListSaga<CommunicationLanguageObject[]>(payload)
  ),
  takeEvery(
    A.getLocations.toString(),
    (payload: SagaGenericParams<O.LocationObject[]>) =>
      getListWithoutLoaderSaga<O.LocationObject[]>(payload)
  ),
  takeLatest(
    A.getTransportTypes.toString(),
    (payload: SagaGenericParams<TransportTypes[]>) =>
      getListSaga<TransportTypes[]>(payload)
  ),
  takeLatest(
    A.getLoadingTypes.toString(),
    (payload: SagaGenericParams<LoadingTypes[]>) =>
      getListSaga<LoadingTypes[]>(payload)
  ),
  takeLatest(
    A.getTruckTypes.toString(),
    (payload: SagaGenericParams<TruckTypes[]>) =>
      getListSaga<TruckTypes[]>(payload)
  ),
  takeLatest(A.getLoadOpportunities.toString(), (payload) =>
    getLoadOpportunitiesSaga(payload as any)
  ),
  takeLatest(
    A.getHomepageSliderTime.toString(),
    (payload: SagaGenericParams<SliderTimeObject>) =>
      getListSaga<SliderTimeObject>(payload)
  ),
  takeLatest(A.getLoadOpportunityDetail.toString(), (payload) =>
    getLoadOpportunityDetailSaga(payload as any)
  ),
  takeLatest(A.getLoadOpportunityDetailWithoutLoader.toString(), (payload) =>
    getLoadOpportunityDetailSagaWithoutloader(payload as any)
  ),
  takeLatest(A.getVehicleDetailWithoutLoader.toString(), (payload) =>
    getVehicleDetailSagaWithoutloader(payload as any)
  ),
  takeLatest(A.getVehicleOpportunityDetail.toString(), (payload) =>
    getVehicleOpportunityDetailSaga(payload as any)
  ),
];

export default getSaga;
