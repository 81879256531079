import React from "react";
import "./style.css";
import { Modal } from "rsuite";
import { color } from "../../utils/Properties";
import ScrollBar from "react-perfect-scrollbar";
import { TypeAttributes } from "rsuite/lib/@types/common";

export enum ModalSize {
  default,
  medium,
  wide,
  extra,
}

type ModalRsuiteProps = {
  className?: string;
  visible: boolean;
  close: () => void;
  title?: string;
  footer?: React.ReactNode;
  children: React.ReactNode;
  size?: TypeAttributes.Size;
  full?: boolean;
  noPadding?: boolean;
  modalSize?: ModalSize;
  headerButton?: React.ReactNode;
  newMinHeight?: number;
  style?: any;
};
const ModalRsuite = ({
  className,
  visible,
  close,
  title,
  footer,
  children,
  style,
  size = "lg",
  full = false,
  noPadding = false,
  modalSize,
  headerButton,
  newMinHeight = 200,
}: ModalRsuiteProps) => {
  return (
    <div className="modal-container">
      <Modal
        style={{ ...style }}
        className={`modalComponentDiv ${className} `}
        size={size}
        show={visible}
        onHide={close}
        full={full}
      >
        <Modal.Header
          closeButton={title ? true : false}
          className={title ? "modalRsuiteHeader" : ""}
          style={{
            fontSize: 22,
            fontWeight: 700,
            color: color.accent,
            backgroundColor: color.primary,
            paddingInline: 30,
            height: 60,
          }}
        >
          {title && title}
        </Modal.Header>
        <ScrollBar>
          <Modal.Body
            className="modalRsuiteBody"
            style={
              noPadding
                ? { marginTop: 0, paddingBottom: 0, minHeight: newMinHeight }
                : {
                    paddingInline: 20,
                    overflow: "none",
                    minHeight: newMinHeight,
                  }
            }
          >
            {children}
          </Modal.Body>
        </ScrollBar>
        {footer && (
          <Modal.Footer className="modalRsuiteFooter">{footer}</Modal.Footer>
        )}
      </Modal>
    </div>
  );
};

export default ModalRsuite;
