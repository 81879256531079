import { SelectPicker as SelectPickerRS, SelectPickerProps } from "rsuite";
import { color } from "../../utils/Properties";

const SelectPicker = ({
  data,
  label,
  value,
  onChange,
  error,
  touched,
  placement,
  ...rest
}: SelectPickerProps) => {
  return (
    <div
      className="field only-date defaultInputRes"
      style={{ borderColor: color.borderColor }}
    >
      <span
        style={{
          fontSize: 13,
          marginBlockEnd: 2,
          color: color.textPrimary,
        }}
      >
        {label}
      </span>
      <SelectPickerRS
        placement={placement ? placement : "bottomStart"}
        className={error && touched ? "errorInput" : "defaultInput "}
        data={data}
        value={value}
        onChange={onChange}
        {...rest}
      />
      {error && <p className="inputErrorStyles">{error}</p>}
    </div>
  );
};

export default SelectPicker;
