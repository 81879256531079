import { createRef, useState, useEffect } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { Icon } from "..";
import "./style.css";

type CardProps = {
  height: string | number;
  arrowHeight: number;
  children: React.ReactNode;
};

const DragScroll = ({ children, height, arrowHeight }: CardProps) => {
  const [overflown, setOverFlown] = useState<boolean>(false);
  const [display, setDisplay] = useState<boolean>(false);
  const [opacityLeft, setOpacityLeft] = useState<number>(0);
  const [opacityRight, setOpacityRigth] = useState<number>(1);
  const scrollRef = createRef<ScrollContainer>();

  useEffect(() => {
    if (
      scrollRef.current &&
      scrollRef.current?.getElement().scrollWidth &&
      scrollRef.current?.getElement().clientWidth
    ) {
      if (
        scrollRef.current?.getElement().scrollWidth >
        scrollRef.current?.getElement().clientWidth
      )
        setOverFlown(true);
    }
  }, [scrollRef]);

  const handleScroll = (
    scrollLeft: number,
    scrollTop: number,
    scrollWidth: number,
    scrollHeight: number
  ) => {
    const containerWidth = scrollRef.current?.getElement().clientWidth || 0;
    if (scrollLeft > 0) {
      setOpacityLeft(1);
      if (scrollWidth - (scrollLeft + containerWidth) === 0) setOpacityRigth(0);
      else setOpacityRigth(1);
    } else {
      setOpacityLeft(0);
      setOpacityRigth(1);
    }
  };

  const buttonDrag = (direction: "left" | "right") => {
    const left = scrollRef.current?.getElement().scrollLeft || 0;
    scrollRef.current
      ?.getElement()
      .scrollTo(
        direction === "left"
          ? { left: left - 300, behavior: "smooth" }
          : { left: left + 300, behavior: "smooth" }
      );
  };

  return (
    <div
      id="dragScroll"
      style={{ width: "100%", height: height }}
      onMouseEnter={() => setDisplay(true)}
      onMouseLeave={() => setDisplay(false)}
    >
      <ScrollContainer
        ref={scrollRef}
        className="dragScroll"
        onEndScroll={handleScroll}
        style={{
          height: height,
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          className={overflown ? "overflownLeftDiv" : ""}
          style={{
            height: arrowHeight,
            opacity: display ? opacityLeft : 0,
            transition: "0.4s",
          }}
          onClick={() => {
            buttonDrag("left");
          }}
        >
          <Icon icon="left" color="white" />
        </div>
        {children}
        <div
          className={overflown ? "overflownRightDiv" : ""}
          style={{
            height: arrowHeight,
            opacity: display ? opacityRight : 0,
            transition: "0.4s",
          }}
          onClick={() => buttonDrag("right")}
        >
          <Icon icon="right" color="white" />
        </div>
      </ScrollContainer>
    </div>
  );
};

export default DragScroll;
