import { useEffect } from "react";
import { DragScroll } from "..";
import { icons } from "../../assets";
import { TruckTypeDto } from "../../redux/profile/type";
import { TruckTypeObject } from "../../types";

export const truckTypes: TruckTypeObject[] = [
  {
    id: "1",
    name: "tautliner&box",
    icon: icons.tautlinerBox,
  },
  { id: "2", name: "flatbed", icon: icons.flatbed },
  {
    id: "3",
    name: "swap_container",
    icon: icons.swapContainer,
  },
  { id: "4", name: "mega", icon: icons.mega },
  {
    id: "5",
    name: "refrigerator",
    icon: icons.refrigerator,
  },
  { id: "6", name: "road_train", icon: icons.mega },
  { id: "7", name: "mega", icon: icons.mega },

  {
    id: "8",
    name: "tautliner&box",
    icon: icons.tautlinerBox,
  },
  { id: "9", name: "flatbed", icon: icons.flatbed },
  {
    id: "10",
    name: "swap_container",
    icon: icons.swapContainer,
  },
  { id: "11", name: "mega", icon: icons.mega },
  {
    id: "12",
    name: "refrigerator",
    icon: icons.refrigerator,
  },
  { id: "13", name: "road_train", icon: icons.mega },
  { id: "14", name: "mega", icon: icons.mega },
];
type TruckSelectorProps = {
  selectedTrucks: string[];
  vehicletypes?: TruckTypeDto[];
  setSelectedTrucks: (selectedTrucks: string[]) => void;
};

const TruckSelector = ({
  selectedTrucks,
  vehicletypes,
  setSelectedTrucks,
}: TruckSelectorProps) => {


  useEffect(() => { vehicletypes?.sort((a, b) => parseInt(a.key_value__c) - parseInt(b.key_value__c)); }



    ,);

  const onTruckClick = (id: string) => {
    if (selectedTrucks.some((x) => x === id)) {
      return setSelectedTrucks(selectedTrucks.filter((x) => x !== id));
    } else {
      return setSelectedTrucks([...selectedTrucks, id]);
    }
  };

  return (
    <DragScroll height={200} arrowHeight={158}>
      {vehicletypes?.map((truck, i) => {

        return (
          <div
            key={truck.id}
            className="truckTypeCard"
            onClick={() => onTruckClick(truck.id)}
            style={
              selectedTrucks.some((truckType: string) => truckType === truck.id)
                ? {
                  boxShadow: "0px 3px 50px #00000014",
                  background: "#d8d8d899",
                }
                : {}
            }
          >
            <img
              src={i > 12 ? icons.refrigerator : truckTypes[i].icon}
              alt=""
            />
            <p>{truck.description}</p>
          </div>
        );
      })}
    </DragScroll>
  );
};

export default TruckSelector;
