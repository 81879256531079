import { Formik } from "formik";
import * as Yup from "yup";
import { Col, Row } from "rsuite";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getCustomerContractLocalization } from "../../../../../redux/selectors";
import { CommunicationLanguageObject } from "../../../../../types";
import { CheckPicker, Icon, Input } from "../../../../../components";
import { createDriver } from "../../../../../redux/transportercontracts/saga";
import { generateCommunicationLanguagesPickerArray } from "../../../../../utils/Helpers";

const CreateDriver = ({
  onSuccess,
  comLang,
}: {
  comLang?: CommunicationLanguageObject[];
  onSuccess: () => void;
}) => {
  const t = useSelector(getCustomerContractLocalization);
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        FirstName: "",
        LastName: "",
        Email: "",
        Phone: "",
        Languages: [],
        Password: "",
      }}
      validationSchema={Yup.object().shape({})}
      onSubmit={(values, { resetForm }) => {
        dispatch(
          createDriver({
            payload: {
              onSuccess: (m, p) => {
                toast.success(m);
                onSuccess();
                return resetForm({
                  values: {
                    FirstName: "",
                    LastName: "",
                    Email: "",
                    Phone: "",
                    Languages: [],
                    Password: "",
                  },
                });
              },
              onError: (m) => {
                toast.error(m);
              },
            },
            body: {
              ...values,
              CommunicationLanguage: values.Languages.join(";"),
            },
          })
        );
      }}
    >
      {({
        handleSubmit,
        handleBlur,
        setFieldValue,
        errors,
        touched,
        handleChange,
        values,
      }) => (
        <>
          <div style={{ width: "100%" }}>
            <Row>
              <Col md={8}>
                <Input
                  name="FirstName"
                  value={values.FirstName}
                  onChange={handleChange("FirstName")}
                  onBlur={handleBlur("FirstName")}
                  label={t.getString("FirstName")}
                  error={errors.FirstName}
                  touched={touched.FirstName}
                />
              </Col>
              <Col md={8}>
                <Input
                  name="LastName"
                  value={values.LastName}
                  onChange={handleChange("LastName")}
                  onBlur={handleBlur("LastName")}
                  label={t.getString("LastName")}
                  error={errors.LastName}
                  touched={touched.LastName}
                />
              </Col>
              <Col md={8}>
                <Input
                  name="Email"
                  value={values.Email}
                  onChange={handleChange("Email")}
                  onBlur={handleBlur("Email")}
                  label={t.getString("Email")}
                  error={errors.Email}
                  touched={touched.Email}
                />
              </Col>
              <Col md={8}>
                <Input
                  name="Phone"
                  value={values.Phone}
                  onChange={handleChange("Phone")}
                  onBlur={handleBlur("Phone")}
                  label={t.getString("Phone")}
                  error={errors.Phone}
                  touched={touched.Phone}
                />
              </Col>
              <Col md={8}>
                <Input
                  name="Password"
                  value={values.Password}
                  onChange={handleChange("Password")}
                  onBlur={handleBlur("Password")}
                  label={t.getString("Password")}
                  error={errors.Password}
                  touched={touched.Password}
                />
              </Col>
              <Col md={6}>
                <CheckPicker
                  customClassName="communicationCheckPicker"
                  searchable={false}
                  cleanable={false}
                  label={t.getString("Language")}
                  data={generateCommunicationLanguagesPickerArray(
                    comLang ?? []
                  )}
                  placement="topStart"
                  name="id"
                  value={values.Languages}
                  onChange={(e) => setFieldValue(`Languages`, e)}
                  onBlur={handleBlur(`Languages`)}
                  error={errors.Languages}
                  touched={touched.Languages}
                />
              </Col>
              <Col md={2}>
                <div className="smResponsiveButtonDiv gridPosition">
                  <button
                    type="submit"
                    className="gridIcon "
                    onClick={() => handleSubmit()}
                  >
                    <Icon icon="plus" size={20} />
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </>
      )}
    </Formik>
  );
};
export default CreateDriver;
