import { Formik } from "formik";
import { useSelector } from "react-redux";
import { Input, SelectPicker } from "../../../../../components";
import * as Yup from "yup";
import {
  getLocalizedErrors,
  getLocalizedTruckRegistration,
} from "../../../../../redux/selectors";
import { InputType } from "../../../../../components/Input";
import { RouteDto } from "../../../../../redux/opportunity/type";
import { Col, Row } from "rsuite";
const EditLocation = ({
  show,
  data,
  onHide,
  types,
  baseValues,
  errors,
  touched,
  onSubmit,
  onSelectDestination,
}: {
  onSelectDestination: (fromcode: string, tocode: string) => void;
  data: {
    Guzergah__c_name: string;
    destinationId: string;
    no: string;
    destinationName: string;
    Guzergah__c: string;
    CustomersWantsPrice__c: any;
  };
  onSubmit: (values: {
    no: string;
    Guzergah__c_name: string;
    destinationName: string;
    destinationId: string;
    Guzergah__c: string;
    CustomersWantsPrice__c: any;
  }) => void;
  types: RouteDto[];
  onHide: () => void;
  show: boolean;
  baseValues: any;
  errors: any;
  touched: any;
}) => {
  // eslint-disable-next-line
  const strings = useSelector(getLocalizedTruckRegistration);
  const errorStrings = useSelector(getLocalizedErrors);
  console.log("sekected", data);
  return (
    <Formik
      enableReinitialize
      initialValues={{
        destinationName: data.destinationName,
        destinationId: data.destinationId,
        Guzergah__c_name: data.Guzergah__c_name,
        no: data.no,
        Guzergah__c: data.Guzergah__c,
        CustomersWantsPrice__c: data.CustomersWantsPrice__c,
      }}
      validationSchema={Yup.object().shape({
        Guzergah__c: Yup.string().required(errorStrings.getString("required")),
      })}
      onSubmit={onSubmit}
    >
      {({
        handleChange,
        handleSubmit,
        handleBlur,
        errors,
        touched,
        values,
      }) => (
        <>
          <Row>
            <Col md={8}>
              <SelectPicker
                cleanable={false}
                searchable={true}
                valueKey={"no"}
                labelKey={"location_To_Load__c"}
                value={values.destinationId}
                label={strings.getString("Destination")}
                data={baseValues.destinationList___c}
                onSelect={(value, item: any) => {
                  handleChange("destinationId")(value);
                  handleChange("destinationName")(
                    item.location_To_Load__c +
                      " > " +
                      item.location_To_Go__c_Name
                        .map((x: any) => x.label)
                        .toString()
                  );
                }}
                onBlur={handleBlur("destinationId")}
                error={errors.destinationId}
                touched={touched.destinationId}
                renderValue={(label, item: any) => {
                  return item ? (
                    <>
                      {item.location_To_Load__c_Name} {" > "}
                      {item.location_To_Go__c_Name
                        .map((x: any) => x.label)
                        .toString()}
                    </>
                  ) : (
                    <></>
                  );
                }}
                renderMenuItem={(label, item: any) => {
                  return item ? (
                    <>
                      {item.location_To_Load__c_Name}
                      {" > "}
                      {item.location_To_Go__c_Name
                        .map((x: any) => x.label)
                        .toString()}
                    </>
                  ) : (
                    <></>
                  );
                }}
              />
            </Col>
            <Col md={8}>
              <SelectPicker
                placement="bottomStart"
                cleanable={false}
                searchable={true}
                labelKey={"name"}
                valueKey={"sfid"}
                style={{ marginBlockEnd: 10 }}
                label={strings.getString("Route")}
                placeholder={strings.getString("Route")}
                data={
                  baseValues.guzergahList__c.filter(
                    (x: any) => x.no !== data.no
                  ).length > 0
                    ? types.filter(
                        (x) =>
                          !baseValues.guzergahList__c
                            .filter((x: any) => x.no !== data.no)
                            .some((y: any) => y.Guzergah__c === x.sfid)
                      )
                    : types
                }
                name="from__c"
                value={values.Guzergah__c}
                onSelect={(e, item: any) => {
                  handleChange("Guzergah__c_name")(item.name);
                  return handleChange("Guzergah__c")(e);
                }}
                onBlur={handleBlur("Guzergah__c")}
                error={errors.Guzergah__c}
                touched={touched.Guzergah__c}
              />
            </Col>
            <Col md={7}>
              <Input
                inputType={InputType.number}
                value={values.CustomersWantsPrice__c}
                label={strings.getString("Requested Price")}
                name="CustomersWantsPrice__c"
                onChange={handleChange("CustomersWantsPrice__c")}
                onBlur={handleBlur("CustomersWantsPrice__c")}
                //@ts-ignore
                error={errors.CustomersWantsPrice__c}
                //@ts-ignore
                touched={touched.CustomersWantsPrice__c}
              />
            </Col>
            <Col md={1}>
              <div className="smResponsiveButtonDiv gridPosition">
                <button
                  type="submit"
                  className="gridIcon "
                  onClick={() => handleSubmit()}
                >
                  <i className="dx-icon-save" style={{ color: "white" }} />
                </button>
              </div>
            </Col>
          </Row>
        </>
      )}
    </Formik>
  );
};
export default EditLocation;
