import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "rsuite";
import { getAuctionAgreement } from "../../../../redux/autions/saga";

const TermsModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const [agreement, setAggrement] = useState<string>();
  const dispatch = useDispatch();

  useEffect(() => {
    open &&
      dispatch(
        getAuctionAgreement({
          payload: {
            onSuccess: (ms, p) => {
              setAggrement(p);
            },
            onError: () => {},
          },
        })
      );
  }, [open, dispatch]);
  return (
    <Modal show={open} onHide={() => setOpen(false)} size={"sm"}>
      <Modal.Header onHide={() => setOpen(false)}></Modal.Header>
      <Modal.Body style={{ minHeight: 400 }}>
        <div
          style={{ padding: "20px" }}
          dangerouslySetInnerHTML={{
            __html: agreement ? agreement : "",
          }}
        ></div>
      </Modal.Body>
    </Modal>
  );
};
export default TermsModal;
