import { call, put, takeLatest } from "@redux-saga/core/effects";
import { ApiResponse, SagaGenericParams } from "../../types";
import { resultStatus } from "../../utils/Properties";
import { createRequestSaga, hideLoader, showLoader } from "../actions";
import * as A from "../actions";
import * as O from "../../types/objects";

function* getDetailsWithIdSaga<Type>({
  payload,
  url,
  id,
}: SagaGenericParams<Type>) {
  yield put(showLoader());
  try {
    const response: ApiResponse<Type> = yield call(createRequestSaga, {
      method: "GET",
      url: url + "/" + id || "",
    });
    if (resultStatus(response.ResultStatus))
      payload.onSuccess(response.Message, response.Result);
    else payload.onError(response.Message);
  } catch (e) {
    console.log("ERROR getListSaga", e);
  } finally {
    yield put(hideLoader());
  }
}

const getWithIdSaga = [
  takeLatest(
    A.getHomepageArticle.toString(),
    (payload: SagaGenericParams<O.ArticleObject[]>) =>
      getDetailsWithIdSaga<O.ArticleObject[]>(payload)
  ),
  takeLatest(
    A.getHomepageLoadArticle.toString(),
    (payload: SagaGenericParams<O.ArticleObject[]>) =>
      getDetailsWithIdSaga<O.ArticleObject[]>(payload)
  ),
  takeLatest(
    A.getHomepageTrucksArticle.toString(),
    (payload: SagaGenericParams<O.ArticleObject[]>) =>
      getDetailsWithIdSaga<O.ArticleObject[]>(payload)
  ),
  takeLatest(
    A.getBlogDetails.toString(),
    (payload: SagaGenericParams<O.BlogObject>) =>
      getDetailsWithIdSaga<O.BlogObject>(payload)
  ),
];

export default getWithIdSaga;
