import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import {
  LoadOpportunities,
  SagaActionParams,
  SagaGenericParams,
} from "../../types";
import { deleteSaga } from "../sagas/deleteSaga";
import { getListSaga } from "../sagas/getSaga";
import { postSaga, PostSagaGenericParams, putSaga } from "../sagas/postsaga";
import {
  ApprovalCustomerOfferDto,
  ApprovalTransportOfferDto,
  ChatDto,
  CityDto,
  ContactDto,
  CountryDto,
  CurrencyDto,
  DestinationDto,
  DisapprovalReasonDto,
  DocumentDto,
  DocumentTypeDto,
  IndustryDto,
  PicklistCountryDto,
  ProfileCustomerOfferDto,
  TransportWaysDto,
  TruckTypeDto,
  VehicleLengthDto,
  VehicleTypeDto,
} from "./type";
import { LoginResult } from "../types";
import { BodyType } from "../actions";
import { VehicleOpportunityDto } from "../opportunity/type";

export const GetCustomerOfferList = createAction(
  "GET_CUSTOMER_OFFER_LIST",
  ({ payload }: SagaActionParams<ProfileCustomerOfferDto[]>) => ({
    payload,
    url: "Profile/GetCustomerOfferLists",
  })
);
export const GetClosedCustomerOfferList = createAction(
  "GET_CLOSED_CUSTOMER_OFFER_LIST",
  ({ payload }: SagaActionParams<ProfileCustomerOfferDto[]>) => ({
    payload,
    url: "Profile/GetCustomerOfferClosingLists",
  })
);
export const setCustomerOfferStatus = createAction(
  "SET_CUSTOMER_OFFER_STATUS",
  ({ payload, body }: SagaActionParams<ApprovalCustomerOfferDto[]>) => ({
    payload,
    body: body,
    url: "Profile/SetCustomerOfferStatus",
    bodyType: BodyType.raw,
  })
);

export const getVehicleWidth = createAction(
  "GET_VEHICLE_WIDTH",
  ({ payload }: SagaActionParams<VehicleLengthDto[]>) => ({
    payload,
    url: "System/GetVehicleWidths",
  })
);
export const getVehicleHeight = createAction(
  "GET_VEHICLE_HEIGHT",
  ({ payload }: SagaActionParams<VehicleLengthDto[]>) => ({
    payload,
    url: "System/GetVehicleHeights",
  })
);
export const getVehicleLength = createAction(
  "GET_VEHICLE_LENGTH",
  ({ payload }: SagaActionParams<VehicleLengthDto[]>) => ({
    payload,
    url: "System/GetVehicleLengths",
  })
);

export const postApprovalTruckOfferList = createAction(
  "POST_APPROVAL_TRUCK_OFFER_LIST",
  ({ payload, body }: SagaActionParams<any>) => ({
    payload,
    body: body,
    url: "Profile/SetApprovalTruckStatus",
    bodyType: BodyType.raw,
  })
);
export const postApprovalLoadOfferList = createAction(
  "POST_APPROVAL_LOAD_OFFER_LIST",
  ({ payload, body }: SagaActionParams<any>) => ({
    payload,
    body: body,
    url: "Profile/SetApprovalLoadStatus",
    bodyType: BodyType.raw,
  })
);
export const getMyLoadOpportunities = createAction(
  "GET_MY_LOAD_OPPORTUNITIES",
  ({ payload, url }: SagaActionParams<LoadOpportunities[]>) => ({
    payload,
    url: url,
  })
);
export const CancelMyLoadOpportunities = createAction(
  "CANCEL_MY_LOAD_OPPORTUNITY",
  ({ payload, body }: SagaActionParams<LoadOpportunities[]>) => ({
    payload,
    body: body,
    url: "VehicleOpportunity/CancelLoadOpportunities",
  })
);
export const getMyTruckOpportunities = createAction(
  "GET_MY_TRUCK_OPPORTUNITIES",
  ({ payload, url }: SagaActionParams<VehicleOpportunityDto[]>) => ({
    payload,
    url: url,
  })
);
export const CancelMyTruckOpportunities = createAction(
  "CANCEL_MY_TRUCK_OPPORTUNITY",
  ({ payload, body }: SagaActionParams<LoadOpportunities[]>) => ({
    payload,
    body: body,
    url: "LoadOpportunity/CancelVehicleOpportunities",
  })
);
export const getApprovalTruckOfferList = createAction(
  "GET_APPROVAL_TRUCK_OFFER_LIST",
  ({ payload }: SagaActionParams<ApprovalTransportOfferDto[]>) => ({
    payload,
    url: "Profile/GetApprovalTruckOfferLists",
  })
);
export const getClosedApprovalTruckOfferList = createAction(
  "GET_CLOSED_APPROVAL_TRUCK_OFFER_LIST",
  ({ payload }: SagaActionParams<ApprovalTransportOfferDto[]>) => ({
    payload,
    url: "Profile/GetApprovalTruckOfferClosingLists",
  })
);
export const GetApprovalLoadOfferLists = createAction(
  "GET_APPROVAL_LOAD_OFFER_LIST_CUSTOMER",
  ({ payload }: SagaActionParams<ApprovalCustomerOfferDto[]>) => ({
    payload,
    url: "Profile/GetApprovalLoadOfferLists",
  })
);
export const GetClosedApprovalLoadOfferLists = createAction(
  "GET_CLOSED_APPROVAL_LOAD_OFFER_LIST_CUSTOMER",
  ({ payload }: SagaActionParams<ApprovalCustomerOfferDto[]>) => ({
    payload,
    url: "Profile/GetApprovalLoadOfferClosingLists",
  })
);
export const getReasonForTransporterDisapproval = createAction(
  "GET_REASON_FOR_DISAPPROVAL",
  ({ payload }: SagaActionParams<DisapprovalReasonDto[]>) => ({
    payload,
    url: "System/GetReasonForTransporterDisapproval",
  })
);
export const sendForApprovalCompany = createAction(
  "SEND_FOR_APPROVAL_COMPANY",
  ({ payload }: SagaActionParams<any>) => ({
    payload,
    url: "Profile/SendForApprovalCompany",
  })
);
export const sendResubmitCompany = createAction(
  "SEND_RESUBMIT_COMPANY",
  ({ payload }: SagaActionParams<any>) => ({
    payload,
    url: "Profile/ResubmitForApprovalCompany",
  })
);
export const createContactUsForm = createAction(
  "CREATE_CONTACT_US_FORM",
  ({ payload, body }: SagaActionParams<any>) => ({
    payload,
    body: body,
    url: "HomePage/CreateContactUs",
  })
);

export const getActiveUserInfo = createAction(
  "GET_ACTIVE_USER_INFO",
  ({ payload, url }: SagaActionParams<LoginResult>) => ({
    payload,
    url,
  })
);

export const getDocumentTypes = createAction(
  "GET_DOCUMENT_TYPES",
  ({ payload, url }: SagaActionParams<DocumentTypeDto[]>) => ({
    payload,
    url,
  })
);
export const getDocuments = createAction(
  "GET_DOCUMENTS",
  ({ payload, url }: SagaActionParams<DocumentDto[]>) => ({
    payload,
    url,
  })
);

export const getChatDetails = createAction(
  "GET_CHAT_DETAILS",
  ({ payload, url }: SagaActionParams<ChatDto[]>) => ({
    payload,
    url,
  })
);
export const getVehicleChatDetails = createAction(
  "GET_VEHICLE_CHAT_DETAILS",
  ({ payload, url }: SagaActionParams<ChatDto[]>) => ({
    payload,
    url,
  })
);
export const getIndustryList = createAction(
  "GET_INDUSTRY_LIST",
  ({ payload, url }: SagaActionParams<IndustryDto[]>) => ({
    payload,
    url,
  })
);
export const getCountryList = createAction(
  "GET_COUNTRY_LIST",
  ({ payload, url }: SagaActionParams<CountryDto[]>) => ({
    payload,
    url,
  })
);
export const getPicklistCountryList = createAction(
  "GET_PICKLIST_COUNTRY_LIST",
  ({ payload, url }: SagaActionParams<PicklistCountryDto[]>) => ({
    payload,
    url,
  })
);
export const getPicklistCountryListForCustomer = createAction(
  "GET_PICKLIST_COUNTRY_LIST_CUSTOMER",
  ({ payload, url }: SagaActionParams<PicklistCountryDto[]>) => ({
    payload,
    url,
  })
);
export const getCityList = createAction(
  "GET_CITY_LIST",
  ({ payload, url }: SagaActionParams<CityDto[]>) => ({
    payload,
    url,
  })
);
export const getContacts = createAction(
  "GET_CONTACTS",
  ({ payload, url }: SagaActionParams<ContactDto[]>) => ({
    payload,
    url,
  })
);
export const getCurrencies = createAction(
  "GET_CURRENCIES",
  ({ payload, url }: SagaActionParams<CurrencyDto[]>) => ({
    payload,
    url,
  })
);
export const getTransportWays = createAction(
  "GET_TRANSPORTWAYS",
  ({ payload, url }: SagaActionParams<TransportWaysDto[]>) => ({
    payload,
    url,
  })
);
export const getTruckTypes = createAction(
  "GET_TRUCKTYPE",
  ({ payload, url }: SagaActionParams<TruckTypeDto[]>) => ({
    payload,
    url,
  })
);
export const getDestinations = createAction(
  "GET_DESTINATIONS",
  ({ payload, url }: SagaActionParams<DestinationDto[]>) => ({
    payload,
    url,
  })
);
export const getDestinationCustomer = createAction(
  "GET_DESTINATIONS_CUSTOMER",
  ({ payload, url }: SagaActionParams<DestinationDto[]>) => ({
    payload,
    url,
  })
);
export const addDestinations = createAction(
  "ADD_DESTINATIONS",
  ({ payload, url, body }: SagaActionParams<DestinationDto>) => ({
    payload,
    url,
    body: body,
  })
);
export const editDestinations = createAction(
  "EDIT_DESTINATIONS",
  ({ payload, url, body }: SagaActionParams<DestinationDto>) => ({
    payload,
    url,
    body: body,
  })
);
export const deleteDestinations = createAction(
  "DELETE_DESTINATIONS",
  ({ payload, url }: SagaActionParams<DestinationDto[]>) => ({
    payload,
    url,
  })
);

export const getVehicleTypes = createAction(
  "GET_VEHICLETYPE",
  ({ payload, url }: SagaActionParams<VehicleTypeDto[]>) => ({
    payload,
    url,
  })
);
export const addVehicleType = createAction(
  "ADD_VEHICLETYPE",
  ({ payload, url, body }: SagaActionParams<VehicleTypeDto>) => ({
    payload,
    url,
    body: body,
  })
);
export const editVehicleType = createAction(
  "EDIT_VEHICLETYPE",
  ({ payload, url, body }: SagaActionParams<VehicleTypeDto>) => ({
    payload,
    url,
    body: body,
  })
);
export const deleteVehicleType = createAction(
  "DELETE_VEHICLETYPE",
  ({ payload, url }: SagaActionParams<VehicleTypeDto[]>) => ({
    payload,
    url,
  })
);

const profileSagas = [
  takeLatest(
    GetCustomerOfferList.toString(),
    (payload: SagaGenericParams<any[]>) => getListSaga<any[]>(payload)
  ),
  takeLatest(
    GetClosedCustomerOfferList.toString(),
    (payload: SagaGenericParams<any[]>) => getListSaga<any[]>(payload)
  ),
  takeLatest(
    setCustomerOfferStatus.toString(),
    (payload: PostSagaGenericParams<any>) => postSaga<any>(payload)
  ),
  takeLatest(
    getVehicleLength.toString(),
    (payload: SagaGenericParams<VehicleLengthDto[]>) =>
      getListSaga<VehicleLengthDto[]>(payload)
  ),

  takeLatest(
    getVehicleHeight.toString(),
    (payload: SagaGenericParams<VehicleLengthDto[]>) =>
      getListSaga<VehicleLengthDto[]>(payload)
  ),
  takeLatest(
    getVehicleWidth.toString(),
    (payload: SagaGenericParams<VehicleLengthDto[]>) =>
      getListSaga<VehicleLengthDto[]>(payload)
  ),
  takeLatest(
    postApprovalLoadOfferList.toString(),
    (payload: PostSagaGenericParams<any>) => putSaga<any>(payload)
  ),
  takeLatest(
    postApprovalTruckOfferList.toString(),
    (payload: PostSagaGenericParams<any>) => putSaga<any>(payload)
  ),
  takeLatest(
    GetApprovalLoadOfferLists.toString(),
    (payload: SagaGenericParams<ApprovalCustomerOfferDto[]>) =>
      getListSaga<ApprovalCustomerOfferDto[]>(payload)
  ),
  takeLatest(
    GetClosedApprovalLoadOfferLists.toString(),
    (payload: SagaGenericParams<ApprovalCustomerOfferDto[]>) =>
      getListSaga<ApprovalCustomerOfferDto[]>(payload)
  ),
  takeLatest(
    getReasonForTransporterDisapproval.toString(),
    (payload: SagaGenericParams<DisapprovalReasonDto[]>) =>
      getListSaga<DisapprovalReasonDto[]>(payload)
  ),
  takeLatest(
    getApprovalTruckOfferList.toString(),
    (payload: SagaGenericParams<ApprovalTransportOfferDto[]>) =>
      getListSaga<ApprovalTransportOfferDto[]>(payload)
  ),
  takeLatest(
    getClosedApprovalTruckOfferList.toString(),
    (payload: SagaGenericParams<ApprovalTransportOfferDto[]>) =>
      getListSaga<ApprovalTransportOfferDto[]>(payload)
  ),
  takeLatest(
    getMyTruckOpportunities.toString(),
    (payload: SagaGenericParams<VehicleOpportunityDto[]>) =>
      getListSaga<VehicleOpportunityDto[]>(payload)
  ),
  takeLatest(
    CancelMyLoadOpportunities.toString(),
    (payload: PostSagaGenericParams<VehicleOpportunityDto>) =>
      postSaga<VehicleOpportunityDto>(payload)
  ),
  takeLatest(
    CancelMyTruckOpportunities.toString(),
    (payload: PostSagaGenericParams<VehicleOpportunityDto>) =>
      postSaga<VehicleOpportunityDto>(payload)
  ),
  takeLatest(
    getMyLoadOpportunities.toString(),
    (payload: SagaGenericParams<LoadOpportunities[]>) =>
      getListSaga<LoadOpportunities[]>(payload)
  ),
  takeLatest(
    sendForApprovalCompany.toString(),
    (payload: PostSagaGenericParams<any>) => putSaga<any>(payload)
  ),
  takeLatest(
    sendResubmitCompany.toString(),
    (payload: PostSagaGenericParams<any>) => putSaga<any>(payload)
  ),
  takeLatest(
    getIndustryList.toString(),
    (payload: SagaGenericParams<IndustryDto[]>) =>
      getListSaga<IndustryDto[]>(payload)
  ),
  takeLatest(
    getActiveUserInfo.toString(),
    (payload: SagaGenericParams<LoginResult>) =>
      getListSaga<LoginResult>(payload)
  ),
  takeLatest(
    getDocuments.toString(),
    (payload: SagaGenericParams<DocumentDto[]>) =>
      getListSaga<DocumentDto[]>(payload)
  ),
  takeLatest(
    getDocumentTypes.toString(),
    (payload: SagaGenericParams<DocumentTypeDto[]>) =>
      getListSaga<DocumentTypeDto[]>(payload)
  ),
  takeLatest(
    getChatDetails.toString(),
    (payload: SagaGenericParams<ChatDto[]>) => getListSaga<ChatDto[]>(payload)
  ),
  takeLatest(
    getVehicleChatDetails.toString(),
    (payload: SagaGenericParams<ChatDto[]>) => getListSaga<ChatDto[]>(payload)
  ),
  takeLatest(
    getPicklistCountryList.toString(),
    (payload: SagaGenericParams<PicklistCountryDto[]>) =>
      getListSaga<PicklistCountryDto[]>(payload)
  ),

  takeLatest(
    getPicklistCountryListForCustomer.toString(),
    (payload: SagaGenericParams<PicklistCountryDto[]>) =>
      getListSaga<PicklistCountryDto[]>(payload)
  ),
  takeLatest(
    getContacts.toString(),
    (payload: SagaGenericParams<ContactDto[]>) =>
      getListSaga<ContactDto[]>(payload)
  ),
  takeLatest(
    getTruckTypes.toString(),
    (payload: SagaGenericParams<TruckTypeDto[]>) =>
      getListSaga<TruckTypeDto[]>(payload)
  ),
  takeLatest(
    getTransportWays.toString(),
    (payload: SagaGenericParams<TransportWaysDto[]>) =>
      getListSaga<TransportWaysDto[]>(payload)
  ),
  takeLatest(
    getCityList.toString(),
    (payload: SagaGenericParams<ContactDto[]>) =>
      getListSaga<ContactDto[]>(payload)
  ),
  takeLatest(
    getCurrencies.toString(),
    (payload: SagaGenericParams<CurrencyDto[]>) =>
      getListSaga<CurrencyDto[]>(payload)
  ),
  takeLatest(
    getCountryList.toString(),
    (payload: SagaGenericParams<CountryDto[]>) =>
      getListSaga<CountryDto[]>(payload)
  ),
  takeLatest(
    getDestinations.toString(),
    (payload: SagaGenericParams<DestinationDto[]>) =>
      getListSaga<DestinationDto[]>(payload)
  ),
  takeLatest(
    getDestinationCustomer.toString(),
    (payload: SagaGenericParams<DestinationDto[]>) =>
      getListSaga<DestinationDto[]>(payload)
  ),
  takeLatest(
    addDestinations.toString(),
    (payload: PostSagaGenericParams<DestinationDto>) =>
      postSaga<DestinationDto>(payload)
  ),
  takeLatest(
    editDestinations.toString(),
    (payload: PostSagaGenericParams<DestinationDto>) =>
      putSaga<DestinationDto>(payload)
  ),
  takeLatest(
    deleteDestinations.toString(),
    (payload: SagaGenericParams<DestinationDto>) =>
      deleteSaga<DestinationDto>(payload)
  ),

  takeLatest(
    getVehicleTypes.toString(),
    (payload: SagaGenericParams<VehicleTypeDto[]>) =>
      getListSaga<VehicleTypeDto[]>(payload)
  ),
  takeLatest(
    addVehicleType.toString(),
    (payload: PostSagaGenericParams<VehicleTypeDto>) =>
      postSaga<VehicleTypeDto>(payload)
  ),
  takeLatest(
    editVehicleType.toString(),
    (payload: PostSagaGenericParams<VehicleTypeDto>) =>
      putSaga<VehicleTypeDto>(payload)
  ),
  takeLatest(
    deleteVehicleType.toString(),
    (payload: SagaGenericParams<VehicleTypeDto>) =>
      deleteSaga<VehicleTypeDto>(payload)
  ),
  takeLatest(
    createContactUsForm.toString(),
    (payload: PostSagaGenericParams<any>) => postSaga<any>(payload)
  ),
];
export default profileSagas;
