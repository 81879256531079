import { all, call, put, takeLatest, delay } from "@redux-saga/core/effects";
import { ApiResponse } from "../../types";
import { resultStatus } from "../../utils/Properties";
import {
  createRequestSaga,
  scrollToPosition,
  setScrollPosition,
} from "../actions";
import * as O from "../../types/objects";
import * as A from "../actions/systemActions";

function* systemsSaga() {
  yield all([]);
}

function* getContactRolesSaga() {
  try {
    const response: ApiResponse<O.GeneralSystemObject, null> = yield call(
      createRequestSaga,
      {
        method: "GET",
        url: "System/GetContactRoles",
      }
    );
    if (resultStatus(response.ResultStatus)) {
      yield put(A.setContactRoles(response.Result));
    } else {
    }
  } catch (e) {
    console.log("ERROR currenciesSaga", e);
  }
}

function* getLanguagesSaga() {
  try {
    const response: ApiResponse<O.GeneralSystemObject, null> = yield call(
      createRequestSaga,
      {
        method: "GET",
        url: "System/GetLanguages",
      }
    );
    if (resultStatus(response.ResultStatus)) {
      yield put(A.setLanguages(response.Result));
    } else {
    }
  } catch (e) {
    console.error("ERROR currenciesSaga", e);
  }
}
//@ts-ignore
function* scrollToPositionSaga({ payload }) {
  try {
    yield put(setScrollPosition(payload));
    yield delay(1000);
    yield put(setScrollPosition());
  } catch (e) {
    console.error("ERROR scrollToPositionSaga", e);
  }
}

function* scrollToPositionWatcher() {
  yield takeLatest(scrollToPosition, scrollToPositionSaga);
}

const systemSaga = [
  takeLatest(A.getAllSystem.toString(), systemsSaga),
  takeLatest(A.getContactRoles.toString(), getContactRolesSaga),
  takeLatest(A.getLanguages.toString(), getLanguagesSaga),
  scrollToPositionWatcher(),
];

export default systemSaga;
