export const formatChatMessageReceive = (
  m: VehicleChatMessageReceiveObject
): ContractMessage => {
  return {
    Message: m.message,
    CreateTime: m.createTime,
    ProfileImage: m.profileImage,
    UserId: m.userId,
    UserName: m.userName,
    Read: m.read,
  };
};
export interface ContractMessage {
  Message: string;
  CreateTime: string;
  ProfileImage: string;
  UserId: string;
  UserName: string;
  Read: boolean;
}
export interface VehicleChatSendObject {
  Message: string;
  vehicle_opp_destination__c?: string;
  user__c?: string;
  contact__c?: string;
}

export interface VehicleChatMessageReceiveObject {
  id: string;
  message: string;
  userId: string;
  createTime: string;
  profileImage: string;
  read: boolean;
  userName: string;
  vehicle_opp_destination__c: string;
}
