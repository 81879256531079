import { call, put } from "@redux-saga/core/effects";
import { ApiResponse, SagaGenericParams } from "../../types";
import { resultStatus } from "../../utils/Properties";
import {
  BodyType,
  createRequestSaga,
  hideLoader,
  showLoader,
} from "../actions";

export function* deleteSaga<Type>({ payload, url }: SagaGenericParams<Type>) {
  yield put(showLoader());
  try {
    const response: ApiResponse<Type> = yield call(createRequestSaga, {
      method: "DELETE",
      url: url || "",
      bodyType: BodyType.formdata,
    });
    if (resultStatus(response.ResultStatus))
      payload.onSuccess(response.Message, response.Result);
    else {
      payload.onError(response.Message);
    }
  } catch (e) {
    console.log("ERROR postSaga", e);
  } finally {
    yield put(hideLoader());
  }
}
