import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteDestinations,
  getDestinations,
  getPicklistCountryList,
  getTransportWays,
  getTruckTypes,
  getVehicleHeight,
  getVehicleLength,
  getVehicleWidth,
} from "../../../../../../redux/profile/profileSaga";
import {
  DestinationDto,
  PicklistCountryDto,
  TransportWaysDto,
  TruckTypeDto,
  VehicleLengthDto,
} from "../../../../../../redux/profile/type";
import { getLocalizedMyProfile } from "../../../../../../redux/selectors";
import { confirm } from "devextreme/ui/dialog";
import { Alert } from "rsuite";
import CreateAccountDestinationForm from "./create";
import EditAccountDestinationForm from "./edit";
import ResponsiveTable, {
  GridButtonType,
} from "../../../../../../components/ResponsiveTable";

const AccountDestinationGrid = () => {
  const [destination, setDestination] = useState<DestinationDto[]>();
  const [selected, setSelected] = useState<DestinationDto>();
  const [countries, setCountries] = useState<PicklistCountryDto[]>();
  const strings = useSelector(getLocalizedMyProfile);
  const [transports, setTransports] = useState<TransportWaysDto[]>();
  const [types, setTypes] = useState<TruckTypeDto[]>();
  const [height, setHeight] = useState<VehicleLengthDto[]>();
  const [width, setWidth] = useState<VehicleLengthDto[]>();
  const [length, setLength] = useState<VehicleLengthDto[]>();
  const dispatch = useDispatch();
  const getDestinationFunc = () => {
    dispatch(
      getPicklistCountryList({
        payload: {
          onSuccess: (message, payload) => setCountries(payload),
          onError: (message) => {},
        },
        url: "System/GetPicklistCountryList",
      })
    );
    dispatch(
      getTransportWays({
        payload: {
          onSuccess: (message, payload) => setTransports(payload),
          onError: (message) => {},
        },
        url: "System/GetTransportWays",
      })
    );
    dispatch(
      getTruckTypes({
        payload: {
          onSuccess: (message, payload) => setTypes(payload),
          onError: (message) => {},
        },
        url: "System/GetTruckType",
      })
    );
    dispatch(
      getDestinations({
        payload: {
          onSuccess: (message, payload) => setDestination(payload),
          onError: (message) => {},
        },
        url: "Profile/AccountDestinations",
      })
    );
    dispatch(
      getVehicleHeight({
        payload: {
          onSuccess: (message, payload) => setHeight(payload),
          onError: (message) => {},
        },
      })
    );
    dispatch(
      getVehicleLength({
        payload: {
          onSuccess: (message, payload) => setLength(payload),
          onError: (message) => {},
        },
      })
    );
    dispatch(
      getVehicleWidth({
        payload: {
          onSuccess: (message, payload) => setWidth(payload),
          onError: (message) => {},
        },
      })
    );
  };
  useEffect(() => {
    getDestinationFunc(); // eslint-disable-next-line
  }, [dispatch]);
  const columns = [
    {
      dataField: "from_name",
      caption: strings.getString("startingpoint"),
    },
    {
      dataField: "countryname__c",
      caption: strings.getString("destinationpoint"),
    },
    {
      dataField: "name",
      caption: strings.getString("name"),
    },
    {
      dataField: "vehicletypename",
      caption: strings.getString("vehicletype"),
    },
    {
      dataField: "transportways__c",
      caption: strings.getString("transportways"),
    },
  ];

  const buttons = [
    {
      type: GridButtonType.edit,
      icon: "dx-icon-edit",
      onClick: (e: any) => {
        setSelected(e);
      },
    },
    {
      type: GridButtonType.default,
      icon: "dx-icon-trash",
      onClick: (e: any) => {
        var confirmResp = confirm(
          strings.getString("deleteDestinationqua"),
          strings.getString("deleteDestination")
        );
        confirmResp.then((result) => {
          if (result) {
            dispatch(
              deleteDestinations({
                payload: {
                  onSuccess: (message, payload) => {
                    Alert.success("Başarıyla silindi.");
                    getDestinationFunc();
                  },
                  onError: (message) => {
                    Alert.error(message);
                  },
                },
                url: "/Profile/DeleteAccountDestination/" + e.id,
              })
            );
          }
        });
      },
    },
  ];
  return (
    <>
      <div
        style={{
          width: "100%",
          marginBlockEnd: 30,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <p className="biggerHeadingStyle">
          {strings.getString("Destinations Where The Customers works")}
        </p>
      </div>

      <ResponsiveTable
        data={destination ?? []}
        columns={columns}
        buttons={buttons}
        createForm={
          types &&
          transports && (
            <CreateAccountDestinationForm
              widthdata={width}
              lengthdata={length}
              heightdata={height}
              types={types}
              transports={transports}
              onSuccess={() => {
                Alert.success("Başarıyla eklendi.");
                getDestinationFunc();
              }}
              countries={countries ? countries : []}
            />
          )
        }
        editForm={
          selected &&
          types &&
          transports && (
            <EditAccountDestinationForm
              types={types}
              widthdata={width}
              lengthdata={length}
              heightdata={height}
              transports={transports}
              onSuccess={() => {
                Alert.success("Başarıyla güncellendi.");
                getDestinationFunc();
              }}
              selected={selected}
              countries={countries ? countries : []}
            />
          )
        }
      />
    </>
  );
};
export default AccountDestinationGrid;
