import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popover, Whisper } from "rsuite";
import { Button, Divider, Icon, Loader, Nav } from "..";
import {
  getLocalizedLoadCard,
  getLocalizedMyProfile,
} from "../../redux/selectors";
import moment from "moment";
import "./style.css";
import { LoadOpportunities, RouteObject } from "../../types";
import { getCurrency } from "../../utils/Helpers";
import ScrollBar from "react-perfect-scrollbar";

import { GoogleMap, DirectionsRenderer } from "@react-google-maps/api";
import { color, CUSTOM_MAP, mapContainerStyles } from "../../utils/Properties";
import Flag from "react-flagkit";
import { getLoadOpportunityDetailWithoutLoader } from "../../redux/actions";
type LoadCardProps = {
  isOffer?: boolean;
  useEp?: boolean;

  load: LoadOpportunities;
  component?: any;
  cancelOpportunity?: () => void;
};

const dividerPadding: number = 6;

const ProfileLoadCardMobil = ({
  load,
  component,
  useEp,
  isOffer = true,
  cancelOpportunity,
}: LoadCardProps) => {
  const [loader, setLoader] = useState(false);
  const [selected, setSelected] = useState(load);
  const dispatch = useDispatch();
  const printerRef = useRef(null);
  const strings = useSelector(getLocalizedLoadCard);
  const profilestrings = useSelector(getLocalizedMyProfile);
  const [route_directions, setRouteDirections] = useState<
    google.maps.DirectionsResult[]
  >([]); // eslint-disable-next-line
  const [show, setShow] = useState(false);

  const [path_distance, setPathDistance] = useState<string>("");
  const [path_distance_index, setPathDistanceIndex] = useState<number>(0);
  function clickableDirectionServiceResult(data: RouteObject) {
    let waypoints: google.maps.DirectionsWaypoint[] = [];
    if (data.koordinat) {
      JSON.parse(data.koordinat)?.forEach((data: any) => {
        waypoints.push({
          location: new google.maps.LatLng(data.Latitude, data.Longtitude),
          stopover: true,
        });
      });
    }
    setRouteDirections([]);
    getDirectionsServiceResult(waypoints);
  }

  const getDirectionsServiceResult = (
    waypoints?: google.maps.DirectionsWaypoint[]
  ) => {
    if (
      selected.DetailTab &&
      selected.DetailTab.ll1name &&
      selected.DetailTab.ul1name
    ) {
      const DirectionsService = new google.maps.DirectionsService();
      console.log("WAYPOINTS", waypoints);
      DirectionsService.route(
        {
          origin: selected.DetailTab.ll1name,
          destination: selected.DetailTab.ul1name,
          waypoints,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          console.log("ROUTE RESULT", result);
          if (status === google.maps.DirectionsStatus.OK) {
            setRouteDirections((rds) => [...rds, result]);
            console.log("LEGS", result.routes);
            let total_distance = 0;
            if ((result?.routes[0]?.legs?.length ?? 0) > 0) {
              result.routes[0].legs.forEach((l) => {
                total_distance += l.distance.value;
              });
            }
            if (total_distance > 0) {
              setPathDistance(Math.round(total_distance / 1000) + "km");
            } else setPathDistance("");
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    }
  };
  function decideColor(i: number) {
    switch (i) {
      case 0:
        return "blue";
      case 1:
        return "orange";
      case 2:
        return "yellow";
      case 3:
        return "black";
      case 4:
        return "pink";
      case 5:
        return "gray";

      default:
        return "red";
    }
  }

  const navContent = [
    {
      id: 0,
      icon: "list",
      activeColor: color.primary,
      label: "",
    },
    {
      id: 1,
      icon: "indicator",
      activeColor: color.primary,
      label: "",
    },
    {
      id: 2,
      icon: "shorter-list",
      activeColor: color.primary,
      label: "",
    },
    {
      id: 3,
      icon: "route",
      activeColor: color.primary,
      label: "",
    },
    {
      id: 4,
      icon: "map-pin",
      activeColor: color.primary,
      label: "",
    },
    {
      id: 5,
      icon: "more",
      activeColor: color.primary,
      label: "",
    },
  ];

  return (
    <div
      ref={printerRef}
      className="verticalLoadCard"
      style={{
        boxShadow: "unset",
        border: "1px solid",
        paddingBottom: 10,
        borderColor: "#e6e6e6",
        height: show
          ? "auto"
          : (!isOffer && cancelOpportunity && !selected.status__c) || component
          ? 490
          : 460,
        position: "relative",
      }}
    >
      {loader && <Loader />}
      <div
        className="smallRegularText"
        style={{
          width: "100%",
          paddingInline: 15,
          marginBlock: 10,
        }}
      >
        <div
          style={{
            width: "100%",
            height: "max-content",
            display: "flex",
          }}
        >
          <div
            className="vehicleLogo"
            style={{
              backgroundColor: color.accent,
              borderRadius: 10,
              marginInlineStart: -5,
              marginBlockStart: -5,
            }}
          >
            <img
              className="imageStyles"
              style={{ padding: 5, borderRadius: 12 }}
              src={
                selected.Owner
                  ? selected.Owner.profileimage
                  : selected.profileimage
              }
              alt=""
            />
          </div>
          <div
            style={{
              width: `calc(100% - 65px)`,
              height: 65,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              paddingInlineStart: 5,
              gap: 5,
            }}
          >
            <p className="mediumHeadingStyle" style={{ lineHeight: "23px" }}>
              {selected.rapidadvisorname}
            </p>
            <div
              className="smallText"
              style={{ display: "flex", alignItems: "center" }}
            >
              {strings.getString("post_end_date")}:{" "}
              <span className="smallText">
                {selected.postedat ? moment(selected.postedat).format("l") : ""}
              </span>
            </div>
          </div>
        </div>
        <Divider paddingBlock={dividerPadding} />
        <div style={{ display: "flex", paddingInline: 3 }}>
          <div
            style={{
              width: "50%",
            }}
          >
            <span className="smallBoldText" style={{ opacity: 0.5 }}>
              {strings.getString("file_number")}
            </span>
            <p>{"#" + selected.opdfilenumber}</p>
          </div>
          <div style={{ width: "50%" }}>
            <span className="smallBoldText" style={{ opacity: 0.5 }}>
              {strings.getString("load_type")}
            </span>
            <p>
              {(selected.main_load_type__c ?? "-") +
                "/" +
                (selected.loadtype ?? "-")}
            </p>
          </div>
        </div>

        <Divider paddingBlock={dividerPadding} />
        <div
          style={{
            paddingInline: 3,
          }}
        >
          <p className="smallBoldText" style={{ opacity: 0.5 }}>
            {strings.getString("route")}
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p className="biggerText">
              {selected.fromcountryid ? selected.fromcountryid : "-"}
            </p>
            <Icon
              icon="right-bold"
              color={color.textSecondary}
              size={13}
              style={{ marginInline: 5 }}
            />
            <p className="biggerText">
              {selected.tocountryid ? selected.tocountryid : "-"}
            </p>
          </div>
        </div>
        <Divider paddingBlock={dividerPadding} />
        <div style={{ display: "flex", paddingInline: 3 }}>
          <div>
            <p className="boldText" style={{ color: color.blackFifty }}>
              {strings.getString("date")}
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingBlockStart: 8,
              }}
            >
              <p>
                {selected.loadbegindate
                  ? moment(selected.loadbegindate).format("l")
                  : "-"}
              </p>
              <Icon icon="right-bold" size={13} style={{ marginInline: 3 }} />
              <p>
                {selected.loadenddate
                  ? moment(selected.loadenddate).format("l")
                  : "-"}
              </p>
            </div>
          </div>
        </div>
        <Divider paddingBlock={dividerPadding} />

        <Divider paddingBlock={dividerPadding} />
        <div style={{ display: "flex", paddingInline: 3 }}>
          <div>
            <Icon
              icon="truck"
              size={14}
              style={{ marginInlineEnd: 5, opacity: 0.5 }}
            />
            <p style={{ marginInlineEnd: 3 }}>
              {selected.trucktype} (
              {selected?.loadTrucks && selected?.loadTrucks?.length > 0
                ? parseInt(
                    selected?.loadTrucks[0]?.vehiclenumber__c.toString() ??
                      "0".toString()
                  )
                : 0}
              )
              {selected.loadTrucks &&
                selected.loadTrucks?.length > 1 &&
                strings.formatString(
                  strings.getString("more"),
                  selected.loadTrucks.length - 1
                )}
            </p>
          </div>
        </div>
        <Divider paddingBlock={dividerPadding} />
        <div className="iconGroup">
          <div className="iconGroupItem">
            <Icon icon="tonne" size={12} />
            <div style={{ textAlign: "center" }}>{selected.weight + " t"}</div>
          </div>
          <div className="iconGroupItem">
            <Icon icon="ruler" size={14} />
            {selected.cubicmeters ? selected.cubicmeters + " m³" : "-"}
          </div>
          <div className="iconGroupItem">
            <Icon icon="distance" size={17} />
            <span>{(selected.mindistance?.toFixed(0) ?? 0) + " km"}</span>
          </div>
          <div className="iconGroupItem">
            <Icon icon="box-stacked" size={15} />
            <span>
              {true //selected.pallet
                ? strings.getString("pallets")
                : strings.getString("box")}
            </span>
          </div>
        </div>

        <Divider paddingBlock={dividerPadding} />
        {!isOffer && cancelOpportunity && !selected.status__c && (
          <div
            style={{
              width: "100%",
              marginBlock: 5,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              style={{
                borderColor: color.error,
                backgroundColor: color.error,
              }}
              title={profilestrings.getString("Cancel Opportunity")}
              onClick={() => {
                cancelOpportunity();
              }}
            />
          </div>
        )}
        {component}

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            height: 44,
            width: "100%",
            borderRadius: 5,
            cursor: "pointer",
            backgroundColor: color.accent,
          }}
        >
          <Whisper
            delay={500}
            placement="top"
            trigger="hover"
            speaker={
              <Popover>
                <p>{strings.getString("details")}</p>
              </Popover>
            }
            enterable
          >
            <div
              className="extraInfosButton"
              style={{
                border: "1px solid",
                borderColor: color.borderSecondary,
                borderStartStartRadius: 5,
                borderEndStartRadius: 5,
                width: "33%",
              }}
              onClick={() => {
                if (!useEp) {
                  setShow(!show);
                } else if (show === true) {
                  setShow(!show);
                } else {
                  setLoader(true);
                  dispatch(
                    getLoadOpportunityDetailWithoutLoader({
                      payload: {
                        onSuccess: (message, payload) => {
                          payload && setSelected(payload);
                          setLoader(false);
                          setShow(!show);
                        },
                        onError: (message) => {
                          setLoader(false);
                        },
                      },
                      url: `LoadOpportunity/GetByLoadOpportunities?sfid=${selected.id}`,
                    })
                  );
                }
                setShow(!show);
              }}
            >
              <Icon icon="more" />
            </div>
          </Whisper>
        </div>
      </div>
      {show && <Divider paddingBlock={"6px 0px"} />}

      <div
        className="extraDetails  mobil-extraDetailsContent"
        style={{
          width: 223,
          paddingInline: 0,
          display: show ? "block" : "none",
          height: show ? 320 : 0,
        }}
      >
        <Nav
          justified
          content={navContent}
          className="mobilenavbarContentMenu"
          appearance={"default"}
        >
          {(activeTab) => {
            return (
              <div
                className="extraDetailsContent"
                style={{
                  border: 0,
                  height: "100%",

                  maxHeight: 300,
                  paddingInlineStart: activeTab !== 4 ? 12 : 0,
                }}
              >
                <ScrollBar>
                  {activeTab !== navContent.length - 1 && activeTab !== 4 && (
                    <div className="extraDetailsFirstTab">
                      <div
                        className="extraDetailsFirstTabFirstCol"
                        style={{ width: "100%" }}
                      >
                        {(activeTab === 0 || activeTab === undefined) && (
                          <>
                            <div className="" style={{ marginBlockEnd: 10 }}>
                              <div>
                                <p
                                  className="boldText"
                                  style={{ color: color.textSecondary }}
                                >
                                  {strings.getString("loading_area")}
                                </p>
                                <ul className="areasList smallRegularText">
                                  {selected.DetailTab &&
                                    selected.DetailTab.ll1name && (
                                      <li>{selected.DetailTab.ll1name}</li>
                                    )}
                                  {selected.DetailTab &&
                                    selected.DetailTab.ll2name && (
                                      <li>{selected.DetailTab.ll2name}</li>
                                    )}
                                  {selected.DetailTab &&
                                    selected.DetailTab.ll3name && (
                                      <li>{selected.DetailTab.ll3name}</li>
                                    )}
                                  {selected.DetailTab &&
                                    selected.DetailTab.ll4name && (
                                      <li>{selected.DetailTab.ll4name}</li>
                                    )}
                                </ul>
                              </div>
                              <div>
                                <p
                                  className="boldText"
                                  style={{ color: color.textSecondary }}
                                >
                                  {strings.getString("unloading_area")}
                                </p>
                                <ul className="areasList smallRegularText">
                                  {selected.DetailTab &&
                                    selected.DetailTab.ul1name && (
                                      <li>{selected.DetailTab.ul1name}</li>
                                    )}
                                  {selected.DetailTab &&
                                    selected.DetailTab.ul2name && (
                                      <li>{selected.DetailTab.ul2name}</li>
                                    )}
                                  {selected.DetailTab &&
                                    selected.DetailTab.ul3name && (
                                      <li>{selected.DetailTab.ul3name}</li>
                                    )}
                                  {selected.DetailTab &&
                                    selected.DetailTab.ul4name && (
                                      <li>{selected.DetailTab.ul4name}</li>
                                    )}
                                </ul>
                              </div>
                            </div>
                            <div style={{ marginBlockEnd: 10 }}>
                              <div>
                                <p
                                  className="boldText"
                                  style={{ color: color.textSecondary }}
                                >
                                  {strings.getString("customs")}
                                </p>
                                {selected.DetailTab &&
                                selected.DetailTab.fromcustoms ? (
                                  <span className="smallRegularText">
                                    {selected.DetailTab &&
                                      selected.DetailTab.fromcustoms}
                                  </span>
                                ) : (
                                  <span className="smallRegularText">-</span>
                                )}
                              </div>
                              <div>
                                <p
                                  className="boldText"
                                  style={{ color: color.textSecondary }}
                                >
                                  {strings.getString("customs")}
                                </p>
                                {selected.DetailTab &&
                                selected.DetailTab.tocustoms ? (
                                  <span className="smallRegularText">
                                    {selected.DetailTab &&
                                      selected.DetailTab.tocustoms}
                                  </span>
                                ) : (
                                  <span className="smallRegularText">-</span>
                                )}
                              </div>
                            </div>
                            <div style={{ marginBlockEnd: 10 }}>
                              <div>
                                <p
                                  className="boldText"
                                  style={{ color: color.textSecondary }}
                                >
                                  {strings.getString("loading_type")}
                                </p>
                                <span className="smallRegularText">
                                  {selected.DetailTab &&
                                  selected.DetailTab.loadintypes
                                    ? selected.DetailTab.loadintypes.replaceAll(
                                        ";",
                                        ","
                                      )
                                    : "-"}
                                </span>
                              </div>
                              <div>
                                <p
                                  className="boldText"
                                  style={{ color: color.textSecondary }}
                                >
                                  {strings.getString("unloading_type")}
                                </p>
                                <span className="smallRegularText">
                                  {selected.DetailTab &&
                                  selected.DetailTab.unloadingtypes
                                    ? selected.DetailTab.unloadingtypes.replaceAll(
                                        ";",
                                        ","
                                      )
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                        {activeTab === 1 && (
                          <>
                            <div style={{ width: "100%", marginBlockEnd: 10 }}>
                              <p
                                className="boldText"
                                style={{ color: color.textSecondary }}
                              >
                                {strings.getString("desired_arrival_date")}
                              </p>
                              <span className="smallRegularText">
                                {selected.loadenddate
                                  ? moment(selected.loadenddate).format("L")
                                  : ""}
                              </span>
                            </div>
                            <div style={{ width: "100%", marginBlockEnd: 10 }}>
                              <p
                                className="boldText"
                                style={{ color: color.textSecondary }}
                              >
                                {strings.getString("payment_conditions")}
                              </p>
                              <span className="smallRegularText">
                                {selected.ExtraTab &&
                                selected.ExtraTab
                                  .advancepaymentafterloading__c ? (
                                  <>
                                    <span>
                                      {strings.formatString(
                                        strings.getString(
                                          "paymentconditionfirstsentence"
                                        ),
                                        selected.ExtraTab.percent__c,
                                        100 - selected.ExtraTab.percent__c
                                      )}
                                    </span>
                                    {selected.ExtraTab.dayafterloading__c && (
                                      <p>
                                        {strings.formatString(
                                          strings.getString("afterloadingday"),
                                          selected.ExtraTab.day__c
                                        )}
                                      </p>
                                    )}

                                    {selected.ExtraTab.dayafterunloading__c && (
                                      <p>
                                        {strings.formatString(
                                          strings.getString(
                                            "afterunloadingday"
                                          ),
                                          selected.ExtraTab.unloadingday__c
                                        )}
                                      </p>
                                    )}

                                    {selected.ExtraTab.afterinvoicedate__c && (
                                      <p>
                                        {strings.formatString(
                                          strings.getString("afterinvoice"),
                                          selected.ExtraTab
                                            .afterinvoicedateday__c
                                        )}
                                      </p>
                                    )}

                                    {selected.ExtraTab
                                      .aftercopycmrinvoice__c && (
                                      <p>
                                        {strings.formatString(
                                          strings.getString("aftercopycmr"),
                                          selected.ExtraTab
                                            .aftercopycmrinvoiceday__c
                                        )}
                                      </p>
                                    )}
                                    {selected.ExtraTab.afteroriginalcmr__c && (
                                      <p>
                                        {strings.formatString(
                                          strings.getString("afteroriginalcmr"),
                                          selected.ExtraTab.cmrday__c
                                        )}
                                      </p>
                                    )}
                                  </>
                                ) : (
                                  <span>{strings.getString("Cash")}</span>
                                )}
                              </span>
                            </div>
                            <div style={{ width: "100%", marginBlockEnd: 10 }}>
                              <p
                                className="boldText"
                                style={{ color: color.textSecondary }}
                              >
                                {strings.getString("hsc") +
                                  " / " +
                                  strings.getString("commodity_price")}
                              </p>
                              {selected?.ExtraTab?.hsc_code__c ? (
                                <span className="smallRegularText">
                                  {selected.ExtraTab.hsc_code__c}
                                </span>
                              ) : (
                                <span className="smallRegularText">-</span>
                              )}
                              {selected?.ExtraTab?.productprice__c ? (
                                <span className="smallRegularText">
                                  /
                                  {getCurrency(
                                    selected.ExtraTab.productprice__c,
                                    selected.currencyisocode
                                  )}
                                </span>
                              ) : (
                                <span className="smallRegularText">/-</span>
                              )}
                            </div>
                            <div style={{ width: "100%", marginBlockEnd: 10 }}>
                              <p
                                className="boldText"
                                style={{ color: color.textSecondary }}
                              >
                                {strings.getString("desired_equipment")}
                              </p>
                              <span className="smallRegularText">
                                {selected.loadTrucks?.map((type, i) => (
                                  <p key={i}>{`${type.equipments ?? ""}`}</p>
                                ))}
                              </span>
                            </div>
                          </>
                        )}
                        {activeTab === 2 && (
                          <div style={{ width: "100%" }}>
                            <p
                              className="boldText"
                              style={{ color: color.textSecondary }}
                            >
                              {strings.getString("other_details")}
                            </p>
                            <div
                              className="smallRegularText"
                              style={{
                                display: "flex",
                              }}
                            >
                              <p
                                style={{
                                  marginInlineEnd: 10,
                                  color: color.textSecondary,
                                }}
                              >
                                {strings.getString("adr") + ": "}
                              </p>
                              <span>
                                {selected?.OtherTab?.adr__c ? (
                                  <>
                                    {strings.getString("yes") +
                                      " - " +
                                      selected.OtherTab.adr__c}
                                  </>
                                ) : (
                                  strings.getString("no")
                                )}
                              </span>
                            </div>
                            <div
                              className="smallRegularText"
                              style={{
                                display: "flex",
                              }}
                            >
                              <p
                                style={{
                                  marginInlineEnd: 10,
                                  color: color.textSecondary,
                                }}
                              >
                                {strings.getString("frigo") + ": "}
                              </p>
                              <span>
                                {selected?.OtherTab?.frigocelsius_ ? (
                                  <>
                                    {strings.getString("yes") +
                                      `- (${selected.OtherTab.frigocelsius_})`}
                                  </>
                                ) : (
                                  strings.getString("no")
                                )}
                              </span>
                            </div>
                            <div
                              className="smallRegularText"
                              style={{
                                display: "flex",
                              }}
                            >
                              <p
                                style={{
                                  marginInlineEnd: 10,
                                  color: color.textSecondary,
                                }}
                              >
                                {strings.getString("doctor_required") + ": "}
                              </p>
                              <span>
                                {selected?.OtherTab?.isdoctorrequired
                                  ? strings.getString("yes")
                                  : strings.getString("no")}
                              </span>
                            </div>
                            <div
                              className="smallRegularText"
                              style={{
                                display: "flex",
                              }}
                            >
                              <p
                                style={{
                                  marginInlineEnd: 10,
                                  color: color.textSecondary,
                                }}
                              >
                                {strings.getString("countrycertificate") + ": "}
                              </p>
                              <span>
                                {selected?.thirdcountrycertificate !== undefined
                                  ? selected.thirdcountryname
                                    ? strings.getString("yes")
                                    : strings.getString("no")
                                  : "-"}
                              </span>
                            </div>
                            <div
                              className="smallRegularText"
                              style={{
                                display: "flex",
                              }}
                            >
                              <p
                                style={{
                                  marginInlineEnd: 10,
                                  color: color.textSecondary,
                                }}
                              >
                                {strings.getString("countryname") + ": "}
                              </p>
                              <span>
                                {selected?.thirdcountryname
                                  ? selected?.thirdcountryname
                                  : "-"}
                              </span>
                            </div>
                            <div
                              className="smallRegularText"
                              style={{
                                display: "flex",
                              }}
                            >
                              <p
                                style={{
                                  marginInlineEnd: 10,
                                  color: color.textSecondary,
                                }}
                              >
                                {strings.getString(
                                  "trucktransitdeclarationtype"
                                ) + ": "}
                              </p>
                              <span>
                                {selected?.trucktransitdeclarationtype
                                  ? selected?.trucktransitdeclarationtype
                                  : "-"}
                              </span>
                            </div>
                            <div
                              className="smallRegularText"
                              style={{
                                display: "flex",
                              }}
                            >
                              <p
                                style={{
                                  marginInlineEnd: 10,
                                  color: color.textSecondary,
                                }}
                              >
                                {strings.getString("seconddrivercheckbox") +
                                  ": "}
                              </p>
                              <span>
                                {selected?.seconddrivercheckbox !== undefined
                                  ? selected?.seconddrivercheckbox
                                    ? strings.getString("yes")
                                    : strings.getString("no")
                                  : "-"}
                              </span>
                            </div>
                          </div>
                        )}
                        {activeTab === 3 && (
                          <>
                            <p
                              className="boldText"
                              style={{ color: color.textSecondary }}
                            >
                              {strings.getString("available_routes")}
                            </p>
                            {selected.RouteTab?.map((route, i) => (
                              <div
                                key={i}
                                className="smallRegularText"
                                style={{
                                  display: "flex",
                                }}
                              >
                                <p>{route.route}&nbsp; </p>
                                <span>
                                  {getCurrency(
                                    route.targetprice,
                                    route.currencyisocode
                                  )}
                                </span>
                                <span>
                                  &nbsp;{route.distance?.toFixed(0) ?? 0} km
                                </span>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  )}

                  {activeTab === 5 && (
                    <>
                      <div style={{ width: "100%", padding: "5px 0px" }}>
                        <p
                          className="boldText"
                          style={{ color: color.textSecondary }}
                        >
                          {strings.getString("language")}
                        </p>
                        <div
                          style={{
                            width: 100,
                            display: "flex",
                            justifyContent: "flex-start",
                            flexWrap: "wrap",
                            gap: "4",
                          }}
                        >
                          {selected?.Owner?.flags?.split(";").map((x) => {
                            return (
                              <Flag
                                style={{ marginRight: 3 }}
                                country={x === "EN" ? "US" : x}
                                title={x}
                                className="flag"
                              />
                            );
                          })}
                        </div>
                        <p
                          className="boldText"
                          style={{
                            color: color.textSecondary,
                            marginBlockEnd: 0,
                            marginBlockStart: 5,
                          }}
                        >
                          {strings.getString("contact_details")}
                        </p>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Icon
                            icon="mail"
                            size={20}
                            style={{ marginInlineEnd: 5 }}
                          />
                          <span
                            className="boldText"
                            style={{
                              textDecoration: "underline",
                              fontWeight: 600,
                            }}
                          >
                            {selected.Owner?.email}
                          </span>
                        </div>
                        <div style={{ display: "flex" }}>
                          <Icon
                            icon="mobile"
                            size={20}
                            style={{ marginInlineEnd: 5 }}
                          />
                          <span
                            className="boldText"
                            style={{ fontWeight: 600 }}
                          >
                            {selected.Owner?.mobilephone}
                          </span>
                        </div>
                        <div style={{ display: "flex" }}>
                          <Icon
                            icon="phone"
                            color={color.black}
                            size={15}
                            style={{
                              marginInlineEnd: 7,
                              marginInlineStart: 3,
                            }}
                          />
                          <span
                            className="boldText"
                            style={{ fontWeight: 600 }}
                          >
                            {selected.Owner?.phone}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                  {activeTab === 4 && (
                    <GoogleMap
                      id={`rapidMap-${selected.id}`}
                      options={{
                        //@ts-ignore
                        styles: CUSTOM_MAP,
                        disableDefaultUI: true,
                        zoomControl: true,
                        scrollwheel: false,
                      }}
                      onLoad={(map: google.maps.Map<Element>) => {
                        setRouteDirections([]);
                        setPathDistance("");

                        if (selected.RouteTab) {
                          clickableDirectionServiceResult(selected.RouteTab[0]);
                        } else {
                          getDirectionsServiceResult();
                        }
                      }}
                      mapContainerStyle={mapContainerStyles}
                    >
                      {route_directions &&
                        route_directions.map((rd, i) => (
                          <DirectionsRenderer
                            key={`route-map-${i}`}
                            directions={rd}
                            onLoad={(
                              directionsRenderer: google.maps.DirectionsRenderer
                            ) => {
                              console.log(
                                "directionsRenderer",
                                directionsRenderer
                              );
                            }}
                            options={{
                              polylineOptions: {
                                strokeColor: decideColor(path_distance_index),
                              },
                            }}
                          />
                        ))}
                      {selected.RouteTab && (
                        <div
                          style={{
                            position: "absolute",
                            left: 10,
                            top: 5,
                          }}
                        >
                          {selected.RouteTab.map((data, i) => {
                            return (
                              <div
                                style={{
                                  position: "relative",
                                  backgroundColor: decideColor(i),
                                  padding: 5,
                                  marginTop: 5,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  clickableDirectionServiceResult(data);
                                  setPathDistanceIndex(i);
                                }}
                              >
                                <span
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {data.route}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      )}

                      {path_distance !== "" && (
                        <div
                          style={{
                            position: "absolute",
                            right: 10,
                            top: 5,
                            padding: 5,
                            backgroundColor: "#ededed",
                            borderRadius: 10,
                          }}
                        >
                          <span style={{ color: "black", fontWeight: "bold" }}>
                            {path_distance}
                          </span>
                        </div>
                      )}
                    </GoogleMap>
                  )}
                </ScrollBar>
              </div>
            );
          }}
        </Nav>
      </div>
    </div>
  );
};

export default ProfileLoadCardMobil;
