import { useState } from "react";
import { AuctionDto, FilteredDataParams } from "../../redux/autions/type";
import { color } from "../../utils/Properties";
import CheckPicker from "../CheckPicker";
import "./style.scss";
const SearchTenderCheckPicker = ({
  defaultData,
  setTenders,
  field,
  label,
  tenders,
  renderData,
  searchTenders,
  setFilteredData,
  filteredData,
}: {
  defaultData?: { Id: any; Label: string }[];
  filteredData: FilteredDataParams;
  setFilteredData: (filteredData: FilteredDataParams) => void;
  searchTenders: AuctionDto[];
  renderData?: (data: any) => any;
  field: string;
  label: string;
  tenders: AuctionDto[];
  setTenders: (value: any) => void;
}) => {
  // eslint-disable-next-line
  const [data, setData] = useState(
    tenders.map((x) => {
      return {
        //@ts-ignore
        Id: x[field],
        //@ts-ignore
        Label: renderData ? renderData(x[field]) : x[field],
      };
    })
  );

  return (
    <CheckPicker
      data={
        defaultData
          ? defaultData
          : [
              //@ts-ignore
              ...new Map(
                data.map((item) =>
                  //@ts-ignore
                  [item["Id"], item]
                )
              ).values(),
            ]
      }
      valueKey="Id"
      searchable={false}
      cleanable={false}
      labelKey="Label"
      className=""
      style={{
        width: "100%",
        border: "1px solid " + color.bgSecondary,
      }}
      placeholder={label}
      placement="topStart"
      onChange={(e) => {
        let data = filteredData;
        //@ts-ignore
        data[field] = e;
        setFilteredData(data);
      }}
    />
  );
};
export default SearchTenderCheckPicker;
