import { useState } from "react";
import { AuctionPriceDto } from "../../redux/autions/type";
import CheckPicker from "../CheckPicker";
import "./style.scss";
const SearchGridButton = ({
  setSearchPrices,
  searchPrices,
  field,
  prices,
}: {
  field: string;
  prices: AuctionPriceDto[];
  searchPrices: AuctionPriceDto[];
  setSearchPrices: (value: any) => void;
}) => {
  // eslint-disable-next-line
  const [data, setData] = useState(
    prices
      //@ts-ignore
      .filter((x) => x[field] !== null)
      .map((x) => {
        return {
          //@ts-ignore
          Id: x[field],
          //@ts-ignore
          Label: x[field],
        };
      })
  );
  return (
    <div>
      <CheckPicker
        data={[
          //@ts-ignore
          ...new Map(
            data.map((item) =>
              //@ts-ignore
              [item["Id"], item]
            )
          ).values(),
        ]}
        valueKey="Id"
        searchable={false}
        labelKey="Label"
        className="datagridCheckBox"
        placeholder={"Filtre"}
        placement="topStart"
        onChange={(e) => {
          if (e.length > 0) {
            setSearchPrices(
              //@ts-ignore
              prices.filter((x) => e.some((y) => y === x[field]))
            );
          } else {
            setSearchPrices(prices);
          }
        }}
      />
    </div>
  );
};
export default SearchGridButton;
