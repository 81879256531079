import React, { useEffect, useRef, useState } from "react";
import { Alert, Col, Row } from "rsuite";
import ImportExcelModal from "./excelModal";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  getApiParams,
  getLocalizedAuction,
  getLocalizedErrors,
} from "../../../../../redux/selectors";
import {
  AuctionCounterDto,
  AuctionDetailDto,
  AuctionPriceDto,
} from "../../../../../redux/autions/type";

import { Loader } from "../../../../../components";
import {
  getAuctionDetailsPriceList,
  gettenderexcelfiletojson,
  postAuctionPriceTable,
} from "../../../../../redux/autions/saga";
import { useHistory } from "react-router-dom";
import { CheckBox } from "devextreme-react";
import SearchGridButton from "../../../../../components/SearchIcon";
import * as Yup from "yup";
import TermsModal from "../termsModal";
import { baseUrl } from "../../../../../utils/Properties";
const PriceTab = ({
  auction,
  prices,
  setPrices,
  setAuction,
  setActive,
  questionModal,
  setquestionModal,
  join,
  setJoin,
  counter,
}: {
  counter?: AuctionCounterDto;
  join: boolean;
  setJoin: (activev: boolean) => void;
  questionModal: boolean;
  setquestionModal: (activev: boolean) => void;
  setActive: (activev: string) => void;
  setAuction: (auction: AuctionDetailDto) => void;
  auction: AuctionDetailDto;
  prices: AuctionPriceDto[];
  setPrices: (prices?: AuctionPriceDto[]) => void;
}) => {
  const tableRef = useRef<any>();
  const [searchPrices, setSearchPrices] = useState(prices);
  const dispatch = useDispatch();
  const [importExcelShow, setImportExcelShow] = useState(false);
  const [showTerms, setShowTerms] = useState(false); // eslint-disable-next-line
  const [buttonVisibility, setButtonVisibility] = useState(false);
  const [loader, setLoader] = useState(false);
  const t = useSelector(getLocalizedAuction);
  const errorStrings = useSelector(getLocalizedErrors);
  const [disabled, setDisabled] = useState(
    !(auction.StartingTender && !auction.EndingTender)
  );
  const history = useHistory();
  useEffect(() => {
    setDisabled(!(auction.StartingTender && !auction.EndingTender && join));
  }, [join, auction]);

  const truckCount = (
    week: number | undefined,
    month: number | undefined,
    year: number | undefined
  ) => {
    if (week && week != null) {
      return week;
    } else if (month && month != null) {
      return month;
    } else if (year && year != null) {
      return month;
    } else {
      return 0;
    }
  };
  const truckString = (
    week: number | undefined,
    month: number | undefined,
    year: number | undefined
  ) => {
    if (week && week != null) {
      return t.getString("Weekly");
    } else if (month && month != null) {
      return t.getString("Monthly");
    } else if (year && year != null) {
      return t.getString("Yearly");
    } else {
      return "-";
    }
  };

  const { token } = useSelector(getApiParams);
  const handleOnExport = async (data: any[]) => {
    setLoader(true);
    await fetch(baseUrl + "Tenders/GetTenderExcelFile/" + auction.sfid, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.blob())
      .then((fff) => {
        //@ts-ignore
        const url = window.URL.createObjectURL(fff);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = auction.tender_no__c + ".xlsx";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        setLoader(false);
      });
  };

  return (
    <React.Fragment>
      <Row style={{ marginBlockStart: 10 }}>
        <Col md={24}>
          {loader && <Loader content={t.getString("saving")} />}
          <Formik
            initialValues={{
              search: "",
              opportunityy__c: auction.sfid,
              tenderShipperOfferModels: prices.map((x) => {
                return {
                  uniqKey: x.id,
                  destinationno: x.opdfilenumber ?? "",
                  fromcountry: x.fromcountry ?? "",
                  loading: x.loadpoint ?? "",
                  tocountry: x.tocountry ?? "",
                  unloading: x.unloadpoint,
                  loadtype: x.loadtype ?? "",
                  opportunitydestination__c: x.id ?? "",
                  dateoftransporteroffer__c: x.loadbegindate ?? "",
                  pricefromshipper__c: x.shipperofferprice ?? "",
                  currencyisocode: x.currencyisocode ?? "",
                  numberofvehiclesoftheshipper__c: truckCount(
                    x.NumberOfTrucksPerWeek__c,
                    x.NumberOfTrucksPerMonth__c,
                    x.NumberOfTrucksPerYear__c
                  ),
                  vehicleCountLabel: truckString(
                    x.NumberOfTrucksPerWeek__c,
                    x.NumberOfTrucksPerMonth__c,
                    x.NumberOfTrucksPerYear__c
                  ),
                  VehicleNumber__c: x.VehicleNumber__c ?? "",
                  vehicleTypes: x.VehicleTypeList,
                  accepted_vehicle_type__c: x.accepted_vehicle_type__c ?? "",
                  firsat_destinasyon_rota__c: "",
                  shipperofferdescription__c:
                    x.shipperofferdescription__c ?? "",
                  tender_expressfreight__c: x.expressfreight ?? "",
                  tender_expresstransittime__c: x.expresstransittime ?? "",
                  tender_transittime__c: x.transittime ?? "",
                  numberOfTrucksPerWeek__c: x.NumberOfTrucksPerWeek__c ?? 0,
                  numberOfTrucksPerMonth__c: x.NumberOfTrucksPerMonth__c ?? 0,
                  numberOfTrucksPerYear__c: x.NumberOfTrucksPerYear__c ?? 0,

                  defaultnumberOfTrucksPerWeek__c:
                    x.NumberOfTrucksPerWeek__c ?? 0,
                  defaultnumberOfTrucksPerMonth__c:
                    x.NumberOfTrucksPerMonth__c ?? 0,
                  defaultnumberOfTrucksPerYear__c:
                    x.NumberOfTrucksPerYear__c ?? 0,
                  tender_Route__c: x.tender_route__c ?? "",
                };
              }),
            }}
            onSubmit={(values: any) => {
              auction.StartingTender &&
                !auction.EndingTender &&
                dispatch(
                  postAuctionPriceTable({
                    payload: {
                      onSuccess: (m, payload) => {
                        dispatch(
                          getAuctionDetailsPriceList({
                            payload: {
                              onSuccess: (m, payload) => {
                                Alert.success(
                                  t.getString("tendersendingsuccessfully"),
                                  5000
                                );
                                setPrices(payload);
                                setButtonVisibility(false);
                                setLoader(false);
                              },
                              onError: () => {},
                            },
                            id: auction.sfid,
                          })
                        );
                      },
                      onError: () => {},
                    },
                    body: values,
                  })
                );
            }}
            validationSchema={Yup.object({
              tenderShipperOfferModels: Yup.array(
                Yup.object({
                  tender_transittime__c: Yup.number().when(
                    "pricefromshipper__c",
                    {
                      is: (pricefromshipper__c: any) => pricefromshipper__c,
                      then: Yup.number().required(
                        errorStrings.getString("required")
                      ),
                    }
                  ),
                  pricefromshipper__c: Yup.number(),
                  numberofvehiclesoftheshipper__c: Yup.number().when(
                    "pricefromshipper__c",
                    {
                      is: (pricefromshipper__c: any) => pricefromshipper__c,
                      then: Yup.number().required(
                        errorStrings.getString("required")
                      ),
                    }
                  ),
                })
              ),
            })}
          >
            {({ setFieldValue, values, errors, handleSubmit }) => {
              return (
                <>
                  <TermsModal open={showTerms} setOpen={setShowTerms} />
                  <ImportExcelModal
                    open={importExcelShow}
                    setOpen={setImportExcelShow}
                    onChangeFilesToJson={(file) => {
                      dispatch(
                        gettenderexcelfiletojson({
                          payload: {
                            onSuccess: (m, res) => {
                              if (res) {
                                const data =
                                  values.tenderShipperOfferModels.map((x) => {
                                    const current = res.find(
                                      (a) => a.uniqKey === x.uniqKey
                                    );
                                    return {
                                      uniqKey:
                                        current && current.uniqKey
                                          ? current.uniqKey
                                          : x.uniqKey,
                                      destinationno:
                                        current && current.destinationno
                                          ? current.destinationno
                                          : x.destinationno,
                                      fromcountry:
                                        current && current.fromcountry
                                          ? current.fromcountry
                                          : x.fromcountry,
                                      loading:
                                        current && current.loading
                                          ? current.loading
                                          : x.loading,
                                      tocountry:
                                        current && current.tocountry
                                          ? current.tocountry
                                          : x.tocountry,
                                      unloading:
                                        current && current.unloading
                                          ? current.unloading
                                          : x.unloading,
                                      loadtype:
                                        current && current.loadtype
                                          ? current.loadtype
                                          : x.loadtype,
                                      opportunitydestination__c:
                                        current &&
                                        current.opportunitydestination__c
                                          ? current.opportunitydestination__c
                                          : x.opportunitydestination__c,
                                      dateoftransporteroffer__c:
                                        current &&
                                        current.dateoftransporteroffer__c
                                          ? current.dateoftransporteroffer__c
                                          : x.dateoftransporteroffer__c,
                                      pricefromshipper__c:
                                        current && current.pricefromshipper__c
                                          ? current.pricefromshipper__c
                                          : x.pricefromshipper__c,
                                      currencyisocode:
                                        current && current.currencyisocode
                                          ? current.currencyisocode
                                          : x.currencyisocode,
                                      numberofvehiclesoftheshipper__c: current
                                        ? truckCount(
                                            current.numberOfTrucksPerWeek__c,
                                            current.numberOfTrucksPerMonth__c,
                                            current.numberOfTrucksPerYear__c
                                          )
                                        : x.numberofvehiclesoftheshipper__c,
                                      vehicleCountLabel:
                                        current && current.vehicleCountLabel
                                          ? current.vehicleCountLabel
                                          : x.vehicleCountLabel,
                                      VehicleNumber__c:
                                        current && current.VehicleNumber__c
                                          ? current.VehicleNumber__c
                                          : x.VehicleNumber__c,
                                      vehicleTypes: x.vehicleTypes,
                                      accepted_vehicle_type__c:
                                        current &&
                                        current.accepted_vehicle_type__c
                                          ? current.accepted_vehicle_type__c
                                          : x.accepted_vehicle_type__c,

                                      firsat_destinasyon_rota__c:
                                        current &&
                                        current.firsat_destinasyon_rota__c
                                          ? current.firsat_destinasyon_rota__c
                                          : x.firsat_destinasyon_rota__c,
                                      shipperofferdescription__c:
                                        current &&
                                        current.shipperofferdescription__c
                                          ? current.shipperofferdescription__c
                                          : x.shipperofferdescription__c,
                                      tender_expressfreight__c:
                                        current &&
                                        current.tender_expressfreight__c
                                          ? current.tender_expressfreight__c
                                          : x.tender_expressfreight__c,
                                      tender_expresstransittime__c:
                                        current &&
                                        current.tender_expresstransittime__c
                                          ? current.tender_expresstransittime__c
                                          : x.tender_expresstransittime__c,
                                      tender_transittime__c:
                                        current && current.tender_transittime__c
                                          ? current.tender_transittime__c
                                          : x.tender_transittime__c,
                                      numberOfTrucksPerWeek__c:
                                        current &&
                                        current.numberOfTrucksPerWeek__c
                                          ? current.numberOfTrucksPerWeek__c
                                          : x.numberOfTrucksPerWeek__c,
                                      numberOfTrucksPerMonth__c:
                                        current &&
                                        current.numberOfTrucksPerMonth__c
                                          ? current.numberOfTrucksPerMonth__c
                                          : x.numberOfTrucksPerMonth__c,
                                      numberOfTrucksPerYear__c:
                                        current &&
                                        current.numberOfTrucksPerYear__c
                                          ? current.numberOfTrucksPerYear__c
                                          : x.numberOfTrucksPerYear__c,

                                      defaultnumberOfTrucksPerWeek__c:
                                        current &&
                                        current.defaultnumberOfTrucksPerWeek__c
                                          ? current.defaultnumberOfTrucksPerWeek__c
                                          : x.defaultnumberOfTrucksPerWeek__c,
                                      defaultnumberOfTrucksPerMonth__c:
                                        current &&
                                        current.defaultnumberOfTrucksPerMonth__c
                                          ? current.defaultnumberOfTrucksPerMonth__c
                                          : x.defaultnumberOfTrucksPerMonth__c,
                                      defaultnumberOfTrucksPerYear__c:
                                        current &&
                                        current.defaultnumberOfTrucksPerYear__c
                                          ? current.defaultnumberOfTrucksPerYear__c
                                          : x.defaultnumberOfTrucksPerYear__c,
                                      tender_Route__c:
                                        current && current.tender_Route__c
                                          ? current.tender_Route__c
                                          : x.tender_Route__c,
                                    };
                                  });
                                setFieldValue("tenderShipperOfferModels", data);
                              }
                            },
                            onError: () => {},
                          },
                          body: {
                            opportunityy__c: auction.sfid,
                            ExcelFile: file,
                          },
                        })
                      );
                    }}
                  />
                  <Row style={{ marginBlock: "20px 10px" }}>
                    <Col md={24} style={{ paddingBottom: 10 }}>
                      <CheckBox
                        disabled={
                          !(auction.StartingTender && !auction.EndingTender)
                        }
                        value={join}
                        onValueChanged={(e) => setJoin(e.value)}
                        text={" "}
                      ></CheckBox>
                      {t.formatString(
                        t.getString("Want to join tender"),
                        <span
                          className="termsInput"
                          onClick={() => {
                            setShowTerms(true);
                          }}
                        >
                          {" "}
                          {t.getString("Terms condition")}
                        </span>
                      )}
                    </Col>
                    <Col md={4}>
                      <div
                        className={
                          "tabButton tableButtonsPosition" +
                          (disabled ? " disabled" : "")
                        }
                        onClick={() =>
                          !disabled &&
                          handleOnExport(values.tenderShipperOfferModels)
                        }
                      >
                        {t.getString("Export")}
                      </div>
                    </Col>
                    <Col md={4}>
                      <div
                        onClick={() => !disabled && setImportExcelShow(true)}
                        className={
                          "tabButton tableButtonsPosition" +
                          (disabled ? " disabled" : "")
                        }
                      >
                        {t.getString("Import")}
                      </div>
                    </Col>
                    <Col md={4}>
                      <div
                        onClick={() => !disabled && history.push("?chat=true")}
                        className={
                          "tabButton tableButtonsPosition" +
                          (disabled ? " disabled" : "")
                        }
                      >
                        {t.getString("Ask Question")}
                      </div>
                    </Col>
                    <Col md={12}>
                      <div
                        className="tableButtonsPosition"
                        style={{
                          justifyContent: "flex-end",
                          gap: 10,
                        }}
                      >
                        {t.getString("Remaining Time")} :{" "}
                        {counter ? counter.endingRemainingTime : "00:00:00"}
                        <button
                          type="submit"
                          disabled={disabled}
                          className="tablebutton"
                          onClick={() => handleSubmit()}
                        >
                          {t.getString("Save")}
                        </button>
                      </div>
                    </Col>

                    <Col md={24} style={{ textAlign: "end" }}></Col>
                  </Row>
                  <div style={{ overflow: "auto", width: "100%" }}>
                    <Table className="borderedTable" ref={tableRef}>
                      <Thead>
                        <Tr>
                          <Th>{t.getString("Destination No")}</Th>
                          <Th>{t.getString("Loading Location")}</Th>
                          <Th>{t.getString("Loading Country")}</Th>
                          <Th>{t.getString("UnLoading Location")}</Th>
                          <Th>{t.getString("Unloading Country")}</Th>
                          <Th>{t.getString("Transit Time")}</Th>
                          <Th style={{ minWidth: 100 }}>
                            {t.getString("Load Type")}
                          </Th>
                          <Th>
                            {t.getString("Navlun")}
                            <div className="xxsmallText">
                              ( {t.getString("Araç Başı")})
                            </div>
                          </Th>
                          <Th>{t.getString("Vehicle Count")}</Th>
                          <Th>{t.getString("Vehicle Types")}</Th>
                          <Th>{t.getString("Express Navlun")}</Th>
                          <Th>
                            {t.getString("Express Transit Time")}{" "}
                            <div className="xxsmallText">
                              ({t.getString("day")})
                            </div>
                          </Th>
                          <Th>{t.getString("Route")}</Th>
                          <Th>{t.getString("Comment")}</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr className="searchTableRow">
                          <Td>
                            <div className="tableInputDiv">
                              <SearchGridButton
                                field="opdfilenumber"
                                prices={prices}
                                setSearchPrices={setSearchPrices}
                                searchPrices={searchPrices}
                              />
                            </div>
                          </Td>
                          <Td>
                            <div className="tableInputDiv">
                              <SearchGridButton
                                field="loadpoint"
                                prices={prices}
                                setSearchPrices={setSearchPrices}
                                searchPrices={searchPrices}
                              />
                            </div>
                          </Td>
                          <Td>
                            <div className="tableInputDiv">
                              <SearchGridButton
                                field="fromcountry"
                                prices={prices}
                                setSearchPrices={setSearchPrices}
                                searchPrices={searchPrices}
                              />
                            </div>
                          </Td>
                          <Td>
                            <div className="tableInputDiv">
                              <SearchGridButton
                                prices={prices}
                                field="unloadpoint"
                                setSearchPrices={setSearchPrices}
                                searchPrices={searchPrices}
                              />
                            </div>
                          </Td>
                          <Td>
                            <div className="tableInputDiv">
                              <SearchGridButton
                                prices={prices}
                                field="tocountry"
                                setSearchPrices={setSearchPrices}
                                searchPrices={searchPrices}
                              />
                            </div>
                          </Td>
                          <Td></Td>
                          <Td>
                            <div className="tableInputDiv">
                              <SearchGridButton
                                prices={prices}
                                field="loadtype"
                                setSearchPrices={setSearchPrices}
                                searchPrices={searchPrices}
                              />
                            </div>
                          </Td>
                          <Td></Td>
                          <Td></Td>
                          <Td></Td>
                          <Td></Td>
                          <Td></Td>
                          <Td></Td>
                          <Td></Td>
                        </Tr>
                        {values.tenderShipperOfferModels
                          .filter((x) =>
                            searchPrices.some((y) => y.id === x.uniqKey)
                          )
                          .map((x, i) => {
                            return (
                              <Tr key={i}>
                                <Td>{x.destinationno}</Td>
                                <Td>{x.loading}</Td>
                                <Td>{x.fromcountry}</Td>
                                <Td>{x.unloading}</Td>
                                <Td>{x.tocountry}</Td>

                                <Td>
                                  <div className="tableInputDiv">
                                    <input
                                      type="number"
                                      disabled={disabled}
                                      className={
                                        "tableInput" +
                                        (errors.tenderShipperOfferModels &&
                                        errors.tenderShipperOfferModels[i] &&
                                        errors.tenderShipperOfferModels[i] //@ts-ignore
                                          .tender_transittime__c
                                          ? " tableInputError"
                                          : " ")
                                      }
                                      value={x.tender_transittime__c}
                                      onChange={(e) => {
                                        const value = e.target.value
                                          ? parseInt(e.target.value)
                                          : "";
                                        setFieldValue(
                                          `tenderShipperOfferModels[${i}]` +
                                            "tender_transittime__c",
                                          value
                                        );
                                      }}
                                      onFocus={() => setButtonVisibility(true)}
                                    />
                                  </div>
                                </Td>
                                <Td>{x.loadtype}</Td>
                                <Td>
                                  <div className="text-center xxsmallText">
                                    {x.VehicleNumber__c
                                      ? `${x.VehicleNumber__c}` +
                                        " " +
                                        t.getString("vehicle")
                                      : "-"}
                                  </div>
                                  <div className="tableInputDivForCurrency">
                                    <input
                                      type="number"
                                      disabled={disabled}
                                      onFocus={() => setButtonVisibility(true)}
                                      className={
                                        "tableInput" +
                                        (errors.tenderShipperOfferModels &&
                                        errors.tenderShipperOfferModels[i] &&
                                        errors.tenderShipperOfferModels[i] //@ts-ignore
                                          .pricefromshipper__c
                                          ? " tableInputError"
                                          : " ")
                                      }
                                      value={x.pricefromshipper__c}
                                      onChange={(e) => {
                                        const value = e.target.value
                                          ? parseInt(e.target.value)
                                          : "";
                                        setFieldValue(
                                          `tenderShipperOfferModels[${i}]` +
                                            "pricefromshipper__c",
                                          value
                                        );
                                      }}
                                    />
                                    <span className="xxsmallText">
                                      ({x.currencyisocode})
                                    </span>
                                  </div>
                                </Td>
                                <Td>
                                  <div className="text-center xxsmallText">
                                    ({x.vehicleCountLabel})
                                  </div>
                                  <div className="tableInputDiv">
                                    <input
                                      type="number"
                                      disabled={disabled}
                                      onFocus={() => setButtonVisibility(true)}
                                      className={
                                        "tableInput" +
                                        (errors.tenderShipperOfferModels &&
                                        errors.tenderShipperOfferModels[i] &&
                                        errors.tenderShipperOfferModels[i] //@ts-ignore
                                          .numberofvehiclesoftheshipper__c
                                          ? " tableInputError"
                                          : " ")
                                      }
                                      value={x.numberofvehiclesoftheshipper__c}
                                      onChange={(e) => {
                                        const value = e.target.value
                                          ? parseInt(e.target.value)
                                          : "";
                                        if (
                                          x.defaultnumberOfTrucksPerWeek__c &&
                                          x.defaultnumberOfTrucksPerWeek__c !=
                                            null
                                        ) {
                                          setFieldValue(
                                            `tenderShipperOfferModels[${i}]` +
                                              "numberOfTrucksPerWeek__c",
                                            value
                                          );
                                        } else if (
                                          x.defaultnumberOfTrucksPerMonth__c &&
                                          x.defaultnumberOfTrucksPerMonth__c !=
                                            null
                                        ) {
                                          setFieldValue(
                                            `tenderShipperOfferModels[${i}]` +
                                              "numberOfTrucksPerMonth__c",
                                            value
                                          );
                                        } else if (
                                          x.defaultnumberOfTrucksPerYear__c &&
                                          x.defaultnumberOfTrucksPerYear__c !=
                                            null
                                        ) {
                                          setFieldValue(
                                            `tenderShipperOfferModels[${i}]` +
                                              "numberOfTrucksPerYear__c",
                                            value
                                          );
                                        }
                                        setFieldValue(
                                          `tenderShipperOfferModels[${i}]` +
                                            "numberofvehiclesoftheshipper__c",
                                          value
                                        );
                                      }}
                                    />
                                  </div>
                                </Td>
                                <Td>
                                  <div className="tableInputDiv">
                                    <select
                                      disabled={disabled}
                                      style={{ minWidth: 60 }}
                                      onFocus={() => setButtonVisibility(true)}
                                      className={"tableInput"}
                                      value={x.accepted_vehicle_type__c}
                                      onChange={(e) => {
                                        setFieldValue(
                                          `tenderShipperOfferModels[${i}]` +
                                            "accepted_vehicle_type__c",
                                          e.target.value
                                        );
                                      }}
                                    >
                                      {x.vehicleTypes.map((y) => (
                                        <option value={y.id}>
                                          {y.vehicleType}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </Td>
                                <Td>
                                  <div className="tableInputDivForCurrency">
                                    <input
                                      type="number"
                                      disabled={disabled}
                                      onFocus={() => setButtonVisibility(true)}
                                      className={"tableInput"}
                                      value={x.tender_expressfreight__c}
                                      onChange={(e) => {
                                        const value = e.target.value
                                          ? parseInt(e.target.value)
                                          : "";
                                        setFieldValue(
                                          `tenderShipperOfferModels[${i}]` +
                                            "tender_expressfreight__c",
                                          value
                                        );
                                      }}
                                    />
                                    <span className="xxsmallText">
                                      ({x.currencyisocode})
                                    </span>
                                  </div>
                                </Td>
                                <Td>
                                  <div className="tableInputDivForCurrency">
                                    <input
                                      type="number"
                                      disabled={disabled}
                                      onFocus={() => setButtonVisibility(true)}
                                      className={
                                        "tableInput" +
                                        (errors.tenderShipperOfferModels &&
                                        errors.tenderShipperOfferModels[i] &&
                                        errors.tenderShipperOfferModels[i] //@ts-ignore
                                          .tender_expresstransittime__c
                                          ? " tableInputError"
                                          : " ")
                                      }
                                      value={x.tender_expresstransittime__c}
                                      onChange={(e) => {
                                        const value = e.target.value
                                          ? parseInt(e.target.value)
                                          : "";
                                        setFieldValue(
                                          `tenderShipperOfferModels[${i}]` +
                                            "tender_expresstransittime__c",
                                          value
                                        );
                                      }}
                                    />
                                  </div>
                                </Td>
                                <Td>
                                  <div className="tableInputDivForCurrency">
                                    <textarea
                                      disabled={disabled}
                                      className="tableInput tableInputTextArea"
                                      onFocus={() => setButtonVisibility(true)}
                                      value={x.tender_Route__c}
                                      onChange={(e) => {
                                        setFieldValue(
                                          `tenderShipperOfferModels[${i}]` +
                                            "tender_Route__c",
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </div>
                                </Td>
                                <Td>
                                  <div className="tableInputDiv">
                                    <textarea
                                      disabled={disabled}
                                      className="tableInput tableInputTextArea"
                                      value={x.shipperofferdescription__c}
                                      onChange={(e) => {
                                        setFieldValue(
                                          `tenderShipperOfferModels[${i}]` +
                                            "shipperofferdescription__c",
                                          e.target.value
                                        );
                                      }}
                                      onFocus={() => setButtonVisibility(true)}
                                    />
                                  </div>
                                </Td>
                              </Tr>
                            );
                          })}
                      </Tbody>
                    </Table>
                  </div>
                </>
              );
            }}
          </Formik>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default PriceTab;
