import "./style.scss";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { useEffect, useRef, useState } from "react";

export enum GridButtonType {
  "edit",
  "default",
}
interface TableButtonProps {
  icon?: any;
  title?: string;
  type: GridButtonType;
  onClick: (e: any) => void;
}
const TableExample = ({
  bordered,
  data,
  showCreate,
  createForm,
  editForm,
  columns,
  buttons,
}: {
  showCreate?: boolean;
  bordered?: boolean;
  createForm?: any;
  editForm?: any;
  columns: {
    dataField: string;
    caption: string;
    formatter?: (value: any) => void;
    lookup?: {
      data: any[];
      key: string;
      labelkey: string;
    };
  }[];
  buttons?: TableButtonProps[];
  data: any[];
}) => {
  const [visibleForm, setVisibleForm] = useState<GridButtonType>(
    GridButtonType.default
  );
  useEffect(() => {
    showCreate && setVisibleForm(GridButtonType.default);
  }, [showCreate]);
  const myRef = useRef(null);
  return (
    <>
      <div ref={myRef}></div>
      {visibleForm === GridButtonType.default && createForm}
      {visibleForm === GridButtonType.edit && (
        <div className="editFormOuterDiv">
          <button
            className="gridIconClose gridUndoButton "
            onClick={() => {
              return setVisibleForm(GridButtonType.default);
            }}
          >
            <i className="dx-icon-close" />
          </button>
          {editForm}
        </div>
      )}
      <Table className={bordered ? "borderedTable" : "customTable"}>
        <Thead>
          <Tr>
            {columns.map((x, i) => (
              <Th key={i}>{x.caption}</Th>
            ))}
            {buttons && <Th></Th>}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((x, i) => (
            <Tr key={i}>
              {columns.map((y, i) => {
                if (y.lookup) {
                  const value = y.lookup.data.find(
                    (a) => a["sfid"] === x[y.dataField]
                  );
                  return (
                    <Td key={i}>{value ? value[y.lookup.labelkey] : "-"}</Td>
                  );
                } else {
                  return (
                    <Td key={i}>
                      {y.formatter
                        ? y.formatter(x[y.dataField])
                        : x[y.dataField]}
                    </Td>
                  );
                }
              })}
              {buttons && (
                <Td style={{ maxWidth: 50 }}>
                  <div className="gridColumnButtonContainer">
                    {buttons.map((a) => (
                      <button
                        className="gridButtonItem"
                        onClick={() => {
                          if (a.type === GridButtonType.edit) {
                            //@ts-ignore
                            myRef.current.scrollIntoView();
                          }

                          setVisibleForm(a.type);
                          return a.onClick(x);
                        }}
                      >
                        {a.icon && <i className={a.icon} />}
                        {a.title && a.title}
                      </button>
                    ))}
                  </div>
                </Td>
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
};
export default TableExample;
