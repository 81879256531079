import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import "yup-phone";
import { Button, CheckPicker, Input, Modal } from "..";
import {
  getLocalizedErrors,
  getLocalizedAuth,
  languageSelector,
} from "../../redux/selectors";
import { Form, RadioGroup, Radio, Alert, ControlLabel, Row, Col } from "rsuite";
import { ButtonType } from "../Button";
import { generateCommunicationLanguagesPickerArray } from "../../utils/Helpers";
import { color } from "../../utils/Properties";
import { AccountTypeParams, RegisterParams } from "../../types";
import { register } from "../../redux/actions";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { icons } from "../../assets";

type RegistrationFormModalProps = {
  show: boolean;
  onHide: () => void;
  types: AccountTypeParams[];
  comLang: any;
};

const RegistrationFormModal = ({
  show,
  onHide,
  types,
  comLang,
}: RegistrationFormModalProps) => {
  const strings = useSelector(getLocalizedAuth);
  const errorStrings = useSelector(getLocalizedErrors);
  const [active, setActive] = useState<number>(0);
  const [accountAlready, setAccountAlready] = useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const RegistrationSchema = Yup.object().shape({
    CompanyName: Yup.string().required(errorStrings.getString("required")),
    RegisterContact: Yup.object().shape(
      {
        Password: Yup.string()
          .min(6, errorStrings.getString("password_min"))
          .required(errorStrings.getString("required")),
        ConfirmPassword: Yup.string()
          .required(errorStrings.getString("required"))
          .oneOf(
            [Yup.ref("Password")],
            errorStrings.getString("confirmpassworderror")
          ),
        FirstName: Yup.string().required(errorStrings.getString("required")),
        LastName: Yup.string().required(errorStrings.getString("required")),
        Email: Yup.string()
          .required(errorStrings.getString("required"))
          .email(errorStrings.getString("email")),
        Phone: Yup.string()
          .test("len", errorStrings.getString("required"), (val) =>
            val ? val.toString().length > 5 : false
          )
          .required(errorStrings.getString("required")),
        Languages: Yup.array()
          .of(Yup.string())
          .min(1, errorStrings.getString("required")),
      },
      [["Phone", "Email"]]
    ),
  });
  const language = useSelector(languageSelector);

  const onRegister = (values: RegisterParams) => {
    setAccountAlready(false);
    values.RegisterContact.CommunicationLanguage =
      values.RegisterContact.Languages.join(";");
    dispatch(
      register({
        params: values,
        onSuccess: (message: string) => {
          Alert.success(strings.getString("loginsuccess"), 5000);
          setActive(0);
          onHide();
          history.push("login");
        },
        onError: (message: string) => {
          if (message === "emailusedbefore") {
            setAccountAlready(true);
            Alert.error(errorStrings.getString("emailusedbefore"), 5000);
          } else {
            Alert.error(message, 5000);
          }
        },
      })
    );
  };

  return (
    <Modal
      className="register-modal"
      visible={show}
      close={() => {
        onHide();
        setActive(0);
      }}
      title={strings.getString("register")}
      size={active === 0 ? "sm" : "lg"}
      noPadding
    >
      <div className="responseive-registerFormik">
        <Formik
          initialValues={{
            accountTypeID: "",
            accountDevelopername: "",
            CompanyName: "",
            RegisterContact: {
              FirstName: "",
              LastName: "",
              Email: "",
              Password: "",
              ConfirmPassword: "",
              Phone: "",
              Languages: [],
              CommunicationLanguage: "",
            },
          }}
          validationSchema={RegistrationSchema}
          onSubmit={(values: any) => onRegister(values)}
          validator={() => ({})}
        >
          {({
            handleSubmit,
            setFieldValue,
            handleBlur,
            handleChange,
            values,
            errors,
            touched,
          }) => {
            const step = values.RegisterContact;
            const stepString = "RegisterContact";
            return (
              <Form>
                {console.log(errors)}
                {active === 0 && (
                  <div
                    className="registrationTypeDiv"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: 10,
                      gap: 10,
                    }}
                  >
                    {types
                      .filter(
                        (x) =>
                          x.developername === "LeadTransporter" ||
                          x.developername === "LeadAccount" ||
                          x.developername === "Candidate_Forwarder"
                      )
                      .map((type: AccountTypeParams) => (
                        <div
                          className={
                            "mobileRegisterStep1" +
                            (values.accountTypeID === type.sfid
                              ? " selectedAccount"
                              : "")
                          }
                          onClick={() => {
                            setActive(1);
                            setFieldValue("accountTypeID", type.sfid);
                            setFieldValue(
                              "accountDevelopername",
                              type.developername
                            );
                          }}
                        >
                          <div className="mobileRegisterStep1img">
                            <img
                              src={
                                //@ts-ignore
                                icons[type.developername]
                              }
                              alt=""
                            />
                          </div>
                          <div>{type.name}</div>
                          <div>
                            <img src={icons.arrow} alt="" />
                          </div>
                        </div>
                      ))}
                  </div>
                )}
                {active === 1 && (
                  <div
                    className=""
                    style={{
                      padding: "20px 50px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: 20,
                    }}
                  >
                    <div style={{}}>
                      <img
                        className=""
                        src={icons.registerResponsiveImage}
                        alt=""
                      />
                    </div>
                    <div
                      className="  custom-radiogroup"
                      style={{ width: "100%" }}
                    >
                      {accountAlready && (
                        <div
                          style={{
                            backgroundColor: "rgb(255, 240, 244)",
                            padding: 15,
                          }}
                        >
                          <span>
                            {errorStrings.getString("emailusedbefore")}
                          </span>
                          <br />
                          <p
                            className="boldText"
                            style={{
                              color: color.primary,
                              textDecorationLine: "underline",
                              cursor: "pointer",
                              width: "max-content",
                            }}
                            onClick={() => {
                              history.push("forgot-password");
                            }}
                          >
                            {strings.getString("forgot_password")}
                          </p>
                        </div>
                      )}
                      {values.accountDevelopername ===
                        "Candidate_Forwarder" && (
                          <RadioGroup
                            style={{
                              fontSize: 13,
                              fontWeight: 400,
                              color: "rgb(24, 24, 24)",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              marginBlockEnd: 10,
                            }}
                            name="accountTypeID"
                            onChange={(e) => (values.accountTypeID = e)}
                            onBlur={handleBlur("accountTypeID")}
                          >
                            {types
                              .filter(
                                (x) =>
                                  x.developername ===
                                  "Candidate_Forwarder_Nakliyeci" ||
                                  x.developername === "Candidate_Forwarder" ||
                                  x.developername ===
                                  "Candidate_Forwarder_Musteri"
                              )
                              .map((e) => (
                                <Radio value={e.sfid} key={e.sfid}>
                                  {strings.getString(e.developername)}
                                </Radio>
                              ))}
                          </RadioGroup>
                        )}
                      <Input
                        placeholder={strings.getString("company_name") + "*"}
                        name="companyName"
                        value={values.CompanyName}
                        onChange={handleChange(`CompanyName`)}
                        onBlur={handleBlur(`CompanyName`)}
                        error={errors.CompanyName}
                        touched={touched.CompanyName}
                      />
                      <Input
                        placeholder={
                          strings.getString("authorized_person_name") + "*"
                        }
                        name="FirstName"
                        value={step?.FirstName}
                        onChange={handleChange(`${stepString}.FirstName`)}
                        onBlur={handleBlur(`${stepString}.FirstName`)}
                        error={errors.RegisterContact?.FirstName}
                        touched={touched.RegisterContact?.FirstName}
                      />
                      <Input
                        placeholder={
                          strings.getString("authorized_person_surname") + "*"
                        }
                        name="LastName"
                        value={step?.LastName}
                        onChange={handleChange(`${stepString}.LastName`)}
                        onBlur={handleBlur(`${stepString}.LastName`)}
                        error={errors.RegisterContact?.LastName}
                        touched={touched.RegisterContact?.LastName}
                      />
                      <div
                        style={{
                          width: "100%",
                          marginBlockEnd: 13,
                        }}
                      >


                        <PhoneInput
                          countryCodeEditable={false}
                          enableSearch
                          placeholder="Telefon Numarası"
                          inputProps={{
                            name: "phone",
                            autoFocus: false,
                          }}
                          inputClass={
                            errors.RegisterContact?.Phone &&
                              touched.RegisterContact?.Phone
                              ? " errorInputbg"
                              : " "
                          }
                          containerClass={
                            errors.RegisterContact?.Phone &&
                              touched.RegisterContact?.Phone
                              ? " errorInput"
                              : " "
                          }
                          defaultErrorMessage={" "}
                          country={language}
                          inputStyle={{ width: "100%" }}
                          value={values.RegisterContact.Phone.slice(
                            2,
                            values.RegisterContact.Phone.length
                          )}
                          onChange={(phone) => {
                            const value = `00${phone}`;
                            console.log(value);
                            setFieldValue("RegisterContact.Phone", value);
                          }}
                        />
                        {touched.RegisterContact?.Phone &&
                          errors.RegisterContact &&
                          errors.RegisterContact.Phone && (
                            <p className="inputErrorStyles">
                              {errors.RegisterContact.Phone}
                            </p>
                          )}
                      </div>

                      <Input
                        placeholder={strings.getString("email") + "*"}
                        name="Email"
                        className="registerModalInputZIndex"
                        value={step?.Email}
                        onChange={handleChange(`${stepString}.Email`)}
                        onBlur={handleBlur(`${stepString}.Email`)}
                        error={errors.RegisterContact?.Email}
                        touched={touched.RegisterContact?.Email}
                      />

                      <CheckPicker
                        style={{ marginBlockEnd: 13 }}
                        customClassName="communicationCheckPicker"
                        searchable={false}
                        cleanable={false}
                        placeholder={
                          strings.getString("communication_language") + "*"
                        }
                        data={generateCommunicationLanguagesPickerArray(
                          comLang
                        )}
                        placement="autoVerticalStart"
                        name="id"
                        value={step?.Languages}
                        onChange={(e) =>
                          setFieldValue(`${stepString}.Languages`, e)
                        }
                        onBlur={handleBlur(`${stepString}.Languages`)}
                        error={errors.RegisterContact?.Languages}
                        touched={touched.RegisterContact?.Languages}
                      />
                      <Input
                        type="password"
                        className="registerModalInputZIndex"
                        placeholder={strings.getString("password") + "*"}
                        name="Password"
                        value={step?.Password}
                        onChange={handleChange(`${stepString}.Password`)}
                        onBlur={handleBlur(`${stepString}.Password`)}
                        error={errors.RegisterContact?.Password}
                        touched={touched.RegisterContact?.Password}
                      />
                      <Input
                        type="password"
                        placeholder={strings.getString("confirmpassword") + "*"}
                        name="ConfirmPassword"
                        value={step?.ConfirmPassword}
                        onChange={handleChange(`${stepString}.ConfirmPassword`)}
                        onBlur={handleBlur(`${stepString}.ConfirmPassword`)}
                        error={errors.RegisterContact?.ConfirmPassword}
                        touched={touched.RegisterContact?.ConfirmPassword}
                      />
                      <div className="" style={{ marginBlockStart: 20 }}>
                        <Button
                          type="submit"
                          title={strings.getString("submit")}
                          onClick={() => handleSubmit()}
                          style={{ width: "100%", marginBlockEnd: 10 }}
                        />
                        <Button
                          buttonType={ButtonType.basic}
                          title={strings.getString("previous")}
                          onClick={() => setActive(0)}
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>



      <div className="registerFormik">
        <Formik
          initialValues={{
            accountTypeID: "",
            accountDevelopername: "",
            CompanyName: "",
            RegisterContact: {
              FirstName: "",
              LastName: "",
              Email: "",
              Phone: "",
              Languages: [],
              Password: "",
              ConfirmPassword: "",
              CommunicationLanguage: "",
            },
          }}
          validationSchema={RegistrationSchema}
          onSubmit={(values: any) => onRegister(values)}
          validator={() => ({})}
        >
          {({
            handleSubmit,
            setFieldValue,
            handleBlur,
            handleChange,
            values,
            errors,
            touched,
          }) => {
            const step = values.RegisterContact;
            const stepString = "RegisterContact";
            return (
              <Form>
                {console.log(errors)}
                {active === 0 && (
                  <div
                    className="registrationTypeDiv"
                    style={{
                      display: "flex",
                    }}
                  >
                    {types
                      .filter(
                        (x) =>
                          x.developername === "LeadTransporter" ||
                          x.developername === "LeadAccount" ||
                          x.developername === "Candidate_Forwarder"
                      )
                      .map((type: AccountTypeParams) => (
                        <div
                          key={type.id}
                          className="registrationTypeInnerDiv"
                          onClick={() => {
                            setActive(1);
                            setFieldValue("accountTypeID", type.sfid);
                            setFieldValue(
                              "accountDevelopername",
                              type.developername
                            );
                          }}
                        >
                          <div
                            className={`registrationType registrationType${type.developername}`}
                          />
                          <div className="registrationTypeNameDiv">
                            <span
                              style={{
                                color: color.primary,
                                fontSize: 12,
                                fontWeight: 700,
                              }}
                            >
                              {strings.getString("for")}
                            </span>
                            <div
                              className="registrationTypeName"
                              style={{
                                backgroundColor: color.primary,
                                color: color.textAccent,
                              }}
                            >
                              {strings
                                .getString(type.developername)
                                .toLocaleUpperCase()}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
                {active === 1 && (
                  <div className="dividingDiv">
                    <div
                      className={`halfWidth registrationFromImage registrationFromImage${values.accountDevelopername}`}
                    />
                    <div className="halfWidth registrationFormDiv custom-radiogroup">
                      {accountAlready && (
                        <div
                          style={{
                            backgroundColor: "rgb(255, 240, 244)",
                            padding: 15,
                          }}
                        >
                          <span>
                            {errorStrings.getString("emailusedbefore")}
                          </span>
                          <br />
                          <p
                            className="boldText"
                            style={{
                              color: color.primary,
                              textDecorationLine: "underline",
                              cursor: "pointer",
                              width: "max-content",
                            }}
                            onClick={() => {
                              history.push("forgot-password");
                            }}
                          >
                            {strings.getString("forgot_password")}
                          </p>
                        </div>
                      )}
                      {values.accountDevelopername ===
                        "Candidate_Forwarder" && (
                          <RadioGroup
                            style={{
                              fontSize: 13,
                              fontWeight: 400,
                              color: "rgb(24, 24, 24)",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              marginBlockEnd: 10,
                            }}
                            name="accountTypeID"
                            onChange={(e) => (values.accountTypeID = e)}
                            onBlur={handleBlur("accountTypeID")}
                          >
                            {types
                              .filter(
                                (x) =>
                                  x.developername ===
                                  "Candidate_Forwarder_Nakliyeci" ||
                                  x.developername === "Candidate_Forwarder" ||
                                  x.developername ===
                                  "Candidate_Forwarder_Musteri"
                              )
                              .map((e) => (
                                <Radio value={e.sfid} key={e.sfid}>
                                  {strings.getString(e.developername)}
                                </Radio>
                              ))}
                          </RadioGroup>
                        )}
                      <Input
                        label={strings.getString("company_name") + "*"}
                        name="companyName"
                        value={values.CompanyName}
                        onChange={handleChange(`CompanyName`)}
                        onBlur={handleBlur(`CompanyName`)}
                        error={errors.CompanyName}
                        touched={touched.CompanyName}
                      />
                      <Row>
                        <Col md={12}>
                          <Input
                            label={
                              strings.getString("authorized_person_name") + "*"
                            }
                            name="FirstName"
                            value={step?.FirstName}
                            onChange={handleChange(`${stepString}.FirstName`)}
                            onBlur={handleBlur(`${stepString}.FirstName`)}
                            error={errors.RegisterContact?.FirstName}
                            touched={touched.RegisterContact?.FirstName}
                          />
                        </Col>
                        <Col md={12}>
                          <Input
                            label={
                              strings.getString("authorized_person_surname") +
                              "*"
                            }
                            name="LastName"
                            value={step?.LastName}
                            onChange={handleChange(`${stepString}.LastName`)}
                            onBlur={handleBlur(`${stepString}.LastName`)}
                            error={errors.RegisterContact?.LastName}
                            touched={touched.RegisterContact?.LastName}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={24}>
                          <div
                            style={{ width: "100%", marginBlockEnd: 13 }}
                            className="defaultInputRes"
                          >
                            <ControlLabel
                              style={{
                                paddingBlockStart: 2,
                                marginBlockEnd: 4,
                                fontSize: 13,
                                fontWeight: 400,
                                color: color.textPrimary,
                              }}
                            >
                              {strings.getString("phone") + "*"}
                            </ControlLabel>
                            <PhoneInput
                              countryCodeEditable={false}
                              enableSearch
                              inputProps={{
                                name: "phone",
                                autoFocus: false,
                              }}
                              inputClass={
                                errors.RegisterContact?.Phone &&
                                  touched.RegisterContact?.Phone
                                  ? " errorInputbg"
                                  : " "
                              }
                              containerClass={
                                errors.RegisterContact?.Phone &&
                                  touched.RegisterContact?.Phone
                                  ? " errorInput"
                                  : " "
                              }
                              defaultErrorMessage={" "}
                              country={language}
                              inputStyle={{ width: "100%" }}
                              value={values.RegisterContact.Phone.slice(
                                2,
                                values.RegisterContact.Phone.length
                              )}
                              onChange={(phone) => {
                                const value = `00${phone}`;
                                console.log(value);
                                setFieldValue("RegisterContact.Phone", value);
                              }}
                            />
                            {touched.RegisterContact?.Phone &&
                              errors.RegisterContact &&
                              errors.RegisterContact.Phone && (
                                <p className="inputErrorStyles">
                                  {errors.RegisterContact.Phone}
                                </p>
                              )}
                          </div>
                        </Col>
                      </Row>

                      <Input
                        label={strings.getString("email") + "*"}
                        name="Email"
                        className="registerModalInputZIndex"
                        value={step?.Email}
                        onChange={handleChange(`${stepString}.Email`)}
                        onBlur={handleBlur(`${stepString}.Email`)}
                        error={errors.RegisterContact?.Email}
                        touched={touched.RegisterContact?.Email}
                      />

                      <CheckPicker
                        customClassName="communicationCheckPicker"
                        searchable={false}
                        cleanable={false}
                        label={
                          strings.getString("communication_language") + "*"
                        }
                        data={generateCommunicationLanguagesPickerArray(
                          comLang
                        )}
                        placement="autoVerticalStart"
                        name="id"
                        value={step?.Languages}
                        onChange={(e) =>
                          setFieldValue(`${stepString}.Languages`, e)
                        }
                        onBlur={handleBlur(`${stepString}.Languages`)}
                        error={errors.RegisterContact?.Languages}
                        touched={touched.RegisterContact?.Languages}
                      />
                      <Row style={{ marginBlockStart: 13 }}>
                        <Col md={12}>
                          <Input
                            type="password"
                            label={strings.getString("password") + "*"}
                            name="Password"
                            className="registerModalInputZIndex"
                            value={step?.Password}
                            onChange={handleChange(`${stepString}.Password`)}
                            onBlur={handleBlur(`${stepString}.Password`)}
                            error={errors.RegisterContact?.Password}
                            touched={touched.RegisterContact?.Password}
                          />
                        </Col>
                        <Col md={12}>
                          <Input
                            type="password"
                            label={strings.getString("confirmpassword") + "*"}
                            name="ConfirmPassword"
                            value={step?.ConfirmPassword}
                            onChange={handleChange(
                              `${stepString}.ConfirmPassword`
                            )}
                            onBlur={handleBlur(`${stepString}.ConfirmPassword`)}
                            error={errors.RegisterContact?.ConfirmPassword}
                            touched={touched.RegisterContact?.ConfirmPassword}
                          />
                        </Col>
                      </Row>
                      <div
                        className="dividedRow registerButtonGroup"
                        style={{ marginBlockStart: 20 }}
                      >
                        <Button
                          buttonType={ButtonType.basic}
                          title={strings.getString("previous")}
                          onClick={() => setActive(0)}
                          style={{ width: 100 }}
                        />
                        <Button
                          type="submit"
                          title={strings.getString("submit")}
                          onClick={() => handleSubmit()}
                          style={{ width: 100 }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default RegistrationFormModal;
