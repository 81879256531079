import { Formik } from "formik";
import { useSelector } from "react-redux";
import { Input, SelectPicker } from "../../../../../components";
import * as Yup from "yup";
import {
  getLocalizedErrors,
  getLocalizedLoadRegistration,
} from "../../../../../redux/selectors";
import { InputType } from "../../../../../components/Input";
import { RouteDto } from "../../../../../redux/opportunity/type";
import { Col, Row } from "rsuite";
const EditLocation = ({
  show,
  data,
  onHide,
  types,
  baseValues,
  errors,
  touched,
  onSubmit,
}: {
  data: {
    no: string;
    Guzergah__c: string;
    CustomersWantsPrice__c: any;
    Currencyiscode___: string;
    Guzergah__c__Name: string;
  };
  onSubmit: (values: {
    no: string;
    Guzergah__c: string;
    CustomersWantsPrice__c: any;
  }) => void;
  types: RouteDto[];
  onHide: () => void;
  show: boolean;
  baseValues: any;
  errors: any;
  touched: any;
}) => {
  // eslint-disable-next-line
  const strings = useSelector(getLocalizedLoadRegistration);
  const errorStrings = useSelector(getLocalizedErrors);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        no: data.no,
        Guzergah__c: data.Guzergah__c,
        Guzergah__c__Name: data.Guzergah__c__Name,
        CustomersWantsPrice__c: data.CustomersWantsPrice__c,
      }}
      validationSchema={Yup.object().shape({
        Guzergah__c: Yup.string().required(errorStrings.getString("required")),
      })}
      onSubmit={(values, { resetForm }) => {
        return onSubmit(values);
      }}
    >
      {({
        handleChange,
        handleSubmit,
        handleBlur,
        errors,
        touched,
        values,
      }) => (
        <Row>
          <Col md={12}>
            <SelectPicker
              placement="bottomStart"
              cleanable={false}
              searchable={false}
              labelKey={"name"}
              valueKey={"sfid"}
              style={{ marginBlockEnd: 10 }}
              label={strings.getString("Route")}
              placeholder={strings.getString("Route")}
              data={
                baseValues.guzergahList__c.filter((x: any) => x.no !== data.no)
                  .length > 0
                  ? types.filter(
                      (x) =>
                        !baseValues.guzergahList__c
                          .filter((x: any) => x.no !== data.no)
                          .some((y: any) => y.Guzergah__c === x.sfid)
                    )
                  : types
              }
              name="from__c"
              value={values.Guzergah__c}
              onSelect={(value, item) => {
                //@ts-ignore
                item && handleChange("Guzergah__c__Name")(item.name);
                return handleChange("Guzergah__c")(value);
              }}
              onBlur={handleBlur("Guzergah__c")}
              error={errors.Guzergah__c}
              touched={touched.Guzergah__c}
            />
          </Col>
          <Col md={11}>
            {" "}
            <Input
              inputType={InputType.number}
              value={values.CustomersWantsPrice__c}
              label={strings.getString("Requested Price")}
              name="CustomersWantsPrice__c"
              onChange={handleChange("CustomersWantsPrice__c")}
              onBlur={handleBlur("CustomersWantsPrice__c")}
              //@ts-ignore
              error={errors.CustomersWantsPrice__c}
              //@ts-ignore
              touched={touched.CustomersWantsPrice__c}
            />
          </Col>
          <Col md={1}>
            <div className="smResponsiveButtonDiv gridPosition">
              <button
                type="submit"
                className="gridIcon "
                onClick={() => handleSubmit()}
              >
                <i className="dx-icon-save" style={{ color: "white" }} />
              </button>
            </div>
          </Col>
        </Row>
      )}
    </Formik>
  );
};
export default EditLocation;
