import { useEffect } from "react";
import { useFormikContext } from "formik";

type FocusErrorProps = {
  stepForm?: boolean;
};

const FocusError = ({ stepForm }: FocusErrorProps) => {
  const { errors, isSubmitting, isValidating } = useFormikContext();

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      let keys = Object.keys(errors);
      if (stepForm) {
        if (keys && keys.length > 0) {
          const selector = `[name=${
            //@ts-ignore
            Object.keys(errors[Object.keys(errors)[0]])[0]
          }]`;
          const errorElement = document.querySelector(selector) as HTMLElement;
          if (errorElement) {
            errorElement.focus();
          }
        }
      } else {
        if (keys.length > 0) {
          const selector = `[name=${keys[0]}]`;
          const errorElement = document.querySelector(selector) as HTMLElement;
          if (errorElement) {
            errorElement.focus();
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, isSubmitting, isValidating]);

  return null;
};

export default FocusError;
