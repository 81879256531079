import { useState } from "react";
import { useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { StepProps } from ".";
import { getLocalizedTruckRegistration } from "../../../../redux/selectors";
import CreateLocation from "./comp/createLocation";
import { confirm } from "devextreme/ui/dialog";
import { RouteDto } from "../../../../redux/opportunity/type";
import { GetRoutes } from "../../../../redux/opportunity/saga";
import EditLocation from "./comp/editLocation";
import ResponsiveTable, {
  GridButtonType,
} from "../../../../components/ResponsiveTable";
import { generateUUID } from "../../../../utils/Properties";

const Step2 = ({ values, errors, touched }: StepProps) => {
  const strings = useSelector(getLocalizedTruckRegistration);
  const { setFieldValue } = useFormikContext();
  const [showCreate, setShowCreate] = useState(false);
  const [routes, setRoutes] = useState<RouteDto[]>([]);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState<{
    destinationId: string;
    no: string;
    Guzergah__c: string;
    destinationName: string;
    Guzergah__c_name: string;
    CustomersWantsPrice__c: any;
  }>();
  const [showEdit, setShowEdit] = useState(false);

  const columns = [
    {
      dataField: "destinationName",
      caption: strings.getString("destinationpoint"),
    },
    {
      dataField: "Guzergah__c_name",
      caption: strings.getString("Route"),
    },
    {
      dataField: "CustomersWantsPrice__c",
      caption: strings.getString("Requested Price"),
    },
  ];
  const buttons = [
    {
      type: GridButtonType.edit,
      icon: "dx-icon-edit",
      onClick: (e: any) => {
        setSelected(e);
        return setShowEdit(true);
      },
    },
    {
      type: GridButtonType.default,
      icon: "dx-icon-trash",
      onClick: (e: any) => {
        var confirmResp = confirm(
          strings.getString("Are you sure delete this route"),

          strings.getString("Delete Route")
        );
        confirmResp.then((result) => {
          if (result) {
            const last = values.guzergahList__c.filter(
              (x: any) => x.no !== e.no
            );
            return setFieldValue("guzergahList__c", last);
          }
        });
      },
    },
  ];
  return (
    <div style={{ marginBlock: "5rem" }}>
      <p
        className="newBoldHeadingStyle"
        style={{ marginBlockEnd: 18, textAlign: "center" }}
      >
        {strings.getString("3 Route")}
      </p>

      <ResponsiveTable
        bordered
        data={values.guzergahList__c}
        columns={columns}
        buttons={buttons}
        createForm={
          <div className="editFormOuterDiv">
            <CreateLocation
              types={routes}
              show={showCreate}
              baseValues={values}
              errors={errors}
              touched={touched}
              onSelectDestination={(fromcode, tocode) => {
                dispatch(
                  GetRoutes({
                    payload: {
                      onSuccess: (message, payload) =>
                        payload && setRoutes(payload),
                      onError: () => {},
                    },
                    url: `System/GetRoutesFilter?From=${fromcode}&To=${tocode}`,
                  })
                );
              }}
              onHide={() => setShowCreate(false)}
              onSubmit={(formitems, routes) => {
                let last = values.guzergahList__c;
                formitems.Guzergah__c.map((x: string) => {
                  const route = routes.find((y) => y.sfid === x);
                  return (last = [
                    ...last,
                    {
                      destinationId: formitems.destinationId,
                      destinationName: formitems.destinationName,
                      no: generateUUID(),
                      Guzergah__c_name: route ? route.name : x,
                      Guzergah__c: x,
                      CustomersWantsPrice__c: formitems.CustomersWantsPrice__c,
                    },
                  ]);
                });
                setFieldValue("guzergahList__c", [...last]);
                return setShowCreate(false);
              }}
            />
          </div>
        }
        editForm={
          selected && (
            <EditLocation
              data={selected}
              types={routes}
              show={showEdit}
              onSelectDestination={(fromcode, tocode) => {
                dispatch(
                  GetRoutes({
                    payload: {
                      onSuccess: (message, payload) =>
                        payload && setRoutes(payload),
                      onError: () => {},
                    },
                    url: `System/GetRoutesFilter?From=${fromcode}&To=${tocode}`,
                  })
                );
              }}
              baseValues={values}
              errors={errors}
              touched={touched}
              onHide={() => setShowEdit(false)}
              onSubmit={(value) => {
                const last = values.guzergahList__c.filter(
                  (x: any) => x.no !== value.no
                );
                setFieldValue("guzergahList__c", [...last, value]);
                return setShowEdit(false);
              }}
            />
          )
        }
      />
    </div>
  );
};

export default Step2;
