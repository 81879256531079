import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider } from "rsuite";
import { getTruckTypes } from "../../../../redux/actions";
import { StepProps } from ".";
import { Input, TruckSelector } from "../../../../components";
import { TruckTypeDto } from "../../../../redux/profile/type";
import { getLocalizedLoadRegistration } from "../../../../redux/selectors";
import { color } from "../../../../utils/Properties";
const Step1 = ({ values, errors, touched }: StepProps) => {
  const strings = useSelector(getLocalizedLoadRegistration);

  const [types, setTypes] = useState<TruckTypeDto[]>();
  const dispatch = useDispatch();
  const { handleBlur, handleChange, setFieldValue } = useFormikContext();
  useEffect(() => {
    dispatch(
      getTruckTypes({
        payload: {
          onSuccess: (message, payload) => setTypes(payload),
          onError: (message) => {},
        },
        url: "System/GetTruckType",
      })
    );
  }, [dispatch]);
  const loadstrings = useSelector(getLocalizedLoadRegistration);

  return (
    <>
      <div>
        <p
          className="formLabel"
          style={{
            fontSize: 13,
            marginBlockEnd: 2,
            color: color.textPrimary,
          }}
        >
          {loadstrings.getString("notes")}
        </p>
        <div style={{ width: "100%" }}>
          <Input
            componentClass="textarea"
            rows={3}
            name="Description__c"
            value={values.Description__c}
            onChange={handleChange("Description__c")}
            onBlur={handleBlur("Description__c")}
          />
        </div>
      </div>
      <p
        className="newBoldHeadingStyle"
        style={{ marginBlock: "40px 18px", textAlign: "center" }}
      >
        {strings.getString("2 Vehicle Information")}
      </p>
      <TruckSelector
        vehicletypes={types}
        selectedTrucks={values.vehicleList__c}
        setSelectedTrucks={(selectedTrucks) => {
          setFieldValue("vehicleList__c", selectedTrucks);
        }}
      />
      {errors.vehicleList__c && (
        <p className="inputErrorStyles">{errors.vehicleList__c}</p>
      )}
      <Divider />
    </>
  );
};

export default Step1;
