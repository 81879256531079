import produce from "immer";
import * as A from "../actions/systemActions";
import { SystemReducer } from "../../types";

const initialState: SystemReducer = {
  headerbar: { left: [], right: [] },
  navbar: [],
  contactRoles: undefined,
  languages: undefined,
  language: "tr",
  communicationLanguages: {
    id: "Türkçe",
    description: "Türkçe",
    parentId: undefined,
    key: "TR",
  },
};
const settingsReducer = produce((draft: SystemReducer, action) => {
  switch (action.type) {
    case A.setHeaderbarMenu.toString():
      draft.headerbar = action.payload;
      break;
    case A.setNavbarMenu.toString():
      draft.navbar = action.payload;
      break;
    case A.setContactRoles.toString():
      draft.contactRoles = action.payload;
      break;
    case A.setLanguages.toString():
      draft.languages = action.payload;
      break;
    case A.setLanguage.toString():
      draft.language = action.payload;
      break;
  }
}, initialState);

export default settingsReducer;
