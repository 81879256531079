import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Nav, Panel, PanelGroup } from "rsuite";
import {
  GetApprovalLoadOfferLists,
  GetClosedApprovalLoadOfferLists,
  getReasonForTransporterDisapproval,
} from "../../../../../../redux/profile/profileSaga";
import {
  ApprovalCustomerOfferDto,
  DisapprovalReasonDto,
} from "../../../../../../redux/profile/type";
import { getLocalizedMyProfile } from "../../../../../../redux/selectors";
import { color } from "../../../../../../utils/Properties";
import ApprovalOfferCardCustomer from "./accept-offer-card-customer";

const AcceptOfferCustomer = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState<ApprovalCustomerOfferDto[]>();
  const [reasons, setReasons] = useState<DisapprovalReasonDto[]>();
  const strings = useSelector(getLocalizedMyProfile);
  const location = useLocation();
  const offerId = new URLSearchParams(location.search).get("accept-offer");
  const [active, setActive] = useState<string>(offerId ?? "");
  const [activeTab, setActiveTab] = useState<string>("open");

  useEffect(() => {
    dispatch(
      getReasonForTransporterDisapproval({
        payload: {
          onSuccess: (message, payload) => {
            setReasons(payload);
          },
          onError: (message) => {},
        },
      })
    );
  }, [dispatch]);
  useEffect(() => {
    if (activeTab === "open") {
      dispatch(
        GetApprovalLoadOfferLists({
          payload: {
            onSuccess: (message, payload) => {
              setData(payload);
            },
            onError: (message) => {},
          },
        })
      );
    }

    if (activeTab === "closed") {
      dispatch(
        GetClosedApprovalLoadOfferLists({
          payload: {
            onSuccess: (message, payload) => {
              setData(payload);
            },
            onError: (message) => {},
          },
        })
      );
    }
  }, [dispatch, activeTab]);
  const getData = () => {
    dispatch(
      GetApprovalLoadOfferLists({
        payload: {
          onSuccess: (message, payload) => {
            setData(payload);
          },
          onError: (message) => {},
        },
      })
    );
  };
  return (
    <>
      <div
        className="my-profile-card"
        style={{ backgroundColor: color.accent }}
      >
        <p className="newBoldHeadingStyle" style={{ marginBlockEnd: 20 }}>
          {strings.getString("Load Offer List")}
        </p>
        <Nav
          className="responsive-nav"
          appearance="tabs"
          activeKey={activeTab}
          onSelect={setActiveTab}
        >
          <Nav.Item
            eventKey="open"
            className="aboutus-menu-item responsive-navitem"
            onClick={() => {}}
          >
            {strings.getString("Open Offer")}
          </Nav.Item>
          <Nav.Item
            eventKey="closed"
            className="aboutus-menu-item responsive-navitem"
            onClick={() => {}}
          >
            {strings.getString("Closed Offer")}
          </Nav.Item>
        </Nav>
        <div>
          <PanelGroup
            accordion
            bordered
            onSelect={(eventKey) => {
              eventKey === active ? setActive("") : setActive(eventKey);
            }}
            activeKey={active}
          >
            {data?.map((x, i) => {
              return (
                <Panel
                  eventKey={x.sfid}
                  header={
                    strings.getString("Load File Number") +
                    " : " +
                    x.Load.opdfilenumber +
                    " / " +
                    strings.getString("Truck File Number") +
                    " : " +
                    x.Truck.filenumber
                  }
                  className="boldText"
                >
                  <ApprovalOfferCardCustomer
                    data={x}
                    reasons={reasons}
                    getData={getData}
                  />
                </Panel>
              );
            })}
          </PanelGroup>
        </div>
      </div>
    </>
  );
};
export default AcceptOfferCustomer;
