export const accountApproved = [
  "0127a000001D63BAAS",
  "0127a000001D63GAAS",
  "0127a000001D63LAAS",
  "0121t000000hb1aAAA",
  "0121t000000kEVKAA2",
];

//KAMYON ARA / YÜKÜM VAR
export const truckUserAuth = [
  "0121t000000kEVKAA2", //ADAY MÜŞTERİ
  "0121t000000kEVPAA2", //MÜŞTERİ
  "0126N000000TS6OQAW", //ADAY FORWARDER MÜŞTERİ
  "0126N000000oPpEQAU", //FORWARDER MÜŞTERİ
  "0126N000000TS6TQAW", //ADAY FORWARDER
  "0121t000000M6ZqAAK", //FORWARDER
];

//YÜK ARA / KAMYONUM VAR
export const loadUserAuth = [
  "0121t000000hb1aAAA", //ADAY NAKLİYECİ
  "0121t000000kEoRAAU", //NAKLİYECİ
  "0126N00000006YiQAI", //ADAY FORWARDER NAKLİYECİ
  "0126N000000oPpJQAU", //FORWARDER_NAKLİYECİ
  "0126N000000TS6TQAW", //ADAY FORWARDER
  "0121t000000M6ZqAAK", //FORWARDER
];

//AUCTION
export const auctionAuth = [
  "0121t000000kEoRAAU", //NAKLİYECİ
  "0121t000000hb1aAAA", //ADAY NAKLİYECİ
  "0121t000000M6ZqAAK", //FORWARDER
  "0126N000000TS6TQAW", //ADAY FORWARDER
  "0126N000000oPpJQAU", //FORWARDER_NAKLİYECİ
  "0126N00000006YiQAI", //ADAY FORWARDER NAKLİYECİ
];
