import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "rsuite";
import * as Yup from "yup";
import { Helmet } from "react-helmet";
import { getLocalizedHelmet } from "../../../redux/selectors";
import { Button, Icon, Input } from "../../../components";
import { createContactUsForm } from "../../../redux/profile/profileSaga";
import {
  getLocalizedErrors,
  getLocalizedMenuStrings,
  getLocalizedMyProfile,
} from "../../../redux/selectors";
const ContactUs = () => {
  const helmetStrings = useSelector(getLocalizedHelmet);
  const strings = useSelector(getLocalizedMyProfile);
  const menustrings = useSelector(getLocalizedMenuStrings);
  const errorStrings = useSelector(getLocalizedErrors);
  const dispatch = useDispatch();
  const statusSchema = Yup.object().shape({
    contact_us_firstname__c: Yup.string().required("Zorunlu"),
    contact_us_lastname__c: Yup.string().required("Zorunlu"),
    contact_us_email__c: Yup.string()
      .email(errorStrings.getString("email"))
      .required("Zorunlu"),
    contact_us_mobile__c: Yup.string().required("Zorunlu"),
    contact_us_message__c: Yup.string().required("Zorunlu"),
  });
  const submitForm = (values: any, { resetForm }: { resetForm: any }) => {
    dispatch(
      createContactUsForm({
        payload: {
          onSuccess: () => {
            resetForm();
            Alert.success(strings.getString("Your form sending successfully"));
          },
          onError: (message) => {
            Alert.success(message);
          },
        },
        body: values,
      })
    );
  };
  return (
    <>
      <Helmet>
        <title>{helmetStrings.getString("ContactUsHelmet")}</title>
      </Helmet>
      <div
        style={{
          width: "100%",
        }}
      >
        <h1 className="h2 contact-us-title" style={{ marginBlockEnd: 10 }}>
          {menustrings.getString("contact_us")}
        </h1>
        <div
          className="contact-us-content-outer-div"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            className="contact-info"
            style={{ width: "45%", marginBlockStart: 10 }}
          >
            <p>
              <Icon icon="phone-2" size={18} style={{ marginInlineEnd: 15 }} />
              +90 212 252 38 33
            </p>
            <p>
              <Icon icon="email" size={18} style={{ marginInlineEnd: 15 }} />
              rapid@rapid.com.tr
            </p>
            <p style={{ marginBlockEnd: 10 }}>
              <Icon icon="clock" size={18} style={{ marginInlineEnd: 15 }} />
              Kemeraltı Cad. Kitapçıbaşı İş Merkezi No:27 Kat: 6-7-8 34425
              Karaköy – İstanbul / TÜRKİYE
            </p>
          </div>
          <div className="contact-form" style={{ width: "45%" }}>
            <Formik
              initialValues={{
                contact_us_firstname__c: "",
                contact_us_lastname__c: "",
                contact_us_email__c: "",
                contact_us_mobile__c: "",
                contact_us_message__c: "",
              }}
              validationSchema={statusSchema}
              onSubmit={submitForm}
            >
              {({
                errors,
                touched,
                values,
                setFieldValue,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <>
                  <div>
                    <div className="contact-row">
                      <div className="contact-input">
                        <Input
                          value={values.contact_us_firstname__c}
                          placeholder={strings.getString("name")}
                          name="contact_us_firstname__c"
                          onChange={handleChange("contact_us_firstname__c")}
                          onBlur={handleBlur("contact_us_firstname__c")}
                          error={errors.contact_us_firstname__c}
                          touched={touched.contact_us_firstname__c}
                        />
                      </div>
                      <div className="contact-input">
                        <Input
                          value={values.contact_us_lastname__c}
                          className="contact-input"
                          placeholder={strings.getString("lastname")}
                          name="contact_us_lastname__c"
                          onChange={handleChange("contact_us_lastname__c")}
                          onBlur={handleBlur("contact_us_lastname__c")}
                          error={errors.contact_us_lastname__c}
                          touched={touched.contact_us_lastname__c}
                        />
                      </div>
                    </div>
                    <div className="contact-row">
                      <div className="contact-input">
                        <Input
                          value={values.contact_us_email__c}
                          className="contact-input"
                          placeholder={strings.getString("email")}
                          name="contact_us_email__c"
                          onChange={handleChange("contact_us_email__c")}
                          onBlur={handleBlur("contact_us_email__c")}
                          error={errors.contact_us_email__c}
                          touched={touched.contact_us_email__c}
                        />
                      </div>

                      <div className="contact-input">
                        <Input
                          value={values.contact_us_mobile__c}
                          className="contact-input"
                          placeholder={strings.getString("phone")}
                          name="contact_us_mobile__c"
                          onChange={handleChange("contact_us_mobile__c")}
                          onBlur={handleBlur("contact_us_mobile__c")}
                          error={errors.contact_us_mobile__c}
                          touched={touched.contact_us_mobile__c}
                        />
                      </div>
                    </div>

                    <textarea
                      placeholder={strings.getString("message")}
                      value={values.contact_us_message__c}
                      className="textarea-input"
                      onChange={(e) =>
                        setFieldValue("contact_us_message__c", e.target.value)
                      }
                      style={{
                        minWidth: "100%",
                        maxWidth: "100%",
                        width: "100%",
                        borderColor: "#fff",
                        backgroundColor: "#f8f8f8",
                        minHeight: 70,
                        border: "1px solid #d8d8d8",
                        borderRadius: 5,
                        padding: "7px 11px",
                      }}
                    />
                    {errors.contact_us_message__c && (
                      <p className="errorInputText">
                        {errors.contact_us_message__c + "*"}
                      </p>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        type="submit"
                        title={strings.getString("submit")}
                        onClick={handleSubmit}
                        style={{
                          marginBlock: 10,
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};
export default ContactUs;
