import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Col, Form, Row } from "rsuite";
import * as Yup from "yup";
import { SelectPicker } from "../../../../../../components";
import { editDestinations } from "../../../../../../redux/profile/profileSaga";
import {
  DestinationDto,
  PicklistCountryDto,
} from "../../../../../../redux/profile/type";
import {
  getLocalizedErrors,
  getLocalizedMyProfile,
} from "../../../../../../redux/selectors";
const EditDestinationForm = ({
  countries,
  selected,
  onSuccess,
}: {
  onSuccess: () => void;
  selected: DestinationDto;
  countries: PicklistCountryDto[];
}) => {
  const errorStrings = useSelector(getLocalizedErrors);
  const strings = useSelector(getLocalizedMyProfile);
  const dispatch = useDispatch();
  return (
    <Formik
      enableReinitialize
      initialValues={{
        id: selected.id,
        from__c: selected.from__c,
        to__c: selected.to__c,
      }}
      validationSchema={Yup.object().shape({
        from__c: Yup.string().required(errorStrings.getString("required")),
        to__c: Yup.string().required(errorStrings.getString("required")),
      })}
      onSubmit={(values) => {
        dispatch(
          editDestinations({
            payload: {
              onSuccess: onSuccess,
              onError: (message) => {
                toast.error(message, {
                  theme: "colored",
                });
              },
            },
            url: "/Profile/UpdateDestination",
            body: values,
          })
        );
      }}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        values,
      }) => (
        <Form>
          <Row>
            <Col md={11}>
              <SelectPicker
                cleanable={false}
                placement="bottomStart"
                labelKey={"description"}
                valueKey={"id"}
                searchable={true}
                style={{ marginBlockEnd: 10 }}
                label={strings.getString("startingpoint")}
                placeholder={strings.getString("startingpoint")}
                data={countries}
                name="from__c"
                value={values.from__c}
                onSelect={handleChange("from__c")}
                onBlur={handleBlur("from__c")}
                error={errors.from__c}
                touched={touched.from__c}
              />
            </Col>
            <Col md={11}>
              <SelectPicker
                cleanable={false}
                searchable={true}
                style={{ marginBlockEnd: 10 }}
                placement="bottomStart"
                label={strings.getString("destinationpoint")}
                placeholder={strings.getString("destinationpoint")}
                data={countries}
                name="to__c"
                labelKey={"description"}
                valueKey={"id"}
                value={values.to__c}
                onSelect={handleChange("to__c")}
                onBlur={handleBlur("to__c")}
                error={errors.to__c}
                touched={touched.to__c}
              />
            </Col>
            <Col md={2}>
              <div className="smResponsiveButtonDiv gridPosition">
                <button
                  type="submit"
                  className="gridIcon "
                  onClick={() => handleSubmit()}
                >
                  <i className="dx-icon-save" style={{ color: "white" }} />
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
export default EditDestinationForm;
