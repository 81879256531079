import "./style.css";
import { useSelector } from "react-redux";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { Breadcrumb } from "rsuite";
import {
  getLocalizedMenuStrings,
  getNavbarMenuSelector,
} from "../../redux/selectors";
import { color } from "../../utils/Properties";
import { Icon } from "..";

const BreadcrumbRS = ({ location }: RouteComponentProps) => {
  const { pathname } = location;
  const navbarList = useSelector(getNavbarMenuSelector);
  const pathnames: string[] = pathname.includes("auction")
    ? ["auctions"]
    : pathname.split("/").filter((x: string) => x);
  const getTitle = (path: string) => {
    let title: string | undefined = undefined;
    title = navbarList.find((i) => i.link === `/${path}`)?.name;
    if (title) {
      return title;
    } else {
      navbarList.some((item) => {
        if (item.subItems) {
          title = item.subItems.find((si) => si.link === `/${path}`)?.name;
          if (title) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      });
      return title;
    }
  };
  const strings = useSelector(getLocalizedMenuStrings);
  return (
    <div
      className="headerBreadcrumb"
      style={{ backgroundColor: "#ffffff", borderColor: color.borderColor }}
    >
      <div className="mainWidth" style={{ height: "100%" }}>
        <Breadcrumb
          separator={<Icon icon="right" size={13} color={color.primary} />}
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            marginBlockEnd: 0,
          }}
        >
          <Breadcrumb.Item
            componentClass={Link}
            to={"/"}
            active={location.pathname === "/"}
            key={1023}
          >
            {strings.getString("Home")}
          </Breadcrumb.Item>
          {pathnames.map((path: string, i: number) => {
            let title = getTitle(path);
            if (path === "my-profile") {
              title = "Profile";
            }
            if (path === "blogs") {
              title = "Blogs";
            }
            if (path === "auctions") {
              title = strings.getString("auctions");
            }
            return (
              <Breadcrumb.Item
                componentClass={Link}
                to={"/" + path}
                active={i + 1 === pathnames.length}
                key={i + 1}
              >
                {title || ""}
              </Breadcrumb.Item>
            );
          })}
        </Breadcrumb>
      </div>
    </div>
  );
};

export default withRouter(BreadcrumbRS);
