import { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Footer } from "../../../components";
import { Alert } from "rsuite";
import { Formik } from "formik";
import * as Yup from "yup";
import "yup-phone";
import {
  getLocalizedErrors,
  getLocalizedTruckRegistration,
} from "../../../redux/selectors";
import { color } from "../../../utils/Properties";
import "./style.css";

import { Step0, Step1, Step2 } from "./steps";
import { getContactRoles } from "../../../redux/actions";
import moment from "moment";
import { createVehicleOpportunity } from "../../../redux/opportunity/saga"; // eslint-disable-next-line
const AlwaysScrollToBottom = () => {
  const elementRef = useRef(null);
  useEffect(() =>
    //@ts-ignore
    elementRef.current.scrollIntoView()
  );
  return <div ref={elementRef} />;
};
const CreateTruck = () => {
  const dispatch = useDispatch();
  const strings = useSelector(getLocalizedTruckRegistration);
  const errorStrings = useSelector(getLocalizedErrors);
  const TruckRegistrationSchema = Yup.object().shape({
    vehicle_Type__c: Yup.mixed().required(errorStrings.getString("required")),
    vehicle_Last_Availability_Date__c: Yup.string().required(
      errorStrings.getString("required")
    ),
    vehicle_First_Availability_Date__c: Yup.string().required(
      errorStrings.getString("required")
    ),
    destinationList___c: Yup.array().min(
      1,
      strings.getString("You have to add at least one destination")
    ),
  });

  useEffect(() => {
    dispatch(getContactRoles());
  }, [dispatch]);

  const onSubmitPress = (values: any, { resetForm }: { resetForm: any }) => {
    dispatch(
      createVehicleOpportunity({
        payload: {
          onSuccess: () => {
            Alert.success(
              strings.getString("Your vehicle registered successfully"),
              5000
            );
            resetForm();
          },
          onError: (message) => {
            Alert.error(message, 5000);
          },
        },
        body: {
          vehicle_opportunity: {
            descriptionfornotwinning__c: values.description__c,
          },
          currencyisocode: values.currency__,
          vehicletype_vehicleopportunity: {
            vehicle_type__c: values.vehicle_Type__c,
            vehicle_first_availability_date__c:
              values.vehicle_First_Availability_Date__c,
            vehicle_last_availability_date__c:
              values.vehicle_Last_Availability_Date__c,
            loading_type__c: values.loading_Type__c,
            unloading_type_c__c: values.unloading_Type_c__c,
            equipments__c: values.equipmentsValue
              ? values.equipmentsValue.join(";")
              : "",
            trucktransitdeclarationtype__c:
              values.truckTransitDeclarationType__c,
            seconddrivercheckbox__c: values.secondDriverCheckbox__c,
            thirdcountrycertificate__c: values.thirdCountryCertificate__c,
            thirdcountryname__c: values.thirdCountryName__c,
          },
          destination_vehicletype_vehicleopp: values.destinationList___c.map(
            (x: {
              location_To_Load__c: string;
              location_To_Go__c: string;
              transporter_Requested_Price__c: string;
              no: string;
            }) => {
              return {
                vehicle_type__c: values.vehicle_Type__c,
                external_id__c: x.no,
                location_to_load__c: x.location_To_Load__c,
                location_to_go__c: x.location_To_Go__c,
                transporter_requested_price__c:
                  x.transporter_Requested_Price__c,
                currencyisocode: values.currency__,
              };
            }
          ),
          route_vehicletype_vehicleopp: values.guzergahList__c.map(
            (x: {
              no: string;
              destinationId: string;
              Guzergah__c: string;
              CustomersWantsPrice__c: any;
            }) => {
              return {
                destination_external_id__c: x.destinationId,
                vehicle_type__c: values.vehicle_Type__c,
                route__c: x.Guzergah__c,
                transporter_requested_price__c: x.CustomersWantsPrice__c,
                currencyisocode: values.currency__,
              };
            }
          ),
        },
      })
    );
  };

  return (
    <div className="innerScreen">
      <div
        className="innerScreenContent"
        style={{ backgroundColor: "#BFE9FE4D" }}
      >
        <div
          className="mainWidth"
          style={{
            marginBlock: 20,
            backgroundColor: color.accent,
            paddingInline: 40,
            paddingBlock: 40,
          }}
        >
          <Formik
            initialValues={{
              countryList: [],
              vehicle_Type__c: "",
              vehicle_First_Availability_Date__c: moment(new Date()).format(),
              vehicle_Last_Availability_Date__c: moment(new Date()).format(),
              thirdCountryCertificate__c: false,
              thirdCountryName__c: "",
              secondDriverCheckbox__c: false,
              truckTransitDeclarationType__c: "",
              loading_Type__c: "",
              unloading_Type_c__c: "",
              equipmentsValue: "",
              description__c: "",
              destinationList___c: [],
              currency__: "",
              guzergahList__c: [],
              showGuzergah: false,
            }}
            validationSchema={TruckRegistrationSchema}
            onSubmit={onSubmitPress}
          >
            {({ handleSubmit, values, errors, touched, validateForm }) => {
              console.log(
                Array.from(
                  new Set(values.countryList.map((item: any) => item.countryid))
                ).length,
                values.countryList
              );
              return (
                <div style={{ width: "100%" }}>
                  <Step0 values={values} errors={errors} touched={touched} />
                  <Step1 values={values} errors={errors} touched={touched} />
                  {values.showGuzergah &&
                    !(
                      Array.from(
                        new Set(
                          values.countryList.map((item: any) => item.countryid)
                        )
                      ).length > 2
                    ) && (
                      <Step2
                        values={values}
                        errors={errors}
                        touched={touched}
                      />
                    )}
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      style={{ width: "20%" }}
                      type="submit"
                      title={strings.getString("submit")}
                      onClick={() => handleSubmit()}
                    />
                  </div>
                </div>
              );
            }}
          </Formik>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CreateTruck;
