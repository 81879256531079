import { useState } from "react";
import { useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { StepProps } from ".";
import { getLocalizedTruckRegistration } from "../../../../redux/selectors";
import { confirm } from "devextreme/ui/dialog";
import CreateDestination from "./comp/createDestination";
import EditDestination from "./comp/editDestination";
import ResponsiveTable, {
  GridButtonType,
} from "../../../../components/ResponsiveTable";
import { GetRoutes } from "../../../../redux/opportunity/saga";

const Step1 = ({ values, errors, touched }: StepProps) => {
  const strings = useSelector(getLocalizedTruckRegistration);
  const { setFieldValue } = useFormikContext();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState<{
    location_To_Load__c: string;
    countryid: string;
    fromcountrycode: string;
    tocountrycode: string;
    location_To_Go__c: string[];
    location_To_Go__c_Name: string[];
    location_To_Load__c_Name: string;
    fromcountryid: string;
    transporter_Requested_Price__c: string;
    no: string;
  }>();

  const columns = [
    {
      dataField: "location_To_Load__c_Name",
      caption: strings.getString("Location to Load"),
    },
    {
      dataField: "location_To_Go__c_Name",
      caption: strings.getString("Location to Go"),
      formatter: (value: any) => (
        <>{value.map((x: any) => x.label).toString()}</>
      ),
    },
    {
      dataField: "transporter_Requested_Price__c",
      caption: strings.getString("Requested Price"),
    },
  ];
  const buttons = [
    {
      type: GridButtonType.edit,
      icon: "dx-icon-edit",
      onClick: (e: any) => {
        return setSelected(e);
      },
    },
    {
      type: GridButtonType.default,
      icon: "dx-icon-trash",
      onClick: (e: any) => {
        var confirmResp = confirm(
          strings.getString("deleteDestinationqua"),
          strings.getString("deleteDestination")
        );
        confirmResp.then((result) => {
          if (result) {
            const last = values.destinationList___c.filter(
              (x: any) => x.no !== e.no
            );
            const lastguzergah = values.guzergahList__c.filter(
              (x: any) => x.destinationId !== e.no
            );
            setFieldValue("guzergahList__c", lastguzergah);
            setFieldValue(
              "countryList",
              values.countryList.filter((x: any) => x.no !== e.no)
            );
            return setFieldValue("destinationList___c", last);
          }
        });
      },
    },
  ];

  return (
    <div style={{ marginBlock: "3rem 5rem" }}>
      <p
        className="newBoldHeadingStyle"
        style={{ marginBlockEnd: 18, textAlign: "center" }}
      >
        {strings.getString("2 Destination Information")}
      </p>

      {errors.destinationList___c && (
        <p className="inputErrorStyles">{errors.destinationList___c}</p>
      )}
      <ResponsiveTable
        bordered
        data={values.destinationList___c}
        columns={columns}
        buttons={buttons}
        createForm={
          <div className="editFormOuterDiv">
            <CreateDestination
              baseValues={values}
              errors={errors}
              touched={touched}
              setCountryList={(valuess) => {
                return setFieldValue("countryList", [
                  ...values.countryList,
                  ...valuess,
                ]);
              }}
              onSubmit={(value) => {
                dispatch(
                  GetRoutes({
                    payload: {
                      onSuccess: (message, payload) => {
                        payload &&
                          payload.length > 0 &&
                          setFieldValue("showGuzergah", true);
                      },
                      onError: () => {},
                    },
                    url: `System/GetRoutesFilter?From=${value.fromcountrycode}&To=${value.tocountrycode}`,
                  })
                );
                const last = values.destinationList___c;
                return setFieldValue("destinationList___c", [...last, value]);
              }}
            />
          </div>
        }
        editForm={
          selected && (
            <EditDestination
              data={selected}
              baseValues={values}
              errors={errors}
              touched={touched}
              setCountryList={(valuess) => {
                return setFieldValue("countryList", [
                  ...values.countryList.filter(
                    (x: any) => x.no === selected.no
                  ),
                  ...valuess,
                ]);
              }}
              onSubmit={(test) => {
                const last = values.destinationList___c.filter(
                  (x: any) => x.no !== test.no
                );
                return setFieldValue("destinationList___c", [...last, test]);
              }}
            />
          )
        }
      />
    </div>
  );
};

export default Step1;
