import { createAction } from "redux-smart-actions";
import * as SagaParams from "../sagas/staticSaga";
import { LoginResult } from "../types";

export const loginAction = createAction(
  "LOGIN_ACTION",
  (payload: SagaParams.LoginParams) => ({
    payload,
  })
);
export const logoutAction = createAction("LOGOUT_ACTION");

export const setUser = createAction("SET_USER", (payload: LoginResult) => ({
  payload,
}));
