import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Nav, Panel, PanelGroup } from "rsuite";
import { getLocalizedMyProfile } from "../../../../../redux/selectors";
import {
  DisapprovalReasonDto,
  ProfileCustomerOfferDto,
} from "../../../../../redux/profile/type";
import {
  GetClosedCustomerOfferList,
  GetCustomerOfferList,
  getReasonForTransporterDisapproval,
} from "../../../../../redux/profile/profileSaga";
import { color } from "../../../../../utils/Properties";
import { Loader } from "../../../../../components";
import ApprovalOfferCardCustomer from "./accept-offer-card-customer";

const CustomerOfferList = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState<ProfileCustomerOfferDto[]>();
  const [reasons, setReasons] = useState<DisapprovalReasonDto[]>();
  const strings = useSelector(getLocalizedMyProfile);
  const location = useLocation();
  const offerId = new URLSearchParams(location.search).get("accept-offer");
  const [active, setActive] = useState<string>(offerId ?? "");
  const [loader, setLoader] = useState(false);
  const [activeTab, setActiveTab] = useState<string>("open");
  useEffect(() => {
    dispatch(
      getReasonForTransporterDisapproval({
        payload: {
          onSuccess: (message, payload) => {
            setReasons(payload);
          },
          onError: (message) => {},
        },
      })
    );
  }, [dispatch]);
  useEffect(() => {
    if (activeTab === "open") {
      setLoader(true);
      dispatch(
        GetCustomerOfferList({
          payload: {
            onSuccess: (message, payload) => {
              setData(payload);
              setLoader(false);
            },
            onError: (message) => {
              setLoader(false);
            },
          },
        })
      );
    }
    if (activeTab === "closed") {
      setLoader(true);
      dispatch(
        GetClosedCustomerOfferList({
          payload: {
            onSuccess: (message, payload) => {
              setData(payload);
              setLoader(false);
            },
            onError: (message) => {
              setLoader(false);
            },
          },
        })
      );
    }
  }, [dispatch, activeTab]);
  const getData = () => {
    dispatch(
      GetCustomerOfferList({
        payload: {
          onSuccess: (message, payload) => {
            setData(payload);
          },
          onError: (message) => {},
        },
      })
    );
  };
  return (
    <>
      <div
        className="my-profile-card"
        style={{ backgroundColor: color.accent }}
      >
        {loader && <Loader />}
        <Nav
          className="responsive-nav"
          appearance="tabs"
          activeKey={activeTab}
          onSelect={setActiveTab}
        >
          <Nav.Item
            eventKey="open"
            className="aboutus-menu-item responsive-navitem"
            onClick={() => {}}
          >
            {strings.getString("Open Offer")}
          </Nav.Item>
          <Nav.Item
            eventKey="closed"
            className="aboutus-menu-item responsive-navitem"
            onClick={() => {}}
          >
            {strings.getString("Closed Offer")}
          </Nav.Item>
        </Nav>
        <div style={{ marginBlockStart: 30 }}>
          <PanelGroup
            accordion
            bordered
            onSelect={(eventKey) => {
              eventKey === active ? setActive("") : setActive(eventKey);
            }}
            activeKey={active}
          >
            {data?.map((x, i) => {
              return (
                <Panel eventKey={x.sfid} header={x.name} className="boldText">
                  {x.QuoteDestionation.map((a) => (
                    <ApprovalOfferCardCustomer
                      data={a.Load}
                      destination={a}
                      reasons={reasons}
                      getData={getData}
                      activeTab={activeTab}
                    />
                  ))}
                </Panel>
              );
            })}
          </PanelGroup>
        </div>
      </div>
    </>
  );
};
export default CustomerOfferList;
