import { createAction } from "redux-smart-actions";
import { SagaActionParams, SliderObject, SliderTimeObject } from "../../types";
import * as O from "../../types/objects";

export const getHomepageArticle = createAction(
  "GET_HOMEPAGE_ARTICLE",
  ({ payload, url, id }: SagaActionParams<O.ArticleObject[]>) => ({
    payload,
    url,
    id,
  })
);
export const getSSS = createAction(
  "GET_SSS",
  ({ payload, url, id }: SagaActionParams<O.SSSDto[]>) => ({
    payload,
    url,
    id,
  })
);

export const getBlogDetails = createAction(
  "GET_BLOG_DETAILS",
  ({ payload, url, id }: SagaActionParams<O.BlogObject>) => ({
    payload,
    url,
    id,
  })
);

export const getHomepageTrucksArticle = createAction(
  "GET_HOMEPAGE_TRUCK_ARTICLE",
  ({ payload, url, id }: SagaActionParams<O.ArticleObject[]>) => ({
    payload,
    url,
    id,
  })
);

export const getHomepageLoadArticle = createAction(
  "GET_HOMEPAGE_LOAD_ARTICLE",
  ({ payload, url, id }: SagaActionParams<O.ArticleObject[]>) => ({
    payload,
    url,
    id,
  })
);

export const getHomepageBlogs = createAction(
  "GET_HOMEPAGE_BLOGS",
  ({ payload, url }: SagaActionParams<O.BlogObject[]>) => ({
    payload,
    url,
  })
);

export const getHomepageOffices = createAction(
  "GET_HOMEPAGE_OFFICES",
  ({ payload, url }: SagaActionParams<O.OfficeObject[]>) => ({ payload, url })
);

export const getHomepageSliders = createAction(
  "GET_HOMEPAGE_SLIDERS",
  ({ payload, url }: SagaActionParams<SliderObject[]>) => ({ payload, url })
);
export const getHomepageSliderTime = createAction(
  "GET_HOMEPAGE_SLIDER_TIME",
  ({ payload, url }: SagaActionParams<SliderTimeObject>) => ({ payload, url })
);

export const getHomepageStats = createAction(
  "GET_HOMEPAGE_STATS",
  ({ payload, url }: SagaActionParams<O.StatObject>) => ({
    payload,
    url,
  })
);

export const getLocations = createAction(
  "GET_LOCATIONS",
  ({ payload, url }: SagaActionParams<O.LocationObject[]>) => ({
    payload,
    url,
  })
);
/**
 * SETTERS
 */
