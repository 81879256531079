import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "rsuite";
import FileSaver from "file-saver";
import { AuctionQuestionDto } from "../../../../redux/autions/type";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedAuction } from "../../../../redux/selectors";
import { getIncDescAuctionQuestion } from "../../../../redux/autions/saga";
import ScrollBar from "react-perfect-scrollbar";
import QuestionModal from "../../auctions/detail/purpose/questionModal";
import { IncDescAuctionDto } from "../../../../redux/autions/inc-desc-type";

const DetailTab = ({
  auction,
  setAuction,
  counter,
}: {
  counter?: any;
  auction: IncDescAuctionDto;
  setAuction: (auction: IncDescAuctionDto) => void;
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [question, setQuestion] = useState<AuctionQuestionDto>();
  const dispatch = useDispatch();
  const t = useSelector(getLocalizedAuction);
  const [questions, setQuestions] = useState<AuctionQuestionDto[]>();

  useEffect(() => {
    dispatch(
      getIncDescAuctionQuestion({
        payload: {
          onSuccess: (m, payload) => setQuestions(payload),
          onError: () => {},
        },
        id: auction.id,
      })
    );
  }, [auction.id, dispatch]);

  const adjustForTimezone = (currentdate: string, datetime?: boolean) => {
    return moment(currentdate + "+00:00").format(
      datetime ? "DD/MM/yyyy HH:mm" : "DD/MM/yyyy"
    );
  };
  const belgeler = [
    { name: "Tüm Dosyalar", url: "" },
    { name: "2023_FTL_Volume_Sheet.xlsx", url: "" },
    { name: "Tender_description", url: "" },
  ];
  const table = [
    {
      title: t.getString("Tender Type"),
      value: auction.transportertendertype__c,
    },
    {
      title: t.getString("Tender Contract Start Date"),
      value: adjustForTimezone(auction.tenderstartdatetime__c, true),
    },
    {
      title: t.getString("Tender Contract End Date"),
      value: adjustForTimezone(auction.tenderenddatetime__c, true),
    },
    {
      title: t.getString("Remaining Time"),
      value: counter ? counter : "-",
    },
    {
      title: t.getString("Tender Contract Period Start Date"),
      value:
        auction.tender_contractstartdate__c != null
          ? adjustForTimezone(auction.tender_contractstartdate__c)
          : "-",
    },
    {
      title: t.getString("Tender Contract Period End Date"),
      value:
        auction.tender_contractenddate__c != null
          ? adjustForTimezone(auction.tender_contractenddate__c)
          : "-",
    },
  ];

  return (
    <React.Fragment>
      {question && (
        <QuestionModal
          open={openModal}
          setOpen={setOpenModal}
          question={question}
        />
      )}
      <Row style={{ marginBlockStart: 10 }}>
        <Col md={12}>
          <div>
            <div
              className="auctiondetailMenuItem"
              style={{ textAlign: "center" }}
            >
              <span className="auctionMenuItemTitle">
                {t.getString("Descriptions")}
              </span>
            </div>
            <div className="auctiondetailMenuItemDescription minHeigth230 menuHeight">
              <ScrollBar>
                <div
                  className=" "
                  dangerouslySetInnerHTML={{
                    __html: auction.Tender_Description__c
                      ? auction.Tender_Description__c
                      : "-",
                  }}
                ></div>
              </ScrollBar>
            </div>
          </div>
        </Col>
        <Col md={12}>
          <div className="auctionDetailItem">
            <div
              className="auctiondetailMenuItem "
              style={{ textAlign: "center" }}
            >
              <span className="auctionMenuItemTitle">
                {t.getString("Informations")}
              </span>
            </div>

            <ScrollBar>
              <div className="menuHeight auctiondetailMenuItemDescription minHeigth230">
                <table className="auctionTable">
                  {table.map((x) => (
                    <tr>
                      <th>{x.title}</th>
                      <td>{x.value}</td>
                    </tr>
                  ))}
                  <tr>
                    <th>{auction.email ?? "-"}</th>{" "}
                    <td>{auction.mobilephone ?? "-"}</td>
                  </tr>
                </table>
              </div>
            </ScrollBar>
          </div>
        </Col>
      </Row>
      <Row style={{ marginBlockStart: 10 }}>
        <Col md={12}>
          <div>
            <div
              className="auctiondetailMenuItem"
              style={{ textAlign: "center" }}
            >
              <span className="auctionMenuItemTitle">
                {t.getString("QuestionAnswers")}
              </span>
            </div>
            <div className="auctiondetailMenuItemDescription">
              <table className="auctionTable">
                {questions?.map((x) => (
                  <tr>
                    <th>
                      <div className="tenderQuestionSpan">
                        {x.tender_question__c}
                      </div>
                    </th>
                    <td>
                      <div
                        style={{ width: "100%", cursor: "pointer" }}
                        onClick={() => {
                          setQuestion(x);
                          return setOpenModal(true);
                        }}
                      >
                        {t.getString("Show")}
                      </div>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        </Col>
        <Col md={12}>
          <div className="auctionDetailItem">
            <div
              className="auctiondetailMenuItem "
              style={{ textAlign: "center" }}
            >
              <span className="auctionMenuItemTitle">
                {t.getString("Documents")}
              </span>
            </div>
            <div className="auctiondetailMenuItemDescription">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                {belgeler.map((x) => (
                  <div
                    className="autionDetailFileItem"
                    onClick={() =>
                      FileSaver.saveAs(
                        "https://dev-app.rapidloadboard.com/static/media/rapid.7f18a9b6.jpg",
                        x.name
                      )
                    }
                  >
                    {x.name}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default DetailTab;
