import "./style.css";
import { useSelector } from "react-redux";
import { DatePicker, DatePickerProps } from "rsuite";
import { getLocalizedDates } from "../../redux/selectors";
import { color } from "../../utils/Properties";

const DatePickerIntl = ({
  label,
  format,
  hideLabel,
  placeholder,
  error,
  touched,
  minDate,
  ...rest
}: DatePickerIntlProps) => {
  const strings = useSelector(getLocalizedDates);

  return (
    <div
      className="field only-date datePickerComp"
      style={{ borderColor: color.borderColor }}
    >
      <span
        style={{
          fontSize: 13,
          marginBlockEnd: 3,
        }}
      >
        {label}
      </span>
      <DatePicker
        cleanable={false}
        className={error && touched ? "errorInput" : "defaultInput"}
        isoWeek={true}
        placeholder={placeholder}
        format={format ? format : "DD/MM/YY HH:mm"}
        locale={{
          sunday: strings.getString("sunday"),
          monday: strings.getString("monday"),
          tuesday: strings.getString("tuesday"),
          wednesday: strings.getString("wednesday"),
          thursday: strings.getString("thursday"),
          friday: strings.getString("friday"),
          saturday: strings.getString("saturday"),
          ok: strings.getString("ok"),
          today: strings.getString("today"),
          yesterday: strings.getString("yesterday"),
          hours: strings.getString("hours"),
          minutes: strings.getString("minutes"),
          seconds: strings.getString("seconds"),
        }}
        {...rest}
      />
      {error && touched && <p className="inputErrorStyles">{error}</p>}
    </div>
  );
};

interface DatePickerIntlProps extends DatePickerProps {
  error?: string | null;
  touched?: string | null;
  minDate?: Date;
  hideLabel?: boolean;
}

export default DatePickerIntl;
