import { Formik } from "formik";
import { Alert, Divider, Form } from "rsuite";
//import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, SelectPicker } from "../../../../../components";
import {
  getLocalizedErrors,
  getLocalizedMyProfile,
  getUserState,
} from "../../../../../redux/selectors";
import { color } from "../../../../../utils/Properties";
import CompanyDetailCard from "./card";
import { updateCompany, setUser } from "../../../../../redux/actions";
import { useEffect, useState } from "react";
import {
  getDocuments,
  getDocumentTypes,
  getIndustryList,
  getPicklistCountryListForCustomer,
} from "../../../../../redux/profile/profileSaga";
import {
  DocumentDto,
  DocumentTypeDto,
  IndustryDto,
  PicklistCountryDto,
} from "../../../../../redux/profile/type";
import { FileUploader } from "devextreme-react";
const CompanyDetails = ({ onClickMessage }: { onClickMessage: () => void }) => {
  const dispatch = useDispatch();
  const [industry, setIndustry] = useState<IndustryDto[]>(); // eslint-disable-next-line
  const [countries, setCountries] = useState<PicklistCountryDto[]>(); // eslint-disable-next-line
  const [documentTypes, setDocumentTypes] = useState<DocumentTypeDto[]>([]);
  const [document, setDocument] = useState<DocumentDto[]>([]);
  const strings = useSelector(getLocalizedMyProfile);
  const user = useSelector(getUserState);
  useEffect(() => {
    dispatch(
      getIndustryList({
        payload: {
          onSuccess: (message, payload) => {
            setIndustry(payload ? payload : []);
          },
          onError: (message) => { },
        },
        url: "System/GetIndustry",
      })
    );
    /*    dispatch(
         getDocuments({
           payload: {
             onSuccess: (message, payload) => {
               setDocument(payload ? payload : []);
             },
             onError: (message) => {},
           },
           url: "Profile/DocumentList",
         })
       ); */
    /*     dispatch(
          getDocumentTypes({
            payload: {
              onSuccess: (message, payload) => {
                setDocumentTypes(payload ? payload : []);
              },
              onError: (message) => { },
            },
            url: "Profile/GetDocumentTypes",
          })
        ); */ // eslint-disable-next-line
    dispatch(
      getPicklistCountryListForCustomer({
        payload: {
          onSuccess: (message, payload) => setCountries(payload),
          onError: (message) => { },
        },
        url: "System/GetPicklistCountryList",
      })
    );
  }, [dispatch]);
  const onSubmitPress = ({
    name,
    taxnumber__c,
    taxoffice__c,
    industry,
    phone,
    companyemail__c,
    website,
    originpicklist__c,
    shippingaddress,
    shippingcountry,
    shippingcity,
    shippingstate,
    shippingstreet,
    shippingpostalcode,
    DocumentLists,
  }: {
    name: string;
    taxnumber__c: string;
    taxoffice__c: string;
    industry: string;
    phone: string;
    companyemail__c: string;
    website: string;
    originpicklist__c: string;
    shippingaddress: string;
    shippingcountry: string;
    shippingcity: string;
    shippingstate: string;
    shippingstreet: string;
    shippingpostalcode: string;
    DocumentLists: any;
  }) => {
    shippingaddress = `${shippingstreet} ${shippingstate}/${shippingcity},${shippingcountry}`;

    if (website && taxoffice__c && taxnumber__c) {
      dispatch(
        updateCompany({
          params: {
            name,
            taxnumber__c,
            taxoffice__c,
            industry,
            phone,
            companyemail__c,
            website,
            originpicklist__c,
            shippingaddress,
            shippingcountry,
            shippingcity,
            shippingstate,
            shippingstreet,
            shippingpostalcode,
            DocumentLists,
          },
          onSuccess: (payload) => {
            user &&
              dispatch(
                setUser({
                  ...user,
                  //@ts-ignore
                  Account: {
                    ...user?.Account,
                    name: name ?? "",
                    taxnumber__c,
                    taxoffice__c,
                    industry,
                    phone,
                    companyemail__c,
                    website,
                    originpicklist__c,
                    shippingcountry,
                    shippingcity,
                    shippingstate,
                    shippingstreet,
                    shippingpostalcode,
                    shippingaddress: `${shippingstreet} ${shippingstate}/${shippingcity},${shippingcountry}`,
                  },
                })
              );
            Alert.success(errorStrings.getString("success"));
          },
          onError: (message) => {
            Alert.error(message, 7000);
          },
        })
      );
    } else {
      Alert.error(errorStrings.getString("error-company-update"), 7000);
    }

  };

  const errorStrings = useSelector(getLocalizedErrors);

  return (
    <div className="my-profile-card" style={{ backgroundColor: color.accent }}>
      <p className="newBoldHeadingStyle">
        {strings.getString("companydetails")}
      </p>
      <p
        className="regularText"
        style={{
          marginBlockEnd: 30,
        }}
      >
        {strings.formatString(strings.getString("company_details_description"))}
      </p>

      <form>
        <Formik
          initialValues={{
            name: user?.Account.name ?? "",
            taxnumber__c: user?.Account.taxnumber__c ?? "",
            taxoffice__c: user?.Account.taxoffice__c ?? "",
            industry: user?.Account.industry ?? "",
            phone: user?.Account.phone ?? "",
            companyemail__c: user?.Account.companyemail__c ?? "",
            website: user?.Account.website ?? "",
            originpicklist__c: user?.Account.originpicklist__c ?? "",
            shippingaddress: user?.Account.shippingaddress ?? "",
            shippingcountry: user?.Account.shippingcountry ?? "",
            shippingcity: user?.Account.shippingcity ?? "",
            shippingstate: user?.Account.shippingstate ?? "",
            shippingstreet: user?.Account.shippingstreet ?? "",
            shippingpostalcode: user?.Account.shippingpostalcode ?? "",
            DocumentLists: [],
          }}
          onSubmit={onSubmitPress}
        >
          {({
            handleSubmit,
            setFieldValue,
            handleBlur,
            handleChange,
            values,
            errors,
            touched,
          }) => {
            return (
              <>
                <div className="my-profile-card-form-row">
                  <Form className="company-details-form">
                    <div className="row">
                      <div className="col-md-1">
                        <Input
                          label={strings.getString("companyname")}
                          name="companyname"
                          value={values.name}
                          onChange={handleChange("name")}
                          onBlur={handleBlur("name")}
                          error={errors.name}
                          disabled={user?.AccountAdmin ? false : true}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-2">
                        <SelectPicker
                          cleanable={false}
                          placement="bottomStart"
                          labelKey={"description"}
                          valueKey={"id"}
                          searchable={true}
                          style={{ marginBlockEnd: 10 }}
                          label={strings.getString("origin__c")}
                          placeholder={strings.getString("origin__c")}
                          data={countries ? countries : []}
                          name="origin__c"
                          value={values.originpicklist__c}
                          onSelect={handleChange("originpicklist__c")}
                          onBlur={handleBlur("originpicklist__c")}
                          error={errors.originpicklist__c}
                          disabled={user?.AccountAdmin ? false : true}
                        />
                      </div>
                      <div className="col-md-2">
                        <Input
                          label={strings.getString("taxnumber__c")}
                          name="taxnumber__c"
                          value={values.taxnumber__c}
                          onChange={handleChange("taxnumber__c")}
                          onBlur={handleBlur("taxnumber__c")}
                          error={errors.taxnumber__c}
                          disabled={user?.AccountAdmin ? false : true}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-2">
                        <Input
                          label={strings.getString("taxoffice__c")}
                          name="taxoffice__c"
                          value={values.taxoffice__c}
                          onChange={handleChange("taxoffice__c")}
                          onBlur={handleBlur("taxoffice__c")}
                          error={errors.taxoffice__c}
                          disabled={user?.AccountAdmin ? false : true}
                        />
                      </div>

                      {(user?.AccountType.Name === "Lead Account" ||
                        user?.AccountType.Name === "Account" ||
                        user?.AccountType.Name ===
                        "Candidate Forwarder Musteri" ||
                        user?.AccountType.Name === "Forwarder" ||
                        user?.AccountType.Name === "Forwarder Transporter" ||
                        user?.AccountType.Name ===
                        "Candidate Forwarder Nakliyeci" ||
                        user?.AccountType.Name === "Candidate Forwarder") && (
                          <div className="col-md-2">
                            <SelectPicker
                              placement="bottomStart"
                              cleanable={false}
                              labelKey={"value__c"}
                              valueKey={"api_name__c"}
                              searchable={true}
                              style={{ marginBlockEnd: 10 }}
                              label={strings.getString("industry")}
                              data={industry ?? []}
                              name="industry"
                              value={values.industry}
                              onSelect={handleChange("industry")}
                              onBlur={handleBlur("industry")}
                              error={errors.industry}
                              disabled={user?.AccountAdmin ? false : true}
                            />
                          </div>
                        )}
                    </div>
                    <div className="row">
                      <div className="col-md-2">
                        <Input
                          label={strings.getString("email")}
                          name="companyemail__c"
                          value={values.companyemail__c}
                          onChange={handleChange("companyemail__c")}
                          onBlur={handleBlur("companyemail__c")}
                          error={errors.companyemail__c}
                        />
                      </div>

                      <div className="col-md-2">
                        <Input
                          label={strings.getString("phone")}
                          name="phone"
                          value={values.phone}
                          onChange={handleChange("phone")}
                          onBlur={handleBlur("phone")}
                          error={errors.phone}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-2">
                        <Input
                          label={strings.getString("website")}
                          name="website"
                          value={values.website}
                          onChange={handleChange("website")}
                          onBlur={handleBlur("website")}
                          error={errors.website}
                          disabled={user?.AccountAdmin ? false : true}
                        />
                      </div>
                      <div className="col-md-2">
                        <Input
                          label={strings.getString("shippingpostalcode")}
                          name="shippingpostalcode"
                          value={values.shippingpostalcode}
                          onChange={handleChange("shippingpostalcode")}
                          onBlur={handleBlur("shippingpostalcode")}
                          error={errors.shippingpostalcode}
                          disabled={user?.AccountAdmin ? false : true}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-2">
                        <Input
                          label={strings.getString("shippingcountry")}
                          name="shippingcountry"
                          value={values.shippingcountry}
                          onChange={handleChange("shippingcountry")}
                          onBlur={handleBlur("shippingcountry")}
                          error={errors.shippingcountry}
                          disabled={user?.AccountAdmin ? false : true}
                        />
                      </div>

                      <div className="col-md-2">
                        <Input
                          label={strings.getString("shippingcity")}
                          name="shippingcity"
                          value={values.shippingcity}
                          onChange={handleChange("shippingcity")}
                          onBlur={handleBlur("shippingcity")}
                          error={errors.shippingcity}
                          disabled={user?.AccountAdmin ? false : true}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-2">
                        <Input
                          label={strings.getString("shippingstate")}
                          name="shippingstate"
                          value={values.shippingstate}
                          onChange={handleChange("shippingstate")}
                          onBlur={handleBlur("shippingstate")}
                          error={errors.shippingstate}
                          disabled={user?.AccountAdmin ? false : true}
                        />
                      </div>

                      <div className="col-md-2">
                        <Input
                          label={strings.getString("shippingstreet")}
                          name="shippingstreet"
                          value={values.shippingstreet}
                          onChange={handleChange("shippingstreet")}
                          onBlur={handleBlur("shippingstreet")}
                          error={errors.shippingstreet}
                          disabled={user?.AccountAdmin ? false : true}
                        />
                      </div>
                    </div>
                    <Input
                      label={strings.getString("shippingaddress")}
                      name="shippingaddress"
                      value={`${values.shippingstreet} ${values.shippingstate}/${values.shippingcity},${values.shippingcountry}`}
                      onChange={handleChange("shippingaddress")}
                      onBlur={handleBlur("shippingaddress")}
                      error={errors.shippingaddress}
                      disabled={true}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        flexDirection: "row",
                      }}
                    >
                      {documentTypes &&
                        documentTypes.map((x, i) => {
                          const current = document.find((y) =>
                            y.FileName.includes(x.Name)
                          );
                          return (
                            <>
                              <div style={{ width: "30%" }}>
                                <p
                                  style={{
                                    paddingBlockStart: 4,
                                    fontSize: 13,
                                    fontWeight: 400,
                                    minHeight: 50,
                                    color: color.textPrimary,
                                  }}
                                >
                                  {x.Name}
                                </p>
                                {current !== undefined ? (
                                  <div>{current.FileName}</div>
                                ) : (
                                  <div>
                                    <FileUploader
                                      labelText=""
                                      multiple={false}
                                      selectButtonText={"Select File"}
                                      uploadMode="useForm"
                                      onValueChanged={(e: any) => {
                                        setFieldValue(
                                          `DocumentLists[${i}].File`,
                                          e.value[0]
                                        );
                                        setFieldValue(
                                          `DocumentLists[${i}].Key`,
                                          x.Key
                                        );
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            </>
                          );
                        })}
                    </div>

                    <p style={{ color: "red" }}>{errors.DocumentLists}</p>
                  </Form>
                  <Divider
                    className="company-details-divider"
                    vertical={true}
                    style={{
                      height: 383,
                      marginInline: 32,
                    }}
                  />
                </div>
                <CompanyDetailCard onClickMessage={onClickMessage} />
                <div
                  style={{
                    marginBlockStart: 40,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {user?.AccountAdmin && (
                    <Button
                      type="submit"
                      title={strings.getString("save") + "➔"}
                      onClick={handleSubmit}
                      style={{ width: 100 }}
                    />
                  )}
                </div>
              </>
            );
          }}
        </Formik>{" "}
      </form>
    </div>
  );
};
export default CompanyDetails;
