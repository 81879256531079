import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { SagaActionParams, SagaGenericParams } from "../../types";
import { getListSaga } from "../sagas/getSaga";
import * as T from "./type";
import { PostSagaGenericParams, postSaga, putSaga } from "../sagas/postsaga";

export const getTransporterContractList = createAction(
  "GET_TRANSPORTER___CONTRACTS_LIST",
  ({ payload, params }: SagaActionParams<T.TransporterContractDto[]>) => ({
    payload,
    url:
      "TransporterContracts/GetTransporterContracts" + (params ? params : ""),
  })
);
export const getTransporterContractLoadsList = createAction(
  "GET_TRANSPORTER_CONTRACT_LOAD",
  ({ payload, id }: SagaActionParams<T.TransporterContractLoadDto[]>) => ({
    payload,
    url: "TransporterContracts/GetTransporterContractLoads/" + id,
  })
);

export const getTransporterContractLoadVehicleList = createAction(
  "GET_TRANSPORTER_CONTRACT_LOAD_VEHICLE",
  ({
    payload,
    id,
  }: SagaActionParams<T.TransporterContractLoadVehicleDto[]>) => ({
    payload,
    url: "TransporterContracts/GetVehicles/" + id,
  })
);
export const updateLoadVehicle = createAction(
  "UPDATE_LOAD_VEHICLE",
  ({
    payload,
    body,
  }: SagaActionParams<T.TransporterContractLoadVehicleDto>) => ({
    payload,
    body,
    url: "/TransporterContracts/UpdateLoadVehicle",
  })
);
export const updateLoadVehicleDocument = createAction(
  "UPDATE_LOAD_VEHICLE_DOCUMENT",
  ({
    payload,
    body,
  }: SagaActionParams<T.TransporterContractLoadVehicleDocumentDto>) => ({
    payload,
    body,
    url: "/TransporterContracts/CreateVehicleDocument",
  })
);
export const getTransporterContractLoadVehicleDocumentList = createAction(
  "GET___TRANSPORTER_CONTRACT_LOAD_VEHICLE_DOCUMENT",
  ({
    payload,
    id,
  }: SagaActionParams<T.TransporterContractLoadVehicleDocumentDto[]>) => ({
    payload,
    url: "TransporterContracts/GetVehicleDocument/" + id,
  })
);

export const getCrmInsurances = createAction(
  "GET_CRM_INSURANCES",
  ({ payload, id }: SagaActionParams<T.CrmInsuranceDto[]>) => ({
    payload,
    url: "TransporterContracts/GetCmrInsurances",
  })
);
export const getDrivers = createAction(
  "GET_DRIVERS",
  ({ payload, id }: SagaActionParams<T.DriverDto[]>) => ({
    payload,
    url: "TransporterContracts/GetDriver",
  })
);
export const createDriver = createAction(
  "CREATE_DRIVER",
  ({ payload, body }: SagaActionParams<T.DriverDto[]>) => ({
    payload,
    url: "TransporterContracts/AddDriver",
    body,
  })
);
export const updateDriver = createAction(
  "UPDATE_DRIVER",
  ({ payload, body }: SagaActionParams<T.DriverDto[]>) => ({
    payload,
    url: "TransporterContracts/UpdateDriver",
    body,
  })
);

export const getPlates = createAction(
  "GET_PLATES",
  ({ payload, id }: SagaActionParams<T.PlateDto[]>) => ({
    payload,
    url: "TransporterContracts/GetVehicle",
  })
);
export const createPlate = createAction(
  "CREATE_PLATE",
  ({ payload, body }: SagaActionParams<T.DriverDto[]>) => ({
    payload,
    url: "TransporterContracts/CreateVehicle",
    body,
  })
);
export const updatePlate = createAction(
  "UPDATE_PLATE",
  ({ payload, body }: SagaActionParams<T.DriverDto[]>) => ({
    payload,
    url: "TransporterContracts/UpdateVehicle",
    body,
  })
);
export const getVehicleDocument = createAction(
  "GET_VEHICLE_DOCUMENT",
  ({ payload, id }: SagaActionParams<T.VehicleDocumentDto>) => ({
    payload,
    url: "TransporterContracts/GetVehicleDocument/" + id,
  })
);
export const createVehicleDocument = createAction(
  "CREATE_VEHICLE_DOCUMENT",
  ({ payload, body }: SagaActionParams<any>) => ({
    payload,
    url: "TransporterContracts/CreateVehicleDocument",
    body,
  })
);
const trContractsSagas = [
  takeLatest(
    updateLoadVehicle.toString(),
    (payload: PostSagaGenericParams<any>) => putSaga<any>(payload)
  ),
  takeLatest(getVehicleDocument.toString(), (payload: SagaGenericParams<any>) =>
    getListSaga<any>(payload)
  ),
  takeLatest(
    getTransporterContractList.toString(),
    (payload: SagaGenericParams<any[]>) => getListSaga<any[]>(payload)
  ),
  takeLatest(
    getTransporterContractLoadsList.toString(),
    (payload: SagaGenericParams<any[]>) => getListSaga<any[]>(payload)
  ),
  takeLatest(
    getTransporterContractLoadVehicleList.toString(),
    (payload: SagaGenericParams<any[]>) => getListSaga<any[]>(payload)
  ),
  takeLatest(
    getTransporterContractLoadVehicleDocumentList.toString(),
    (payload: SagaGenericParams<any[]>) => getListSaga<any[]>(payload)
  ),
  takeLatest(getCrmInsurances.toString(), (payload: SagaGenericParams<any[]>) =>
    getListSaga<any[]>(payload)
  ),
  takeLatest(getDrivers.toString(), (payload: SagaGenericParams<any[]>) =>
    getListSaga<any[]>(payload)
  ),
  takeLatest(getPlates.toString(), (payload: SagaGenericParams<any[]>) =>
    getListSaga<any[]>(payload)
  ),
  takeLatest(createDriver.toString(), (payload: PostSagaGenericParams<any>) =>
    postSaga<any>(payload)
  ),
  takeLatest(createPlate.toString(), (payload: PostSagaGenericParams<any>) =>
    postSaga<any>(payload)
  ),

  takeLatest(updatePlate.toString(), (payload: PostSagaGenericParams<any>) =>
    putSaga<any>(payload)
  ),
  takeLatest(updateDriver.toString(), (payload: PostSagaGenericParams<any>) =>
    putSaga<any>(payload)
  ),
  takeLatest(
    updateLoadVehicleDocument.toString(),
    (payload: PostSagaGenericParams<any>) => postSaga<any>(payload)
  ),
];
export default trContractsSagas;
