import { useSelector } from "react-redux";
import { getLocalizedHomepage } from "../../../../../redux/selectors";
import { color } from "../../../../../utils/Properties";
import "./style.css";
import withDirection, {
  withDirectionPropTypes,
  DIRECTIONS,
  //@ts-ignore
} from "react-with-direction";
import { Icon } from "../../../../../components";

const Stats = ({ direction }: withDirectionPropTypes) => {
  const strings = useSelector(getLocalizedHomepage);

  const storyItems: {
    id: number;
    icon: string;
    content: string;
    number: number | string;
  }[] = [
      {
        id: 1,
        icon: "hardhat",
        content: strings.getString("experience"),
        number: 59,
      },
      {
        id: 2,
        icon: "trucks",
        content: strings.getString("loadingtruck"),
        number: "429.000",
      },
      {
        id: 3,
        icon: "globe-2",
        content: strings.getString("officesinworld"),
        number: 9,
      },
      {
        id: 4,
        icon: "roadkm",
        content: strings.getString("millionkmroad"),
        number: "40+",
      },
    ];

  return (
    <div className="story">
      <h3
        className="bigHeadingStyle"
        style={{
          color: color.textHeader,
          marginBlockEnd: 55,
          textAlign: "center",
        }}
      >
        {strings.getString("succes_story")}
      </h3>
      <div className="storyItems ">
        {storyItems.map((item) => {
          return (
            <div key={item.id} className="storyItem">
              <div
                className="centeringDiv"
                style={{
                  width: 112,
                  height: 112,
                  borderRadius: 60,
                  backgroundColor: color.accent,
                }}
              >
                <div
                  className="centeringDiv"
                  style={{
                    width: 92,
                    height: 92,
                    border: "1px solid #00a3e0",
                    borderRadius: 60,
                    backgroundColor: color.accent,
                  }}
                >
                  <Icon
                    icon={item.icon}
                    size={40}
                    style={{
                      transform:
                        direction === DIRECTIONS.LTR
                          ? "rotateY(0)"
                          : "rotateY(180deg)",
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  textAlign: "center",
                  color: color.side3,
                  fontWeight: 900,
                }}
              >
                <p className="bigHeadingStyle" style={{ color: color.side3 }}>
                  {item.number}
                </p>
                <p>{item.content}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default withDirection(Stats);
