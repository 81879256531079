import { forwardRef, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popover, Whisper } from "rsuite";
import { Button, Icon, Loader, Nav } from "..";
import {
  getLocalizedMyProfile,
  getLocalizedTruckCard,
} from "../../redux/selectors";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import {
  color,
  CUSTOM_MAP,
  GOOGLE_MAPS_API_KEY,
  mapContainerStyles,
} from "../../utils/Properties";
import moment from "moment";
import { VehicleOpportunityDto } from "../../redux/opportunity/type";
import Flag from "react-flagkit";
import { getCurrency } from "../../utils/Helpers";
import ScrollBar from "react-perfect-scrollbar";

import "./style.scss";
import { getVehicleDetailWithoutLoader } from "../../redux/actions";
type TruckCardProps = {
  truck: VehicleOpportunityDto;
  isOffer?: boolean;
  cancelOpportunity?: () => void;
  useEp?: boolean;
};

const ProfileTruckCard = forwardRef<HTMLDivElement, TruckCardProps>(
  ({ truck, isOffer = true, useEp, cancelOpportunity }, ref) => {
    const [loader, setLoader] = useState(false);
    const [selected, setSelected] = useState(truck);
    const dispatch = useDispatch();
    const strings = useSelector(getLocalizedTruckCard);
    const cardRef = useRef(null);
    const [show, setShow] = useState(false);
    const profilestrings = useSelector(getLocalizedMyProfile);
    const navContent = [
      {
        id: 0,
        icon: "list",
        activeColor: color.primary,
        label: strings.getString("details"),
      },
      {
        id: 1,
        icon: "indicator",
        activeColor: color.primary,
        label: strings.getString("extra_infos"),
      },
      {
        id: 2,
        icon: "shorter-list",
        activeColor: color.primary,
        label: strings.getString("other_details"),
      },
      {
        id: 3,
        icon: "route",
        activeColor: color.primary,
        label: strings.getString("routes"),
      },
      {
        id: 4,
        icon: "map-pin",
        activeColor: color.primary,
        label: strings.getString("map"),
      },
    ];

    const postEndCalculater = selected.lastofferdate
      ? new Date(selected.lastofferdate).getTime() - new Date().getTime()
      : new Date().getTime() - new Date().getTime();

    return (
      <div key={selected.id} ref={cardRef}>
        <div
          ref={ref}
          className="truckResultCard"
          style={{
            fontSize: 10,
            color: color.textPrimary + color.thirtyOpacity,
            backgroundColor: color.pageBgColor,
          }}
        >
          {loader && <Loader />}
          {isOffer && (
            <p
              className="boldText"
              style={{ padding: "20px 20px 0px 20px", fontSize: 16 }}
            >
              {strings.getString("Vehicle On Offer")}:
            </p>
          )}
          <div style={{ width: "100%", display: "flex" }}>
            <div
              style={{
                marginInlineStart: 10,
                width: `100%`,
                paddingBlock: "12px 2px",
                paddingInline: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: 30,
              }}
            >
              <div
                className="vehicleLogo"
                style={{
                  backgroundColor: color.accent,
                  borderRadius: 10,
                }}
              >
                <img
                  className="imageStyles"
                  style={{ borderRadius: 10 }}
                  src={selected.profileimage}
                  alt=""
                />
              </div>
              <div className="content smallRegularText">
                <div>
                  <p className="boldText" style={{ color: color.blackFifty }}>
                    {strings.getString("file_number")}
                  </p>
                  <p>{"#" + selected.filenumber}</p>
                </div>
                <div className="divider-profile-selected-card" />
                <div>
                  <p className="boldText" style={{ color: color.blackFifty }}>
                    {strings.getString("truck_type")}
                  </p>
                  <p>{selected.trucktypename}</p>
                </div>
                <div className="divider-profile-selected-card" />
                <div>
                  <p className="boldText" style={{ color: color.blackFifty }}>
                    {strings.getString("destination")}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingBlockStart: 8,
                    }}
                  >
                    <p>{selected.loadcountry ? selected.loadcountry : "-"}</p>
                    <Icon
                      icon="right-bold"
                      size={13}
                      style={{ marginInline: 3 }}
                    />
                    <p>{selected.tocountry ? selected.tocountry : "-"}</p>
                  </div>
                </div>

                <div className="divider-profile-selected-card" />
                <div>
                  <p className="boldText" style={{ color: color.blackFifty }}>
                    {strings.getString("date")}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingBlockStart: 8,
                    }}
                  >
                    <p>
                      {selected.lastofferdate
                        ? moment(selected.firstavailabilitydate).format("L")
                        : "-"}
                    </p>
                    <Icon
                      icon="right-bold"
                      size={13}
                      style={{ marginInline: 3 }}
                    />
                    <br />
                    <p>
                      {selected.lastofferdate
                        ? moment(selected.lastavailabilitydate).format("l")
                        : "-"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {!isOffer && cancelOpportunity && !selected.status__c && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginInlineEnd: 10,
                }}
              >
                <Button
                  style={{
                    borderColor: color.error,
                    backgroundColor: color.error,
                    marginInlineStart: 20,
                    width: 100,
                    height: 30,
                  }}
                  title={profilestrings.getString("Cancel Opportunity")}
                  onClick={() => {
                    cancelOpportunity();
                  }}
                />
              </div>
            )}
          </div>
          <div
            style={{
              marginInlineStart: 8,
              marginBlockEnd: 5,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginInlineStart: 10,
                width: `calc(100% - 38px)`,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <p
                  className="boldText"
                  style={{ fontSize: 14, color: color.textPrimary }}
                >
                  {selected.rapidadvisorname}
                </p>
                <span className="smallText" style={{ color: color.side }}>
                  {selected.publishDate
                    ? moment(selected.publishDate).format("l")
                    : ""}
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    width: 160,
                    textAlign: "center",
                    marginInlineEnd: 30,
                  }}
                >
                  <p
                    className="smallBoldText"
                    style={{ color: color.blackFifty }}
                  >
                    {strings.getString("post_end_date")}
                  </p>
                  <span
                    className="thinText"
                    style={
                      postEndCalculater < 432000000 && postEndCalculater > 0
                        ? { color: color.error }
                        : {}
                    }
                  >
                    {selected.lastofferdate
                      ? moment(selected.lastofferdate).format("l")
                      : ""}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className="extraInfos"
            style={{
              color: color.textPrimary + color.fiftyOpacity,
              borderColor: color.black + "16",
              borderBlockEnd: "1px solid #18181816",
              backgroundColor: "#f5f5f5",
            }}
          >
            <div
              className="standardText"
              style={{
                display: "flex",
                alignItems: "center",
                marginInlineEnd: 50,
              }}
            >
              <Icon icon="clock" size={17} style={{ marginInlineEnd: 5 }} />
              <p style={{ marginInlineEnd: 5, fontWeight: 700 }}>
                {strings.getString("estimated_arrival")}
              </p>
              <span style={{ marginInlineEnd: 30 }}>
                {selected.estimatedarrivaltime
                  ? Math.round(selected.estimatedarrivaltime)
                  : "-"}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: 44,
                width: "max-content",
                border: "1px solid",
                borderColor: color.borderSecondary,
                borderRadius: 5,
                cursor: "pointer",
                backgroundColor: color.accent,
              }}
            >
              <Whisper
                delay={500}
                placement="top"
                trigger="hover"
                speaker={
                  <Popover>
                    <p>{strings.getString("details")}</p>
                  </Popover>
                }
                enterable
              >
                <div
                  className="extraInfosButton"
                  style={{
                    borderColor: color.borderSecondary,
                    backgroundColor: "#74747416",
                    borderStartStartRadius: 5,
                    borderEndStartRadius: 5,
                  }}
                  onClick={() => {
                    if (!useEp) {
                      setShow(!show);
                    } else if (show === true) {
                      setShow(!show);
                    } else {
                      setLoader(true);
                      dispatch(
                        getVehicleDetailWithoutLoader({
                          payload: {
                            onSuccess: (message, payload) => {
                              setLoader(false);
                              payload && setSelected(payload);
                              setShow(!show);
                            },
                            onError: (message) => {
                              setLoader(false);
                            },
                          },
                          url: `VehicleOpportunity/GetByVehicleOpportunities?sfid=${selected.destination_vehicletype_vehicleopp__c}`,
                        })
                      );
                    }
                  }}
                >
                  <Icon icon="more" />
                </div>
              </Whisper>
            </div>
          </div>
          <div
            className="extraDetails"
            style={{
              height: show ? 293 : 0,
            }}
          >
            <Nav content={navContent}>
              {(activeTab) => {
                return (
                  <div
                    className="extraDetailsContent"
                    style={{
                      paddingInline: activeTab !== 4 ? 32 : 0,
                    }}
                  >
                    <ScrollBar>
                      {activeTab !== 4 && (
                        <div className="extraDetailsFirstTab">
                          <div className="extraDetailsFirstTabFirstCol">
                            {(activeTab === 0 || activeTab === undefined) && (
                              <>
                                <div
                                  className="dividedRow"
                                  style={{ marginBlockEnd: 10 }}
                                >
                                  <div style={{ width: "50%" }}>
                                    <p
                                      className="boldText"
                                      style={{ color: color.textSecondary }}
                                    >
                                      {strings.getString("loading_area")}
                                    </p>
                                    <ul className="areasList smallRegularText">
                                      {selected.loadpoint}
                                    </ul>
                                  </div>
                                  <div style={{ width: "50%" }}>
                                    <p
                                      className="boldText"
                                      style={{ color: color.textSecondary }}
                                    >
                                      {strings.getString("unloading_area")}
                                    </p>
                                    <ul className="areasList smallRegularText">
                                      {selected.unloadpoint}
                                    </ul>
                                  </div>
                                </div>

                                <div
                                  className="dividedRow"
                                  style={{ marginBlockEnd: 10 }}
                                >
                                  <div style={{ width: "50%" }}>
                                    <p
                                      className="boldText"
                                      style={{ color: color.textSecondary }}
                                    >
                                      {strings.getString("loading_type")}
                                    </p>
                                    <span className="smallRegularText">
                                      {selected.loadingtype
                                        ? selected.loadingtype
                                        : "-"}
                                    </span>
                                  </div>
                                  <div style={{ width: "50%" }}>
                                    <p
                                      className="boldText"
                                      style={{ color: color.textSecondary }}
                                    >
                                      {strings.getString("unloading_type")}
                                    </p>
                                    <span className="smallRegularText">
                                      {selected.unloadingtype
                                        ? selected.unloadingtype
                                        : "-"}
                                    </span>
                                  </div>
                                </div>
                                <div
                                  className="dividedRow"
                                  style={{ marginBlockEnd: 10 }}
                                >
                                  <div style={{ width: "50%" }}>
                                    <p
                                      className="boldText"
                                      style={{ color: color.textSecondary }}
                                    >
                                      {strings.getString("maxWeight")}
                                    </p>
                                    <span className="smallRegularText">
                                      {selected.weight ? selected.weight : "-"}
                                    </span>
                                  </div>
                                  <div style={{ width: "50%" }}>
                                    <p
                                      className="boldText"
                                      style={{ color: color.textSecondary }}
                                    >
                                      {strings.getString("volumemt")}
                                    </p>
                                    <span className="smallRegularText">
                                      {selected.volume ? selected.volume : "-"}
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                            {activeTab === 1 && (
                              <>
                                <div style={{ width: "100%" }}>
                                  <p
                                    className="boldText"
                                    style={{ color: color.textSecondary }}
                                  >
                                    {strings.getString("measurements")}
                                  </p>
                                  <div
                                    className="smallRegularText"
                                    style={{ display: "flex" }}
                                  >
                                    <div style={{ marginInlineEnd: 10 }}>
                                      <span className="smallRegularText">
                                        {strings.getString("width") + ": "}
                                      </span>
                                      <span>
                                        {selected.vehiclewidth
                                          ? selected.vehiclewidth + "m"
                                          : "-"}
                                      </span>
                                    </div>
                                    <div style={{ marginInlineEnd: 10 }}>
                                      <span className="smallRegularText">
                                        {strings.getString("height") + ": "}
                                      </span>
                                      <span>
                                        {selected.vehicleheight
                                          ? selected.vehicleheight + "m"
                                          : "-"}
                                      </span>
                                    </div>
                                    <div>
                                      <span className="smallRegularText">
                                        {strings.getString("length") + ": "}
                                      </span>
                                      <span>
                                        {selected.vehiclelength
                                          ? selected.vehiclelength + "m"
                                          : "-"}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "100%",
                                    marginBlockStart: 10,
                                  }}
                                >
                                  <p
                                    className="boldText"
                                    style={{ color: color.textSecondary }}
                                  >
                                    {strings.getString("equipments")}:
                                  </p>{" "}
                                  <span className="smallRegularText">
                                    {selected.equipments}
                                  </span>
                                </div>
                              </>
                            )}
                            {activeTab === 2 && (
                              <>
                                <div
                                  style={{
                                    width: "100%",
                                    marginBlockStart: 10,
                                  }}
                                >
                                  <p
                                    className="boldText"
                                    style={{ color: color.textSecondary }}
                                  >
                                    {strings.getString(
                                      "Does it Have Third Country Permission or Cemt"
                                    )}
                                  </p>{" "}
                                  <span className="smallRegularText">
                                    {selected.thirdcountrycertificate
                                      ? selected.thirdcountrycertificate ===
                                        true
                                        ? strings.getString("yes")
                                        : strings.getString("no")
                                      : "-"}
                                  </span>
                                </div>
                                <div style={{ width: "100%" }}>
                                  <p
                                    className="boldText"
                                    style={{ color: color.textSecondary }}
                                  >
                                    {strings.getString("Countries")}:
                                  </p>{" "}
                                  <span className="smallRegularText">
                                    {selected.thirdcountryname
                                      ? selected.thirdcountryname
                                      : "-"}
                                  </span>
                                </div>
                                <div style={{ width: "100%" }}>
                                  <p
                                    className="boldText"
                                    style={{ color: color.textSecondary }}
                                  >
                                    {strings.getString(
                                      "Transport Declaration Document Type"
                                    )}
                                    :{" "}
                                  </p>
                                  <span className="smallRegularText">
                                    {selected.trucktransitdeclarationtype
                                      ? selected.trucktransitdeclarationtype
                                      : "-"}
                                  </span>
                                </div>{" "}
                                <div style={{ width: "100%" }}>
                                  <p
                                    className="boldText"
                                    style={{ color: color.textSecondary }}
                                  >
                                    {strings.getString(
                                      "Can Provide Second Driver"
                                    )}{" "}
                                  </p>
                                  <span className="smallRegularText">
                                    {selected.seconddrivercheckbox
                                      ? selected.seconddrivercheckbox === true
                                        ? strings.getString("yes")
                                        : strings.getString("no")
                                      : "-"}
                                  </span>
                                </div>
                              </>
                            )}
                            {activeTab === 3 && (
                              <>
                                <p
                                  className="boldText"
                                  style={{ color: color.textSecondary }}
                                >
                                  {strings.getString("destination")}
                                </p>
                                {selected.RouteTab?.map((route, i) => (
                                  <div
                                    key={i}
                                    className="smallRegularText"
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <p>{route.route} - &nbsp; </p>
                                    <span>
                                      {Math.round(route.transittime) ?? 0} Days
                                      - &nbsp;
                                    </span>
                                    {isOffer && (
                                      <span>
                                        {getCurrency(
                                          route.targetprice,
                                          route.currencyisocode
                                        )}
                                      </span>
                                    )}
                                  </div>
                                ))}
                              </>
                            )}
                          </div>
                          <div
                            style={{
                              width: "5%",
                              height: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                width: 1,
                                backgroundColor: color.borderColor,
                              }}
                            />
                          </div>
                          <div className="extraDetailsFirstTabSecondCol">
                            <p
                              className="boldText"
                              style={{ color: color.textSecondary }}
                            >
                              {strings.getString("language")}
                            </p>
                            <div
                              style={{
                                width: 70,
                                display: "flex",
                                justifyContent: "flex-start",
                                flexWrap: "wrap",
                                gap: "4",
                              }}
                            >
                              {selected?.Owner?.flags?.split(";").map((x) => {
                                return (
                                  <Flag
                                    style={{ marginRight: 3 }}
                                    country={x === "EN" ? "US" : x}
                                    title={x}
                                    className="flag"
                                  />
                                );
                              })}
                            </div>
                            <p
                              className="boldText"
                              style={{
                                color: color.textSecondary,
                                marginBlockEnd: 0,
                                marginBlockStart: 5,
                              }}
                            >
                              {strings.getString("contact_details")}
                            </p>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Icon
                                icon="mail"
                                size={20}
                                style={{ marginInlineEnd: 5 }}
                              />
                              <span
                                className="boldText"
                                style={{
                                  textDecoration: "underline",
                                  fontWeight: 600,
                                }}
                              >
                                {selected.Owner?.email}
                              </span>
                            </div>
                            <div style={{ display: "flex" }}>
                              <Icon
                                icon="mobile"
                                size={20}
                                style={{ marginInlineEnd: 5 }}
                              />
                              <span
                                className="boldText"
                                style={{ fontWeight: 600 }}
                              >
                                {selected.Owner?.mobilephone
                                  ? selected.Owner?.mobilephone
                                  : "-"}
                              </span>
                            </div>
                            <div style={{ display: "flex" }}>
                              <Icon
                                icon="phone"
                                color={color.black}
                                size={15}
                                style={{
                                  marginInlineEnd: 7,
                                  marginInlineStart: 3,
                                }}
                              />
                              <span
                                className="boldText"
                                style={{ fontWeight: 600 }}
                              >
                                {selected.Owner?.phone
                                  ? selected.Owner.phone
                                  : "-"}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                      {activeTab === 4 && (
                        <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
                          <GoogleMap
                            id="rapidMap"
                            options={{
                              //@ts-ignore
                              styles: CUSTOM_MAP,
                              disableDefaultUI: true,
                              zoomControl: true,
                            }}
                            mapContainerStyle={mapContainerStyles}
                            center={{ lat: 38.4539188, lng: 27.1761196 }}
                            zoom={15}
                          ></GoogleMap>
                        </LoadScript>
                      )}
                    </ScrollBar>
                  </div>
                );
              }}
            </Nav>
          </div>
        </div>
      </div>
    );
  }
);

export default ProfileTruckCard;
