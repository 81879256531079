import { createAction } from "redux-smart-actions";
import {
  AccountTypeParams,
  CommunicationLanguageObject,
  LoadingTypes,
  LoadOpportunities,
  LoadOpportunitiesResponse,
  SagaActionParams,
  TransportTypes,
  TruckTypes,
} from "../../types";
import { VehicleOpportunityDto } from "../opportunity/type";
import * as SagaParams from "../sagas/staticSaga";

export const getAccountTypes = createAction(
  "GET_ACCOUNT_TYPES",
  ({ payload, url }: SagaActionParams<AccountTypeParams[]>) => ({
    payload,
    url,
  })
);

export const getTransportTypes = createAction(
  "GET_TRANSPORT_TYPES",
  ({ payload, url }: SagaActionParams<TransportTypes[]>) => ({
    payload,
    url,
  })
);

export const getTruckTypes = createAction(
  "GET_TRUCK_TYPES",
  ({ payload, url }: SagaActionParams<TruckTypes[]>) => ({
    payload,
    url,
  })
);
export const getLoadingTypes = createAction(
  "GET_LOADING_TYPES",
  ({ payload, url }: SagaActionParams<LoadingTypes[]>) => ({
    payload,
    url,
  })
);
export const getLoadOpportunities = createAction(
  "GET_LOAD_OPPURTUNITIES",
  ({ payload, url }: SagaActionParams<LoadOpportunitiesResponse>) => ({
    payload,
    url,
  })
);
export const getLoadOpportunityDetail = createAction(
  "GET_LOAD_OPPURTUNITY_DETAIL",
  ({ payload, url }: SagaActionParams<LoadOpportunities>) => ({
    payload,
    url,
  })
);
export const getLoadOpportunityDetailWithoutLoader = createAction(
  "GET_LOAD_OPPURTUNITY_DETAIL_WITHOUTLOADER",
  ({ payload, url }: SagaActionParams<LoadOpportunities>) => ({
    payload,
    url,
  })
);
export const getVehicleDetailWithoutLoader = createAction(
  "GET_VEHICLE_DETAIL_WITHOUTLOADER",
  ({ payload, url }: SagaActionParams<VehicleOpportunityDto>) => ({
    payload,
    url,
  })
);

export const getVehicleOpportunityDetail = createAction(
  "GET_VEHICLE_OPPURTUNITY_DETAIL",
  ({ payload, url }: SagaActionParams<VehicleOpportunityDto>) => ({
    payload,
    url,
  })
);
export const getCommunicationLanguages = createAction(
  "GET_COMMUNICATION_LANGUAGE",
  ({ payload, url }: SagaActionParams<CommunicationLanguageObject[]>) => ({
    payload,
    url,
  })
);

/**
 * SETTERS
 */
export const registerCompany = createAction(
  "REGISTER_COMPANY",
  (payload: SagaParams.RegisterCompanySagaParams) => ({
    payload,
  })
);

export const shipperOffer = createAction(
  "SHIPPER_OFFER",
  (payload: SagaParams.ShipperOfferSagaParams) => ({
    payload,
  })
);

export const register = createAction(
  "REGISTER",
  (payload: SagaParams.RegisterSagaParams) => ({
    payload,
  })
);
export const changePassword = createAction(
  "CHANGE_PASSWORD",
  (payload: SagaParams.ChangePasswordParams) => ({
    payload,
  })
);
export const forgotPassword = createAction(
  "FORGOT_PASSWORD",
  (payload: SagaParams.ForgotPasswordParams) => ({
    payload,
  })
);
export const emailconfirm = createAction(
  "EMAIL_CONFITM",
  (payload: SagaParams.EmailConfirmParams) => ({
    payload,
  })
);

export const updateProfile = createAction(
  "UPDATE_PROFILE",
  (payload: SagaParams.UpdateProfileSagaParams) => ({
    payload,
  })
);

export const updateCompany = createAction(
  "UPDATE_COMPANY",
  (payload: SagaParams.UpdateCompanySagaParams) => ({
    payload,
  })
);
