import { useSelector } from "react-redux";
import { images } from "../../../assets";
import { getLocalizedAuth } from "../../../redux/selectors";
import { Helmet } from "react-helmet";
import { getLocalizedHelmet } from "../../../redux/selectors";

const AuthError = () => {
  const helmetStrings = useSelector(getLocalizedHelmet);
  const strings = useSelector(getLocalizedAuth);
  return (
    <>
      <Helmet>
        <title>{helmetStrings.getString("ErrorpageHelmet")}</title>
      </Helmet>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          flexDirection: "column",
        }}
      >
        <img src={images.noaccess} alt={""} />
        <p style={{ color: "#ca4f4f" }} className={"bigHeadingStyle"}>
          {strings.getString("noauth")}
        </p>
      </div>
    </>
  );
};
export default AuthError;
