import { ButtonHTMLAttributes } from "react";
import { Icon } from "..";
import { color } from "../../utils/Properties";

export enum ButtonType {
  default,
  basic,
  inverted,
  soft,
}

interface ButtonProps extends ButtonHTMLAttributes<any> {
  title?: string;
  icon?: string;
  type?: "submit" | "reset" | "button";
  style?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  iconPosition?: "start" | "end";
  iconColor?: string;
  iconSize?: number;
  titleStyle?: React.CSSProperties;
  buttonType?: ButtonType;
}

const Button = ({
  title,
  icon,
  type,
  style,
  titleStyle,
  iconStyle,
  iconPosition,
  iconColor,
  iconSize,
  buttonType,
  ...rest
}: ButtonProps) => {
  const buttonStyles = () => {
    const defStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 5,
      fontWeight: 700,
      fontSize: 12,
      paddingBlock: 13,
      cursor: "pointer",
      width: "100%",
      border: "1px solid",
      outline: "none",
    };
    switch (buttonType) {
      case ButtonType.basic:
        return {
          ...defStyle,
          backgroundColor: color.accent,
          border: "1px solid",
          borderColor: color.black,
          color: color.textPrimary,
          fontSize: 10,
          paddingBlock: 10,
        };
      case ButtonType.inverted:
        return {
          ...defStyle,
          borderColor: color.primary,
          backgroundColor: "transparent",
          border: "1px solid",
          color: color.primary,
        };
      case ButtonType.soft:
        return {
          ...defStyle,
          backgroundColor: color.bgPrimarySoft,
          color: color.primary,
          border: "none",
          paddingBlock: 5,
          borderRadius: 40,
          fontSize: 10,
          letterSpacing: 0.9,
        };
      case ButtonType.default:
      default:
        return {
          ...defStyle,
          border: "1px solid",
          borderColor: color.primary,
          backgroundColor: color.primary,
          color: color.textAccent,
        };
    }
  };

  return (
    <button
      type={type}
      style={{
        ...buttonStyles(),
        ...(style || {}),
      }}
      {...rest}
    >
      {icon && iconPosition === "start" && (
        <div
          style={{
            width: "16px",
            height: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ...(iconStyle || {}),
            borderRadius: 5,
            marginInlineEnd: 5,
          }}
        >
          <Icon
            icon={icon}
            color={iconColor ? iconColor : "white"}
            size={iconSize ? iconSize : 14}
          />
        </div>
      )}
      {title && <p style={{ width: "100%" }}>{title.toLocaleUpperCase()}</p>}
      {icon && iconPosition === "end" && (
        <div
          style={{
            width: "16px",
            height: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 5,
            marginInlineEnd: 10,
            ...(iconStyle || {}),
          }}
        >
          <Icon
            icon={icon}
            color={iconColor ? iconColor : "white"}
            size={iconSize ? iconSize : 14}
          />
        </div>
      )}
    </button>
  );
};

export default Button;
