import React, { useEffect, useRef, useState } from "react";
import { Footer } from "../../../../components";
import "../style.scss";
import { useParams } from "react-router-dom";
import {
  AuctionCounterDto,
  AuctionDetailDto,
} from "../../../../redux/autions/type";
import { useDispatch, useSelector } from "react-redux";
import { getAuctionDetails } from "../../../../redux/autions/saga";
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import { Helmet } from "react-helmet";
import { baseUrl } from "../../../../utils/Properties";
import { getApiParams, getLocalizedHelmet } from "../../../../redux/selectors";
import PurposeAuction from "./purpose";

const AuctionDetail = () => {
  const helmetStrings = useSelector(getLocalizedHelmet);
  const { id }: { id: string } = useParams();
  const dispatch = useDispatch();
  const [auction, setAuction] = useState<AuctionDetailDto>();
  const [counter, setCounter] = useState<AuctionCounterDto>();
  const connectionRef = useRef<HubConnection | null>(null);
  const [connectionStatus, setConnectionStatus] = useState<
    "offline" | "connecting" | "connected" | "failed"
  >("offline");
  const { token, lang } = useSelector(getApiParams);

  const reconnect = (counter: AuctionCounterDto, tender: AuctionDetailDto) => {
    if (
      counter.tender_Start_Date__c !== tender.Tender_Start_Date__c ||
      counter.tender_End_Date__c !== tender.Tender_End_Date__c
    ) {
      try {
        connectionRef.current?.stop().then(() => {
          console.log("CONNECTION STOPPED");
          connectionRef.current = null;
          connectCounter(tender);
        });
      } catch (e) {
        console.log("ERROR UNMOUNT", e);
      }
    }
  };

  const disconnect = () => {
    try {
      connectionRef.current?.stop().then(() => {
        console.log("CONNECTION STOPPED");
        connectionRef.current = null;
      });
    } catch (e) {
      console.log("ERROR UNMOUNT", e);
    }
  };
  useEffect(() => {
    dispatch(
      getAuctionDetails({
        payload: {
          onSuccess: (m, payload, response) => {
            if (payload && payload.length > 0) {
              setAuction({ ...payload[0] });
              if (payload[0].Opportunity_Tender_Type__c === "Target Tender") {
                payload[0].StartingTender &&
                  !payload[0].EndingTender &&
                  connectCounter(payload[0]);
                !payload[0].EndingTender &&
                  !payload[0].StartingTender &&
                  connectStartCounter(payload[0]);
              }
            }
          },
          onError: () => {},
        },
        id: id,
      })
    );
    return () => disconnect();
    // eslint-disable-next-line
  }, [dispatch, id]);

  const connectCounter = (tender: AuctionDetailDto) => {
    const HUB_ENDPOINT =
      baseUrl + "api/targettenderbradcasting?opportunity__c=" + tender.sfid;

    if (connectionRef.current !== undefined) {
      setConnectionStatus("connecting");
      const connection = new HubConnectionBuilder()
        .withUrl(HUB_ENDPOINT, {
          headers: { "Accept-Language": `${lang}` },
          accessTokenFactory: () => token ?? "",
        })
        .configureLogging(LogLevel.Debug)
        .build();
      connection.serverTimeoutInMilliseconds = 3000;
      console.log(`Trying to connect to ${HUB_ENDPOINT}`);
      connection
        .start()
        .then(() => {
          console.log(`Connected to ${HUB_ENDPOINT}`);
          setConnectionStatus("connected");
        })
        .catch((err: any) => {
          setConnectionStatus("failed");
          console.log(`Error starting the connection: ${err.toString()}`);
        });
      connection.onclose(async () => {
        console.log(`Disconnected from ${HUB_ENDPOINT} ${connectionStatus}`);
        connectionRef.current = null;
        connectCounter(tender);
      });
      connection.on(tender.sfid, function (payload: AuctionCounterDto) {
        setCounter(payload);
        if (payload.endingTender) {
          window.location.reload();
        }
        reconnect(payload, tender);
      });
      connectionRef.current = connection;
      setConnectionStatus("connected");
    } else console.log("CONNECTION ALREADY ESTABLIESHED");
    return () => {
      try {
        connectionRef.current?.stop().then(() => {
          console.log("CONNECTION STOPPED");
          connectionRef.current = null;
        });
      } catch (e) {
        console.log("ERROR UNMOUNT", e);
      }
    };
  };
  const connectStartCounter = (tender: AuctionDetailDto) => {
    const HUB_ENDPOINT =
      baseUrl + "api/targettenderbradcasting?opportunity__c=" + tender.sfid;
    console.log("bildirim");
    if (connectionRef.current !== undefined) {
      setConnectionStatus("connecting");
      const connection = new HubConnectionBuilder()
        .withUrl(HUB_ENDPOINT, {
          headers: { "Accept-Language": `${lang}` },
          accessTokenFactory: () => token ?? "",
        })
        .configureLogging(LogLevel.Debug)
        .build();
      connection.serverTimeoutInMilliseconds = 3000;
      console.log(`Trying to connect to ${HUB_ENDPOINT}`);
      connection
        .start()
        .then(() => {
          console.log(`Connected to ${HUB_ENDPOINT}`);
          setConnectionStatus("connected");
        })
        .catch((err: any) => {
          setConnectionStatus("failed");
          console.log(`Error starting the connection: ${err.toString()}`);
        });
      connection.onclose(async () => {
        console.log(`Disconnected from ${HUB_ENDPOINT} ${connectionStatus}`);
        connectionRef.current = null;
        connectStartCounter(tender);
      });
      connection.on(tender.sfid, function (payload: AuctionCounterDto) {
        setCounter(payload);
        if (payload.startingTender) {
          window.location.reload();
        }
        reconnect(payload, tender);
      });
      connectionRef.current = connection;
      setConnectionStatus("connected");
    } else console.log("CONNECTION ALREADY ESTABLIESHED");
    return () => {
      try {
        connectionRef.current?.stop().then(() => {
          console.log("CONNECTION STOPPED");
          connectionRef.current = null;
        });
      } catch (e) {
        console.log("ERROR UNMOUNT", e);
      }
    };
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>
          {helmetStrings.formatString(
            helmetStrings.getString("TenderDetailHelmet"),
            auction ? auction.tender_no__c : ""
          )}
        </title>
      </Helmet>
      {auction && auction.Opportunity_Tender_Type__c === "Target Tender" && (
        <PurposeAuction
          auction={auction}
          setAuction={setAuction}
          counter={counter}
        />
      )}
      <Footer />
    </React.Fragment>
  );
};
export default AuctionDetail;
