import { createAction } from "redux-smart-actions";
import * as O from "../../types/objects";

export const getAllSystem = createAction("GET_ALL_SYSTEM");
export const getContactRoles = createAction("GET_CONTACT_ROLES");
export const getLanguages = createAction("GET_LANGUAGES");

export const setHeaderbarMenu = createAction(
  "SET_SIDEBAR_MENU",
  (payload: {
    left: O.LeftHeaderbarType[];
    right: O.RightHeaderbarType[];
  }) => ({
    payload,
  })
);

export const setNavbarMenu = createAction(
  "SET_NAVBAR_MENU",
  (payload: O.NavbarListType[]) => ({
    payload,
  })
);

export const setContactRoles = createAction(
  "SET_CONTACT_ROLES",
  (payload: O.GeneralSystemObject) => ({ payload })
);

export const setLanguages = createAction(
  "SET_LANGUAGES",
  (payload: O.GeneralSystemObject) => ({ payload })
);

export const setLanguage = createAction("SET_LANGUAGE", (payload: string) => ({
  payload,
}));
