import { useDispatch, useSelector } from "react-redux";
import { Alert, Form, FormGroup } from "rsuite";
import { Button, Icon, Input } from "../../../components";
import { getLocalizedAuth, getLocalizedErrors } from "../../../redux/selectors";
import { color } from "../../../utils/Properties";
import { Formik } from "formik";
import * as Yup from "yup";
import { forgotPassword } from "../../../redux/actions";
import { ForgotPasswordParams } from "../../../types";

type ForgotPasswordProps = {
  onClick: () => void;
};

const ForgotPassword = ({ onClick }: ForgotPasswordProps) => {
  const dispatch = useDispatch();
  const strings = useSelector(getLocalizedAuth);
  const errorStrings = useSelector(getLocalizedErrors);
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().required(errorStrings.getString("required")),
  });

  const onSubmit = ({ email }: { email: string }) => {
    let input: ForgotPasswordParams = {
      Email: email,
    };
    dispatch(
      forgotPassword({
        params: input,
        onSuccess: (message: string) => {
          Alert.success(message, 5000);
          setTimeout(() => {
            window.location.replace("login");
          }, 5000);
        },
        onError: (message: string) => {
          Alert.error(message, 5000);
        },
      })
    );
  };

  return (
    <div className="forgotPasswordContent">
      <div
        className="standardText"
        style={{
          display: "flex",
          cursor: "pointer",
          lineHeight: "18px",
          fontSize: 12,
          marginBlockEnd: "35%",
          width: "max-content",
          color: color.textHeader,
        }}
        onClick={onClick}
      >
        <Icon
          icon="left"
          size={12}
          color={color.black}
          style={{ marginInlineEnd: 10, marginBlockStart: 3 }}
        />
        {strings.getString("back_to_login")}
      </div>
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={ForgotPasswordSchema}
        onSubmit={onSubmit}
      >
        {({ handleChange, handleBlur, handleSubmit, errors, touched }) => (
          <Form>
            <h4
              style={{
                fontWeight: 600,
                fontSize: 36,
                lineHeight: "54px",
                fontStyle: "normal",
                color: color.black,
              }}
            >
              {strings.getString("forgot")}
            </h4>
            <p
              className="headingStyle"
              style={{
                fontWeight: 400,
                color: color.blackFifty,
                marginBlockEnd: "6%",
              }}
            >
              {strings.getString("forgot_description")}
            </p>
            <Input
              label={strings.getString("email")}
              placeholder={strings.getString("email_placeholder")}
              name="email"
              onChange={handleChange("email")}
              onBlur={handleBlur("email")}
              error={errors.email}
              touched={touched.email}
            />
            <FormGroup>
              <Button
                title={strings.getString("send")}
                icon="forward"
                iconPosition="end"
                style={{ marginBlockStart: "4%", paddingBlock: 16 }}
                onClick={handleSubmit}
              />
            </FormGroup>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ForgotPassword;
