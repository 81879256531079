import { useDispatch, useSelector } from "react-redux";
import { Icon, Slider } from "../../../../../components";
import {
  getLocalizedHomepage,
  languageSelector,
} from "../../../../../redux/selectors";
import { color, webImageUrl } from "../../../../../utils/Properties";
import withDirection, {
  withDirectionPropTypes,
  DIRECTIONS,
  //@ts-ignore
} from "react-with-direction";
import "./style.scss";
import { useEffect, useState } from "react";
import { getHomepageBlogs } from "../../../../../redux/actions";
import { BlogObject } from "../../../../../types";
import moment from "moment";
import { useHistory } from "react-router-dom";

const BlogNews = ({ direction }: withDirectionPropTypes) => {
  const dispatch = useDispatch();
  const language = useSelector(languageSelector);
  const strings = useSelector(getLocalizedHomepage);
  const history = useHistory();
  const [blogs, setBlogs] = useState<BlogObject[]>();

  useEffect(() => {
    dispatch(
      getHomepageBlogs({
        payload: {
          onSuccess: (message, payload) => setBlogs(payload),
          onError: (message) => {},
        },
        url: "HomePage/GetBlogs",
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, language]);

  return (
    <div className="blogAndNews" id="news">
      <h2
        className="h2 blogsnews-title"
        style={{
          color: color.black,
          paddingBlock: "70px 15px",
          textAlign: "center",
        }}
      >
        {strings.getString("blog&news")}
      </h2>
      <p
        className="blogsnews-subtitle"
        style={{
          fontSize: 20,
          fontWeight: 400,
          color: color.blackFifty,
          marginBlockEnd: 20,
          textAlign: "center",
        }}
      >
        {strings.getString("news_description")}
      </p>
      <div className="newsCarouselDiv">
        <Slider>
          {blogs?.map((blog) => {
            return (
              <div key={blog.id} className="newsCard">
                <div className="newscard-content">
                  <div
                    className="newscard-img-div"
                    style={{
                      width: "100%",
                      height: 284,
                    }}
                  >
                    <img
                      className="imageStyles"
                      src={webImageUrl + blog.smallimage}
                      alt=""
                    />
                  </div>
                  <p
                    className="boldText newscard-date"
                    style={{
                      paddingBlock: "7px 7px",
                      textTransform: "uppercase",
                      color: "#939393",
                    }}
                  >
                    {moment(blog.date).format("ll") + " | " + blog.owner}
                  </p>
                  <p
                    className="newscard-title"
                    style={{
                      fontSize: 20,
                      fontWeight: 300,
                      lineHeight: "32px",
                      paddingBlockEnd: 7,
                      fontStyle: "normal",
                      color: color.textHeader,
                    }}
                  >
                    {blog.title}
                  </p>
                  <p
                    className="newscard-description"
                    style={{
                      height: 130,
                      maxHeight: 130,
                      overflow: "hidden",
                      color: color.textPrimary,
                      fontSize: 15,
                      lineHeight: "32px",
                    }}
                  >
                    {blog.shortdescription}
                  </p>
                </div>
                <div
                  className="newscard-button"
                  style={{
                    width: 120,
                    height: 44,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    color: color.primary,
                    fontSize: 13,
                    fontWeight: 700,
                    cursor: "pointer",
                  }}
                  onClick={() => history.push("/blogs/" + blog.id)}
                >
                  <span>{strings.getString("read_more")}</span>
                  <Icon
                    icon="right"
                    color={color.primary}
                    size={13}
                    style={{
                      transform:
                        direction === DIRECTIONS.LTR
                          ? "rotateY(0)"
                          : "rotateY(180deg)",
                    }}
                  />
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default withDirection(BlogNews);
