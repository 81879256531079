import { call, put, takeLatest } from "@redux-saga/core/effects";
import { ApiCallback, ApiResponse } from "../../types";
import { resultStatus } from "../../utils/Properties";
import {
  BodyType,
  createRequestSaga,
  hideLoader,
  showLoader,
} from "../actions";
import * as P from "../../types/params";
import * as A from "../actions/staticActions";

import * as U from "../actions/userActions";

export interface ShipperOfferSagaParams extends ApiCallback {
  params: P.ShipperOfferParams;
}
function* shipperOfferSaga({
  payload,
}: {
  type: string;
  payload: ShipperOfferSagaParams;
}) {
  try {
    yield put(showLoader());
    const response: ApiResponse = yield call(createRequestSaga, {
      method: "POST",
      url: "LoadOpportunity/CreateShipperOffer",
      body: payload.params,
      bodyType: BodyType.formdata,
    });
    if (resultStatus(response.ResultStatus)) {
      payload.onSuccess(response.Message);
    } else {
      payload.onError(response.Message);
    }
  } catch (e) {
    console.log("ERROR ADD PROJECT IMPROTANT PLACES SAGA", e);
  } finally {
    yield put(hideLoader());
  }
}

export interface RegisterCompanySagaParams extends ApiCallback {
  params: P.RegisterCompanyParams;
}
function* registerCompanySaga({
  payload,
}: {
  type: string;
  payload: RegisterCompanySagaParams;
}) {
  try {
    yield put(showLoader());
    const response: ApiResponse = yield call(createRequestSaga, {
      method: "POST",
      url: "System/RegisterCompany",
      body: payload.params,
      bodyType: BodyType.formdata,
    });
    if (resultStatus(response.ResultStatus)) {
      payload.onSuccess(response.Message);
    } else {
      payload.onError(response.Message);
    }
  } catch (e) {
    console.log("ERROR ADD PROJECT IMPROTANT PLACES SAGA", e);
  } finally {
    yield put(hideLoader());
  }
}

export interface RegisterSagaParams extends ApiCallback {
  params: P.RegisterParams;
}
function* registerSaga({
  payload,
}: {
  type: string;
  payload: RegisterSagaParams;
}) {
  try {
    yield put(showLoader());
    const response: ApiResponse = yield call(createRequestSaga, {
      method: "POST",
      url: "System/RegisterCompany",
      body: payload.params,
      bodyType: BodyType.formdata,
    });
    console.log("RESPONSE REGISTER", response);
    if (resultStatus(response.ResultStatus)) {
      payload.onSuccess(response.Message);
    } else {
      if (response.StatusCode === 409) {
        payload.onError("emailusedbefore");
      } else {
        payload.onError(response.Message);
      }
    }
  } catch (e) {
    console.log("ERROR ADD PROJECT IMPROTANT PLACES SAGA", e);
  } finally {
    yield put(hideLoader());
  }
}
export interface ChangePasswordParams extends ApiCallback {
  params: P.ChangePasswordParams;
}
function* changePassword({
  payload,
}: {
  type: string;
  payload: ChangePasswordParams;
}) {
  try {
    yield put(showLoader());
    const response: ApiResponse = yield call(createRequestSaga, {
      method: "POST",
      url: "System/SetPassword",
      body: payload.params,
      bodyType: BodyType.formdata,
    });
    if (resultStatus(response.ResultStatus)) {
      payload.onSuccess(response.Message);
    } else {
      payload.onError(response.Message);
    }
  } catch (e) {
    console.log("ERROR Change Password", e);
  } finally {
    yield put(hideLoader());
  }
}
export interface LoginParams extends ApiCallback {
  params: P.LoginParams;
}

function* loginSaga({ payload }: { type: string; payload: LoginParams }) {
  try {
    yield put(showLoader());
    const response: ApiResponse<any> = yield call(createRequestSaga, {
      method: "POST",
      url: "Auth/authenticate",
      body: payload.params,
      bodyType: BodyType.raw,
    });
    if (resultStatus(response.ResultStatus)) {
      payload.onSuccess(response.Message, response.Result);
    } else {
      payload.onError(response.Message);
    }
  } catch (e) {
    console.log("ERROR LOGIN", e);
  } finally {
    yield put(hideLoader());
  }
}
export interface ForgotPasswordParams extends ApiCallback {
  params: P.ForgotPasswordParams;
}
function* forgotPasswordSaga({
  payload,
}: {
  type: string;
  payload: ForgotPasswordParams;
}) {
  try {
    yield put(showLoader());
    const response: ApiResponse = yield call(createRequestSaga, {
      method: "POST",
      url: "System/ResetPassword",
      body: payload.params,
      bodyType: BodyType.formdata,
    });
    if (resultStatus(response.ResultStatus)) {
      payload.onSuccess(response.Message);
    } else {
      payload.onError(response.Message);
    }
  } catch (e) {
    console.log("ERROR Change Password", e);
  } finally {
    yield put(hideLoader());
  }
}
export interface EmailConfirmParams extends ApiCallback {
  params: P.EmailConfirmParams;
}
function* emailconfirmSaga({
  payload,
}: {
  type: string;
  payload: EmailConfirmParams;
}) {
  try {
    yield put(showLoader());
    const response: ApiResponse = yield call(createRequestSaga, {
      method: "POST",
      url: "System/EmailConfirm",
      body: payload.params,
      bodyType: BodyType.formdata,
    });
    if (resultStatus(response.ResultStatus)) {
      payload.onSuccess(response.Message);
    } else {
      payload.onError(response.Message);
    }
  } catch (e) {
    console.log("ERROR EmailConfirm ", e);
  } finally {
    yield put(hideLoader());
  }
}
export interface UpdateProfileSagaParams extends ApiCallback {
  params: P.UpdateProfileParams;
}
function* updateProfileSaga({
  payload,
}: {
  type: string;
  payload: UpdateProfileSagaParams;
}) {
  try {
    yield put(showLoader());
    const response: ApiResponse = yield call(createRequestSaga, {
      method: "PUT",
      url: "Profile/ProfileUpdate",
      body: payload.params,
      bodyType: BodyType.formdata,
    });
    if (resultStatus(response.ResultStatus)) {
      payload.onSuccess(response.Message);
    } else {
      payload.onError(response.Message);
    }
  } catch (e) {
    console.log("ERROR ADD PROJECT IMPROTANT PLACES SAGA", e);
  } finally {
    yield put(hideLoader());
  }
}

export interface UpdateCompanySagaParams extends ApiCallback {
  params: P.UpdateCompanyParams;
}
function* updateCompanySaga({
  payload,
}: {
  type: string;
  payload: UpdateCompanySagaParams;
}) {
  try {
    yield put(showLoader());
    const response: ApiResponse = yield call(createRequestSaga, {
      method: "PUT",
      url: "Profile/CompanyUpdate",
      body: payload.params,
      bodyType: BodyType.formdata,
    });
    if (resultStatus(response.ResultStatus)) {
      payload.onSuccess(response.Message);
    } else {
      payload.onError(response.Message);
    }
  } catch (e) {
    console.log("ERROR ADD PROJECT IMPROTANT PLACES SAGA", e);
  } finally {
    yield put(hideLoader());
  }
}

const staticSaga = [
  takeLatest(A.registerCompany.toString(), registerCompanySaga),
  takeLatest(A.shipperOffer.toString(), shipperOfferSaga),
  takeLatest(A.register.toString(), registerSaga),
  takeLatest(A.changePassword.toString(), changePassword),
  takeLatest(A.forgotPassword.toString(), forgotPasswordSaga),
  takeLatest(A.emailconfirm.toString(), emailconfirmSaga),
  takeLatest(U.loginAction.toString(), loginSaga),
  takeLatest(A.updateProfile.toString(), updateProfileSaga),
  takeLatest(A.updateCompany.toString(), updateCompanySaga),
];

export default staticSaga;
