import { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { StepProps } from ".";
import { getLocalizedLoadRegistration } from "../../../../redux/selectors";
import CreateLocation from "./comp/createLocation";
import { confirm } from "devextreme/ui/dialog";
import { RouteDto } from "../../../../redux/opportunity/type";
import { GetRoutes } from "../../../../redux/opportunity/saga";
import EditLocation from "./comp/editLocation";
import ResponsiveTable, {
  GridButtonType,
} from "../../../../components/ResponsiveTable";
import { generateUUID } from "../../../../utils/Properties";

const Step2 = ({ values, errors, touched }: StepProps) => {
  const strings = useSelector(getLocalizedLoadRegistration);
  const { setFieldValue } = useFormikContext();
  const [showCreate, setShowCreate] = useState(false);
  const [routes, setRoutes] = useState<RouteDto[]>([]);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState<{
    no: string;
    Guzergah__c: string;
    Guzergah__c__Name: string;
    CustomersWantsPrice__c: any;
    Currencyiscode___: string;
  }>();
  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    if (values.fromcountrycode && values.tocountrycode) {
      dispatch(
        GetRoutes({
          payload: {
            onSuccess: (message, payload) => payload && setRoutes(payload),
            onError: () => {},
          },
          url: `System/GetRoutesFilter?From=${values.fromcountrycode}&To=${values.tocountrycode}`,
        })
      );
    } else {
      setRoutes([]);
    }
  }, [dispatch, values.fromcountrycode, values.tocountrycode]);
  const columns = [
    {
      dataField: "Guzergah__c",
      caption: strings.getString("Route"),
      lookup: {
        data: routes,
        key: "sfid",
        labelkey: "name",
      },
    },
    {
      dataField: "CustomersWantsPrice__c",
      caption: strings.getString("Requested Price"),
    },
  ];
  const buttons = [
    {
      type: GridButtonType.edit,
      icon: "dx-icon-edit",
      onClick: (e: any) => {
        setSelected(e);
        return setShowEdit(true);
      },
    },
    {
      type: GridButtonType.default,
      icon: "dx-icon-trash",
      onClick: (e: any) => {
        var confirmResp = confirm(
          strings.getString("Are you sure delete this route"),

          strings.getString("Delete Route")
        );
        confirmResp.then((result) => {
          if (result) {
            const last = values.guzergahList__c.filter(
              (x: any) => x.no !== e.no
            );
            return setFieldValue("guzergahList__c", last);
          }
        });
      },
    },
  ];
  return (
    <>
      {routes.length > 0 && (
        <>
          {" "}
          <p
            className="newBoldHeadingStyle"
            style={{ marginBlock: "40px 18px", textAlign: "center" }}
          >
            {strings.getString("3 Route")}
          </p>
          <ResponsiveTable
            bordered
            data={values.guzergahList__c}
            columns={columns}
            buttons={buttons}
            showCreate={showCreate}
            createForm={
              <div className="editFormOuterDiv">
                <CreateLocation
                  types={routes}
                  show={showCreate}
                  baseValues={values}
                  errors={errors}
                  touched={touched}
                  onHide={() => setShowCreate(false)}
                  onSubmit={(value) => {
                    let array = values.guzergahList__c;
                    value.Guzergah__c.map((x) => {
                      return (array = [
                        ...array,
                        {
                          no: generateUUID(),
                          Guzergah__c: x,
                          CustomersWantsPrice__c: value.CustomersWantsPrice__c,
                        },
                      ]);
                    });
                    return setFieldValue("guzergahList__c", [...array]);
                  }}
                />
              </div>
            }
            editForm={
              selected && (
                <EditLocation
                  data={selected}
                  types={routes}
                  show={showEdit}
                  baseValues={values}
                  errors={errors}
                  touched={touched}
                  onHide={() => setShowEdit(false)}
                  onSubmit={(value) => {
                    const last = values.guzergahList__c.filter(
                      (x: {
                        no: string;
                        Guzergah__c: string;
                        CustomersWantsPrice__c: any;
                      }) => x.no !== value.no
                    );
                    setFieldValue("guzergahList__c", [...last, value]);
                    return setShowCreate(true);
                  }}
                />
              )
            }
          />
        </>
      )}
    </>
  );
};

export default Step2;
