import { useSelector } from "react-redux";
import { getUserState } from "../../../../../redux/selectors";
import { color } from "../../../../../utils/Properties";
import AccountDestinationGrid from "./accountdestination";
import DestinationGrid from "./destination";

const DestinationPage = () => {
  const user = useSelector(getUserState);
  return (
    <div className="my-profile-card" style={{ backgroundColor: color.accent }}>
      <div>
        {(user?.AccountType.Name === "Account" ||
          user?.AccountType.Name === "Lead Account" ||
          user?.AccountType.Name === "Forwarder Customer" ||
          user?.AccountType.Name === "Forwarder" ||
          user?.AccountType.Name === "Candidate Forwarder Musteri" ||
          user?.AccountType.Name === "Candidate Forwarder Nakliyeci" ||
          user?.AccountType.Name === "Candidate Forwarder") && (
          <AccountDestinationGrid />
        )}
      </div>
      <div style={{ marginBlock: 30 }}>
        {(user?.AccountType.Name === "Transporter" ||
          user?.AccountType.Name === "Lead Transporter" ||
          user?.AccountType.Name === "Forwarder Transporter" ||
          user?.AccountType.Name === "Forwarder" ||
          user?.AccountType.Name === "Candidate Forwarder Musteri" ||
          user?.AccountType.Name === "Candidate Forwarder Nakliyeci" ||
          user?.AccountType.Name === "Candidate Forwarder") && (
          <>
            <DestinationGrid />
          </>
        )}
      </div>
    </div>
  );
};
export default DestinationPage;
