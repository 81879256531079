import React, { useState } from "react";
import { Nav, NavProps } from "rsuite";
import NavItem from "rsuite/lib/Nav/NavItem";
import { Icon } from "..";
import { color } from "../../utils/Properties";
import "./style.css";

export interface CustomNavProps extends NavProps {
  content: { id: number; icon: string; activeColor: string; label: string }[];
}
const CustomNav = ({
  content,
  activeKey,
  appearance,
  className,
  justified,
  ...rest
}: CustomNavProps) => {
  return (
    <Nav
      {...rest}
      justified={justified}
      appearance={appearance}
      className={"tabbedNav " + className}
    >
      {content.map((c, i) => {
        return (
          <NavItem
            key={i}
            eventKey={i}
            active={activeKey ? i === activeKey : i === 0}
          >
            <Icon
              icon={c.icon}
              size={18}
              color={c.id === activeKey ? c.activeColor : color.black}
              style={{ marginInlineEnd: 5 }}
            />
            {c.label}
          </NavItem>
        );
      })}
    </Nav>
  );
};
export type NavComponentProps = {
  className?: string;

  justified?: boolean;
  appearance?: any;
  content: { id: number; icon: string; activeColor: string; label: string }[];
  children: (activeTab?: number) => React.ReactNode;
};
const NavComponent = ({
  content,
  appearance,
  className,
  justified,
  children,
}: NavComponentProps) => {
  const [active, setActive] = useState<number>();
  return (
    <>
      <CustomNav
        justified={justified ? justified : false}
        className={className}
        appearance={appearance ? appearance : "tabs"}
        activeKey={active}
        onSelect={(eventKey) => setActive(eventKey)}
        content={content}
      />
      {children(active)}
    </>
  );
};

export default NavComponent;
