import { Loader as Loading } from "rsuite";
import { color } from "../../utils/Properties";

interface Props {
  content?: string;
  style?: React.CSSProperties;
}
const Loader = ({ style, content }: Props) => {
  return (
    <div
      style={{
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        position: "fixed",
        zIndex: 1051,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: color.bgColor + color.backdropTransparency,
        ...(style ?? {}),
      }}
    >
      <Loading size={"lg"} content={content} />
    </div>
  );
};

export default Loader;
