import React from "react";
import { useSelector } from "react-redux";
import { getLocalizedMyProfile } from "../../../../../redux/selectors";
import { color } from "../../../../../utils/Properties";
import IncMyAuctions from "./inc-desc/inc-desc";
const BaseAuctions = () => {
  const strings = useSelector(getLocalizedMyProfile);

  return (
    <React.Fragment>
      <div
        className="my-profile-card"
        style={{ backgroundColor: color.accent, position: "fixed" }}
      >
        <p className="newBoldHeadingStyle" style={{ marginBlockEnd: 20 }}>
          {strings.getString("Profile My Auctions")}
        </p>{" "}
        <IncMyAuctions />
      </div>
    </React.Fragment>
  );
};

export default BaseAuctions;
