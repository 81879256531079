import { useSelector } from "react-redux";
import { getLocalizedMyProfile } from "../../../../../redux/selectors";
import { color } from "../../../../../utils/Properties";

const MyFleet = () => {
  const strings = useSelector(getLocalizedMyProfile);

  return (
    <div
      className="my-profile-card"
      style={{ backgroundColor: color.accent, position: "fixed" }}
    >
      <p
        className="newBoldHeadingStyle"
        style={{ textAlign: "center", color: "#215CA0" }}
      >
        {strings.getString("comingsoon")}
      </p>
    </div>
  );
};
export default MyFleet;
