import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "rsuite";
import { Helmet } from "react-helmet";
import AuctionCard from "./auctionCard";
import "../style.scss";
import { getLocalizedHelmet } from "../../../../../../redux/selectors";
import { getMyIncDescAuctionList } from "../../../../../../redux/autions/saga";
import { IncDescAuctionDto } from "../../../../../../redux/autions/inc-desc-type";

const IncMyAuctions = () => {
  const helmetStrings = useSelector(getLocalizedHelmet);
  const dispatch = useDispatch(); // eslint-disable-next-line
  const [page, setPage] = useState(1); // eslint-disable-next-line
  const [take, setTake] = useState(200); // eslint-disable-next-line
  const [total, setTotal] = useState<number>();
  const [auctions, setAuctions] = useState<IncDescAuctionDto[]>([]);

  useEffect(() => {
    dispatch(
      getMyIncDescAuctionList({
        payload: {
          onSuccess: (m, payload, response) => {
            setAuctions(payload ?? []);
            setTotal(response.TotalCount);
          },
          onError: () => {},
        },
        params: `?_skip=${(page - 1) * take}&_take=${take}`,
      })
    );
  }, [dispatch, page, take]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{helmetStrings.getString("TenderHelmet")}</title>
      </Helmet>
      <div className="mainWidth auctionPage">
        <Row>
          <Col md={24}>
            <div className="auctionCardList">
              {auctions?.map((x, i) => (
                <AuctionCard auction={x} />
              ))}
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default IncMyAuctions;
