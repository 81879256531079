import React, { useEffect, useRef, useState } from "react";
import { Alert, Col, Row } from "rsuite";
import "../style.scss";
import DetailTab from "./detailTab";
import AuctionChatDrawer from "./chat";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getApiParams,
  getLocalizedAuction,
  getUserState,
} from "../../../../redux/selectors";
import IncPriceTab from "./inc-priceTab";
import {
  DescAuctionPriceDto,
  IncDescAuctionDto,
  IncDescAuctionPriceDto,
} from "../../../../redux/autions/inc-desc-type";
import { getIncDescAuctionDetails } from "../../../../redux/autions/saga";
import DescPriceTab from "./desc-priceTab";
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import { baseUrl, color } from "../../../../utils/Properties";
const IncDescAuctionDetails = () => {
  const [auction, setAuction] = useState<IncDescAuctionDto>();
  const history = useHistory();
  const location = useLocation();
  const connectionRef = useRef<HubConnection | null>(null);
  const [connectionStatus, setConnectionStatus] = useState<
    "offline" | "connecting" | "connected" | "failed"
  >("offline");
  const user = useSelector(getUserState);
  const { token, lang } = useSelector(getApiParams);
  const { id }: { id: string } = useParams();
  const [questionModal, setquestionModal] = useState(false);
  const [active, setActive] = useState("1");
  const [priceData, setPriceData] = useState<DescAuctionPriceDto>();
  const [priceDataInc, setPriceDataInc] = useState<IncDescAuctionPriceDto>();
  const t = useSelector(getLocalizedAuction);
  const toggleMenu = (tab: string) => {
    setActive(tab);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (location.search.includes("?chat=true")) {
      setquestionModal(true);
      history.push(location.search.replace("?chat=true", "?"));
    } // eslint-disable-next-line
  }, [location.search]);
  useEffect(() => {
    dispatch(
      getIncDescAuctionDetails({
        payload: {
          onSuccess: (m, payload, response) => {
            if (payload && payload.length > 0) {
              setAuction({ ...payload[0] });
            }
          },
          onError: () => {},
        },
        id: id,
      })
    );
  }, [dispatch, id]);

  const connect = (tender: IncDescAuctionDto) => {
    const HUB_ENDPOINT =
      baseUrl + "api/reductiontenderbradcasting?sfid=" + tender.id;

    if (connectionRef.current !== undefined) {
      setConnectionStatus("connecting");
      const connection = new HubConnectionBuilder()
        .withUrl(HUB_ENDPOINT, {
          headers: { "Accept-Language": `${lang}` },
          accessTokenFactory: () => token ?? "",
        })
        .configureLogging(LogLevel.Debug)
        .build();
      connection.serverTimeoutInMilliseconds = 3000;
      console.log(`Trying to connect to ${HUB_ENDPOINT}`);
      connection
        .start()
        .then(() => {
          console.log(`Connected to ${HUB_ENDPOINT}`);
          setConnectionStatus("connected");
        })
        .catch((err: any) => {
          setConnectionStatus("failed");
          console.log(`Error starting the connection: ${err.toString()}`);
        });
      connection.onclose(async () => {
        console.log(`Disconnected from ${HUB_ENDPOINT} ${connectionStatus}`);
        connectionRef.current = null;
        connect(tender);
      });
      connection.on(tender.id, function (payload: any) {
        console.log(payload);
        setPriceData(payload);
      });
      connectionRef.current = connection;
      setConnectionStatus("connected");
    } else console.log("CONNECTION ALREADY ESTABLIESHED");
  };
  const onAccept = async () => {
    if (auction && connectionRef.current !== null) {
      try {
        await connectionRef.current?.invoke("SendTender", {
          sfid: auction.id,
        });
        Alert.success("Teklif verildi", 5000);
      } catch (err: any) {
        console.log(`Error sending  ${err.message} ${connectionStatus}`);
      }
    }
  };

  const getUserCheck = (priceData: DescAuctionPriceDto) => {
    if (priceData.reductionTenderLists.length > 0) {
      if (priceData.reductionTenderLists[0].user === user?.UserID) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };
  const getUserCheckInc = (data: IncDescAuctionPriceDto) => {
    if (data && data?.raiseTenderLists.length > 0) {
      if (data.raiseTenderLists[0].user === user?.UserID) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };
  const connectInc = (tender: IncDescAuctionDto) => {
    const HUB_ENDPOINT =
      baseUrl + "api/raisetenderbradcasting?sfid=" + tender.id;

    if (connectionRef.current !== undefined) {
      setConnectionStatus("connecting");
      const connection = new HubConnectionBuilder()
        .withUrl(HUB_ENDPOINT, {
          headers: { "Accept-Language": `${lang}` },
          accessTokenFactory: () => token ?? "",
        })
        .configureLogging(LogLevel.Debug)
        .build();
      connection.serverTimeoutInMilliseconds = 3000;
      console.log(`Trying to connect to ${HUB_ENDPOINT}`);
      connection
        .start()
        .then(() => {
          console.log(`Connected to ${HUB_ENDPOINT}`);
          setConnectionStatus("connected");
        })
        .catch((err: any) => {
          setConnectionStatus("failed");
          console.log(`Error starting the connection: ${err.toString()}`);
        });
      connection.onclose(async () => {
        console.log(`Disconnected from ${HUB_ENDPOINT} ${connectionStatus}`);
        connectionRef.current = null;
        connectInc(tender);
      });
      connection.on(tender.id, function (payload: any) {
        console.log(payload);
        setPriceDataInc(payload);
      });
      connectionRef.current = connection;
      setConnectionStatus("connected");
    } else console.log("CONNECTION ALREADY ESTABLIESHED");
  };
  const onAcceptInc = async () => {
    if (auction && connectionRef.current !== null) {
      try {
        await connectionRef.current?.invoke("SendTender", {
          sfid: auction.id,
        });
        Alert.success("Teklif verildi", 5000);
      } catch (err: any) {
        console.log(`Error sending  ${err.message} ${connectionStatus}`);
      }
    }
  };

  useEffect(() => {
    if (auction) {
      auction.transportertendertype__c === "Arttırmalı İhale"
        ? connectInc(auction)
        : connect(auction);
    }
    return () => {
      try {
        connectionRef.current?.stop().then(() => {
          console.log("CONNECTION STOPPED");
          connectionRef.current = null;
        });
      } catch (e) {
        console.log("ERROR UNMOUNT", e);
      }
    }; // eslint-disable-next-line
  }, [auction]);
  return (
    <React.Fragment>
      {auction && (
        <div className="mainWidth auctionPage">
          <Row>
            <>
              {priceData && priceData.endingTender && (
                <div
                  style={{
                    color: "white",
                    background: getUserCheck(priceData)
                      ? color.success
                      : color.bgPrimary,
                    marginBlockEnd: 20,
                    paddingBlock: "20px",
                    textAlign: "center",
                    fontSize: 24,
                    fontWeight: 700,
                  }}
                >
                  {getUserCheck(priceData)
                    ? t.getString("Tender Success")
                    : t.getString("Tender close info")}
                </div>
              )}
              {priceDataInc && priceDataInc.endingTender && (
                <div
                  style={{
                    color: "white",
                    background: getUserCheckInc(priceDataInc)
                      ? color.success
                      : color.bgPrimary,
                    marginBlockEnd: 20,
                    paddingBlock: "20px",
                    textAlign: "center",
                    fontSize: 24,
                    fontWeight: 700,
                  }}
                >
                  {getUserCheckInc(priceDataInc)
                    ? t.getString("Tender Success")
                    : t.getString("Tender close info")}
                </div>
              )}
            </>
            <Col md={24} className="headingStyle" style={{ marginBlockEnd: 5 }}>
              {auction.opdtender_no__c} {auction.transportertendertype__c}
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div
                className={
                  "auctionMenuItem" + (active === "1" ? " activeMenuItem" : "")
                }
                onClick={() => toggleMenu("1")}
                style={{ textAlign: "center" }}
              >
                <span className="auctionMenuItemTitle">
                  {t.getString("Tender Informations")}
                </span>
              </div>
            </Col>
            <Col lg={12}>
              <div
                className={
                  "auctionMenuItem" + (active === "2" ? " activeMenuItem" : "")
                }
                onClick={() => toggleMenu("2")}
                style={{ textAlign: "center" }}
              >
                <span className="auctionMenuItemTitle">
                  {t.getString("Price Table")}
                </span>
              </div>
            </Col>
          </Row>
          <AuctionChatDrawer
            show={questionModal}
            setShow={setquestionModal}
            auction={auction}
          />

          {active === "1" && (
            <>
              {auction.transportertendertype__c === "Arttırmalı İhale" ? (
                <DetailTab
                  auction={auction}
                  setAuction={setAuction}
                  counter={
                    priceDataInc?.startingTender ? (
                      <>{priceDataInc?.endingRemainingTime}</>
                    ) : (
                      <>{priceDataInc?.startingRemainingTime}</>
                    )
                  }
                />
              ) : (
                <>
                  <DetailTab
                    auction={auction}
                    setAuction={setAuction}
                    counter={
                      priceData?.startingTender ? (
                        <>{priceData?.endingRemainingTime}</>
                      ) : (
                        <>{priceData?.startingRemainingTime}</>
                      )
                    }
                  />
                </>
              )}
            </>
          )}
          {active === "2" && (
            <>
              {auction.transportertendertype__c === "Arttırmalı İhale" ? (
                <IncPriceTab
                  auction={auction}
                  setAuction={setAuction}
                  priceData={priceDataInc}
                  onAccept={() => onAcceptInc()}
                />
              ) : (
                <>
                  <DescPriceTab
                    auction={auction}
                    setAuction={setAuction}
                    priceData={priceData}
                    onAccept={() => onAccept()}
                  />
                </>
              )}
            </>
          )}
        </div>
      )}
    </React.Fragment>
  );
};
export default IncDescAuctionDetails;
