import { useEffect, useState } from "react";
import Flag from "react-flagkit";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "rsuite";
import { Icon } from "../../../../../components";
import { getCommunicationLanguages } from "../../../../../redux/actions/staticActions";
import {
  getLocalizedMyProfile,
  getUserState,
} from "../../../../../redux/selectors";
import { CommunicationLanguageObject } from "../../../../../types";
import { color } from "../../../../../utils/Properties";

const CompanyDetailCard = ({
  onClickMessage,
}: {
  onClickMessage: () => void;
}) => {
  const dispatch = useDispatch();
  const user = useSelector(getUserState);

  const strings = useSelector(getLocalizedMyProfile);
  const [comLang, setComLang] = useState<CommunicationLanguageObject[]>();

  useEffect(() => {
    dispatch(
      getCommunicationLanguages({
        payload: {
          onSuccess: (message, payload) => {
            setComLang(payload);
            console.log("Lang", payload);
          },
          onError: (message) => {},
        },
        url: "System/GetCommunicationLanguage",
      })
    );
  }, [dispatch]);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Row
          style={{
            border: "1px solid " + color.bgPrimarySoft,
            maxWidth: 400,
            paddingBlock: 20,
            width: "100%",
          }}
        >
          <Col md={10} style={{ textAlign: "center" }}>
            <img
              className="avatar"
              src={user?.RapidAdvisor.profileimage}
              alt=""
              style={{
                width: 70,
                height: 80,
                borderRadius: 3,
                marginBlockEnd: 6,
              }}
            />
            <p>{user?.RapidAdvisor?.name}</p>
            <span>{strings.getString("Rapid Logistics Advisor")}</span>
            <div>
              <p
                style={{
                  whiteSpace: "nowrap",
                  textAlign: "center",
                  marginInlineEnd: 10,
                }}
              >
                {strings.getString("communication_language")}
              </p>
              <span>
                {user?.RapidAdvisor?.languages__c
                  ?.split(";")
                  .map((x: string) => {
                    return comLang?.find((y) => y.description === x)?.key ? (
                      <Flag
                        style={{ marginRight: 3 }}
                        country={
                          comLang?.find((y) => y.description === x)?.key !==
                          "EN"
                            ? comLang?.find((y) => y.description === x)?.key
                            : "US"
                        }
                        title={x}
                      />
                    ) : null;
                  })}
              </span>
            </div>
          </Col>
          <Col md={14} className="profileCompanyDetailsOwnerCardContent">
            <div style={{ marginBlockStart: 10, whiteSpace: "nowrap" }}>
              <span>
                <Icon icon="mail" /> {user?.RapidAdvisor?.email}
              </span>
            </div>

            <div style={{ marginBlockStart: 10 }}>
              <span>
                <Icon icon="id-card" /> {user?.RapidAdvisor?.mobilephone}
              </span>
            </div>

            <div style={{ marginBlockStart: 10 }}>
              <span>
                <Icon icon="mobile" /> {user?.RapidAdvisor?.mobilephone}
              </span>
            </div>

            <div style={{ marginBlockStart: 10 }}>
              <span>
                <Icon icon="chat" /> {user?.RapidAdvisor?.mobilephone}
              </span>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default CompanyDetailCard;
