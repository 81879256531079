import { Modal } from "rsuite";
import { AuctionQuestionDto } from "../../../../../redux/autions/type";

const QuestionModal = ({
  question,
  open,
  setOpen,
}: {
  question: AuctionQuestionDto;
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  return (
    <Modal show={open} onHide={() => setOpen(false)} size={"sm"}>
      <Modal.Body>
        <div style={{ padding: "20px" }}>
          <div className="headingStyle">{question.tender_question__c}</div>
          <div className="">{question.tender_answer__c}</div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default QuestionModal;
