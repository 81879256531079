import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { BaseLayout, Footer } from "../../../components";
import { getBlogDetails } from "../../../redux/actions";
import { BlogObject } from "../../../types";
import { color, webImageUrl } from "../../../utils/Properties";
import "./style.scss";
import { Helmet } from "react-helmet";
import { getLocalizedHelmet, languageSelector } from "../../../redux/selectors";

const BlogPage = () => {
  const dispatch = useDispatch();
  const language = useSelector(languageSelector);
  const helmetStrings = useSelector(getLocalizedHelmet);
  const [blog, setBlog] = useState<BlogObject>();
  const { id }: { id: string } = useParams();
  useEffect(() => {
    dispatch(
      getBlogDetails({
        payload: {
          onSuccess: (message, payload) => setBlog(payload),
          onError: (message) => {},
        },
        url: "HomePage/GetBlogs",
        id: id,
      })
    );
    // eslint-disable-next-line
  }, [language]);
  return (
    <div className="innerScreen">
      <Helmet>
        <title>
          {helmetStrings.formatString(
            helmetStrings.getString("BlogHelmet"),
            blog ? blog.title : ""
          )}
        </title>
      </Helmet>
      <div
        className="innerScreenContent"
        style={{ backgroundColor: "#BFE9FE4D" }}
      >
        <div
          className="sidebarNavigationDiv blogs-sidenavigation"
          style={{ backgroundColor: color.pageBgColor }}
        >
          <div className="sidebarNavigation">
            <BaseLayout />
          </div>
        </div>
        <div className="truckRegistrationDiv blog-outer-div">
          <div
            className="truckRegistration blog-content "
            style={{ backgroundColor: color.accent }}
          >
            <p
              className="blogTitle"
              dangerouslySetInnerHTML={{
                __html: blog ? blog.title : "",
              }}
            />
            <img
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                marginBlock: 40,
              }}
              width={"75%"}
              src={webImageUrl + blog?.bigimage}
              alt=""
            />

            <p
              dangerouslySetInnerHTML={{
                __html: blog ? blog.description : "",
              }}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogPage;
