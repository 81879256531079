import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteVehicleType,
  getTransportWays,
  getTruckTypes,
  getVehicleHeight,
  getVehicleLength,
  getVehicleTypes,
  getVehicleWidth,
} from "../../../../../redux/profile/profileSaga";
import {
  TransportWaysDto,
  TruckTypeDto,
  VehicleLengthDto,
  VehicleTypeDto,
} from "../../../../../redux/profile/type";
import { getLocalizedMyProfile } from "../../../../../redux/selectors";
import { color } from "../../../../../utils/Properties";
import { confirm } from "devextreme/ui/dialog";
import { Alert } from "rsuite";
import EditContactForm from "./edit";
import CreateVehicleTypeForm from "./create";
import ResponsiveTable, {
  GridButtonType,
} from "../../../../../components/ResponsiveTable";

const Contacts = () => {
  const [data, setData] = useState<VehicleTypeDto[]>();
  const [transports, setTransports] = useState<TransportWaysDto[]>();
  const [types, setTypes] = useState<TruckTypeDto[]>();
  const [selected, setSelected] = useState<VehicleTypeDto>();
  const strings = useSelector(getLocalizedMyProfile);
  const [height, setHeight] = useState<VehicleLengthDto[]>();
  const [width, setWidth] = useState<VehicleLengthDto[]>();
  const [showCreate, setShowCreate] = useState(false);
  const [length, setLength] = useState<VehicleLengthDto[]>();
  const dispatch = useDispatch();
  const getDestinationFunc = () => {
    dispatch(
      getVehicleHeight({
        payload: {
          onSuccess: (message, payload) => setHeight(payload),
          onError: (message) => {},
        },
      })
    );
    dispatch(
      getVehicleLength({
        payload: {
          onSuccess: (message, payload) => setLength(payload),
          onError: (message) => {},
        },
      })
    );
    dispatch(
      getVehicleWidth({
        payload: {
          onSuccess: (message, payload) => setWidth(payload),
          onError: (message) => {},
        },
      })
    );
    dispatch(
      getVehicleTypes({
        payload: {
          onSuccess: (message, payload) => setData(payload),
          onError: (message) => {},
        },
        url: "Profile/VehicleTypes",
      })
    );
    dispatch(
      getTransportWays({
        payload: {
          onSuccess: (message, payload) => setTransports(payload),
          onError: (message) => {},
        },
        url: "System/GetTransportWays",
      })
    );
    dispatch(
      getTruckTypes({
        payload: {
          onSuccess: (message, payload) => setTypes(payload),
          onError: (message) => {},
        },
        url: "System/GetTruckType",
      })
    );
  };
  useEffect(() => {
    getDestinationFunc(); // eslint-disable-next-line
  }, [dispatch]);
  const columns = [
    {
      dataField: "vehicletypename",
      caption: strings.getString("vehicletype"),
    },
    {
      dataField: "numberofvehicles__c",
      caption: strings.getString("numberofvehicles"),
    },
    {
      dataField: "transportways__c",
      caption: strings.getString("transportways"),
    },
  ];

  const buttons = [
    {
      type: GridButtonType.edit,
      icon: "dx-icon-edit",
      onClick: (e: any) => {
        setShowCreate(false);
        setSelected(e);
      },
    },
    {
      type: GridButtonType.default,
      icon: "dx-icon-trash",
      onClick: (e: any) => {
        var confirmResp = confirm(
          strings.getString("deleteVehicleTypequa"),
          strings.getString("deleteVehicleType")
        );
        confirmResp.then((result) => {
          if (result) {
            dispatch(
              deleteVehicleType({
                payload: {
                  onSuccess: (message, payload) => {
                    Alert.success("Başarıyla silindi.");
                    getDestinationFunc();
                  },
                  onError: (message) => {
                    Alert.error(message);
                  },
                },
                url: "/Profile/DeleteVehicleType/" + e.id,
              })
            );
          }
        });
      },
    },
  ];

  return (
    <div className="my-profile-card" style={{ backgroundColor: color.accent }}>
      <p className="newBoldHeadingStyle">{strings.getString("vehicletypes")}</p>
      <div
        className="regularText"
        style={{
          marginBlockEnd: 30,
        }}
      >
        {strings.formatString(
          strings.getString("destination_details_description")
        )}
      </div>
      <ResponsiveTable
        data={data ?? []}
        columns={columns}
        buttons={buttons}
        showCreate={showCreate}
        createForm={
          <CreateVehicleTypeForm
            width={width}
            length={length}
            height={height}
            listData={data ?? []}
            onSuccess={() => {
              Alert.success("Başarıyla eklendi.");
              getDestinationFunc();
            }}
            types={types ?? []}
            transports={transports ?? []}
          />
        }
        editForm={
          selected && (
            <EditContactForm
              width={width}
              length={length}
              height={height}
              listData={data ?? []}
              onSuccess={() => {
                Alert.success("Başarıyla güncellendi.");
                getDestinationFunc();
                return setShowCreate(true);
              }}
              types={types ?? []}
              transports={transports ?? []}
              selected={selected}
            />
          )
        }
      />
    </div>
  );
};
export default Contacts;
