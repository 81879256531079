import {
  BlogPage,
  Homepage,
  MyProfile,
  SearchLoads,
  SearchTrucks,
} from "../inner-screens";
import authError from "../inner-screens/authError";
import AboutPage from "../inner-screens/aboutus";
import AuctionDetail from "../inner-screens/auctions/detail";
import {
  auctionAuth,
  loadUserAuth,
  truckUserAuth,
} from "../../utils/userTypes";
import CreateTruck from "../inner-screens/createTruck";
import CreateLoad from "../inner-screens/createLoad";
import IncDescAuctionDetail from "../inner-screens/inc-desc-auctions/detail.tsx";
import BaseAuctions from "../inner-screens/base-auction";

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
  roles?: string[];
  profileRedirect?: boolean;
}
const homeRoutes: Array<RouteProps> = [
  { path: "/", exact: true, component: Homepage },
  { path: "/authError", exact: true, component: authError },
];
const nonAuthRoutes: Array<RouteProps> = [
  { path: "/blogs/:id", exact: true, component: BlogPage },
  { path: "/sss", exact: true, component: AboutPage },
  { path: "/contact-us", exact: true, component: AboutPage },
];
const authRoutes: Array<RouteProps> = [
  {
    profileRedirect: true,
    path: "/search-trucks",
    component: SearchTrucks,
    exact: true,
    roles: truckUserAuth,
  },
  {
    profileRedirect: true,
    exact: true,
    path: "/search-loads",
    component: SearchLoads,
    roles: loadUserAuth,
  },
  {
    profileRedirect: true,
    path: "/load-registration",
    exact: true,
    component: CreateLoad,

    roles: truckUserAuth,
  },
  {
    profileRedirect: true,
    path: "/truck-registration",
    exact: true,
    component: CreateTruck,
    roles: loadUserAuth,
  },
  {
    profileRedirect: true,
    path: "/auctions/:tab/:type",
    exact: true,
    component: BaseAuctions,
    roles: auctionAuth,
  },
  {
    profileRedirect: true,
    path: "/auction-details/:id",
    exact: true,
    component: AuctionDetail,
    roles: auctionAuth,
  },

  {
    profileRedirect: true,
    path: "/inc-desc-auction-details/:id",
    exact: true,
    component: IncDescAuctionDetail,
    roles: auctionAuth,
  },
  {
    path: "/my-profile",
    exact: true,
    component: MyProfile,
    roles: [],
    profileRedirect: false,
  },
];
const authenticateRoutes: Array<RouteProps> = [];

export { nonAuthRoutes, authRoutes, authenticateRoutes, homeRoutes };
