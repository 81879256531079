type DividerProps = {
  paddingBlock?: any;
};

const Divider = ({ paddingBlock }: DividerProps) => {
  return (
    <div
      style={{ width: "100%", paddingBlock: paddingBlock ? paddingBlock : 28 }}
    >
      <div
        style={{
          width: "100%",
          height: 0,
          border: "1px solid #f0f0f0",
        }}
      />
    </div>
  );
};

export default Divider;
