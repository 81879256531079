import produce from "immer";
import { setUser, logoutAction } from "../actions/userActions";
import { LoginResult } from "../types";

export interface UserReducer {
  user?: LoginResult;
}
export const initialUserState: UserReducer = {};

const userReducer = produce((draft: UserReducer, action: any) => {
  switch (action.type) {
    case setUser.toString():
      draft.user = action.payload;
      break;

    case logoutAction.toString():
      draft.user = undefined;
      break;
  }
}, initialUserState);

export default userReducer;
