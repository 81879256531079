import { useDispatch, useSelector } from "react-redux";
import { color } from "../../../utils/Properties";
//@ts-ignore
import Faq from "react-faq-component";
import { Helmet } from "react-helmet";
import {
  getLocalizedHelmet,
  getLocalizedSidebarNavigation,
  languageSelector,
} from "../../../redux/selectors";
import { useEffect, useState } from "react";
import { getSSS } from "../../../redux/actions";
const FaqsPage = () => {
  const strings = useSelector(getLocalizedSidebarNavigation);
  const helmetStrings = useSelector(getLocalizedHelmet);
  const [sss, setSss] = useState<{ title: string; content: string }[]>();
  const dispatch = useDispatch();
  const language = useSelector(languageSelector);
  useEffect(() => {
    dispatch(
      getSSS({
        payload: {
          onSuccess: (message, payload) => {
            if (payload) {
              setSss(
                payload.map((x) => {
                  return { title: x.question, content: x.reply };
                })
              );
            }
          },
          onError: (message) => {},
        },
        url: "HomePage/GetSSS",
      })
    );
  }, [dispatch, language]);

  const styles = {
    bgColor: "transparent",
    titleTextColor: color.primary,
    titleTextSize: "18px",
    rowTitleColor: color.textPrimary,
    rowTitleTextSize: "12px",
    rowContentColor: color.textPrimary,
    rowContentTextSize: "12px",
  };

  return (
    <>
      <Helmet>
        <title>{helmetStrings.getString("FaqsHelmet")}</title>
      </Helmet>
      <div
        className="faqsContent"
        style={{
          width: "100%",
          height: "max-content",
          marginBlockEnd: 15,
        }}
      >
        <Faq
          data={{ rows: sss, title: strings.getString("faq_header") }}
          styles={styles}
        />
      </div>
    </>
  );
};

export default FaqsPage;
