import React, { FunctionComponent, ReactNode, useState } from "react";

type ContextProps = {
  persistStore: boolean;
  toggle: (remember_user: boolean) => void;
};
const initialValues = {
  persistStore: false,
  toggle: () => null,
};
export const SessionContext = React.createContext<ContextProps>(initialValues);

const { Provider } = SessionContext;

type Props = { children: ReactNode };
const SessionProvider: FunctionComponent<Props> = ({ children }) => {
  const [persistStore, setPersistStore] = useState<boolean>(false);
  const toggle = (remember_user: boolean) => setPersistStore(remember_user);
  return <Provider value={{ persistStore, toggle }}>{children}</Provider>;
};

export default SessionProvider;
