import { Popover, Whisper as WhisperRS } from "rsuite";

type WhisperProps = {
  speaker: string | React.ReactNode;
  children: React.ReactNode;
};

const Whisper = ({ speaker, children }: WhisperProps) => {
  return (
    <WhisperRS
      delay={500}
      placement="top"
      trigger="hover"
      speaker={
        <Popover>
          {typeof speaker === "string" ? <p>{speaker}</p> : speaker}
        </Popover>
      }
      enterable
    >
      {children}
    </WhisperRS>
  );
};

export default Whisper;
