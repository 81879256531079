import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Col, Form, Row } from "rsuite";
import * as Yup from "yup";
import { CheckPicker, Icon, SelectPicker } from "../../../../../../components";
import { addDestinations } from "../../../../../../redux/profile/profileSaga";
import {
  PicklistCountryDto,
  TransportWaysDto,
  TruckTypeDto,
  VehicleLengthDto,
} from "../../../../../../redux/profile/type";
import {
  getLocalizedErrors,
  getLocalizedMyProfile,
} from "../../../../../../redux/selectors";
const CreateAccountDestinationForm = ({
  countries,
  onSuccess,
  types,
  transports,
  widthdata,
  lengthdata,
  heightdata,
}: {
  widthdata?: VehicleLengthDto[];
  lengthdata?: VehicleLengthDto[];
  heightdata?: VehicleLengthDto[];
  onSuccess: () => void;
  countries: PicklistCountryDto[];
  types: TruckTypeDto[];
  transports: TransportWaysDto[];
}) => {
  const errorStrings = useSelector(getLocalizedErrors);
  const strings = useSelector(getLocalizedMyProfile);
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        from__c: "",
        to__c: [],
        transportways__c: "",
        vehicletype__c: [],
        vehiclewidth__c: "",
        vehicleheight__c: "",
        vehiclelength__c: "",
      }}
      validationSchema={Yup.object().shape({
        from__c: Yup.string().required(errorStrings.getString("required")),
        to__c: Yup.array()
          .min(1, errorStrings.getString("required"))
          .required(errorStrings.getString("required")),
        vehicletype__c: Yup.array()
          .min(1, errorStrings.getString("required"))
          .required(errorStrings.getString("required")),
        transportways__c: Yup.string().required(
          errorStrings.getString("required")
        ),
      })}
      onSubmit={(values, { resetForm }) => {
        dispatch(
          addDestinations({
            payload: {
              onSuccess: (message) => {
                onSuccess();
                return resetForm({
                  values: {
                    from__c: "",
                    to__c: [],
                    transportways__c: "",
                    vehicletype__c: [],
                    vehiclewidth__c: "",
                    vehicleheight__c: "",
                    vehiclelength__c: "",
                  },
                });
              },
              onError: (message) => {
                toast.error(message, {
                  theme: "colored",
                });
              },
            },
            url: "/Profile/AddAccountDestinationMulti",
            body: values,
          })
        );
      }}
    >
      {({
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        values,
      }) => (
        <Form>
          <Row>
            <Col md={24} className="mb-1">
              <CheckPicker
                label={strings.getString("vehicletype")}
                searchable={true}
                onClean={() => setFieldValue("vehicletype__c", [])}
                placement="bottomStart"
                placeholder={strings.getString("vehicletype")}
                data={types ? types : []}
                value={values.vehicletype__c}
                onChange={(e) => setFieldValue("vehicletype__c", e)}
                valueKey="id"
                style={{ width: "100%", height: "auto" }}
                labelKey="description"
              />
            </Col>
            <Col md={24} className="pt-2">
              <SelectPicker
                placement="bottomStart"
                cleanable={false}
                searchable={true}
                style={{ marginBlockEnd: 10 }}
                label={strings.getString("transportways")}
                placeholder={strings.getString("transportways")}
                data={transports}
                name="to__c"
                labelKey={"value__c"}
                valueKey={"api_name__c"}
                value={values.transportways__c}
                onSelect={handleChange("transportways__c")}
                onBlur={handleBlur("transportways__c")}
                error={errors.transportways__c}
                touched={touched.transportways__c}
              />
            </Col>

            <Col md={11}>
              <SelectPicker
                cleanable={false}
                searchable={true}
                placement="bottomStart"
                labelKey={"description"}
                valueKey={"id"}
                style={{ marginBlockEnd: 10 }}
                label={strings.getString("startingpoint")}
                placeholder={strings.getString("startingpoint")}
                data={countries}
                name="from__c"
                value={values.from__c}
                onSelect={handleChange("from__c")}
                onBlur={handleBlur("from__c")}
                error={errors.from__c}
                touched={touched.from__c}
              />
            </Col>

            <Col md={11}>
              <CheckPicker
                label={strings.getString("destinationpoint")}
                searchable={true}
                onClean={() => setFieldValue("to__c", [])}
                placement="bottomStart"
                placeholder={strings.getString("destinationpoint")}
                data={countries ? countries : []}
                value={values.to__c}
                onChange={(e) => setFieldValue("to__c", e)}
                valueKey="id"
                style={{ width: "100%", height: "auto" }}
                labelKey="description"
              />

              {errors.to__c && (
                <p className="inputErrorStyles">{errors.to__c}</p>
              )}
            </Col>
            <Col md={1}>
              <div className="smResponsiveButtonDiv gridPosition">
                <button
                  type="submit"
                  className="gridIcon "
                  onClick={() => handleSubmit()}
                >
                  <Icon icon="plus" size={20} />
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
export default CreateAccountDestinationForm;
