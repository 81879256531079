const RequireMoment = (locale: string) => {
  switch (locale) {
    case "tr":
    case "tr-tr":
    case "tr-TR":
      require("moment/locale/tr");
      break;
    case "ar":
      require("moment/locale/ar");
      break;
    default:
      break;
  }
};

export default RequireMoment;
