import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Drawer, Modal, Progress } from "rsuite";
import { Button } from "../../../components";
import {
  getLocalizedMyProfile,
  getLocalizedSidebarNavigation,
  getUserState,
} from "../../../redux/selectors";
import { color } from "../../../utils/Properties";
import "./style.scss";
import CompanyDetails from "./menuitems/company-details";
import Destinations from "./menuitems/destinations";
import VehicleTypes from "./menuitems/vehicle-types";
import Profile from "./menuitems/profile";
import { images } from "../../../assets";
import MyFleet from "./menuitems/myfleet";
import PreviousTransport from "./menuitems/previous-transport";
import ActiveTransport from "./menuitems/active-transport";
import Auctions from "./menuitems/auctions";
import Contacts from "./menuitems/contacts";
import { getActiveUserInfo } from "../../../redux/profile/profileSaga";
import { setUser } from "../../../redux/actions";
import { useHistory, useLocation } from "react-router-dom";
import ModalBody from "rsuite/lib/Modal/ModalBody";
import ModalHeader from "rsuite/lib/Modal/ModalHeader";
import AcceptOfferTransporter from "./menuitems/offer/accept-offer-transporter";
import UpdateProfileContent from "./updateProfileContent";
import ScrollBar from "react-perfect-scrollbar";
import AcceptOfferCustomer from "./menuitems/offer/accept-offer-customer";
import { ButtonType } from "../../../components/Button";
import { Helmet } from "react-helmet";
import { getLocalizedHelmet } from "../../../redux/selectors";
import { loadUserAuth, truckUserAuth } from "../../../utils/userTypes";
import CustomerOfferList from "./menuitems/t";
import MyLoadOpportunityBasePage from "./menuitems/my-opportunities/load";
import MyTruckOpportunityBasePage from "./menuitems/my-opportunities/truck";
import Plates from "./menuitems/plates";
import Address from "./menuitems/address";
const AlwaysScrollToBottom = () => {
  const elementRef = useRef(null);
  useEffect(() =>
    //@ts-ignore
    elementRef.current.scrollIntoView()
  );
  return <div ref={elementRef} />;
};
const MyProfile = () => {
  const helmetStrings = useSelector(getLocalizedHelmet);
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  const [updateModalShow, setUpdateModalShow] = useState(true);
  const [active, setActive] = useState<number>(2);

  const [activeItem, setActiveItem] = useState<string>("#profile"); // eslint-disable-next-line
  const [showChat, setShowChat] = useState<boolean>(false);
  const strings = useSelector(getLocalizedSidebarNavigation);
  const profileStrings = useSelector(getLocalizedMyProfile);
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(getUserState);

  const setActiveCssClass = (id: string, number: number) => {
    setActive(number);
    let actItem = document.querySelector(`${activeItem}`);
    actItem?.classList.remove("active");
    setActiveItem(`#${id}`);
    let item = document.querySelector(`#${id}`);
    item?.classList.add("active");
    showMenu && setShowMenu(false);
  };
  const setActiveCss = (id: string, number: number) => {
    setActiveCssClass(id, number);
    console.log(id, number);
    return history.push(`?no=${number}&page=${id}`);
  };
  useEffect(() => {
    const url = new URLSearchParams(location.search);
    const no = url.get("no");
    const page = url.get("page");
    if (no && page) {
      setActive(no ? parseInt(no) : 2);
      setActiveItem(page ? `#${page}` : "#profile");
      setActiveCssClass(page, parseInt(no));
    } // eslint-disable-next-line
  }, [location.search]);
  const returnColor = () => {
    switch (user?.WebApprovalStatus) {
      case "Accepted":
        return "black";
      case "Rejected":
        return color.error;
      case "In_Progress":
        return color.primary;
      default:
        return "black";
    }
  };
  useEffect(() => {
    dispatch(
      getActiveUserInfo({
        payload: {
          onSuccess: (message: string, payload: any) => {
            dispatch(setUser(payload));
          },
          onError: (message: string) => { },
        },
        url: "/Profile/ActiveUserInformation",
      })
    ); // eslint-disable-next-line
  }, [dispatch]);
  return (
    <div className="innerScreen">
      <AlwaysScrollToBottom />
      <Helmet>
        <title>{helmetStrings.getString("ProfileHelmet")}</title>
      </Helmet>
      <div className="mobile-profile-sidenavbar-content">
        <Button
          title={profileStrings.getString("Profile Menu")}
          buttonType={ButtonType.inverted}
          style={{
            zIndex: 1035,
            borderColor: "#E6F7FE",
            backgroundColor: "#E6F7FE",
            color: color.primary,
            borderRadius: 0,
            boxShadow: "0 4px 2px -2px #80808026",
            position: "fixed",
          }}
          onClick={() => {
            setShowMenu(true);
          }}
        />
        <Drawer
          className="mobile-profile-sidenavbar-drawer"
          placement={"left"}
          show={showMenu}
          onHide={() => setShowMenu(false)}
        >
          <Drawer.Body>
            <div
              className="sidebarNavigationDiv"
              style={{
                backgroundColor: color.pageBgColor,
                width: "100%",
                borderInlineEnd: 0,
                paddingBlock: 0,
              }}
            >
              <div className="sidebarNavigation">
                <div>
                  <div className="sidebarNavigation-div">
                    <p
                      className="newBoldHeadingStyle"
                      style={{ marginBlock: 15 }}
                    >
                      {profileStrings.getString("profilemanagement")}
                    </p>
                    <div>
                      <img
                        className="profile-sidebar-menu-img"
                        width={160}
                        height={160}
                        style={{ borderRadius: 25 }}
                        src={images.useravatar}
                        alt=""
                      />
                      <div
                        className="profile-sidebar-menu-info"
                        style={{ marginBlock: "18px 13px" }}
                      >
                        <span className="mediumHeadingStyle profile-sidebar-menu-name">
                          {user?.FirstName + " " + user?.LastName}
                        </span>
                        <p
                          className="headingStyle profile-sidebar-menu-account"
                          style={{ color: "#8b8b8b" }}
                        >
                          Account/{user?.Account.name}
                          <br />@{user?.Email}
                        </p>
                        <div>
                          <p
                            className="headingStyle profile-sidebar-menu-status"
                            style={{ color: "#0B5CAB" }}
                          >
                            {profileStrings.getString("status")}:{" "}
                            <span style={{ color: "#8b8b8b" }}>
                              {user?.Account.accountclass__c
                                ? user.Account.accountclass__c
                                : "-"}
                            </span>
                            <br />
                            {profileStrings.getString("point")}:{" "}
                            <span style={{ color: "#8b8b8b" }}>
                              {user?.AccountClassPoint}
                            </span>
                          </p>

                          <Progress.Line
                            percent={
                              user?.ProfilePercentage
                                ? user.ProfilePercentage
                                : 10
                            }
                            showInfo={true}
                            className="progress-bar"
                          />
                          <span className="standardText">
                            Profile Percentage
                          </span>
                        </div>
                      </div>

                      <Divider style={{ marginBlock: 6 }} />
                      <div></div>
                    </div>
                    <ul>
                      <li>
                        <span
                          id="profile"
                          className={
                            active === 2
                              ? "headingStyle active"
                              : "headingStyle"
                          }
                          onClick={() => setActiveCss("profile", 2)}
                        >
                          {profileStrings.getString("myprofile")}
                        </span>
                      </li>
                      <li>
                        <span
                          id="company"
                          className={
                            active === 3
                              ? "headingStyle active"
                              : "headingStyle"
                          }
                          onClick={() => setActiveCss("company", 3)}
                        >
                          {profileStrings.getString("companydetails")}
                        </span>
                      </li>
                      <li>
                        <span
                          id="destination"
                          className={
                            active === 4
                              ? "headingStyle active"
                              : "headingStyle"
                          }
                          onClick={() => setActiveCss("destination", 4)}
                        >
                          {profileStrings.getString("destinations")}
                        </span>
                      </li>

                      {(user?.AccountType.Name === "Forwarder" ||
                        user?.AccountType.Name === "Lead Transporter" ||
                        user?.AccountType.Name === "Transporter" ||
                        user?.AccountType.Name === "Forwarder Customer" ||
                        user?.AccountType.Name === "Forwarder Transporter" ||
                        user?.AccountType.Name ===
                        "Candidate Forwarder Nakliyeci" ||
                        user?.AccountType.Name ===
                        "Candidate Forwarder Musteri" ||
                        user?.AccountType.Name === "Candidate Forwarder") && (
                          <li>
                            <span
                              id="vehicle"
                              className={
                                active === 6
                                  ? "headingStyle active"
                                  : "headingStyle"
                              }
                              onClick={() => setActiveCss("vehicle", 6)}
                            >
                              {profileStrings.getString("vehicletypes")}
                            </span>
                          </li>
                        )}

                      {/*        {(user?.AccountType.Name === "Forwarder" ||
                        user?.AccountType.Name === "Lead Transporter" ||
                        user?.AccountType.Name === "Transporter" ||
                        user?.AccountType.Name === "Forwarder Customer" ||
                        user?.AccountType.Name === "Forwarder Transporter" ||
                        user?.AccountType.Name ===
                        "Candidate Forwarder Nakliyeci" ||
                        user?.AccountType.Name ===
                        "Candidate Forwarder Musteri" ||
                        user?.AccountType.Name === "Candidate Forwarder") && (
                          <li>
                            <span
                              id="myfleet"
                              className={
                                active === 7
                                  ? "headingStyle active"
                                  : "headingStyle"
                              }
                              onClick={() => setActiveCss("myfleet", 7)}
                            >
                              {profileStrings.getString("myfleet")}
                            </span>
                          </li>
                        )} */}
                      {/* 
                      <li>
                        <span
                          id="previoustransport"
                          className={
                            active === 8
                              ? "headingStyle active"
                              : "headingStyle"
                          }
                          onClick={() => setActiveCss("previoustransport", 8)}
                        >
                          {profileStrings.getString("previoustransport")}
                        </span>
                      </li> */}
                      {/*          <li>
                        <span
                          id="activetransport"
                          className={
                            active === 9
                              ? "headingStyle active"
                              : "headingStyle"
                          }
                          onClick={() => setActiveCss("activetransport", 9)}
                        >
                          {profileStrings.getString("activetransport")}
                        </span>
                      </li> */}
                      {/*             <li>
                        <span
                          id="auctions"
                          className={
                            active === 10
                              ? "headingStyle active"
                              : "headingStyle"
                          }
                          onClick={() => setActiveCss("auctions", 10)}
                        >
                          {profileStrings.getString("auctions")}{" "}
                        </span>
                      </li> */}

                      {/*      {(user?.AccountType.Name === "Forwarder" ||
                        user?.AccountType.Name === "Lead Transporter" ||
                        user?.AccountType.Name === "Transporter" ||
                        user?.AccountType.Name === "Forwarder Customer" ||
                        user?.AccountType.Name === "Forwarder Transporter" ||
                        user?.AccountType.Name ===
                        "Candidate Forwarder Nakliyeci" ||
                        user?.AccountType.Name ===
                        "Candidate Forwarder Musteri" ||
                        user?.AccountType.Name === "Candidate Forwarder") && (
                          <li>
                            <span
                              id="drivers"
                              className={
                                active === 11
                                  ? "headingStyle active"
                                  : "headingStyle"
                              }
                              onClick={() => setActiveCss("drivers", 11)}
                            >
                              {profileStrings.getString("drivers")}
                            </span>
                          </li>
                        )} */}
                      {/*    {(user?.AccountType.Name === "Forwarder" ||
                        user?.AccountType.Name === "Lead Transporter" ||
                        user?.AccountType.Name === "Transporter" ||
                        user?.AccountType.Name === "Forwarder Customer" ||
                        user?.AccountType.Name === "Forwarder Transporter" ||
                        user?.AccountType.Name ===
                        "Candidate Forwarder Nakliyeci" ||
                        user?.AccountType.Name ===
                        "Candidate Forwarder Musteri" ||
                        user?.AccountType.Name === "Candidate Forwarder") && (
                          <li>
                            <span
                              id="plates"
                              className={
                                active === 12
                                  ? "headingStyle active"
                                  : "headingStyle"
                              }
                              onClick={() => setActiveCss("plates", 12)}
                            >
                              {profileStrings.getString("plates")}
                            </span>
                          </li>
                        )} */}
                      {/*      <li>
                        <span
                          id="accept-offer"
                          className={
                            active === 13
                              ? "headingStyle active"
                              : "headingStyle"
                          }
                          onClick={() => setActiveCss("accept-offer", 13)}
                        >
                          {profileStrings.getString("My Offer")}{" "}
                        </span>
                      </li> */}
                      {/*    <li>
                        <span
                          id="my-opportunities"
                          className={
                            active === 14
                              ? "headingStyle active"
                              : "headingStyle"
                          }
                          onClick={() => setActiveCss("my-opportunities", 14)}
                        >
                          {profileStrings.getString("My Opportunities")}{" "}
                        </span>
                      </li> */}
                      {/*   <li>
                        <span
                          id="customer-offers"
                          className={
                            active === 15
                              ? "headingStyle active"
                              : "headingStyle"
                          }
                          onClick={() => setActiveCss("customer-offers", 15)}
                        >
                          {profileStrings.getString("Customer Offers")}
                        </span>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>{" "}
          </Drawer.Body>
        </Drawer>
      </div>
      <div
        className="innerScreenContent profileInnerScreenContent"
        style={{ backgroundColor: "#BFE9FE4D" }}
      >
        <div
          className="sidebarNavigationDiv profile-sidebarmenu-lg-size"
          style={{ backgroundColor: color.pageBgColor }}
        >
          <div className="sidebarNavigation">
            <div>
              <div className="sidebarNavigation-div">
                <p className="newBoldHeadingStyle" style={{ marginBlock: 35 }}>
                  {profileStrings.getString("profilemanagement")}
                </p>
                <div>
                  <img
                    className=""
                    width={160}
                    height={160}
                    style={{ borderRadius: 25 }}
                    src={images.useravatar}
                    alt=""
                  />
                  <div className="" style={{ marginBlock: "18px 13px" }}>
                    <span className="mediumHeadingStyle ">
                      {user?.FirstName + " " + user?.LastName}
                    </span>
                    <p
                      className="headingStyle "
                      style={{ color: "#8b8b8b", wordBreak: "break-all" }}
                    >
                      {profileStrings.getString("Account")}/{user?.Account.name}
                      <br />@{user?.Email}
                    </p>
                    <div>
                      <p className="headingStyle" style={{ color: "#0B5CAB" }}>
                        {profileStrings.getString("Status")}:{" "}
                        <span style={{ color: "#8b8b8b" }}>
                          {user?.Account.accountclass__c
                            ? user.Account.accountclass__c
                            : "-"}
                        </span>
                        <br />
                        {profileStrings.getString("Point")} :{" "}
                        <span style={{ color: "#8b8b8b" }}>
                          {user?.AccountClassPoint}
                        </span>
                      </p>

                      <Progress.Line
                        percent={
                          user?.ProfilePercentage ? user.ProfilePercentage : 10
                        }
                        showInfo={true}
                        className="progress-bar"
                      />
                      <span className="standardText">
                        {profileStrings.getString("Profile Percentage")}
                      </span>
                    </div>
                  </div>

                  <Divider style={{ marginBlock: 6 }} />
                  <div></div>
                </div>
                <ul>
                  <li>
                    <span
                      id="profile"
                      className="headingStyle active"
                      onClick={() => setActiveCss("profile", 2)}
                    >
                      {profileStrings.getString("myprofile")}
                    </span>
                  </li>
                  <li>
                    <span
                      id="company"
                      className="headingStyle"
                      onClick={() => setActiveCss("company", 3)}
                    >
                      {profileStrings.getString("companydetails")}
                    </span>
                  </li>
                  <li>
                    <span
                      id="destination"
                      className="headingStyle"
                      onClick={() => setActiveCss("destination", 4)}
                    >
                      {profileStrings.getString("destinations")}
                    </span>
                  </li>

                  {(user?.AccountType.Name === "Forwarder" ||
                    user?.AccountType.Name === "Lead Transporter" ||
                    user?.AccountType.Name === "Transporter" ||
                    user?.AccountType.Name === "Forwarder Customer" ||
                    user?.AccountType.Name === "Forwarder Transporter" ||
                    user?.AccountType.Name ===
                    "Candidate Forwarder Nakliyeci" ||
                    user?.AccountType.Name === "Candidate Forwarder Musteri" ||
                    user?.AccountType.Name === "Candidate Forwarder") && (
                      <li>
                        <span
                          id="vehicle"
                          className="headingStyle"
                          onClick={() => setActiveCss("vehicle", 6)}
                        >
                          {profileStrings.getString("vehicletypes")}
                        </span>
                      </li>
                    )}

                  {/*         {(user?.AccountType.Name === "Forwarder" ||
                    user?.AccountType.Name === "Lead Transporter" ||
                    user?.AccountType.Name === "Transporter" ||
                    user?.AccountType.Name === "Forwarder Customer" ||
                    user?.AccountType.Name === "Forwarder Transporter" ||
                    user?.AccountType.Name ===
                    "Candidate Forwarder Nakliyeci" ||
                    user?.AccountType.Name === "Candidate Forwarder Musteri" ||
                    user?.AccountType.Name === "Candidate Forwarder") && (
                      <li>
                        <span
                          id="myfleet"
                          className="headingStyle"
                          onClick={() => setActiveCss("myfleet", 7)}
                        >
                          {profileStrings.getString("myfleet")}
                        </span>
                      </li>
                    )} */}
                  {/* 
                  <li>
                    <span
                      id="previoustransport"
                      className="headingStyle"
                      onClick={() => setActiveCss("previoustransport", 8)}
                    >
                      {profileStrings.getString("previoustransport")}
                    </span>
                  </li> */}
                  {/*     <li>
                    <span
                      id="activetransport"
                      className="headingStyle"
                      onClick={() => setActiveCss("activetransport", 9)}
                    >
                      {profileStrings.getString("activetransport")}
                    </span>
                  </li> */}
                  {/* {(user?.AccountType.Name === "Lead Account" ||
                    user?.AccountType.Name === "Account" ||
                    user?.AccountType.Name === "Candidate Forwarder Musteri" ||
                    user?.AccountType.Name === "Forwarder" ||
                    user?.AccountType.Name === "Forwarder Transporter" ||
                    user?.AccountType.Name ===
                      "Candidate Forwarder Nakliyeci" ||
                    user?.AccountType.Name === "Candidate Forwarder") && (
                    <li>
                      <span
                        id="myaddress"
                        className="headingStyle"
                        onClick={() => setActiveCss("myaddress", 16)}
                      >
                        {profileStrings.getString("myaddress")}
                      </span>
                    </li>
                  )} */}
                  {/*    <li>
                    <span
                      id="auctions"
                      className={
                        active === 10 ? "headingStyle active" : "headingStyle"
                      }
                      onClick={() => setActiveCss("auctions", 10)}
                    >
                      {profileStrings.getString("auctions")}{" "}
                    </span>
                  </li> */}
                  {/* 
                  {(user?.AccountType.Name === "Forwarder" ||
                    user?.AccountType.Name === "Lead Transporter" ||
                    user?.AccountType.Name === "Transporter" ||
                    user?.AccountType.Name === "Forwarder Customer" ||
                    user?.AccountType.Name === "Forwarder Transporter" ||
                    user?.AccountType.Name ===
                    "Candidate Forwarder Nakliyeci" ||
                    user?.AccountType.Name === "Candidate Forwarder Musteri" ||
                    user?.AccountType.Name === "Candidate Forwarder") && (
                      <li>
                        <span
                          id="drivers"
                          className={
                            active === 11 ? "headingStyle active" : "headingStyle"
                          }
                          onClick={() => setActiveCss("drivers", 11)}
                        >
                          {profileStrings.getString("drivers")}
                        </span>
                      </li>
                    )} */}
                  {/* {(user?.AccountType.Name === "Forwarder" ||
                    user?.AccountType.Name === "Lead Transporter" ||
                    user?.AccountType.Name === "Transporter" ||
                    user?.AccountType.Name === "Forwarder Customer" ||
                    user?.AccountType.Name === "Forwarder Transporter" ||
                    user?.AccountType.Name ===
                      "Candidate Forwarder Nakliyeci" ||
                    user?.AccountType.Name === "Candidate Forwarder Musteri" ||
                    user?.AccountType.Name === "Candidate Forwarder") && (
                    <li>
                      <span
                        id="plates"
                        className={
                          active === 12 ? "headingStyle active" : "headingStyle"
                        }
                        onClick={() => setActiveCss("plates", 12)}
                      >
                        {profileStrings.getString("Plates")}
                      </span>
                    </li>
                  )} */}
                  {/*    <li>
                    <span
                      id="accept-offer"
                      className={
                        active === 13 ? "headingStyle active" : "headingStyle"
                      }
                      onClick={() => setActiveCss("accept-offer", 13)}
                    >
                      {profileStrings.getString("My Offer")}{" "}
                    </span>
                  </li> */}
                  {/*         <li>
                    <span
                      id="my-opportunities"
                      className={
                        active === 14 ? "headingStyle active" : "headingStyle"
                      }
                      onClick={() => setActiveCss("my-opportunities", 14)}
                    >
                      {profileStrings.getString("My Opportunities")}
                    </span>
                  </li> */}
                  {/*        <li>
                    <span
                      id="customer-offers"
                      className={
                        active === 15 ? "headingStyle active" : "headingStyle"
                      }
                      onClick={() => setActiveCss("customer-offers", 15)}
                    >
                      {profileStrings.getString("Customer Offers")}
                    </span>
                  </li> */}
                </ul>
              </div>
            </div>
            <Divider />
            <div className="detailedFormDesc">
              <p className="standardText">
                {strings.getString("detailed_form")}
              </p>
              <p
                className="mediumHeadingStyle"
                style={{ marginTop: 14, color: color.primary }}
              >
                {strings.getString("form_header")}
              </p>
              <p
                className="standardText"
                style={{
                  color: "#8095ae",
                  marginBlockStart: 12,
                  fontSize: 11,
                  textAlign: "justify",
                  marginInlineEnd: 10,
                }}
              >
                {strings.getString("form_header_content")}
                <br />
                <br />
                <span
                  className="standardText"
                  style={{ color: "#8095ae", textAlign: "justify" }}
                >
                  {strings.getString("form_question")}
                </span>
              </p>
              <div
                style={{ marginBlockStart: 24, width: "100%", maxWidth: 200 }}
              >
                <Button
                  title={strings.getString("form_button")}
                  onClick={() => history.push("/sss")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="truckRegistrationDiv profile-main-content-div">
          {!true ? (
            <>
              {user &&
                (user.WebApprovalStatus === "In_Progress" ||
                  user.WebApprovalStatus === "Rejected") && (
                  <>
                    {!updateModalShow && (
                      <div
                        style={{
                          zIndex: 7,
                          width: 90,
                          right: 0,
                          position: "fixed",
                          backgroundColor: color.success,
                          borderColor: color.success,
                          color: "white",
                          padding: 10,
                          borderTopLeftRadius: 8,
                          borderBottomLeftRadius: 8,
                          cursor: "pointer",
                        }}
                        onClick={() => setUpdateModalShow(true)}
                      >
                        {profileStrings.getString("approvalstatus")}
                      </div>
                    )}
                    <Modal
                      show={updateModalShow}
                      onHide={() => setUpdateModalShow(false)}
                      size={"sm"}
                    >
                      <ModalHeader
                        onHide={() => setUpdateModalShow(false)}
                      ></ModalHeader>
                      <ModalBody>
                        <ScrollBar>
                          <div
                            style={{
                              maxHeight: 500,
                              padding: 20,
                              fontSize: 24,
                              fontWeight: 600,
                              textAlign: "center",
                              color: returnColor(),
                            }}
                          >
                            {user &&
                              user.WebApprovalStatus === "In_Progress" &&
                              (user.profile_information_completed__c ? (
                                <>
                                  <img
                                    src={images.loadingDots}
                                    alt=""
                                    width={100}
                                  />
                                  <br />
                                  <span>
                                    {profileStrings.getString(
                                      "Your profile information has been sent Awaiting approval"
                                    )}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <img
                                    src={images.editprofile}
                                    alt=""
                                    width={100}
                                  />
                                  <br />
                                  <span style={{ color: "#ca4f4f" }}>
                                    {
                                      <UpdateProfileContent
                                        setActivePage={(id, number) => {
                                          setActiveCss(id, number);
                                          setUpdateModalShow(false);
                                        }}
                                      />
                                    }
                                  </span>
                                </>
                              ))}

                            {user && user.WebApprovalStatus === "Rejected" && (
                              <>
                                <img src={images.rejected} alt="" width={100} />
                                <br />
                                <span style={{ color: "#ca4f4f" }}>
                                  {profileStrings.getString(
                                    "Your Profile Rejected"
                                  )}
                                </span>{" "}
                                <br />
                                <span>
                                  {profileStrings.getString("Reject Content")}
                                </span>
                                <>
                                  <p
                                    style={{ fontWeight: 900, color: "black" }}
                                  >
                                    {profileStrings.getString("Reject Reason")}:
                                    <span style={{ color: color.error }}>
                                      {user.ReasonForRejected
                                        ? user.ReasonForRejected
                                        : "-"}
                                    </span>
                                  </p>
                                </>
                                <div style={{ marginBlock: 20 }}>
                                  <UpdateProfileContent
                                    setActivePage={(id, number) => {
                                      setActiveCss(id, number);
                                      setUpdateModalShow(false);
                                    }}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </ScrollBar>
                      </ModalBody>
                    </Modal>
                  </>
                )}
            </>
          ) : (
            <></>
          )}

          {location.search.includes("accept-offer") ? (
            <>
              {(user?.AccountType.Name === "Forwarder" ||
                user?.AccountType.Name === "Lead Transporter" ||
                user?.AccountType.Name === "Transporter" ||
                user?.AccountType.Name === "Forwarder Customer" ||
                user?.AccountType.Name === "Forwarder Transporter" ||
                user?.AccountType.Name === "Candidate Forwarder Nakliyeci" ||
                user?.AccountType.Name === "Candidate Forwarder Musteri" ||
                user?.AccountType.Name === "Candidate Forwarder") && (
                  <AcceptOfferTransporter />
                )}

              {(user?.AccountType.Name === "Lead Account" ||
                user?.AccountType.Name === "Account" ||
                user?.AccountType.Name === "Candidate Forwarder Musteri" ||
                user?.AccountType.Name === "Forwarder" ||
                user?.AccountType.Name === "Forwarder Transporter" ||
                user?.AccountType.Name === "Candidate Forwarder Nakliyeci" ||
                user?.AccountType.Name === "Candidate Forwarder") && (
                  <AcceptOfferCustomer />
                )}
            </>
          ) : (
            <>
              {active === 2 && <Profile />}
              {active === 3 && (
                <CompanyDetails onClickMessage={() => setShowChat(true)} />
              )}
              {active === 4 && <Destinations />}
              {(user?.AccountType.Name === "Forwarder" ||
                user?.AccountType.Name === "Lead Transporter" ||
                user?.AccountType.Name === "Transporter" ||
                user?.AccountType.Name === "Forwarder Customer" ||
                user?.AccountType.Name === "Forwarder Transporter" ||
                user?.AccountType.Name === "Candidate Forwarder Nakliyeci" ||
                user?.AccountType.Name === "Candidate Forwarder Musteri" ||
                user?.AccountType.Name === "Candidate Forwarder") &&
                active === 6 && <VehicleTypes />}
              {(user?.AccountType.Name === "Forwarder" ||
                user?.AccountType.Name === "Lead Transporter" ||
                user?.AccountType.Name === "Transporter" ||
                user?.AccountType.Name === "Forwarder Customer" ||
                user?.AccountType.Name === "Forwarder Transporter" ||
                user?.AccountType.Name === "Candidate Forwarder Nakliyeci" ||
                user?.AccountType.Name === "Candidate Forwarder Musteri" ||
                user?.AccountType.Name === "Candidate Forwarder") &&
                active === 7 && <MyFleet />}

              {active === 8 && <PreviousTransport />}
              {active === 9 && <ActiveTransport />}
              {active === 10 && <Auctions />}
              {(user?.AccountType.Name === "Forwarder" ||
                user?.AccountType.Name === "Lead Transporter" ||
                user?.AccountType.Name === "Transporter" ||
                user?.AccountType.Name === "Forwarder Customer" ||
                user?.AccountType.Name === "Forwarder Transporter" ||
                user?.AccountType.Name === "Candidate Forwarder Nakliyeci" ||
                user?.AccountType.Name === "Candidate Forwarder Musteri" ||
                user?.AccountType.Name === "Candidate Forwarder") &&
                active === 11 && <Contacts />}
              {/* {(user?.AccountType.Name === "Forwarder" ||
                user?.AccountType.Name === "Lead Transporter" ||
                user?.AccountType.Name === "Transporter" ||
                user?.AccountType.Name === "Forwarder Customer" ||
                user?.AccountType.Name === "Forwarder Transporter" ||
                user?.AccountType.Name === "Candidate Forwarder Nakliyeci" ||
                user?.AccountType.Name === "Candidate Forwarder Musteri" ||
                user?.AccountType.Name === "Candidate Forwarder") &&
                active === 12 && <Plates />} */}
              {active === 13 && (
                <>
                  {(user?.AccountType.Name === "Forwarder" ||
                    user?.AccountType.Name === "Lead Transporter" ||
                    user?.AccountType.Name === "Transporter" ||
                    user?.AccountType.Name === "Forwarder Customer" ||
                    user?.AccountType.Name === "Forwarder Transporter" ||
                    user?.AccountType.Name ===
                    "Candidate Forwarder Nakliyeci" ||
                    user?.AccountType.Name === "Candidate Forwarder Musteri" ||
                    user?.AccountType.Name === "Candidate Forwarder") && (
                      <AcceptOfferTransporter />
                    )}

                  {(user?.AccountType.Name === "Lead Account" ||
                    user?.AccountType.Name === "Account" ||
                    user?.AccountType.Name === "Candidate Forwarder Musteri" ||
                    user?.AccountType.Name === "Forwarder" ||
                    user?.AccountType.Name === "Forwarder Transporter" ||
                    user?.AccountType.Name ===
                    "Candidate Forwarder Nakliyeci" ||
                    user?.AccountType.Name === "Candidate Forwarder") && (
                      <AcceptOfferCustomer />
                    )}
                </>
              )}
              {active === 14 && (
                <>
                  {loadUserAuth.some((x) => x === user?.AccountType.Code) && (
                    <MyTruckOpportunityBasePage />
                  )}
                  {truckUserAuth.some((x) => x === user?.AccountType.Code) && (
                    <MyLoadOpportunityBasePage />
                  )}
                </>
              )}

              {active === 15 && <CustomerOfferList />}
              {/* {active === 16 && (
                <>
                  {(user?.AccountType.Name === "Lead Account" ||
                    user?.AccountType.Name === "Account" ||
                    user?.AccountType.Name === "Candidate Forwarder Musteri" ||
                    user?.AccountType.Name === "Forwarder" ||
                    user?.AccountType.Name === "Forwarder Transporter" ||
                    user?.AccountType.Name ===
                      "Candidate Forwarder Nakliyeci" ||
                    user?.AccountType.Name === "Candidate Forwarder") && (
                    <Address />
                  )}
                </>
              )} */}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default MyProfile;
