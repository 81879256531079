import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ResponsiveTable, {
  GridButtonType,
} from "../../../../../components/ResponsiveTable";
import { getContacts } from "../../../../../redux/profile/profileSaga";
import { ContactDto } from "../../../../../redux/profile/type";
import { getLocalizedMyProfile } from "../../../../../redux/selectors";
import { color } from "../../../../../utils/Properties";
import EditDriver from "./editDriver";
import CreateDriver from "./createDriver";
import { CommunicationLanguageObject } from "../../../../../types";
import { getCommunicationLanguages } from "../../../../../redux/actions";
import { Alert } from "rsuite";
const Contacts = () => {
  const [contacts, setContacts] = useState<ContactDto[]>();
  const [comLang, setComLang] = useState<CommunicationLanguageObject[]>();
  const strings = useSelector(getLocalizedMyProfile);
  const [selected, setSelected] = useState<ContactDto>();
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const dispatch = useDispatch();
  const getDestinationFunc = () => {
    dispatch(
      getContacts({
        payload: {
          onSuccess: (message, payload) => setContacts(payload),
          onError: (message) => {},
        },
        url: "Profile/Contacts",
      })
    );
  };
  useEffect(() => {
    dispatch(
      getCommunicationLanguages({
        payload: {
          onSuccess: (message, payload) => {
            setComLang(payload);
          },
          onError: (message) => {},
        },
        url: "System/GetCommunicationLanguage",
      })
    );
    getDestinationFunc();
    // eslint-disable-next-line
  }, [dispatch]);
  const columns = [
    {
      dataField: "firstname",
      caption: strings.getString("name"),
    },
    {
      dataField: "mobilephone",
      caption: strings.getString("phone"),
    },
    {
      dataField: "rol__c",
      caption: strings.getString("role"),
    },
    {
      dataField: "email",
      caption: strings.getString("email"),
    },
  ];
  const buttons = [
    {
      type: GridButtonType.edit,
      icon: "dx-icon-edit",
      onClick: (e: any) => {
        setShowCreate(false);
        setSelected(e);
      },
    },
  ];
  return (
    <div
      className="my-profile-card"
      style={{ backgroundColor: color.accent, position: "fixed" }}
    >
      <p className="newBoldHeadingStyle">{strings.getString("drivers")}</p>

      <ResponsiveTable
        data={contacts ?? []}
        buttons={buttons}
        showCreate={showCreate}
        columns={columns}
        createForm={
          <CreateDriver
            comLang={comLang}
            onSuccess={() => {
              Alert.success("Başarıyla eklendi.");
              getDestinationFunc();
            }}
          />
        }
        editForm={
          selected && (
            <EditDriver
              comLang={comLang}
              onSuccess={() => {
                Alert.success("Başarıyla güncellendi.");
                getDestinationFunc();
                return setShowCreate(true);
              }}
              selected={selected}
            />
          )
        }
      />
    </div>
  );
};
export default Contacts;
