import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Row } from "rsuite";
import {
  IncDescAuctionDto,
  IncDescAuctionPriceDto,
} from "../../../../redux/autions/inc-desc-type";
import { getLocalizedAuction } from "../../../../redux/selectors";
import { getCurrency } from "../../../../utils/Helpers";
import TermsModal from "./termsModal";
const IncPriceTab = ({
  auction,
  setAuction,
  priceData,
  onAccept,
}: {
  onAccept: () => void;
  priceData?: IncDescAuctionPriceDto;
  auction: IncDescAuctionDto;
  setAuction: (auction: IncDescAuctionDto) => void;
}) => {
  const t = useSelector(getLocalizedAuction);
  const [showTerms, setShowTerms] = useState(false); // eslint-disable-next-line

  const history = useHistory();
  const truckCount = (
    week: number | undefined,
    month: number | undefined,
    year: number | undefined
  ) => {
    if (week && week != null) {
      return week;
    } else if (month && month != null) {
      return month;
    } else if (year && year != null) {
      return month;
    } else {
      return 0;
    }
  };
  const truckString = (
    week: number | undefined,
    month: number | undefined,
    year: number | undefined
  ) => {
    if (week && week != null) {
      return t.getString("Weekly");
    } else if (month && month != null) {
      return t.getString("Monthly");
    } else if (year && year != null) {
      return t.getString("Yearly");
    } else {
      return "-";
    }
  };
  const getTime = (time: number) => {
    var minutes = Math.floor(time / 60).toString();
    var seconds = (time % 60).toString();
    if (seconds.length <= 1) {
      seconds = "0" + seconds;
    }
    return `${minutes.length <= 1 && "0"}${minutes}:${seconds}`;
  };
  return (
    <React.Fragment>
      <TermsModal open={showTerms} setOpen={setShowTerms} />

      <Row style={{ marginBlock: 10 }}>
        <Col md={12}>
          <div onClick={() => history.push("?chat=true")} className="tabButton">
            {t.getString("Ask Question")}
          </div>
        </Col>
        <Col md={12} style={{ textAlign: "end" }}>
          {priceData?.startingTender ? (
            <>
              {t.getString("Remaining Time")} : {priceData?.endingRemainingTime}
            </>
          ) : (
            <>
              {t.getString("Start Date")} : {priceData?.startingRemainingTime}
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={5} className="auctionInfoCard">
          <div> {t.getString("Loading Location")}</div>
          <div>{auction.loadpoint}</div>
        </Col>
        <Col md={5} className="auctionInfoCard">
          <div>{t.getString("UnLoading Location")}</div>
          <div>{auction.unloadpoint}</div>
        </Col>
        <Col md={4} className="auctionInfoCard">
          <div>{t.getString("Transit Time")}</div>
          <div>{auction.transittime ?? "-"}</div>
        </Col>
        <Col md={5} className="auctionInfoCard">
          <div>{t.getString("Load Type")}</div>
          <div>{auction.loadtype}</div>
        </Col>
        <Col md={5} className="auctionInfoCard">
          <div>{t.getString("Vehicle Count")}</div>
          <div>
            <span style={{ fontSize: 10 }}>
              (
              {truckString(
                auction.NumberOfTrucksPerWeek__c,
                auction.NumberOfTrucksPerMonth__c,
                auction.NumberOfTrucksPerYear__c
              )}
              )
            </span>
          </div>
          <div>
            {truckCount(
              auction.NumberOfTrucksPerWeek__c,
              auction.NumberOfTrucksPerMonth__c,
              auction.NumberOfTrucksPerYear__c
            )}{" "}
            {t.getString("Vehicle")}
          </div>{" "}
          <div>
            ({" "}
            {auction.VehicleTypeList.map((x, i) => (
              <span key={x.id} style={{ fontSize: "12px" }}>
                {x.vehicleType}
                {auction.VehicleTypeList.length !== i + 1 ? "," : " "}
              </span>
            ))}
            )
          </div>
        </Col>
      </Row>
      {priceData && (
        <>
          <Row style={{ marginBlock: 40 }}>
            <Col md={8} className="text-center">
              <div>{t.getString("Starting Price")}</div>
              <div>
                {getCurrency(
                  priceData?.starting_Price_Raise_Tender__c,
                  auction.currencyisocode
                )}
              </div>
            </Col>
            <Col md={8} className="text-center">
              <div>{t.getString("Increase Amount")}</div>
              <div>
                {getCurrency(
                  priceData?.increaseAmount_RaiseTender__c,
                  auction.currencyisocode
                )}
              </div>
            </Col>
            <Col md={8} className="text-center">
              <div>{t.getString("Waiting Time")}</div>
              <div>
                {priceData?.waitingTime_RaiseTender__c}{" "}
                {priceData?.waitingTimeUnit_RaiseTender__c}
              </div>
            </Col>
          </Row>
          <Row
            style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
          >
            <Col md={5} className="auctionInfoCard">
              {priceData?.raiseTenderLists.map((x, i) => (
                <div key={i}>
                  {i + 1} - {getCurrency(x.price, auction.currencyisocode)}
                </div>
              ))}
            </Col>
            <Col md={7} className="text-center mediumHeadingStyle">
              <div>{t.getString("Anlık Navlun")}</div>
              <div>
                {getCurrency(priceData?.activeAmount, auction.currencyisocode)}
              </div>
            </Col>
            {!priceData.endingTender && (
              <Col md={7} className="bigHeadingStyle text-center">
                <div>{t.getString("Time")}</div>
                <div>{getTime(priceData?.priceRaiseTime)} </div>
              </Col>
            )}
            <Col md={5} className="text-center">
              {priceData.startingTender && !priceData.endingTender && (
                <button
                  disabled={priceData?.endingTender}
                  className="auctionButton"
                  onClick={() => {
                    !priceData?.endingTender && onAccept();
                  }}
                >
                  <div>
                    {getCurrency(
                      priceData?.activeAmount,
                      auction.currencyisocode
                    )}
                  </div>
                  <div>{t.getString("ACCEPT")}</div>
                </button>
              )}
            </Col>
          </Row>
        </>
      )}
    </React.Fragment>
  );
};
export default IncPriceTab;
