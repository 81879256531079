import axios, { CancelToken, Method, AxiosError } from "axios";
import { call, put, select } from "redux-saga/effects";
import { baseUrl } from "../../utils/Properties";
import { getApiParams } from "../selectors";
import { logoutAction } from "./userActions";
export * from "./appActions";
export * from "./componentActions";
export * from "./homepageActions";
export * from "./staticActions";
export * from "./languageActions";
export * from "./userActions";
export * from "./systemActions";

export enum BodyType {
  raw,
  formdata,
}

interface ApiParams {
  url: string;
  method?: Method;
  bodyType?: BodyType;
  body?: object;
  cancelToken?: CancelToken;
}
interface ApiUnderTheHoodParams {
  token?: string;
  lang?: string;
}
export function* createRequestSaga({
  url,
  method,
  body,
  bodyType,
  cancelToken,
}: ApiParams) {
  const { token, lang } = yield select(getApiParams);
  let language = lang;
  if (language === "tr") language = "tr-TR";
  if (language === "en") language = "en-US";
  if (language === "ru") language = "ru-RU";
  return yield call(createInnerRequestSaga, {
    token,
    lang: language,
    url,
    method,
    bodyType,
    body,
    cancelToken,
  });
}
export const getFormData = (data: Object): FormData => {
  let formData = new FormData();
  Object.keys(data).forEach((key) => {
    const val = (data as any)[key];
    if (Array.isArray(val)) {
      val.forEach((v, i) => {
        if (Object.keys(v).length === 0) {
          formData.append(`${key}`, v);
        } else {
          if (typeof v === "object") {
            Object.keys(v).forEach((k) =>
              formData.append(`${key}[${i}].${k}`, v[k])
            );
          } else {
            formData.append(`${key}[${i}]`, v);
          }
        }
      });
    } else if (typeof val === "object" && !(val instanceof File)) {
      Object.keys(val).forEach((k) => formData.append(`${key}.${k}`, val[k]));
    } else formData.append(key, val);
  });
  return formData;
};
const getRequestHeaders = (bodyType: BodyType) => {
  switch (bodyType) {
    case BodyType.formdata:
      return {
        post: { "Content-Type": "multipart/form-data" },
        put: { "Content-Type": "multipart/form-data" },
        patch: { "Content-Type": "multipart/form-data" },
        delete: { "Content-Type": "multipart/form-data" },
      };
    case BodyType.raw:
    default:
      return {
        post: { "Content-Type": "application/json-patch+json" },
        put: { "Content-Type": "application/json-patch+json" },
        patch: { "Content-Type": "application/json-patch+json" },
        delete: { "Content-Type": "application/json-patch+json" },
      };
  }
};
const createInnerRequestSaga = ({
  token,
  lang,
  url,
  method,
  bodyType,
  body,
  cancelToken,
}: ApiParams & ApiUnderTheHoodParams) => {
  return axios
    .request({
      url,
      method,
      baseURL: baseUrl,
      transformRequest: [
        function (data, headers) {
          return data;
        },
      ],
      transformResponse: [
        function (data) {
          return data;
        },
      ],
      headers: {
        common: {
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${token || ""}`,
          "Accept-Language": lang || "en-US",
        },
        ...getRequestHeaders(bodyType || BodyType.raw),
      },

      params: method === "GET" && body ? body : undefined,
      data:
        method !== "GET" && body
          ? bodyType === BodyType.formdata
            ? getFormData(body)
            : JSON.stringify(body)
          : undefined,
      responseType: "json",
      cancelToken,
    })
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      console.log("ERROR AXIOS", error.response);
      if (!!error.isAxiosError && !error.response) {
        console.log("NETWORK ERROR: ", error.message);
      } else if (error.response?.status === 401) {
        console.log("ERROR 401", url, error.response);
        put(logoutAction());
      } else if (error.response?.status === 409) {
        console.log("ERROR 409", url, error.response);
        return error.response.data;
      }
    });
};
