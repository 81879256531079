import { Carousel as CarouselRS } from "rsuite";
import { SliderObject, SliderTimeObject } from "../../types";
import { color, webImageUrl } from "../../utils/Properties";

import "./style.css";

type CarouselType = {
  data: SliderObject[];
  time?: SliderTimeObject;
};

const Carousel = ({ data, time }: CarouselType) => {
  return (
    <CarouselRS
      autoplay
      autoplayInterval={time?.value ? time.value : 10000}
      className="custom-slider"
      placement="right"
      shape="bar"
    >
      {data.map((item, i) => (
        <div
          key={i}
          className="homepage-carousel-item"
          style={{
            backgroundImage: `url(${webImageUrl + item.image})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="homepageWidth" style={{ margin: 0 }}>
            <h1
              className="h1 homepage-carousel-title"
              style={{
                color: color.textHeader,
                maxWidth: 438,
                marginBlockEnd: 20,
              }}
            >
              {item.title}
            </h1>
            <h3
              className="lightHeadingStyle homepage-carousel-subtitle"
              style={{
                color: color.textPrimary,
                maxWidth: 320,
              }}
            >
              {item.description}
            </h3>
          </div>
        </div>
      ))}
    </CarouselRS>
  );
};

export default Carousel;
