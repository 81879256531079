import { Switch, useHistory, useLocation } from "react-router-dom";
import "./style.css";
import { Breadcrumb, ChatDrawer } from "../../components";
import ScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import {
  getApiParams,
  getLocalizedChat,
  getUserState,
  scrollPositionSelector,
} from "../../redux/selectors";
import { useEffect, useRef, useState } from "react";
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import { ChatMessageReceiveObject } from "../../components/ChatDrawer";
import { chatUrl } from "../../utils/Properties";
import { ToastContainer, toast } from "react-toastify";
import {
  getLoadOpportunityDetail,
  getVehicleOpportunityDetail,
} from "../../redux/actions";
import { LoadOpportunities } from "../../types";
import { authRoutes, homeRoutes, nonAuthRoutes } from "../routes/allRoutes";
import Authmiddleware from "../routes/middleware/Authmiddleware";
import { VehicleChatMessageReceiveObject } from "../../components/VehicleChatDrawer/utils";
import { VehicleOpportunityDto } from "../../redux/opportunity/type";
import VehicleChatDrawer from "../../components/VehicleChatDrawer";
import { TenderChatMessageReceiveObject } from "../inner-screens/auctions/detail/chat/utils";

const VEHICLEHUB_ENDPOINT = chatUrl + "api/vehiclechat";
const HUB_ENDPOINT = chatUrl + "api/chat";
const TENDERHUB_ENDPOINT = chatUrl + "api/tenderchat";
const INCTENDERHUB_ENDPOINT = chatUrl + "api/raisereductiontenderchat";
const BaseScreen = () => {
  const idRef = useRef<string>();
  const vehicleidRef = useRef<string>();
  const tenderidRef = useRef<string>();
  const inctenderidRef = useRef<string>();
  const { lang, token } = useSelector(getApiParams);
  const strings = useSelector(getLocalizedChat);

  const dispatch = useDispatch();
  const [chatload, setchatload] = useState<LoadOpportunities>();
  const [vehicleChatload, setVehicleChatload] =
    useState<VehicleOpportunityDto>();
  const [showChat, setShowChat] = useState<boolean>(false);
  const [showVehicleChat, setShowVehicleChat] = useState<boolean>(false);
  const connectionRef = useRef<HubConnection | null>(null);
  const [connectionStatus, setConnectionStatus] = useState<
    "offline" | "connecting" | "connected" | "failed"
  >("offline");
  const location = useLocation();
  const history = useHistory();
  const user = useSelector(getUserState);
  const scrollPosition = useSelector(scrollPositionSelector);
  const scrollRef = useRef<HTMLElement | null>(null);
  useEffect(() => {
    user && token && connectVehicleChat(user.UserID, token);
    user && token && connectChat(user.UserID, token);
    user && token && connectTenderChat(user.UserID, token);
    user && token && connectIncTenderChat(user.UserID, token); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (scrollPosition !== undefined)
      scrollRef.current?.scrollTo({ top: scrollPosition, behavior: "smooth" }); // eslint-disable-next-line
  }, [scrollPosition]);
  const connectChat = (userId: string, tokenRes: string) => {
    console.log("bildirim");
    if (connectionRef.current !== undefined) {
      setConnectionStatus("connecting");
      const connection = new HubConnectionBuilder()
        .withUrl(HUB_ENDPOINT, {
          headers: { "Accept-Language": `${lang}` },
          accessTokenFactory: () => tokenRes,
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Debug)
        .build();
      connection.serverTimeoutInMilliseconds = 25000;
      console.log(`Trying to connect to ${HUB_ENDPOINT}`);
      connection
        .start()
        .then(() => {
          console.log(`Connected to ${HUB_ENDPOINT}`);
          setConnectionStatus("connected");
        })
        .catch((err: any) => {
          setConnectionStatus("failed");
          console.log(`Error starting the connection: ${err.toString()}`);
        });
      connection.onclose(async () => {
        console.log(`Disconnected from ${HUB_ENDPOINT} ${connectionStatus}`);
        connectionRef.current = null;
      });
      connection.on(userId, function (payload: ChatMessageReceiveObject) {
        const message = strings.formatString(
          strings.getString("messagealert"),
          payload.userName,
          payload.message
        );
        console.log(
          "location.search.includes(payload.opportunity_destination__c)",
          location.search.includes(payload.opportunity_destination__c)
        );
        if (
          !(
            idRef.current &&
            idRef.current === payload.opportunity_destination__c
          )
        ) {
          if (!location.search.includes(payload.opportunity_destination__c)) {
            vehicleidRef.current = undefined;
            idRef.current = payload.opportunity_destination__c;
            toast.info(message, {
              theme: "colored",
              toastId: payload.opportunity_destination__c,
            });
          }
        }
      });
      connectionRef.current = connection;
      setConnectionStatus("connected");
    } else console.log("CONNECTION ALREADY ESTABLIESHED");
    return () => {
      try {
        connectionRef.current?.stop().then(() => {
          console.log("CONNECTION STOPPED");
          connectionRef.current = null;
        });
      } catch (e) {
        console.log("ERROR UNMOUNT", e);
      }
    };
  };
  const connectVehicleChat = (userId: string, tokenRes: string) => {
    console.log("bildirim");
    if (connectionRef.current !== undefined) {
      setConnectionStatus("connecting");
      const connection = new HubConnectionBuilder()
        .withUrl(VEHICLEHUB_ENDPOINT, {
          headers: { "Accept-Language": `${lang}` },
          accessTokenFactory: () => tokenRes,
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Debug)
        .build();
      connection.serverTimeoutInMilliseconds = 25000;
      console.log(`Trying to connect to ${VEHICLEHUB_ENDPOINT}`);
      connection
        .start()
        .then(() => {
          console.log(`Connected to ${VEHICLEHUB_ENDPOINT}`);
          setConnectionStatus("connected");
        })
        .catch((err: any) => {
          setConnectionStatus("failed");
          console.log(`Error starting the connection: ${err.toString()}`);
        });
      connection.onclose(async () => {
        console.log(
          `Disconnected from ${VEHICLEHUB_ENDPOINT} ${connectionStatus}`
        );
        connectionRef.current = null;
      });
      connection.on(
        userId,
        function (payload: VehicleChatMessageReceiveObject) {
          const message = strings.formatString(
            strings.getString("messagealert"),
            payload.userName,
            payload.message
          );
          if (
            !(
              vehicleidRef.current &&
              vehicleidRef.current === payload.vehicle_opp_destination__c
            )
          ) {
            if (!location.search.includes(payload.vehicle_opp_destination__c)) {
              idRef.current = undefined;
              vehicleidRef.current = payload.vehicle_opp_destination__c;
              toast.info(message, {
                theme: "colored",
                toastId: payload.vehicle_opp_destination__c,
              });
            }
          }
        }
      );
      connectionRef.current = connection;
      setConnectionStatus("connected");
    } else console.log("CONNECTION ALREADY ESTABLIESHED");
  };
  const connectTenderChat = (userId: string, tokenRes: string) => {
    console.log("bildirim");
    if (connectionRef.current !== undefined) {
      setConnectionStatus("connecting");
      const connection = new HubConnectionBuilder()
        .withUrl(TENDERHUB_ENDPOINT, {
          headers: { "Accept-Language": `${lang}` },
          accessTokenFactory: () => tokenRes,
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Debug)
        .build();
      connection.serverTimeoutInMilliseconds = 25000;
      console.log(`Trying to connect to ${TENDERHUB_ENDPOINT}`);
      connection
        .start()
        .then(() => {
          console.log(`Connected to ${TENDERHUB_ENDPOINT}`);
          setConnectionStatus("connected");
        })
        .catch((err: any) => {
          setConnectionStatus("failed");
          console.log(`Error starting the connection: ${err.toString()}`);
        });
      connection.onclose(async () => {
        console.log(
          `Disconnected from ${TENDERHUB_ENDPOINT} ${connectionStatus}`
        );
        connectionRef.current = null;
      });
      connection.on(userId, function (payload: TenderChatMessageReceiveObject) {
        const message = strings.formatString(
          strings.getString("messagealert"),
          payload.userName,
          payload.message
        );
        if (
          !(
            tenderidRef.current &&
            tenderidRef.current === payload.opportunity__c
          )
        ) {
          if (!location.search.includes(payload.opportunity__c)) {
            idRef.current = undefined;
            inctenderidRef.current = undefined;
            vehicleidRef.current = undefined;
            tenderidRef.current = payload.opportunity__c;
            toast.info(message, {
              theme: "colored",
              toastId: payload.opportunity__c,
            });
          }
        }
      });
      connectionRef.current = connection;
      setConnectionStatus("connected");
    } else console.log("CONNECTION ALREADY ESTABLIESHED");
  };

  const connectIncTenderChat = (userId: string, tokenRes: string) => {
    if (connectionRef.current !== undefined) {
      setConnectionStatus("connecting");
      const connection = new HubConnectionBuilder()
        .withUrl(INCTENDERHUB_ENDPOINT, {
          headers: { "Accept-Language": `${lang}` },
          accessTokenFactory: () => tokenRes,
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Debug)
        .build();
      connection.serverTimeoutInMilliseconds = 25000;
      console.log(`Trying to connect to ${INCTENDERHUB_ENDPOINT}`);
      connection
        .start()
        .then(() => {
          console.log(`Connected to ${INCTENDERHUB_ENDPOINT}`);
          setConnectionStatus("connected");
        })
        .catch((err: any) => {
          setConnectionStatus("failed");
          console.log(`Error starting the connection: ${err.toString()}`);
        });
      connection.onclose(async () => {
        console.log(
          `Disconnected from ${INCTENDERHUB_ENDPOINT} ${connectionStatus}`
        );
        connectionRef.current = null;
      });
      connection.on(userId, function (payload: any) {
        const message = strings.formatString(
          strings.getString("messagealert"),
          payload.userName,
          payload.message
        );
        console.log(payload, "AAApayload");
        if (
          !(
            inctenderidRef.current &&
            inctenderidRef.current === payload.opportunity_destination__c
          )
        ) {
          if (!location.search.includes(payload.opportunity_destination__c)) {
            idRef.current = undefined;
            vehicleidRef.current = undefined;
            tenderidRef.current = undefined;
            inctenderidRef.current = payload.opportunity_destination__c;
            toast.info(message, {
              theme: "colored",
              toastId: payload.opportunity_destination__c,
            });
          }
        }
      });
      connectionRef.current = connection;
      setConnectionStatus("connected");
    } else console.log("CONNECTION ALREADY ESTABLIESHED");
  };
  return (
    <div className="baseScreen">
      {vehicleChatload && (
        <VehicleChatDrawer
          setTruckUndefined={() => setVehicleChatload(undefined)}
          show={showVehicleChat}
          setShow={(show: boolean) => {
            if (!show) {
              vehicleidRef.current = undefined;
            }
            setShowVehicleChat(show);
          }}
          truck={vehicleChatload}
        />
      )}
      {chatload && (
        <ChatDrawer
          show={showChat}
          setShow={(show: boolean) => {
            if (!show) {
              idRef.current = undefined;
            }
            setShowChat(show);
          }}
          truck={chatload}
        />
      )}
      <ToastContainer
        theme="colored"
        position="top-right"
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        onClick={(e) => {
          if (inctenderidRef.current) {
            if (
              location.pathname.includes(inctenderidRef.current) &&
              !location.search.includes("?chat=true")
            ) {
              history.push("?chat=true");
            } else {
              window.open(
                "/inc-desc-auction-details/" +
                  inctenderidRef.current +
                  "?chat=true"
              );
            }
          }
          if (tenderidRef.current) {
            if (
              location.pathname.includes(tenderidRef.current) &&
              !location.search.includes("?chat=true")
            ) {
              history.push("?chat=true");
            } else {
              window.open(
                "/auction-details/" + tenderidRef.current + "?chat=true"
              );
            }
          }

          idRef.current &&
            dispatch(
              getLoadOpportunityDetail({
                payload: {
                  onSuccess: (message, payload) => {
                    setchatload(payload);
                    setShowChat(true);
                  },
                  onError: (message) => {},
                },
                url:
                  "LoadOpportunity/GetByLoadOpportunities?sfid=" +
                  idRef.current,
              })
            );

          vehicleidRef.current &&
            dispatch(
              getVehicleOpportunityDetail({
                payload: {
                  onSuccess: (message, payload) => {
                    setVehicleChatload(payload);
                    setShowVehicleChat(true);
                  },
                  onError: (message) => {},
                },
                url:
                  "VehicleOpportunity/GetByVehicleOpportunities?sfid=" +
                  vehicleidRef.current,
              })
            );
        }}
      />

      <Switch location={location}>
        {homeRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            isProfileRedirect={false}
            component={route.component}
            key={idx}
            isAuthProtected={false}
            exact
          />
        ))}
        <div className="page">
          <div className="innerPage">
            <Breadcrumb />

            <div className="innerPageContent">
              <ScrollBar
                id="scrollheight"
                style={{
                  width: "100%",
                }}
                containerRef={(container) => {
                  scrollRef.current = container;
                }}
              >
                {authRoutes.map((route, idx) => (
                  <Authmiddleware
                    isProfileRedirect={route.profileRedirect}
                    roles={route.roles}
                    path={route.path}
                    component={route.component}
                    key={idx}
                    isAuthProtected={true}
                    exact
                  />
                ))}
                {nonAuthRoutes.map((route, idx) => (
                  <Authmiddleware
                    roles={route.roles}
                    path={route.path}
                    isProfileRedirect={false}
                    component={route.component}
                    key={idx}
                    isAuthProtected={false}
                    exact
                  />
                ))}
              </ScrollBar>
            </div>
          </div>
        </div>
      </Switch>
    </div>
  );
};
export default BaseScreen;
