import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "../../../../../components";
import * as Yup from "yup";
import {
  getLocalizedErrors,
  getLocalizedTruckRegistration,
} from "../../../../../redux/selectors";
import { InputType } from "../../../../../components/Input";
import { useState } from "react";
import { getLocationForOpp } from "../../../../../redux/opportunity/saga";
import AutoCompleteRs from "../../../../../components/AutoCompleteRs";
import { AutoComplete, Col, Row } from "rsuite";
import { color } from "../../../../../utils/Properties";

const EditDestination = ({
  data,
  baseValues,
  errors,
  touched,
  onSubmit,
  setCountryList,
}: {
  setCountryList: (
    values: {
      countryid: string;
      no: string;
      locationId: string;
    }[]
  ) => void;
  data: {
    countryid: string;
    fromcountryid: string;
    location_To_Go__c_Name: string[];
    location_To_Load__c_Name: string;
    location_To_Load__c: string;
    location_To_Go__c: string[];
    transporter_Requested_Price__c: string;
    no: string;
  };
  onSubmit: (values: any) => void;
  baseValues: any;
  errors: any;
  touched: any;
}) => {
  const strings = useSelector(getLocalizedTruckRegistration);
  const errorStrings = useSelector(getLocalizedErrors);
  const dispatch = useDispatch();
  const [list, setList] = useState<any[]>([]);
  return (
    <Formik
      enableReinitialize
      initialValues={{
        no: data.no,
        fromcountryid: data.fromcountryid,
        location_To_Load__c: data.location_To_Load__c,
        location_To_Go__c: data.location_To_Go__c,
        location_To_Load__c_Name: data.location_To_Load__c_Name,
        location_To_Go__c_Name: data.location_To_Go__c_Name,
        transporter_Requested_Price__c: data.transporter_Requested_Price__c,
        locationGo: "",
        countryid: data.countryid,
      }}
      validationSchema={Yup.object().shape({
        location_To_Load__c: Yup.string().required(
          errorStrings.getString("required")
        ),
        location_To_Go__c: Yup.array()
          .min(1, errorStrings.getString("required"))
          .required(errorStrings.getString("required")),
      })}
      onSubmit={(values) => {
        var array = values.location_To_Go__c_Name.map((x: any) => {
          return {
            countryid: x.countryid,
            no: values.no,
            locationId: x.value,
          };
        });
        setCountryList([
          ...array,
          {
            countryid: values.fromcountryid,
            no: values.no,
            locationId: values.location_To_Load__c,
          },
        ]);
        return onSubmit(values);
      }}
    >
      {({
        handleChange,
        handleSubmit,
        handleBlur,
        setFieldValue,
        errors,
        touched,
        values,
      }) => (
        <>
          <Row>
            <Col md={8}>
              <AutoCompleteRs
                selectedItem={values.location_To_Load__c_Name}
                error={errors.location_To_Load__c}
                label={strings.getString("Location to Load")}
                action={getLocationForOpp}
                touch={touched.location_To_Load__c}
                url="VehicleOpportunity/GetLocations"
                onselectitem={(value) => {
                  setFieldValue("location_To_Load__c", value);
                }}
                onselectitemforlabel={(value) => {
                  setFieldValue("location_To_Load__c_Name", value);
                }}
                onselectcountrycode={(value, id) => {
                  setFieldValue("fromcountrycode", value);
                  id && setFieldValue("fromcountryid", value);
                }}
              />
            </Col>
            <Col md={8}>
              <span
                style={{
                  fontSize: 13,
                  marginBlockEnd: 2,
                  color: color.textPrimary,
                }}
              >
                {strings.getString("Location to Go")}
              </span>
              <AutoComplete
                value={values.locationGo}
                error={errors.location_To_Go__c}
                touch={touched.location_To_Go__c}
                onSelect={(item: any, e: any) => {
                  console.log(item);
                  setFieldValue("location_To_Go__c", [
                    ...values.location_To_Go__c,
                    item.value,
                  ]);
                  const array = [...values.location_To_Go__c_Name, item];
                  setFieldValue("location_To_Go__c_Name", array);
                  return setFieldValue("countryid", item.countryid);
                }}
                onExiting={() => {
                  return setFieldValue("locationGo", "");
                }}
                placement="bottomStart"
                data={
                  list
                    ? list.map((x) => {
                        return {
                          value: x.sfid,
                          label: x.name,
                          countryid: x.countryid,
                        };
                      })
                    : []
                }
                onChange={(value) => {
                  setFieldValue("locationGo", value);
                  dispatch(
                    getLocationForOpp({
                      payload: {
                        onSuccess: (message, payload) => {
                          setList(payload ? payload : []);
                        },
                        //@ts-ignore
                        onError: (message) => {},
                      },
                      url: `HomePage/GetLocationData?SearchParam=${value}`,
                    })
                  );
                }}
                menuClassName={"renderedMneu"}
                renderItem={(item) => {
                  return item.label;
                }}
              />
              <div style={{ paddingInline: 10, paddingBlock: 10 }}>
                {values.location_To_Go__c_Name.map((x: any) => (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>{x.label}</div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setFieldValue(
                          "location_To_Go__c",
                          values.location_To_Go__c.filter((y) => y !== x.value)
                        );
                        setFieldValue(
                          "location_To_Go__c_Name",
                          values.location_To_Go__c_Name.filter(
                            (y: any) => y.value !== x.value
                          )
                        );
                      }}
                    >
                      X
                    </div>
                  </div>
                ))}
              </div>
            </Col>
            <Col md={7}>
              <Input
                inputType={InputType.number}
                value={values.transporter_Requested_Price__c}
                label={strings.getString("Requested Price")}
                name="transporter_Requested_Price__c"
                onChange={handleChange("transporter_Requested_Price__c")}
                onBlur={handleBlur("transporter_Requested_Price__c")}
                error={errors.transporter_Requested_Price__c}
                touched={touched.transporter_Requested_Price__c}
              />
            </Col>
            <Col md={1}>
              <div className="smResponsiveButtonDiv gridPosition">
                <button
                  type="submit"
                  className="gridIcon "
                  onClick={() => handleSubmit()}
                >
                  <i className="dx-icon-save" style={{ color: "white" }} />
                </button>
              </div>
            </Col>
          </Row>
        </>
      )}
    </Formik>
  );
};
export default EditDestination;
