import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ProfileLoadCard } from "../../../../../../components";
import ProfileLoadCardMobil from "../../../../../../components/ProfileLoadCardMobil";
import { getMyLoadOpportunities } from "../../../../../../redux/profile/profileSaga";
import { LoadOpportunities } from "../../../../../../types";
import { Pagination } from "rsuite";

const MyCloseLoadOpportunity = () => {
  const dispatch = useDispatch();
  const [dataList, setDataList] = useState<LoadOpportunities[]>();
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(6);
  const [total, setTotal] = useState<number>();

  useEffect(() => {
    dispatch(
      getMyLoadOpportunities({
        payload: {
          onSuccess: (message, payload, response) => {
            setDataList(payload);
            console.log(response);
            setTotal(response.TotalCount);
            setTake(response.Taken);
          },
          onError: (message) => {},
        },
        url: `VehicleOpportunity/GetMyClosingLoadOpportunities?_skip=${
          (page - 1) * take
        }&_take=${take}`,
      })
    ); // eslint-disable-next-line
  }, [dispatch, page]); // eslint-disable-next-line

  return (
    <>
      <div>
        <div className="profile-offer-load-card">
          {dataList &&
            dataList.map((data, i) => (
              <ProfileLoadCard key={i} load={data} isOffer={false} />
            ))}
        </div>
        <div className="mobile-profile-offer-load-card">
          {dataList &&
            dataList.map((data, i) => (
              <ProfileLoadCardMobil key={i} load={data} isOffer={false} />
            ))}
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {total ? (
            <Pagination
              prev
              next
              activePage={page}
              size="lg"
              total={total}
              pages={Math.round(total / take)}
              onSelect={(page: number) => {
                setPage(page);
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};
export default MyCloseLoadOpportunity;
