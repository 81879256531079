import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProfileTruckCard } from "../../../../../../components";
import ProfileTruckCardMobil from "../../../../../../components/ProfileTruckCardMobil";
import { VehicleOpportunityDto } from "../../../../../../redux/opportunity/type";
import {
  CancelMyTruckOpportunities,
  getMyTruckOpportunities,
} from "../../../../../../redux/profile/profileSaga";
import { getLocalizedMyProfile } from "../../../../../../redux/selectors";
import { confirm } from "devextreme/ui/dialog";
import { Alert, Pagination } from "rsuite";
const MyTruckOpportunity = () => {
  const dispatch = useDispatch();
  const [dataList, setDataList] = useState<VehicleOpportunityDto[]>();
  const strings = useSelector(getLocalizedMyProfile);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(6);
  const [total, setTotal] = useState<number>();

  useEffect(() => {
    dispatch(
      getMyTruckOpportunities({
        payload: {
          onSuccess: (message, payload, response) => {
            setDataList(payload);
            setTotal(response.TotalCount);
            setTake(response.Taken);
          },
          onError: (message) => {},
        },
        url: `LoadOpportunity/GetMyVehicleOpportunities?_skip=${
          (page - 1) * take
        }&_take=${take}`,
      })
    ); // eslint-disable-next-line
  }, [dispatch, page]);
  const cancelOpportunity = (id: string) => {
    var confirmResp = confirm(
      strings.getString("Are you sure want to cancel"),
      strings.getString("Cancel Opportunity")
    );
    confirmResp.then((result) => {
      if (result) {
        dispatch(
          CancelMyTruckOpportunities({
            payload: {
              onSuccess: (message, payload) => {
                Alert.success(strings.getString("Success"));
                dispatch(
                  getMyTruckOpportunities({
                    payload: {
                      onSuccess: (message, payload, response) => {
                        setDataList(payload);
                        setTotal(response.TotalCount);
                        setTake(response.Taken);
                      },
                      onError: (message) => {},
                    },
                    url: `LoadOpportunity/GetMyVehicleOpportunities?_skip=${
                      (page - 1) * take
                    }&_take=${take}`,
                  })
                );
              },
              onError: (message) => {
                Alert.error(message);
              },
            },
            body: {
              sfid: id,
            },
          })
        );
      }
    });
  };
  return (
    <>
      <div>
        <div className="profile-offer-load-card">
          {dataList &&
            dataList.map((data, i) => (
              <ProfileTruckCard
                key={i}
                truck={data}
                isOffer={false}
                cancelOpportunity={() => {
                  return cancelOpportunity(data.id);
                }}
              />
            ))}
        </div>
        <div className="mobile-profile-offer-load-card">
          {dataList &&
            dataList.map((data, i) => (
              <ProfileTruckCardMobil
                key={i}
                truck={data}
                isOffer={false}
                cancelOpportunity={() => {
                  return cancelOpportunity(data.id);
                }}
              />
            ))}
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {total && (
            <Pagination
              prev
              next
              activePage={page}
              size="lg"
              total={total}
              pages={Math.round(total / take)}
              onSelect={(page: number) => {
                console.log(page);
                setPage(page);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default MyTruckOpportunity;
