import { MutableRefObject, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader, Popover, Whisper } from "rsuite";
import { Divider, Icon, Nav } from "..";
import { getLocalizedTruckCard } from "../../redux/selectors";
import {
  color,
  GOOGLE_MAPS_API_KEY,
  mapContainerStyles,
  CUSTOM_MAP,
} from "../../utils/Properties";
import moment from "moment";
import "./style.css";
import { VehicleOpportunityDto } from "../../redux/opportunity/type";
import Flag from "react-flagkit";
import { getCurrency } from "../../utils/Helpers";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import ScrollBar from "react-perfect-scrollbar";
import { getVehicleDetailWithoutLoader } from "../../redux/actions";
type TruckCardProps = {
  truck: VehicleOpportunityDto;
  selected: boolean;
  setSelected: (truckId: string) => void;
  details: string;
  setDetails: (truckId: string) => void;
  favList: string[];
  setFavList: (favList: string[]) => void;
  openModal: (id: string) => void;
  openChat: () => void;
  openBooking: (truck: VehicleOpportunityDto, accept: boolean) => void;
  handlePrint: (ref: MutableRefObject<null>) => void;
};

const dividerPadding: number = 6;

const TruckCardMobil = ({
  truck,
  selected,
  setSelected,
  details,
  setDetails,
  favList,
  openModal,
  setFavList,
  openChat,
  openBooking,
  handlePrint,
}: TruckCardProps) => {
  const printerRef = useRef(null);
  const [selectedTruck, setSelectedTruck] = useState(truck);
  const strings = useSelector(getLocalizedTruckCard);

  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const navContent = [
    {
      id: 0,
      icon: "list",
      activeColor: color.primary,
      label: "",
    },
    {
      id: 1,
      icon: "indicator",
      activeColor: color.primary,
      label: "",
    },
    {
      id: 2,
      icon: "shorter-list",
      activeColor: color.primary,
      label: "",
    },
    {
      id: 3,
      icon: "route",
      activeColor: color.primary,
      label: "",
    },
    {
      id: 4,
      icon: "map-pin",
      activeColor: color.primary,
      label: "",
    },
  ];
  return (
    <div
      ref={printerRef}
      className="verticalTruckCard"
      style={{
        boxShadow: "none",
        height:
          details === selectedTruck.destination_vehicletype_vehicleopp__c
            ? "auto"
            : 500,
        border: "1px solid",
        borderColor: selected ? color.primary : "#e6e6e6",
      }}
    >
      <div
        className="smallRegularText"
        style={{
          width: "100%",
          paddingInline: 15,
          marginBlock: 10,
        }}
      >
        <div
          style={{
            width: "100%",
            height: "max-content",
            display: "flex",
            marginBlockEnd: 8,
          }}
        >
          <div
            className="vehicleLogo"
            style={{
              backgroundColor: color.accent,
              borderRadius: 10,
              marginInlineStart: -5,
              marginBlockStart: -5,
            }}
          >
            <img
              className="imageStyles"
              style={{ padding: 5, borderRadius: 12 }}
              src={selectedTruck.profileimage}
              alt=""
            />
          </div>
          <div
            style={{
              width: `calc(100% - 65px)`,
              height: 65,
              display: "flex",
              flexDirection: "column",
              gap: 10,
              paddingInlineStart: 5,
            }}
          >
            <p className="mediumHeadingStyle" style={{ lineHeight: "23px" }}>
              {selectedTruck.rapidadvisorname}
            </p>
            <div
              className="smallText"
              style={{ display: "flex", alignItems: "center" }}
            >
              {strings.getString("post_end_date")}:{" "}
              <span className="smallText">
                {selectedTruck.lastofferdate
                  ? moment(selectedTruck.lastofferdate).format("l")
                  : ""}
              </span>
            </div>
          </div>
        </div>
        <Divider paddingBlock={dividerPadding} />
        <div style={{ display: "flex", paddingInline: 3 }}>
          <div style={{}}>
            <span className="smallBoldText" style={{ opacity: 0.5 }}>
              {strings.getString("file_number")}
            </span>
            <p>{"#" + selectedTruck.filenumber}</p>
          </div>
        </div>{" "}
        <Divider paddingBlock={dividerPadding} />
        <div style={{ display: "flex", paddingInline: 3 }}>
          <div>
            <p className="boldText " style={{ color: color.blackFifty }}>
              {strings.getString("date")}
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingBlockStart: 8,
              }}
            >
              <p>
                {selectedTruck.firstavailabilitydate
                  ? moment(selectedTruck.firstavailabilitydate).format("l")
                  : "-"}
              </p>
              <Icon icon="right-bold" size={13} style={{ marginInline: 3 }} />
              <br />
              <p>
                {selectedTruck.lastavailabilitydate
                  ? moment(selectedTruck.lastavailabilitydate).format("l")
                  : "-"}
              </p>
            </div>
          </div>
        </div>{" "}
        <Divider paddingBlock={dividerPadding} />
        <div
          style={{
            paddingInline: 3,
          }}
        >
          <span className="smallBoldText" style={{ opacity: 0.5 }}>
            {strings.getString("truck_type")}
          </span>
          <p>{selectedTruck.trucktypename}</p>
        </div>
        <Divider paddingBlock={dividerPadding} />
        <div
          style={{
            paddingInline: 3,
          }}
        >
          <p className="smallBoldText" style={{ opacity: 0.5 }}>
            {strings.getString("route")}
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p className="biggerText">
              {selectedTruck.loadcountry ? selectedTruck.loadcountry : "-"}
            </p>
            <Icon
              icon="right-bold"
              color={color.textSecondary}
              size={13}
              style={{ marginInline: 5 }}
            />
            <p className="biggerText">
              {selectedTruck.tocountry ? selectedTruck.tocountry : "-"}
            </p>
          </div>
        </div>
        <Divider paddingBlock={dividerPadding} />
        <div
          style={{
            paddingInline: 3,
            display: "flex",
            alignItems: "center",
          }}
        >
          <div>
            <span className="smallBoldText" style={{ opacity: 0.5 }}>
              {strings.getString("estimated_amount")}
            </span>
            <p>
              {selectedTruck.currencyisocode
                ? getCurrency(
                    selectedTruck.estimatedamount,
                    selectedTruck.currencyisocode
                  )
                : selectedTruck.estimatedamount}
            </p>
          </div>
          {selectedTruck.estimatedamount && (
            <div
              className={
                selectedTruck.offerHasBeen
                  ? "offerButtonDisabled smallText"
                  : "offerButton smallText"
              }
              style={{ marginBlock: 10, marginInline: 10 }}
              onClick={() => {
                !selectedTruck.offerHasBeen &&
                  openBooking(selectedTruck, false);
              }}
            >
              {strings.getString("make_offer")}
            </div>
          )}
        </div>
        <div
          className={
            selectedTruck.offerHasBeen
              ? "actionButtonDisabled "
              : "actionButton "
          }
          onClick={() => {
            if (!selectedTruck.offerHasBeen) {
              !(
                selectedTruck.shipperofferprice &&
                selectedTruck.shipperofferprice > 0
              ) && selectedTruck.estimatedamount
                ? openBooking(selectedTruck, true)
                : openBooking(selectedTruck, false);
            }
          }}
          style={{
            width: "100%",
            cursor: !(
              selectedTruck.shipperofferprice &&
              selectedTruck.shipperofferprice > 0
            )
              ? "pointer"
              : "no-drop",
          }}
        >
          {!selectedTruck.offerHasBeen ? (
            selectedTruck.estimatedamount ? (
              <p style={{ textAlign: "center" }}>
                {strings.getString("accept_now")}
              </p>
            ) : (
              <p style={{ textAlign: "center" }}>
                {strings.getString("make_offer")}
              </p>
            )
          ) : (
            <p style={{ textAlign: "center" }}>
              {strings.getString("offersending")}
            </p>
          )}
        </div>
        <div className="boldText" style={{ display: "flex", marginBlock: 7 }}>
          <Icon icon="clock" size={17} style={{ marginInlineEnd: 5 }} />
          <p style={{ marginInlineEnd: 5, fontWeight: 600 }}>
            {strings.getString("estimates")}
          </p>
          <span style={{ marginInlineEnd: 30 }}>
            {selectedTruck.estimatedarrivaltime
              ? Math.round(selectedTruck.estimatedarrivaltime)
              : "-"}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: 44,
            width: "100%",
            border: "1px solid",
            borderColor: color.borderSecondary,
            borderRadius: 5,
            cursor: "pointer",
            backgroundColor: color.accent,
          }}
        >
          <Whisper
            delay={500}
            placement="top"
            trigger="hover"
            speaker={
              <Popover>
                <p>{strings.getString("details")}</p>
              </Popover>
            }
            enterable
          >
            <div
              className="extraInfosButton"
              style={{
                borderColor: color.borderSecondary,
                borderStartStartRadius: 5,
                borderEndStartRadius: 5,
                width: "33%",
              }}
              onClick={() => {
                if (
                  details ===
                  selectedTruck.destination_vehicletype_vehicleopp__c
                ) {
                  setDetails("");
                } else {
                  setLoader(true);
                  setDetails(
                    selectedTruck.destination_vehicletype_vehicleopp__c
                  );
                  dispatch(
                    getVehicleDetailWithoutLoader({
                      payload: {
                        onSuccess: (message, payload) => {
                          payload && setSelectedTruck(payload);
                          return setLoader(false);
                        },
                        onError: (message) => {
                          setLoader(false);
                        },
                      },
                      url: `VehicleOpportunity/GetByVehicleOpportunities?sfid=${selectedTruck.destination_vehicletype_vehicleopp__c}`,
                    })
                  );
                }
              }}
            >
              <Icon icon="more" />
            </div>
          </Whisper>
          <Whisper
            delay={500}
            placement="top"
            trigger="hover"
            speaker={
              <Popover>
                <p>{strings.getString("print")}</p>
              </Popover>
            }
            enterable
          >
            <div
              className="extraInfosButton"
              style={{
                borderColor: color.borderSecondary,
                width: "33%",
              }}
              onClick={() => handlePrint(printerRef)}
            >
              <Icon icon="printer" color={color.blackFifty} />
            </div>
          </Whisper>

          <Whisper
            delay={500}
            placement="top"
            trigger="hover"
            speaker={
              <Popover>
                <p>{strings.getString("chat")}</p>
              </Popover>
            }
            enterable
          >
            <div
              className="extraInfosButton"
              style={{ border: "none", width: "33%" }}
              onClick={() => openChat()}
            >
              <Icon icon="chat" />
            </div>
          </Whisper>
        </div>
      </div>
      {details === selectedTruck.destination_vehicletype_vehicleopp__c && (
        <Divider paddingBlock={"6px 0px"} />
      )}

      <div
        className="extraDetails  mobil-extraDetailsContent"
        style={{
          width: 223,
          paddingInline: 0,
          display:
            details === selectedTruck.destination_vehicletype_vehicleopp__c
              ? "block"
              : "none",
          height:
            details === selectedTruck.destination_vehicletype_vehicleopp__c
              ? 293
              : 0,
        }}
      >
        <div
          className="w-100"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {loader && <Loader />}
        </div>
        {!loader && (
          <Nav
            content={navContent}
            justified
            className="mobilenavbarContentMenu"
            appearance={"default"}
          >
            {(activeTab) => {
              return (
                <div
                  className="extraDetailsContent"
                  style={{
                    border: 0,
                    paddingInlineStart: activeTab !== 4 ? 12 : 0,
                  }}
                >
                  <ScrollBar>
                    {activeTab !== 4 && (
                      <div
                        className="extraDetailsFirstTab"
                        style={{
                          flexDirection: "column",
                        }}
                      >
                        <div
                          className="extraDetailsFirstTabFirstCol"
                          style={{ width: "100%" }}
                        >
                          {(activeTab === 0 || activeTab === undefined) && (
                            <>
                              <div
                                className="dividedRow"
                                style={{ marginBlockEnd: 10 }}
                              >
                                <div style={{ width: "50%" }}>
                                  <p
                                    className="boldText"
                                    style={{ color: color.textSecondary }}
                                  >
                                    {strings.getString("loading_area")}
                                  </p>
                                  <ul className="areasList smallRegularText">
                                    {selectedTruck.loadpoint}
                                  </ul>
                                </div>
                                <div style={{ width: "50%" }}>
                                  <p
                                    className="boldText"
                                    style={{ color: color.textSecondary }}
                                  >
                                    {strings.getString("unloading_area")}
                                  </p>
                                  <ul className="areasList smallRegularText">
                                    {selectedTruck.unloadpoint}
                                  </ul>
                                </div>
                              </div>

                              <div
                                className="dividedRow"
                                style={{ marginBlockEnd: 10 }}
                              >
                                <div style={{ width: "50%" }}>
                                  <p
                                    className="boldText"
                                    style={{ color: color.textSecondary }}
                                  >
                                    {strings.getString("loading_type")}
                                  </p>
                                  <span className="smallRegularText">
                                    {selectedTruck.loadingtype
                                      ? selectedTruck.loadingtype
                                      : "-"}
                                  </span>
                                </div>
                                <div style={{ width: "50%" }}>
                                  <p
                                    className="boldText"
                                    style={{ color: color.textSecondary }}
                                  >
                                    {strings.getString("unloading_type")}
                                  </p>
                                  <span className="smallRegularText">
                                    {selectedTruck.unloadingtype
                                      ? selectedTruck.unloadingtype
                                      : "-"}
                                  </span>
                                </div>
                              </div>
                              <div
                                className="dividedRow"
                                style={{ marginBlockEnd: 10 }}
                              >
                                <div style={{ width: "50%" }}>
                                  <p
                                    className="boldText"
                                    style={{ color: color.textSecondary }}
                                  >
                                    {strings.getString("maxWeight")}
                                  </p>
                                  <span className="smallRegularText">
                                    {selectedTruck.weight
                                      ? selectedTruck.weight
                                      : "-"}
                                  </span>
                                </div>
                                <div style={{ width: "50%" }}>
                                  <p
                                    className="boldText"
                                    style={{ color: color.textSecondary }}
                                  >
                                    {strings.getString("volumemt")}
                                  </p>
                                  <span className="smallRegularText">
                                    {selectedTruck.volume
                                      ? selectedTruck.volume
                                      : "-"}
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                          {activeTab === 1 && (
                            <>
                              <div style={{ width: "100%" }}>
                                <p
                                  className="boldText"
                                  style={{ color: color.textSecondary }}
                                >
                                  {strings.getString("measurements")}
                                </p>
                                <div
                                  className="smallRegularText"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div style={{ marginInlineEnd: 10 }}>
                                    <span className="smallRegularText">
                                      {strings.getString("width") + ": "}
                                    </span>
                                    <span>
                                      {selectedTruck.vehiclewidth
                                        ? selectedTruck.vehiclewidth + "m"
                                        : "-"}
                                    </span>
                                  </div>
                                  <div style={{ marginInlineEnd: 10 }}>
                                    <span className="smallRegularText">
                                      {strings.getString("height") + ": "}
                                    </span>
                                    <span>
                                      {selectedTruck.vehicleheight
                                        ? selectedTruck.vehicleheight + "m"
                                        : "-"}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="smallRegularText">
                                      {strings.getString("length") + ": "}
                                    </span>
                                    <span>
                                      {selectedTruck.vehiclelength
                                        ? selectedTruck.vehiclelength + "m"
                                        : "-"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  marginBlockStart: 10,
                                }}
                              >
                                <p
                                  className="boldText"
                                  style={{ color: color.textSecondary }}
                                >
                                  {strings.getString("equipments")}:
                                </p>{" "}
                                <span className="smallRegularText">
                                  {selectedTruck.equipments}
                                </span>
                              </div>
                            </>
                          )}
                          {activeTab === 2 && (
                            <>
                              <div
                                style={{
                                  width: "100%",
                                  marginBlockStart: 10,
                                }}
                              >
                                <p
                                  className="boldText"
                                  style={{ color: color.textSecondary }}
                                >
                                  {strings.getString(
                                    "Does it Have Third Country Permission or Cemt"
                                  )}
                                </p>{" "}
                                <span className="smallRegularText">
                                  {selectedTruck.thirdcountrycertificate
                                    ? selectedTruck.thirdcountrycertificate ===
                                      true
                                      ? strings.getString("yes")
                                      : strings.getString("no")
                                    : "-"}
                                </span>
                              </div>
                              <div style={{ width: "100%" }}>
                                <p
                                  className="boldText"
                                  style={{ color: color.textSecondary }}
                                >
                                  {strings.getString("Countries")}:
                                </p>{" "}
                                <span className="smallRegularText">
                                  {selectedTruck.thirdcountryname
                                    ? selectedTruck.thirdcountryname
                                    : "-"}
                                </span>
                              </div>
                              <div style={{ width: "100%" }}>
                                <p
                                  className="boldText"
                                  style={{ color: color.textSecondary }}
                                >
                                  {strings.getString(
                                    "Transport Declaration Document Type"
                                  )}
                                  :{" "}
                                </p>
                                <span className="smallRegularText">
                                  {selectedTruck.trucktransitdeclarationtype
                                    ? selectedTruck.trucktransitdeclarationtype
                                    : "-"}
                                </span>
                              </div>{" "}
                              <div style={{ width: "100%" }}>
                                <p
                                  className="boldText"
                                  style={{ color: color.textSecondary }}
                                >
                                  {strings.getString(
                                    "Can Provide Second Driver"
                                  )}{" "}
                                </p>
                                <span className="smallRegularText">
                                  {selectedTruck.seconddrivercheckbox
                                    ? selectedTruck.seconddrivercheckbox ===
                                      true
                                      ? strings.getString("yes")
                                      : strings.getString("no")
                                    : "-"}
                                </span>
                              </div>
                            </>
                          )}
                          {activeTab === 3 && (
                            <>
                              <p
                                className="boldText"
                                style={{ color: color.textSecondary }}
                              >
                                {strings.getString("destination")}
                              </p>
                              {selectedTruck.RouteTab?.map((route, i) => (
                                <div
                                  key={i}
                                  className="smallRegularText"
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  <p>{route.route} - &nbsp; </p>
                                  <span>
                                    {Math.round(route.transittime) ?? 0} Days -
                                    &nbsp;
                                  </span>
                                  <span>
                                    {getCurrency(
                                      route.targetprice,
                                      route.currencyisocode
                                    )}
                                  </span>
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                        {activeTab === 1 && (
                          <div
                            className="extraDetailsFirstTabSecondCol"
                            style={{ width: "100%", marginTop: 20 }}
                          >
                            <p
                              className="boldText"
                              style={{ color: color.textSecondary }}
                            >
                              {strings.getString("language")}
                            </p>
                            <div
                              style={{
                                width: 70,
                                display: "flex",
                                justifyContent: "flex-start",
                                flexWrap: "wrap",
                                gap: "4",
                              }}
                            >
                              {selectedTruck?.Owner?.flags
                                ?.split(";")
                                .map((x) => {
                                  return (
                                    <Flag
                                      style={{ marginRight: 3 }}
                                      country={x === "EN" ? "US" : x}
                                      title={x}
                                      className="flag"
                                    />
                                  );
                                })}
                            </div>
                            <p
                              className="boldText"
                              style={{
                                color: color.textSecondary,
                                marginBlockEnd: 0,
                                marginBlockStart: 5,
                              }}
                            >
                              {strings.getString("contact_details")}
                            </p>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Icon
                                icon="mail"
                                size={20}
                                style={{ marginInlineEnd: 5 }}
                              />
                              <span
                                className="boldText"
                                style={{
                                  textDecoration: "underline",
                                  fontWeight: 600,
                                }}
                              >
                                {selectedTruck.Owner?.email}
                              </span>
                            </div>
                            <div style={{ display: "flex" }}>
                              <Icon
                                icon="mobile"
                                size={20}
                                style={{ marginInlineEnd: 5 }}
                              />
                              <span
                                className="boldText"
                                style={{ fontWeight: 600 }}
                              >
                                {selectedTruck.Owner?.mobilephone
                                  ? selectedTruck.Owner?.mobilephone
                                  : "-"}
                              </span>
                            </div>
                            <div style={{ display: "flex" }}>
                              <Icon
                                icon="phone"
                                color={color.black}
                                size={15}
                                style={{
                                  marginInlineEnd: 7,
                                  marginInlineStart: 3,
                                }}
                              />
                              <span
                                className="boldText"
                                style={{ fontWeight: 600 }}
                              >
                                {selectedTruck.Owner?.phone
                                  ? selectedTruck.Owner.phone
                                  : "-"}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {activeTab === 4 && (
                      <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
                        <GoogleMap
                          id="rapidMap"
                          options={{
                            //@ts-ignore
                            styles: CUSTOM_MAP,
                            disableDefaultUI: true,
                            zoomControl: true,
                          }}
                          mapContainerStyle={mapContainerStyles}
                          center={{ lat: 38.4539188, lng: 27.1761196 }}
                          zoom={15}
                        ></GoogleMap>
                      </LoadScript>
                    )}
                  </ScrollBar>
                </div>
              );
            }}
          </Nav>
        )}
      </div>
    </div>
  );
};

export default TruckCardMobil;
