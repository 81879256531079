import { useRef, useState } from "react";
import { Popover, Whisper } from "rsuite";
import { useDispatch, useSelector } from "react-redux";
import { Button, Divider, Icon, Loader, Nav } from "..";
import {
  getLocalizedMyProfile,
  getLocalizedTruckCard,
} from "../../redux/selectors";
import {
  color,
  GOOGLE_MAPS_API_KEY,
  mapContainerStyles,
  CUSTOM_MAP,
} from "../../utils/Properties";
import moment from "moment";
import "./style.css";
import { VehicleOpportunityDto } from "../../redux/opportunity/type";
import Flag from "react-flagkit";
import { getCurrency } from "../../utils/Helpers";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import ScrollBar from "react-perfect-scrollbar";
import { getVehicleDetailWithoutLoader } from "../../redux/actions";
type TruckCardProps = {
  truck: VehicleOpportunityDto;
  cancelOpportunity?: () => void;
  isOffer?: boolean;
  useEp?: boolean;
};

const dividerPadding: number = 6;

const ProfileTruckCardMobil = ({
  truck,
  isOffer = true,
  cancelOpportunity,
  useEp,
}: TruckCardProps) => {
  const [loader, setLoader] = useState(false);
  const printerRef = useRef(null);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(truck);
  const strings = useSelector(getLocalizedTruckCard);
  // eslint-disable-next-line
  const [show, setShow] = useState(false);
  const profilestrings = useSelector(getLocalizedMyProfile);
  const navContent = [
    {
      id: 0,
      icon: "list",
      activeColor: color.primary,
      label: "",
    },
    {
      id: 1,
      icon: "indicator",
      activeColor: color.primary,
      label: "",
    },
    {
      id: 2,
      icon: "shorter-list",
      activeColor: color.primary,
      label: "",
    },
    {
      id: 3,
      icon: "route",
      activeColor: color.primary,
      label: "",
    },
    {
      id: 4,
      icon: "map-pin",
      activeColor: color.primary,
      label: "",
    },
  ];
  return (
    <div
      ref={printerRef}
      className="verticalTruckCard"
      style={{
        boxShadow: "none",
        height: show
          ? "auto"
          : !isOffer && cancelOpportunity && !selected.status__c
          ? 440
          : 400,
        border: "1px solid",
        borderColor: "#e6e6e6",
      }}
    >
      {loader && <Loader />}
      <div
        className="smallRegularText"
        style={{
          width: "100%",
          paddingInline: 15,
          marginBlock: 10,
        }}
      >
        <div
          style={{
            width: "100%",
            height: "max-content",
            display: "flex",
            marginBlockEnd: 8,
          }}
        >
          <div
            className="vehicleLogo"
            style={{
              backgroundColor: color.accent,
              borderRadius: 10,
              marginInlineStart: -5,
              marginBlockStart: -5,
            }}
          >
            <img
              className="imageStyles"
              style={{ padding: 5, borderRadius: 12 }}
              src={selected.profileimage}
              alt=""
            />
          </div>
          <div
            style={{
              width: `calc(100% - 65px)`,
              height: 65,
              display: "flex",
              flexDirection: "column",
              gap: 10,
              paddingInlineStart: 5,
            }}
          >
            <p className="mediumHeadingStyle" style={{ lineHeight: "23px" }}>
              {selected.rapidadvisorname}
            </p>
            <div
              className="smallText"
              style={{ display: "flex", alignItems: "center" }}
            >
              {strings.getString("post_end_date")}:{" "}
              <span className="smallText">
                {selected.lastofferdate
                  ? moment(selected.lastofferdate).format("l")
                  : ""}
              </span>
            </div>
          </div>
        </div>
        <Divider paddingBlock={dividerPadding} />
        <div style={{ display: "flex", paddingInline: 3 }}>
          <div style={{}}>
            <span className="smallBoldText" style={{ opacity: 0.5 }}>
              {strings.getString("file_number")}
            </span>
            <p>{"#" + selected.filenumber}</p>
          </div>
        </div>{" "}
        <Divider paddingBlock={dividerPadding} />
        <div style={{ display: "flex", paddingInline: 3 }}>
          <div>
            <p className="boldText " style={{ color: color.blackFifty }}>
              {strings.getString("date")}
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingBlockStart: 8,
              }}
            >
              <p>
                {selected.firstavailabilitydate
                  ? moment(selected.firstavailabilitydate).format("l")
                  : "-"}
              </p>
              <Icon icon="right-bold" size={13} style={{ marginInline: 3 }} />
              <br />
              <p>
                {selected.lastavailabilitydate
                  ? moment(selected.lastavailabilitydate).format("l")
                  : "-"}
              </p>
            </div>
          </div>
        </div>{" "}
        <Divider paddingBlock={dividerPadding} />
        <div
          style={{
            paddingInline: 3,
          }}
        >
          <span className="smallBoldText" style={{ opacity: 0.5 }}>
            {strings.getString("truck_type")}
          </span>
          <p>{selected.trucktypename}</p>
        </div>
        <Divider paddingBlock={dividerPadding} />
        <div
          style={{
            paddingInline: 3,
          }}
        >
          <p className="smallBoldText" style={{ opacity: 0.5 }}>
            {strings.getString("route")}
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p className="">
              {selected.loadcountry ? selected.loadcountry : "-"}
            </p>
            <Icon
              icon="right-bold"
              color={color.textSecondary}
              size={13}
              style={{ marginInline: 5 }}
            />
            <p className="">{selected.tocountry ? selected.tocountry : "-"}</p>
          </div>
        </div>
        {!isOffer && cancelOpportunity && !selected.status__c && (
          <div
            style={{
              marginBlock: 10,
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              style={{
                borderColor: color.error,
                backgroundColor: color.error,
              }}
              title={profilestrings.getString("Cancel Opportunity")}
              onClick={() => {
                cancelOpportunity();
              }}
            />
          </div>
        )}
        <div className="boldText" style={{ display: "flex", marginBlock: 7 }}>
          <Icon icon="clock" size={17} style={{ marginInlineEnd: 5 }} />
          <p style={{ marginInlineEnd: 5, fontWeight: 600 }}>
            {strings.getString("estimates")}
          </p>
          <span style={{ marginInlineEnd: 30 }}>
            {selected.estimatedarrivaltime
              ? Math.round(selected.estimatedarrivaltime)
              : "-"}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            height: 44,
            width: "100%",
            borderRadius: 5,
            cursor: "pointer",
            backgroundColor: color.accent,
          }}
        >
          <Whisper
            delay={500}
            placement="top"
            trigger="hover"
            speaker={
              <Popover>
                <p>{strings.getString("details")}</p>
              </Popover>
            }
            enterable
          >
            <div
              className="extraInfosButton"
              style={{
                border: "1px solid",
                borderColor: color.borderSecondary,
                borderStartStartRadius: 5,
                borderEndStartRadius: 5,
                width: "33%",
              }}
              onClick={() => {
                if (!useEp) {
                  setShow(!show);
                } else if (show === true) {
                  setShow(!show);
                } else {
                  setLoader(true);
                  dispatch(
                    getVehicleDetailWithoutLoader({
                      payload: {
                        onSuccess: (message, payload) => {
                          payload && setSelected(payload);
                          setLoader(false);
                          setShow(!show);
                        },
                        onError: (message) => {
                          setLoader(false);
                        },
                      },
                      url: `VehicleOpportunity/GetByVehicleOpportunities?sfid=${selected.destination_vehicletype_vehicleopp__c}`,
                    })
                  );
                }
              }}
            >
              <Icon icon="more" />
            </div>
          </Whisper>
        </div>
      </div>
      {show && <Divider paddingBlock={"6px 0px"} />}

      <div
        className="extraDetails  mobil-extraDetailsContent"
        style={{
          width: 223,
          paddingInline: 0,
          display: show ? "block" : "none",
          height: show ? 293 : 0,
        }}
      >
        <Nav
          content={navContent}
          justified
          className="mobilenavbarContentMenu"
          appearance={"default"}
        >
          {(activeTab) => {
            return (
              <div
                className="extraDetailsContent"
                style={{
                  border: 0,
                  paddingInlineStart: activeTab !== 4 ? 12 : 0,
                }}
              >
                <ScrollBar>
                  {activeTab !== 4 && (
                    <div
                      className="extraDetailsFirstTab"
                      style={{
                        flexDirection: "column",
                      }}
                    >
                      <div
                        className="extraDetailsFirstTabFirstCol"
                        style={{ width: "100%" }}
                      >
                        {(activeTab === 0 || activeTab === undefined) && (
                          <>
                            <div
                              className="dividedRow"
                              style={{ marginBlockEnd: 10 }}
                            >
                              <div style={{ width: "50%" }}>
                                <p
                                  className="boldText"
                                  style={{ color: color.textSecondary }}
                                >
                                  {strings.getString("loading_area")}
                                </p>
                                <ul className="areasList smallRegularText">
                                  {selected.loadpoint}
                                </ul>
                              </div>
                              <div style={{ width: "50%" }}>
                                <p
                                  className="boldText"
                                  style={{ color: color.textSecondary }}
                                >
                                  {strings.getString("unloading_area")}
                                </p>
                                <ul className="areasList smallRegularText">
                                  {selected.unloadpoint}
                                </ul>
                              </div>
                            </div>

                            <div
                              className="dividedRow"
                              style={{ marginBlockEnd: 10 }}
                            >
                              <div style={{ width: "50%" }}>
                                <p
                                  className="boldText"
                                  style={{ color: color.textSecondary }}
                                >
                                  {strings.getString("loading_type")}
                                </p>
                                <span className="smallRegularText">
                                  {selected.loadingtype
                                    ? selected.loadingtype
                                    : "-"}
                                </span>
                              </div>
                              <div style={{ width: "50%" }}>
                                <p
                                  className="boldText"
                                  style={{ color: color.textSecondary }}
                                >
                                  {strings.getString("unloading_type")}
                                </p>
                                <span className="smallRegularText">
                                  {selected.unloadingtype
                                    ? selected.unloadingtype
                                    : "-"}
                                </span>
                              </div>
                            </div>
                            <div
                              className="dividedRow"
                              style={{ marginBlockEnd: 10 }}
                            >
                              <div style={{ width: "50%" }}>
                                <p
                                  className="boldText"
                                  style={{ color: color.textSecondary }}
                                >
                                  {strings.getString("maxWeight")}
                                </p>
                                <span className="smallRegularText">
                                  {selected.weight ? selected.weight : "-"}
                                </span>
                              </div>
                              <div style={{ width: "50%" }}>
                                <p
                                  className="boldText"
                                  style={{ color: color.textSecondary }}
                                >
                                  {strings.getString("volumemt")}
                                </p>
                                <span className="smallRegularText">
                                  {selected.volume ? selected.volume : "-"}
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                        {activeTab === 1 && (
                          <>
                            <div style={{ width: "100%" }}>
                              <p
                                className="boldText"
                                style={{ color: color.textSecondary }}
                              >
                                {strings.getString("measurements")}
                              </p>
                              <div
                                className="smallRegularText"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div style={{ marginInlineEnd: 10 }}>
                                  <span className="smallRegularText">
                                    {strings.getString("width") + ": "}
                                  </span>
                                  <span>
                                    {selected.vehiclewidth
                                      ? selected.vehiclewidth + "m"
                                      : "-"}
                                  </span>
                                </div>
                                <div style={{ marginInlineEnd: 10 }}>
                                  <span className="smallRegularText">
                                    {strings.getString("height") + ": "}
                                  </span>
                                  <span>
                                    {selected.vehicleheight
                                      ? selected.vehicleheight + "m"
                                      : "-"}
                                  </span>
                                </div>
                                <div>
                                  <span className="smallRegularText">
                                    {strings.getString("length") + ": "}
                                  </span>
                                  <span>
                                    {selected.vehiclelength
                                      ? selected.vehiclelength + "m"
                                      : "-"}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                marginBlockStart: 10,
                              }}
                            >
                              <p
                                className="boldText"
                                style={{ color: color.textSecondary }}
                              >
                                {strings.getString("equipments")}:
                              </p>{" "}
                              <span className="smallRegularText">
                                {selected.equipments}
                              </span>
                            </div>
                          </>
                        )}
                        {activeTab === 2 && (
                          <>
                            <div
                              style={{
                                width: "100%",
                                marginBlockStart: 10,
                              }}
                            >
                              <p
                                className="boldText"
                                style={{ color: color.textSecondary }}
                              >
                                {strings.getString(
                                  "Does it Have Third Country Permission or Cemt"
                                )}
                              </p>{" "}
                              <span className="smallRegularText">
                                {selected.thirdcountrycertificate
                                  ? selected.thirdcountrycertificate === true
                                    ? strings.getString("yes")
                                    : strings.getString("no")
                                  : "-"}
                              </span>
                            </div>
                            <div style={{ width: "100%" }}>
                              <p
                                className="boldText"
                                style={{ color: color.textSecondary }}
                              >
                                {strings.getString("Countries")}:
                              </p>{" "}
                              <span className="smallRegularText">
                                {selected.thirdcountryname
                                  ? selected.thirdcountryname
                                  : "-"}
                              </span>
                            </div>
                            <div style={{ width: "100%" }}>
                              <p
                                className="boldText"
                                style={{ color: color.textSecondary }}
                              >
                                {strings.getString(
                                  "Transport Declaration Document Type"
                                )}
                                :{" "}
                              </p>
                              <span className="smallRegularText">
                                {selected.trucktransitdeclarationtype
                                  ? selected.trucktransitdeclarationtype
                                  : "-"}
                              </span>
                            </div>{" "}
                            <div style={{ width: "100%" }}>
                              <p
                                className="boldText"
                                style={{ color: color.textSecondary }}
                              >
                                {strings.getString("Can Provide Second Driver")}{" "}
                              </p>
                              <span className="smallRegularText">
                                {selected.seconddrivercheckbox
                                  ? selected.seconddrivercheckbox === true
                                    ? strings.getString("yes")
                                    : strings.getString("no")
                                  : "-"}
                              </span>
                            </div>
                          </>
                        )}
                        {activeTab === 3 && (
                          <>
                            <p
                              className="boldText"
                              style={{ color: color.textSecondary }}
                            >
                              {strings.getString("destination")}
                            </p>
                            {selected.RouteTab?.map((route, i) => (
                              <div
                                key={i}
                                className="smallRegularText"
                                style={{
                                  display: "flex",
                                }}
                              >
                                <p>{route.route} - &nbsp; </p>
                                <span>
                                  {Math.round(route.transittime) ?? 0} Days -
                                  &nbsp;
                                </span>
                                <span>
                                  {getCurrency(
                                    route.targetprice,
                                    route.currencyisocode
                                  )}
                                </span>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                      {activeTab === 1 && (
                        <div
                          className="extraDetailsFirstTabSecondCol"
                          style={{ width: "100%", marginTop: 20 }}
                        >
                          <p
                            className="boldText"
                            style={{ color: color.textSecondary }}
                          >
                            {strings.getString("language")}
                          </p>
                          <div
                            style={{
                              width: 70,
                              display: "flex",
                              justifyContent: "flex-start",
                              flexWrap: "wrap",
                              gap: "4",
                            }}
                          >
                            {selected?.Owner?.flags?.split(";").map((x) => {
                              return (
                                <Flag
                                  style={{ marginRight: 3 }}
                                  country={x === "EN" ? "US" : x}
                                  title={x}
                                  className="flag"
                                />
                              );
                            })}
                          </div>
                          <p
                            className="boldText"
                            style={{
                              color: color.textSecondary,
                              marginBlockEnd: 0,
                              marginBlockStart: 5,
                            }}
                          >
                            {strings.getString("contact_details")}
                          </p>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Icon
                              icon="mail"
                              size={20}
                              style={{ marginInlineEnd: 5 }}
                            />
                            <span
                              className="boldText"
                              style={{
                                textDecoration: "underline",
                                fontWeight: 600,
                              }}
                            >
                              {selected.Owner?.email}
                            </span>
                          </div>
                          <div style={{ display: "flex" }}>
                            <Icon
                              icon="mobile"
                              size={20}
                              style={{ marginInlineEnd: 5 }}
                            />
                            <span
                              className="boldText"
                              style={{ fontWeight: 600 }}
                            >
                              {selected.Owner?.mobilephone
                                ? selected.Owner?.mobilephone
                                : "-"}
                            </span>
                          </div>
                          <div style={{ display: "flex" }}>
                            <Icon
                              icon="phone"
                              color={color.black}
                              size={15}
                              style={{
                                marginInlineEnd: 7,
                                marginInlineStart: 3,
                              }}
                            />
                            <span
                              className="boldText"
                              style={{ fontWeight: 600 }}
                            >
                              {selected.Owner?.phone
                                ? selected.Owner.phone
                                : "-"}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {activeTab === 4 && (
                    <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
                      <GoogleMap
                        id="rapidMap"
                        options={{
                          //@ts-ignore
                          styles: CUSTOM_MAP,
                          disableDefaultUI: true,
                          zoomControl: true,
                        }}
                        mapContainerStyle={mapContainerStyles}
                        center={{ lat: 38.4539188, lng: 27.1761196 }}
                        zoom={15}
                      ></GoogleMap>
                    </LoadScript>
                  )}
                </ScrollBar>
              </div>
            );
          }}
        </Nav>
      </div>
    </div>
  );
};

export default ProfileTruckCardMobil;
