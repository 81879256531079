import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { icons } from "../../../../../assets";
import {
  getLocalizedHomepage,
  languageSelector,
} from "../../../../../redux/selectors";
import { color } from "../../../../../utils/Properties";
import withDirection, {
  withDirectionPropTypes,
  DIRECTIONS,
  //@ts-ignore
} from "react-with-direction";
import "./style.css";
import {
  getHomepageArticle,
  getHomepageLoadArticle,
  getHomepageTrucksArticle,
} from "../../../../../redux/actions";
import { ArticleObject } from "../../../../../types/homepage";
import { Loader } from "../../../../../components";
import { useHistory } from "react-router-dom";

const AboutUs = ({ direction }: withDirectionPropTypes) => {
  const dispatch = useDispatch();
  const language = useSelector(languageSelector);
  const strings = useSelector(getLocalizedHomepage);
  const [article, setArticle] = useState<ArticleObject>();
  const [trucks, setTrucks] = useState<ArticleObject>();
  const [loads, setLoads] = useState<ArticleObject>();
  const [loader, setLoader] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setLoader(true);
    dispatch(
      getHomepageLoadArticle({
        payload: {
          onSuccess: (message, payload) => {
            if (payload) {
              setLoads(payload[0]);
            }
          },
          onError: (message) => {},
        },
        url: "HomePage/GetArticle",
        id: "loads",
      })
    );
    dispatch(
      getHomepageArticle({
        payload: {
          onSuccess: (message, payload) => {
            if (payload) {
              setArticle(payload[0]);
            }
          },
          onError: (message) => {},
        },
        url: "HomePage/GetArticle",
        id: "whoweare",
      })
    );
    dispatch(
      getHomepageTrucksArticle({
        payload: {
          onSuccess: (message, payload) => {
            if (payload) {
              setTrucks(payload[0]);
            }
          },
          onError: (message) => {},
        },
        url: "HomePage/GetArticle",
        id: "trucks",
      })
    );
    setLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, language]);

  return (
    <div className="aboutUs" id="history">
      {loader ? <Loader /> : null}
      <h3
        className="bigHeadingStyle"
        style={{
          marginBlock: "80px 44px",
        }}
      >
        {article && (
          <>
            <p
              className="aboutUsArticle"
              dangerouslySetInnerHTML={{
                __html: article ? article.title : "",
              }}
            />
          </>
        )}
      </h3>
      <div
        className="homepageaboutusArticle"
        style={{
          height: "auto",
          maxWidth: "55%",
          color: color.textPrimary,
          marginBlockEnd: 80,
          fontSize: 16,
          fontWeight: 400,
        }}
      >
        {article && (
          <>
            <span
              dangerouslySetInnerHTML={{
                __html: article
                  ? showMore
                    ? article.description
                    : article.description.slice(0, 390)
                  : "",
              }}
            />
            {article.description.length > 390 && (
              <div
                style={{
                  color: color.primary,
                  textAlign: "center",
                  width: "100%",
                  cursor: "pointer",
                }}
                onClick={() => setShowMore(!showMore)}
              >
                {showMore
                  ? strings.getString("Show Less")
                  : strings.getString("Show More")}
              </div>
            )}
          </>
        )}
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <div
          className="exploreCard"
          style={{
            marginBlockEnd: 80,
            transition: "0.3s",
            backgroundColor: "#FFF4EC",
          }}
        >
          <div
            style={{
              width: 167,
              height: 167,
              borderRadius: 84,
              backgroundColor: color.accent,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={icons.truck3d}
              alt=""
              style={{
                transform:
                  direction === DIRECTIONS.LTR
                    ? "rotateY(0)"
                    : "rotateY(180deg)",
              }}
            />
          </div>
          <p
            className="exploreCardTitle"
            style={{
              fontSize: 24,
              fontWeight: 700,
              color: color.textHeader,
              lineHeight: "30px",
              marginBlockStart: 30,
            }}
          >
            {trucks && trucks.title}
          </p>
          <p
            className="exploreCardDesc"
            style={{
              fontSize: 18,
              lineHeight: "32px",
              color: color.textPrimary,
              paddingBlockStart: 15,
              height: 150,
              overflow: "hidden",
            }}
            dangerouslySetInnerHTML={{
              __html: trucks ? trucks.description.slice(0, 95) : "",
            }}
          />
          <p
            className="exploreCardButton"
            style={{
              color: color.primary,
              fontWeight: 600,
              fontSize: 16,
              textDecorationLine: "underline",
            }}
            onClick={() => history.push("/search-trucks?page=1&take=10")}
          >
            {strings.getString("explore")}
          </p>
        </div>
        <div
          className="exploreCard"
          style={{
            transition: "0.3s",
            backgroundColor: "#FFF0F4",
          }}
        >
          <div
            style={{
              width: 167,
              height: 167,
              borderRadius: 100,
              backgroundColor: color.accent,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={icons.box3d}
              alt=""
              style={{
                transform:
                  direction === DIRECTIONS.LTR
                    ? "rotateY(0)"
                    : "rotateY(180deg)",
              }}
            />
          </div>
          <p
            className="exploreCardTitle"
            style={{
              fontSize: 24,
              fontWeight: 700,
              color: color.textHeader,
              lineHeight: "30px",
              marginBlockStart: 30,
            }}
          >
            {loads && loads.title}
          </p>
          <p
            className="exploreCardDesc"
            style={{
              fontSize: 18,
              lineHeight: "32px",
              color: color.textPrimary,
              paddingBlockStart: 15,
              height: 150,
              overflow: "hidden",
            }}
            dangerouslySetInnerHTML={{
              __html: loads ? loads.description.slice(0, 91) : "",
            }}
          />
          <p
            className="exploreCardButton"
            style={{
              color: color.primary,
              fontWeight: 600,
              fontSize: 16,
              textDecorationLine: "underline",
            }}
            onClick={() => history.push("/search-loads?page=1&take=10")}
          >
            {strings.getString("explore")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default withDirection(AboutUs);
