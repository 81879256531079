import { useDispatch, useSelector } from "react-redux";
import { Formik, FormikProps } from "formik";
import { getLocalizedSidebarFilter } from "../../redux/selectors";
import { Input, DatePicker, Button, Divider } from "..";
import { InputType } from "../Input";
import "./style.scss";
import {
  Checkbox,
  CheckboxGroup,
  Dropdown,
  Form,
  Sidenav,
  TagPicker,
} from "rsuite";
import { LoadingTypes, TransportTypes, TruckTypes } from "../../types";
import { useEffect, useRef, useState } from "react";
import {
  getLoadingTypes,
  getLocations,
  getTransportTypes,
  getTruckTypes,
} from "../../redux/actions";
import { ValueType } from "rsuite/lib/DateRangePicker";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { color } from "../../utils/Properties";
import Whisper from "../Whisper";
import AutoCompleteRs from "../AutoCompleteRs";

type FilterProps = {
  setFilterQuery?: (t: string) => void;
  isTruck?: boolean;
  filterQuery?: string;
  setPage?: (t: number) => void;
}; // eslint-disable-next-line
const initialValues = {
  departure: "",
  destination: "",
  start: null,
  time: 0,
  transportType: [],
  loadingTypes: [],
  unloadingTypes: [],
  truckType: [],
  ton: { min: 0, max: 100 },
  volume: { min: 0, max: 10000 },
  carNumber: { min: 0, max: 100 },
  details: [],
  price: 0,
};
const SidebarFilter = ({
  setFilterQuery,
  setPage,
  filterQuery,
  isTruck = false,
}: FilterProps) => {
  const obj = new URLSearchParams(filterQuery);
  console.log("filterQuery", filterQuery);
  const departureVal = obj.get("departure");
  const departureName = obj.get("departureName");

  const destinationVal = obj.get("destination");
  const destinationName = obj.get("destinationName");

  const start = obj.get("startdate");
  const time = obj.get("time");
  const transportType = obj.get("transportType");
  const transportTypeArray =
    transportType !== null ? transportType.split(",") : [];

  const loadingType = obj.get("loadingTypes");
  const loadingTypeArray = loadingType !== null ? loadingType.split(",") : [];

  const unloadingType = obj.get("unloadingTypes");
  const unloadingTypeArray =
    unloadingType !== null ? unloadingType.split(",") : [];

  const truckType = obj.get("truckType");
  const truckTypeArray = truckType !== null ? truckType.split(",") : [];

  const tonmin = obj.get("tonMin");
  const tonmax = obj.get("tonMax");
  const volumemin = obj.get("volumeMin");
  const volumemax = obj.get("volumeMax");

  const carNumbermin = obj.get("carNumberMin");
  const carNumbermax = obj.get("carNumberMax");
  const price = obj.get("price");
  const details = obj.get("details");
  const detailsArray = details !== null ? details.split(",") : [];

  const location = useLocation();
  const dispatch = useDispatch();
  const formikRef = useRef<FormikProps<any>>(null);
  const [transportTypes, setTransportTypes] = useState<TransportTypes[]>();
  const [loadingTypes, setLoadingTypes] = useState<LoadingTypes[]>();
  const [truckTypes, setTruckTypes] = useState<TruckTypes[]>();
  const [destinationValue, setDestinationValue] = useState<string | undefined>(
    destinationName !== null ? destinationName : undefined
  );
  const [departureValue, setDepartureValue] = useState<string | undefined>(
    departureName !== null ? departureName : undefined
  );
  const strings = useSelector(getLocalizedSidebarFilter);
  // eslint-disable-next-line
  const getLocationById = (sfid: string, type: "departure" | "destination") =>
    dispatch(
      getLocations({
        payload: {
          onSuccess: (message, payload) => {
            console.log("GetLocationById", type);
            switch (type) {
              case "departure":
                const depValue = payload?.find((d) => d.sfid === sfid);
                setDepartureValue(depValue?.name);
                console.log("DepartureValue", departureValue);
                break;
              case "destination":
                const destValue = payload?.find((d) => d.sfid === sfid);
                setDestinationValue(destValue?.name);

                break;

              default:
                break;
            }
          },
          onError: (message) => {
            console.log("ERROR", type, message);
          },
        },
        url: `HomePage/GetLocationById?Sfid=${sfid}`,
      })
    );

  useEffect(() => {
    dispatch(
      getTruckTypes({
        payload: {
          onSuccess: (message, payload) => setTruckTypes(payload),
          onError: (message) => {},
        },
        url: "System/GetTruckType",
      })
    );
    dispatch(
      getTransportTypes({
        payload: {
          onSuccess: (message, payload) => setTransportTypes(payload),
          onError: (message) => {},
        },
        url: "System/GetTransportTypes",
      })
    );
    dispatch(
      getLoadingTypes({
        payload: {
          onSuccess: (message, payload) => setLoadingTypes(payload),
          onError: (message) => {},
        },
        url: "System/GetLoadingTypes",
      })
    );
    let time = "30";
    // eslint-disable-next-line
    const array = new URLSearchParams(location.search);
    const startDate = array.get("start");
    startDate && (time = "30");
    // eslint-disable-next-line
    const query = `departure=${array.get("departure") ?? ""}&destination=${
      array.get("destination") ?? ""
    }&start=${array.get("start") ?? ""}&time=${time}&tonMax=${
      array.get("tonMax") ?? ""
    }`;

    // eslint-disable-next-line
  }, []);
  console.log("start", start);
  const detailsCheckboxList: { label: string; value: string }[] = [
    { label: strings.getString("adr"), value: "adr" },
    { label: strings.getString("frigo"), value: "frigo" },
  ];

  const onSubmitPress = ({
    departure,
    destination,
    start,
    time,
    transportType,
    loadingTypes,
    unloadingTypes,
    truckType,
    ton,
    volume,
    carNumber,
    details,
    price,
  }: {
    departure: string;
    destination: string;
    start: Date | null;
    time: number;
    transportType: string[];
    loadingTypes: string[];
    unloadingTypes: string[];
    truckType: string[];
    ton: { min: number; max: number };
    volume: { min: number; max: number };
    carNumber: { min: number; max: number };
    details: string[];
    price: number;
  }) => {
    const startDate = start ? moment(start).format("L") : "";
    const query = isTruck
      ? `startdate=${moment(start).format()}&departureName=${
          departureValue ? departureValue : ""
        }&destinationName=${
          destinationValue ? destinationValue : ""
        }&departure=${departure ? departure : ""}&destination=${
          destination ? destination : ""
        }&start=${startDate}&time=${time ? time : ""}&truckType=${
          truckType.length > 0 ? truckType : ""
        }&tonMin=${ton.min ? ton.min : ""}&loadingTypes=${
          loadingTypes.length > 0 ? loadingTypes : ""
        }&unloadingTypes=${
          unloadingTypes.length > 0 ? unloadingTypes : ""
        }&tonMin=${ton.min ? ton.min : ""}&tonMax=${
          ton.max ? ton.max : ""
        }&volumeMin=${volume.min ? volume.min : ""}&volumeMax=${
          volume.max ? volume.max : ""
        }
    `
      : `startdate=${moment(start).format()}&departureName=${
          departureValue ? departureValue : ""
        }&destinationName=${
          destinationValue ? destinationValue : ""
        }&departure=${departure ? departure : ""}&destination=${
          destination ? destination : ""
        }&start=${startDate}&time=${time ? time : ""}&transportType=${
          transportType.length > 0 ? transportType : ""
        }&loadingTypes=${
          loadingTypes.length > 0 ? loadingTypes : ""
        }&unloadingTypes=${
          unloadingTypes.length > 0 ? unloadingTypes : ""
        }&truckType=${truckType.length > 0 ? truckType : ""}&tonMin=${
          ton.min ? ton.min : ""
        }&tonMax=${ton.max ? ton.max : ""}&volumeMin=${
          volume.min ? volume.min : ""
        }&volumeMax=${volume.max ? volume.max : ""}&carNumberMin=${
          carNumber.min ? carNumber.min : ""
        }&carNumberMax=${carNumber.max ? carNumber.max : ""}&details=${
          details.length ? details : ""
        }&price=${price ? price : ""}
    `;
    typeof setFilterQuery === "function" && setFilterQuery(query);
    typeof setPage === "function" && setPage(1);
  };
  const element = document.getElementById("scrollheight");
  element?.addEventListener("scroll", function (e) {});

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={{
          departure: departureVal !== null ? departureVal : "",
          destination: destinationVal !== null ? destinationVal : "",
          start: start ? new Date(start.replace(" ", "+")) : null,
          time: time ? parseInt(time) : 30,
          transportType: transportTypeArray,
          loadingTypes: loadingTypeArray,
          unloadingTypes: unloadingTypeArray,
          truckType: truckTypeArray,
          ton: {
            min: tonmin ? parseInt(tonmin) : 0,
            max: tonmax ? parseInt(tonmax) : 0,
          },
          volume: {
            min: volumemin ? parseInt(volumemin) : 0,
            max: volumemax ? parseInt(volumemax) : 0,
          },
          carNumber: {
            min: carNumbermin ? parseInt(carNumbermin) : 0,
            max: carNumbermax ? parseInt(carNumbermax) : 0,
          },
          details: detailsArray,
          price: price ? parseInt(price) : 0,
        }}
        onSubmit={onSubmitPress}
      >
        {({
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
          values,
          resetForm,
        }) => {
          return (
            <Form className="exampleDiv">
              <div style={{ width: "100%", height: `calc(100% - 58px)` }}>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    paddingInlineEnd: 15,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <p className="headingStyle">
                      {strings.getString("route_details")}
                    </p>
                    <Button
                      style={{
                        width: 80,
                        paddingBlock: 5,
                        height: 30,
                        color: color.error,
                        borderColor: color.error,
                        backgroundColor: "transparent",
                      }}
                      type="submit"
                      title={strings.getString("clear_filter")}
                      onClick={() => {
                        setDestinationValue(undefined);
                        setDepartureValue(undefined);
                        resetForm();
                        typeof setFilterQuery === "function" &&
                          setFilterQuery("");
                      }}
                    />
                  </div>
                  <label
                    placeholder={strings.getString("departure_placeholder")}
                  >
                    {strings.getString("departure")}
                  </label>
                  <AutoCompleteRs
                    action={getLocations}
                    url="HomePage/GetLocations"
                    onselectitem={(value) => {
                      setFieldValue("departure", value);
                    }}
                    style={{ marginBlock: 5 }}
                    placeholder={strings.getString("departure_placeholder")}
                  />

                  <label>{strings.getString("destination")}</label>
                  <AutoCompleteRs
                    action={getLocations}
                    url="HomePage/GetLocations"
                    onselectitem={(value) => {
                      setFieldValue("destination", value);
                    }}
                    placeholder={strings.getString("destination_placeholder")}
                    style={{ marginBlock: 5 }}
                  />

                  <div className="flexDiv">
                    <div style={{ width: "45%" }}>
                      <DatePicker
                        label={strings.getString("starting_from")}
                        name="start"
                        value={values.start || undefined}
                        onChange={(e) => {
                          console.log("Tarih", e);
                          setFieldValue("start", e);
                        }}
                        onBlur={handleBlur("start")}
                      />
                    </div>
                    {console.log("time", time, values.time)}
                    <div style={{ width: "45%" }}>
                      <Input
                        inputType={InputType.number}
                        label={strings.getString("lasting")}
                        name="time"
                        //@ts-ignore
                        value={values.time}
                        onChange={handleChange("time")}
                        onBlur={handleBlur("time")}
                      />
                    </div>
                  </div>
                  <Divider />
                  {!isTruck && (
                    <>
                      <p className="headingStyle">
                        {strings.getString("transport_type")}
                      </p>
                      <TagPicker
                        placement="bottomStart"
                        className="1aed  filterTagPicker "
                        placeholder={strings.getString("transport_type")}
                        data={transportTypes ? transportTypes : []}
                        value={values.transportType}
                        onChange={(e) => setFieldValue("transportType", e)}
                        valueKey="id"
                        style={{ width: "100%", height: "auto" }}
                        labelKey="description"
                      />
                    </>
                  )}
                  <>
                    <p
                      className="headingStyle"
                      style={{ marginBlock: "16px 10px" }}
                    >
                      {strings.getString("truck_type")}
                    </p>
                    <TagPicker
                      placement="bottomStart"
                      className="1aed  filterTagPicker"
                      placeholder={strings.getString("truck_type")}
                      data={truckTypes ? truckTypes : []}
                      value={values.truckType}
                      onChange={(e) => {
                        console.log(e);
                        setFieldValue("truckType", e);
                      }}
                      valueKey="id"
                      style={{ width: "100%", height: "auto" }}
                      labelKey="description"
                      renderMenuItem={(label, item) => {
                        return (
                          <Whisper
                            speaker={
                              //@ts-ignore
                              item.key_value__c ? item.key_value__c : "-"
                            }
                          >
                            <div
                              style={{ cursor: "pointer", marginInlineEnd: 20 }}
                            >
                              <span>{label}</span>
                            </div>
                          </Whisper>
                        );
                      }}
                    />
                  </>
                  <Divider />
                  <Sidenav className="sidemenu-sidefilter-sidebar">
                    <Sidenav.Body>
                      <Dropdown
                        eventKey={"2"}
                        title={strings.getString("loading_type")}
                        className="sidemenu-dropdown headingStyle"
                      >
                        <CheckboxGroup
                          name="loadingTypes"
                          value={values.loadingTypes}
                          onChange={(e) => setFieldValue("loadingTypes", e)}
                        >
                          {loadingTypes?.map((type) => (
                            <Checkbox key={type.id} value={type.id}>
                              {type.description}
                            </Checkbox>
                          ))}
                        </CheckboxGroup>
                      </Dropdown>
                      <Dropdown
                        eventKey={"3"}
                        title={strings.getString("unloading_type")}
                        className="sidemenu-dropdown headingStyle"
                        style={{ marginBlockEnd: 0 }}
                      >
                        <CheckboxGroup
                          name="unloadingTypes"
                          value={values.unloadingTypes}
                          onChange={(value: ValueType[]) => {
                            setFieldValue("unloadingTypes", value);
                          }}
                        >
                          {loadingTypes?.map((unloadType) => (
                            <Checkbox key={unloadType.id} value={unloadType.id}>
                              {unloadType.description}
                            </Checkbox>
                          ))}
                        </CheckboxGroup>
                      </Dropdown>
                      <Divider />
                    </Sidenav.Body>
                  </Sidenav>

                  <p className="headingStyle">{strings.getString("details")}</p>
                  <div
                    className="flexDiv"
                    style={{ alignItems: "flex-end", height: 73 }}
                  >
                    <div style={{ width: "43%" }}>
                      <Input
                        inputType={InputType.number}
                        label={strings.getString("tons")}
                        name="ton"
                        //@ts-ignore
                        value={values.ton?.min}
                        onChange={handleChange("ton.min")}
                        onBlur={handleBlur("ton.min")}
                      />
                    </div>
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      -
                    </div>
                    <div style={{ width: "43%" }}>
                      <Input
                        inputType={InputType.number}
                        name="ton"
                        //@ts-ignore
                        value={values.ton?.max}
                        onChange={handleChange("ton.max")}
                        onBlur={handleBlur("ton.max")}
                      />
                    </div>
                  </div>
                  <div className="flexDiv">
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "47%" }}>
                        <Input
                          inputType={InputType.number}
                          label={strings.getString("volume")}
                          name="volume"
                          //@ts-ignore
                          value={values.volume?.min}
                          onChange={handleChange("volume.min")}
                          onBlur={handleBlur("volume.min")}
                        />
                      </div>
                      <div
                        style={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        -
                      </div>

                      <div style={{ width: "47%" }}>
                        <Input
                          inputType={InputType.number}
                          name="volume"
                          //@ts-ignore
                          value={values.volume?.max}
                          onChange={handleChange("volume.max")}
                          onBlur={handleBlur("volume.max")}
                        />
                      </div>
                    </div>
                  </div>
                  {!isTruck && (
                    <div
                      className="flexDiv"
                      style={{ alignItems: "flex-end", height: 73 }}
                    >
                      <div style={{ width: "43%" }}>
                        <Input
                          inputType={InputType.number}
                          label={strings.getString("number_of_cars")}
                          name="carNumber"
                          //@ts-ignore
                          value={values.carNumber?.min}
                          onChange={handleChange("carNumber.min")}
                          onBlur={handleBlur("carNumber.min")}
                        />
                      </div>
                      <div
                        style={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        -
                      </div>

                      <div style={{ width: "43%" }}>
                        <Input
                          inputType={InputType.number}
                          name="carNumber"
                          //@ts-ignore
                          value={values.carNumber?.max}
                          onChange={handleChange("carNumber.max")}
                          onBlur={handleBlur("carNumber.max")}
                        />
                      </div>
                    </div>
                  )}
                  {!isTruck && (
                    <>
                      <Divider />
                      <CheckboxGroup
                        name="details"
                        value={values.details}
                        onChange={(e) => setFieldValue("details", e)}
                      >
                        {detailsCheckboxList.map((item) => {
                          return (
                            <Checkbox key={item.value} value={item.value}>
                              {item.label}
                            </Checkbox>
                          );
                        })}
                      </CheckboxGroup>
                    </>
                  )}
                  {!isTruck && (
                    <>
                      {" "}
                      <Divider />
                      <p className="smallHeadingStyle">
                        {strings.getString("maximum_requated_price")}
                      </p>
                      <Input
                        inputType={InputType.number}
                        name="price"
                        //@ts-ignore
                        value={values.price}
                        onChange={handleChange("price")}
                        onBlur={handleBlur("price")}
                      />
                    </>
                  )}
                  <div
                    style={{
                      width: "100%",
                      bottom: 0,
                      paddingBlockEnd: 15,
                    }}
                  >
                    <Button
                      type="submit"
                      title={
                        isTruck
                          ? strings.getString("search_trucks")
                          : strings.getString("search_loads")
                      }
                      onClick={handleSubmit}
                    />
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default SidebarFilter;
