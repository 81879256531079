import { useDispatch, useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { getLocalizedLoadRegistration } from "../../../../redux/selectors";
import { StepProps } from ".";
import { DatePicker, Input, SelectPicker } from "../../../../components";
import {
  AdrListDto,
  FrigoTypeDto,
  LoadTypeDto,
} from "../../../../redux/opportunity/type";
import { useEffect, useState } from "react";
import {
  getAdrList,
  GetFrigoTypes,
  GetLoadTypes,
  getLocationForOpp,
  GetMainLoadTypes,
} from "../../../../redux/opportunity/saga";
import moment from "moment";
import { InputType } from "../../../../components/Input";
import { getCurrencies } from "../../../../redux/profile/profileSaga";
import { CurrencyDto } from "../../../../redux/profile/type";
import AutoCompleteRs from "../../../../components/AutoCompleteRs";

interface Step0Props extends StepProps {
  quickBooking?: boolean;
}

const Step0 = ({ values, errors, touched, quickBooking }: Step0Props) => {
  const dispatch = useDispatch();
  const { handleChange, handleBlur, setFieldValue } = useFormikContext();

  const strings = useSelector(getLocalizedLoadRegistration);
  // eslint-disable-next-line
  const [loadTypes, setLoadTypes] = useState<LoadTypeDto[]>();
  const [mainLoadTypes, setMainLoadTypes] = useState<LoadTypeDto[]>();
  const [frigoTypes, setFrigoTypes] = useState<FrigoTypeDto[]>();
  const [adrList, setAdrList] = useState<AdrListDto[]>();
  const [currencies, setCurrencies] = useState<CurrencyDto[]>();
  useEffect(() => {
    dispatch(
      GetMainLoadTypes({
        payload: {
          onSuccess: (message, payload) => setMainLoadTypes(payload),
          onError: (message) => {},
        },
      })
    );
    dispatch(
      GetLoadTypes({
        payload: {
          onSuccess: (message, payload) => setLoadTypes(payload),
          onError: (message) => {},
        },
      })
    );
    dispatch(
      getAdrList({
        payload: {
          onSuccess: (message, payload) => setAdrList(payload),
          onError: (message) => {},
        },
      })
    );
    dispatch(
      GetFrigoTypes({
        payload: {
          onSuccess: (message, payload) => setFrigoTypes(payload),
          onError: (message) => {},
        },
      })
    );
    dispatch(
      getCurrencies({
        payload: {
          onSuccess: (message, payload) => setCurrencies(payload),
          onError: (message) => {},
        },
        url: "System/GetCurrencyList",
      })
    );
  }, [dispatch]);
  return (
    <>
      <p
        className="newBoldHeadingStyle"
        style={{ marginBlockEnd: 18, textAlign: "center" }}
      >
        {strings.getString("1 Load Information")}
      </p>

      <div className="dividedRow">
        <div className="halfWidth">
          <AutoCompleteRs
            selectedItem={values.FirstLoadingLocation__c_Name}
            error={errors.FirstLoadingLocation__c}
            label={strings.getString("Loading Location")}
            action={getLocationForOpp}
            url="Homepage/GetLocationData"
            onselectitem={(value) => {
              setFieldValue("FirstLoadingLocation__c", value);
              return setFieldValue("guzergahList__c", []);
            }}
            onselectitemforlabel={(value) => {
              setFieldValue("FirstLoadingLocation__c_Name", value);
            }}
            style={{ marginBlock: "0px 5px" }}
            onselectcountrycode={(value) => {
              setFieldValue("fromcountrycode", value);
            }}
          />
        </div>
        <div className="halfWidth">
          <AutoCompleteRs
            selectedItem={values.FirstUnloadingLocation__c_Name}
            error={errors.FirstUnloadingLocation__c}
            label={strings.getString("Unloading Location")}
            action={getLocationForOpp}
            url="Homepage/GetLocationData"
            onselectitem={(value) => {
              setFieldValue("FirstUnloadingLocation__c", value);
              return setFieldValue("guzergahList__c", []);
            }}
            onselectitemforlabel={(value) => {
              setFieldValue("FirstUnloadingLocation__c_Name", value);
            }}
            style={{ marginBlock: "0px 5px" }}
            onselectcountrycode={(value) => {
              setFieldValue("tocountrycode", value);
            }}
          />
        </div>
      </div>
      <div className="dividedRow mt-1">
        <div className="threeCol">
          <DatePicker
            label={strings.getString("Load Start Date")}
            name="start"
            value={new Date(values.LoadingTimeStarted__c)}
            onChange={(e) => {
              setFieldValue("LoadingTimeStarted__c", moment(e).format());
            }}
            onBlur={handleBlur("LoadingTimeStarted__c")}
          />
        </div>
        <div className="threeCol">
          <DatePicker
            label={strings.getString("Load End Date")}
            name="LoadingTimeEnded__c"
            value={new Date(values.LoadingTimeEnded__c)}
            onChange={(e) => {
              setFieldValue("LoadingTimeEnded__c", moment(e).format());
            }}
            onBlur={handleBlur("LoadingTimeEnded__c")}
          />
        </div>
        <div className="threeCol">
          <DatePicker
            label={strings.getString("Desired Arrival Date")}
            name="start"
            value={new Date(values.CustomersRequestedArrivalDate__c)}
            onChange={(e) => {
              setFieldValue(
                "CustomersRequestedArrivalDate__c",
                moment(e).format()
              );
            }}
            onBlur={handleBlur("CustomersRequestedArrivalDate__c")}
          />
        </div>
      </div>
      <div className="dividedRow mt-1">
        <div className="threeCol">
          <SelectPicker
            cleanable={false}
            searchable={true}
            valueKey={"api_name__c"}
            labelKey={"value__c"}
            value={values.Main_Load_Type__c}
            label={strings.getString("Main Load Type")}
            data={mainLoadTypes ? mainLoadTypes : []}
            onSelect={(value, item) => {
              setFieldValue("Main_Load_Type__c", value);
              //@ts-ignore
              item.api_name__c !== "FRİGO" &&
                setFieldValue("FrigoCelsius__c", "");
              //@ts-ignore
              item.api_name__c !== "ADR" && setFieldValue("ADR__c", "");
              //@ts-ignore
              setFieldValue(
                "main_load_type__c_VALUE",
                //@ts-ignore
                item.api_name__c
              );
            }}
            onBlur={handleBlur("Main_Load_Type__c")}
            error={errors.Main_Load_Type__c}
            touched={touched.Main_Load_Type__c}
          />
        </div>
        <div className="threeCol">
          <Input
            label={strings.getString("Load Type")}
            name="LoadType__c"
            value={values.LoadType__c}
            onChange={handleChange(`LoadType__c`)}
            onBlur={handleBlur("LoadType__c")}
            error={errors.LoadType__c}
            touched={touched.LoadType__c}
          />
        </div>
        <div className="threeCol">
          <Input
            min={1}
            label={strings.getString("Vehicle Number")}
            name="VehicleNumber__c"
            inputType={InputType.number}
            //@ts-ignore
            value={values.VehicleNumber__c}
            onChange={handleChange(`VehicleNumber__c`)}
            onBlur={handleBlur("VehicleNumber__c")}
            error={errors.VehicleNumber__c}
            touched={true}
          />
        </div>
      </div>
      <div
        className={
          values.main_load_type__c_VALUE === "ADR" ||
          values.main_load_type__c_VALUE === "FRİGO"
            ? "dividedRow"
            : "dividedRow "
        }
      >
        {values.main_load_type__c_VALUE === "ADR" && (
          <div className="threeCol">
            <SelectPicker
              cleanable={false}
              value={values.adr__c}
              valueKey={"sfid"}
              labelKey={"value__c"}
              searchable={true}
              label={strings.getString("ADR")}
              data={adrList ? adrList : []}
              onSelect={handleChange("ADR__c")}
              onBlur={handleBlur("ADR__c")}
              error={errors.ADR__c}
              touched={touched.ADR__c}
            />
          </div>
        )}
        {values.main_load_type__c_VALUE === "FRİGO" && (
          <div className="threeCol">
            <SelectPicker
              cleanable={false}
              value={values.FrigoCelsius__c}
              valueKey={"sfid"}
              labelKey={"value__c"}
              searchable={true}
              label={strings.getString("Frigo Celsius")}
              data={frigoTypes ? frigoTypes : []}
              onSelect={handleChange("FrigoCelsius__c")}
              onBlur={handleBlur("FrigoCelsius__c")}
              error={errors.FrigoCelsius__c}
              touched={touched.FrigoCelsius__c}
            />
          </div>
        )}
      </div>
      <div className="dividedRow ">
        <div className="threeCol">
          <Input
            inputType={InputType.number}
            label={strings.getString("Tonnage")}
            name="Tonnage__c"
            value={values.Tonnage__c}
            onChange={handleChange("Tonnage__c")}
            onBlur={handleBlur("Tonnage__c")}
            error={errors.Tonnage__c}
            touched={true}
          />
        </div>
        <div className="threeCol">
          <Input
            inputType={InputType.number}
            value={values.CustomersWantsPrice__c}
            label={strings.getString("Requested Price")}
            name="CustomersWantsPrice__c"
            onChange={handleChange("CustomersWantsPrice__c")}
            onBlur={handleBlur("CustomersWantsPrice__c")}
            error={errors.CustomersWantsPrice__c}
            touched={touched.CustomersWantsPrice__c}
          />
        </div>
        <div className="threeCol">
          <SelectPicker
            cleanable={false}
            searchable={true}
            valueKey={"isocode"}
            labelKey={"isocode"}
            value={values.Currencyisocode}
            label={strings.getString("Currency")}
            data={currencies ? currencies : []}
            onSelect={(value, item) => {
              setFieldValue("Currencyisocode", value);
              if (values.guzergahList__c.length > 0) {
                setFieldValue(
                  "guzergahList__c",
                  values.guzergahList__c.map((x: any) => {
                    return {
                      no: x.no,
                      Guzergah__c: x.Guzergah__c,
                      Currencyiscode___: value,
                      CustomersWantsPrice__c: x.CustomersWantsPrice__c,
                    };
                  })
                );
              }
            }}
            onBlur={handleBlur("Currencyisocode")}
            error={errors.Currencyisocode}
            touched={touched.Currencyisocode}
          />
        </div>
      </div>
    </>
  );
};

export default Step0;
