import moment from "moment";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Row } from "rsuite";
import { IncDescAuctionDto } from "../../../redux/autions/inc-desc-type";
import { AuctionListCounterDto } from "../../../redux/autions/type";
import { getLocalizedAuction } from "../../../redux/selectors";

const AuctionCard = ({
  auction,
  listCounter,
  isOpen,
  refreshList,
}: {
  refreshList?: () => void;
  isOpen: boolean;
  auction: IncDescAuctionDto;
  listCounter?: AuctionListCounterDto;
}) => {
  const history = useHistory();
  const t = useSelector(getLocalizedAuction);
  return (
    <div className="auctionCard ">
      <Row>
        <Col
          md={24}
          onClick={() =>
            history.push("/inc-desc-auction-details/" + auction.id)
          }
        >
          <Row>
            <Col md={6}>
              <div className="auctionCardColumn">
                <p className="auctionCardColumnTitle">
                  {t.getString("Tender No")}
                </p>
                <p>{auction.opdtender_no__c}</p>
              </div>
            </Col>
            <Col md={6}>
              <div className="auctionCardColumn">
                <p className="auctionCardColumnTitle">
                  {t.getString("Tender Type")}
                </p>
                <p>{auction.transportertendertype__c}</p>
              </div>
            </Col>
            <Col md={6}>
              <div className="auctionCardColumn">
                <p className="auctionCardColumnTitle">
                  {t.getString("Tender Contract Start Date")}
                </p>
                <p>
                  {moment(auction.tenderstartdatetime__c + "+00:00").format(
                    "DD.MM.YYYY HH:mm"
                  )}
                </p>
              </div>
            </Col>
            <Col md={6}>
              <div className="auctionCardColumn">
                <p className="auctionCardColumnTitle">
                  {t.getString("Tender Contract End Date")}
                </p>
                <p>
                  {moment(auction.tenderenddatetime__c + "+00:00").format(
                    "DD.MM.YYYY HH:mm"
                  )}
                </p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default AuctionCard;
