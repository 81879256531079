import { useDispatch, useSelector } from "react-redux";
import { Button, Divider } from "..";
import { getLocalizedSidebarNavigation } from "../../redux/selectors";
import { color } from "../../utils/Properties";
import "./style.scss";
//@ts-ignore
import Faq from "react-faq-component";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { getSSS } from "../../redux/actions";

const BaseLayout = () => {
  const strings = useSelector(getLocalizedSidebarNavigation);
  const history = useHistory();
  const [sss, setSss] = useState<{ title: string; content: string }[]>();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getSSS({
        payload: {
          onSuccess: (message, payload) => {
            if (payload) {
              setSss(
                payload.map((x) => {
                  return { title: x.question, content: x.reply };
                })
              );
            }
          },
          onError: (message) => {},
        },
        url: "HomePage/GetSSS",
      })
    );
  }, [dispatch]);

  const styles = {
    bgColor: "transparent",
    titleTextColor: color.primary,
    titleTextSize: "18px",
    rowTitleColor: color.textPrimary,
    rowTitleTextSize: "12px",
    rowContentColor: color.textPrimary,
    rowContentTextSize: "12px",
  };

  return (
    <div className="sidebarNavigation">
      <div className="sidebarNavigationComponent">
        <div
          className="biggerHeadingStyle"
          style={{ fontSize: 24, lineHeight: "140%" }}
        ></div>

        <Divider />
        <div className="detailedFormDesc">
          <p className="standardText">{strings.getString("detailed_form")}</p>
          <p
            className="mediumHeadingStyle"
            style={{ marginTop: 14, color: color.primary }}
          >
            {strings.getString("form_header")}
          </p>
          <p
            className="standardText"
            style={{
              color: "#8095ae",
              marginBlockStart: 12,
              fontSize: 11,
            }}
          >
            {strings.getString("form_header_content")}
            <br />
            <br />
            <span className="standardText" style={{ color: "#8095ae" }}>
              {strings.getString("form_question")}
            </span>
          </p>
          <div style={{ marginBlockStart: 24, width: "100%", maxWidth: 200 }}>
            <Button
              title={strings.getString("form_button")}
              onClick={() => history.push("/sss")}
            />
          </div>
        </div>
        <Divider />

        <div
          className="faqsContent"
          style={{
            width: "100%",
            height: "max-content",
            marginBlockEnd: 15,
          }}
        >
          <Faq
            data={{ rows: sss, title: strings.getString("faq_header") }}
            styles={styles}
          />
        </div>
      </div>
    </div>
  );
};

export default BaseLayout;
