import { ChatMessageReceiveObject } from ".";

export const formatChatMessageReceive = (
  m: ChatMessageReceiveObject
): ContractMessage => {
  return {
    Message: m.message,
    CreateTime: m.createTime,
    ProfileImage: m.profileImage,
    UserId: m.userId,
    UserName: m.userName,
    Read: m.read,
  };
};
export interface ContractMessage {
  Message: string;
  CreateTime: string;
  ProfileImage: string;
  UserId: string;
  UserName: string;
  Read: boolean;
}
export interface ChatSendObject {
  Message: string;
  opportunity_destination__c?: string;
  user__c?: string;
  contact__c?: string;
}
