import { useDispatch, useSelector } from "react-redux";

import {
  getLocalizedErrors,
  getLocalizedMyProfile,
  getUserState,
} from "../../../../../redux/selectors";
import { Button, CheckPicker, Input } from "../../../../../components";
import { color } from "../../../../../utils/Properties";
import { Formik } from "formik";
import { Alert, Checkbox } from "rsuite";

import * as Yup from "yup";
import {
  getCommunicationLanguages,
  setUser,
  updateProfile,
} from "../../../../../redux/actions";
import { useEffect, useState } from "react";
import { CommunicationLanguageObject } from "../../../../../types";
const Profile = () => {
  const dispatch = useDispatch();
  const strings = useSelector(getLocalizedMyProfile);
  const errorStrings = useSelector(getLocalizedErrors);
  const [comLang, setComLang] = useState<CommunicationLanguageObject[]>();
  const user = useSelector(getUserState);
  useEffect(() => {
    dispatch(
      getCommunicationLanguages({
        payload: {
          onSuccess: (message, payload) => {
            setComLang(payload);
          },
          onError: (message) => {},
        },
        url: "System/GetCommunicationLanguage",
      })
    ); // eslint-disable-next-line
  }, []);
  const onSubmitPress = ({
    firstname,
    lastname,
    email,
    communicationlanguage__c,
    Languages,
    proposalresponsible__c,
    phone,
  }: {
    firstname: string;
    lastname: string;
    proposalresponsible__c: boolean;
    email: string;
    communicationlanguage__c: string;
    Languages: any[];
    phone: string;
  }) => {
    communicationlanguage__c = Languages.join(";");
    dispatch(
      updateProfile({
        params: {
          firstname,
          lastname,
          email,
          phone,
          communicationlanguage__c,
          proposalresponsible__c,
        },
        onSuccess: (payload) => {
          user &&
            dispatch(
              setUser({
                ...user,
                FirstName: firstname,
                LastName: lastname,
                MobilePhone: phone,
                Email: email,
                CommunicationLanguage: communicationlanguage__c,
                ProposalResponsible: proposalresponsible__c,
              })
            );
          Alert.success(errorStrings.getString("success"));
        },
        onError: (message) => {
          Alert.error(message, 7000);
        },
      })
    );
  };

  const profileSchema = Yup.object().shape({
    firstname: Yup.string().required(errorStrings.getString("required")),
    lastname: Yup.string().required(errorStrings.getString("required")),
    email: Yup.string()
      .email(errorStrings.getString("email"))
      .required(errorStrings.getString("required")),
    role: Yup.string(),
    phone: Yup.string()
      .phone("", true, errorStrings.getString("phone"))
      .required(errorStrings.getString("required")),
  });

  return (
    <Formik
      initialValues={{
        firstname: user?.FirstName ?? "",
        lastname: user?.LastName ?? "",
        email: user?.Email ?? "",
        phone: user?.MobilePhone ?? "",
        communicationlanguage__c: user?.CommunicationLanguage ?? "",
        proposalresponsible__c: user?.ProposalResponsible ?? false,
        Languages: user?.CommunicationLanguage.split(";") ?? [],
      }}
      validationSchema={profileSchema}
      onSubmit={onSubmitPress}
    >
      {({
        handleSubmit,
        setFieldValue,
        handleBlur,
        handleChange,
        values,
        errors,
        touched,
      }) => {
        return (
          <div className="myprofile-menu-outer-div">
            <div
              className="profile-menu my-profile-card "
              style={{
                backgroundColor: color.accent,
                position: "fixed",
              }}
            >
              <p className="newBoldHeadingStyle">
                {strings.getString("profiledetails")}
              </p>
              <div
                className="regularText"
                style={{
                  marginBlockEnd: 30,
                }}
              >
                {strings.formatString(
                  strings.getString("profile_details_description")
                )}
              </div>
              <div className="my-profile-card-form">
                <div className="row">
                  <div className="col-md-2">
                    <Input
                      label={strings.getString("firstname")}
                      name="firstname"
                      value={values.firstname}
                      onChange={handleChange("firstname")}
                      onBlur={handleBlur("firstname")}
                      error={errors.firstname}
                      touched={touched.firstname}
                    />
                  </div>
                  <div className="col-md-2">
                    <Input
                      label={strings.getString("lastname")}
                      name="lastname"
                      value={values.lastname}
                      onChange={handleChange("lastname")}
                      onBlur={handleBlur("lastname")}
                      error={errors.lastname}
                      touched={touched.lastname}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2">
                    <Input
                      label={strings.getString("email")}
                      name="email"
                      value={values.email}
                      onChange={handleChange("email")}
                      onBlur={handleBlur("email")}
                      error={errors.email}
                      touched={touched.email}
                    />
                  </div>

                  <div className="col-md-2">
                    <Input
                      label={strings.getString("phone")}
                      name="phone"
                      value={values.phone}
                      onChange={handleChange("phone")}
                      onBlur={handleBlur("phone")}
                      error={errors.phone}
                      touched={touched.phone}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2">
                    <CheckPicker
                      searchable={false}
                      cleanable={false}
                      label={strings.getString("communication_language")}
                      data={comLang ? comLang : []}
                      labelKey="description"
                      valueKey="id"
                      placement="autoVerticalStart"
                      name="id"
                      value={values.Languages}
                      onChange={(e) => setFieldValue(`Languages`, e)}
                      onBlur={handleBlur(`Languages`)}
                      error={errors.Languages}
                      touched={touched.Languages}
                    />
                  </div>
                  {console.log("Languages", values.Languages)}
                  <div className="col-md-2">
                    <Checkbox
                      name="partial"
                      checked={values.proposalresponsible__c}
                      onChange={(value, checked) =>
                        setFieldValue("proposalresponsible__c", checked)
                      }
                    >
                      {strings.getString("proposalresponsible__c")}
                    </Checkbox>
                  </div>
                </div>
                <div></div>
              </div>
              {user && (
                <div
                  style={{
                    marginBlockStart: 40,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    type="submit"
                    title={strings.getString("save") + "➔"}
                    onClick={handleSubmit}
                    style={{ width: 100 }}
                  />
                </div>
              )}
            </div>
          </div>
        );
      }}
    </Formik>
  );
};
export default Profile;
