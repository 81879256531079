import { CheckPicker as CheckPickerRS, CheckPickerProps } from "rsuite";
import { color } from "../../utils/Properties";

const CheckPicker = ({
  data,
  label,
  value,
  onChange,
  error,
  customClassName = " ",
  touched,
  ...rest
}: CheckPickerProps) => {
  return (
    <div
      className="field only-date defaultInputRes"
      style={{ borderColor: color.borderColor }}
    >
      <label
        style={{
          fontSize: 13,
          marginBlockEnd: 2,
          color: color.textPrimary,
        }}
      >
        {label}
      </label>
      <CheckPickerRS
        className={
          error && touched
            ? `${"errorInput"}  ${customClassName} `
            : `${"defaultInput"}  ${customClassName} `
        }
        data={data}
        value={value}
        onChange={onChange}
        {...rest}
      />
      {error && touched && <p className="inputErrorStyles">{error}</p>}
    </div>
  );
};

export default CheckPicker;
