import { FileUploader } from "devextreme-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "rsuite";
import { Button } from "../../../../../components";
import { ButtonType } from "../../../../../components/Button";
import { getLocalizedAuction } from "../../../../../redux/selectors";
import { color } from "../../../../../utils/Properties";

const ImportExcelModal = ({
  open,
  setOpen,
  onChangeFilesToJson,
}: {
  open: boolean;
  onChangeFilesToJson: (file: any) => void;
  setOpen: (open: boolean) => void;
}) => {
  const [file, setjson] = useState<any>();
  const [error, setError] = useState<string>("");
  const t = useSelector(getLocalizedAuction);
  return (
    <Modal show={open} onHide={() => setOpen(false)} size={"sm"}>
      <Modal.Body>
        <div style={{ padding: "50px 50px 0px 50px" }}>
          <FileUploader
            uploadFailedMessage=""
            accept={".xls,.xlsx"}
            labelText={t.getString("Drop files here or click to upload")}
            multiple={false}
            selectButtonText={t.getString("Select File")}
            onValueChanged={(e) => {
              return e.value && setjson(e.value);
            }}
          />

          {error && (
            <div
              style={{
                marginBlock: 5,
                color: color.error,
                padding: 10,
                fontWeight: 600,
                background: color.errorSoft,
                border: "1px solid " + color.error,
                textAlign: "center",
              }}
            >
              {error}
            </div>
          )}
          <div
            style={{
              marginTop: 30,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              buttonType={ButtonType.inverted}
              title={"Kapat"}
              onClick={() => {
                return setOpen(false);
              }}
              style={{ width: 100 }}
            />
            <Button
              buttonType={ButtonType.default}
              title={"Kaydet"}
              onClick={() => {
                if (file) {
                  onChangeFilesToJson(file);
                  return setOpen(false);
                } else {
                  setError(t.getString("Tender Excel File Error"));
                }
              }}
              style={{ width: 100 }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ImportExcelModal;
