import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer, Form } from "rsuite";
import { Formik } from "formik";
import * as Yup from "yup";
import "./style.css";
import ScrollBar from "react-perfect-scrollbar";
import withDirection, {
  DIRECTIONS,
  //@ts-ignore
} from "react-with-direction";
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import {
  ChatDrawerProps,
  ChatSendObject,
  formatChatMessageReceive,
  TenderChatMessageReceiveObject,
} from "./utils";
import { chatUrl, color } from "../../../../../utils/Properties";
import { getApiParams, getUserState } from "../../../../../redux/selectors";
import { Divider, Icon, Input, Loader } from "../../../../../components";
import { MessageChatDto } from "../../../../../redux/autions/type";
import { getAuctionChat } from "../../../../../redux/autions/saga";

const AlwaysScrollToBottom = () => {
  const elementRef = useRef(null);
  useEffect(() =>
    //@ts-ignore
    elementRef.current.scrollIntoView()
  );
  return <div ref={elementRef} />;
};
const HUB_ENDPOINT = chatUrl + "api/tenderchat";

const AuctionChatDrawer = ({
  auction,
  show,
  setShow,
  direction,
}: ChatDrawerProps) => {
  const user = useSelector(getUserState);
  const messagesEndRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const ChatSchema = Yup.object().shape({ message: Yup.string().min(1) });
  const [messages, setMessages] = useState<MessageChatDto[]>([]);
  const { token, lang } = useSelector(getApiParams);
  const connectionRef2 = useRef<HubConnection | null>(null);
  const dispatch = useDispatch();
  const [connectionStatus, setConnectionStatus] = useState<
    "offline" | "connecting" | "connected" | "failed"
  >("offline");

  useEffect(() => {
    if (show) {
      dispatch(
        getAuctionChat({
          payload: {
            onSuccess: (ms, payload) => {
              payload &&
                payload.length > 0 &&
                setMessages(JSON.parse(payload[0].tendermessagejson__c));
              return connectChat();
            },
            onError: () => {},
          },
          id: auction.sfid,
        })
      );
    } // eslint-disable-next-line
  }, [dispatch, show, auction.sfid]);
  const connectChat = () => {
    console.log(
      connectionRef2.current === null && token && lang,
      connectionRef2.current === null
    );
    if (connectionRef2.current === null && token && lang) {
      setConnectionStatus("connecting");
      const connection = new HubConnectionBuilder()
        .withUrl(HUB_ENDPOINT, {
          headers: { "Accept-Language": `${lang}` },
          accessTokenFactory: () => token,
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Debug)
        .build();
      connection.serverTimeoutInMilliseconds = 25000;
      console.log(`Trying to connect to ${HUB_ENDPOINT}`);
      connection
        .start()
        .then(() => {
          console.log(`TENDER CHAT Connected to ${HUB_ENDPOINT}`);
          setConnectionStatus("connected");
          setLoader(false);
        })
        .catch((err: any) => {
          setConnectionStatus("failed");
          console.log(`Error starting the CHAT connection: ${err.toString()}`);
        });
      connection.onclose(async () => {
        console.log(`Disconnected from ${HUB_ENDPOINT}`);
      });
      connection.on(
        auction.sfid + user?.UserID,
        function (payload: TenderChatMessageReceiveObject) {
          console.log("ReceiveMessage", auction.sfid, payload);
          setMessages((state) => [...state, formatChatMessageReceive(payload)]);
          scrollToBottom();
        }
      );
      connectionRef2.current = connection;
      setConnectionStatus("connected");
    } else console.log("CONNECTION ALREADY ESTABLIESHED");
  };
  const onMessageSend = async (
    values: any,
    { resetForm }: { resetForm: any }
  ) => {
    if (connectionRef2.current !== null) {
      try {
        const serverObject: ChatSendObject = {
          opportunity__c: auction?.sfid,
          contact__c: user?.UserID,
          user__c: auction.advisorid,
          Message: values.message,
        };
        const result = await connectionRef2.current?.invoke(
          "SendMessage",
          serverObject
        );
        console.log("SendMessage", result);
        resetForm();
      } catch (err: any) {
        console.log(
          `Error sending  from${user?.FirstName} ${user?.LastName}: ${err.message} ${connectionStatus}`
        );
      }
    }
  };

  const scrollToBottom = () => {
    //@ts-ignore
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);
  return (
    <Drawer
      className="vehicle-chat-drawer"
      placement={direction === DIRECTIONS.LTR ? "right" : "left"}
      show={show}
      onHide={() => {
        setShow(false);
        if (connectionRef2.current && connectionRef2.current !== null) {
          try {
            connectionRef2.current?.stop().then(() => {
              console.log("CONNECTION STOPPED");
              connectionRef2.current = null;
            });
          } catch (e) {
            console.log("ERROR UNMOUNT", e);
          }
        }
      }}
    >
      <Drawer.Header>
        <Drawer.Title
          className="biggerHeadingStyle"
          style={{
            textAlign: "center",
            color: color.textHeader,
            marginBlockEnd: 15,
            fontSize: 14,
          }}
        ></Drawer.Title>
        <div className="newHeadingStyle text-center">
          {auction.tender_no__c}
        </div>
        <Divider />
      </Drawer.Header>
      <Drawer.Body
        className="vehicle-chat-card-drawer-body"
        style={{ height: `calc(100% - 230px)`, marginBlock: 15 }}
      >
        {loader && <Loader />}
        <ScrollBar>
          {!loader &&
            messages?.map((text, i) => {
              if (text.UserId !== user?.UserID) {
                return (
                  <div
                    key={i}
                    style={{
                      marginBlockStart: 10,
                    }}
                  >
                    <div
                      className="managerMessagebox standardText"
                      style={{
                        borderColor: color.borderSide,
                        backgroundColor: color.accent,
                      }}
                    >
                      {text.Message}
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    key={i}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      marginBlockStart: 10,
                    }}
                  >
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                    ></div>
                    <div
                      className="customerMessagebox standardText"
                      style={{
                        borderColor: color.borderSide,
                        backgroundColor: color.borderSide,
                      }}
                    >
                      {text.Message}
                    </div>
                  </div>
                );
              }
            })}
          <div ref={messagesEndRef} />
          <AlwaysScrollToBottom />
        </ScrollBar>
      </Drawer.Body>
      <Drawer.Footer>
        <Formik
          initialValues={{
            message: "",
          }}
          initialErrors={{ message: " " }}
          validationSchema={ChatSchema}
          onSubmit={onMessageSend}
        >
          {({ handleSubmit, handleChange, handleBlur, values }) => {
            return (
              <Form
                style={{
                  width: "100%",
                  display: "flex",
                }}
              >
                {direction === DIRECTIONS.RTL && (
                  <div
                    onClick={() => handleSubmit()}
                    style={{ marginInlineEnd: 15, cursor: "pointer" }}
                  >
                    <Icon
                      icon="send"
                      size={48}
                      style={{ transform: `rotate(180deg)` }}
                    />
                  </div>
                )}
                <div style={{ width: `calc(100% - 65px)` }}>
                  <Input
                    name="firstName0"
                    value={values.message}
                    onChange={handleChange("message")}
                    onBlur={handleBlur("message")}
                    style={{ paddingBlock: 13 }}
                    autoFocus
                    onPressEnter={() => handleSubmit()}
                  />
                </div>
                {direction === DIRECTIONS.LTR && (
                  <div
                    onClick={() => handleSubmit()}
                    style={{ marginInlineStart: 15, cursor: "pointer" }}
                  >
                    <Icon icon="send" size={48} />
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </Drawer.Footer>
    </Drawer>
  );
};

export default withDirection(AuctionChatDrawer);
