import { createAction } from "redux-smart-actions";

/**
 * GETTERS
 */

/**
 * SETTERS
 */
export const updateLocalization = createAction(
  "UPDATE_LOCALIZATION",
  (payload) => payload
);
