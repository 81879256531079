import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Col, Row } from "rsuite";
import { getAuctionDetailsPriceList } from "../../../../../redux/autions/saga";
import AuctionChatDrawer from "../chat";
import {
  AuctionCounterDto,
  AuctionDetailDto,
  AuctionPriceDto,
} from "../../../../../redux/autions/type";
import { getLocalizedAuction } from "../../../../../redux/selectors";
import { color } from "../../../../../utils/Properties";
import "../../style.scss";
import DetailTab from "../detailTab";
import PriceTab from "./priceTab";
const PurposeAuction = ({
  auction,
  setAuction,
  counter,
}: {
  counter?: AuctionCounterDto;
  auction: AuctionDetailDto;
  setAuction: (auction: AuctionDetailDto) => void;
}) => {
  const t = useSelector(getLocalizedAuction);
  const [questionModal, setquestionModal] = useState(false);
  const [active, setActive] = useState("1");
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [prices, setPrices] = useState<AuctionPriceDto[]>();
  const [join, setJoin] = useState(false);
  const notdisabled = auction.StartingTender && !auction.EndingTender;
  const toggleMenu = (tab: string) => {
    setActive(tab);
  };
  useEffect(() => {
    if (location.search.includes("?chat=true")) {
      setquestionModal(true);
      history.push(location.search.replace("?chat=true", "?"));
    } // eslint-disable-next-line
  }, [location.search]);
  useEffect(() => {
    active === "2" &&
      dispatch(
        getAuctionDetailsPriceList({
          payload: {
            onSuccess: (m, payload) => {
              setPrices(payload);
              payload &&
                payload.length > 0 &&
                setJoin(
                  payload[0].tender_accept_rules__c &&
                    payload[0].tender_accept_rules__c != null
                    ? true
                    : false
                );
            },
            onError: () => {},
          },
          id: auction.sfid,
        })
      );
  }, [dispatch, active, auction.sfid]);
  useEffect(() => {
    !notdisabled && setActive("1");
    // eslint-disable-next-line
  }, [auction]);
  return (
    <React.Fragment>
      {auction && auction.EndingTender && (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            position: "absolute",
            zIndex: 1035,
            backgroundColor: color.bgColor + color.thirtyOpacity,
            cursor: "no-drop",
          }}
        >
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              transform: "translateY(-20%)",
            }}
          >
            <div
              style={{
                padding: 10,
                backgroundColor: color.bgPrimary,
                fontSize: 24,
                fontWeight: 900,
                color: "white",
                position: "fixed",
                textAlign: "center",
              }}
            >
              <div>{t.getString("Tender close info")}</div>
            </div>
          </div>
        </div>
      )}
      {auction && !auction.StartingTender && (
        <div
          style={{
            padding: 10,
            backgroundColor: color.bgPrimary,
            fontSize: 24,
            fontWeight: 900,
            color: "white",
            textAlign: "center",
          }}
        >
          <div>{t.getString("Tender start info")}</div>
          {counter && counter.startingRemainingTime}
        </div>
      )}
      <div className="mainWidth auctionPage">
        <Row>
          <Col md={24} className="headingStyle" style={{ marginBlockEnd: 5 }}>
            {auction.tender_no__c} /{" "}
            {t.getString(auction.Opportunity_Tender_Type__c)}
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div
              className={
                "auctionMenuItem" + (active === "1" ? " activeMenuItem" : "")
              }
              onClick={() => toggleMenu("1")}
              style={{ textAlign: "center" }}
            >
              <span className="auctionMenuItemTitle">
                {t.getString("Tender Informations")}
              </span>
            </div>
          </Col>
          <Col lg={12}>
            <div
              className={
                "auctionMenuItem" + (active === "2" ? " activeMenuItem" : "")
              }
              onClick={() => toggleMenu("2")}
              style={{ textAlign: "center" }}
            >
              <span className="auctionMenuItemTitle">
                {t.getString("Price Table")}
              </span>
            </div>
          </Col>
        </Row>
        <AuctionChatDrawer
          show={questionModal}
          setShow={setquestionModal}
          auction={auction}
        />
        {active === "1" && (
          <DetailTab
            auction={auction}
            setAuction={setAuction}
            counter={counter}
          />
        )}
        {active === "2" && prices && (
          <PriceTab
            setJoin={setJoin}
            counter={counter}
            join={join}
            questionModal={questionModal}
            setquestionModal={setquestionModal}
            auction={auction}
            prices={prices}
            setPrices={setPrices}
            setAuction={setAuction}
            setActive={setActive}
          />
        )}
      </div>
    </React.Fragment>
  );
};
export default PurposeAuction;
