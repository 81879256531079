import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Col, Row } from "rsuite";
import { Footer, Icon } from "../../../components";
import { Helmet } from "react-helmet";
import {
  getClosedIncDescAuctionList,
  getIncDescAuctionList,
} from "../../../redux/autions/saga";
import { FilteredDataParams } from "../../../redux/autions/type";
import {
  getLocalizedAuction,
  getLocalizedHelmet,
} from "../../../redux/selectors";
import { color } from "../../../utils/Properties";
import AuctionCard from "./auctionCard";
import Filter from "./filter";
import "./style.scss";
import { IncDescAuctionDto } from "../../../redux/autions/inc-desc-type";

const IncDescAuctions = () => {
  const helmetStrings = useSelector(getLocalizedHelmet);
  const t = useSelector(getLocalizedAuction);
  const dispatch = useDispatch();
  const history = useHistory();
  const { tab }: { tab: string } = useParams();
  const [active, setActive] = useState(tab); // eslint-disable-next-line
  const [page, setPage] = useState(1); // eslint-disable-next-line
  const [take, setTake] = useState(200); // eslint-disable-next-line
  const [total, setTotal] = useState<number>();
  const [auctions, setAuctions] = useState<IncDescAuctionDto[]>([]);
  const [closeauctions, setcloseAuctions] = useState<IncDescAuctionDto[]>([]);
  const [mainList, setMainList] = useState<IncDescAuctionDto[]>([]);
  const [closemainList, setCloseMainList] = useState<IncDescAuctionDto[]>([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [openClosedFilter, setOpenClosedFilter] = useState(false);
  const [filteredData, setFilteredData] = useState<FilteredDataParams>({});
  const toggleMenu = (tab: string) => {
    setActive(tab);
    return history.push("/auctions/" + tab + "/incdesc");
  };

  useEffect(() => {
    tab === "open" &&
      dispatch(
        getIncDescAuctionList({
          payload: {
            onSuccess: (m, payload, response) => {
              setAuctions(payload ?? []);
              setMainList(payload ?? []);
              setTotal(response.TotalCount);
            },
            onError: () => {},
          },
          params: `?_skip=${(page - 1) * take}&_take=${take}`,
        })
      );
    tab === "close" &&
      dispatch(
        getClosedIncDescAuctionList({
          payload: {
            onSuccess: (m, payload, response) => {
              setcloseAuctions(payload ?? []);
              setCloseMainList(payload ?? []);
              setTotal(response.TotalCount);
            },
            onError: () => {},
          },
          params: `?_skip=${(page - 1) * take}&_take=${take}`,
        })
      );
  }, [dispatch, tab, page, take]);
  const arrayCheck = (array?: any[]) => {
    if (array && array.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  const filterQuery = (filterData: FilteredDataParams) => {
    setFilteredData(filterData);
    console.log(filterData);
    let tenders = mainList;
    if (active === "close") tenders = closemainList;
    if (arrayCheck(filterData.transportertendertype__c)) {
      tenders = tenders.filter((x) =>
        filterData.transportertendertype__c?.some(
          (y) => y === x.transportertendertype__c
        )
      );
    }
    if (filterData.StartDate) {
      tenders = tenders.filter((x) => {
        let start = new Date(x.tenderstartdatetime__c);
        let end = new Date(x.tenderenddatetime__c);
        start.setHours(0, 0, 0, 0);
        end.setHours(0, 0, 0, 0);

        let firstfilter = new Date(
          filterData.StartDate ? filterData.StartDate : ""
        );
        firstfilter.setHours(0, 0, 0, 0);

        let lastfilter = new Date(filterData.EndDate ? filterData.EndDate : "");
        lastfilter.setHours(0, 0, 0, 0);

        return (
          (start >= firstfilter && start <= lastfilter) ||
          (end >= firstfilter && end <= lastfilter)
        );
      });
    }

    if (active === "close") setcloseAuctions(tenders);
    else setAuctions(tenders);
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>{helmetStrings.getString("TenderHelmet")}</title>
      </Helmet>
      <div className="mainWidth auctionPage">
        <Row>
          <Col md={12}>
            <div
              className={
                "auctionMenuItem" + (active === "open" ? " activeMenuItem" : "")
              }
            >
              <Row>
                <Col md={2} onClick={() => setOpenFilter(!openFilter)}>
                  <Icon icon="preferrences" size={24} color={color.primary} />
                </Col>
                <Col
                  md={22}
                  style={{ textAlign: "center" }}
                  onClick={() => toggleMenu("open")}
                >
                  <span className="auctionMenuItemTitle">
                    {t.getString("Opened Tenders")}
                  </span>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={12}>
            <div
              className={
                "auctionMenuItem" +
                (active === "close" ? " activeMenuItem" : "")
              }
            >
              <Row>
                <Col
                  md={2}
                  onClick={() => setOpenClosedFilter(!openClosedFilter)}
                >
                  <Icon icon="preferrences" size={24} color={color.primary} />
                </Col>
                <Col
                  md={22}
                  style={{ textAlign: "center" }}
                  onClick={() => toggleMenu("close")}
                >
                  <span className="auctionMenuItemTitle">
                    {t.getString("Closed Tenders")}
                  </span>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        {tab === "open" && (
          <Filter
            filteredData={filteredData}
            setFilteredData={filterQuery}
            searchTenders={auctions}
            open={openFilter}
            onHide={() => setOpenFilter(false)}
            tenders={mainList}
            setTenders={setAuctions}
          />
        )}
        {tab === "close" && (
          <Filter
            filteredData={filteredData}
            setFilteredData={filterQuery}
            searchTenders={closeauctions}
            open={openClosedFilter}
            onHide={() => setOpenClosedFilter(false)}
            tenders={closemainList}
            setTenders={setcloseAuctions}
          />
        )}
        <Row>
          <Col md={24}>
            <div className="auctionCardList">
              {tab === "open" && (
                <>
                  {auctions?.map((x, i) => (
                    <AuctionCard
                      isOpen={true}
                      auction={x}
                      key={i}
                      refreshList={() =>
                        dispatch(
                          getIncDescAuctionList({
                            payload: {
                              onSuccess: (m, payload, response) => {
                                setAuctions(payload ?? []);
                                setMainList(payload ?? []);
                                setTotal(response.TotalCount);
                              },
                              onError: () => {},
                            },
                            params: `?_skip=${(page - 1) * take}&_take=${take}`,
                          })
                        )
                      }
                    />
                  ))}
                </>
              )}
              {tab === "close" && (
                <>
                  {closeauctions?.map((x, i) => (
                    <AuctionCard auction={x} key={i} isOpen={false} />
                  ))}
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>

      <Footer />
    </React.Fragment>
  );
};
export default IncDescAuctions;
