const images = {
  profile1: require("./profile1.png").default,
  profile2: require("./profile2.png").default,
  profile3: require("./profile3.png").default,
  profile4: require("./profile4.png").default,
  profile5: require("./profile5.png").default,
  profile6: require("./profile6.png").default,
  profile7: require("./profile7.png").default,
  profile8: require("./profile8.png").default,
  profile9: require("./profile9.png").default,
  badgeGold: require("./badge-gold.png").default,
  noaccess: require("./noaccess.png").default,
  useravatar: require("./useravatar.png").default,
  loadingDots: require("./loadingdots.gif").default,
  editprofile: require("./editprofile.png").default,
  rejected: require("./rejected.png").default,
  comingsoon: require("./comingsoon.png").default,
};

export default images;
