export const baseUrl = "https://sanalsinifim.com.tr/";
export const chatUrl = "https://sanalsinifim.com.tr/";
export const webImageUrl =
  "https://rapidsandbox.s3.eu-central-1.amazonaws.com/";
export const mobileImageUrl = baseUrl + "images/";

export const GOOGLE_MAPS_API_KEY = "AIzaSyA6iyq_m5c3tclVvVRY36ERlARovUvaAXs";
export const color = {
  primary: "#0B5CAB",
  primarySofter: "#095197",
  secondary: "#F7F7F7",
  side: "#00A3E0",
  side2: "#005EA8",
  side3: "#0B827C",
  accent: "#FFFFFF",
  black: "#181818",
  blackFifty: "#18181880",
  pageBgMainColor: "#ffffff",
  pageBgColor: "#fcfcfc",
  bgColor: "#181818",
  bgPrimary: "#0B5CAB",
  bgPrimarySoft: "#FEEBEB",
  bgSecondary: "#B7B7B733",
  bgSoft: "#F7F7F7",
  borderColor: "#F0F0F0",
  borderSecondary: "#D8D8D8",
  borderSide: "#E4E8EE",
  error: "#C63235",
  success: "#37A75B",
  warning: "#fccf00",
  warningSoft: "#FFE56E",
  primarySoft: "#FFF7EB",
  errorSoft: "#F7E2E1",
  successSoft: "#F2FFF6",
  textHeader: "#032D60",
  textPrimary: "#181818",
  textSecondary: "#757575",
  textSemi: "#e5e5e5",
  textAccent: "#FFFFFF",
  textSide: "#009FD7",
  transparent: "transparent",
  backdropTransparency: "CC",
  fiftyOpacity: "80",
  thirtyOpacity: "48",
  available: "#0B827C",
  lessAvailable: "#FFF03F",
  notAvailable: "#C63235",
};
export function isEmpty(obj: any) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}
export const SERVER_DATE_FORMAT = "LLL";
export const getFormData = (data: Object): FormData => {
  console.log(data, "OBJECT");
  let formData = new FormData();
  Object.keys(data).forEach((key) => {
    const val = (data as any)[key];
    if (Array.isArray(val)) {
      val.forEach((v, i) => {
        if (Object.keys(v).length === 0) {
          formData.append(`${key}`, v);
        } else {
          if (typeof v === "object") {
            Object.keys(v).forEach((k) =>
              formData.append(`${key}[${i}].${k}`, v[k])
            );
          } else {
            formData.append(`${key}[${i}]`, v);
          }
        }
      });
    } else if (typeof val === "object" && !(val instanceof File)) {
      Object.keys(val).forEach((k) => formData.append(`${key}.${k}`, val[k]));
    } else formData.append(key, val);
  });
  console.log(formData, "formData");
  return formData;
};
export const getPreferredLanguage = () => {
  const lang =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    "en";
  return lang;
};
export function generateUUID() {
  // Public Domain/MIT
  var d = new Date().getTime(); //Timestamp
  var d2 =
    (typeof performance !== "undefined" &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}
export const articleKeys = {
  whoWeAre: "??whoweare",
};

export const acceptAllImages = "image/*";

export const goUnfilledInputPage = (errors: any) => {
  let keys = Object.keys(errors);
  if (keys.length > 0) {
    const selector = keys[0];
    return selector;
  } else {
    return "";
  }
};

const Calendar = {
  sunday: "Pz",
  monday: "Mo",
  tuesday: "Tu",
  wednesday: "We",
  thursday: "Th",
  friday: "Cm",
  saturday: "Sa",
  ok: "OK",
  today: "Today",
  yesterday: "Yesterday",
  hours: "Hours",
  minutes: "Minutes",
  seconds: "Seconds",
  /**
   * Format of the string is based on Unicode Technical Standard #35:
   * https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
   **/
  formattedMonthPattern: "MMM YYYY",
  formattedDayPattern: "DD MMM YYYY",
};

export const RsTRLocales = {
  Pagination: {
    more: "More",
    prev: "Previous",
    next: "Next",
    first: "First",
    last: "Last",
  },
  Table: {
    emptyMessage: "No data found",
    loading: "Yükleniyor...",
  },
  TablePagination: {
    lengthMenuInfo: "{0} / sayfa",
    totalInfo: "Toplam: {0}",
  },
  Calendar,
  DatePicker: {
    ...Calendar,
  },
  DateRangePicker: {
    ...Calendar,
    last7Days: "Last 7 Days",
  },
  Picker: {
    noResultsText: "No results found",
    placeholder: "Select",
    searchPlaceholder: "Search",
    checkAll: "All",
  },
  InputPicker: {
    newItem: "New item",
    createOption: 'Create option "{0}"',
  },
  Uploader: {
    inited: "Initial",
    progress: "Uploading",
    error: "Error",
    complete: "Finished",
    emptyFile: "Empty",
    upload: "Upload",
  },
};

export const resultStatus = (CODE: number): boolean => {
  switch (CODE) {
    case 0: // NoRecord = 0
    case 1: //NewRecordAdded = 1
    case 2: //RecordUpdated = 2
    case 3: //RecordDeleted = 3
    case 9: //QuerySuccess = 9
    case 6: //SystemErrorOccured = 6
    case 17: //ExpiredLoginAllowed = 17
    case 18: //ExpiredLoginAllowed = 18
    case 19: //PasswordChanged = 19
      return true;
    case 4: //ErrorOccured = 4
    case 5: //WrongLogin = 5
    case 7: //SameRecordNotAdded = 7
    case 8: //CitizenUINValidationError = 8
    case 10: //QueryError = 10
    case 11: //Unauthorized = 11
    case 12: //CantDeleteHasSubRows = 12
    case 13: //CantDeleteImage = 13
    case 14: //CantUpdateImage = 14
    case 15: //SessionTimeout = 15
    case 16: //PackageExpired = 16
    default:
      return false;
  }
};

export const createMapOptions = (maps: any) => {
  return {
    styles: CUSTOM_MAP,
  };
};

export const mapContainerStyles = {
  width: "100%",
  height: "100%",
};

export const mapOptions = {
  disableDefaultUI: true,
  zoomControl: true,
};

export const CUSTOM_MAP: google.maps.MapTypeStyle[] = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#c9c9c9",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
];
