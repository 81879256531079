import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Alert, Form, FormGroup } from "rsuite";
import { Button, Icon, Input } from "../../../components";
import { getLocalizedAuth, getLocalizedErrors } from "../../../redux/selectors";
import { color } from "../../../utils/Properties";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  loginAction,
  setAuthenticate,
  setUser,
  getAccountTypes,
  getCommunicationLanguages,
} from "../../../redux/actions";
import {
  AccountTypeParams,
  CommunicationLanguageObject,
  LoginParams,
} from "../../../types";
import RegistrationFormModal from "../../../components/Navbar/registration-form-modal";
import useStorage from "../../../utils/useStorage";

type LoginProps = {
  activePage: number;
  onClick: (active: number) => void;
  callbackUrl?: string;
};

const Login = ({ activePage, onClick, callbackUrl }: LoginProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const strings = useSelector(getLocalizedAuth);
  const errorStrings = useSelector(getLocalizedErrors);
  const { toggle } = useStorage(); // eslint-disable-next-line
  const [logged, setLogged] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [accountType, setAccountType] = useState<AccountTypeParams[]>();
  const [comLang, setComLang] = useState<CommunicationLanguageObject[]>();

  useEffect(() => {
    toggle(logged); // eslint-disable-next-line
  }, [logged]);
  const LoginSchema = Yup.object().shape({
    email: Yup.string().required(errorStrings.getString("required")),
    password: Yup.string().required(errorStrings.getString("required")),
  });

  useEffect(() => {
    if (history.action === "POP") {
      dispatch(setAuthenticate(false));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const onLoginPress = ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    const input: LoginParams = { userName: email, password: password };
    dispatch(
      loginAction({
        params: input,
        onSuccess: (message: string, payload: any) => {
          dispatch(setAuthenticate(true));
          dispatch(setUser(payload));
          Alert.success(errorStrings.getString("success"), 5000);
          if (
            callbackUrl &&
            (!callbackUrl.includes("forgot") ||
              !callbackUrl.includes("change-password") ||
              !callbackUrl.includes("email-confirmed"))
          ) {
            history.push(callbackUrl);
          } else {
            history.push("/");
          }
        },
        onError: (message: string) => {
          Alert.error(message, 5000);
        },
      })
    );
  };

  return (
    <>
      <RegistrationFormModal
        show={show}
        onHide={() => setShow(false)}
        types={accountType || []}
        comLang={comLang}
      />

      <div className="loginContent">
        <div
          className="standardText"
          style={{
            display: "flex",
            cursor: "pointer",
            lineHeight: "18px",
            fontSize: 12,
            marginBlockEnd: "16.2%",
            width: "max-content",
            color: color.textHeader,
          }}
          onClick={() => {
            dispatch(setAuthenticate(false));
            history.push("/");
          }}
        >
          <Icon
            icon="left"
            size={12}
            color={color.black}
            style={{ marginInlineEnd: 10, marginBlockStart: 3 }}
          />
          {strings.getString("back_to_homepage")}
        </div>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={LoginSchema}
          onSubmit={onLoginPress}
        >
          {({ handleChange, handleBlur, handleSubmit, touched, errors }) => (
            <Form>
              <h4
                style={{
                  fontWeight: 700,
                  fontSize: 36,
                  lineHeight: "60px",
                  fontStyle: "normal",
                  color: color.textPrimary,
                }}
              >
                {strings.getString("welcome")}
              </h4>
              <p
                className="bigRegularText"
                style={{
                  fontSize: 20,
                  color: color.textPrimary,
                  marginBlockEnd: "12%",
                }}
              >
                {strings.getString("motto")}
              </p>
              <Input
                label={strings.getString("email")}
                placeholder={strings.getString("email_placeholder")}
                name="email"
                onChange={handleChange("email")}
                onBlur={handleBlur("email")}
                error={errors.email}
                touched={touched.email}
              />
              <Input
                label={strings.getString("password")}
                placeholder={strings.getString("password_placeholder")}
                type="password"
                name="password"
                onChange={handleChange("password")}
                onBlur={handleBlur("password")}
                error={errors.password}
                touched={touched.password}
              />

              <FormGroup>
                <Button
                  title={strings.getString("login").toUpperCase()}
                  icon="forward"
                  iconPosition="end"
                  style={{ marginBlockEnd: "7.2%", paddingBlock: 16 }}
                  onClick={handleSubmit}
                />
              </FormGroup>
              <p className="standardText">
                {strings.getString("no_account")}
                <span
                  style={{ color: color.primary, cursor: "pointer" }}
                  onClick={() => {
                    dispatch(
                      getAccountTypes({
                        payload: {
                          onSuccess: (message, payload) => {
                            setAccountType(payload);
                          },
                          onError: (message) => {},
                        },
                        url: "System/GetAccountTypes",
                      })
                    );
                    dispatch(
                      getCommunicationLanguages({
                        payload: {
                          onSuccess: (message, payload) => {
                            setComLang(payload);
                          },
                          onError: (message) => {},
                        },
                        url: "System/GetCommunicationLanguage",
                      })
                    );
                    setShow(true);
                  }}
                >
                  {strings.getString("register")}
                </span>
              </p>
              <p
                className="boldText"
                style={{
                  color: color.primary,
                  textDecorationLine: "underline",
                  cursor: "pointer",
                  width: "max-content",
                }}
                onClick={() => {
                  onClick(2);
                  history.push("forgot-password");
                }}
              >
                {strings.getString("forgot_password")}
              </p>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default Login;
