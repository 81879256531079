const icons = {
  box3d: require("./box3d.svg").default,
  flatbed: require("./flatbed.png").default,
  logo: require("./logo.png").default,
  rapid: require("./rapid.jpg").default,
  raplak: require("./raplak.jpg").default,
  m1: require("./m1.png").default,
  mega: require("./mega.png").default,
  profileAvatar: require("./profile-avatar.svg").default,
  refrigerator: require("./refrigerator.png").default,
  swapContainer: require("./swap-container.png").default,
  tautlinerBox: require("./tautliner-box.png").default,
  truck3d: require("./truck3d.png").default,
  truckTautlinerBox: require("./truck-tautliner-box.svg").default,
  LeadAccount: require("./LeadAccount.png").default,
  Candidate_Forwarder: require("./Candidate_Forwarder.png").default,
  LeadTransporter: require("./LeadTransporter.png").default,
  arrow: require("./arrow.png").default,
  registerResponsiveImage: require("./registerResponsiveImage.png").default,
};

export default icons;
