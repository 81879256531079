import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Drawer, Form } from "rsuite";
import { Formik } from "formik";
import * as Yup from "yup";
import { Icon, Input } from "..";
import { chatUrl, color } from "../../utils/Properties";
import {
  getApiParams,
  getLocalizedChat,
  getUserState,
} from "../../redux/selectors";
import { images } from "../../assets";
import "./style.css";
import moment from "moment";
import ScrollBar from "react-perfect-scrollbar";
import withDirection, {
  DIRECTIONS,
  //@ts-ignore
} from "react-with-direction";
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import {
  ChatSendObject,
  ContractMessage,
  formatChatMessageReceive,
} from "./utils";
import { getChatDetails } from "../../redux/profile/profileSaga";
import Loader from "../Loader";
import { useHistory, useLocation } from "react-router-dom";
import { getCurrency } from "../../utils/Helpers";

type ChatDrawerProps = {
  show: boolean;
  setShow: (show: boolean) => void;
  truck: any;
  setTruckUndefined: () => void;
  direction: any;
};
export interface ChatMessageReceiveObject {
  id: string;
  message: string;
  userId: string;
  createTime: string;
  profileImage: string;
  read: boolean;
  userName: string;
  opportunity_destination__c: string;
}
const AlwaysScrollToBottom = () => {
  const elementRef = useRef(null);
  useEffect(() =>
    //@ts-ignore
    elementRef.current.scrollIntoView()
  );
  return <div ref={elementRef} />;
};
const HUB_ENDPOINT = chatUrl + "api/chat";
const ChatDrawer = ({ truck, show, setShow, direction }: ChatDrawerProps) => {
  const user = useSelector(getUserState);
  const messagesEndRef = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const chatActive = useRef(false);
  const strings = useSelector(getLocalizedChat);
  const [loader, setLoader] = useState(false);
  const ChatSchema = Yup.object().shape({ message: Yup.string().min(1) }); // eslint-disable-next-line
  const [message, setMessage] = useState<string>("");
  const [messages, setMessages] = useState<ContractMessage[]>([]);
  const { token, lang } = useSelector(getApiParams);
  const connectionRef = useRef<HubConnection | null>(null);
  const dispatch = useDispatch();
  const [connectionStatus, setConnectionStatus] = useState<
    "offline" | "connecting" | "connected" | "failed"
  >("offline");

  useEffect(() => {
    if (truck && truck.id) {
      try {
        connectionRef.current?.stop().then(() => {
          console.log("CONNECTION STOPPED", truck.id);
          connectionRef.current = null;
        });
      } catch (e) {
        console.log("ERROR UNMOUNT", e);
      }
      chatActive.current = true;
      setLoader(true);
      dispatch(
        getChatDetails({
          payload: {
            onSuccess: (message, payload) => {
              if (payload) {
                setMessages(JSON.parse(payload[0].messagejson__c));
                connectChat();
              } else {
                setMessages([]);
                connectChat();
              }
            },
            onError: (message) => {},
          },
          url: "/Profile/GetChats/" + truck?.id,
        })
      );
    } // eslint-disable-next-line
  }, [truck?.id]);
  const connectChat = () => {
    if (connectionRef.current === null && token && lang) {
      setConnectionStatus("connecting");
      const connection = new HubConnectionBuilder()
        .withUrl(HUB_ENDPOINT, {
          headers: { "Accept-Language": `${lang}` },
          accessTokenFactory: () => token,
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Debug)
        .build();
      connection.serverTimeoutInMilliseconds = 25000;
      console.log(`Trying to connect to ${HUB_ENDPOINT}`);
      connection
        .start()
        .then(() => {
          console.log(`CHAT Connected to ${HUB_ENDPOINT}`);
          setConnectionStatus("connected");
          setLoader(false);
        })
        .catch((err: any) => {
          setConnectionStatus("failed");
          console.log(`Error starting the CHAT connection: ${err.toString()}`);
        });
      connection.onclose(async () => {
        console.log(`Disconnected from ${HUB_ENDPOINT}`);
      });
      connection.on(
        truck.id + user?.UserID,
        function (payload: ChatMessageReceiveObject) {
          console.log("ReceiveMessage", truck?.id, payload);
          setMessages((state) => [...state, formatChatMessageReceive(payload)]);
          scrollToBottom();
        }
      );
      connectionRef.current = connection;
      setConnectionStatus("connected");
    } else console.log("CONNECTION ALREADY ESTABLIESHED");
  };
  const onMessageSend = async (
    values: any,
    { resetForm }: { resetForm: any }
  ) => {
    if (connectionRef.current !== null) {
      try {
        const serverObject: ChatSendObject = {
          opportunity_destination__c: truck?.id,
          contact__c: user?.UserID,
          user__c: truck.rapidadvisorId,
          Message: values.message,
        };

        const result = await connectionRef.current?.invoke(
          "SendMessage",
          serverObject
        );
        console.log("SendMessage", result);
        resetForm();
      } catch (err: any) {
        console.log(
          `Error sending ${message} from${user?.FirstName} ${user?.LastName}: ${err.message} ${connectionStatus}`
        );
      }
    }
  };

  const scrollToBottom = () => {
    //@ts-ignore
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  return (
    <Drawer
      className="vehicle-chat-drawer"
      placement={direction === DIRECTIONS.LTR ? "right" : "left"}
      show={show}
      onHide={() => {
        const array = new URLSearchParams(location.search);
        const id = array.get("chatId");
        history.push(location.search.replace("&chatId=" + id, ""));
        setShow(false);
      }}
    >
      {truck && (
        <>
          <Drawer.Header>
            <Drawer.Title
              className="biggerHeadingStyle"
              style={{
                textAlign: "center",
                color: color.textHeader,
                marginBlockEnd: 15,
                fontSize: 14,
              }}
            >
              {strings.getString("chat")}
            </Drawer.Title>
            <div
              className="vehicle-chat-drawer-card"
              style={{
                width: `calc(100% + 16px)`,
                border: "1px solid",
                borderColor: color.borderSide,
                borderRadius: 6,
                boxShadow: "20px 20px 50px #0047A412",
                paddingInline: 30,
                paddingBlock: 10,
              }}
            >
              <div
                className="chatHeaderCard"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{ width: 67, height: 67, borderRadius: 67 }}
                  className="chatHeaderCardImg"
                >
                  <img
                    className="imageStyles"
                    style={{ borderRadius: 67 }}
                    src={truck?.Owner.profileimage}
                    alt=""
                  />
                </div>
                <div
                  className="smallHeadingStyle"
                  style={{
                    fontWeight: 700,
                    color: color.textHeader,
                    marginBlockEnd: 5,
                    width: 390,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <p
                    className="smallHeadingStyle"
                    style={{ color: color.textHeader, marginBlockEnd: 0 }}
                  >
                    {truck?.Owner.name}
                  </p>
                  <div style={{ width: "100%", height: 40, display: "flex" }}>
                    <div>
                      <span
                        className="smallText"
                        style={{
                          color: color.textHeader + color.fiftyOpacity,
                        }}
                      >
                        {strings.getString("File Number")}
                      </span>
                      <p className="smallBoldText headingColor">
                        {truck?.filenumber}
                      </p>
                    </div>
                    <Divider
                      style={{ height: 35, marginBlockStart: 5 }}
                      vertical
                    />
                    <div>
                      <span
                        className="smallText"
                        style={{
                          color: color.textHeader + color.fiftyOpacity,
                        }}
                      >
                        {strings.getString("load_type")}
                      </span>
                      <p className="smallBoldText headingColor">
                        {truck.loadtype}
                      </p>
                    </div>
                    <Divider
                      style={{ height: 35, marginBlockStart: 5 }}
                      vertical
                    />
                    <div>
                      <span
                        className="smallText"
                        style={{
                          color: color.textHeader + color.fiftyOpacity,
                        }}
                      >
                        {strings.getString("truck_type")}
                      </span>
                      <p className="smallBoldText headingColor">
                        {truck.trucktype}
                      </p>
                    </div>{" "}
                    <Divider
                      style={{ height: 35, marginBlockStart: 5 }}
                      vertical
                    />
                    <div>
                      <span
                        className="smallText"
                        style={{
                          color: color.textHeader + color.fiftyOpacity,
                        }}
                      >
                        {strings.getString("estimated_amount")}
                      </span>
                      <p className="smallBoldText headingColor">
                        {truck.currencyisocode
                          ? getCurrency(
                              truck.estimatedamount,
                              truck.currencyisocode
                            )
                          : truck.estimatedamount}
                      </p>
                    </div>
                    <Divider
                      style={{ height: 35, marginBlockStart: 5 }}
                      vertical
                    />
                    <div>
                      <span
                        className="smallText"
                        style={{
                          color: color.textHeader + color.fiftyOpacity,
                        }}
                      >
                        {strings.getString("weight")}
                      </span>
                      <p className="smallBoldText headingColor">
                        {truck?.weight ? truck?.weight + "t" : "-"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>{" "}
              <p className="smallText" style={{ textAlign: "left" }}>
                {truck?.postedat
                  ? moment(truck?.postedat).startOf("day").fromNow()
                  : "-"}
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <Divider style={{ marginBlock: 10 }} />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="smallHeadingStyle"
                      style={{
                        fontWeight: 700,
                        color: color.textHeader,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <p className="smallBoldText headingColor">
                        {truck.fromcountryid ? truck.fromcountryid : "-"}
                      </p>
                      <Icon
                        icon="right-bold"
                        size={13}
                        style={{ marginInline: 5, marginBlockStart: 1 }}
                      />
                      <p className="smallBoldText headingColor">
                        {truck.tocountryid ? truck.tocountryid : "-"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBlockStart: 5,
              }}
            >
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    width: 35,
                    height: 35,
                    border: "2px solid",
                    borderColor: color.accent,
                    borderRadius: 25,
                    marginInlineEnd: -5,
                  }}
                >
                  <img
                    className="imageStyles"
                    style={{ borderRadius: 25 }}
                    src={truck?.Owner.profileimage}
                    alt=""
                  />
                </div>
                <div
                  style={{
                    width: 35,
                    height: 35,
                    border: "2px solid",
                    borderColor: color.accent,
                    borderRadius: 25,
                    marginInlineStart: -5,
                  }}
                >
                  <img
                    className="imageStyles"
                    style={{ borderRadius: 25 }}
                    src={images.useravatar}
                    alt=""
                  />
                </div>
              </div>
              <p className="boldText" style={{ color: color.textHeader }}>
                Rapid&{truck?.Owner.name}
              </p>
            </div>
          </Drawer.Header>
          <Drawer.Body
            className="vehicle-chat-card-drawer-body"
            style={{ height: `calc(100% - 360px)`, marginBlock: 15 }}
          >
            {loader && <Loader />}
            <ScrollBar>
              {!loader &&
                messages.map((text, i) => {
                  if (text.UserId !== user?.UserID) {
                    return (
                      <div
                        key={i}
                        style={{
                          marginBlockStart: 10,
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              width: 47,
                              height: 47,
                              border: "2px solid",
                              borderColor: color.accent,
                              borderRadius: 40,
                              marginInlineEnd: 10,
                            }}
                          >
                            <img
                              className="imageStyles"
                              style={{ borderRadius: 25 }}
                              src={truck?.Owner.profileimage}
                              alt=""
                            />
                          </div>
                          <div>
                            <p
                              className="xsmallHeadingStyle"
                              style={{
                                color: color.textHeader,
                                marginBlockEnd: 0,
                              }}
                            >
                              {text.UserName}
                            </p>
                            <span className="smallText">
                              {moment(text.CreateTime).format(
                                "DD/MM/YY HH:mm:ss"
                              )}
                            </span>
                          </div>
                        </div>
                        <div
                          className="managerMessagebox standardText"
                          style={{
                            borderColor: color.borderSide,
                            backgroundColor: color.accent,
                          }}
                        >
                          {text.Message}
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={i}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                          marginBlockStart: 10,
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div>
                            <p
                              className="xsmallHeadingStyle"
                              style={{
                                color: color.textHeader,
                                marginBlockEnd: 0,
                              }}
                            >
                              {text.UserName}
                            </p>
                            <span className="smallText ">
                              {moment(text.CreateTime).format(
                                "DD/MM/YY HH:mm:ss"
                              )}
                            </span>
                          </div>
                          <div
                            style={{
                              width: 47,
                              height: 47,
                              border: "2px solid",
                              borderColor: color.accent,
                              borderRadius: 40,
                              marginInlineStart: 10,
                            }}
                          >
                            <img
                              className="imageStyles"
                              style={{ borderRadius: 25 }}
                              src={images.useravatar}
                              alt=""
                            />
                          </div>
                        </div>
                        <div
                          className="customerMessagebox standardText"
                          style={{
                            borderColor: color.borderSide,
                            backgroundColor: color.borderSide,
                          }}
                        >
                          {text.Message}
                        </div>
                      </div>
                    );
                  }
                })}
              <div ref={messagesEndRef} />
              <AlwaysScrollToBottom />
            </ScrollBar>
          </Drawer.Body>
          <Drawer.Footer>
            <Formik
              initialValues={{
                message: "",
              }}
              initialErrors={{ message: " " }}
              validationSchema={ChatSchema}
              onSubmit={onMessageSend}
            >
              {({ handleSubmit, handleChange, handleBlur, values }) => {
                return (
                  <Form
                    style={{
                      width: "100%",
                      display: "flex",
                    }}
                  >
                    {direction === DIRECTIONS.RTL && (
                      <div
                        onClick={() => handleSubmit()}
                        style={{ marginInlineEnd: 15, cursor: "pointer" }}
                      >
                        <Icon
                          icon="send"
                          size={48}
                          style={{ transform: `rotate(180deg)` }}
                        />
                      </div>
                    )}
                    <div style={{ width: `calc(100% - 65px)` }}>
                      <Input
                        name="firstName0"
                        value={values.message}
                        onChange={handleChange("message")}
                        onBlur={handleBlur("message")}
                        style={{ paddingBlock: 13 }}
                        autoFocus
                        onPressEnter={() => handleSubmit()}
                      />
                    </div>
                    {direction === DIRECTIONS.LTR && (
                      <div
                        onClick={() => handleSubmit()}
                        style={{ marginInlineStart: 15, cursor: "pointer" }}
                      >
                        <Icon icon="send" size={48} />
                      </div>
                    )}
                  </Form>
                );
              }}
            </Formik>
          </Drawer.Footer>
        </>
      )}
    </Drawer>
  );
};

export default withDirection(ChatDrawer);
