import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import {
  LoadOpportunities,
  SagaActionParams,
  SagaGenericParams,
} from "../../types";
import { getListSaga, getListWithoutLoaderSaga } from "../sagas/getSaga";
import * as T from "./type";
import { PostSagaGenericParams, postSaga, putSaga } from "../sagas/postsaga";

export const getCustomerContracts = createAction(
  "GET_CUSTOMER_CONTRACTS_LIST",
  ({ payload, params }: SagaActionParams<T.CustomerContractDto[]>) => ({
    payload,
    url: "CustomerContracts/GetCustomerContracts" + (params ? params : ""),
  })
);

export const updateCustomerContracts = createAction(
  "UPDATE_CUSTOMER_CONTRACTS",
  ({ payload, body }: SagaActionParams<any>) => ({
    payload,
    body,
    url: "CustomerContracts/UpdateCustomerContractLoad",
  })
);
export const getCustomerContractDetails = createAction(
  "GET_CUSTOMER_CONTRACTS_DETAILS",
  ({ payload, id }: SagaActionParams<T.CustomerContractDetailDto[]>) => ({
    payload,
    url: "CustomerContracts/GetCustomerContractLoads/" + id,
  })
);
export const getCustomerContractVehicleDetails = createAction(
  "GET_CUSTOMER_CONTRACT_VEHICLES_LIST",
  ({ payload, id }: SagaActionParams<T.CustomerContractVehicleDto[]>) => ({
    payload,
    url: "CustomerContracts/GetVehicles/" + id,
  })
);
export const getCustomerLoadOpportunities = createAction(
  "GET_CUSTOMER_CONTRACT_LOAD_OPPURTUNITY_DETAIL",
  ({ payload, url }: SagaActionParams<LoadOpportunities[]>) => ({
    payload,
    url,
  })
);
export const getLoadingAddress = createAction(
  "GET_LOADING_ADDRESS_LIST",
  ({ payload, params }: SagaActionParams<T.LoadingAddressDto[]>) => ({
    payload,
    url: "/CustomerContracts/GetLoadingAddress" + (params ? params : ""),
  })
);
export const getUnloadingAddress = createAction(
  "GET_UNLOADING_ADDRESS_LIST",
  ({ payload, params }: SagaActionParams<T.LoadingAddressDto[]>) => ({
    payload,
    url: "/CustomerContracts/GetUnloadingAddress" + (params ? params : ""),
  })
);

export const getCustomerContractCustoms = createAction(
  "GET_CUSTOMER_CONTRACT_CUSTOM_LIST",
  ({ payload, url }: SagaActionParams<T.CustomerContractCustomDto[]>) => ({
    payload,
    url: url,
  })
);
export const createLoadingAddress = createAction(
  "CREATE_LOADING_ADDRESS",
  ({ payload, body }: SagaActionParams<T.LoadingAddressDto>) => ({
    payload,
    body,
    url: "CustomerContracts/AddLoadingAddress",
  })
);
export const createUnLoadingAddress = createAction(
  "CREATE_UNLOADING_ADDRESS",
  ({ payload, body }: SagaActionParams<T.UnLoadingAddressDto>) => ({
    payload,
    body,
    url: "CustomerContracts/AddUnloadingAddress",
  })
);

export const updateAddress = createAction(
  "UPDATE_ADDRESS",
  ({ payload, body }: SagaActionParams<any>) => ({
    payload,
    body,
    url: "CustomerContracts/UpdateAddress",
  })
);

export const getCustomerContract = createAction(
  "GET_CUSTOMER_CONTRACT",
  ({ payload, id }: SagaActionParams<T.CustomerAgreementDto>) => ({
    payload,
    url: "CustomerContracts/GetAgreement/" + id,
  })
);
export const uploadCustomerContract = createAction(
  "UPLOAD_CUSTOMER_CONTRACT",
  ({ payload, body }: SagaActionParams<string>) => ({
    payload,
    body,
    url: "/CustomerContracts/UploadSignatureAgreement",
  })
);

export const getTransporterContract = createAction(
  "GET_TRANSPORTER_CONTRACT",
  ({ payload, id }: SagaActionParams<T.CustomerAgreementDto>) => ({
    payload,
    url: "TransporterContracts/GetAgreement/" + id,
  })
);
export const uploadTransporterContract = createAction(
  "UPLOAD_TRANSPORTER_CONTRACT",
  ({ payload, body }: SagaActionParams<string>) => ({
    payload,
    body,
    url: "TransporterContracts/UploadSignatureAgreement",
  })
);
const customerContractsSagas = [
  takeLatest(
    getCustomerContract.toString(),
    (payload: SagaGenericParams<any[]>) => getListSaga<any[]>(payload)
  ),
  takeLatest(
    getTransporterContract.toString(),
    (payload: SagaGenericParams<any[]>) => getListSaga<any[]>(payload)
  ),
  takeLatest(
    uploadCustomerContract.toString(),
    (payload: PostSagaGenericParams<any[]>) => postSaga<any[]>(payload)
  ),
  takeLatest(
    uploadTransporterContract.toString(),
    (payload: PostSagaGenericParams<any[]>) => postSaga<any[]>(payload)
  ),
  takeLatest(
    getCustomerLoadOpportunities.toString(),
    (payload: SagaGenericParams<any[]>) => getListSaga<any[]>(payload)
  ),
  takeLatest(
    getCustomerContracts.toString(),
    (payload: SagaGenericParams<any[]>) => getListSaga<any[]>(payload)
  ),
  takeLatest(
    getCustomerContractDetails.toString(),
    (payload: SagaGenericParams<any[]>) => getListSaga<any[]>(payload)
  ),
  takeLatest(
    getCustomerContractVehicleDetails.toString(),
    (payload: SagaGenericParams<any[]>) => getListSaga<any[]>(payload)
  ),
  takeLatest(
    getLoadingAddress.toString(),
    (payload: SagaGenericParams<any[]>) => getListSaga<any[]>(payload)
  ),
  takeLatest(
    getUnloadingAddress.toString(),
    (payload: SagaGenericParams<any[]>) => getListSaga<any[]>(payload)
  ),
  takeLatest(
    getCustomerContractCustoms.toString(),
    (payload: SagaGenericParams<any[]>) =>
      getListWithoutLoaderSaga<any[]>(payload)
  ),

  takeLatest(
    createLoadingAddress.toString(),
    (payload: PostSagaGenericParams<any>) => postSaga<any>(payload)
  ),
  takeLatest(
    createUnLoadingAddress.toString(),
    (payload: PostSagaGenericParams<any>) => postSaga<any>(payload)
  ),
  takeLatest(updateAddress.toString(), (payload: PostSagaGenericParams<any>) =>
    putSaga<any>(payload)
  ),
  takeLatest(
    updateCustomerContracts.toString(),
    (payload: PostSagaGenericParams<any>) => putSaga<any>(payload)
  ),
];
export default customerContractsSagas;
