import { createAction } from "redux-smart-actions";
import { SagaActionParams } from "../../types";
import { SocialMediaLinksObject } from "../../types/components";

export const getSocialMediaLinks = createAction(
  "GET_SOCIAL_MEDIA_LINKS",
  ({ payload, url }: SagaActionParams<SocialMediaLinksObject>) => ({
    payload,
    url,
  })
);
