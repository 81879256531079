import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { getUserState } from "../../../redux/selectors";

interface AuthLayoutProps {
  component: any;
  isAuthProtected: any;
  isProfileRedirect?: boolean;
  path?: string;
  exact?: boolean;
  roles?: string[];
  key?: number;
}

const Authmiddleware = ({
  component,
  isAuthProtected,
  path,
  exact,
  key,
  roles = [],
  isProfileRedirect = false,
  ...rest
}: AuthLayoutProps) => {
  const Component = component;
  const user = useSelector(getUserState);

  return (
    <Route
      exact
      path={path}
      {...rest}
      render={(props) => {
        if (isAuthProtected && !user) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        } else if (
          user &&
          roles &&
          roles.length > 0 &&
          !roles.some((x) => x === user.AccountType.Code)
        ) {
          return (
            <Redirect
              to={{ pathname: "/authError", state: { from: props.location } }}
            />
          );
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};

export default Authmiddleware;
