import { forwardRef, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GoogleMap, DirectionsRenderer } from "@react-google-maps/api";
import moment from "moment";
import Flag from "react-flagkit";
import {
  getLocalizedLoadCard,
  getLocalizedMyProfile,
} from "../../redux/selectors";
import { color, CUSTOM_MAP, mapContainerStyles } from "../../utils/Properties";
import { LoadOpportunities, RouteObject } from "../../types";
import { Button, Icon, Loader, Nav, Whisper } from "..";
import "./style.scss";

import { getCurrency } from "../../utils/Helpers";
import ScrollBar from "react-perfect-scrollbar";
import { getLoadOpportunityDetailWithoutLoader } from "../../redux/actions";

type LoadCardProps = {
  width?: any;
  title?: string;
  isOffer?: boolean;
  component?: any;
  useEp?: boolean;
  load: LoadOpportunities;
  cancelOpportunity?: () => void;
};

const ProfileLoadCard = forwardRef<HTMLDivElement, LoadCardProps>(
  (
    { load, useEp, isOffer = true, width, cancelOpportunity, component },
    ref
  ) => {
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const [selected, setSelected] = useState(load);
    const strings = useSelector(getLocalizedLoadCard);
    const profilestrings = useSelector(getLocalizedMyProfile);
    const cardRef = useRef(null);
    const [route_directions, setRouteDirections] = useState<
      google.maps.DirectionsResult[]
    >([]);

    const [show, setShow] = useState(false);
    const [path_distance, setPathDistance] = useState<string>("");
    const [path_distance_index, setPathDistanceIndex] = useState<number>(0);

    const navContent = [
      {
        id: 0,
        icon: "list",
        activeColor: color.primary,
        label: strings.getString("details"),
      },
      {
        id: 1,
        icon: "indicator",
        activeColor: color.primary,
        label: strings.getString("extra_infos"),
      },
      {
        id: 2,
        icon: "shorter-list",
        activeColor: color.primary,
        label: strings.getString("other_details"),
      },
      {
        id: 3,
        icon: "route",
        activeColor: color.primary,
        label: strings.getString("routes"),
      },
      {
        id: 4,
        icon: "map-pin",
        activeColor: color.primary,
        label: strings.getString("map"),
      },
    ];

    const postEndCalculater =
      new Date(
        selected.postendsat ? selected.postendsat : new Date()
      ).getTime() - new Date().getTime();

    function decideColor(i: number) {
      switch (i) {
        case 0:
          return "blue";
        case 1:
          return "orange";
        case 2:
          return "yellow";
        case 3:
          return "black";
        case 4:
          return "pink";
        case 5:
          return "gray";

        default:
          return "red";
      }
    }

    function clickableDirectionServiceResult(data: RouteObject) {
      let waypoints: google.maps.DirectionsWaypoint[] = [];
      if (data.koordinat) {
        JSON.parse(data.koordinat)?.forEach((data: any) => {
          waypoints.push({
            location: new google.maps.LatLng(data.Latitude, data.Longtitude),
            stopover: true,
          });
        });
      }
      setRouteDirections([]);
      getDirectionsServiceResult(waypoints);
    }

    const getDirectionsServiceResult = (
      waypoints?: google.maps.DirectionsWaypoint[]
    ) => {
      if (
        selected.DetailTab &&
        selected.DetailTab.ll1name &&
        selected.DetailTab.ul1name
      ) {
        const DirectionsService = new google.maps.DirectionsService();
        console.log("WAYPOINTS", waypoints);
        DirectionsService.route(
          {
            origin: selected.DetailTab.ll1name,
            destination: selected.DetailTab.ul1name,
            waypoints,
            travelMode: google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            console.log("ROUTE RESULT", result);
            if (status === google.maps.DirectionsStatus.OK) {
              setRouteDirections((rds) => [...rds, result]);
              console.log("LEGS", result.routes);
              let total_distance = 0;
              if ((result?.routes[0]?.legs?.length ?? 0) > 0) {
                result.routes[0].legs.forEach((l) => {
                  total_distance += l.distance.value;
                });
              }
              if (total_distance > 0) {
                setPathDistance(Math.round(total_distance / 1000) + "km");
              } else setPathDistance("");
            } else {
              console.error(`error fetching directions ${result}`);
            }
          }
        );
      }
    };

    return (
      <div key={selected.id} ref={cardRef}>
        <div
          ref={ref}
          className="truckResultCard"
          style={{
            fontSize: 10,
            color: color.textPrimary + color.thirtyOpacity,
            borderColor: show ? color.side3 : "#e6e6e6",
            backgroundColor: color.pageBgColor,
          }}
        >
          {loader && <Loader />}
          {isOffer && (
            <p
              className="boldText"
              style={{ padding: "20px 20px 0px 20px", fontSize: 16 }}
            >
              {strings.getString("Vehicle On Load")}:
            </p>
          )}

          <div style={{ width: "100%", display: "flex" }}>
            <div
              style={{
                marginInlineStart: 10,
                width: `100%`,
                paddingBlock: "12px 2px",
                paddingInline: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: 20,
              }}
            >
              <div
                className="vehicleLogo"
                style={{
                  backgroundColor: color.accent,
                  borderRadius: 10,
                }}
              >
                <img
                  className="imageStyles"
                  style={{ borderRadius: 10 }}
                  src={
                    selected.Owner
                      ? selected.Owner.profileimage
                      : selected.profileimage
                  }
                  alt=""
                />
              </div>
              <div
                className="content smallRegularText"
                style={{ width: width ? width : "unset" }}
              >
                <div>
                  <p className="boldText " style={{ color: color.blackFifty }}>
                    {strings.getString("file_number")}
                  </p>
                  <p>{"#" + selected.opdfilenumber}</p>
                </div>

                <div className="divider-profile-truck-card" />
                <div>
                  <p className="boldText" style={{ color: color.blackFifty }}>
                    {strings.getString("load_type")}
                  </p>
                  <p>
                    {(selected.main_load_type__c ?? "-") +
                      "/" +
                      (selected.loadtype ?? "-")}
                  </p>
                </div>
                <div className="divider-profile-truck-card" />
                <div>
                  <p className="boldText" style={{ color: color.blackFifty }}>
                    {strings.getString("route")}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingBlockStart: 8,
                    }}
                  >
                    <p>
                      {selected.fromcountryid ? selected.fromcountryid : "-"}
                    </p>
                    <Icon
                      icon="right-bold"
                      size={13}
                      style={{ marginInline: 3 }}
                    />
                    <p>{selected.tocountryid ? selected.tocountryid : "-"}</p>
                  </div>
                </div>
                <div className="divider-profile-truck-card" />
                <div>
                  <p className="boldText" style={{ color: color.blackFifty }}>
                    {strings.getString("date")}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingBlockStart: 8,
                    }}
                  >
                    <p>
                      {selected.loadbegindate
                        ? moment(selected.loadbegindate).format("l")
                        : "-"}
                    </p>
                    <Icon
                      icon="right-bold"
                      size={13}
                      style={{ marginInline: 3 }}
                    />
                    <p>
                      {selected.loadenddate
                        ? moment(selected.loadenddate).format("l")
                        : "-"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {!isOffer && cancelOpportunity && !selected.status__c && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginInlineEnd: 10,
                }}
              >
                <Button
                  style={{
                    borderColor: color.error,
                    backgroundColor: color.error,
                    marginInlineStart: 20,
                    width: 100,
                    height: 30,
                  }}
                  title={profilestrings.getString("Cancel Opportunity")}
                  onClick={() => {
                    cancelOpportunity();
                  }}
                />
              </div>
            )}
          </div>

          <div
            style={{
              marginInlineStart: 8,
              marginBlockEnd: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ marginInlineStart: 10 }}>
              <p
                className="boldText"
                style={{ fontSize: 14, color: color.textPrimary }}
              >
                {selected.rapidadvisorname}
              </p>
              <span
                className="smallText"
                style={{ color: color.side }}
                title={moment(selected.postedat).format("lll")}
              >
                {selected.postedat
                  ? moment(selected.postedat).format("l")
                  : "-"}
              </span>
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{ width: 160, textAlign: "center", marginInlineEnd: 30 }}
              >
                <p
                  className="smallBoldText"
                  style={{ color: color.blackFifty }}
                >
                  {strings.getString("post_end_date")}
                </p>
                <span
                  className="thinText"
                  style={
                    postEndCalculater < 432000000 && postEndCalculater > 0
                      ? { color: color.error }
                      : {}
                  }
                >
                  {selected.postendsat
                    ? moment(selected.postendsat).format("l")
                    : "-"}
                </span>
              </div>
            </div>
          </div>

          {component}
          <div
            className="extraInfos"
            style={{
              color: color.textPrimary + color.fiftyOpacity,
              borderColor: color.black + "16",
              borderBlockEnd: "1px solid #18181816",
              backgroundColor: "#f5f5f5",
            }}
          >
            <div
              className="standardText"
              style={{
                display: "flex",
                alignItems: "center",
                marginInlineEnd: 50,
              }}
            >
              <Whisper speaker={strings.getString("weight")}>
                <div style={{ cursor: "pointer", marginInlineEnd: 20 }}>
                  <Icon icon="tonne" size={14} style={{ marginInlineEnd: 5 }} />
                  <span>{selected.weight + " t"}</span>
                </div>
              </Whisper>
              <Whisper speaker={strings.getString("volume")}>
                <div style={{ cursor: "pointer", marginInlineEnd: 20 }}>
                  <Icon icon="ruler" size={12} style={{ marginInlineEnd: 5 }} />
                  <span>
                    {selected.cubicmeters
                      ? new Intl.NumberFormat("tr-TR", {
                          maximumSignificantDigits: 20,
                        }).format(selected.cubicmeters) + " m³"
                      : "-"}
                  </span>
                </div>
              </Whisper>
              <Whisper
                speaker={
                  <p>
                    <p style={{ fontWeight: 700 }}>
                      {strings.getString("desired_truck_number") + ": "}
                    </p>
                    {selected.loadTrucks?.map((type, i) => (
                      <p key={i}>{`${type.vehicletype} / ${parseInt(
                        type.vehiclenumber__c.toString()
                      )}`}</p>
                    ))}
                  </p>
                }
              >
                <div
                  style={{
                    display: "flex",
                    marginInlineEnd: 20,
                    cursor: "pointer",
                  }}
                >
                  <Icon icon="truck" size={20} style={{ marginInlineEnd: 5 }} />
                  <p style={{ marginInlineEnd: 3 }}>
                    {selected.trucktype}

                    {selected.loadTrucks &&
                      selected.loadTrucks?.length > 1 &&
                      strings.formatString(
                        strings.getString("more"),
                        selected.loadTrucks.length - 1
                      )}
                  </p>
                  {selected?.loadTrucks && selected?.loadTrucks?.length > 0 && (
                    <span
                      style={{
                        textAlign: "center",
                        paddingBlockStart: 2,
                        width: 18,
                        height: 18,
                        borderRadius: 40,
                        color: color.accent,
                        backgroundColor: color.side3,
                        fontSize: 11,
                      }}
                    >
                      {parseInt(
                        selected?.loadTrucks[0]?.vehiclenumber__c.toString() ??
                          "0".toString()
                      )}
                    </span>
                  )}
                </div>
              </Whisper>
              <Whisper speaker={strings.getString("distance")}>
                <div style={{ cursor: "pointer", marginInlineEnd: 30 }}>
                  <Icon
                    icon="distance"
                    size={17}
                    style={{ marginInlineEnd: 5 }}
                  />
                  <span>{(selected.mindistance?.toFixed(0) ?? 0) + " km"}</span>
                </div>
              </Whisper>
              <Whisper
                speaker={
                  strings.getString("pack_type") +
                  " / " +
                  strings.getString("number_of_packs")
                }
              >
                <div
                  style={{
                    display: "flex",
                    marginInlineEnd: 20,
                    cursor: "pointer",
                  }}
                >
                  <Icon
                    icon="box-stacked"
                    size={17}
                    style={{ marginInlineEnd: 5 }}
                  />
                  <p style={{ marginInlineEnd: 3 }}>
                    {selected.packagetype ? selected.packagetype : "-"}
                  </p>
                  {selected.packagequantity > 0 && (
                    <span
                      style={{
                        textAlign: "center",
                        paddingBlockStart: 2,
                        width: 18,
                        height: 18,
                        borderRadius: 40,
                        color: color.accent,
                        backgroundColor: color.side3,
                        fontSize: 11,
                      }}
                    >
                      {selected.packagequantity}
                    </span>
                  )}
                </div>
              </Whisper>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: 44,
                width: "max-content",
                border: "1px solid",
                borderColor: color.borderSecondary,
                borderRadius: 5,
                cursor: "pointer",
                backgroundColor: color.accent,
              }}
            >
              <Whisper speaker={strings.getString("details")}>
                <div
                  className="extraInfosButton"
                  style={{
                    borderColor: color.borderSecondary,
                    backgroundColor: show ? "#74747416" : "transparent",
                    borderStartStartRadius: 5,
                    borderEndStartRadius: 5,
                  }}
                  onClick={() => {
                    if (!useEp) {
                      setShow(!show);
                    } else if (show === true) {
                      setShow(!show);
                    } else {
                      setLoader(true);
                      dispatch(
                        getLoadOpportunityDetailWithoutLoader({
                          payload: {
                            onSuccess: (message, payload) => {
                              payload && setSelected(payload);
                              setLoader(false);
                              setShow(!show);
                            },
                            onError: (message) => {
                              setLoader(false);
                            },
                          },
                          url: `LoadOpportunity/GetByLoadOpportunities?sfid=${selected.id}`,
                        })
                      );
                    }
                    setShow(!show);
                  }}
                >
                  <Icon icon="more" />
                </div>
              </Whisper>
            </div>
          </div>
          <div className="extraDetails" style={{ height: show ? 293 : 0 }}>
            <Nav content={navContent}>
              {(activeTab) => {
                return (
                  <div
                    className="extraDetailsContent"
                    style={{
                      paddingInline:
                        activeTab !== navContent.length - 1 ? 32 : 0,
                    }}
                  >
                    <ScrollBar>
                      {activeTab !== navContent.length - 1 && (
                        <div className="extraDetailsFirstTab">
                          <div className="extraDetailsFirstTabFirstCol">
                            {(activeTab === 0 || activeTab === undefined) && (
                              <>
                                <div
                                  className="dividedRow"
                                  style={{ marginBlockEnd: 10 }}
                                >
                                  <div style={{ width: "50%" }}>
                                    <p
                                      className="boldText"
                                      style={{ color: color.textSecondary }}
                                    >
                                      {strings.getString("loading_area")}
                                    </p>
                                    <ul className="areasList smallRegularText">
                                      {selected.DetailTab &&
                                        selected.DetailTab.ll1name && (
                                          <li>{selected.DetailTab.ll1name}</li>
                                        )}
                                      {selected.DetailTab &&
                                        selected.DetailTab.ll2name && (
                                          <li>{selected.DetailTab.ll2name}</li>
                                        )}
                                      {selected.DetailTab &&
                                        selected.DetailTab.ll3name && (
                                          <li>{selected.DetailTab.ll3name}</li>
                                        )}
                                      {selected.DetailTab &&
                                        selected.DetailTab.ll4name && (
                                          <li>{selected.DetailTab.ll4name}</li>
                                        )}
                                    </ul>
                                  </div>
                                  <div style={{ width: "50%" }}>
                                    <p
                                      className="boldText"
                                      style={{ color: color.textSecondary }}
                                    >
                                      {strings.getString("unloading_area")}
                                    </p>
                                    <ul className="areasList smallRegularText">
                                      {selected.DetailTab &&
                                        selected.DetailTab.ul1name && (
                                          <li>{selected.DetailTab.ul1name}</li>
                                        )}
                                      {selected.DetailTab &&
                                        selected.DetailTab.ul2name && (
                                          <li>{selected.DetailTab.ul2name}</li>
                                        )}
                                      {selected.DetailTab &&
                                        selected.DetailTab.ul3name && (
                                          <li>{selected.DetailTab.ul3name}</li>
                                        )}
                                      {selected.DetailTab &&
                                        selected.DetailTab.ul4name && (
                                          <li>{selected.DetailTab.ul4name}</li>
                                        )}
                                    </ul>
                                  </div>
                                </div>
                                <div
                                  className="dividedRow"
                                  style={{ marginBlockEnd: 10 }}
                                >
                                  <div style={{ width: "50%" }}>
                                    <p
                                      className="boldText"
                                      style={{ color: color.textSecondary }}
                                    >
                                      {strings.getString("customs")}
                                    </p>
                                    {selected.DetailTab &&
                                    selected.DetailTab.fromcustoms ? (
                                      <span className="smallRegularText">
                                        {selected.DetailTab &&
                                          selected.DetailTab.fromcustoms}
                                      </span>
                                    ) : (
                                      <span className="smallRegularText">
                                        -
                                      </span>
                                    )}
                                  </div>
                                  <div style={{ width: "50%" }}>
                                    <p
                                      className="boldText"
                                      style={{ color: color.textSecondary }}
                                    >
                                      {strings.getString("customs")}
                                    </p>
                                    {selected.DetailTab &&
                                    selected.DetailTab.tocustoms ? (
                                      <span className="smallRegularText">
                                        {selected.DetailTab &&
                                          selected.DetailTab.tocustoms}
                                      </span>
                                    ) : (
                                      <span className="smallRegularText">
                                        -
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div
                                  className="dividedRow"
                                  style={{ marginBlockEnd: 10 }}
                                >
                                  <div style={{ width: "50%" }}>
                                    <p
                                      className="boldText"
                                      style={{ color: color.textSecondary }}
                                    >
                                      {strings.getString("loading_type")}
                                    </p>
                                    <span className="smallRegularText">
                                      {selected.DetailTab &&
                                      selected.DetailTab.loadintypes
                                        ? selected.DetailTab.loadintypes.replaceAll(
                                            ";",
                                            ","
                                          )
                                        : "-"}
                                    </span>
                                  </div>
                                  <div style={{ width: "50%" }}>
                                    <p
                                      className="boldText"
                                      style={{ color: color.textSecondary }}
                                    >
                                      {strings.getString("unloading_type")}
                                    </p>
                                    <span className="smallRegularText">
                                      {selected.DetailTab &&
                                      selected.DetailTab.unloadingtypes
                                        ? selected.DetailTab.unloadingtypes.replaceAll(
                                            ";",
                                            ","
                                          )
                                        : "-"}
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                            {activeTab === 1 && (
                              <>
                                <div
                                  style={{ width: "100%", marginBlockEnd: 10 }}
                                >
                                  <p
                                    className="boldText"
                                    style={{ color: color.textSecondary }}
                                  >
                                    {strings.getString("desired_arrival_date")}
                                  </p>
                                  <span className="smallRegularText">
                                    {selected.loadenddate
                                      ? moment(selected.loadenddate).format("L")
                                      : ""}
                                  </span>
                                </div>
                                <div
                                  style={{ width: "100%", marginBlockEnd: 10 }}
                                >
                                  <p
                                    className="boldText"
                                    style={{ color: color.textSecondary }}
                                  >
                                    {strings.getString("payment_conditions")}
                                  </p>
                                  <span className="smallRegularText">
                                    {selected.ExtraTab &&
                                    selected.ExtraTab
                                      .advancepaymentafterloading__c ? (
                                      <>
                                        <span>
                                          {strings.formatString(
                                            strings.getString(
                                              "paymentconditionfirstsentence"
                                            ),
                                            selected.ExtraTab.percent__c,
                                            100 - selected.ExtraTab.percent__c
                                          )}
                                        </span>
                                        {selected.ExtraTab
                                          .dayafterloading__c && (
                                          <p>
                                            {strings.formatString(
                                              strings.getString(
                                                "afterloadingday"
                                              ),
                                              selected.ExtraTab.day__c
                                            )}
                                          </p>
                                        )}

                                        {selected.ExtraTab
                                          .dayafterunloading__c && (
                                          <p>
                                            {strings.formatString(
                                              strings.getString(
                                                "afterunloadingday"
                                              ),
                                              selected.ExtraTab.unloadingday__c
                                            )}
                                          </p>
                                        )}

                                        {selected.ExtraTab
                                          .afterinvoicedate__c && (
                                          <p>
                                            {strings.formatString(
                                              strings.getString("afterinvoice"),
                                              selected.ExtraTab
                                                .afterinvoicedateday__c
                                            )}
                                          </p>
                                        )}

                                        {selected.ExtraTab
                                          .aftercopycmrinvoice__c && (
                                          <p>
                                            {strings.formatString(
                                              strings.getString("aftercopycmr"),
                                              selected.ExtraTab
                                                .aftercopycmrinvoiceday__c
                                            )}
                                          </p>
                                        )}
                                        {selected.ExtraTab
                                          .afteroriginalcmr__c && (
                                          <p>
                                            {strings.formatString(
                                              strings.getString(
                                                "afteroriginalcmr"
                                              ),
                                              selected.ExtraTab.cmrday__c
                                            )}
                                          </p>
                                        )}
                                      </>
                                    ) : (
                                      <span>{strings.getString("Cash")}</span>
                                    )}
                                  </span>
                                </div>
                                <div
                                  style={{ width: "100%", marginBlockEnd: 10 }}
                                >
                                  <p
                                    className="boldText"
                                    style={{ color: color.textSecondary }}
                                  >
                                    {strings.getString("hsc") +
                                      " / " +
                                      strings.getString("commodity_price")}
                                  </p>
                                  {selected?.ExtraTab?.hsc_code__c ? (
                                    <span className="smallRegularText">
                                      {selected.ExtraTab.hsc_code__c}
                                    </span>
                                  ) : (
                                    <span className="smallRegularText">-</span>
                                  )}
                                  {selected?.ExtraTab?.productprice__c ? (
                                    <span className="smallRegularText">
                                      /
                                      {getCurrency(
                                        selected.ExtraTab.productprice__c,
                                        selected.currencyisocode
                                      )}
                                    </span>
                                  ) : (
                                    <span className="smallRegularText">/-</span>
                                  )}
                                </div>
                                <div
                                  style={{ width: "100%", marginBlockEnd: 10 }}
                                >
                                  <p
                                    className="boldText"
                                    style={{ color: color.textSecondary }}
                                  >
                                    {strings.getString("desired_equipment")}
                                  </p>
                                  <span className="smallRegularText">
                                    {selected.loadTrucks?.map((type, i) => (
                                      <p key={i}>{`${
                                        type.equipments ?? ""
                                      }`}</p>
                                    ))}
                                  </span>
                                </div>
                              </>
                            )}
                            {activeTab === 2 && (
                              <div style={{ width: "100%" }}>
                                <p
                                  className="boldText"
                                  style={{ color: color.textSecondary }}
                                >
                                  {strings.getString("other_details")}
                                </p>
                                <div
                                  className="smallRegularText"
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  <p
                                    style={{
                                      marginInlineEnd: 10,
                                      color: color.textSecondary,
                                    }}
                                  >
                                    {strings.getString("adr") + ": "}
                                  </p>
                                  <span>
                                    {selected?.OtherTab?.adr__c ? (
                                      <>
                                        {strings.getString("yes") +
                                          " - " +
                                          selected.OtherTab.adr__c}
                                      </>
                                    ) : (
                                      strings.getString("no")
                                    )}
                                  </span>
                                </div>
                                <div
                                  className="smallRegularText"
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  <p
                                    style={{
                                      marginInlineEnd: 10,
                                      color: color.textSecondary,
                                    }}
                                  >
                                    {strings.getString("frigo") + ": "}
                                  </p>
                                  <span>
                                    {selected?.OtherTab?.frigocelsius_ ? (
                                      <>
                                        {strings.getString("yes") +
                                          `- (${selected.OtherTab.frigocelsius_})`}
                                      </>
                                    ) : (
                                      strings.getString("no")
                                    )}
                                  </span>
                                </div>
                                <div
                                  className="smallRegularText"
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  <p
                                    style={{
                                      marginInlineEnd: 10,
                                      color: color.textSecondary,
                                    }}
                                  >
                                    {strings.getString("doctor_required") +
                                      ": "}
                                  </p>
                                  <span>
                                    {selected?.OtherTab?.isdoctorrequired
                                      ? strings.getString("yes")
                                      : strings.getString("no")}
                                  </span>
                                </div>
                                <div
                                  className="smallRegularText"
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  <p
                                    style={{
                                      marginInlineEnd: 10,
                                      color: color.textSecondary,
                                    }}
                                  >
                                    {strings.getString("countrycertificate") +
                                      ": "}
                                  </p>
                                  <span>
                                    {selected?.thirdcountrycertificate !==
                                    undefined
                                      ? selected.thirdcountryname
                                        ? strings.getString("yes")
                                        : strings.getString("no")
                                      : "-"}
                                  </span>
                                </div>
                                <div
                                  className="smallRegularText"
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  <p
                                    style={{
                                      marginInlineEnd: 10,
                                      color: color.textSecondary,
                                    }}
                                  >
                                    {strings.getString("countryname") + ": "}
                                  </p>
                                  <span>
                                    {selected?.thirdcountryname
                                      ? selected?.thirdcountryname
                                      : "-"}
                                  </span>
                                </div>
                                <div
                                  className="smallRegularText"
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  <p
                                    style={{
                                      marginInlineEnd: 10,
                                      color: color.textSecondary,
                                    }}
                                  >
                                    {strings.getString(
                                      "trucktransitdeclarationtype"
                                    ) + ": "}
                                  </p>
                                  <span>
                                    {selected?.trucktransitdeclarationtype
                                      ? selected?.trucktransitdeclarationtype
                                      : "-"}
                                  </span>
                                </div>
                                <div
                                  className="smallRegularText"
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  <p
                                    style={{
                                      marginInlineEnd: 10,
                                      color: color.textSecondary,
                                    }}
                                  >
                                    {strings.getString("seconddrivercheckbox") +
                                      ": "}
                                  </p>
                                  <span>
                                    {selected?.seconddrivercheckbox !==
                                    undefined
                                      ? selected?.seconddrivercheckbox
                                        ? strings.getString("yes")
                                        : strings.getString("no")
                                      : "-"}
                                  </span>
                                </div>
                              </div>
                            )}
                            {activeTab === 3 && (
                              <>
                                <p
                                  className="boldText"
                                  style={{ color: color.textSecondary }}
                                >
                                  {strings.getString("available_routes")}
                                </p>
                                {selected.RouteTab?.map((route, i) => (
                                  <div
                                    key={i}
                                    className="smallRegularText"
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <p>{route.route}&nbsp; </p>
                                    {isOffer && (
                                      <span>
                                        {getCurrency(
                                          route.targetprice,
                                          route.currencyisocode
                                        )}
                                      </span>
                                    )}
                                    <span>
                                      &nbsp;{route.distance?.toFixed(0) ?? 0} km
                                    </span>
                                  </div>
                                ))}
                              </>
                            )}
                          </div>
                          <div
                            style={{
                              width: "5%",
                              height: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                width: 1,
                                backgroundColor: color.borderColor,
                              }}
                            />
                          </div>
                          <div className="extraDetailsFirstTabSecondCol">
                            <p
                              className="boldText"
                              style={{ color: color.textSecondary }}
                            >
                              {strings.getString("language")}
                            </p>
                            <div
                              style={{
                                width: 100,
                                display: "flex",
                                justifyContent: "flex-start",
                                flexWrap: "wrap",
                                gap: "4",
                              }}
                            >
                              {selected?.Owner?.flags?.split(";").map((x) => {
                                return (
                                  <Flag
                                    style={{ marginRight: 3 }}
                                    country={x === "EN" ? "US" : x}
                                    title={x}
                                    className="flag"
                                  />
                                );
                              })}
                            </div>
                            <p
                              className="boldText"
                              style={{
                                color: color.textSecondary,
                                marginBlockEnd: 0,
                                marginBlockStart: 5,
                              }}
                            >
                              {strings.getString("contact_details")}
                            </p>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Icon
                                icon="mail"
                                size={20}
                                style={{ marginInlineEnd: 5 }}
                              />
                              <span
                                className="boldText"
                                style={{
                                  textDecoration: "underline",
                                  fontWeight: 600,
                                }}
                              >
                                {selected.Owner?.email}
                              </span>
                            </div>
                            <div style={{ display: "flex" }}>
                              <Icon
                                icon="mobile"
                                size={20}
                                style={{ marginInlineEnd: 5 }}
                              />
                              <span
                                className="boldText"
                                style={{ fontWeight: 600 }}
                              >
                                {selected.Owner?.mobilephone}
                              </span>
                            </div>
                            <div style={{ display: "flex" }}>
                              <Icon
                                icon="phone"
                                color={color.black}
                                size={15}
                                style={{
                                  marginInlineEnd: 7,
                                  marginInlineStart: 3,
                                }}
                              />
                              <span
                                className="boldText"
                                style={{ fontWeight: 600 }}
                              >
                                {selected.Owner?.phone}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                      {activeTab === 4 && (
                        <GoogleMap
                          id={`rapidMap-${selected.id}`}
                          options={{
                            //@ts-ignore
                            styles: CUSTOM_MAP,
                            disableDefaultUI: true,
                            zoomControl: true,
                            scrollwheel: false,
                          }}
                          onLoad={(map: google.maps.Map<Element>) => {
                            setRouteDirections([]);
                            setPathDistance("");

                            if (selected.RouteTab) {
                              clickableDirectionServiceResult(
                                selected.RouteTab[0]
                              );
                            } else {
                              getDirectionsServiceResult();
                            }
                          }}
                          mapContainerStyle={mapContainerStyles}
                        >
                          {route_directions &&
                            route_directions.map((rd, i) => (
                              <DirectionsRenderer
                                key={`route-map-${i}`}
                                directions={rd}
                                onLoad={(
                                  directionsRenderer: google.maps.DirectionsRenderer
                                ) => {
                                  console.log(
                                    "directionsRenderer",
                                    directionsRenderer
                                  );
                                }}
                                options={{
                                  polylineOptions: {
                                    strokeColor:
                                      decideColor(path_distance_index),
                                  },
                                }}
                              />
                            ))}
                          {selected.RouteTab && (
                            <div
                              style={{
                                position: "absolute",
                                left: 10,
                                top: 5,
                              }}
                            >
                              {selected.RouteTab.map((data, i) => {
                                return (
                                  <div
                                    style={{
                                      position: "relative",
                                      backgroundColor: decideColor(i),
                                      padding: 5,
                                      marginTop: 5,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      clickableDirectionServiceResult(data);
                                      setPathDistanceIndex(i);
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "black",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {data.route}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          )}

                          {path_distance !== "" && (
                            <div
                              style={{
                                position: "absolute",
                                right: 10,
                                top: 5,
                                padding: 5,
                                backgroundColor: "#ededed",
                                borderRadius: 10,
                              }}
                            >
                              <span
                                style={{ color: "black", fontWeight: "bold" }}
                              >
                                {path_distance}
                              </span>
                            </div>
                          )}
                        </GoogleMap>
                      )}
                    </ScrollBar>
                  </div>
                );
              }}
            </Nav>
          </div>
        </div>
      </div>
    );
  }
);

export default ProfileLoadCard;
