import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteDestinations,
  getDestinationCustomer,
  getPicklistCountryListForCustomer,
} from "../../../../../../redux/profile/profileSaga";
import {
  DestinationDto,
  PicklistCountryDto,
} from "../../../../../../redux/profile/type";
import { getLocalizedMyProfile } from "../../../../../../redux/selectors";
import { confirm } from "devextreme/ui/dialog";
import { Alert } from "rsuite";
import CreateDestinationForm from "./create";
import EditDestinationForm from "./edit";
import ResponsiveTable, {
  GridButtonType,
} from "../../../../../../components/ResponsiveTable";

const DestinationGrid = () => {
  const [destination, setDestination] = useState<DestinationDto[]>();
  const [selected, setSelected] = useState<DestinationDto>();
  const [countries, setCountries] = useState<PicklistCountryDto[]>();
  const strings = useSelector(getLocalizedMyProfile);
  const dispatch = useDispatch();
  const getDestinationFunc = () => {
    dispatch(
      getPicklistCountryListForCustomer({
        payload: {
          onSuccess: (message, payload) => setCountries(payload),
          onError: (message) => {},
        },
        url: "System/GetPicklistCountryList",
      })
    );
    dispatch(
      getDestinationCustomer({
        payload: {
          onSuccess: (message, payload) => setDestination(payload),
          onError: (message) => {},
        },
        url: "Profile/Destinations",
      })
    );
  };
  useEffect(() => {
    getDestinationFunc(); // eslint-disable-next-line
  }, [dispatch]);
  const columns = [
    {
      dataField: "from_name",
      caption: strings.getString("startingpoint"),
    },
    {
      dataField: "countryname__c",
      caption: strings.getString("destinationpoint"),
    },
    {
      dataField: "name",
      caption: strings.getString("name"),
    },
  ];
  const buttons = [
    {
      type: GridButtonType.edit,
      icon: "dx-icon-edit",
      onClick: (e: any) => {
        return setSelected(e);
      },
    },
    {
      type: GridButtonType.default,
      icon: "dx-icon-trash",
      onClick: (e: any) => {
        console.log(e);
        var confirmResp = confirm(
          strings.getString("deleteDestinationqua"),
          strings.getString("deleteDestination")
        );
        confirmResp.then((result) => {
          if (result) {
            dispatch(
              deleteDestinations({
                payload: {
                  onSuccess: (message, payload) => {
                    Alert.success("Başarıyla silindi.");
                    getDestinationFunc();
                  },
                  onError: (message) => {
                    Alert.error(message);
                  },
                },
                url: "/Profile/DeleteDestination/" + e.id,
              })
            );
          }
        });
      },
    },
  ];
  return (
    <>
      <div
        style={{
          width: "100%",
          marginBlockEnd: 30,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <p className="biggerHeadingStyle">
          {strings.getString("Destinations Where The Transporters works")}
        </p>
      </div>
      <ResponsiveTable
        data={destination ?? []}
        columns={columns}
        buttons={buttons}
        createForm={
          <CreateDestinationForm
            onSuccess={() => {
              Alert.success("Başarıyla eklendi.");
              getDestinationFunc();
            }}
            countries={countries ? countries : []}
          />
        }
        editForm={
          selected && (
            <EditDestinationForm
              onSuccess={() => {
                Alert.success("Başarıyla güncellendi.");
                getDestinationFunc();
              }}
              selected={selected}
              countries={countries ? countries : []}
            />
          )
        }
      />
    </>
  );
};
export default DestinationGrid;
