import { all } from "redux-saga/effects";
import auctionSagas from "../autions/saga";
import opportunitySagas from "../opportunity/saga";
import profileSagas from "../profile/profileSaga";

import getSaga from "./getSaga";
import getWithIdSaga from "./getWithIdSaga";
import staticSaga from "./staticSaga";
import systemSaga from "./systemSaga";
import customerContractsSagas from "../customercontracts/saga";
import trContractsSagas from "../transportercontracts/saga";

export function* rootSaga() {
  yield all([
    ...getSaga,
    ...getWithIdSaga,
    ...staticSaga,
    ...systemSaga,
    ...profileSagas,
    ...opportunitySagas,
    ...auctionSagas,
    ...trContractsSagas,
    ...customerContractsSagas,
  ]);
}
