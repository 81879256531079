import getSymbolFromCurrency from "currency-symbol-map";
import {
  GeneralSystemObject,
  PickerObject,
  LanguageObject,
  CommunicationLanguageObject,
} from "../types/objects";

export const generatePickerArray = (
  generalSystemObjectArray: GeneralSystemObject[]
) => {
  let pickerArray: PickerObject[] = generalSystemObjectArray.map((item) => {
    return { label: item.description, value: item.id, role: item.description };
  });
  return pickerArray;
};

export const generateLanguagesPickerArray = (
  generalSystemObjectArray: LanguageObject[]
) => {
  let pickerArray: PickerObject[] = generalSystemObjectArray.map((item) => {
    return { label: item.language, value: item.flag, role: item.culture };
  });
  return pickerArray;
};
export const generateCommunicationLanguagesPickerArray = (
  generalSystemObjectArray: CommunicationLanguageObject[]
) => {
  let pickerArray: PickerObject[] = generalSystemObjectArray.map((item) => {
    return { label: item.description, value: item.id, role: item.id };
  });
  return pickerArray;
};

export const getCurrency = (
  description: string | number | undefined | null,
  currencyCode: string | undefined
): string => {
  if (description && currencyCode)
    return description + " (" + getSymbolFromCurrency(currencyCode) + ")";
  else if (currencyCode)
    return " -(" + getSymbolFromCurrency(currencyCode) + ")";
  else return " -";
};
