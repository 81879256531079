import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Button from "../Button";
import withDirection, {
  DIRECTIONS,
  withDirectionPropTypes,
  //@ts-ignore
} from "react-with-direction";

const Carousel = ({
  children,
  direction,
  responsive,
  noArrows,
  noInfinite,
}: withDirectionPropTypes) => {
  var settings = {
    dots: false,
    infinite: noInfinite === true ? false : true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    rtl: direction === DIRECTIONS.LTR ? false : true,
    responsive: responsive
      ? responsive
      : [
          {
            breakpoint: 1920,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 928,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: false,
            },
          },
        ],
    prevArrow:
      noArrows === true ? (
        <></>
      ) : (
        <div style={{ width: 38, height: 38 }}>
          <Button
            icon={direction === DIRECTIONS.LTR ? "left" : "right"}
            iconPosition="start"
            style={{
              paddingBlock: "11px",
              paddingInline: "8px 4px",
              borderRadius: 10,
              width: 38,
              marginInlineStart: "-30px",
            }}
          />
        </div>
      ),
    nextArrow:
      noArrows === true ? (
        <></>
      ) : (
        <div style={{ width: 38, height: 38 }}>
          <Button
            icon={direction === DIRECTIONS.LTR ? "right" : "left"}
            iconPosition="start"
            style={{
              paddingBlock: "11px",
              paddingInline: "10px 2px",
              borderRadius: 10,
              width: 38,
            }}
          />
        </div>
      ),
  };

  return <Slider {...settings}>{children}</Slider>;
};

export default withDirection(Carousel);
