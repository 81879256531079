import { Formik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Modal } from "rsuite";
import ModalBody from "rsuite/lib/Modal/ModalBody";
import ModalHeader from "rsuite/lib/Modal/ModalHeader";
import {
  Button,
  Input,
  ProfileLoadCard,
  ProfileTruckCard,
} from "../../../../../../components";
import { InputType } from "../../../../../../components/Input";
import ProfileLoadCardMobil from "../../../../../../components/ProfileLoadCardMobil";
import ProfileTruckCardMobil from "../../../../../../components/ProfileTruckCardMobil";
import { postApprovalLoadOfferList } from "../../../../../../redux/profile/profileSaga";
import {
  ApprovalCustomerOfferDto,
  DisapprovalReasonDto,
} from "../../../../../../redux/profile/type";
import { getLocalizedMyProfile } from "../../../../../../redux/selectors";
import { getCurrency } from "../../../../../../utils/Helpers";
import { color } from "../../../../../../utils/Properties";

const ApprovalOfferCardCustomer = ({
  data,
  reasons,
  getData,
}: {
  getData: () => void;
  data: ApprovalCustomerOfferDto;
  reasons?: DisapprovalReasonDto[];
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [showAccept, setShowAccept] = useState<boolean>(false);
  const [showUpdateOffer, setShowUpdateOffer] = useState<boolean>(false);
  const strings = useSelector(getLocalizedMyProfile);
  const dispatch = useDispatch();

  const condition = () => {
    if (data.RequestedPrice === data.OfferedPriceToCustomer) {
      if (
        data.RequestedPrice !== null &&
        data.OfferedPriceToCustomer !== null
      ) {
        return false;
      } else return true;
    } else {
      return true;
    }
  };
  return (
    <>
      <div className="mobile-profile-offer-list">
        <div className="mobile-profile-offer-load-card">
          {data.Load && (
            <ProfileLoadCardMobil
              key={data.Load.id}
              load={data.Load}
              useEp={true}
            />
          )}
        </div>
        <div className="mobile-profile-offer-load-card">
          {data.Load && (
            <ProfileTruckCardMobil
              key={data.Load.id}
              truck={data.Truck}
              useEp={true}
            />
          )}
        </div>
        <div className="profile-offer-load-card">
          {data && (
            <ProfileTruckCard key={data.sfid} truck={data.Truck} useEp={true} />
          )}
        </div>
        <div className="profile-offer-load-card">
          {data.Load && (
            <ProfileLoadCard key={data.Load.id} load={data.Load} useEp={true} />
          )}
        </div>
        <div className="dividingDiv">
          <table className="pack-table">
            <tr>
              <th className="smallHeadingStyle">
                {strings.getString("The Price We Offer")}:
              </th>
              <td className="regularText">
                {data.Load.currencyisocode && data.OfferedPriceToCustomer
                  ? getCurrency(
                      data.OfferedPriceToCustomer,
                      data.Load.currencyisocode
                    )
                  : data.OfferedPriceToCustomer
                  ? data.OfferedPriceToCustomer
                  : "-"}
              </td>
            </tr>
          </table>
          <table className="pack-table">
            <tr>
              <th className="smallHeadingStyle">
                {strings.getString("Requested Price")}:
              </th>
              <td className="regularText">
                {data.Load.currencyisocode && data.RequestedPrice
                  ? getCurrency(data.RequestedPrice, data.Load.currencyisocode)
                  : data.RequestedPrice
                  ? data.RequestedPrice
                  : "-"}
              </td>
            </tr>
          </table>
        </div>
        <div className="dividingDiv">
          <div style={{ marginBlockStart: 24, width: "100%", maxWidth: 200 }}>
            <Button
              style={{
                borderColor: color.error,
                backgroundColor: color.error,
              }}
              title={strings.getString("Cancel")}
              onClick={() => {
                setShow(true);
              }}
            />
          </div>
          {condition() && (
            <div style={{ marginBlockStart: 24, width: "100%", maxWidth: 200 }}>
              <Button
                style={{
                  borderColor: color.warning,
                  backgroundColor: color.warning,
                }}
                title={strings.getString("Make Offer")}
                onClick={() => {
                  setShowUpdateOffer(true);
                }}
              />
            </div>
          )}
          {data.OfferedPriceToCustomer && (
            <div style={{ marginBlockStart: 24, width: "100%", maxWidth: 200 }}>
              <Button
                style={{
                  borderColor: color.success,
                  backgroundColor: color.success,
                }}
                title={strings.getString("Accept")}
                onClick={() => {
                  setShowAccept(true);
                }}
              />
            </div>
          )}
        </div>
      </div>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        size={"xs"}
      >
        <ModalBody>
          <div style={{ padding: 20 }}>
            <Formik
              initialValues={{
                sfid: data.sfid,
                accepted: false,
                customer_requested_price__c: data.OfferedPriceToCustomer ?? 0,
              }}
              onSubmit={(values) => {
                dispatch(
                  postApprovalLoadOfferList({
                    payload: {
                      onSuccess: () => {
                        Alert.success(
                          strings.getString("The Offer is canceled")
                        );
                        setShow(false);
                        getData();
                      },
                      onError: () => {},
                    },
                    body: values,
                  })
                );
              }}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                errors,
                touched,
                values,
              }) => (
                <div>
                  <div
                    style={{
                      display: "flex",
                      marginBlockStart: 20,
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      style={{
                        borderColor: color.error,
                        backgroundColor: color.error,
                      }}
                      title={strings.getString("Cancel")}
                      onClick={handleSubmit}
                    />
                  </div>
                </div>
              )}
            </Formik>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        show={showUpdateOffer}
        onHide={() => {
          setShowUpdateOffer(false);
        }}
        size={"xs"}
      >
        <ModalBody>
          <div style={{ padding: 20 }}>
            <Formik
              initialValues={{
                sfid: data.sfid,
                accepted: null,
                customer_requested_price__c: data.OfferedPriceToCustomer,
              }}
              onSubmit={(values) => {
                dispatch(
                  postApprovalLoadOfferList({
                    payload: {
                      onSuccess: () => {
                        Alert.success(
                          strings.getString("New offer price sending")
                        );
                        setShowUpdateOffer(false);
                        getData();
                      },
                      onError: () => {},
                    },
                    body: values,
                  })
                );
              }}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                errors,
                touched,
                values,
              }) => (
                <div>
                  <Input
                    inputType={InputType.number}
                    name={strings.getString("Offer Price")}
                    label={strings.getString("Offer Price")}
                    placeHolder={strings.getString("Offer Price")}
                    value={values.customer_requested_price__c}
                    onChange={handleChange("customer_requested_price__c")}
                    onBlur={handleBlur("customer_requested_price__c")}
                    style={{ paddingBlock: 13 }}
                    autoFocus
                  />

                  <div
                    style={{
                      display: "flex",
                      marginBlockStart: 20,
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      style={{
                        borderColor: color.success,
                        backgroundColor: color.success,
                      }}
                      title={strings.getString("Make Offer")}
                      onClick={() => handleSubmit()}
                    />
                  </div>
                </div>
              )}
            </Formik>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        show={showAccept}
        onHide={() => {
          setShowAccept(false);
        }}
        size={"xs"}
      >
        {" "}
        <ModalHeader
          onHide={() => setShowAccept(false)}
          style={{ minHeight: 7 }}
        >
          {""}
        </ModalHeader>
        <ModalBody>
          <div style={{ padding: 20 }}>
            <Formik
              initialValues={{
                sfid: data.sfid,
                accepted: true,
                customer_requested_price__c: data.OfferedPriceToCustomer,
              }}
              onSubmit={(values) => {
                dispatch(
                  postApprovalLoadOfferList({
                    payload: {
                      onSuccess: () => {
                        Alert.success(
                          strings.getString(
                            "The Offer is accepted successfully"
                          )
                        );
                        setShowAccept(false);
                        getData();
                      },
                      onError: () => {},
                    },
                    body: values,
                  })
                );
              }}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                errors,
                touched,
                values,
              }) => (
                <div>
                  <p
                    className="biggerHeadingStyle"
                    style={{ textAlign: "center" }}
                  >
                    {strings.getString("Are you sure accept this offer")}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      marginBlockStart: 20,
                      justifyContent: "space-between",
                      gap: 10,
                    }}
                  >
                    <Button
                      style={{
                        borderColor: color.success,
                        backgroundColor: color.success,
                      }}
                      title={strings.getString("Yes")}
                      onClick={handleSubmit}
                    />
                  </div>
                </div>
              )}
            </Formik>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
export default ApprovalOfferCardCustomer;
