import {
  ControlLabel,
  Input as InputRS,
  InputGroup,
  InputNumber,
  InputProps,
  SelectPicker,
} from "rsuite";
import { Icon } from "..";
import { PickerObject } from "../../types";
import { color } from "../../utils/Properties";
import "./style.css";

export enum InputType {
  default,
  number,
}

export const currencies: PickerObject[] = [
  { label: "$", value: "1", role: "Dollars" },
  { label: "€", value: "2", role: "Euro" },
  { label: "₺", value: "3", role: "Turkish Lira" },
];

const Input = ({
  hideConfirmIcon = false,
  fontsize,
  numberSelect,
  inputType,
  icon,
  iconPlace,
  selectPickerdisabled,
  children,
  currencySelect,
  selectPicker,
  doubleInput,
  selectPickerItems,
  selectPickerPlaceholder,
  selectPickerSelectedId,
  handleChangeCheckBox,
  selectPickerSelect,
  selectPickerLabelKey,
  selectPickerValueKey,
  selectPickerClean,
  error,
  touched,
  ...rest
}: Props) => {
  const inputStyles = () => {
    const defStyle = {
      fontSize: fontsize ? fontsize : 14,
      color: color.textPrimary,
      fontWeight: 400,
      paddingBlock: 8,
      paddingInline: 11,
      borderRadius: 5,
    };
    switch (inputType) {
      case InputType.number:
        return (
          //@ts-ignore
          <InputNumber
            style={{
              ...defStyle,
              paddingBlock: 1,
              paddingInline: 5,
              backgroundColor: "transparent",
            }}
            min={0}
            defaultValue={rest.defaultValue ? rest.defaultValue : "0"}
            {...rest}
          />
        );
      case InputType.default:
      default:
        return (
          <InputRS
            style={{
              ...defStyle,
            }}
            {...rest}
          />
        );
    }
  };
  return (
    <div style={{ marginBlockEnd: 15 }}>
      {rest.label && (
        <ControlLabel
          style={{
            paddingBlockStart: 2,
            marginBlockEnd: 4,
            fontSize: fontsize ? fontsize : 13,
            fontWeight: 400,
            color: color.textPrimary,
          }}
        >
          {rest.label}
        </ControlLabel>
      )}
      <InputGroup className={error && touched ? "errorInput" : "defaultInput"}>
        {icon && iconPlace === "left" && (
          <InputGroup.Addon style={{ backgroundColor: "transparent" }}>
            <Icon icon={icon} />
          </InputGroup.Addon>
        )}
        {inputStyles()}
        {icon && iconPlace === "right" && (
          <InputGroup.Addon style={{ backgroundColor: "transparent" }}>
            <Icon icon={icon} />
          </InputGroup.Addon>
        )}
        {selectPicker && selectPickerSelect && selectPickerItems && (
          <>
            <InputGroup.Addon
              style={{
                backgroundColor: "transparent",
                padding: 0,
                width: "40%",
              }}
            >
              <SelectPicker
                searchable={true}
                disabled={selectPickerdisabled ? selectPickerdisabled : false}
                placement="bottomStart"
                className="inputCurrencySelect"
                appearance="subtle"
                placeholder={selectPickerPlaceholder}
                data={selectPickerItems}
                value={selectPickerSelectedId}
                onSelect={selectPickerSelect}
                valueKey={selectPickerValueKey}
                labelKey={selectPickerLabelKey}
                onClean={selectPickerClean}
                cleanable={false}
                style={{
                  width: "100%",
                  borderInlineStart: "1px solid",
                  borderColor: color.borderSecondary,
                }}
              />
            </InputGroup.Addon>
          </>
        )}
        {currencySelect && (
          <InputGroup.Addon
            style={{ backgroundColor: "transparent", padding: 0 }}
          >
            <SelectPicker
              className="inputCurrencySelect"
              data={currencies}
              defaultValue={"1"}
              searchable={false}
              cleanable={false}
              placement="bottomEnd"
              disabled={rest.disabled}
            />
          </InputGroup.Addon>
        )}
        {error && touched && (
          <InputGroup.Addon style={{ backgroundColor: color.errorSoft }}>
            <Icon icon="danger" size={20} />
          </InputGroup.Addon>
        )}
        {!error && touched && (
          <InputGroup.Addon style={{ backgroundColor: "transparent" }}>
            <Icon icon="check" size={20} />
          </InputGroup.Addon>
        )}
        {children || null}
      </InputGroup>
      {error && touched && <p className="inputErrorStyles">{error}</p>}
    </div>
  );
};

interface Props extends InputProps {
  hideConfirmIcon?: boolean;
  inputType?: InputType;
  fontSize?: number;
  icon?: string;
  iconPlace?: "right" | "left";
  children?: React.ReactNode;
  error?: string | null;
  touched?: boolean | null;
  currencySelect?: boolean;
  doubleInput?: boolean;
  doubleinputType?: boolean;
  selectPicker?: boolean;
  selectPickerItems?: any;
  handleChangeCheckBox?: (checked: boolean) => void;
  selectPickerPlaceholder?: string;
  selectPickerSelectedId?: string | number;
  selectPickerSelect?: (id: string | number, item: any) => void;
  selectPickerValueKey?: string;
  selectPickerLabelKey?: string;
  selectPickerdisabled?: boolean;
}

export default Input;
