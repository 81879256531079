import { useState } from "react";
import { useSelector } from "react-redux";
import { Nav } from "rsuite";
import { Loader } from "../../../../../../components";
import {
  getLocalizedMyProfile,
  isLoadingState,
} from "../../../../../../redux/selectors";
import { color } from "../../../../../../utils/Properties";
import MyCloseLoadOpportunity from "./close";
import MyLoadOpportunity from "./open";

const MyTruckOpportunityBasePage = () => {
  const loader = useSelector(isLoadingState);
  const strings = useSelector(getLocalizedMyProfile);
  const [activeTab, setActiveTab] = useState<string>("open");

  return (
    <>
      <div
        className="my-profile-card"
        style={{ backgroundColor: color.accent }}
      >
        {loader && <Loader />}
        <Nav
          className="responsive-nav"
          appearance="tabs"
          activeKey={activeTab}
          onSelect={setActiveTab}
        >
          <Nav.Item
            eventKey="open"
            className="aboutus-menu-item responsive-navitem"
          >
            {strings.getString("My Open Truck Opportunity")}
          </Nav.Item>
          <Nav.Item
            eventKey="closed"
            className="aboutus-menu-item responsive-navitem"
          >
            {strings.getString("My Closed Truck Opportunity")}
          </Nav.Item>
        </Nav>
        <div style={{ marginBlockStart: 30 }}>
          {activeTab === "open" && <MyLoadOpportunity />}
          {activeTab === "closed" && <MyCloseLoadOpportunity />}
        </div>
      </div>
    </>
  );
};
export default MyTruckOpportunityBasePage;
