import { call, put } from "@redux-saga/core/effects";
import { ApiCallback, ApiResponse } from "../../types";
import { resultStatus } from "../../utils/Properties";
import {
  BodyType,
  createRequestSaga,
  hideLoader,
  showLoader,
} from "../actions";

export interface PostSagaGenericParams<Type> {
  type: string;
  payload: ApiCallback<Type>;
  body: object;
  url: string;
  bodyType?: BodyType;
  id?: string;
}
export function* postSaga<Type>({
  body,
  payload,
  url,
  bodyType = BodyType.formdata,
}: PostSagaGenericParams<Type>) {
  yield put(showLoader());
  try {
    const response: ApiResponse<Type> = yield call(createRequestSaga, {
      method: "POST",
      url: url || "",
      body: body,
      bodyType: bodyType,
    });
    if (resultStatus(response.ResultStatus)) {
      payload.onSuccess(response.Message, response.Result);
    } else payload.onError(response.Message);
  } catch (e) {
    console.log("ERROR postSga", e);
  } finally {
    yield put(hideLoader());
  }
}
export function* putSaga<Type>({
  body,
  payload,
  url,
  bodyType = BodyType.formdata,
}: PostSagaGenericParams<Type>) {
  yield put(showLoader());
  try {
    const response: ApiResponse<Type> = yield call(createRequestSaga, {
      method: "PUT",
      url: url || "",
      body: body,
      bodyType: bodyType,
    });
    if (resultStatus(response.ResultStatus)) {
      payload.onSuccess(response.Message, response.Result);
    } else payload.onError(response.Message);
  } catch (e) {
    console.log("ERROR putSga", e);
  } finally {
    yield put(hideLoader());
  }
}
