import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Button, Icon, Input } from "../../../components";
import { getLocalizedAuth, getLocalizedErrors } from "../../../redux/selectors";
import { color } from "../../../utils/Properties";
import * as Yup from "yup";
import { Alert, Form, FormGroup } from "rsuite";
import { useHistory, useParams } from "react-router-dom";
import { ChangePasswordParams } from "../../../types";
import { changePassword } from "../../../redux/actions";

const ChangePassword = () => {
  const strings = useSelector(getLocalizedAuth);
  const errorStrings = useSelector(getLocalizedErrors);
  const history = useHistory();
  const dispatch = useDispatch();
  const { Token }: { Token: string } = useParams();
  const ChangePasswordSchema = Yup.object().shape({
    Password: Yup.string()
      .min(6, errorStrings.getString("password_min"))
      .matches(
        /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])/,
        errorStrings.getString("password")
      )
      .required(errorStrings.getString("required")),
    confirmPassword: Yup.string()
      .required(errorStrings.getString("required"))
      .oneOf(
        [Yup.ref("Password")],
        errorStrings.getString("confirmpassworderror")
      ),
  });
  const submitForm = (values: any) => {
    let input: ChangePasswordParams = {
      Password: values.Password,
      Token: Token,
    };
    dispatch(
      changePassword({
        params: input,
        onSuccess: (message: string) => {
          Alert.success(errorStrings.getString("success"));
          history.push("/login");
        },
        onError: (message: string) => {
          Alert.error(message, 5000);
        },
      })
    );
  };

  return (
    <div className="authPage">
      <div className="authPageImage">
        <div style={{ marginInlineStart: "16%", marginBlockEnd: 50 }}>
          <h1
            style={{
              fontSize: 52,
              fontWeight: 700,
              lineHeight: "60px",
              maxWidth: 400,
              color: color.primarySofter,
              marginBlockEnd: 25,
            }}
          >
            {strings.getString("header")}
          </h1>
          <h4
            className="newHeadingStyle"
            style={{
              fontSize: 24,
              color: color.primarySofter,
              maxWidth: 350,
            }}
          >
            {strings.getString("subheader")}
          </h4>
          <p
            style={{
              fontSize: 40,
              fontWeight: 700,
              maxWidth: 400,
              color: color.primarySofter,
            }}
          >
            ...
          </p>
        </div>
      </div>
      <div className="authContent">
        <div className="forgotPasswordContent">
          <div
            className="standardText"
            style={{
              display: "flex",
              cursor: "pointer",
              lineHeight: "18px",
              fontSize: 12,
              marginBlockEnd: "35%",
              width: "max-content",
              color: color.textHeader,
            }}
            onClick={() => history.push("/login")}
          >
            <Icon
              icon="left"
              size={12}
              color={color.black}
              style={{ marginInlineEnd: 10, marginBlockStart: 3 }}
            />
            {strings.getString("back_to_login")}
          </div>
          <Formik
            initialValues={{
              Password: "",
              confirmPassword: "",
            }}
            validationSchema={ChangePasswordSchema}
            onSubmit={(values) => submitForm(values)}
          >
            {({ handleChange, handleBlur, handleSubmit, errors, touched }) => (
              <Form>
                <h4
                  style={{
                    fontWeight: 600,
                    fontSize: 36,
                    lineHeight: "54px",
                    fontStyle: "normal",
                    color: color.black,
                  }}
                >
                  {strings.getString("change")}
                </h4>
                <p
                  className="headingStyle"
                  style={{
                    fontWeight: 400,
                    color: color.blackFifty,
                    marginBlockEnd: "6%",
                  }}
                >
                  {strings.getString("change_description")}
                </p>
                <Input
                  type="password"
                  label={strings.getString("newPassword")}
                  placeholder={strings.getString("password_placeholder")}
                  name="Password"
                  onChange={handleChange("Password")}
                  onBlur={handleBlur("Password")}
                  error={errors.Password}
                  touched={touched.Password}
                />
                <Input
                  type="password"
                  label={strings.getString("confirmPassword")}
                  placeholder={strings.getString("password_placeholder")}
                  name="confirmPassword"
                  onChange={handleChange("confirmPassword")}
                  onBlur={handleBlur("confirmPassword")}
                  error={errors.confirmPassword}
                  touched={touched.confirmPassword}
                />
                <FormGroup>
                  <Button
                    title={strings.getString("send")}
                    icon="forward"
                    iconPosition="end"
                    style={{ marginBlockStart: "4%", paddingBlock: 16 }}
                    onClick={handleSubmit}
                  />
                </FormGroup>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
