import { images } from "../../../assets";
import { LoadObject } from "../../../types";
export const routes: {
  id: string;
  route: string;
  distance: number;
  price: number;
}[] = [
  {
    id: "1",
    route: "Istanbul, Bulgaria, Romania, Russia",
    distance: 2500,
    price: 3000,
  },
  {
    id: "2",
    route: "Istanbul, Bulgaria, Romania, Slovenia, Russia",
    distance: 3000,
    price: 3500,
  },
  { id: "3", route: "Istanbul, Russia", distance: 2000, price: 2500 },
];
export const loadResults: LoadObject[] = [
  {
    id: "1",
    name: "Faruk Güngör",
    departure: "Turkey",
    destination: "Romania",
    distance: 1523,
    load_type: "Goods",
    weight: 24,
    volume: 100,
    truck_type: ["5", "3"],
    number_of_cars: 13,
    start: "01/01/21",
    end: "02/01/21",
    amount: 4000,
    currency: "USD",
    posted: "2021-03-16T11:47:20.573",
    post_end: "2022-05-02T11:47:20.573",
    gps: true,
    image: images.profile1,
    location: { lat: 40.930438, lng: 29.150688 },
    pallets: false,
    pack_number: 1,
    partial: false,
    routes: [
      {
        id: "1",
        route: "Istanbul, Bulgaria, Romania, Russia",
        distance: 2500,
        price: 3000,
      },
      {
        id: "2",
        route: "Istanbul, Bulgaria, Romania, Slovenia, Russia",
        distance: 3000,
        price: 3500,
      },
    ],
    adr: true,
    adr_level: "ADR 5",
    frigo: false,
    frigo_degrees: null,
    doctor_required: true,
    second_driver_required: true,
    first_one_buys: true,
  },
  {
    id: "2",
    name: "Mehmet Polat Koçak",
    departure: null,
    destination: null,
    distance: 1523,
    load_type: "Metal",
    weight: 24,
    volume: 100,
    truck_type: ["2"],
    number_of_cars: 2,
    start: null,
    end: null,
    amount: null,
    currency: "TRY",
    posted: "2021-03-16T11:47:20.573",
    post_end: "2022-04-16T11:47:20.573",
    gps: false,
    image: images.profile2,
    location: { lat: 41.030438, lng: 33.150688 },
    pallets: true,
    pack_number: 5,
    partial: true,
    adr: false,
    adr_level: null,
    frigo: true,
    frigo_degrees: "-3 deg",
    doctor_required: false,
    second_driver_required: true,
    first_one_buys: false,
  },
  {
    id: "3",
    name: "Emin Sözüer",
    departure: "Turkey",
    destination: "Romania",
    distance: 1228,
    load_type: "Glass",
    weight: 24,
    volume: 100,
    truck_type: ["2", "3", "4"],
    number_of_cars: 3,
    start: "01/01/21",
    end: "02/01/21",
    amount: 3000,
    currency: "USD",
    posted: "2021-03-15T11:47:20.573",
    post_end: "2021-10-19T11:47:20.573",
    gps: true,
    image: images.profile3,
    location: { lat: 38.930438, lng: 44.150688 },
    pallets: true,
    pack_number: 3,
    partial: false,
    adr: false,
    adr_level: null,
    frigo: true,
    frigo_degrees: "-3 deg",
    doctor_required: false,
    second_driver_required: false,
    first_one_buys: false,
  },
  {
    id: "4",
    name: "Bayram Özge",
    departure: "Turkey",
    destination: "Romania",
    distance: 1400,
    load_type: "Goods",
    weight: 24,
    volume: 100,
    truck_type: ["5"],
    number_of_cars: 13,
    start: "01/01/21",
    end: "02/01/21",
    amount: 2500,
    currency: "EUR",
    posted: "2021-03-15T11:47:20.573",
    post_end: "2021-10-24T11:47:20.573",
    gps: true,
    image: images.profile4,
    location: { lat: 41.230438, lng: 27.150688 },
    pallets: false,
    pack_number: 2,
    partial: false,
    routes: [
      {
        id: "1",
        route: "Istanbul, Bulgaria, Romania, Russia",
        distance: 2500,
        price: 3000,
      },
      {
        id: "2",
        route: "Istanbul, Bulgaria, Romania, Slovenia, Russia",
        distance: 3000,
        price: 3500,
      },
      { id: "3", route: "Istanbul, Russia", distance: 2000, price: 2500 },
    ],
    adr: false,
    adr_level: null,
    frigo: false,
    frigo_degrees: null,
    doctor_required: false,
    second_driver_required: false,
    first_one_buys: true,
  },
  {
    id: "5",
    name: "Can Kılıç",
    departure: null,
    destination: null,
    distance: 2157,
    load_type: "Car Transport",
    weight: 24,
    volume: 100,
    truck_type: ["2", "4"],
    number_of_cars: 15,
    start: null,
    end: null,
    amount: null,
    currency: "EUR",
    posted: "2021-03-14T11:47:20.573",
    post_end: "2021-10-20T11:47:20.573",
    gps: false,
    image: images.profile5,
    location: { lat: 40.930438, lng: 29.150688 },
    pallets: true,
    pack_number: 7,
    partial: false,
    routes: [
      {
        id: "1",
        route: "Istanbul, Bulgaria, Romania, Russia",
        distance: 2500,
        price: 3000,
      },
      {
        id: "2",
        route: "Istanbul, Bulgaria, Romania, Slovenia, Russia",
        distance: 3000,
        price: 3500,
      },
    ],
    adr: true,
    adr_level: "ADR 9",
    frigo: true,
    frigo_degrees: "-3 deg",
    doctor_required: true,
    second_driver_required: true,
    first_one_buys: true,
  },
  {
    id: "6",
    name: "Elenar Pena",
    departure: "Turkey",
    destination: "Romania",
    distance: 576,
    load_type: "Carpentry",
    weight: 24,
    volume: 100,
    truck_type: ["1", "2", "3", "4", "5"],
    number_of_cars: 7,
    start: "01/01/21",
    end: "02/01/21",
    amount: 5000,
    currency: "TRY",
    posted: "2021-03-14T11:47:20.573",
    post_end: "2021-04-16T11:47:20.573",
    gps: true,
    image: images.profile6,
    location: { lat: 41.025944, lng: 28.978391 },
    pallets: true,
    pack_number: 18,
    partial: false,
    adr: true,
    adr_level: "ADR 9",
    frigo: true,
    frigo_degrees: "-3 deg",
    doctor_required: true,
    second_driver_required: true,
    first_one_buys: false,
  },
  {
    id: "7",
    name: "Jack Daniels",
    departure: "Turkey",
    destination: "Romania",
    distance: 1523,
    load_type: "Goods",
    weight: 24,
    volume: 100,
    truck_type: ["3"],
    number_of_cars: 2,
    start: "01/01/21",
    end: "02/01/21",
    amount: 4700,
    currency: "TRY",
    posted: "2021-03-09T11:47:20.573",
    post_end: "2021-03-21T11:47:20.573",
    gps: true,
    image: images.profile7,
    location: { lat: 41.025944, lng: 27.978391 },
    pallets: false,
    pack_number: 12,
    partial: true,
    routes: [
      {
        id: "1",
        route: "Istanbul, Bulgaria, Romania, Russia",
        distance: 2300,
        price: 3000,
      },
      {
        id: "2",
        route: "Istanbul, Bulgaria, Romania, Slovenia, Russia",
        distance: 3200,
        price: 3500,
      },
    ],
    adr: true,
    adr_level: "ADR 9",
    frigo: true,
    frigo_degrees: "-3 deg",
    doctor_required: true,
    second_driver_required: true,
    first_one_buys: true,
  },
  {
    id: "8",
    name: "Legolas Greenleaf",
    departure: null,
    destination: null,
    distance: 1412,
    load_type: "Carpentry",
    weight: 24,
    volume: 100,
    truck_type: ["3", "6"],
    number_of_cars: 8,
    start: null,
    end: null,
    amount: null,
    currency: "USD",
    posted: "2021-03-02T11:47:20.573",
    post_end: "2021-03-16T11:47:20.573",
    gps: false,
    image: images.profile8,
    location: { lat: 41.005944, lng: 36.978391 },
    pallets: false,
    pack_number: 5,
    partial: false,
    adr: true,
    adr_level: "ADR 9",
    frigo: true,
    frigo_degrees: "-3 deg",
    doctor_required: true,
    second_driver_required: true,
    first_one_buys: false,
  },
  {
    id: "9",
    name: "Jorji Costava",
    departure: "Turkey",
    destination: "Romania",
    distance: 1523,
    load_type: "Mega",
    weight: 24,
    volume: 100,
    truck_type: ["5"],
    number_of_cars: 3,
    start: "01/01/21",
    end: "02/01/21",
    amount: 3900,
    currency: "EUR",
    posted: "2021-02-16T11:47:20.573",
    post_end: "2021-03-16T11:47:20.573",
    gps: true,
    image: images.profile9,
    location: { lat: 37.025944, lng: 37.978391 },
    pallets: true,
    pack_number: 9,
    partial: true,
    routes: [
      {
        id: "1",
        route: "Istanbul, Bulgaria, Romania, Russia",
        distance: 2100,
        price: 3000,
      },
      {
        id: "2",
        route: "Istanbul, Bulgaria, Romania, Slovenia, Russia",
        distance: 2700,
        price: 3500,
      },
    ],
    adr: true,
    adr_level: "ADR 9",
    frigo: true,
    frigo_degrees: "-3 deg",
    doctor_required: true,
    second_driver_required: true,
    first_one_buys: true,
  },
];
